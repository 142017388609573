import React, { useState, useImperativeHandle } from 'react';

const QuickFilterInput = (props, ref) => {
  const [inputText, setInputText] = useState('');

  useImperativeHandle(ref, () => ({
    // allow the parent component to call the reset function
    reset() {
      setInputText('');
      props.onQuickFilterChange('');
    },
  }));

  const handleInputTextChange = (newValue) => {
    setInputText(newValue);
    props.onQuickFilterChange(newValue);
  };

  return <input className='quickFilterInput' type='text' id='filter-text-box' placeholder='Filter...' value={inputText} onChange={(e) => handleInputTextChange(e.target.value)} />;
};

/* eslint react/prop-types: 0 */
// Disable PropTypes because React.forwardRef doesn't support it.

// QuickFilterInput.propTypes = {
//   onQuickFilterChange: PropTypes.func,
// };

export default React.forwardRef(QuickFilterInput);
