import moment from 'moment';

const loadInspectionsByNumberOfMonthsSelected = (months, updateInspections, dispatch, lastUpdate = 0, filterOnInspectionsCanceledByMSA = false) => {
  if (lastUpdate !== 0) {
    updateInspections(dispatch, lastUpdate, false, filterOnInspectionsCanceledByMSA);
  } else if (months !== 0) {
    updateInspections(dispatch, Math.round(moment().add(-months, 'M') / 1000), true, filterOnInspectionsCanceledByMSA);
  } else {
    updateInspections(dispatch, 0, filterOnInspectionsCanceledByMSA);
  }
};

export default loadInspectionsByNumberOfMonthsSelected;
