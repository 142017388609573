import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import ViewCancelInspectionInfo from '../molecules/ViewCancelInspectionInfos';
import ViewInspectionInfos from '../molecules/ViewInspectionInfos';

Modal.setAppElement('body');

const ModalViewInspection = (props) => {
  const { modalData } = props.concessions;

  const closeModal = () => {
    props.dispatch({ type: 'HIDE_MODAL' });
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel='Modal annuler inspection'
      overlayClassName='modal-overlay'
      className='modal modalConcession'>
      {modalData.status === 'canceled' && modalData.cancelInfos ? (
        <ViewCancelInspectionInfo inspection={modalData} />
      ) : (
        <ViewInspectionInfos modalData={{ ...modalData, userRole: props.userRole, inspectionType: props.inspectionType }} />
      )}
      <div className='center formButton'>
        <button type='button' onClick={() => closeModal()} className='negativ'>
          Fermer
        </button>
      </div>
    </Modal>
  );
};

ModalViewInspection.propTypes = {
  inspectionType: PropTypes.string,
  userRole: PropTypes.string,
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
};

export default ModalViewInspection;
