import { Col, Row } from 'antd';
import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import constants from '../../constants';
import { getActionToDispatch, getMissions, updateNumberOfMonthsSelectedMissions } from '../../actions/MissionsActions';
import { updateNumberOfMonthsSelectedInspections, updatePlannedInspections } from '../../actions/plannedInspectionsActions';
import { getSpecialists, getSpecialistSkills } from '../../actions/specialistsActions';
import loadInspectionsByNumberOfMonthsSelected from '../../helpers/inspection';
import { filterSpecialistsByMenu, getSpecialistAndSkills } from '../../helpers/specialistsFilter';
import InspectionDetailsDrawer from '../inspections/organisms/InspectionDetailsDrawer';
import ModalAssignInspections from '../inspections/organisms/ModalAssignInspections';
import ModalCancelInspections from '../inspections/organisms/ModalCancelInspections';
import ModalNoShowInspections from '../inspections/organisms/ModalNoShowInspections';
import ModalUnassignInspections from '../inspections/organisms/ModalUnassignInspections';
import MissionDetailsDrawer from '../missions/organisms/MissionDetailsDrawer';
import ModalAssignSpecialistOrChangeStatusMissions from '../missions/organisms/ModalAssignSpecialistOrChangeStatusMissions';
import ActionsBar from '../shared/organisms/ActionsBar';
import NavBar from '../shared/templates/NavBar';
import ListInspections from './ListInspections';
import ListMissions from './ListMissions';
import MapComponent from './MapComponent';
import MapFilters from './MapFilters';

const moment = extendMoment(Moment);

const MapTab = (props) => {
  const {
    inspectionsFromDB,
    lastUpdate,
    lastUpdateMissions,
    specialistsState,
    specialistSkillsState,
    lastUpdateSpecialists,
    updateInspections,
    dispatch,
    match,
    missionsFromDB,
    numberOfMonthsSelectedInspections,
    numberOfMonthsSelectedMissions,
  } = props;

  const [mapPosition, setMapPosition] = useState([47, -3]);
  const [mapZoom, setMapZoom] = useState(6);
  const [inspections, setInspections] = useState([]);
  const [statusNb, setStatusNb] = useState({});

  const [inspectionFilters, setInspectionFilters] = useState([{ filterName: 'status', filter: ['ordered'] }]);
  const [inspectionFiltersRefresh, setInspectionFiltersRefresh] = useState(1);
  const [inspectionsFiltered, setInspectionsFiltered] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [missions, setMissions] = useState([]);
  const [missionFilters, setMissionFilters] = useState([{ filterName: 'status', filter: ['ordered'] }]);
  const [missionFiltersRefresh, setMissionFiltersRefresh] = useState(1);
  const [missionsFiltered, setMissionsFiltered] = useState([]);

  const [specialistsKind, setSpecialistsKind] = useState('');
  const [specialists, setSpecialists] = useState([]);
  const [departementList, setDepartementList] = useState([]);
  const [specialistFilters, setSpecialistFilters] = useState([]);
  const [specialistFiltersRefresh, setSpecialistFiltersRefresh] = useState(1);
  const [specialistFiltered, setSpecialistFiltered] = useState([]);
  const [speStatusNb, setSpeStatusNb] = useState({});
  const [skills, setSkills] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [clientListSelected, setClientListSelected] = useState([]);
  const [monthsSelected, setMonthsSelected] = useState(false);
  const [numberOfMonthsSelected, setNumberOfMonthsSelected] = useState(specialistsKind === 'inspect' ? numberOfMonthsSelectedInspections : numberOfMonthsSelectedMissions);

  const [showInspectionOrMissionFilter, setShowInspectionOrMissionFilter] = useState(true);
  const [showInspectionOrMission, setShowInspectionOrMission] = useState(false);
  const [inspectionOrMissionSelected, setInspectionOrMissionSelected] = useState(null);

  const [inspectionsOrMissionsChecked, setInspectionOrMissionChecked] = useState(null);
  const [modalToDisplay, setModalToDisplay] = useState('');
  const [checkAll, setCheckAll] = useState(null);

  const getMissionsOrInspections = specialistsKind === 'inspect' ? updateInspections : getMissions;
  const lastUpdateMissionsOrInspections = specialistsKind === 'inspect' ? lastUpdate : lastUpdateMissions;

  const fetchData = (specialistKind) => {
    try {
      const now = new Date();
      if (inspectionsFromDB && specialistKind === 'inspect') {
        setInspections(
          inspectionsFromDB.map((inspection) => {
            const returnedInspection = {
              ...inspection,
              search: `${inspection?.readableId} ${inspection?.seller?.name} ${inspection?.seller?.phone} ${inspection?.seller?.email} ${inspection?.concession?.name} ${inspection?.entity?.name} ${inspection?.appointment?.address} ${inspection?.infos}`.toLowerCase(),
            };

            if (returnedInspection.status === 'ordered') {
              const inspectionCreatedAt = new Date(returnedInspection.createdAt);
              const diffHours = Math.abs(now - inspectionCreatedAt) / (60 * 60 * 1000);
              // status ordered ==> hurry if > n hours
              if (diffHours > 4) {
                returnedInspection.hurry = true;
              }
            }

            return returnedInspection;
          }),
        );

        // for each inspection we get the entity and set lvl 0 and concession at lvl 1
        const clientTree = [];
        inspectionsFromDB.forEach((inspection) => {
          if (inspection.entity && inspection.concession) {
            let foundEntityKey = clientTree.find((entity) => entity.value === `entity ${inspection.entity._id}`);
            if (!foundEntityKey) {
              foundEntityKey = { value: `entity ${inspection.entity._id}`, title: inspection.entity.name, children: [] };
              clientTree.push(foundEntityKey);
            }
            const concessionKey = `concession ${inspection.concession._id}`;
            if (!foundEntityKey.children.some((concession) => concession.value === concessionKey)) {
              foundEntityKey.children.push({ value: concessionKey, title: inspection.concession.name });
            }
          }
        });
        setClientList(clientTree);
      } else {
        setMissions(
          missionsFromDB.map((mission) => {
            const returnedMission = {
              ...mission,
              search: `${mission.readableId} ${mission?.start} ${mission?.appointment?.contactPhone} ${mission?.appointment?.contactName} ${mission?.concession?.name} ${mission?.entity.name} ${mission?.appointment.address}`.toLowerCase(),
            };

            if (returnedMission.status === 'ordered') {
              const missionCreatedAt = new Date(returnedMission.createdAt);
              const diffHours = Math.abs(now - missionCreatedAt) / (60 * 60 * 1000);
              // status ordered ==> hurry if > n hours
              if (diffHours > 4) {
                returnedMission.hurry = true;
              }
            }

            return returnedMission;
          }),
        );
        // for each mission we get the entity and set lvl 0 and concession at lvl 1
        const clientTree = [];
        missionsFromDB.forEach((mission) => {
          let foundEntityKey = clientTree.find((entity) => entity.value === `entity ${mission.entity._id}`);
          if (!foundEntityKey) {
            foundEntityKey = { value: `entity ${mission.entity._id}`, title: mission.entity.name, children: [] };
            clientTree.push(foundEntityKey);
          }
          const concessionKey = `concession ${mission.concession._id}`;
          if (!foundEntityKey.children.some((concession) => concession.value === concessionKey)) {
            foundEntityKey.children.push({ value: concessionKey, title: mission.concession.name });
          }
        });
        setClientList(clientTree);
      }
      setMonthsSelected(false);
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  const statusCounterInspections = () => {
    const newStatusNb = {
      ordered: 0,
      scheduled: 0,
      done: 0,
      canceled: 0,
      notReceived: 0,
      all: inspections.length,
    };

    inspections.forEach((i) => {
      if (i.status === 'scheduled' && (moment(i.appointment.date) < moment())) {
        newStatusNb.notReceived += 1;
      }
      if (i.status === 'refused_validation' || i.status === 'no_show') {
        newStatusNb.canceled += 1;
      }
    });

    Object.keys(newStatusNb).forEach((status) => {
      if (status !== 'all') newStatusNb[status] = _.sumBy(inspections, (mission) => mission.status === status);
    });

    return newStatusNb;
  };

  const statusCounterMissions = () => {
    const newStatusNb = {
      ordered: 0,
      scheduled: 0,
      ongoing: 0,
      done: 0,
      canceled: 0,
      all: missions.length,
    };
    Object.keys(newStatusNb).forEach((status) => { if (status !== 'all') newStatusNb[status] = _.sumBy(missions, (mission) => mission.status === status); });

    return newStatusNb;
  };

  const speStatusCounter = () => {
    const newStatusNb = {
      confirme: 0,
      actif: 0,
      non_confirme: 0,
      nouveaux: 0,
      bloques: 0,
      inactifs: 0,
      all: 0,
    };
    specialists.forEach((i) => {
      const statusToCheck = specialistsKind === 'inspect' ? i.status : i.stafferStatus;
      switch (statusToCheck) {
        case 'confirme':
          newStatusNb.confirme += 1;
          newStatusNb.all += 1;
          return newStatusNb;
        case 'actif':
          newStatusNb.actif += 1;
          newStatusNb.all += 1;
          return newStatusNb;
        case 'non_confirme':
          newStatusNb.non_confirme += 1;
          newStatusNb.all += 1;
          return newStatusNb;
        case 'nouveaux':
          newStatusNb.nouveaux += 1;
          newStatusNb.all += 1;
          return newStatusNb;
        case 'bloques':
          newStatusNb.bloques += 1;
          newStatusNb.all += 1;
          return newStatusNb;
        case 'inactifs':
          newStatusNb.inactifs += 1;
          newStatusNb.all += 1;
          return newStatusNb;
        default:
          return newStatusNb;
      }
    });
    return newStatusNb;
  };

  // on a une liste de filtres, avec un use effect dessus qui update la liste de filtered inspections, quand cette liste est modifiée on les parcours et applique
  const addSpecialistFilters = (filterName, filter) => {
    const newFilters = specialistFilters;
    const newFilter = { filterName, filter };
    const oldFilterIndex = newFilters.findIndex((filterSpe) => filterSpe.filterName === filterName);

    if (oldFilterIndex !== -1) {
      newFilters[oldFilterIndex] = newFilter;
    } else {
      newFilters.push(newFilter);
    }
    setSpecialistFilters(newFilters);
    setSpecialistFiltersRefresh(specialistFiltersRefresh + 1);
  };

  const removeSpecialistFilters = (filterName) => {
    setSpecialistFilters(specialistFilters.filter((filterSpe) => filterSpe.filterName !== filterName));
    setSpecialistFiltersRefresh(specialistFiltersRefresh + 1);
  };

  const addInspectionFilters = (filterName, filter) => {
    const newFilters = inspectionFilters;
    const newFilter = { filterName, filter };
    const oldFilterIndex = newFilters.findIndex((filterIns) => filterIns.filterName === filterName);

    if (oldFilterIndex !== -1) {
      newFilters[oldFilterIndex] = newFilter;
    } else {
      newFilters.push(newFilter);
    }

    setInspectionFilters(newFilters);
    setInspectionFiltersRefresh(inspectionFiltersRefresh + 1);
  };

  const removeInspectionFilters = (filterNames) => {
    setInspectionFilters(inspectionFilters.filter((filterIns) => !filterNames.includes(filterIns.filterName)));
    setInspectionFiltersRefresh(inspectionFiltersRefresh + 1);
  };

  const addMissionFilters = (filterName, filter) => {
    const newFilters = missionFilters;
    const newFilter = { filterName, filter };
    const oldFilterIndex = newFilters.findIndex((filterMiss) => filterMiss.filterName === filterName);

    if (oldFilterIndex !== -1) {
      newFilters[oldFilterIndex] = newFilter;
    } else {
      newFilters.push(newFilter);
    }

    setMissionFilters(newFilters);
    setMissionFiltersRefresh(missionFiltersRefresh + 1);
  };

  const removeMissionFilters = (filterNames) => {
    setMissionFilters(missionFilters.filter((filterMiss) => !filterNames.includes(filterMiss.filterName)));
    setMissionFiltersRefresh(missionFiltersRefresh + 1);
  };

  const searchFilter = (searchTerm, type) => {
    if (type === 'specialist') {
      if (searchTerm !== '' && searchTerm.length > 0) {
        const terms = searchTerm.split(' ');

        addSpecialistFilters('searchFilter', terms);
      } else {
        removeSpecialistFilters('searchFilter');
      }
    } else if (type === 'inspection') {
      if (searchTerm !== '' && searchTerm.length > 0) {
        const terms = searchTerm.split(' ');

        addInspectionFilters('searchFilter', terms);
      } else {
        removeInspectionFilters('searchFilter');
      }
    } else if (type === 'mission') {
      if (searchTerm !== '' && searchTerm.length > 0) {
        const terms = searchTerm.split(' ');

        addMissionFilters('searchFilter', terms);
      } else {
        removeMissionFilters('searchFilter');
      }
    }
  };

  const filterInspectionClient = (selectedValue) => {
    setClientListSelected(selectedValue);
    let inspectionFiltersToClear = [];

    if (selectedValue.length > 0) {
      const entityFilters = [];
      const concessionFilters = [];
      selectedValue.forEach((val) => {
        const [type, id] = val.split(' ');
        if (type === 'entity') {
          entityFilters.push(id);
        } else if (type === 'concession') {
          concessionFilters.push(id);
        }
      });

      if (entityFilters.length > 0) {
        addInspectionFilters('inspectionClientEntity', entityFilters);
      } else {
        inspectionFiltersToClear.push('inspectionClientEntity');
      }
      if (concessionFilters.length > 0) {
        addInspectionFilters('inspectionClientConcession', concessionFilters);
      } else {
        inspectionFiltersToClear.push('inspectionClientConcession');
      }
    } else {
      inspectionFiltersToClear = ['inspectionClientEntity', 'inspectionClientConcession'];
    }
    if (inspectionFiltersToClear.length > 0) {
      removeInspectionFilters(inspectionFiltersToClear);
    }
  };

  const filterMissionClient = (selectedValue) => {
    setClientListSelected(selectedValue);
    let missionFiltersToClear = [];

    if (selectedValue.length > 0) {
      const entityFilters = [];
      const concessionFilters = [];
      selectedValue.forEach((val) => {
        const [type, id] = val.split(' ');
        if (type === 'entity') {
          entityFilters.push(id);
        } else if (type === 'concession') {
          concessionFilters.push(id);
        }
      });

      if (entityFilters.length > 0) {
        addMissionFilters('missionClientEntity', entityFilters);
      } else {
        missionFiltersToClear.push('missionClientEntity');
      }
      if (concessionFilters.length > 0) {
        addMissionFilters('missionClientConcession', concessionFilters);
      } else {
        missionFiltersToClear.push('missionClientConcession');
      }
    } else {
      missionFiltersToClear = ['missionClientEntity', 'missionClientConcession'];
    }
    if (missionFiltersToClear.length > 0) {
      removeMissionFilters(missionFiltersToClear);
    }
  };

  const filterInspectionDate = (dates) => {
    if (!dates || dates.length !== 2) {
      removeInspectionFilters('inspectionDate');
    } else {
      const [fromDate, toDate] = dates;
      const adjustedDateFrom = moment(fromDate).startOf('day');
      const adjustedDateTo = moment(toDate).endOf('day');
      addInspectionFilters('inspectionDate', [adjustedDateFrom, adjustedDateTo]);
    }
  };

  const filterMissionDate = (dates) => {
    if (!dates || dates.length !== 2) {
      removeMissionFilters('missionDate');
    } else {
      const [fromDate, toDate] = dates;

      const adjustedDateFrom = moment(fromDate).startOf('day');
      const adjusteDateTo = moment(toDate).endOf('day');

      addMissionFilters('missionDate', [adjustedDateFrom, adjusteDateTo]);
    }
  };

  const filterSpecialistDate = (dates) => {
    if (!dates || dates.length !== 2) {
      removeSpecialistFilters('specialistDate');
    } else {
      const [fromDate, toDate] = dates;
      const dateFrom = new Date(fromDate);
      let dateTo = new Date(toDate);

      if (dateFrom.getTime() === dateTo.getTime()) {
        dateTo = new Date(fromDate);
      }
      dateFrom.setHours(0);
      dateTo.setHours(24);
      const adjustedDateFrom = dateFrom.getTime();
      const adjustedDateTo = dateTo.getTime();

      addSpecialistFilters('specialistDate', [adjustedDateFrom, adjustedDateTo]);
    }
  };

  const filterSpecialistSkills = (selectedSkillsFromFilter) => {
    setSelectedSkills(selectedSkillsFromFilter);
    if (selectedSkillsFromFilter.length > 0) {
      if (specialistsState.length !== specialists.length) {
        const [specialistsList] = getSpecialistAndSkills(specialistsState, specialistSkillsState);
        setSpecialists(specialistsList[0]);
      }
    } else {
      removeSpecialistFilters('specialistSkills');
    }
  };

  const filterSpecialistAvailability = (selectedAvailabilities) => {
    if (selectedAvailabilities.length > 0) {
      addSpecialistFilters('specialistAvailability', selectedAvailabilities);
    } else {
      removeSpecialistFilters('specialistAvailability');
    }
  };

  useEffect(() => {
    addSpecialistFilters('specialistSkills', selectedSkills);
  }, [selectedSkills]);

  const filterSpecialistDepartement = (selectedDepartments) => {
    if (selectedDepartments.length > 0) {
      addSpecialistFilters('specialistDepartement', selectedDepartments);
    } else {
      removeSpecialistFilters('specialistDepartement');
    }
  };

  const resetFilters = () => {
    setSpecialistFilters([]);
    setSpecialistFiltered([]);
    setInspectionFilters([]);
    setInspectionsFiltered([]);
  };

  const setPositionMap = (latlng) => {
    setMapPosition(latlng);
    setMapZoom(10);
  };

  const handleInspectionOrMissionClick = (inspectionOrMission) => {
    setShowInspectionOrMission(true);
    setShowInspectionOrMissionFilter(false);
    setInspectionOrMissionSelected(inspectionOrMission);
    setPositionMap([inspectionOrMission.appointment.lat, inspectionOrMission.appointment.lng]);
  };

  const handleSpecialistUpdate = (updatedSpecialist) => {
    const speIndex = specialists.findIndex((specialist) => specialist._id === updatedSpecialist._id);
    if (speIndex) {
      specialists[speIndex] = updatedSpecialist;
      setSpecialists(specialists);
    }
  };

  const hideFilterButtonClick = () => {
    setShowInspectionOrMissionFilter(!showInspectionOrMissionFilter);
  };

  const inspectionPositions = inspectionsFiltered.reduce((acc, insFiltered) => {
    if (insFiltered && insFiltered.appointment && insFiltered.appointment.lat) {
      return [...acc, [insFiltered.appointment.lat, insFiltered.appointment.lng]];
    }
    return [...acc, [0, 0]];
  }, []);

  const missionPositions = missionsFiltered.reduce((acc, missFiltered) => {
    if (missFiltered && missFiltered.appointment && missFiltered.appointment.lat) {
      return [...acc, [missFiltered.appointment.lat, missFiltered.appointment.lng]];
    }
    return [...acc, [0, 0]];
  }, []);

  const specialistPositions = specialistFiltered.reduce((acc, speFiltered) => {
    if (speFiltered && speFiltered.location && speFiltered.location.coordinates) {
      return [...acc, [speFiltered.location.coordinates[1], speFiltered.location.coordinates[0]]];
    }
    return [...acc, [0, 0]];
  }, []);

  const getInspectionsOrMissionsSelected = (inspectionsOrMissionsCheck) => {
    setInspectionOrMissionChecked(inspectionsOrMissionsCheck);
  };

  const onSelectAllInspection = (value) => {
    setCheckAll(value || null);
  };

  const closeModal = ({ didUpdate = false }) => {
    if (didUpdate) {
      setInspectionOrMissionChecked([]);
    }
    setModalToDisplay('');
  };

  const onClickMultiple = (modalName, rowsData = null) => () => {
    setModalToDisplay(modalName);
    if (rowsData) {
      props.dispatch(getActionToDispatch({ type: 'SHOW_MODAL', modalName, modalData: rowsData }));
    }
  };

  const onSelectedMonths = (months) => {
    setMonthsSelected(true);
    setNumberOfMonthsSelected(months);
    if (specialistsKind === 'inspect') {
      updateNumberOfMonthsSelectedInspections(dispatch, months);
    } else {
      updateNumberOfMonthsSelectedMissions(dispatch, months);
    }
    loadInspectionsByNumberOfMonthsSelected(months, getMissionsOrInspections, dispatch, 0, true);
  };

  const closeModalMission = async (withUpdate = false) => {
    props.dispatch(getActionToDispatch({ type: 'HIDE_MODAL' }));
    if (withUpdate) {
      await getMissions(props.dispatch, props.updatedAt);
    }
  };

  const { application, allMissions } = props;

  const myProps = {
    dispatch,
    application,
    allMissions,
  };

  const renderModals = () => {
    const { modalName } = allMissions;

    switch (modalName) {
      case 'assignSpecialist':
        return <ModalAssignSpecialistOrChangeStatusMissions {...myProps} closeModal={closeModalMission} />;
      case 'unassignSpecialist':
        return <ModalAssignSpecialistOrChangeStatusMissions {...myProps} closeModal={closeModalMission} />;
      case 'cancelMission':
        return <ModalAssignSpecialistOrChangeStatusMissions {...myProps} closeModal={closeModalMission} />;
      case 'changeStatus':
        return <ModalAssignSpecialistOrChangeStatusMissions {...myProps} closeModal={closeModalMission} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (specialistsKind) {
      (async () => {
        await Promise.all([getSpecialists(dispatch, lastUpdateSpecialists), getSpecialistSkills(dispatch)]);
        loadInspectionsByNumberOfMonthsSelected(numberOfMonthsSelected, getMissionsOrInspections, dispatch, lastUpdateMissionsOrInspections, true);
      })();
    }
  }, [specialistsKind]);

  useEffect(() => {
    if (match.path === '/admin/inspect/map') {
      setSpecialistsKind('inspect');
      setSpecialistFilters([{ filterName: 'status', filter: ['actif', 'confirme'] }]);
      setNumberOfMonthsSelected(numberOfMonthsSelectedInspections);
      dispatch({
        type: 'SET_NAVIGATION',
        navigation: 'map',
      });
    }
    if (match.path === '/admin/staff/mapStaff') {
      setSpecialistsKind('staff');
      setSpecialistFilters([{ filterName: 'stafferStatus', filter: ['actif', 'confirme'] }]);
      setNumberOfMonthsSelected(numberOfMonthsSelectedMissions);
      dispatch({
        type: 'SET_NAVIGATION',
        navigation: 'mapStaff',
      });
    }
  }, []);

  useEffect(() => {
    const [specialistsList, skillsList, departmentsList] = getSpecialistAndSkills(specialistsState, specialistSkillsState);
    setSpecialists(filterSpecialistsByMenu(specialistsList[0], specialistsKind));
    setSkills(skillsList[0]);
    setDepartementList(departmentsList[0]);
  }, [specialistsState.length > 1 && specialistSkillsState.length > 1 && specialistsKind]);

  useEffect(() => {
    let baseInspection = inspections;
    if (inspectionFilters.length > 0) {
      const statusFilter = inspectionFilters.find((filter) => filter.filterName === 'status');
      if (statusFilter) {
        baseInspection = inspections.filter((inspection) => statusFilter.filter.includes(inspection.status));
      }
    }
    setInspectionsFiltered(baseInspection);
    setStatusNb(statusCounterInspections(inspections));
  }, [inspections]);

  useEffect(() => {
    let baseMission = missions;
    if (missionFilters.length > 0) {
      const statusFilter = missionFilters.find((filter) => filter.filterName === 'status');
      if (statusFilter) {
        baseMission = missions.filter((mission) => statusFilter.filter.includes(mission.status));
      }
    }
    setMissionsFiltered(baseMission);
    setStatusNb(statusCounterMissions(missions));
  }, [missions]);

  useEffect(() => {
    setSpecialistFiltered(
      specialists.filter(
        (elem) =>
          specialistFilters.every((filterSpe) => {
            const { filterName, filter } = filterSpe;
            if (specialistsKind === 'inspect' && filterName === 'status') {
              return filter.includes(elem.status);
            }
            if (specialistsKind === 'staff' && filterName === 'stafferStatus') {
              return filter.includes(elem.stafferStatus);
            }
            if (filterName === 'searchFilter') {
              return filter.every((term) => elem.search.includes(term));
            }
            if (filterName === 'specialistDate') {
              const [adjustedDateFrom, adjustedDateTo] = filter;
              const specialistDate = elem?.createdAt?.value;
              const adjustedSpecialistDate = new Date(specialistDate).getTime();
              return specialistDate && adjustedSpecialistDate >= adjustedDateFrom && adjustedSpecialistDate <= adjustedDateTo;
            }
            if (filterName === 'specialistSkills') {
              return elem.skills && filter.some((skill) => elem.skills.includes(skill));
            }
            if (filterName === 'specialistDepartement') {
              return filter.includes(elem.departement);
            }
            if (filterName === 'specialistAvailability') {
              return filter.includes(elem?.profile?.availability);
            }
            return false;
          }),
      ),
    );
  }, [specialistFiltersRefresh]);

  useEffect(() => {
    setInspectionsFiltered(
      inspections.filter(
        (inspection) =>
          inspectionFilters.every((filterIns) => {
            const { filterName, filter } = filterIns;

            if (filterName === 'status') {
              return filter.includes(inspection.status);
            }
            if (filterName === 'searchFilter') {
              return filter.every((term) => inspection.search.includes(term));
            }
            if (filterName === 'inspectionDate') {
              const [adjustedDateFrom, adjustedDateTo] = filter;
              const inspectionDate = inspection?.appointment?.date || inspection?.appointment?.timeSlots?.inspectionTimeSlot;
              const inspectionsInSelectedRange = moment(inspectionDate).isBetween(adjustedDateFrom, adjustedDateTo);
              return inspectionsInSelectedRange;
            }
            if (filterName === 'inspectionClientEntity') {
              return inspection.entity && filter.includes(inspection.entity._id);
            }
            if (filterName === 'inspectionClientConcession') {
              return inspection.concession && filter.includes(inspection.concession._id);
            }
            return false;
          }),
      ),
    );
  }, [inspectionFiltersRefresh]);

  useEffect(() => {
    setMissionsFiltered(
      missions.filter(
        (mission) =>
          missionFilters.every((filterMiss) => {
            const { filterName, filter } = filterMiss;

            if (filterName === 'status') {
              return filter.includes(mission.status);
            }
            if (filterName === 'searchFilter') {
              return filter.every((term) => mission.search.includes(term));
            }
            if (filterName === 'missionDate') {
              const [adjustedDateFrom, adjustedDateTo] = filter;
              const startMissionDate = moment(mission?.start).startOf('day');
              const endMissionDate = moment(mission?.end).endOf('day');
              const dateRangeFromUser = moment.range(adjustedDateFrom, adjustedDateTo);
              const dateRangeFromMissions = moment.range(startMissionDate, endMissionDate);
              const missionsInSelectedRange = dateRangeFromUser.intersect(dateRangeFromMissions);
              return missionsInSelectedRange;
            }
            if (filterName === 'missionClientEntity') {
              return mission.entity && filter.includes(mission.entity._id);
            }
            if (filterName === 'missionClientConcession') {
              return mission.concession && filter.includes(mission.concession._id);
            }
            return false;
          }),
      ),
    );
  }, [missionFiltersRefresh]);

  useEffect(() => {
    if (specialistFilters.length > 0) {
      const statusFilter = specialistFilters.find(({ filterName }) => ['status', 'stafferStatus'].includes(filterName));
      if (statusFilter) {
        setSpecialistFiltered(specialists.filter((elem) => statusFilter.filter.includes((specialistsKind === 'inspect') ? elem.status : elem.stafferStatus)));
      }
    } else {
      // now if no filters = we show nothing
      setSpecialistFiltered([]);
    }
    setSpeStatusNb(speStatusCounter(specialists));
  }, [specialists]);

  useEffect(() => {
    if (inspectionsFromDB.length > 1 || missionsFromDB.length > 1) {
      fetchData(specialistsKind);
    }
  }, [lastUpdateMissionsOrInspections, getMissionsOrInspections, inspectionsFromDB, missionsFromDB]);

  useEffect(() => {
    if (match.path === '/admin/inspect/map') {
      setSpecialistsKind('inspect');
      setNumberOfMonthsSelected(numberOfMonthsSelectedInspections);
      dispatch({
        type: 'SET_NAVIGATION',
        navigation: 'map',
      });
    }
    if (match.path === '/admin/staff/mapStaff') {
      setSpecialistsKind('staff');
      setNumberOfMonthsSelected(numberOfMonthsSelectedMissions);
      dispatch({
        type: 'SET_NAVIGATION',
        navigation: 'mapStaff',
      });
    }
  }, []);

  return (
    <div>
      <NavBar />
      <div
        style={{
          height: 'calc(100% - 80px)',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
      >
        <Row style={{ backgroundColor: 'white' }}>
          <MapComponent
            mapPosition={mapPosition}
            mapZoom={mapZoom}
            inspectionsOrMissions={specialistsKind === 'inspect' ? inspectionsFiltered : missionsFiltered}
            inspectionsPositions={specialistsKind === 'inspect' ? inspectionPositions : missionPositions}
            specialists={specialistFiltered}
            skills={skills}
            specialistPositions={specialistPositions}
            handleInspectionOrMissionClick={handleInspectionOrMissionClick}
            handleSpecialistUpdate={handleSpecialistUpdate}
            specialistKind={specialistsKind}
          />
          {!showInspectionOrMission && (
            <div
              style={{
                position: 'absolute',
                zIndex: '1100',
                left: 7,
                top: 7,
              }}
            >
              <button className='specialistFicheClose' onClick={hideFilterButtonClick}>
                <i className={showInspectionOrMissionFilter ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} style={{ marginRight: '0px' }} />
              </button>
            </div>
          )}
          {showInspectionOrMissionFilter && (
            <div className='cardFilter'>
              <MapFilters
                inspectionsOrMissionsStatusNb={statusNb}
                inspectionsOrMissionsFilteredNb={specialistsKind === 'inspect' ? inspectionsFiltered.length : missionsFiltered.length}
                filterInspectionsOrMissions={specialistsKind === 'inspect' ? addInspectionFilters : addMissionFilters}
                filterSpecialists={addSpecialistFilters}
                specialistStatusNb={speStatusNb}
                specialistFilteredNb={specialistFiltered.length}
                clientList={clientList}
                departementList={departementList}
                skillsList={skills}
                availabilities={Object.entries(constants.availabilities)}
                searchFilter={searchFilter}
                clientFilter={specialistsKind === 'inspect' ? filterInspectionClient : filterMissionClient}
                dateFilter={specialistsKind === 'inspect' ? filterInspectionDate : filterMissionDate}
                specialistDateFilter={filterSpecialistDate}
                specialistSkillsFilter={filterSpecialistSkills}
                specialistDepartementFilter={filterSpecialistDepartement}
                specialistAvailabilityFilter={filterSpecialistAvailability}
                resetFilters={resetFilters}
                specialistKind={specialistsKind}
                /* Set Value Selected */
                inspectionsOrMissionsSearchEnter={specialistsKind === 'inspect' ? _.get(
                  inspectionFilters.find((filter) => filter.filterName === 'searchFilter'),
                  'filter',
                  [],
                ) : _.get(
                  missionFilters.find((filter) => filter.filterName === 'searchFilter'),
                  'filter',
                  [],
                )}
                inspectionsOrMissionsStatusSelected={specialistsKind === 'inspect' ? _.get(
                  inspectionFilters.find((filter) => filter.filterName === 'status'),
                  'filter',
                  [],
                ) : _.get(
                  missionFilters.find((filter) => filter.filterName === 'status'),
                  'filter',
                  [],
                )}

                inspectionsOrMissionsClientConcessionSelected={clientListSelected}
                inspectionsOrMissionsDateSelected={specialistsKind === 'inspect' ? _.get(
                  inspectionFilters.find((filter) => filter.filterName === 'inspectionDate'),
                  'filter',
                  [],
                ) : _.get(
                  missionFilters.find((filter) => filter.filterName === 'missionDate'),
                  'filter',
                  [],
                )}
                specialistsSearchEnter={_.get(
                  specialistFilters.find((filter) => filter.filterName === 'searchFilter'),
                  'filter',
                  [],
                )}
                specialistsStatusSelected={_.get(
                  specialistFilters.find((filter) => (specialistsKind === 'inspect') ? filter.filterName === 'status' : filter.filterName === 'stafferStatus'),
                  'filter',
                  [],
                )}
                specialistDepartementSelected={_.get(
                  specialistFilters.find((filter) => filter.filterName === 'specialistDepartement'),
                  'filter',
                  [],
                )}
                specialistSkillsSelected={_.get(
                  specialistFilters.find((filter) => filter.filterName === 'specialistSkills'),
                  'filter',
                  [],
                )}
                specialistDateSelected={
                  _.get(
                    specialistFilters.find((filter) => filter.filterName === 'specialistDate'),
                    'filter',
                    [],
                  ) || null
                }
                specialistAvailabilitySelected={
                  _.get(
                    specialistFilters.find((filter) => filter.filterName === 'specialistAvailability'),
                    'filter',
                    [],
                  ) || null}
              />
              <ActionsBar
                selectedRows={inspectionsOrMissionsChecked}
                onClickMultipleAssign={specialistsKind === 'inspect' ? onClickMultiple('assign-multiple') : onClickMultiple('assignSpecialist', inspectionsOrMissionsChecked)}
                onClickMultipleUnassign={specialistsKind === 'inspect' ? onClickMultiple('unassign-multiple') : onClickMultiple('unassignSpecialist', inspectionsOrMissionsChecked)}
                onClickMultipleNoShow={onClickMultiple('no-show')}
                onClickMultipleCancel={specialistsKind === 'inspect' ? onClickMultiple('cancel-multiple') : onClickMultiple('cancelMission', inspectionsOrMissionsChecked)}
                onSelectAllInspection={onSelectAllInspection}
                onClickChangeStatus={onClickMultiple('changeStatus', inspectionsOrMissionsChecked)}
                mapView={true}
                onSelectMonthsLoadingInspections={onSelectedMonths}
                loadingInspectionsByMonths={monthsSelected}
                numberOfMonthsSelected={numberOfMonthsSelected}
                interfaceView={specialistsKind === 'inspect' ? 'adminInterface' : 'missionInterface'}
              />
              <div style={{ height: '60vh' }}>
                {specialistsKind === 'inspect'
                  ? (
                    <ListInspections
                      inspections={inspectionsFiltered.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt))}
                      setPosition={setPositionMap}
                      handleInspectionClick={handleInspectionOrMissionClick}
                      inspectionsSelected={getInspectionsOrMissionsSelected}
                      checkAll={checkAll}
                    />
                  ) : (
                    <ListMissions
                      missions={missionsFiltered.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt))}
                      setPosition={setPositionMap}
                      handleMissionClick={handleInspectionOrMissionClick}
                      missionsSelected={getInspectionsOrMissionsSelected}
                      checkAll={checkAll}
                    />
                  )
                }
              </div>
              <ModalAssignInspections isOpen={modalToDisplay === 'assign-multiple'} inspections={inspectionsOrMissionsChecked} onRequestClose={closeModal} />
              <ModalNoShowInspections
                isOpen={modalToDisplay === 'no-show'}
                inspections={modalToDisplay === 'no-show' ? inspectionsOrMissionsChecked : [inspectionsFiltered]}
                onRequestClose={closeModal}
              />
              <ModalCancelInspections
                isOpen={['cancel', 'cancel-multiple'].includes(modalToDisplay)}
                inspections={modalToDisplay === 'cancel-multiple' ? inspectionsOrMissionsChecked : [inspectionsFiltered]}
                onRequestClose={closeModal}
              />
              <ModalUnassignInspections
                isOpen={['unassign', 'unassign-multiple'].includes(modalToDisplay)}
                inspections={modalToDisplay === 'unassign-multiple' ? inspectionsOrMissionsChecked : [inspectionsFiltered]}
                onRequestClose={closeModal}
              />
            </div>
          )}
        </Row>
        {showInspectionOrMission && inspectionOrMissionSelected && (
          <Row style={{ backgroundColor: 'white' }}>
            <Col style={{ backgroundColor: '#fafafa' }}>
             {specialistsKind === 'inspect'
               ? <InspectionDetailsDrawer
                streetView
                isOpen={showInspectionOrMission}
                inspectionId={inspectionOrMissionSelected._id}
                closeCard={() => {
                  setShowInspectionOrMission(false);
                  setShowInspectionOrMissionFilter(true);
                  setInspectionOrMissionSelected(null);
                }}
                adminInterface={true}
                style={{
                  zIndex: 1000,
                  left: 0,
                  top: 80,
                  boxShadow: 'none',
                  overflowX: 'hidden',
                }}
                placement='left'
                mask={false}
              />
               : <MissionDetailsDrawer
               streetView
               isOpen={showInspectionOrMission}
               missionId={inspectionOrMissionSelected._id}
               closeCard={() => {
                 setShowInspectionOrMission(false);
                 setShowInspectionOrMissionFilter(true);
                 setInspectionOrMissionSelected(null);
               }}
               adminInterface={true}
               style={{
                 zIndex: 1000,
                 left: 0,
                 top: 80,
                 boxShadow: 'none',
                 overflowX: 'hidden',
               }}
               placement='left'
               mask={false}
                />
             }

            </Col>
          </Row>
        )}
        {renderModals()}
      </div>
    </div>
  );
};

MapTab.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  updatedAt: PropTypes.number,
  allMissions: PropTypes.object,
  inspectionsFromDB: PropTypes.array,
  lastUpdate: PropTypes.number,
  numberOfMonthsSelectedInspections: PropTypes.number,
  updateInspections: PropTypes.func,
  missionsFromDB: PropTypes.array,
  lastUpdateMissions: PropTypes.number,
  updateNumberOfMonthsSelectedMissions: PropTypes.func,
  updateNumberOfMonthsSelectedInspections: PropTypes.func,
  numberOfMonthsSelectedMissions: PropTypes.number,
  getMissions: PropTypes.func,
  specialistsState: PropTypes.array,
  specialistSkillsState: PropTypes.array,
  lastUpdateSpecialists: PropTypes.number,
  getSpecialists: PropTypes.func,
  getSpecialistSkills: PropTypes.func,
  match: PropTypes.object,
  activeMenu: PropTypes.string,
};

const mapStateToProps = (state) => ({
  activeMenu: state.application.activeMenu,
  inspectionsFromDB: state.plannedInspections.inspections,
  missionsFromDB: state.missions.missions,
  lastUpdateMissions: state.missions.lastUpdatedAt,
  numberOfMonthsSelectedMissions: state.missions.numberOfMonthsSelected,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
  numberOfMonthsSelectedInspections: state.plannedInspections.numberOfMonthsSelected,
  specialistsState: state.specialists.specialists,
  specialistSkillsState: state.specialists.specialistSkills,
  lastUpdateSpecialists: state.specialists.updatedAt,

  application: state.application,
  updatedAt: state.missions.lastUpdatedAt,
  allMissions: state.missions,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updateInspections: updatePlannedInspections,
  getMissions,
  getSpecialists,
  getSpecialistSkills,
  updateNumberOfMonthsSelectedInspections,
  updateNumberOfMonthsSelectedMissions,
});

export default connect(mapStateToProps, mapDispatchToProps)(MapTab);
