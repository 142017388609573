import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const isAuthorized = (data) => {
  if (data && (data.role === 'reseller' || data.role === 'chief' || data.role === 'superChief')) {
    return true;
  }
  return false;
};

const Concession = ({ activeSession, isLogged, component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      activeSession && (isLogged || localStorage.getItem('user')) && isAuthorized(user) ? React.createElement(component, { ...props, isLogged }) : <Redirect to='/login' />
    }
  />
);

Concession.propTypes = {
  activeSession: PropTypes.bool,
  isLogged: PropTypes.bool,
  user: PropTypes.object,
  component: PropTypes.object,
};

export default Concession;
