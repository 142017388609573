import constants from '../constants';

const getCountries = async () => {
  const response = await fetch(constants.URI.v3.utils.countries, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

export default {
  getCountries,
};
