import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import adminReducer from '../reducers/adminReducer';
// Custom Reducers
import applicationReducer from '../reducers/applicationReducer';
import billingsReducer from '../reducers/billingsReducer';
import concessionsReducer from '../reducers/concessionsReducer';
import countriesReducer from '../reducers/countriesReducer';
import entitiesReducer from '../reducers/entitiesReducer';
import missionReducer from '../reducers/missionReducer';
import onDemandInspectionsReducer from '../reducers/onDemandInspectionsReducer';
import plannedInspectionsReducer from '../reducers/plannedInspectionsReducer';
import reportsV3Reducer from '../reducers/reportsV3Reducer';
import specialistsReducer from '../reducers/specialistsReducer';

const appReducer = combineReducers({
  application: applicationReducer,
  onDemandInspections: onDemandInspectionsReducer,
  plannedInspections: plannedInspectionsReducer,
  admin: adminReducer,
  concessions: concessionsReducer,
  reportsV3: reportsV3Reducer,
  specialists: specialistsReducer,
  billings: billingsReducer,
  countries: countriesReducer,
  entities: entitiesReducer,
  missions: missionReducer,
});

const middlewares = [thunkMiddleware];
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local') middlewares.push(logger);
const store = createStore(appReducer, composeWithDevTools(applyMiddleware(...middlewares)));

const setUser = ({ user }) => ({
  type: 'LOGIN_SUCCESS',
  user,
});

const configureStore = () => {
  let user = localStorage.getItem('user');
  if (user) {
    try {
      user = JSON.parse(user);
      store.dispatch(setUser({ user }));
    } catch (e) {
      // test
    }
  }
  return store;
};

export default configureStore;
