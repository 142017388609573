import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

export default function InfosProfessionalExperienceViewer({ professionalExperience }) {
  const professionalExperiencesList = ({ companyName, positionTitle, duration }, i) => (
    <div key={i} style={{ display: 'flex', width: '100%' }}>
      {companyName && (
        <li className='w30'>
          <label>Entreprise</label>
          <div data-cy='companyName'>{companyName || 'Non renseignée'}</div>
        </li>
      )}
      {positionTitle && (
        <li className='w40'>
          <label>Poste</label>
          <div data-cy='positionTitle'>{positionTitle || 'Non renseignée'}</div>
        </li>
      )}
      {duration && (
        <li className='w30'>
          <label>Durée</label>
          <div data-cy='duration'>{ moment(duration.start).format('MM/YY') } - { duration.end ? moment(duration.end).format('MM/YY') : "Aujourd'hui" }</div>
        </li>
      )}
    </div>
  );

  return (
    <ul>
      {professionalExperience && professionalExperience.map((experience, i) => professionalExperiencesList(experience, i))}
    </ul>
  );
}

InfosProfessionalExperienceViewer.propTypes = {
  professionalExperience: PropTypes.arrayOf(PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    positionTitle: PropTypes.string.isRequired,
    duration: PropTypes.object.isRequired,
  })).isRequired,
};

