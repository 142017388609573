import React from 'react';

const Loading = () => (
  <div className='loading'>
    <div className='c-car-spinner'>
      <svg viewBox='0 0 80 80' className='c-car-spinner__svg'>
        <circle cx='40' cy='40' r='30' className='c-car-spinner__svg-circle' />
      </svg>
      <svg width='51px' className='c-car-spinner__svg-car' height='39px' viewBox='0 0 51 39' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g id='car-picture-icon' transform='translate(-16.000000, -19.000000)' stroke='#000000' strokeWidth='3'>
            <g id='Group' transform='translate(41.500000, 38.500000) rotate(-360.000000) translate(-41.500000, -38.500000) translate(18.000000, 21.000000)'>
              <polygon id='Stroke-4097' points='3.21917808 35 12.2328767 35 12.2328767 29.2727273 3.21917808 29.2727273' />
              <polygon id='Stroke-4098' points='35.4109589 35 44.4246575 35 44.4246575 29.2727273 35.4109589 29.2727273' />
              <polygon id='Stroke-4099' points='47 29.2727273 47 20.4909091 41.125 14.6363636 5.875 14.6363636 0 20.4909091 0 29.2727273' />
              <polygon id='Stroke-4100' points='35.303653 0 11.696347 0 5.79452055 14.6363636 41.2054795 14.6363636' />
              <path d='M0,23.5454545 L13.5205479,23.5454545' id='Stroke-4101' />
              <path d='M33.4794521,23.5454545 L47,23.5454545' id='Stroke-4102' />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
);

export default Loading;
