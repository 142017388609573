import React, { useState } from 'react';
import PropTypes from 'prop-types';

const InspectConcessSubMenu = ({
  onChangeStatus,
  role,
  inspectionsOrMissions,
  view = '',
}) => {
  const [active, setActive] = useState('ordered');
  const menuItems = [
    {
      label: 'En attente de validation',
      status: 'pending_validation',
    },
    {
      label: 'En attente',
      status: 'ordered',
    },
    {
      label: 'Programmées',
      status: 'scheduled',
    },
    {
      label: 'Réalisées',
      status: 'done',
    },
    {
      label: 'Annulées',
      status: 'canceled',
    },
    {
      label: 'Non présentées',
      status: 'no_show',
    },
    {
      label: 'Toutes',
      status: '',
    },
  ];

  if (view === 'ConcessionMissions') {
    menuItems.splice(0, 1); // Removes the second element => En attente de validation
    menuItems.splice(2, 0, { label: 'En cours', status: 'ongoing' }); // Insert new object after index 1
  }

  const handleClickActiveSubMenu = (menuItem) => {
    onChangeStatus(menuItem.status);
    setActive(menuItem.status);
  };

  const counterByStatus = (inspectionOrMissionsData, statusSelected) => inspectionOrMissionsData?.filter(({ status }) => statusSelected === status).length;

  return (
    <div className={`container-sub-menu concess ${role}`}>
      <div className="wrapper">
        <nav className="main__menu">
          <ul>
            {menuItems.map((menuItem, index) => (
              <li key={index}>
              <span className={active === menuItem.status ? 'active' : ''} onClick={() => handleClickActiveSubMenu(menuItem)}>
                {`${menuItem.label} ${menuItem.status !== '' ? `(${counterByStatus(inspectionsOrMissions, menuItem.status)})` : `(${inspectionsOrMissions.length})`} `}
              </span>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

InspectConcessSubMenu.propTypes = {
  onChangeStatus: PropTypes.func,
  view: PropTypes.string,
  role: PropTypes.bool,
  inspectionsOrMissions: PropTypes.array,
};

export default InspectConcessSubMenu;
