/* eslint-disable no-unused-vars, class-methods-use-this */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReportsActions from '../../actions/ReportsV3Actions';
import { filterSpecialCharactersRegex } from '../../helpers/regex';

const optionsCheckPointType = [
  { value: 'BoolCheckpoint', label: 'Checkbox' },
  { value: 'CommentCheckpoint', label: 'Commentaire' },
  { value: 'DateCheckpoint', label: 'Date' },
  { value: 'EnumCheckpoint', label: 'Select' },
  { value: 'NumberCheckpoint', label: 'Nombre' },
  { value: 'PhotoCheckpoint', label: 'Photo' },
  { value: 'DividerCheckpoint', label: 'Séparateur' },
  { value: 'TextCheckpoint', label: 'Texte' },
];

const optionsRuleType = [
  { value: 'alert', label: 'Pop-Up' },
  { value: 'warn', label: 'Commentaire' },
];

let optionsCategories = [];
let optionsCheckPointSpecialType = [];

class SearchTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      filterFacets: {
        category: [],
        kind: [],
        level: [],
        group: [],
        site: [],
        report: [],
      },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(ReportsActions.setFilterFacets(''));
    dispatch(ReportsActions.setFilterSearch(''));
    setTimeout(() => this.props.searchAction({ pageReset: true }), 20);
    this.getConstants();
  }

  getConstants = async () => {
    const cpConstants = this.props.reportsV3.constants;
    if (cpConstants) {
      optionsCategories = cpConstants.category.map((cat) => ({ value: _.get(cat, 'key', ''), label: _.get(cat, 'label', ''), _id: _.get(cat, '_id', '') }));
      optionsCheckPointSpecialType = cpConstants.specialType.map((obj) => ({ value: _.get(obj, 'key', ''), label: _.get(obj, 'label', ''), _id: _.get(obj, '_id', '') }));
    }
  };

  handleChange = (e) => {
    const { target } = e;
    const { value } = target;
    const searchValue = value.replace(filterSpecialCharactersRegex, '');

    if (this.state.search === searchValue) {
      return '';
    }

    this.setState({ search: searchValue });

    return searchValue;
  };

  handleSearch = async (e) => {
    const searchValue = this.handleChange(e);
    if (searchValue === '') return;
    const { dispatch } = this.props;
    await dispatch(ReportsActions.setFilterSearch(searchValue));
    this.props.searchAction({ pageReset: true });
  };

  handleSelect = (facets, obj) => {
    let facetsSearchString = '';
    const newFilterFacets = { ...this.state.filterFacets };
    newFilterFacets[`${obj.name}`] = facets;
    this.setState({ filterFacets: newFilterFacets }, async () => {
      const facet = Object.keys(this.state.filterFacets);
      _.forEach(facet, (theKey) => {
        _.mapValues(this.state.filterFacets[`${theKey}`], (prop) => {
          if (prop._id || prop.value) {
            facetsSearchString = facetsSearchString.concat(`&${theKey}=${prop._id || prop.value}`);
          }
        });
      });
      await this.props.dispatch(ReportsActions.setFilterFacets(facetsSearchString));
      this.props.searchAction({ pageReset: true });
    });
  };

  renderSelectFilter = (options) => {
    const filterOptions = {
      filterSites: {
        title: 'Sites',
        options,
        placeHolder: 'Sélectionnez un élement',
        search: 'site',
      },
      filterReports: {
        title: 'Rapports',
        options,
        placeHolder: 'Sélectionnez un élement',
        search: 'report',
      },
      filterGroups: {
        title: 'Groupes',
        options,
        placeHolder: 'Sélectionnez un élement',
        search: 'group',
      },
      filterRuleType: {
        title: 'Type de règle',
        options: optionsRuleType,
        placeHolder: 'Sélectionnez un élement',
        search: 'level',
      },
      filterCheckPointType: {
        title: 'Type de point de contrôle',
        options: optionsCheckPointType,
        placeHolder: 'Sélectionnez un élement',
        search: 'kind',
      },
      filterCategories: {
        title: 'Catégorie',
        options: optionsCategories,
        placeHolder: 'Sélectionnez un élement',
        search: 'category',
      },
    };
    const optionsToBeRendered = _.keys(filterOptions);
    const optionsArray = _.keys(options);
    const optionsValuesArray = _.values(options);
    return _.map(
      optionsArray,
      (option, i) =>
        optionsToBeRendered.includes(option) &&
        optionsValuesArray[i] === true && (
          <div className='search-item' key={i}>
            <label>{filterOptions[option].title}</label>
            <div className='search-input'>
              `
              <Select
                placeholder={filterOptions[option].placeHolder}
                className='multi-select'
                value={this.state.filterFacets[`${filterOptions[option].search}`]}
                name={filterOptions[option].search}
                onChange={this.handleSelect}
                options={filterOptions[option].options}
                isMulti={true}
              />
            </div>
          </div>
        ),
    );
  };

  render() {
    const { options = {} } = this.props;

    return (
      <div className='search-container'>
        {!options.hideSearch && (
          <div className='search-item'>
            <label>Recherche</label>
            <div className='search-input icon'>
              <i className='fa fa-search' />
              <DebounceInput type='text' value={this.state.search} onChange={this.handleSearch} debounceTimeout={300} placeholder='Recherchez...' />
            </div>
          </div>
        )}
        {this.renderSelectFilter(options)}
      </div>
    );
  }
}

SearchTable.propTypes = {
  searchAction: PropTypes.func,
  options: PropTypes.object,
  type: PropTypes.string,
  index: PropTypes.string,
  dispatch: PropTypes.func,
  reportsV3: PropTypes.object,
};

const mapStateToProps = (state) => ({
  reportsV3: state.reportsV3,
});

export default connect(mapStateToProps)(SearchTable);
