import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import NavigationActions from '../../actions/NavigationActions';
import { ExportExcelServices, notSentInspection } from '../../services/ExportExcelServices';
import NavBar from '../shared/templates/NavBar';

const ExcelExports = (props) => {
  const { addToast } = useToasts();
  const [selectedExport, setSelectedExport] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [emails, setEmail] = useState('');
  const [days, setDays] = useState(0);
  const { TabPane } = Tabs;

  useEffect(() => {
    props.dispatch(NavigationActions.setNavigation({ navigation: 'excelExports' }));
  }, []);

  const exportOptions = [
    { label: 'Récap Inspections MSA - Programmées non-envoyées', value: 'notSentInspection' },
    { label: 'Récap Inspections MSA - Réalisées', value: 'plannedInspectionRecap' },
    { label: 'Récap Inspections MSA - Tous status', value: 'allPlannedInspectionRecap' },
    { label: 'Récap Inspections MSA - Annulées', value: 'canceledInspectionRecap' },
    { label: 'Récap Inspections AutoInspect', value: 'onDemandInspectionRecap' },
    { label: 'Récap Factures', value: 'billingRecap' },
  ];

  function onSelectedExport(e) {
    setSelectedExport(e);
  }

  function getStartDate(e) {
    setStartDate(e.target.value);
  }

  function getEndDate(e) {
    setEndDate(e.target.value);
  }

  function emailTo(e) {
    setEmail(e.target.value);
  }

  function sendRelativeDate(e) {
    switch (e.target.value) {
      case '7Days':
        setDays(7);
        break;
      case '1Month':
        setDays(31);
        break;
      case '3Months':
        setDays(92);
        break;
      case '9Months':
        setDays(275);
        break;
      case '1Year':
        setDays(365);
        break;
      case '2Years':
        setDays(730);
        break;
      default:
        setDays(0);
    }
  }

  function toastNotification(sentence, appearance, timeout) {
    addToast(sentence, { appearance, autoDismiss: true, autoDismissTimeout: timeout });
  }
  let response;

  async function onSubmitAbs(e) {
    e.preventDefault();
    const queryObjectAbs = {
      startDate,
      endDate,
      emails,
    };

    if ((startDate === '' || endDate === '') && selectedExport.value !== 'notSentInspection') {
      toastNotification('Rajouter les dates manquantes', 'error', 3000);
    }
    if (!emails) {
      toastNotification('Rajouter une adresse mail', 'error', 3000);
    }
    if (!selectedExport.value) {
      toastNotification("Veuillez choisir un type d'export", 'error', 3000);
    }

    if (startDate > endDate && selectedExport.value !== 'notSentInspection') {
      toastNotification('La date de début ne peut être antérieure à la date de début', 'error', 3000);
    }
    if (selectedExport.value === 'notSentInspection' && emails) {
      response = await notSentInspection(-1, emails);
      if (response.status === 200) {
        toastNotification('Export généré avec succès', 'success', 2000);
      } else {
        toastNotification('Erreur lors de la génération', 'error', 2000);
      }
    }
    if (startDate < endDate && selectedExport.value && selectedExport.value !== 'notSentInspection' && emails) {
      response = await ExportExcelServices(queryObjectAbs, 'abs', selectedExport.value);
      if (response.status === 200) {
        toastNotification('Export généré avec succès', 'success', 2000);
      }
      if (response.status === 204 && selectedExport.value === 'billingRecap') {
        toastNotification('Pas de factures disponibles a ces dates', 'error', 3000);
      }
      if (response.status !== 200) {
        toastNotification('Erreur lors de la génération', 'error', 2000);
      }
    }
  }

  async function onSubmitRel(e) {
    e.preventDefault();
    const queryObjectRel = {
      emails,
      days,
      offset: -1,
    };

    if (days === 0) {
      toastNotification('Choisir une date', 'error', 3000);
    }
    if (!emails) {
      toastNotification('Rajouter une adresse mail', 'error', 3000);
    }
    if (!selectedExport.value) {
      toastNotification("Veuillez choisir un type d'export", 'error', 3000);
    }

    if (selectedExport.value === 'notSentInspection' && emails) {
      response = await notSentInspection(-1, emails);
      if (response.status === 200) {
        toastNotification('Export généré avec succès', 'success', 2000);
      } else {
        toastNotification('Erreur lors de la génération', 'error', 2000);
      }
    }

    if (days !== 0 && selectedExport.value !== 'notSentInspection') {
      response = await ExportExcelServices(queryObjectRel, 'rel', selectedExport.value);
      if (response.status === 200) {
        toastNotification('Export généré avec succès', 'success', 2000);
      } else {
        toastNotification('Erreur lors de la génération', 'error', 2000);
      }
    }
  }

  return (
    <React.Fragment>
      <NavBar />
      <div className='concession mtp'>
        <div className='filtersLogs'>
          <Row center='xs'>
            <Col xs={12}>
              <p className='mainTitle'>EXPORTS EXCEL</p>
              <Tabs defaultActiveKey='1' centered>
                <TabPane tab='Dates absolues' key='1'>
                  <form className='formInput' onSubmit={(e) => onSubmitAbs(e)}>
                    <Select placeholder="Type de l'export" className='multi-select' isClearable onChange={onSelectedExport} options={exportOptions} />
                    <hr style={{ marginTop: 20 }}></hr>
                    <div className='formInput'>
                      <label style={{ marginTop: 20 }}>date début</label>
                      <input type='date' name='date' defaultValue={startDate} onChange={(e) => getStartDate(e)} />
                      <label style={{ marginTop: 20 }}>date fin</label>
                      <input type='date' name='date' defaultValue={endDate} onChange={(e) => getEndDate(e)} />
                    </div>
                    <hr style={{ marginTop: 20 }}></hr>
                    <label style={{ marginTop: 20 }}>Destinataire</label>
                    <input type='email' placeholder={'Email'} onChange={(e) => emailTo(e)} />
                    <div className='center formButton' style={{ marginTop: 20, paddingLeft: 100, paddingRight: 100 }}>
                      <button type='submit' className='positiv'>
                        Valider
                      </button>
                    </div>
                  </form>
                </TabPane>
                <TabPane tab='Dates relatives' key='2'>
                  <form className='formInput' onSubmit={(e) => onSubmitRel(e)}>
                    <Select placeholder="Type de l'export" className='multi-select' isClearable onChange={onSelectedExport} options={exportOptions} />
                    <hr style={{ marginTop: 20 }}></hr>
                    <Row style={{ marginTop: 20 }}>
                      <Col style={{ margin: 10 }}>
                        <input name='relativeDates' value='7Days' type='radio' onClick={(e) => sendRelativeDate(e)} />
                        <label>-7 jours</label>
                      </Col>
                      <Col style={{ marginTop: 10 }}>
                        <input name='relativeDates' value='1Month' type='radio' onClick={(e) => sendRelativeDate(e)} />
                        <label style={{ marginInline: 20 }}>-1 mois</label>
                      </Col>
                      <Col style={{ marginTop: 10 }}>
                        <input name='relativeDates' value='3Months' type='radio' onClick={(e) => sendRelativeDate(e)} />
                        <label style={{ marginInline: 20 }}>-3 mois</label>
                      </Col>
                      <Col style={{ marginTop: 10 }}>
                        <input name='relativeDates' value='9Months' type='radio' onClick={(e) => sendRelativeDate(e)} />
                        <label style={{ marginInline: 20 }}>-9 mois</label>
                      </Col>
                      <Col style={{ marginTop: 10 }}>
                        <input name='relativeDates' value='1Year' type='radio' onClick={(e) => sendRelativeDate(e)} />
                        <label style={{ marginInline: 20 }}>-1 an</label>
                      </Col>
                      <Col style={{ marginTop: 10 }}>
                        <input name='relativeDates' value='2Years' type='radio' onClick={(e) => sendRelativeDate(e)} />
                        <label style={{ marginInline: 20 }}>-2 ans</label>
                      </Col>
                    </Row>
                    <hr style={{ marginTop: 20 }}></hr>
                    <label style={{ marginTop: 20 }}>Destinataire</label>
                    <input type='email' placeholder={'Email'} onChange={(e) => emailTo(e)} />
                    <div className='center formButton' style={{ marginTop: 20, paddingLeft: 100, paddingRight: 100 }}>
                      <button type='submit' className='positiv'>
                        Valider
                      </button>
                    </div>
                  </form>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  application: state.application,
});

ExcelExports.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
};

export default connect(mapStateToProps)(ExcelExports);
