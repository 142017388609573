import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UsersActions from '../../actions/UsersActions';

class LoginPage extends React.Component {
  state = {
    email: '',
    password: '',
    error: null,
    showPassword: false,
  };

  // componentDidMount() {
  //   console.log(this.props ? this.props : 'no props');
  // }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps.application.loginError, this.props.application.loginError);
    this.setState({
      error: nextProps.application.loginError,
    });
  }

  setValueOfPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  setValueOfEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  setValueOfShowPassword = (e) => {
    this.setState({ showPassword: e.target.checked });
  };

  handleLoginProcess = (e) => {
    // Make api call
    this.setState({ error: null });
    e.preventDefault();
    e.stopPropagation();
    const { dispatch } = this.props;
    UsersActions.login({ email: this.state.email, password: this.state.password })(dispatch);
  };

  renderError = () => {
    let toRender = null;
    if (this.state.error) {
      toRender = (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return toRender;
  };

  render() {
    return (
      <div className='loginPage'>
        <div className='logo_img'></div>
        <h1 className='logo'>
          Mon<span>Specialiste</span>Auto.com
        </h1>
        <div className='helper'>Connectez-vous</div>
        <form onSubmit={this.handleLoginProcess}>
          <div className='formInput'>
            <label>Email</label>
            <input data-cy='email' type='email' value={this.state.email} onChange={this.setValueOfEmail} required />
          </div>
          <div className='formInput'>
            <label>Mot de passe</label>
            <input data-cy="password" type={this.state.showPassword ? 'text' : 'password'} value={this.state.password} onChange={this.setValueOfPassword} required />
          </div>
          <div className=''>
            <input type='checkbox' onChange={this.setValueOfShowPassword} />
            {this.state.showPassword ? 'Masquer' : 'Voir'} le mot de passe
          </div>
          {this.renderError()}
          <div className='formButton'>
            <button className='positiv'>
              {this.props.application.loginInProgress ? (
                <span>
                  <i className='fa fa-spinner fa-pulse'></i> Connexion en cours
                </span>
              ) : (
                'Connexion'
              )}
            </button>
          </div>
          <div className='formButton'>
            <Link to='/premiereconnexion'>
              <button className='orange'>Première connexion</button>
            </Link>
          </div>
        </form>
        <div className='linkContainer'>
          <Link to='/motdepasseoublie'>J'ai oublié mon mot de passe</Link>
        </div>
      </div>
    );
  }
}
//  <Link to='/inscription'>Créer un compte professionnel</Link>
const mapStateToProps = (state) => ({ application: state.application });

LoginPage.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
};

export default connect(mapStateToProps)(LoginPage);
