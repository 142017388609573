const makeRequest = (opts = {}, onProgress, uploadProgress) =>
  new Promise((resolve, reject) => {
    const { url } = opts;
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    xhr.withCredentials = true;
    if (opts && opts.headers) {
      const keys = Object.keys(opts.headers);
      for (let i = 0; i < keys.length; i += 1) {
        xhr.setRequestHeader(keys[i], opts.headers[keys[i]]);
      }
    }
    xhr.onload = (e) => {
      let { response } = e.target;
      // on parse la reponse si c'est un json
      if (typeof response === 'string') {
        try {
          response = JSON.parse(response);
        } catch (err) {
          // do something with err
        }
      }
      resolve({
        status: e.target.status,
        body: response,
      });
    };
    xhr.onerror = (error) => {
      reject(new Error(error.target.responseText));
    };
    xhr.onloadStart = () => {};
    if (onProgress) {
      xhr.onprogress = onProgress;
    }
    if (uploadProgress) {
      xhr.upload.onprogress = onProgress;
    }
    xhr.send(opts.body);
  });

export default makeRequest;
