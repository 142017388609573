import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavBar from '../../shared/templates/NavBar';
import NavigationActions from '../../../actions/NavigationActions';
import BillingsContainer from '../templates/BillingsContainer';

const BillingsPage = ({ dispatch }) => {
  useEffect(() => {
    dispatch(NavigationActions.setNavigation({ navigation: 'billings' }));
  }, []);

  return (
    <>
      <NavBar />
      <div className='appContainer map'>
        <BillingsContainer adminInterface />
      </div>
    </>
  );
};

BillingsPage.propTypes = {
  dispatch: PropTypes.func,
  adminInterface: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(null, mapDispatchToProps)(BillingsPage);
