import _ from 'lodash';

export const rowsToUpdate = (newValue, oldValue) =>
  _.compact(
    newValue.map((ins) => {
      const changed = oldValue.find((oldIns) => ins._id === oldIns._id && ins.updatedAt !== oldIns.updatedAt);
      if (changed) {
        return ins;
      }
      return null;
    }),
  );

// export all columns except those with the notExported property
export const selectColumnsToExport = (columnDefs) => columnDefs.filter((column) => !column.notExportable).map((column) => column.field);
