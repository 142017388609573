import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import _ from 'lodash';

import InspectionsServices from '../../../services/InspectionsServices';
import AdminsActions from '../../../actions/AdminsActions';

const ModalCustomSMS = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [smsText, setSmsText] = useState('');
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setErrorMessage('');
    setSmsText(
      `Bonjour\n\nNous vous contactons de la part de ${_.get(props, 'inspection.entity.name')} pour programmer un RDV pour l'inspection de votre ${_.get(
        props,
        'inspection.vehicle.brand',
      )} ${_.get(
        props,
        'inspection.vehicle.model',
      )}.\n\nMerci de nous recontacter au 01 86 65 20 43 pour nous transmettre vos disponibilités.\n\nA très vite,\nMon Specialiste Auto\n`,
    );
  };

  const sendSMS = async () => {
    try {
      setLoading(true);
      setErrorMessage('');
      const results = await InspectionsServices.sendCustomSMS({
        inspectionId: _.get(props, 'inspection._id'),
        text: smsText,
      });
      setLoading(false);

      if (results.status === 200) {
        props.onRequestClose({});
        AdminsActions.notify({
          type: 'NOTIFY',
          isError: false,
          message: 'Le sms a été envoyé',
        })(props.dispatch);
      } else {
        setErrorMessage(results.body.error || '');
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage("Une erreur inconnue s'est produite");
    }
  };

  useEffect(() => {
    resetForm();
  }, [_.get(props, 'inspection._id')]);

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={props.isOpen}
      onRequestClose={() => props.onRequestClose({})}
      contentLabel='Modal annuler inspection'
      overlayClassName='modal-overlay'
      className='modal modalInspectionBan'>
      <div className='formTitle'>Envoi d'un SMS personnalisé au vendeur</div>

      <p>
        Destinataire : {_.get(props, 'inspection.seller.name')} - {_.get(props, 'inspection.seller.phone')}
      </p>

      <div className='formInput' style={{ marginBottom: '10px' }}>
        <label style={{ lineHeight: '10px' }}>SMS pour le vendeur</label>
        <textarea rows='14' type='text' name='smsText' value={smsText} placeholder='Votre sms' onChange={(e) => setSmsText(e.target.value)} />
      </div>
      {errorMessage && (
        <div className='error'>
          <p>{errorMessage}</p>
        </div>
      )}
      <div className='center formButton mt20px'>
        <button type='button' onClick={() => props.onRequestClose({})} className='negativ'>
          Annuler
        </button>
        <button type='button' onClick={sendSMS} className='positiv' disabled={loading}>
          {loading && <i className='fa fa-spinner fa-pulse' />}
          {!loading && <span>Envoyer</span>}
        </button>
      </div>
    </Modal>
  );
};

ModalCustomSMS.propTypes = {
  dispatch: PropTypes.func,
  inspection: PropTypes.object,
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(null, mapDispatchToProps)(ModalCustomSMS);
