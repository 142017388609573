import React from 'react';
import { InfiniteLoader, List } from 'react-virtualized';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Tooltip } from 'antd';

const MsaElementList = ({
  /** Are there more items to load? (This information comes from the most recent API request.) */
  hasNextPage,
  /** Are we currently loading a page of items? (This may be an in-flight flag in your Redux store for example.) */
  isNextPageLoading,
  /** List of items loaded so far */
  list,
  /** Callback function (eg. Redux action-creator) responsible for loading the next page of items */
  loadNextPage,
  /**  Adds Checkpoint to the chosen section */
  handleAddItem,
  /**  The type of this list, is used for helper title etc */
  type,
  width, // optional div width
}) => {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const rowCount = hasNextPage ? list.length + 1 : list.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreRows = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isRowLoaded = ({ index }) => !hasNextPage || index < list.length;

  // Render a list item or a loading indicator.
  const rowRenderer = ({ index, key, style }) => {
    let content;
    let titleText;

    if (!isRowLoaded({ index })) {
      content = 'Loading...';
    } else if (type === 'checkpoint') {
      content = _.get(list[index], 'label').concat(' - ').concat(_.get(list[index], 'description', ''));
      titleText = 'Ajouter Point de Contrôle au Rapport';
    } else if (type === 'msaCarPart') {
      content = _.get(list[index], 'label');
      titleText = 'Ajouter une pièce auto';
    } else if (type === 'cupiCarPart') {
      content = _.get(list[index], 'labelMsa') ? `${_.get(list[index], 'labelMsa')} / ${_.get(list[index], 'labelGt')}` : _.get(list[index], 'labelGt');
      titleText = 'Ajouter une pièce CUPI';
    } else {
      content = 'List type not specified';
    }

    return (
      <div key={key} style={style} className='SortableItem infinite'>
        {content}
        {content !== 'Loading...' && (
          <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 1300 }} title={titleText}>
            <button className='cypress__tooltip-checkpoint btnIcon add' onClick={(e) => handleAddItem(e, list[index])}>
              <i data-cy={`plus-button-${type}`} className='fas fa-plus-square' />
            </button>
          </Tooltip>
        )}
      </div>
    );
  };

  rowRenderer.propTypes = {
    index: PropTypes.number,
    key: PropTypes.string,
    style: PropTypes.object,
  };

  return (
    <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={rowCount} threshold={1}>
      {({ onRowsRendered, registerChild }) => (
        <List
          ref={registerChild}
          onRowsRendered={onRowsRendered}
          rowRenderer={rowRenderer}
          height={300}
          width={width || 500}
          rowHeight={40}
          rowCount={list.length + (hasNextPage ? 1 : 0)}
          className='InfiniteList'
        />
      )}
    </InfiniteLoader>
  );
};

MsaElementList.propTypes = {
  hasNextPage: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
  loadNextPage: PropTypes.func,
  handleAddItem: PropTypes.func,
  list: PropTypes.array,
  width: PropTypes.number,
  type: PropTypes.string,
};
export default MsaElementList;
