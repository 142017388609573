import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import AdminsActions from '../../../actions/AdminsActions';
import UsersActions from '../../../actions/UsersActions';
import GooglePlacesAutoComplete from '../atoms/GooglePlacesAutoComplete';

class EditUser extends React.Component {
  state = {};

  updateUser = async () => {
    const { userEdited } = this.props.application;
    let result = false;

    if (userEdited._id) {
      result = await UsersActions.updateUser({
        _id: userEdited._id,
        firstname: userEdited.firstname,
        lastname: userEdited.lastname,
        companyName: userEdited.companyName,
        phone: userEdited.phone,
        fromAdmin: true,
        email: userEdited.email,
        isActive: userEdited.isActive,
        lng: userEdited.lng,
        lat: userEdited.lat,
        status: userEdited.status,
        dateOfBirth: userEdited.dateOfBirth,
        mailCopies: userEdited.mailCopies,
        diplomaLevel: userEdited.diplomaLevel,
        city: userEdited.city,
        comment: userEdited.comment,
        isSpecialist: userEdited.isSpecialist,
        isAdmin: userEdited.isAdmin,
        invoicePeriod: userEdited.invoicePeriod,
        concessionAccount: userEdited.concessionAccount,
      })(this.props.dispatch);
    } else {
      // eslint-disable-next-line no-console
      console.log("L'utilisateur en question n'a pas de APIKey ou ID", userEdited);

      if (userEdited.isAdmin) {
        result = await UsersActions.createUser({
          firstname: userEdited.firstname,
          lastname: userEdited.lastname,
          companyName: userEdited.companyName,
          password: userEdited.password,
          phone: userEdited.phone,
          fromAdmin: true,
          comment: userEdited.comment,
          email: userEdited.email,
          isActive: userEdited.isActive,
          lng: userEdited.lng,
          lat: userEdited.lat,
          dateOfBirth: userEdited.dateOfBirth,
          diplomaLevel: userEdited.diplomaLevel,
          mailCopies: userEdited.mailCopies,
          city: userEdited.city,
          isSpecialist: userEdited.isSpecialist,
          isAdmin: userEdited.isAdmin,
          concessionAccount: userEdited.concessionAccount,
        })(this.props.dispatch);
      } else {
        alert("Impossible de modifier l'utilisateur");
      }
    }
    if (result) {
      let type = '';
      if (this.props.application.userEdited.isSpecialist) {
        type = this.props.admin.activeMenuFilters.specialist || this.props.admin.optionsFilters.specialist[0].menuSelector;
      }
      if (this.props.application.userEdited.isAdmin) {
        type = this.props.admin.activeMenuFilters.admin || this.props.admin.optionsFilters.admin[0].menuSelector;
      }
      // console.log('type', type);
      this.props.dispatch(
        AdminsActions.getActionToDispatch({
          type,
        }),
      );
      this.closeModalEditInfos();
    }
  };

  fillUserToEdit = (type, e) => {
    const params = {};
    params[type] = e.target.value;
    this.props.dispatch(
      UsersActions.getActionToDispatch({
        type: 'SET_DATA_TO_EDIT',
        data: params,
      }),
    );
  };

  fillUserToEditCheckbox = (type, e) => {
    const params = {};
    params[type] = e.target.checked;
    this.props.dispatch(
      UsersActions.getActionToDispatch({
        type: 'SET_DATA_TO_EDIT',
        data: params,
      }),
    );
  };

  closeModalEditInfos = () => {
    this.props.dispatch(UsersActions.getActionToDispatch({ type: 'RESET_EDIT_USER_INFOS' }));
    this.props.dispatch(UsersActions.getActionToDispatch({ type: 'HIDE_MODAL_EDIT_USER_INFOS' }));
  };

  renderError() {
    if (this.props.application.updateEditUserError) {
      return (
        <div className='error'>
          <p>{this.props.application.updateEditUserError}</p>
        </div>
      );
    }
    return null;
  }

  setAddress = (data) => {
    const { lng, lat, address } = data;
    const newData = {
      lng,
      lat,
      city: address,
    };
    this.props.dispatch(
      UsersActions.getActionToDispatch({
        type: 'SET_DATA_TO_EDIT',
        data: newData,
      }),
    );
  };

  renderPassword = () => {
    if (!this.props.application.userEdited._id) {
      return (
        <div className='formInput'>
          <label>Mot de passe</label>
          <input type='text' value={this.props.application.userEdited.password} placeholder='Mot de passe' onChange={this.fillUserToEdit.bind(this, 'password')} />
        </div>
      );
    }
    return null;
  };

  renderSpecialistPart() {
    if (this.props.application.userEdited.isSpecialist) {
      return (
        <React.Fragment>
          <div className='formInput'>
            <label>Date de naissance</label>
            <input type='text' value={this.props.application.userEdited.dateOfBirth} placeholder='13-02-2018' onChange={this.fillUserToEdit.bind(this, 'dateOfBirth')} />
          </div>
          <div className='formInput'>
            <label>Diplôme</label>
            <input type='text' value={this.props.application.userEdited.diplomaLevel} placeholder='BAC, CEP, brevet' onChange={this.fillUserToEdit.bind(this, 'diplomaLevel')} />
          </div>
          <GooglePlacesAutoComplete
            placeholder={'Ville'}
            label={'Ville'}
            init={
              this.props.application.userEdited._id
                ? {
                    address: this.props.application.userEdited.city,
                    lng: this.props.application.userEdited.lng,
                    lat: this.props.application.userEdited.lat,
                  }
                : null
            }
            onChange={this.setAddress}
          />
        </React.Fragment>
      );
    }
    return null;
  }

  renderValidButtonText = () => {
    if (this.props.application.loadingUpdate) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }
    return 'Sauvegarder';
  };

  render() {
    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={this.props.application.showModalEditUserInfos}
        onRequestClose={this.closeModalEditInfos}
        contentLabel='Modal edition des informations'
        overlayClassName='modal-overlay'
        className='modal modalEditUserInfos'
      >
        <div className='modalContent '>
          <div className='mainTitle'>
            {this.props.application.userEdited._id ? 'Modification ' : 'Ajout '}
            {this.props.application.userEdited.isSpecialist ? `d'un spécialiste` : ''}
            {this.props.application.userEdited.isAdmin ? `d'un administrateur` : ''}
          </div>
          <form className='mt10px'>
            <div className='formInput'>
              <label>Nom</label>
              <input type='text' value={this.props.application.userEdited.lastname} placeholder='Nom' onChange={this.fillUserToEdit.bind(this, 'lastname')} />
            </div>
            <div className='formInput'>
              <label>Prénom(s)</label>
              <input type='text' value={this.props.application.userEdited.firstname} placeholder='Prénom(s)' onChange={this.fillUserToEdit.bind(this, 'firstname')} />
            </div>
            <div className='formInput'>
              <label>Téléphone</label>
              <input type='text' value={this.props.application.userEdited.phone || ''} placeholder='Ex : 06125637623' onChange={this.fillUserToEdit.bind(this, 'phone')} />
            </div>
            <div className='formInput'>
              <label>Commentaires</label>
              <textarea
                type='text'
                value={this.props.application.userEdited.comment}
                placeholder="Commentaire sur l'utilisateur"
                onChange={this.fillUserToEdit.bind(this, 'comment')}
              ></textarea>
            </div>
            <div className='formInput'>
              <label>Email</label>
              <input type='email' value={this.props.application.userEdited.email} placeholder='Ex: john@doe.com' onChange={this.fillUserToEdit.bind(this, 'email')} />
            </div>
            {this.renderPassword()}
            {this.renderSpecialistPart()}
            {this.renderError()}
            <div className='center formButton'>
              <button type='button' onClick={this.closeModalEditInfos} className='negativ'>
                Annuler
              </button>
              <button type='button' onClick={this.updateUser.bind(this)} className='positiv' disabled={this.props.application.loadingUpdate}>
                {this.renderValidButtonText()}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

EditUser.propTypes = {
  dispatch: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
};

export default EditUser;
