import React from 'react';
import { Link } from 'react-router-dom';

const NoMatchPage = () => (
  <div className='notfound'>
    <h1 className='logo'>
      Mon<span>Specialiste</span>Auto.com
    </h1>
    <div>
      <img src='https://res.cloudinary.com/monspecialisteauto-com/image/upload/v1521358792/car-breakdown.png' />
    </div>
    <div>Oups...Vous êtes en panne ?</div>
    <p>La page demandée n'existe plus ou a été déplacée.</p>
    <div>
      <Link to='/'>Retour à l'accueil</Link>
    </div>
  </div>
);

export default NoMatchPage;
