const applicationReducerDefaultState = {
  activeSession: false,
  isLogged: false,
  loginInProgress: false,
  loggedAt: undefined,
  user: undefined,
  loginError: undefined,
  activeMenu: 'inspection',
  loadingBooking: false,
  loadingInspections: false,
  loadingUpdate: false,
  loadingType: false,
  loadingTypeError: false,
  bookingError: null,
  updateUserError: null,
  updateEditUserError: null,
  verifyEmailError: null,
  showModalEditInfos: false,
  showModalApiInfos: false,
  showModalEditUserInfos: false,
  verifyStep: 1,
  isSearch: false,
  userEdited: {
    oldPassword: '',
    password: '',
    firstname: '',
    lastname: '',
    email: '',
    address: '',
    lng: 0,
    lat: 0,
    city: '',
    phone: '',
    isActive: false,
    companyName: '',
    dateOfBirth: '',
    diplomaLevel: '',
    emailVerified: false,
    isSpecialist: false,
    isAdmin: false,
  },
  activeMenuFilters: {
    fraisVo: 'Citadine',
  },
  optionsFilters: {
    fraisVo: [
      {
        menuSelector: 'Citadine',
        value: 'Citadine',
        icon: null,
      },
      {
        menuSelector: 'Berline',
        value: 'Berline',
        icon: null,
      },
      {
        menuSelector: 'SUV',
        value: 'SUV',
        icon: null,
      },
      {
        menuSelector: 'Monospace',
        value: 'Monospace',
        icon: null,
      },
    ],
    admin: [
      {
        menuSelector: 'users',
        value: 'Utilisateurs',
        icon: null,
      },
      {
        menuSelector: 'concessions',
        value: 'Sites',
        icon: null,
      },
      {
        menuSelector: 'entities',
        value: 'Groupes',
        icon: null,
      },
      {
        menuSelector: 'inspections',
        value: 'AutoInspect',
        icon: null,
      },
    ],
    reportsv3: [
      {
        menuSelector: 'fre',
        value: 'FRE',
        icon: null,
      },
      {
        menuSelector: 'technicalCost',
        value: 'Barème FRE / technicité',
        icon: null,
      },
    ],
    reportsv3Admin: [
      {
        menuSelector: 'points',
        value: 'Points de contrôle',
        icon: null,
      },
      {
        menuSelector: 'regles',
        value: 'Règles métier',
        icon: null,
      },
      {
        menuSelector: 'rapports',
        value: 'Rapports',
        icon: null,
      },
      {
        menuSelector: 'fre',
        value: 'FRE',
        icon: null,
      },
      {
        menuSelector: 'technicalCost',
        value: 'Barème FRE / technicité',
        icon: null,
      },
    ],
    referentiels: [
      {
        menuSelector: 'msaCarParts',
        value: 'Pièces auto MSA',
        icon: null,
      },
      {
        menuSelector: 'cupiCarParts',
        value: 'Pièces auto CUPI',
        icon: null,
      },
      {
        menuSelector: 'defectTypologies',
        value: 'Typologies de défauts',
        icon: null,
      },
      {
        menuSelector: 'labelTranslations',
        value: 'Traductions',
        icon: null,
      },
    ],
    reseller: [
      {
        menuSelector: 'msa',
        value: 'Inspections MSA',
        icon: null,
      },
      {
        menuSelector: 'concession',
        value: 'AutoInspect',
        icon: null,
      },
    ],
  },
  filters: {
    status: 'en attente',
    sort: 'createdAt',
    asc: 1,
    perimeter: 50,
    skip: 0,
  },
};

const applicationReducer = (state = applicationReducerDefaultState, action) => {
  // console.log('Got actions', action);
  switch (action.type) {
    case 'UPDATE_APP_VERSIONS': {
      return {
        ...state,
        versions: action.versions,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        loginInProgress: true,
      };
    }
    case 'LOGIN_SUCCESS': {
      return {
        ...state,
        activeSession: true,
        isLogged: true,
        user: action.user,
        loginInProgress: false,
      };
    }
    case 'LOGIN_ERROR': {
      return {
        ...state,
        activeSession: false,
        isLogged: false,
        loginError: action.error,
        loginInProgress: false,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        activeSession: false,
        isLogged: false,
      };
    }
    case 'SERVER_KILLED_SESSION': {
      return {
        ...state,
        activeSession: false,
        isLogged: false,
      };
    }
    case 'SET_USER': {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    }
    case 'SET_NAVIGATION': {
      return {
        ...state,
        activeMenu: action.navigation || 'inspection',
      };
    }
    case 'LOADING_INSPECTION_REFRESHING': {
      return {
        ...state,
        loadingInspections: true,
      };
    }
    case 'LOADING_INSPECTION_SUCCESS': {
      return {
        ...state,
        loadingInspections: false,
      };
    }
    case 'LOADING_INSPECTION_ERROR': {
      return {
        ...state,
        loadingInspections: false,
        bookingError: action.error,
      };
    }
    case 'SET_FILTERS': {
      return {
        ...state,
        filters: Object.assign(state.filters, action.filters),
        isSearch: action.isSearch || false,
      };
    }
    case 'RESET_FILTERS': {
      // console.log('Les filtres par default', action.filters);
      return { ...state, filters: action.filters };
      // return {
      //   ...state,
      //   filters: {
      //     ...applicationReducerDefaultState.filters,
      //   },
      //   isSearch: action.isSearch || false,
      // };
    }
    case 'LOADING_FILTERS': {
      return {
        ...state,
        loadingFilters: action.loadingFilters,
      };
    }
    case 'SHOW_MODAL_CUSTOM_SMS': {
      return {
        ...state,
        showModalCustomSMS: true,
        inspectionId: action.inspectionId,
        data: action.data,
      };
    }
    case 'HIDE_MODAL_CUSTOM_SMS': {
      return {
        ...state,
        showModalCustomSMS: false,
      };
    }
    case 'SHOW_MODAL_EDIT_INFOS': {
      return {
        ...state,
        showModalEditInfos: true,
      };
    }
    case 'SHOW_MODAL_EDIT_USER_INFOS': {
      return {
        ...state,
        showModalEditUserInfos: true,
      };
    }
    case 'HIDE_MODAL_EDIT_INFOS': {
      return {
        ...state,
        showModalEditInfos: false,
      };
    }
    case 'HIDE_MODAL_EDIT_USER_INFOS': {
      return {
        ...state,
        showModalEditUserInfos: false,
      };
    }
    case 'SET_UPDATE_LOADING': {
      return {
        ...state,
        loadingUpdate: action.state,
      };
    }
    case 'SHOW_MODAL_RESEND': {
      return {
        ...state,
        showModalResend: true,
        inspectionId: action.inspectionId,
      };
    }
    case 'HIDE_MODAL_RESEND': {
      return {
        ...state,
        showModalResend: false,
        inspectionId: null,
      };
    }
    case 'SET_DATA_TO_EDIT': {
      return {
        ...state,
        userEdited: {
          ...state.userEdited,
          ...action.data,
        },
      };
    }
    case 'RESET_EDIT_INFOS': {
      return {
        ...state,
        showModalEditInfos: false,
        updateUserError: false,
        updateEditUserError: false,
        userEdited: {
          ...applicationReducerDefaultState.userEdited,
        },
      };
    }
    case 'RESET_EDIT_USER_INFOS': {
      return {
        ...state,
        showModalEditUserInfos: false,
        updateUserError: false,
        updateEditUserError: false,
        userEdited: {
          ...applicationReducerDefaultState.userEdited,
        },
      };
    }
    case 'UPDATE_USER_EDIT_ERROR': {
      return {
        ...state,
        updateEditUserError: action.error,
      };
    }
    case 'UPDATE_ERROR': {
      return {
        ...state,
        updateUserError: action.error,
      };
    }
    case 'SET_VERIFY_STEP': {
      return {
        ...state,
        verifyStep: action.step,
      };
    }
    case 'VERIFY_ERROR': {
      return {
        ...state,
        verifyEmailError: action.error,
      };
    }
    case 'PREPARE_USER_TO_EDIT': {
      // console.log('data', action.data);
      return {
        ...state,
        userEdited: {
          _id: action.data._id || null,
          firstname: action.data.firstname || '',
          lastname: action.data.lastname || '',
          email: action.data.email || '',
          lng: action.data.lng || 0,
          lat: action.data.lat || 0,
          city: action.data.city || '',
          phone: action.data.phone || '',
          isActive: action.data.isActive || false,
          companyName: action.data.companyName || '',
          dateOfBirth: action.data.dateOfBirth || '',
          diplomaLevel: action.data.diplomaLevel || '',
          emailVerified: action.data.emailVerified || false,
          isSpecialist: action.data.isSpecialist || false,
          isAdmin: action.data.isAdmin || false,
          comment: action.data.comment || '',
          invoicePeriod: action.data.invoicePeriod,
          mailCopies: action.data.mailCopies || '',
        },
      };
    }
    case 'SET_USER_TO_EDIT': {
      return {
        ...state,
        userEdited: {
          firstname: state.user ? state.user.firstname || '' : '',
          lastname: state.user ? state.user.lastname || '' : '',
          companyName: state.user ? state.user.companyName || '' : '',
          phone: state.user ? state.user.phone || '' : '',
          webhookUrl: state.user ? state.user.webhookUrl || '' : '',
          mailCopies: state.user ? state.user.mailCopies || '' : '',
        },
      };
    }
    case 'SET_LOADING_TYPE_ERROR': {
      return {
        ...state,
        loadingTypeError: action.error,
      };
    }
    case 'SET_LOADING_TYPE': {
      return {
        ...state,
        loadingType: action.value,
      };
    }
    default:
      return state;
  }
};

export default applicationReducer;
