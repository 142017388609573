import UsersServices from '../services/UsersServices';

const getActionToDispatch = (params) => {
  switch (params.type) {
    case 'LOGIN_BEGIN': {
      return {
        type: 'LOGIN',
        time: new Date(),
        email: params.email,
      };
    }
    case 'LOGIN_SUCCESS': {
      return {
        type: 'LOGIN_SUCCESS',
        user: params.user,
      };
    }
    case 'LOGIN_ERROR': {
      return {
        type: 'LOGIN_ERROR',
        error: params.error,
      };
    }
    case 'SET_UPDATE_LOADING': {
      return {
        type: 'SET_UPDATE_LOADING',
        state: params.state,
      };
    }
    case 'RESET_EDIT_INFOS': {
      return {
        type: 'RESET_EDIT_INFOS',
      };
    }
    case 'RESET_EDIT_USER_INFOS': {
      return {
        type: 'RESET_EDIT_USER_INFOS',
      };
    }
    case 'SET_DATA_TO_EDIT': {
      return {
        type: 'SET_DATA_TO_EDIT',
        data: params.data,
      };
    }
    case 'SHOW_MODAL_EDIT_INFOS': {
      return {
        type: 'SHOW_MODAL_EDIT_INFOS',
      };
    }
    case 'SHOW_MODAL_EDIT_USER_INFOS': {
      return {
        type: 'SHOW_MODAL_EDIT_USER_INFOS',
      };
    }
    case 'HIDE_MODAL_EDIT_INFOS': {
      return {
        type: 'HIDE_MODAL_EDIT_INFOS',
      };
    }
    case 'HIDE_MODAL_EDIT_USER_INFOS': {
      return {
        type: 'HIDE_MODAL_EDIT_USER_INFOS',
      };
    }
    case 'SET_USER_TO_EDIT': {
      return {
        type: 'SET_USER_TO_EDIT',
      };
    }
    case 'UPDATE_ERROR': {
      return {
        type: 'UPDATE_ERROR',
        error: params.error,
      };
    }
    case 'LOGOUT': {
      return {
        type: 'LOGOUT',
      };
    }
    case 'SET_USER': {
      return {
        type: 'SET_USER',
        user: params.user,
      };
    }
    case 'SET_VERIFY_STEP': {
      return {
        type: 'SET_VERIFY_STEP',
        step: params.step,
      };
    }
    case 'VERIFY_ERROR': {
      return {
        type: 'VERIFY_ERROR',
        error: params.error,
      };
    }
    case 'PREPARE_USER_TO_EDIT': {
      return {
        type: 'PREPARE_USER_TO_EDIT',
        data: params.data,
      };
    }
    case 'UPDATE_USER_EDIT_ERROR': {
      return {
        type: 'UPDATE_USER_EDIT_ERROR',
        error: params.error,
      };
    }
    default:
      return {};
  }
};

const refreshUser = ({ apikey }) => (
  async (dispatch) => {
    const { status, body } = await UsersServices.getUser({ apikey });
    if (status === 401) {
      dispatch({
        type: 'SERVER_KILLED_SESSION',
        payload: {},
      });
    } else {
      localStorage.setItem('user', JSON.stringify(body));
      dispatch(getActionToDispatch({ type: 'SET_USER', user: body }));
    }
  }
);

const verifyEmail = ({ token }) => (
  async (dispatch) => {
    dispatch(getActionToDispatch({ type: 'SET_VERIFY_STEP', step: 1 }));
    dispatch(getActionToDispatch({ type: 'VERIFY_ERROR', error: null }));
    try {
      const { status, body } = await UsersServices.verifyEmail({ token });
      if (status === 200) {
        dispatch(getActionToDispatch({ type: 'SET_VERIFY_STEP', step: 2 }));
      } else if (status === 401) {
        dispatch({
          type: 'SERVER_KILLED_SESSION',
          payload: {},
        });
      } else {
        dispatch(getActionToDispatch({ type: 'SET_VERIFY_STEP', step: -1 }));
        dispatch(getActionToDispatch({
          type: 'VERIFY_ERROR',
          error: body.error || 'Une erreur est survenue.',
        }));
      }
    } catch (e) {
      dispatch(getActionToDispatch({ type: 'SET_VERIFY_STEP', step: -1 }));
      dispatch(getActionToDispatch({
        type: 'VERIFY_ERROR',
        error: e.reason || e.message || 'Une erreur est survenue.',
      }));
    }
  }
);

const login = ({ email, password }) => (
  async (dispatch) => {
    dispatch(getActionToDispatch({ type: 'LOGIN_BEGIN', email }));
    dispatch(getActionToDispatch({
      type: 'LOGIN_ERROR',
      error: null,
    }));
    try {
      const { status, body } = await UsersServices.login({ email, password });
      if (status === 200) {
        if (body.isAdmin || body.role === 'reseller' || body.role === 'chief' || body.role === 'superChief') {
          localStorage.setItem('user', JSON.stringify(body));
          dispatch(getActionToDispatch({ type: 'LOGIN_SUCCESS', user: body }));
          window.location.replace('/');
        } else {
          dispatch(getActionToDispatch({
            type: 'LOGIN_ERROR',
            error: body.error || 'Vous n\'êtes pas autorisé à vous connecter sur ce serveur.',
          }));
        }
      } else {
        dispatch(getActionToDispatch({
          type: 'LOGIN_ERROR',
          error: body.error || 'Une erreur est survenue.',
        }));
      }
    } catch (e) {
      dispatch(getActionToDispatch({
        type: 'LOGIN_ERROR',
        error: e.reason || e.message || 'Une erreur est survenue.',
      }));
    }
  }
);

const logout = () => (
  async (dispatch) => {
    try {
      const ret = await UsersServices.logout();
      const { status } = ret;
      if (status !== 200) {
        dispatch(getActionToDispatch({
          type: 'LOGOUT',
          error: 'Une erreur est survenue.',
        }));
      }
      localStorage.removeItem('user');
      window.location.replace('/login');
    } catch (e) {
      dispatch(getActionToDispatch({
        type: 'LOGOUT',
        error: e.reason || e.message || 'Une erreur est survenue.',
      }));
    }
  }
);

const resetPassword = async ({ email }) => {
  try {
    const { status, body } = await UsersServices.resetPassword({ email });
    return { status, body };
  } catch (e) {
    return { status: null, error: new Error('Une erreur est survenue') };
  }
};

const checkPasswordToken = ({ email, password }) => (
  async (dispatch) => {
    dispatch(getActionToDispatch({ type: 'LOGIN_BEGIN', email }));
    dispatch(getActionToDispatch({
      type: 'LOGIN_ERROR',
      error: null,
    }));
    try {
      const { status, body } = await UsersServices.login({ email, password });
      if (status === 200) {
        if (body.isAdmin) {
          localStorage.setItem('user', JSON.stringify(body));
          dispatch(getActionToDispatch({ type: 'LOGIN_SUCCESS', user: body }));
        } else if (status === 401) {
          dispatch({
            type: 'SERVER_KILLED_SESSION',
            payload: {},
          });
        } else {
          dispatch(getActionToDispatch({
            type: 'LOGIN_ERROR',
            error: body.error || 'Vous n\'êtes pas autorisé à vous connecter sur ce serveur.',
          }));
        }
      } else {
        dispatch(getActionToDispatch({
          type: 'LOGIN_ERROR',
          error: body.error || 'Une erreur est survenue.',
        }));
      }
    } catch (e) {
      dispatch(getActionToDispatch({
        type: 'LOGIN_ERROR',
        error: e.reason || e.message || 'Une erreur est survenue.',
      }));
    }
  }
);

const updateUser = (data) => (
  async (dispatch) => {
    dispatch(getActionToDispatch({ type: 'SET_UPDATE_LOADING', state: true }));
    if (!data.fromAdmin) {
      dispatch(getActionToDispatch({ type: 'UPDATE_ERROR', error: null }));
    } else {
      dispatch(getActionToDispatch({ type: 'UPDATE_USER_EDIT_ERROR', error: null }));
    }
    try {
      const { status, body } = await UsersServices.updateUser(data);
      dispatch(getActionToDispatch({ type: 'SET_UPDATE_LOADING', state: false }));
      if (status === 200) {
        if (!data.fromAdmin) {
          dispatch(getActionToDispatch({ type: 'RESET_EDIT_INFOS' }));
          dispatch(getActionToDispatch({ type: 'HIDE_MODAL_EDIT_INFOS' }));
          refreshUser({ apikey: data.apikey })(dispatch);
        }
        return true;
      }
      if (status === 401) {
        dispatch({
          type: 'SERVER_KILLED_SESSION',
          payload: {},
        });
      }
      if (data.fromAdmin) {
        dispatch(getActionToDispatch({
          type: 'UPDATE_USER_EDIT_ERROR',
          error: body.error || 'Une erreur est survenue.',
        }));
        return false;
      }
      dispatch(getActionToDispatch({
        type: 'UPDATE_ERROR',
        error: body.error || 'Une erreur est survenue.',
      }));
      return false;
    } catch (e) {
      if (data.fromAdmin) {
        dispatch(getActionToDispatch({ type: 'UPDATE_USER_EDIT_ERROR', state: false }));
        dispatch(getActionToDispatch({
          type: 'UPDATE_USER_EDIT_ERROR',
          error: e.reason || e.message || 'Une erreur est survenue.',
        }));
      } else {
        dispatch(getActionToDispatch({ type: 'SET_UPDATE_LOADING', state: false }));
        dispatch(getActionToDispatch({
          type: 'UPDATE_ERROR',
          error: e.reason || e.message || 'Une erreur est survenue.',
        }));
      }
      return false;
    }
  }
);

const createUser = (data) => (
  async (dispatch) => {
    dispatch(getActionToDispatch({ type: 'SET_UPDATE_LOADING', state: true }));
    try {
      const { status, body } = await UsersServices.createUser(data);
      dispatch(getActionToDispatch({ type: 'SET_UPDATE_LOADING', state: false }));
      if (status === 200) {
        return true;
      }
      if (status === 401) {
        dispatch({
          type: 'SERVER_KILLED_SESSION',
          payload: {},
        });
      }
      dispatch(getActionToDispatch({
        type: 'UPDATE_USER_EDIT_ERROR',
        error: body.error || 'Une erreur est survenue.',
      }));
      return false;
    } catch (e) {
      dispatch(getActionToDispatch({ type: 'SET_UPDATE_LOADING', state: false }));
      dispatch(getActionToDispatch({
        type: 'UPDATE_USER_EDIT_ERROR',
        error: e.reason || e.message || 'Une erreur est survenue.',
      }));
      return false;
    }
  }
);

const UsersActions = {
  login,
  logout,
  getActionToDispatch,
  updateUser,
  createUser,
  resetPassword,
  checkPasswordToken,
  verifyEmail,
};

export default UsersActions;
