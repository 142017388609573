import BillingsServices from '../services/BillingsServices';

// Action types
export const REQUEST_BILLINGS = 'REQUEST_BILLINGS';
export const GET_BILLINGS = 'GET_BILLINGS';
export const GET_BILLINGS_SUCCESS = 'GET_BILLINGS_SUCCESS';
export const GET_BILLINGS_ERROR = 'GET_BILLINGS_ERROR';
export const REQUEST_BILLING = 'REQUEST_BILLING';
export const GET_BILLING = 'GET_BILLING';
export const GET_BILLING_SUCCESS = 'GET_BILLING_SUCCESS';
export const GET_BILLING_ERROR = 'GET_BILLING_ERROR';
export const REQUEST_UPDATE_BILLING = 'REQUEST_UPDATE_BILLING';
export const UPDATE_BILLING = 'UPDATE_BILLING';
export const UPDATE_BILLING_SUCCESS = 'UPDATE_BILLING_SUCCESS';
export const UPDATE_BILLING_ERROR = 'UPDATE_BILLING_ERROR';
export const CLEAR_BILLING = 'CLEAR_BILLING';
export const CLEAR_BILLING_CONFIGURATION = 'CLEAR_BILLING_CONFIGURATION';
export const REQUEST_BILLING_CONFIGURATION = 'REQUEST_BILLING_CONFIGURATION';
export const GET_BILLING_CONFIGURATION = 'GET_BILLING_CONFIGURATION';
export const GET_BILLING_CONFIGURATION_SUCCESS = 'GET_BILLING_CONFIGURATION_SUCCESS';
export const GET_BILLING_CONFIGURATION_ERROR = 'GET_BILLING_CONFIGURATION_ERROR';
export const REQUEST_BILLING_HISTORY_CONFIGURATION = 'REQUEST_BILLING_HISTORY_CONFIGURATION';
export const GET_BILLING_HISTORY_CONFIGURATION = 'GET_BILLING_HISTORY_CONFIGURATION';
export const GET_BILLING_HISTORY_CONFIGURATION_SUCCESS = 'GET_BILLING_HISTORY_CONFIGURATION_SUCCESS';
export const GET_BILLING_HISTORY_CONFIGURATION_ERROR = 'GET_BILLING_HISTORY_CONFIGURATION_ERROR';
export const REQUEST_UPDATE_STATUS_BILLING = 'REQUEST_UPDATE_STATUS_BILLING';
export const UPDATE_STATUS_BILLING = 'UPDATE_STATUS_BILLING';
export const UPDATE_STATUS_BILLING_SUCCESS = 'UPDATE_STATUS_BILLING_SUCCESS';
export const UPDATE_STATUS_BILLING_ERROR = 'UPDATE_STATUS_BILLING_ERROR';
export const SERVER_KILLED_SESSION = 'SERVER_KILLED_SESSION';

// Action creator
export const serverKilledSession = () => ({
  type: SERVER_KILLED_SESSION,
});

export const requestBillings = () => ({
  type: REQUEST_BILLINGS,
});

export const getBillingsSuccess = (billings) => ({
  type: GET_BILLINGS_SUCCESS,
  billings,
});

export const getBillingsError = (error) => ({
  type: GET_BILLINGS_ERROR,
  error,
});

export const getBillings = async (dispatch) => {
  try {
    dispatch(requestBillings());
    const result = await BillingsServices.getBillings();
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getBillingsError(result.body.message || 'Failed'));
    return dispatch(getBillingsSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestBilling = () => ({
  type: REQUEST_BILLING,
});

export const getBillingSuccess = (billing) => ({
  type: GET_BILLING_SUCCESS,
  billing,
});

export const getBillingError = (error) => ({
  type: GET_BILLING_ERROR,
  error,
});

export const getBilling = async (dispatch, billingId) => {
  try {
    dispatch(requestBilling());
    const result = await BillingsServices.getBillingByOrderId(billingId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getBillingError(result.body.message || result.body.error || 'Failed'));
    return dispatch(getBillingSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestUpdateBilling = () => ({
  type: REQUEST_UPDATE_BILLING,
});

export const updateBillingSuccess = (billing) => ({
  type: UPDATE_BILLING_SUCCESS,
  billing,
});

export const updateBillingError = (error) => ({
  type: UPDATE_BILLING_ERROR,
  error,
});

export const updateBilling = async (dispatch, billingData, billingId) => {
  try {
    dispatch(requestUpdateBilling());
    const result = await BillingsServices.updateBillingByOrderId(billingData, billingId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(updateBillingError(result.body.message || result.body.error || 'Failed'));
    return dispatch(updateBillingSuccess(result));
  } catch (e) {
    throw new Error(e);
  }
};

export const clearBilling = () => ({
  type: CLEAR_BILLING,
});

export const requestStatusUpdateBilling = () => ({
  type: REQUEST_UPDATE_STATUS_BILLING,
});

export const updateStatusBillingSuccess = (billings) => ({
  type: UPDATE_STATUS_BILLING_SUCCESS,
  billings,
});

export const updateStatusBillingError = (error) => ({
  type: UPDATE_STATUS_BILLING_ERROR,
  error,
});

export const updateStatusBilling = async (dispatch, billingsToUpdateStatus) => {
  try {
    dispatch(requestStatusUpdateBilling());
    const result = await BillingsServices.updateStatusBilling(billingsToUpdateStatus);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(updateStatusBillingError(result.body.message || result.body.error || 'Failed'));
    return dispatch(updateStatusBillingSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const clearBillingConfiguration = () => ({
  type: CLEAR_BILLING_CONFIGURATION,
});

export const requestBillingConfiguration = () => ({
  type: REQUEST_BILLING_CONFIGURATION,
});

export const getBillingConfigurationSuccess = (billingConfiguration) => ({
  type: GET_BILLING_CONFIGURATION_SUCCESS,
  billingConfiguration,
});

export const getBillingConfigurationError = (error) => ({
  type: GET_BILLING_CONFIGURATION_ERROR,
  error,
});

export const getBillingConfiguration = async (dispatch, reportId) => {
  try {
    dispatch(requestBillingConfiguration());
    const result = await BillingsServices.getBillingConfigurationByReportId(reportId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getBillingConfigurationError(result.body.message || result.body.error || 'Failed'));
    return dispatch(getBillingConfigurationSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestBillingHistoryConfiguration = () => ({
  type: REQUEST_BILLING_HISTORY_CONFIGURATION,
});

export const getBillingHistoryConfigurationSuccess = (billingHistoryConfiguration) => ({
  type: GET_BILLING_HISTORY_CONFIGURATION_SUCCESS,
  billingHistoryConfiguration,
});

export const getBillingHistoryConfigurationError = (error) => ({
  type: GET_BILLING_HISTORY_CONFIGURATION_ERROR,
  error,
});

export const getBillingHistoryConfiguration = async (dispatch, reportId) => {
  try {
    dispatch(requestBillingHistoryConfiguration());
    const result = await BillingsServices.getBillingHistoryConfigurationByReportId(reportId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getBillingHistoryConfigurationError(result.body.message || result.body.error || 'Failed'));
    return dispatch(getBillingHistoryConfigurationSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};
