import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUploader from '../../shared/organisms/FileUploader';

const UploadDocuments = ({ drawerFiles, selectNewFile, onInputValueChange, addDocumentDrawerView = false }) => {
  const [newInspectionFiles, setNewInspectionFiles] = useState([]);
  const [docList, setDocList] = useState([]);
  const temporaryFilesArray = [];

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          if (temporaryFilesArray.length > 0) {
            if (!temporaryFilesArray.some((el) => el.name === file.name)) {
              temporaryFilesArray.push(file);
              setNewInspectionFiles((oldArray) => [...oldArray, file]);
            }
          } else {
            temporaryFilesArray.push(file);
            setNewInspectionFiles((oldArray) => [...oldArray, file]);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [newInspectionFiles],
  );

  const deleteDocument = (i) => {
    setNewInspectionFiles((oldArray) => oldArray.filter((item, index) => index !== i));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (newInspectionFiles.length > 0) {
      if (!addDocumentDrawerView) {
        onInputValueChange('documents')(newInspectionFiles);
      }
      selectNewFile(newInspectionFiles);
    } else {
      if (!addDocumentDrawerView) {
        onInputValueChange('documents')(drawerFiles);
      }
      selectNewFile(drawerFiles);
    }
    const filesToShow = newInspectionFiles || drawerFiles;
    setDocList(
      filesToShow?.map((file, i) => (
        <li key={i}>
          <FileUploader identifier='doc' deleteDocument={() => deleteDocument(i)} newInspection={true} label={file.name} icon='documents' isUploading={false} />
        </li>
      )),
    );
  }, [newInspectionFiles]);

  return (
    <div className='horizontalForm'>
      <div className='horizontalFormPart'>
        <div className='formTitle'>(optionnel) Attacher des documents administratifs à la commande (PDF, Word, PNG, JPEG...)</div>
        <div data-cy='importInspectionsFiles' className='importSpreadsheet' {...getRootProps()}>
          <input {...getInputProps()} />
          <p> Sélectionner un ou plusieurs fichiers...</p>
          <i className='fas fa-file-upload' />
        </div>
        <div className='specialistFiche'>
          <div className='specialistFichePart'>
            <ul className='documents'>{docList || null}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

UploadDocuments.propTypes = {
  newInspection: PropTypes.object,
  selectNewFile: PropTypes.func,
  onInputValueChange: PropTypes.func,
  drawerFiles: PropTypes.array,
  addDocumentDrawerView: PropTypes.bool,
};

export default UploadDocuments;
