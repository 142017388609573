/* eslint-disable no-unused-vars, class-methods-use-this */
import { Modal } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import ReportsActions from '../../actions/ReportsV3Actions';
import config from '../../config';
import c from '../../constants';
import ReportsServices from '../../services/ReportsService';
import Filters from '../shared/atoms/Filters';
import Text from '../shared/atoms/Text';
import Paginator from '../shared/molecules/Paginator';
import SearchTable from './SearchTable';
import ToggleMenu from './ToggleTable';

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortValues: {},
      page: 0,
      // selectedOption: [],
    };
  }

  searchAction = ({ pageReset }) => {
    let additionnalQueryParams = '';
    const { index, filterSearch, filterFacets } = this.props;
    const { page } = this.state;

    if (this.props.options) {
      additionnalQueryParams = this.props.options.additionnalQueryParams;
    }

    const callNewQuery = (newPage) => {
      let newQueryString = '';
      if (filterSearch) newQueryString = newQueryString.concat(`&searchTerm=${filterSearch}`);
      if (newPage) newQueryString = newQueryString.concat(`&pageNumber=${newPage}`);
      if (filterFacets !== '') newQueryString = newQueryString.concat(`${filterFacets}`);
      if (additionnalQueryParams && additionnalQueryParams.length > 0) newQueryString = newQueryString.concat(`${additionnalQueryParams}`);
      this.props.dispatch(ReportsActions.executeAction(index, newQueryString));
    };

    if (pageReset) {
      this.setState({ page: 0 }, () => callNewQuery(0));
    } else {
      callNewQuery(page);
    }
  };

  onSort = ({ variable, sortable }) => {
    if (!sortable) {
      return;
    }
    const { sortValues } = this.state;

    sortValues[variable] = !sortValues[variable];
    this.setState({ sortValues });
  };

  convertRole = (role) => {
    switch (role) {
      case 'reseller':
        return 'Utilisateur';
      case 'chief':
        return 'Responsable';
      case 'superChief':
        return 'Admin de groupe';
      default:
        return '';
    }
  };

  // remove?
  convertVariable = (data, type) => {
    if (_.get(data, 'field.subVariable') && _.get(data, `row[${data.field.subVariable}]`)) {
      return _.get(data, `row[${data.field.variable}][${data.field.subVariable}]`, '');
    }
    const variable = _.get(data, `row[${data.field.variable}]`);
    let i;

    switch (type) {
      case 'date':
        return moment(variable).format('DD/MM/YYYY');
      case 'role':
        return this.convertRole(variable);
      case 'status':
        i = _.find(config.optionsStatus, { value: variable }) || {};
        return i.displayName || 'non defini';
      case 'bool':
        return variable ? 'Oui' : 'Non';
      default:
        return variable;
    }
  };

  changePage = (newPage) => {
    this.setState({ page: newPage }, () => {
      this.searchAction({});
    });
  };

  showConfirm = (deleteFunction) => {
    const { confirm } = Modal;
    confirm({
      title: 'Confirmation',
      content: 'Êtes-vous sûr de vouloir supprimer ce point de contrôle ?',
      cancelText: 'Annuler',
      okText: 'Supprimer',
      onOk() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
          const ret = await deleteFunction();

          if (ret && ret.error && ret.reports) {
            alert(
              `Impossible de supprimer le point de contrôle car défini dans les rapports suivants:\n\n${ret.reports.map((report) => `${report.title} - ${report.description}\n`)}`,
            );
          }

          resolve();
          // eslint-disable-next-line no-console
        }).catch((e) => console.log('[Error] while deleting', e));
      },
      onCancel() {},
    });
  };

  openModal = (name, data) => {
    switch (name) {
      case 'deleteCheckpoint':
        this.showConfirm(() => this.deleteItem(c.URI.v3.checkpoints.concat(data._id)));
        break;
      case 'deleteRule':
        this.showConfirm(() => this.deleteItem(c.URI.v3.cpRules.concat(data._id)));
        break;
      case 'cloneReport':
        this.postItem(c.URI.v3.inspection, _.omit(data, ['_id']));
        break;
      default:
        this.props.dispatch(ReportsActions.showReportsV3Modal({ modalName: name, modalData: data }));
    }
  };

  // eslint-disable-next-line consistent-return
  deleteItem = async (url) => {
    const ret = await ReportsServices.deleteOne(url);

    if (_.get(ret, 'body.error')) {
      return ret.body;
    }
    this.changePage(this.props.reportsV3.pageNumber);
  };

  postItem = async (url, payload) => {
    await ReportsServices.post(url, payload);
    this.changePage(this.props.reportsV3.pageNumber);
  };

  showActions = (array, row) => {
    const render = [];
    array.map(
      (item, itemKey) =>
        render.push(
          <div title={item.tooltip} key={itemKey} onClick={() => item.handler((name) => this.openModal(name, { ...row }))}>
            <i className={item.icon ? item.icon : 'fa fa-cog'} />
          </div>,
        ),
      // eslint-disable-next-line function-paren-newline
    );
    return render;
  };

  handler = (data) => {
    const { openModal } = this;
    if (data.handler && typeof data.handler === 'function') {
      data.handler(openModal);
    }
  };

  displayElement = (elem, row) => {
    if (elem.action) {
      return this.showActions(elem.actions, row);
    }
    return (
      <Text fontStyle='table-value' size={14}>
        {this.convertVariable({ row, field: elem }, elem.type)}
      </Text>
    );
  };

  render() {
    const { sortValues } = this.state;
    const { optionsFilters } = this.props.application;
    const { option = 'concession', list, index, options, light, showFilter, type } = this.props;
    const { rows, pageNumber, totalPages } = this.props.reportsV3;

    return (
      <div className='table'>
        {!light && (
          <Col xs={12} className='table-header'>
            <div className='title'>
              <Text fontStyle='normal' size={17}>
                {list.example.title} ({rows.length || '0'})
              </Text>
            </div>
            <div>
              <ToggleMenu menu={list.example.menu} searchAction={this.searchAction} openModal={this.openModal} />
            </div>
          </Col>
        )}
        {light && (
          <div className='concession-header'>
            <Filters
              selectOptions={this.props.changeOption}
              className='concessionFilter'
              title={list.example.title}
              options={showFilter ? optionsFilters.reseller : []}
              activeMenu={option}
            />
            <div className='action-bar'>
              {list.example.menu.map((value, key) => (
                <React.Fragment key={key}>
                  <div className='item' key={key} onClick={() => this.handler(value)}>
                    <i className={value.icon} />
                    <Text fontStyle='normal'>{value.displayName}</Text>
                  </div>
                  <div className='divider' />
                </React.Fragment>
              ))}
              <div className='item' onClick={() => this.searchAction({})}>
                <i className='fa fa-sync-alt' />
                <Text fontStyle='normal'>Rafraichir</Text>
              </div>
            </div>
          </div>
        )}
        {index && (
          <Col xs={12} className='table-search'>
            <SearchTable type={type} index={index} options={options} searchAction={this.searchAction} />
          </Col>
        )}
        <div className='table-content'>
          <div className='table-row-header'>
            {list.example.fields.map((value, key) => {
              const angle = sortValues[value.variable] ? 'angle-up' : null;
              return (
                <Col xs={value.size.xs} key={key} className={angle && 'selected'}>
                  <Text fontStyle='table-title' size={15}>
                    {value.displayName}
                  </Text>
                  {value.sortable && <i className={`fa fa-${angle || 'angle-down'}`} />}
                </Col>
              );
            })}
          </div>
          <div className='table-rows'>
            {rows &&
              rows.map((row, rowKey) => (
                <div className='table-row' key={row._id}>
                  {list.example.fields.map((value, key) => (
                    <Col xs={value.size.xs} key={key} className={_.get(list, `example.fields[${key}].customClass`)}>
                      {this.displayElement(_.get(list, `example.fields[${key}]`), row, index)}
                    </Col>
                  ))}
                </div>
              ))}
            {(!rows || rows.length === 0) && (
              <Text size={20} fontStyle='normal' className='center'>
                Aucun résultat
              </Text>
            )}
            <Paginator pageCount={totalPages} current={pageNumber} onChange={this.changePage} />
          </div>
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  list: PropTypes.object,
  index: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  dispatch: PropTypes.func,
  options: PropTypes.object,
  changeOption: PropTypes.func,
  option: PropTypes.string,
  concessions: PropTypes.object,
  application: PropTypes.object,
  role: PropTypes.string,
  light: PropTypes.bool,
  showFilter: PropTypes.bool,
  reportsV3: PropTypes.object,
  filterFacets: PropTypes.string,
  filterSearch: PropTypes.string,
};

const mapStateToProps = (state) => ({
  concessions: state.concessions,
  application: state.application,
  reportsV3: state.reportsV3,
  filterFacets: state.reportsV3.filterFacets,
  filterSearch: state.reportsV3.filterSearch,
});

export default connect(mapStateToProps)(Table);
