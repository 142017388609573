import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ReportV3Services from '../../../services/ReportsService';

const ReportSelector = ({ reportIdChange, concessionSelected }) => {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [concessionReport, setConcessionReport] = useState({});
  const [reportSelected, setReportSelected] = useState(_.get(concessionReport, 'default.title'));

  const findReportId = () => {
    if (_.get(concessionReport, 'default.title') === reportSelected) {
      return _.get(concessionReport, 'default._id');
    }
    return _.get(concessionReport, 'reports').find((report) => report.title === reportSelected)._id;
  };

  useEffect(() => {
    setReportSelected(_.get(concessionReport, 'default.title'));
  }, [concessionReport]);

  useEffect(() => {
    setConcessionReport(reports.find((report) => report.concessionName === concessionSelected));
  }, [reports, concessionSelected]);

  useEffect(() => {
    reportIdChange('reportId')(findReportId());
  }, [reportSelected]);

  useEffect(() => {
    ReportV3Services.getPlanned()
      .then((results) => {
        setLoading(false);
        setReports(results);
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(e.message);
      });
  }, []);

  const reportValue = () => {
    if (!reportSelected) {
      return '';
    }
    if (concessionReport.default && reportSelected === concessionReport.default.title) {
      return { value: reportSelected, label: `${concessionReport.default.title} (rapport par défaut)` };
    }
    return { value: reportSelected, label: reportSelected };
  };

  const getReportsOrDisableBool = (disableBool) => {
    if (concessionReport && _.get(concessionReport, 'default.title') && concessionReport.reports && concessionReport.reports.length > 0) {
      if (disableBool) {
        return false;
      }
      return [
        { value: concessionReport.default.title, label: `${concessionReport.default.title} (rapport par défaut)` },
        ...concessionReport.reports.map((report) => ({ value: report.title, label: report.title })),
      ];
    }
    if (concessionReport && concessionReport.reports && concessionReport.reports.length > 0) {
      if (disableBool) {
        return false;
      }
      return concessionReport.reports.map((report) => ({ value: report.title, label: report.title }));
    }
    if (_.get(concessionReport, 'default.title')) {
      if (disableBool) {
        return false;
      }
      return [{ value: concessionReport.default.title, label: `${concessionReport.default.title} (rapport par défaut)` }];
    }
    if (disableBool) {
      return true;
    }
    return [{ title: 'rapport undefined', label: 'Aucun rapport disponible' }];
  };

  return (
    <React.Fragment>
      {!loading ? (
        <div className='formInput withSearch'>
          <label>Assigner un rapport *</label>
          <Select
            value={getReportsOrDisableBool(true) ? getReportsOrDisableBool(false) : reportValue()}
            options={getReportsOrDisableBool(false)}
            onChange={(e) => setReportSelected(e.value)}
            // isDisabled={getReportsOrDisableBool(true)}
            name='choose report'
            className='cypress__select-assign-report'
          />
        </div>
      ) : (
        <i className='fa fa-spinner fa-pulse' />
      )}
      {errorMessage && (
        <div className='error'>
          <p>{errorMessage}</p>
        </div>
      )}
    </React.Fragment>
  );
};

ReportSelector.propTypes = {
  reportIdChange: PropTypes.func,
  concessionSelected: PropTypes.string,
};

export default ReportSelector;
