/*
 * @func fixDate
 * Vérifie que _date est bien une date et transforme si nécessaire
 */
import moment from 'moment';
import constants from '../constants';

export const fixDate = (_date) => {
  let date = _date;
  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date);
  }
  if (date && date.getTime && date.getTime()) {
    return date;
  }
  return null;
};

export const diffDates = (first, second) => {
  const createdAt = moment(second);
  const y = first.diff(createdAt, 'years');
  createdAt.add(y, 'years');

  const m = first.diff(createdAt, 'months');
  createdAt.add(m, 'months');

  const d = first.diff(createdAt, 'days');
  return { y, m, d };
};

export const validateTimeSlot = (dateFromInspectionObject) => {
  if (moment(dateFromInspectionObject.start, constants.formatDateTimeAppointment).isValid()) {
    return {
      date: moment(dateFromInspectionObject.start, constants.formatDateTimeAppointment).toDate(),
      startingTime: moment(dateFromInspectionObject.start, constants.formatDateTimeAppointment).toDate(),
      endingTime: moment(dateFromInspectionObject.end, constants.formatDateTimeAppointment).toDate(),
    };
  }
  return {
    date: moment(new Date(dateFromInspectionObject.start)).toDate(),
    startingTime: moment(new Date(dateFromInspectionObject.start)).toDate(),
    endingTime: moment(new Date(dateFromInspectionObject.end)).toDate(),
  };
};

const applyNewDate = (dateSelect, timeSelect) => {
  const myDate = moment(dateSelect);
  const selectedDateTime = moment(timeSelect);
  selectedDateTime.year(myDate.year()).month(myDate.month()).date(myDate.date());
  return new Date(selectedDateTime);
};

export const dataMapBd = ({ date, startingTime, endingTime }) => ({
  start: applyNewDate(date, startingTime),
  end: applyNewDate(date, endingTime),
});
