import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import ConcessionsActions from '../../../actions/Concessions';
import c from '../../../constants';
import ConcessionsServices from '../../../services/ConcessionsServices';
import ReportServices from '../../../services/ReportsService';
import CountrySelect from '../../concessions/shared/atoms/CountrySelect';
import EntitySelect from '../../concessions/shared/atoms/EntitySelect';
import RenderError from '../atoms/RenderError';
import ReportSelector from '../atoms/ReportSelector';
import Text from '../atoms/Text';
import ModalFooterButton from '../molecules/ModalFooterButton';

Modal.setAppElement('body');

function ModalCreateEditViewSite(props) {
  // if connected as admin or superChief
  const { isAdmin } = props.application.user;
  const isSuperChief = props.application.user?.role === 'superChief';

  // if modal is for creation or edition or viewing
  const createConcession = props.concessions.modalName === 'createConcession';
  const editConcession = props.concessions.modalName === 'editConcession';
  const viewConcession = props.concessions.modalName === 'viewConcession';

  const { modalData } = props.concessions;
  const reportTemplates = modalData?.reportTemplates;

  const [name, setName] = useState(modalData?.name);
  const [country, setCountry] = useState(modalData?.country);
  const [selectedEntity, setSelectedEntity] = useState('');

  // four types of reports
  const [plannedInspectionDefault, setPlannedInspectionDefault] = useState('');
  const [plannedInspection, setPlannedInspection] = useState([]);
  const [onDemandInspectionDefault, setOnDemandInspectionDefault] = useState('');
  const [onDemandInspection, setOnDemandInspection] = useState([]);

  const [selectedReports, setSelectedReports] = useState([]);
  const [error, setError] = useState('');

  const [plateLogoUrl, setPlateLogoUrl] = useState(modalData?.options?.plateLogoUrl || '');

  const closeModal = () => {
    const { dispatch, application } = props;

    dispatch(ConcessionsActions.getActionToDispatch({ type: 'HIDE_MODAL' }));
    ConcessionsActions.reloadTable({ index: 'concessions' })(dispatch, application);
  };

  function onNameChange(e) {
    setName(e.target.value);
  }

  async function initialize() {
    if (isAdmin || isSuperChief) {
      ReportServices.getAllPromise(`${c.URI.v3.inspection}outline`).then((outlineResponse) => {
        const allReports = outlineResponse.map((report) => ({ value: report._id, label: report.title, _id: report._id }));

        setSelectedReports(allReports);

        setOnDemandInspectionDefault(
          reportTemplates?.onDemandInspection?.default ? allReports.find((option) => option._id === reportTemplates.onDemandInspection.default._id) : '',
        );
        setOnDemandInspection(reportTemplates?.onDemandInspection?.reports ? allReports.filter((option) => reportTemplates.onDemandInspection.reports.includes(option._id)) : []);
        setPlannedInspectionDefault(reportTemplates?.plannedInspection?.default ? allReports.find((option) => option._id === reportTemplates.plannedInspection.default._id) : '');
        setPlannedInspection(reportTemplates?.plannedInspection?.reports ? allReports.filter((option) => reportTemplates.plannedInspection.reports.includes(option._id)) : []);
      });
    }
  }

  useEffect(() => {
    (() => initialize())();
  }, []);

  const onReportSelection = (selection, obj) => {
    switch (obj.name) {
      case 'onDemandInspectionDefault':
        return setOnDemandInspectionDefault(selection);
      case 'onDemandInspection':
        return setOnDemandInspection(selection);
      case 'plannedInspectionDefault':
        return setPlannedInspectionDefault(selection);
      case 'plannedInspection':
        return setPlannedInspection(selection);
      default:
        return null;
    }
  };

  const onCountrySelected = (selectedCountry) => {
    setCountry(selectedCountry);
  };

  const onEntitySelected = (selection) => {
    setSelectedEntity(selection);
  };

  async function submit(e) {
    e.preventDefault();
    const reportTemplatesToSend = {
      plannedInspection: {
        default: plannedInspectionDefault?.value,
        reports: plannedInspection?.map((insp) => insp.value),
      },
      onDemandInspection: {
        default: onDemandInspectionDefault?.value,
        reports: onDemandInspection?.map((insp) => insp.value),
      },
    };

    if (!name) {
      setError('Veuillez remplir le nom du site');
      return;
    }

    if (!country && !modalData.country) {
      setError('Pays manquant');
      return;
    }
    if (editConcession) {
      const editedResponse = await ConcessionsServices.editConcession({
        concessionId: modalData?._id,
        countryId: country?._id || modalData?.country?._id,
        reportTemplates: reportTemplatesToSend,
        name,
        plateLogoUrl,
      }).catch((err) => setError(err.error || 'Une erreur est survenue'));

      if (editedResponse && editedResponse.status < 400) {
        closeModal();
      }
    }

    if (createConcession && !isAdmin) {
      const createdResponse = await ConcessionsServices.createConcession({
        name,
        countryId: country?._id,
        reportTemplates: reportTemplatesToSend,
      }).catch((err) => setError(err ? err.error : 'Une erreur est survenue'));

      if (createdResponse && createdResponse.status < 400) {
        closeModal();
      }
    } else if (createConcession && isAdmin) {
      const createdResponse = await ConcessionsServices.createConcession({
        name,
        entityId: selectedEntity._id,
        countryId: country?._id,
        reportTemplates: reportTemplatesToSend,
        plateLogoUrl,
      }).catch((err) => setError(err ? err.error : 'Une erreur est survenue'));

      if (createdResponse && createdResponse.status < 400) {
        closeModal();
      }
    }
  }
  const sendNotifToConcessionReseller = () => {
    const concessionId = modalData._id;

    ConcessionsServices.sendWelcomeToResellerFromConcession({ concessionId }).then(() => {
      alert('Les emails ont été envoyés aux utilisateurs du site');
    });
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel='Modal annuler inspection'
      overlayClassName='modal-overlay'
      className='modal modalConcession'
    >
      <div className='modalContent '>
        {editConcession && <div className='mainTitle'>Édition d'un site</div>}
        {createConcession && <div className='mainTitle'>Création d'un site</div>}
        {viewConcession && <div className='mainTitle'>Visualisation du site</div>}
        {viewConcession ? (
          <form className='mt10px formModal'>
            <Row>
              <Col xs={12} md={6}>
                <label>Nom</label>
                <Text fontStyle='normal' size={14}>
                  {modalData.name}
                </Text>
              </Col>
              {modalData.entity && (
                <Col xs={12} md={6}>
                  <label>Groupe</label>
                  <Text fontStyle='normal' size={14}>
                    {modalData.entity.name}
                  </Text>
                </Col>
              )}
            </Row>
            <Row>
              {modalData.country && (
                <Col xs={12} md={6}>
                  <label>Pays</label>
                  <Text fontStyle='normal' size={14}>
                    {modalData.country.name}
                  </Text>
                </Col>
              )}
              {props.application.user.isAdmin || props.application.user.role === 'superChief' ? (
                <Col xs={12} md={6}>
                  <label>Notifier les utilisateurs par mail (e-mail de bienvenue)</label>
                  <Text fontStyle='normal' className='viewConcession' size={14} onClick={() => sendNotifToConcessionReseller()}>
                    Cliquez-ici
                  </Text>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <div className='center formButton'>
              <button type='button' onClick={closeModal} className='negativ'>
                Fermer
              </button>
            </div>
          </form>
        ) : (
          <form className='mt10px' onSubmit={(e) => submit(e)}>

            <Col >
              <div data-cy='siteName' className='formInput'>
                    <label>Nom du site</label>
                    <input type='text' width={'100%'} name='name' placeholder='Nom' defaultValue={name} onChange={(e) => onNameChange(e)} />
              </div>
              <div className='formInput'>
                <CountrySelect selection={country} onSelect={(selectedCountry) => onCountrySelected(selectedCountry)} />
              </div>
            </Col>
            {isAdmin && createConcession ? (
              <div className='formInput withSearch'>
                <EntitySelect onSelect={(selection) => onEntitySelected(selection)} />
              </div>
            ) : null}
            {isAdmin || isSuperChief ? (
              <Row>
                <Col xs={12}>
                  <div className='mainTitle'>Rapport 'AutoInspect'</div>
                </Col>
                <Col xs={12}>
                  <ReportSelector
                    reportType='onDemandInspectionDefault'
                    isMulti={false}
                    options={selectedReports}
                    selection={onDemandInspectionDefault}
                    handleSelect={onReportSelection}
                  />
                </Col>
                <Col xs={12}>
                  <ReportSelector reportType='onDemandInspection' isMulti={true} options={selectedReports} selection={onDemandInspection} handleSelect={onReportSelection} />
                </Col>
                <Col xs={12}>
                  <div className='mainTitle'>Rapport 'Inspections MSA'</div>
                </Col>
                <Col xs={12}>
                  <ReportSelector
                    reportType='plannedInspectionDefault'
                    isMulti={false}
                    options={selectedReports}
                    selection={plannedInspectionDefault}
                    handleSelect={onReportSelection}
                  />
                </Col>
                <Col xs={12}>
                  <ReportSelector reportType='plannedInspection' isMulti={true} options={selectedReports} selection={plannedInspection} handleSelect={onReportSelection} />
                </Col>
               { isAdmin && (modalData?.entity?.options?.blurPhotos || selectedEntity?.options?.blurPhotos)
                && <Col xs={12}>
                  <div className='formInput'>
                    <label>[URL] Remplacer la plaque du véhicule par ce logo client</label>
                    <input
                        type='text'
                        name='urlValue'
                        data-cy='urlInput'
                        placeholder='https://monspecialisteauto.com/logo.jpeg (format accepté: jpeg/jpg/png)'
                        defaultValue={plateLogoUrl}
                        onChange={(e) => setPlateLogoUrl(e.target.value)}
                      />
                  </div>
                </Col>}
              </Row>
            ) : null}
            {error && <RenderError error={error} />}
            <ModalFooterButton data={props.concessions} actionCancel={closeModal} />
          </form>
        )}
      </div>
    </Modal>
  );
}
ModalCreateEditViewSite.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
};
export default ModalCreateEditViewSite;
