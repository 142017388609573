/* eslint-disable no-unused-vars, class-methods-use-this */
import { Select, TreeSelect } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const renderReportFilter = (props) => {
  const { handleSelectGroup, handleSelectSite, handleSelectReport, isAdmin, groups, selectedGroup, sites, selectedSite, reports, selectedReport } = props;
  return (
    <React.Fragment>
      {isAdmin && (
        <div className='search-item'>
          <label>Groupes</label>
          <div className='search-input'>
            <Select
              size={'large'}
              onChange={handleSelectGroup}
              className='multi-select'
              value={selectedGroup}
              labelInValue={false}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              showSearch>
              {_.map(groups, (option) => (
                <Select.Option key={option._id} value={option._id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      )}
      <div className='search-item'>
        <label>Sites</label>
        <div className='search-input'>
          <Select
            size={'large'}
            onChange={handleSelectSite}
            value={selectedSite}
            className='multi-select'
            labelInValue={false}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={sites.length === 0}
            showSearch>
            {_.map(sites, (option) => (
              <Select.Option key={option.key} value={option.value}>
                {option.title}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className='search-item'>
        <label>Rapports</label>
        <div className='search-input'>
          <Select
            size={'large'}
            onChange={handleSelectReport}
            value={selectedReport}
            className='multi-select'
            labelInValue={false}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={reports.length === 0}
            showSearch>
            {_.map(reports, (option) => (
              <Select.Option key={option.key} value={option.value}>
                {option.title}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </React.Fragment>
  );
};

renderReportFilter.propTypes = {
  handleSelectGroup: PropTypes.func,
  handleSelectSite: PropTypes.func,
  handleSelectReport: PropTypes.func,
  isAdmin: PropTypes.bool,
  groups: PropTypes.array,
  selectedGroup: PropTypes.string,
  sites: PropTypes.array,
  selectedSite: PropTypes.string,
  reports: PropTypes.array,
  selectedReport: PropTypes.string,
};

const renderGlobalFilter = (props) => {
  const { handleSelectGroup, handleSelectReportsGlobal, isAdmin, groups, treeData, selectedGroup, selectedReportsGlobal } = props;
  const { SHOW_CHILD } = TreeSelect;
  const { Option } = Select;
  const disabled = treeData.length === 0;
  // console.log('[DEV] treeData, disabled', treeData, disabled);
  const tProps = {
    treeData,
    value: selectedReportsGlobal,
    onChange: handleSelectReportsGlobal,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    searchPlaceholder: 'Sélectionnez un ou plusieurs rapports',
    style: {
      minWidth: 300,
      width: '100%',
    },
    size: 'large',
    disabled,
  };
  return (
    <React.Fragment>
      {isAdmin && (
        <div className='search-item'>
          <label>Groupes</label>
          <div className='search-input'>
            <Select
              size={'large'}
              value={selectedGroup}
              onChange={handleSelectGroup}
              className='multi-select'
              labelInValue={false}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              showSearch>
              {_.map(groups, (option) => (
                <Option key={option._id} value={option._id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}
      <div className='search-item'>
        <label>Sites / Rapports</label>
        <div className='search-input'>
          <TreeSelect {...tProps} />
        </div>
      </div>
    </React.Fragment>
  );
};

renderGlobalFilter.propTypes = {
  isAdmin: PropTypes.bool,
  groups: PropTypes.array,
  handleSelectGroup: PropTypes.func,
  handleSelectReportsGlobal: PropTypes.func,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  treeData: PropTypes.array,
  selectedGroup: PropTypes.string,
  selectedReportsGlobal: PropTypes.array,
};

const FRESearchBar = (props) => {
  const {
    bySiteByReport,
    selectedGroup,
    sites,
    selectedSite,
    handleSelectSite,
    reports,
    handleSelectGroup,
    handleSelectReportsGlobal,
    handleSelectReport,
    isAdmin,
    groups,
    treeData,
    selectedReport,
    selectedReportsGlobal,
  } = props;

  return (
    <div className='search-container'>
      {bySiteByReport
        ? renderGlobalFilter({
            isAdmin,
            groups,
            handleSelectGroup,
            handleSelectReportsGlobal,
            treeData,
            selectedGroup,
            selectedReportsGlobal,
          })
        : renderReportFilter({
            handleSelectGroup,
            handleSelectSite,
            handleSelectReport,
            isAdmin,
            groups,
            selectedGroup,
            sites,
            selectedSite,
            reports,
            selectedReport,
          })}
    </div>
  );
};

FRESearchBar.propTypes = {
  bySiteByReport: PropTypes.bool,
  type: PropTypes.string,
  isAdmin: PropTypes.bool,
  handleSelectGroup: PropTypes.func,
  handleSelectSite: PropTypes.func,
  handleSelectReport: PropTypes.func,
  handleSelectReportsGlobal: PropTypes.func,
  treeData: PropTypes.array,
  groups: PropTypes.array,
  selectedGroup: PropTypes.string,
  sites: PropTypes.array,
  selectedSite: PropTypes.string,
  reports: PropTypes.array,
  selectedReport: PropTypes.string,
  selectedReportsGlobal: PropTypes.array,
};

export default FRESearchBar;
