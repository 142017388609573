import { v4 as uuidv4 } from 'uuid';

const queryString = (obj) => {
  const result = [];
  for (let i = 0; i < obj.length; i += 1) {
    const toAdd = obj[i].key.concat('=').concat(encodeURIComponent(obj[i].value));
    result.push(toAdd);
  }
  return result.length > 0 ? '?'.concat(result.join('&')) : '';
};

const queryStringV2 = (obj) => {
  const result = [];
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    if (typeof obj[key] !== 'undefined') {
      const toAdd = key.concat('=').concat(encodeURIComponent(obj[key]));
      result.push(toAdd);
    }
  });
  return result.length > 0 ? '?'.concat(result.join('&')) : '';
};

const paramsToArrayQs = (queries) => {
  const keys = Object.keys(queries);
  const values = Object.values(queries);
  const params = [];
  for (let i = 0; i < keys.length; i += 1) {
    if (typeof values[i] !== 'undefined') {
      params.push({ key: keys[i], value: values[i] });
    }
  }
  return params;
};

const utils = {
  queryString,
  queryStringV2,
  paramsToArrayQs,
  uuidv4,
};
// Export functions
export default utils;
