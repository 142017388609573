import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateOnDemandInspections } from '../../../actions/onDemandInspectionsActions';
import ModalEditReport from '../organisms/ModalEditReport';
import { clearInspection } from '../../../actions/plannedInspectionsActions';
import ConcessionsActions from '../../../actions/Concessions';
import getUrlDomain from '../../../helpers/getUrlDomain';

const ViewInfosActionCell = ({ dispatch, value, role, inspectionType, lastUpdate }) => {
  const [openModalReport, setOpenModalReport] = useState(false);
  const checkIfInspectionIsDoneAndOnDemandType =
    (role === 'superChief' || role === 'chief' || role === 'admin') && value.status === 'done' && inspectionType === 'onDemandInspection';
  const closeModal = () => {
    setOpenModalReport(false);
    updateOnDemandInspections(dispatch, lastUpdate);
    dispatch(clearInspection());
  };
  const getViewerUrl = (viewerUrl) => (role && value?.entity?.options?.viewerUrl) ? viewerUrl?.replace(getUrlDomain(viewerUrl), value.entity.options.viewerUrl) : viewerUrl;

  return (
    <div style={{ fontSize: 'x-large' }}>
      <span>
        <i
          title='Dupliquer'
          onClick={() => {
            dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'duplicateConcessionInspection', modalData: value }));
          }}
          style={{ marginRight: 15 }}
          className='fa fa-clone'
        />
      </span>
      {checkIfInspectionIsDoneAndOnDemandType && (
        <span onClick={() => setOpenModalReport(true)}>
          <i className='fas fa-edit' style={{ marginTop: 2, marginRight: 15, cursor: 'pointer' }} />
        </span>
      )}
      <span>
        <i
          className='fa fa-eye'
          style={{ marginTop: 2, marginRight: 15, cursor: 'pointer' }}
          onClick={() => dispatch({ type: 'SHOW_MODAL', modalName: 'viewInspection', modalData: value })}
        />
      </span>
      {_.get(value, 'summary.viewerUrl', null) && (
        <a href={getViewerUrl(_.get(value, 'summary.viewerUrl', null))} target='_blank' rel='noreferrer noopener' title='Voir le rapport'>
          <i
            className={'fas fa-external-link-alt'}
            style={{
              color: '#1f5288',
              marginTop: 2,
              marginRight: 15,
              cursor: 'pointer',
            }}
          />
        </a>
      )}
      {_.get(value, 'summary.noCostViewerUrl', null) && (
        <a href={getViewerUrl(_.get(value, 'summary.noCostViewerUrl', null))} target='_blank' rel='noreferrer noopener' title='Voir le rapport sans frais'>
          <i className={'fab fa-creative-commons-nc-eu'} style={{ color: '#1f5288', cursor: 'pointer' }} />
        </a>
      )}
      {openModalReport && <ModalEditReport isOpen={openModalReport} inspectionId={value._id} onRequestClose={closeModal} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.application.user,
  lastUpdate: state.onDemandInspections.lastUpdatedAt,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updateOnDemandInspections,
  clearInspection,
});

ViewInfosActionCell.propTypes = {
  dispatch: PropTypes.func,
  value: PropTypes.any,
  onRequestClose: PropTypes.func,
  role: PropTypes.string,
  inspectionType: PropTypes.string,
  updateOnDemandInspections: PropTypes.func,
  lastUpdate: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewInfosActionCell);
