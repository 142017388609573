import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NavigationActions from '../../../actions/NavigationActions';
import NavBar from '../../shared/templates/NavBar';
import InspectionsContainer from '../templates/InspectionsContainer';

const InspectionsPage = (props) => {
  useEffect(() => {
    props.dispatch(NavigationActions.setNavigation({ navigation: 'inspection' }));
  }, []);

  return (
    <div>
      <NavBar />
      <InspectionsContainer adminInterface />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

InspectionsPage.propTypes = {
  dispatch: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(InspectionsPage);
