import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UsersServices from '../../services/UsersServices';

class FirstConnectPage extends React.Component {
  state = {
    email: '',
    password: '',
    error: null,
    loading: false,
    success: false,
    showPassword: false,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      error: nextProps.application.loginError,
    });
  }

  setValueOfEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleLoginProcess = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Make api call
    if (this.state.email && this.state.email.length > 0) {
      this.setState({ error: null, loading: true });
      const { status } = await UsersServices.resetPassword({ email: this.state.email, createNew: true });
      switch (status) {
        case 200:
          this.setState({ success: true, loading: false });
          break;
        default:
          this.setState({ error: `Assurez-vous d'avoir entré la bonne adresse email.`, loading: false });
          break;
      }
    } else {
      this.setState({
        error: `Entrez l'adresse mail utilisée lors de l'inscription sur`.concat(`MonSpecialisteAuto.com.`),
        loading: false,
      });
    }
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  renderForm = () => {
    if (this.state.success) {
      return (
        <div className='success'>
          <p className='mt10px'>
            <i className='fa fa-check'></i>
          </p>
          <p className='mt15px'> Vous allez recevoir un lien de création de mot de passe à l'adresse {this.state.email} d'ici quelques instants.</p>
        </div>
      );
    }
    return (
      <React.Fragment>
        {/* <p className="content ">Vous avez oublié votre mot de passe ? Pas de stress !</p> */}
        <p className='content'>Entrez votre adresse email et vous recevrez un lien de création de mot de passe sur votre boite mail.</p>
        <form onSubmit={this.handleLoginProcess}>
          <div className='formInput'>
            <label>Email</label>
            <input type='email' value={this.state.email} onChange={this.setValueOfEmail} />
          </div>
          {this.renderError()}
          <div className='formButton'>
            <button disabled={this.state.loading} className='positiv'>
              {this.state.loading ? (
                <span>
                  <i className='fa fa-spinner fa-pulse'></i> En cours
                </span>
              ) : (
                'Créer mon mot de passe'
              )}
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className='passwordForgetPage'>
        <div className='logo_img'></div>
        <h1 className='logo'>
          Mon<span>Specialiste</span>Auto.com
        </h1>
        <div className='mt20px'></div>
        {this.renderForm()}
        <div className='linkContainer'>
          <Link to='/login'>Retour à la page de connexion</Link>
        </div>
      </div>
    );
  }
}
//  <Link to='/inscription'>Créer un compte professionnel</Link>
const mapStateToProps = (state) => ({ application: state.application });

FirstConnectPage.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
};

export default connect(mapStateToProps)(FirstConnectPage);
