import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import AdminsActions from '../../actions/AdminsActions';
import NavigationActions from '../../actions/NavigationActions';
import UsersActions from '../../actions/UsersActions';
import Filters from '../shared/atoms/Filters';
import NavBar from '../shared/templates/NavBar';
import UsersContainer from './components/UsersContainer';

class AdminAdminsPage extends React.Component {
  state = {};

  componentDidMount() {
    this.props.dispatch(NavigationActions.setNavigation({ navigation: 'admins' }));
    this.props.dispatch(
      AdminsActions.getActionToDispatch({
        type: 'ADMIN_ACTIFS',
      }),
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ user: nextProps.admin.usersFilters });
    if (nextProps.admin.lastSearch.admin !== this.props.admin.lastSearch.admin) {
      const toSend = {
        ...nextProps.admin.usersFilters,
      };
      AdminsActions.getUsers(toSend)(this.props.dispatch);
    }
  }

  selectFilterOptions = (options) => {
    if (options === 'ADD_ADMIN') {
      this.props.dispatch(UsersActions.getActionToDispatch({ type: 'RESET_EDIT_USER_INFOS' }));
      this.props.dispatch(
        UsersActions.getActionToDispatch({
          type: 'SET_DATA_TO_EDIT',
          data: { isAdmin: true },
        }),
      );
      this.props.dispatch(
        UsersActions.getActionToDispatch({
          type: 'SHOW_MODAL_EDIT_USER_INFOS',
        }),
      );
    } else {
      this.props.dispatch(
        AdminsActions.getActionToDispatch({
          type: 'SET_ACTIVE_MENU_FILTERS',
          activeMenu: { admin: options },
        }),
      );
      this.props.dispatch(AdminsActions.getActionToDispatch({ type: options }));
    }
  };

  downloadUser = () => {};

  render() {
    return (
      <div>
        <NavBar />
        <div className='appContainer'>
          <Filters
            selectOptions={this.selectFilterOptions}
            title='Administrateurs'
            options={this.props.admin.optionsFilters.admin}
            activeMenu={this.props.admin.activeMenuFilters.admin || this.props.admin.optionsFilters.admin[0].menuSelector}
          />
          <div>
            <UsersContainer {...this.props} downloadUser={this.downloadUser.bind(this)} type='admin' />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ application: state.application, admin: state.admin });

AdminAdminsPage.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  admin: PropTypes.object,
};

export default connect(mapStateToProps)(AdminAdminsPage);
