import { message, Popconfirm, Radio, Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import {
  clearSpecialistPropositions,
  getSpecialist,
  getSpecialistPropositions,
  updateProfessionalExperiences,
  deleteProfessionalExperience,
  addProfessionalExperience,
} from '../../../../actions/specialistsActions';
import constants from '../../../../constants';
import { diffDates } from '../../../../helpers/dates';
import { uploadFile } from '../../../../helpers/manageUpload';
import InspectionsServices from '../../../../services/InspectionsServices';
import CardItemInDrawer from '../../../shared/molecules/CardItemInDrawer';
import DrawerWrapperBody from '../../../shared/organisms/DrawerWrapperBody';
import FileUploader from '../../../shared/organisms/FileUploader';
import FileInput from '../atoms/FileInput';
import CommentEditor from './CommentEditor';
import DocumentsAndInspectionTestStatus from './DocumentsAndInspectionTestStatus';
import InfosFacturationEditor from './InfosFacturationEditor';
import InfosFacturationViewer from './InfosFacturationViewer';
import InfosGeneralesEditor from './InfosGeneralesEditor';
import InfosGeneralesViewer from './InfosGeneralesViewer';
import SettingsEditor from './SettingsEditor';
import InfosProfessionalExperienceViewer from './InfosProfessionalExperienceViewer';
import InfosProfessionalExperienceEditor from './InfosProfessionalExperienceEditor';
import AddProfessionalExperienceEditor from './AddProfessionalExperienceEditor';
import InfosHabilitationsViewer from './InfosHabilitationsViewer';

const { Option } = Select;

const SpecialistDetailsDrawer = ({
  specialist,
  specialistId,
  specialistSkillsList,
  isOpen,
  closeCard,
  specialistPropositions,
  dispatch,
  isLoading,
  isLoadingGetProposition,
  updateSpecialist,
  appVersions,
  placement = 'right',
  mask = true,
  specialistKind,
}) => {
  const [inspectSkills, setInspectSkills] = useState([]);
  const [staffSkills, setStaffSkills] = useState([]);
  const [skills, setSkills] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingProfessionalExperiences, setIsEditingProfessionalExperiences] = useState(false);
  const [addProfessionalExperienceBtn, setAddProfessionalExperienceBtn] = useState(false);
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [isEditingFacturation, setIsEditingFacturation] = useState(false);
  const [isEditingSettings, setIsEditingSettings] = useState(false);
  const [isEditingSkills, setIsEditingSkills] = useState(false);
  const [isEditingDocumentsAndInspectionTestStatus, setIsEditingDocumentsAndInspectionTestStatus] = useState(false);
  const [inspectionTypeTest, setInspectionTypeTest] = useState(0);
  const [editLastRefresh, setEditLastRefresh] = useState(new Date().getTime());
  const [typeToUpload, setTypeToUpload] = useState('');
  const uploadInputRef = useRef();

  const fetchSpecialistAndPropositions = async () => {
    await Promise.all([getSpecialist(dispatch, specialistId), getSpecialistPropositions(dispatch, specialistId)]);
  };

  const saveProfile = async (value, updateProfessionalExperiencesBool = false) => {
    if (updateProfessionalExperiencesBool) await updateProfessionalExperiences(dispatch, value);
    else await updateSpecialist({ ...specialist, value, specialistKind });
    setIsEditing(false);
    setIsEditingComment(false);
    setIsEditingFacturation(false);
    setIsEditingSettings(false);
    setIsEditingSkills(false);
    setIsEditingDocumentsAndInspectionTestStatus(false);
    setIsEditingProfessionalExperiences(false);
    setEditLastRefresh(new Date().getTime());
    await fetchSpecialistAndPropositions();
  };

  const handleDeleteProfessionalExperience = async (professionalExperienceId) => {
    await deleteProfessionalExperience(dispatch, professionalExperienceId, specialist._id);
    setIsEditingProfessionalExperiences(false);
    await fetchSpecialistAndPropositions();
  };

  const handleAddProfessionalExperience = async (professionalExperienceData) => {
    await addProfessionalExperience(dispatch, professionalExperienceData, specialist._id);
    setAddProfessionalExperienceBtn(false);
    await fetchSpecialistAndPropositions();
  };

  const renderInfoGenerales = () => {
    if (!isEditing) {
      return <InfosGeneralesViewer
        {...specialist}
        specialistKind={specialistKind}
        appVersions={appVersions}
      />;
    }
    return <InfosGeneralesEditor
      {...specialist}
      specialistKind={specialistKind}
      lastRefresh={editLastRefresh}
      close={() => setIsEditing(false)}
      cancel={() => setIsEditing(false)}
      save={saveProfile}
    />;
  };

  const renderComment = () => {
    const { comments } = specialist;
    const fields = [
      { label: 'Expérience', value: 'experience' },
      { label: 'Disponibilité', value: 'availability' },
      { label: 'Personnalité', value: 'personality' },
      { label: 'Avertissement', value: 'warnings' },
      { label: 'Autre', value: 'other' },
      { label: 'Remontées inspecteur', value: 'specialistFeedback' },
    ];
    if (!isEditingComment) {
      return (
        <div className='specialistFicheComment'>
          <ul>
            {fields.map(
              (field, key) =>
                comments &&
                comments[field.value] && (
                  <li className='full' key={key}>
                    <label className={`${field.value === 'warnings' && 'red'}`}>{field.label}</label>
                    <div data-cy={[field.label]} style={{ whiteSpace: 'pre-wrap' }}>
                      {comments[field.value]}
                    </div>
                  </li>
                ),
            )}
          </ul>
        </div>
      );
    }
    return <CommentEditor {...specialist} fields={fields} close={() => setIsEditingComment(false)} cancel={() => setIsEditingComment(false)} save={saveProfile} />;
  };

  const renderSettings = () => (
    <SettingsEditor {...specialist} close={() => setIsEditingSettings(false)} cancel={() => setIsEditingSettings(false)} save={saveProfile} isEditing={isEditingSettings} />
  );

  const onChangeInspectionTypeTest = async (e) => {
    await setInspectionTypeTest(e.target.value);
  };

  const scheduleTestInspection = () => {
    InspectionsServices.createTestInspection(specialistId, inspectionTypeTest)
      .then((res) => {
        if (res?.body?.error) {
          message.error(`Erreur: inspection test non créee.\n${res.body.error}`);
        } else {
          message.success(`Inspection test nº ${res?.body?.createdInspections?.[0]?.readableId} programmée`);
        }
      })
      .catch((err) => message.error(`Erreur: ${err}`));
  };

  const handleInspectSkillChange = async (value) => {
    setInspectSkills(value);
  };

  const handleStaffSkillChange = async (value) => {
    setStaffSkills(value);
  };

  const upload = (type) => {
    setTypeToUpload(type);
    uploadInputRef.current.click();
  };

  // eslint-disable-next-line consistent-return
  const onInputFileChange = async () => {
    if (!uploadInputRef.current.files[0]) {
      return null;
    }
    if (uploadInputRef.current.files[0] && uploadInputRef.current.files[0].size > 5007200) {
      return alert('Le fichier sélectionné est trop volumineux');
    }

    try {
      const resultUploadFile = await uploadFile({
        type: uploadInputRef.current.files[0].type,
        body: uploadInputRef.current.files[0],
        specialistId,
        docType: typeToUpload,
      });
      if (resultUploadFile && resultUploadFile === 200) {
        await fetchSpecialistAndPropositions();
      }
    } catch {
      return alert("Echec de l'upload");
    }
  };

  const inspectSkillsOptions = specialistSkillsList.filter((skill) => skill.kind === 'inspect').map((option) => ({ value: option._id, label: option.label, key: option._id }));
  const staffSkillsOptions = specialistSkillsList.filter((skill) => skill.kind === 'staff').map((option) => ({ value: option._id, label: option.label, key: option._id }));
  const specialistStaffSkills = staffSkillsOptions?.filter((skill) => specialist?.skills?.includes(skill.value));
  const specialistInspectSkills = inspectSkillsOptions?.filter((skill) => specialist?.skills?.includes(skill.value) && skill.value);

  useEffect(() => {
    setInspectSkills(specialistInspectSkills.map((option) => option.value));
    setStaffSkills(specialistStaffSkills.map((option) => option.value));
  }, [specialist]);

  useEffect(() => {
    setSkills([...staffSkills, ...inspectSkills]);
  }, [staffSkills, inspectSkills]);

  const renderSkills = () => (
    <>
      <label>Inspect</label>
      <Select
        disabled={!isEditingSkills}
        mode='multiple'
        size='small'
        style={{ width: '100%', paddingBottom: '10px' }}
        placeholder='Aucune'
        value={inspectSkills}
        onChange={handleInspectSkillChange}
        dropdownStyle={{ zIndex: 10000000 }}
        optionFilterProp='children'
        data-cy='inspect'
      >
        {inspectSkillsOptions?.map((skill) => (
          <Option key={skill.key} value={skill.value}>
            {skill.label}
          </Option>
        ))}
      </Select>

      <label>Staff</label>
      <Select
        disabled={!isEditingSkills}
        mode='multiple'
        size='small'
        style={{ width: '100%' }}
        placeholder='Aucune'
        value={staffSkills}
        onChange={handleStaffSkillChange}
        dropdownStyle={{ zIndex: 10000000 }}
        optionFilterProp='children'
        data-cy='staff'
      >
        {staffSkillsOptions?.map((skill) => (
          <Option key={skill.key} value={skill.value}>
            {skill.label}
          </Option>
        ))}
      </Select>
    </>
  );

  const renderInfosFacturation = () => {
    if (!isEditingFacturation) {
      return <InfosFacturationViewer {...specialist} />;
    }
    return <InfosFacturationEditor {...specialist} close={() => setIsEditingFacturation(false)} cancel={() => setIsEditingFacturation(false)} save={saveProfile} />;
  };

  const renderProfessionalExperiences = () => {
    if (!isEditingProfessionalExperiences && !addProfessionalExperienceBtn) {
      return <InfosProfessionalExperienceViewer {...specialist} />;
    }
    if (isEditingProfessionalExperiences) {
      return <InfosProfessionalExperienceEditor
        {...specialist}
        cancel={() => setIsEditingProfessionalExperiences(false)}
        save={saveProfile}
        deleteProfessionalExperience={handleDeleteProfessionalExperience}
      />;
    }
    return <AddProfessionalExperienceEditor
      cancel={() => setAddProfessionalExperienceBtn(false)}
      save={handleAddProfessionalExperience}
    />;
  };

  const renderDocumentsAndTestInspection = () => (
    <DocumentsAndInspectionTestStatus
      {...specialist}
      close={() => setIsEditingDocumentsAndInspectionTestStatus(false)}
      cancel={() => setIsEditingDocumentsAndInspectionTestStatus(false)}
      save={saveProfile}
      isEditing={isEditingDocumentsAndInspectionTestStatus}
      specialistKind={specialistKind}
    />
  );

  const now = moment();

  useEffect(() => {
    (async () => fetchSpecialistAndPropositions())();
  }, [specialistId]);

  const createdAt = specialist.createdAt && moment(specialist.createdAt.value);
  const diffDate = createdAt ? diffDates(now, createdAt) : null;
  const daySlots = [
    { day: 0, label: 'Lundi', slots: specialist.availabilitySlots && specialist.availabilitySlots[0] },
    { day: 1, label: 'Mardi', slots: specialist.availabilitySlots && specialist.availabilitySlots[1] },
    { day: 2, label: 'Mercredi', slots: specialist.availabilitySlots && specialist.availabilitySlots[2] },
    { day: 3, label: 'Jeudi', slots: specialist.availabilitySlots && specialist.availabilitySlots[3] },
    { day: 4, label: 'Vendredi', slots: specialist.availabilitySlots && specialist.availabilitySlots[4] },
    { day: 5, label: 'Samedi', slots: specialist.availabilitySlots && specialist.availabilitySlots[5] },
    { day: 6, label: 'Dimanche', slots: specialist.availabilitySlots && specialist.availabilitySlots[6] },
  ];

  return (
    <DrawerWrapperBody
      title={
        Object.keys(specialist).length >= 1 && (
          <div className='specialistFicheFirstLine' style={{ fontSize: '1rem' }}>
            <div className='specialistFicheFirstLineLeft'>
              <div className='specialistFicheName'>
                <span>
                  <strong>
                    {specialist?.firstname} {specialist?.lastname}&nbsp;&nbsp;({specialist?.readableId})
                  </strong>
                </span>
                {specialistKind !== 'staff' && (
                  <span style={{ float: 'right', paddingRight: '10px' }}>
                    <span data-cy='numberOfInspections'>{specialist?.nbInspections || 0}</span>
                    <span className='grayLabel'>{specialist?.nbInspections > 1 ? <span> inspection(s)</span> : <span> inspection</span>}</span>
                  </span>
                )}
              </div>
              {createdAt && (
                <div className='specialistFicheInscriptionDate'>
                  <div>
                    {' '}
                    <span className='grayLabel'> Inscrit le </span> {moment(createdAt).format('LL')}
                  </div>
                  <div>
                    <span className='grayLabel'>Actif depuis </span>
                    {`${diffDate.y && diffDate.y > 1 ? `${diffDate.y} ans` : `${diffDate.y} an`} ${diffDate.m && `${diffDate.m} mois`} ${
                      diffDate.d && diffDate.d > 1 ? `${diffDate.d} jours` : `${diffDate.d} jour`
                    } `}
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      }
      isLoading={isLoading && isLoadingGetProposition}
      isOpen={isOpen}
      closeDrawer={() => {
        closeCard();
        dispatch(clearSpecialistPropositions());
      }}
      placement={placement}
      mask={mask}
    >
      {Object.keys(specialist).length > 0 && (
        <div className='specialistFicheBody'>
          {specialistPropositions && specialistPropositions.length > 0 && (
            <CardItemInDrawer title='Propositions par inspection'>
              <div className='specialistFichePart'>
                {specialistPropositions.map((proposition, key) => (
                  <div className='row' key={key}>
                    <Col xs={3}>
                      <label className='label'>{proposition.inspection.readableId} :</label>
                    </Col>
                    <Col xs={9}>
                      {proposition.propositionTimeSlots.map((slots, slotsKey) => {
                        const isDayDiff = moment(slots.start).format('dddd') !== moment(slots.end).format('dddd');
                        let slotText;
                        if (!isDayDiff) {
                          slotText = `${moment(slots.start).format('ddd LL')} de ${moment(slots.start).format('LT')} à ${moment(slots.end).format('LT')}`;
                        } else if (isDayDiff) {
                          slotText = `${moment(slots.start).format('ddd LL')} de ${moment(slots.start).format('LT')} au ${moment(slots.end).format('dddd LL')} à ${moment(
                            slots.end,
                          ).format('LT')}`;
                        }
                        return (
                          <p className='proposition-slots' key={slotsKey}>
                            {slotText}
                          </p>
                        );
                      })}
                    </Col>
                  </div>
                ))}
              </div>
            </CardItemInDrawer>
          )}
          {specialist.availabilitySlots && (
            <CardItemInDrawer title='Disponibilité'>
              <div className='specialistFichePart'>
                <div className='specialist-availability'>
                  <ul className='specialist-availability__list list listStyleNone'>
                    {daySlots.map(
                      (day, key) =>
                        day.slots.length > 0 && (
                          <li key={key} className='list__item'>
                            {' '}
                            {day.label} de
                            <ul className='specialist-availability__list'>
                              {day.slots.map((slot, slotKey) => (
                                <li key={slotKey}>
                                  {moment(slot.start).format('LT')} : {moment(slot.end).format('LT')}
                                </li>
                              ))}
                            </ul>
                          </li>
                        ),
                    )}
                  </ul>
                </div>
              </div>
            </CardItemInDrawer>
          )}
          <details open>
            <summary>
              <span> Informations générales </span>
              {!isEditing && (
                <button
                  className='buttonUpdate'
                  onClick={() => {
                    setIsEditing(true);
                  }}
                  data-cy='editGeneralInfos'
                >
                  Modifier
                </button>
              )}
            </summary>
            <div className='body'>
              <div className='specialistFiche'>
                <div className='specialistFichePart'>{renderInfoGenerales()}</div>
              </div>
            </div>
          </details>
          <details open>
            <summary>
              <span> Habilitation(s) </span>
            </summary>
            <div className='body'>
              <div className='specialistFiche'>
                <div className='specialistFichePart'>
                  <InfosHabilitationsViewer {...specialist} />
                </div>
              </div>
            </div>
          </details>
          <details open>
            <summary>
              <span> Expérience(s) professionnelle(s) </span>
              {(!isEditingProfessionalExperiences && !addProfessionalExperienceBtn) && (
                <div>
                  <button
                    style={{ right: '5.5rem' }}
                    className='buttonUpdate'
                    onClick={() => {
                      setAddProfessionalExperienceBtn(true);
                    }}
                    data-cy='addProfessionalExperience'
                  >
                    Ajouter
                  </button>
                  <button
                    className='buttonUpdate'
                    onClick={() => {
                      setIsEditingProfessionalExperiences(true);
                    }}
                    data-cy='editProfessionalExperiences'
                  >
                    Modifier
                  </button>
                </div>
              )}
            </summary>
            <div className='body'>
              <div className='specialistFiche'>
                <div className='specialistFichePart'>{renderProfessionalExperiences()}</div>
              </div>
            </div>
          </details>
          <details open>
            <summary>
              <span> Compétences </span>
              {!isEditingSkills ? (
                <button
                  className='buttonUpdate'
                  onClick={() => {
                    setIsEditingSkills(!isEditingSkills);
                  }}
                  data-cy='editSkills'
                >
                  Modifier
                </button>
              ) : (
                <button data-cy='save' className='buttonUpdate' onClick={() => saveProfile({ skills })}>
                  Sauvegarder
                </button>
              )}
            </summary>
            <div className='body'>
              <div className='specialistFiche'>
                <div className='specialistFichePart'>{renderSkills()}</div>
              </div>
            </div>
          </details>
          <details open>
            <summary>
              <span> Commentaires </span>
              {!isEditingComment ? (
                <button
                  className='buttonUpdate'
                  onClick={() => {
                    setIsEditingComment(true);
                  }}
                  data-cy='editComments'
                >
                  Modifier
                </button>
              ) : null}
            </summary>
            <div className='body'>
              <div className='specialistFiche'>
                <div className='specialistFichePart'>{renderComment()}</div>
              </div>
            </div>
          </details>

          {specialistKind !== 'staff' && (
            <details open>
              <summary>
                <span> Programmer l'inspection test </span>
                <Popconfirm
                  placement='topRight'
                  title={`Souhaitez-vous programmer une inspection test en ${inspectionTypeTest ? `carrosserie` : `mécanique`}, le dernier vendredi du mois ?`}
                  okText='Oui'
                  cancelText='Non'
                  onConfirm={scheduleTestInspection}
                >
                  <button className='buttonUpdate'>Programmer</button>
                </Popconfirm>
              </summary>
              <div className='body'>
                <div className='specialistFiche'>
                  <div className='specialistFichePart'>
                    <Radio.Group options={constants.inspectionTypeTest} onChange={onChangeInspectionTypeTest} defaultValue={inspectionTypeTest} />
                  </div>
                </div>
              </div>
            </details>
          )}
          <details open>
            <summary>
              <span> Documents </span>
            </summary>
            <div className='body'>
              <div className='specialistFiche'>
                <div className='specialistFichePart'>
                  <ul className='documents'>
                    <li>
                      <FileUploader identifier='CV' select={() => upload('cv')} uri={specialist.cv} label={'CV'} icon='documents' isUploading={false} />
                    </li>
                    <li>
                      <FileUploader
                        identifier='DIPLOME'
                        select={() => upload('mechanicDiploma')}
                        uri={specialist.mechanicDiploma}
                        label={'Diplome'}
                        icon='briefcase'
                        isUploading={false}
                      />
                    </li>
                    <li>
                      <FileUploader identifier='SHINE' select={() => upload('shine')} uri={specialist.shine} label={'Shine / KBIS'} isUploading={false} icon='person' />
                    </li>
                  </ul>
                  <ul className='documents'>
                    <li>
                      <FileUploader
                        identifier='PERMIS'
                        select={() => upload('drivingLicence')}
                        uri={specialist.drivingLicence}
                        label={'Permis Recto'}
                        isUploading={false}
                        icon='card'
                      />
                    </li>
                    <li>
                      <FileUploader
                        identifier='PERMIS'
                        select={() => upload('drivingLicence2')}
                        uri={specialist.drivingLicence2}
                        label={'Permis Verso'}
                        isUploading={false}
                        icon='card'
                      />
                    </li>
                  </ul>
                  <ul className='documents'>
                    <li>
                      <FileUploader
                        identifier='RECTO'
                        select={() => upload('ci_recto')}
                        uri={specialist.ci_recto}
                        label={'CI Recto / Passport'}
                        isUploading={false}
                        icon='browsers'
                      />
                    </li>
                    <li>
                      <FileUploader identifier='VERSO' select={() => upload('ci_verso')} uri={specialist.ci_verso} label={'CI Verso'} isUploading={false} icon='browsers' />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </details>
          <details open>
            <summary>
              {specialistKind !== 'staff' ? <span> Documents et inspection test </span> : <span> Documents </span>}
              {!isEditingDocumentsAndInspectionTestStatus ? (
                <button
                  className='buttonUpdate'
                  onClick={() => {
                    setIsEditingDocumentsAndInspectionTestStatus(true);
                  }}
                  data-cy='editDocsTest'
                >
                  Modifier
                </button>
              ) : null}
            </summary>
            <div className='body'>
              <div className='specialistFiche'>
                <div className='specialistFichePart'>{renderDocumentsAndTestInspection()}</div>
              </div>
            </div>
          </details>
          <details open>
            <summary>
              <span> Informations de facturation </span>
              {!isEditingFacturation ? (
                <button
                  className='buttonUpdate'
                  onClick={() => {
                    setIsEditingFacturation(true);
                  }}
                  data-cy='editBilling'
                >
                  Modifier
                </button>
              ) : null}
            </summary>
            <div className='body'>
              <div className='specialistFiche'>
                <div className='specialistFichePart'>{renderInfosFacturation()}</div>
              </div>
            </div>
          </details>
          <details open>
            <summary>
              <span> Paramètres </span>
              {!isEditingSettings ? (
                <button
                  className='buttonUpdate'
                  onClick={() => {
                    setIsEditingSettings(true);
                  }}
                  data-cy='editParams'
                >
                  Modifier
                </button>
              ) : null}
            </summary>
            <div className='body'>
              <div className='specialistFiche'>
                <div className='specialistFichePart'>{renderSettings()}</div>
              </div>
            </div>
          </details>
        </div>
      )}
      <FileInput
        type='file'
        style={{ display: 'none' }}
        ref={uploadInputRef}
        onChange={onInputFileChange}
      />
    </DrawerWrapperBody>
  );
};

SpecialistDetailsDrawer.propTypes = {
  specialistSkillsList: PropTypes.array,
  specialistPropositions: PropTypes.array,
  application: PropTypes.object,
  specialist: PropTypes.object,
  appVersions: PropTypes.object,
  style: PropTypes.object,
  title: PropTypes.string,
  specialistId: PropTypes.string,
  placement: PropTypes.string,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingGetProposition: PropTypes.bool,
  show: PropTypes.bool,
  edit: PropTypes.bool,
  mask: PropTypes.bool,
  dispatch: PropTypes.func,
  uploadFile: PropTypes.func,
  closeCard: PropTypes.func,
  getSpecialist: PropTypes.func,
  getSpecialistPropositions: PropTypes.func,
  updateSpecialist: PropTypes.func,
  addProfessionalExperience: PropTypes.func,
  updateProfessionalExperiences: PropTypes.func,
  deleteProfessionalExperience: PropTypes.func,
  close: PropTypes.func,
  specialistKind: PropTypes.string,
};

const mapStateToProps = (state) => ({
  application: state.application,
  isLoadingGetProposition: state.specialists.isLoadingGetProposition,
  isLoading: state.specialists.isLoading,
  specialist: state.specialists.specialist,
  specialistPropositions: state.specialists.specialistPropositions,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getSpecialist,
  getSpecialistPropositions,
  addProfessionalExperience,
  updateProfessionalExperiences,
  deleteProfessionalExperience,
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistDetailsDrawer);
