import * as actions from '../actions/EntitiesActions';

const initialState = {
  entities: [],
  entity: {},
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_ENTITIES:
    case actions.REQUEST_ENTITY:
    case actions.REQUEST_UPDATE_ENTITY: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case actions.GET_ENTITIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        entities: [...action.entities],
      };
    }
    case actions.GET_ENTITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        entity: action.entity,
      };
    }
    case actions.UPDATE_ENTITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case actions.GET_ENTITIES_ERROR:
    case actions.GET_ENTITY_ERROR:
    case actions.UPDATE_ENTITY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
