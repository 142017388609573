import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { copyDocumentInfos, saveDocumentInfos, updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import constants from '../../../constants';
import objects from '../../../helpers/customObject';
import { dataMapBd, validateTimeSlot } from '../../../helpers/dates';
import { putFileToBucket } from '../../../helpers/manageUpload';
import InspectionsServices from '../../../services/InspectionsServices';
import GooglePlacesAutoComplete from '../../shared/atoms/GooglePlacesAutoComplete';
import Loading from '../../shared/atoms/Loading';
import SpecialistSelectors from '../../shared/atoms/SpecialistSelectors';
import AddEditTimeSlotPicker from '../../shared/molecules/AddEditTimeSlotPicker';
import DatePicker from '../../shared/molecules/DateTimePicker';
import CguCheckbox from '../atoms/CguCheckBox';
import CreateInspectionsProgressStep from '../atoms/CreateInspectionsProgressStep';
import ConcessionsAndReportsSelector from '../molecules/ConcessionsAndReportsSelector';
import CreateInspectionStep2 from '../molecules/CreateInspectionStep2';
import CreateInspectionStep3 from '../molecules/CreateInspectionStep3';
import EditCreateInspectionCarInfo from '../molecules/EditCreateInspectionCarInfo';
import EditCreateInspectionResellerInfo from '../molecules/EditCreateInspectionResellerInfo';
import UploadDocuments from '../molecules/UploadDocuments';

const ModalEditCreateInspection = ({
  dispatch,
  inspection,
  isLoading,
  onRequestClose,
  lastUpdate,
  create,
  isOpen,
  user,
  inspectionDetails,
  modalToDisplay,
}) => {
  const pathTimeSlots = 'appointment.timeSlots.propositionTimeSlots';
  const adaptInspectionFormatForModal = (propsInspection) => {
    const adaptedInspection = propsInspection ? _.cloneDeep(propsInspection) : {};
    if (_.get(adaptedInspection, 'vehicle.immat.displayFormat')) {
      adaptedInspection.vehicle.immat = adaptedInspection.vehicle.immat.displayFormat;
    }
    if (_.get(adaptedInspection, 'appointment.date')) {
      adaptedInspection.appointment.date = moment(adaptedInspection.appointment.date).format(constants.formatDateTimeAppointment);
    }
    return adaptedInspection;
  };

  const [inspectionSelected, setInspectionSelected] = useState(adaptInspectionFormatForModal(inspection));
  const [newInspection, setNewInspection] = useState(adaptInspectionFormatForModal(inspection));
  const [newDateChoose, setNewDateChoose] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [concessionSelected, setConcessionSelected] = useState('');
  const [cguAccepted, setCguAccepted] = useState(false);
  const [step, setStep] = useState(1);
  const [inspectionMails, setInspectionMails] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const timeSlotsBd = _.get(newInspection, pathTimeSlots);

  let timeSlotsCustom = [];
  if (timeSlotsBd) timeSlotsCustom = timeSlotsBd.map(validateTimeSlot);
  const updateInspection = () => {
    const modifiedInspection = _.cloneDeep(newInspection);
    const momentDates = newDateChoose.map(({ start, end }) => ({
      start: moment(start).format(constants.formatDateTimeAppointment),
      end: moment(end).format(constants.formatDateTimeAppointment),
    }));
    setNewInspection(_.set(modifiedInspection, pathTimeSlots, momentDates));
  };

  const updateDateAppointment = (appointment) => {
    setNewDateChoose(appointment.map(dataMapBd));
  };

  const onInputValueChange = (field) => (value) => {
    const modifiedInspection = _.cloneDeep(newInspection);
    setNewInspection(_.set(modifiedInspection, field, value));
    return setNewInspection;
  };

  const selectNewFile = (filesUploaded) => {
    onInputValueChange('documents')(filesUploaded || newInspection?.documents);
    setFilesToUpload(filesUploaded || newInspection?.documents);
  };

  useEffect(() => updateInspection(), [newDateChoose]);

  useEffect(() => {
    setNewInspection(adaptInspectionFormatForModal(inspection));
    setErrorMessage('');
    setLoading(false);

    const inspections = _.cloneDeep(adaptInspectionFormatForModal(inspection));
    const timeSlots = _.get(inspections, pathTimeSlots);
    const momentDates = timeSlots?.map(({ start, end }) => ({
      start: moment(start).format(constants.formatDateTimeAppointment),
      end: moment(end).format(constants.formatDateTimeAppointment),
    }));
    setInspectionSelected(_.set(inspections, pathTimeSlots, momentDates));
    setInspectionMails(inspection.mails?.join(', '));
  }, [inspection?._id && !isLoading]);

  const saveInspection = () => {
    if (_.isEqual(inspectionSelected, newInspection)) return setErrorMessage('Aucune modification détectée !');
    setErrorMessage('');
    setLoading(true);
    updateInspection();
    const inspectionChanged = objects.difference(newInspection, inspectionSelected);
    if (_.get(inspectionChanged, pathTimeSlots)) {
      _.set(inspectionChanged, pathTimeSlots, _.get(newInspection, pathTimeSlots));
    }
    inspectionChanged._id = _.get(newInspection, '_id');
    return setTimeout(() => {
      InspectionsServices.updateInspections([inspectionChanged])
        .then((results) => {
          setLoading(false);
          if (results.status === 200) {
            setNewInspection({});
            onRequestClose({ didUpdate: true });
          } else {
            throw new Error(_.get(results, 'body.error') || "Une erreur s'est produite");
          }
        })
        .catch((e) => {
          setLoading(false);
          setErrorMessage(e.message);
        });
    }, 1000);
  };

  const saveDocumentsInfos = async (inspectionId) => {
    const documentsInfos = filesToUpload?.map((documentsInfo) => ({
      fileName: documentsInfo.name,
      type: documentsInfo.type,
    }));
    const resultSaveDocumentInfos = await saveDocumentInfos(dispatch, inspectionId, documentsInfos);
    if (resultSaveDocumentInfos.type === 'SAVE_DOCUMENT_INFOS_SUCCESS') {
      await Promise.all(filesToUpload?.map((fileToUpload, index) => putFileToBucket(resultSaveDocumentInfos.documentsUpdated.url[index], fileToUpload.type, fileToUpload)));
      await updatePlannedInspections(dispatch, lastUpdate);
    }
  };

  const copyDocumentsInfos = async (oldInspectionId, newInspectionId, oldDocumentsToCopy) => {
    const documentsToCopy = oldDocumentsToCopy?.map((oldDocumentToCopy) => ({
      fileName: oldDocumentToCopy.fileName,
      url: oldDocumentToCopy?.url.replace(oldInspectionId, newInspectionId),
    }));
    const resultSaveDocumentInfos = await copyDocumentInfos(dispatch, oldInspectionId, newInspectionId, documentsToCopy);
    if (resultSaveDocumentInfos.type === 'COPY_DOCUMENT_INFOS_SUCCESS') {
      await updatePlannedInspections(dispatch, lastUpdate);
    }
  };

  const createNewInspection = async () => {
    setErrorMessage('');
    if (!cguAccepted) {
      setErrorMessage('Veuillez accepter les conditions générales de vente avant de continuer');
    } else {
      setLoading(true);
      try {
        const commentForDuplicateInspection = {
          message: `Inspection dupliquée : ${inspection.readableId}`,
          user: user._id,
          createdAt: new Date(),
        };

        const dataToSend = (modalToDisplay === 'duplicate') ? {
          appointment: {
            address: newInspection?.appointment?.address,
            ...(newInspection?.appointment?.timeSlots?.propositionTimeSlots?.length >= 1) && { timeSlots: { propositionTimeSlots: newInspection?.appointment?.timeSlots?.propositionTimeSlots } },
          },
          userId: newInspection?.user?._id,
          reportId: newInspection?.reportId,
          seller: newInspection?.seller,
          vehicle: { ...newInspection?.vehicle, entryIntoServiceDate: newInspection?.vehicle?.entryIntoServiceDate },
          vin: newInspection?.vin,
          infos: newInspection?.infos,
          ...(newInspection?.vatType) && { vatType: newInspection?.vatType },
          ...(newInspection?.expectedPrice) && { expectedPrice: newInspection?.expectedPrice },
          ...(newInspection?.comments?.length >= 1) ? { comments: [...newInspection.comments, commentForDuplicateInspection] } : { comments: [commentForDuplicateInspection] },
        } : {
          ...newInspection,
        };
        const result = await InspectionsServices.createInspection([
          { ...dataToSend,
            type: 'plannedInspection',
          }], concessionSelected);
        if (result && result.status !== 200) {
          const {
            body: { error },
          } = result;
          throw new Error(error || "Une erreur s'est produite");
        }
        if (filesToUpload?.length >= 1) {
          await saveDocumentsInfos(result?.body?.createdInspections[0]?._id);
        }
        if (modalToDisplay === 'duplicate' && newInspection?.appointment?.documents?.length >= 1) await copyDocumentsInfos(newInspection._id, result.body.createdInspections[0]._id, newInspection.appointment.documents);
        setLoading(false);
        setStep(3);
      } catch (e) {
        setLoading(false);
        setErrorMessage(e.message);
      }
    }
  };

  const newInspectionChecker = () => {
    const keysToCheck = ['vehicle.brand', 'vehicle.model', 'vehicle.entryIntoServiceDate', 'seller.name', 'seller.phone', 'reportId', 'appointment.address'];
    const numberOfErrors = keysToCheck.filter((key) => !_.get(newInspection, key)).length;

    if (!cguAccepted) {
      setErrorMessage('Veuillez accepter les conditions générales de vente avant de continuer');
      return false;
    }
    if (create && (numberOfErrors > 0 && concessionSelected)) {
      setErrorMessage('Veuillez remplir tous les champs obligatoires');
      return false;
    }
    return true;
  };

  const renderStep = () => {
    if (!newInspectionChecker()) {
      setStep(1);
    }
    if (step === 2) {
      return (
        <CreateInspectionStep2
          inspection={newInspection}
          filesToUpload={filesToUpload}
          setStep={setStep}
          createNewInspection={createNewInspection}
          loading={loading}
          errorMessage={errorMessage}
        />
      );
    }
    if (step === 3) {
      return <CreateInspectionStep3 closeModal={onRequestClose} />;
    }
    return false;
  };

  function getAddressDetails(fullAddressName) {
    onInputValueChange('appointment.address')(fullAddressName);
  }
  const getLabelToShow = (modalToDisplayT) => {
    if (modalToDisplayT === 'duplicate') return 'Duplication';
    return 'Modification';
  };
  const renderContentModal = () => {
    if (step === 1) {
      return (
        <div className='modalContent'>
          <div className='mainTitle'>{modalToDisplay ? getLabelToShow(modalToDisplay) : 'Nouvelle inspection'}</div>
          {create && !modalToDisplay && <CreateInspectionsProgressStep step={step} />}
          <form className=''>
            <div className='horizontalForm'>
              <div className='horizontalFormPart'>
                <div className='formTitle'>Informations sur le véhicule à inspecter</div>
                <EditCreateInspectionCarInfo newInspection={newInspection} onInputValueChange={onInputValueChange} />
                <GooglePlacesAutoComplete
                  getAddressDetails={(fullAddressName) => getAddressDetails(fullAddressName)}
                  label="Adresse de l'inspection*"
                  placeholder='Rechercher par nom de business ou par adresse'
                  value={newInspection?.appointment?.address || ''}
                />
                <div className='formInput'>
                  <label>Emails en copie</label>
                  <textarea
                    data-cy='ccEmails'
                    rows='2'
                    value={inspectionMails || ''}
                    placeholder='Ex: john@doe.com, mim@doe.com'
                    onChange={(e) => {
                      onInputValueChange('mails')(e.target.value);
                      setInspectionMails(e.target.value);
                    }}
                  />
                </div>
                <div className='formInput'>
                  <label>VAT TYPE</label>
                  <div>
                    <Select
                      size='small'
                      style={{ width: '100%' }}
                      placeholder='Aucune'
                      value={constants.vatType.find((vat) => vat.value === _.get(newInspection, 'vatType')) || ''}
                      defaultValue={constants.vatType.find((vat) => vat.value === _.get(newInspection, 'vatType')) || ''}
                      onChange={(e) => onInputValueChange('vatType')(e.value) }
                      options={constants.vatType}
                    />
                  </div>
                </div>
                <div className='formInput'>
                  <label>Expected price</label>
                  <input
                    type='number'
                    placeholder='Prix attendu'
                    value={newInspection?.expectedPrice || ''}
                    onChange={(e) => onInputValueChange('expectedPrice')(e.target.value) }
                  />
                </div>
                <div className='legend'>*: champ obligatoire</div>
              </div>
              <div className='horizontalFormPart'>
                <div className='formTitle'>Informations sur le client</div>
                <EditCreateInspectionResellerInfo newInspection={newInspection} onInputValueChange={onInputValueChange} />
                {(create || modalToDisplay === 'duplicate') || (
                  <React.Fragment>
                    <div className='formInput'>
                      <label>Horaire de l'inspection</label>
                      <DatePicker
                        selected={_.get(newInspection, 'appointment.date') ? moment(newInspection.appointment.date, 'DD/MM/YYYY HH:mm') : null}
                        onChange={(date) => onInputValueChange('appointment.date')(moment(date).format('DD/MM/YYYY HH:mm'))}
                        dateFormat='dd/MM/yy HH:mm'
                        showTimeSelect
                        timeIntervals={5}
                      />
                    </div>
                    <SpecialistSelectors
                      type='text'
                      placeholder='Sélectionnez un spécialiste'
                      selectOptions={(spe) => onInputValueChange('specialist')(spe ? spe._id : null)}
                      label='Spécialiste'
                      init={{
                        _id: _.get(newInspection, 'specialist._id'),
                        name: `${_.get(newInspection, 'specialist.firstname')} ${_.get(newInspection, 'specialist.lastname')}`,
                      }}
                      scheduled={['scheduled', 'done', 'canceled', 'no_show'].includes(_.get(newInspection, 'status'))}
                    />
                  </React.Fragment>
                )}
                <div className='formInput'>
                  <label>Custom Id</label>
                  <input
                    data-cy='customId'
                    type='text'
                    value={newInspection?.customId || ''}
                    placeholder='Custom id'
                    onChange={(e) => onInputValueChange('customId')(e.target.value)}
                  />
                </div>
                {(create || modalToDisplay === 'duplicate') && <ConcessionsAndReportsSelector concessionIdChange={setConcessionSelected} reportIdChange={onInputValueChange} />}
                <div className='formInput'>
                  <label>Informations complémentaires</label>
                  <textarea
                    data-cy='inspectionInfos'
                    rows='4'
                    value={newInspection.infos || ''}
                    onChange={(e) => onInputValueChange('infos')(e.target.value)}
                    placeholder="Toutes informations utiles au bon déroulement de l'inspection."
                  />
                </div>
                {!create && (
                  <div className='formInput'>
                    <label>Commentaires</label>
                    <ul className='smallComments'>
                      {!!_.get(newInspection, 'comments.length') &&
                        newInspection.comments.map((comment, key) => (
                          <li key={`comment-${key}`}>
                            {comment.user && (
                              <div className='smallCommentsImage'>
                                {comment.user.profilePicture && <img src={comment.user.profilePicture} alt={comment.user.firstname} />}
                                <div className='' style={{ width: '30px' }}>
                                  {comment.user.firstname}
                                </div>
                              </div>
                            )}
                            <div className='smallCommentsText'>{comment.message}</div>
                          </li>
                        ))}
                      {!_.get(newInspection, 'comments.length') && <div>Pas de commentaires</div>}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className='horizontalForm'>
              <div className='horizontalFormPart'>
                <div className='formTitle'>(optionnel) Disponibilitées du client pour l'inspection de son véhicule</div>
                <div className='formInput'>
                  <AddEditTimeSlotPicker handler={updateDateAppointment} days={timeSlotsCustom} />
                </div>
              </div>
            </div>
            {create && <UploadDocuments files={newInspection.documents} selectNewFile={selectNewFile} onInputValueChange={onInputValueChange} />}
            {(create || modalToDisplay === 'duplicate') && <CguCheckbox setCguAccepted={setCguAccepted} cguAccepted={cguAccepted} />}
            {errorMessage && (
              <div className='error'>
                <p>{errorMessage}</p>
              </div>
            )}
            <div className='center formButton'>
              <button type='button' onClick={() => onRequestClose({})} className='negativ'>
                Annuler
              </button>
              {create ? (
                <button
                  type='button'
                  onClick={() => {
                    setErrorMessage('');
                    setStep(2);
                  }}
                  className='positiv'
                  disabled={loading}
                >
                  {loading ? <i className='fa fa-spinner fa-pulse' /> : 'Valider'}
                </button>
              ) : (
                <button
                  type='button'
                  onClick={ (modalToDisplay === 'duplicate') ? () => createNewInspection() : () => saveInspection()}
                  className='positiv'
                  disabled={loading}
                >
                  {loading ? <i className='fa fa-spinner fa-pulse' /> : 'Valider'}
                </button>
              )}
            </div>
          </form>
        </div>
      );
    }
    return renderStep();
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose({})}
      contentLabel='Modal nouvelle inspection'
      overlayClassName='modal-overlay'
      className='modal modalInspectionCreation'
    >
      {(!isLoading && Object.keys(inspectionDetails).length > 0) || create || modalToDisplay === 'duplicate' ? renderContentModal() : <Loading />}
    </Modal>
  );
};

ModalEditCreateInspection.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  inspection: PropTypes.object,
  user: PropTypes.object,
  create: PropTypes.bool,
  isLoading: PropTypes.bool,
  inspectionDetails: PropTypes.object,
  dispatch: PropTypes.func,
  updatePlannedInspections: PropTypes.func,
  saveDocumentInfos: PropTypes.func,
  lastUpdate: PropTypes.number,
  signedUrls: PropTypes.array,
  modalToDisplay: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updatePlannedInspections,
  saveDocumentInfos,
  copyDocumentInfos,
});

const mapStateToProps = (state) => ({
  user: state.application.user,
  inspectionDetails: state.plannedInspections.inspection,
  signedUrls: state.plannedInspections.signedUrls,
  isLoading: state.plannedInspections.isLoading,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditCreateInspection);
