import InspectionsServices from '../services/InspectionsServices';

// Action types
export const SERVER_KILLED_SESSION = 'SERVER_KILLED_SESSION';
export const REQUEST_INSPECTION = 'REQUEST_INSPECTION';
export const GET_INSPECTION = 'GET_INSPECTION';
export const GET_INSPECTION_SUCCESS = 'GET_INSPECTION_SUCCESS';
export const GET_INSPECTION_ERROR = 'GET_INSPECTION_ERROR';
export const CLEAR_INSPECTION = 'CLEAR_INSPECTION';
export const REQUEST_DRAWER_INSPECTION = 'REQUEST_DRAWER_INSPECTION';
export const GET_INSPECTION_DRAWER_SUCCESS = 'GET_INSPECTION_DRAWER_SUCCESS';
export const GET_INSPECTION_DRAWER_ERROR = 'GET_INSPECTION_DRAWER_ERROR';
export const CLEAR_INSPECTION_DRAWER = 'CLEAR_INSPECTION_DRAWER';
export const REQUEST_UPDATE_COMMENT_INSPECTION = 'REQUEST_UPDATE_COMMENT_INSPECTION';
export const UPDATE_COMMENT_INSPECTION = 'UPDATE_COMMENT_INSPECTION';
export const UPDATE_COMMENT_INSPECTION_SUCCESS = 'UPDATE_COMMENT_INSPECTION_SUCCESS';
export const UPDATE_COMMENT_INSPECTION_ERROR = 'UPDATE_COMMENT_INSPECTION_ERROR';
export const UPDATE_INSPECTIONS = 'UPDATE_PLANNED_INSPECTIONS';
export const UPDATE_INSPECTIONS_FAIL = 'UPDATE_PLANNED_INSPECTIONS_FAIL';
export const REQUEST_SAVE_DOCUMENT_INFOS = 'REQUEST_SAVE_DOCUMENT_INFOS';
export const SAVE_DOCUMENT_INFOS = 'SAVE_DOCUMENT_INFOS';
export const SAVE_DOCUMENT_INFOS_SUCCESS = 'SAVE_DOCUMENT_INFOS_SUCCESS';
export const SAVE_DOCUMENT_INFOS_ERROR = 'SAVE_DOCUMENT_INFOS_ERROR';
export const REQUEST_COPY_DOCUMENT_INFOS = 'REQUEST_COPY_DOCUMENT_INFOS';
export const COPY_DOCUMENT_INFOS = 'COPY_DOCUMENT_INFOS';
export const COPY_DOCUMENT_INFOS_SUCCESS = 'COPY_DOCUMENT_INFOS_SUCCESS';
export const COPY_DOCUMENT_INFOS_ERROR = 'COPY_DOCUMENT_INFOS_ERROR';
export const REQUEST_DELETE_DOCUMENT_INFOS = 'REQUEST_DELETE_DOCUMENT_INFOS';
export const DELETE_DOCUMENT_INFOS = 'DELETE_DOCUMENT_INFOS';
export const DELETE_DOCUMENT_INFOS_SUCCESS = 'DELETE_DOCUMENT_INFOS_SUCCESS';
export const DELETE_DOCUMENT_INFOS_ERROR = 'DELETE_DOCUMENT_INFOS_ERROR';
export const UPDATE_NUMBER_OF_MONTHS_SELECTED_INSPECTIONS = 'UPDATE_NUMBER_OF_MONTHS_SELECTED_INSPECTIONS';
export const VALIDATE_INSPECTION_SUCCESS = 'VALIDATE_INSPECTION_SUCCESS';
export const VALIDATE_INSPECTION_ERROR = 'VALIDATE_INSPECTION_ERROR';
export const VALIDATE_INSPECTION = 'VALIDATE_INSPECTION';
export const REQUEST_VALIDATE_INSPECTION = 'REQUEST_VALIDATE_INSPECTION';

// Action creator
export const serverKilledSession = () => ({
  type: SERVER_KILLED_SESSION,
});

export const validateInspectionRequest = () => ({
  type: REQUEST_VALIDATE_INSPECTION,
});

export const validateInspectionSuccess = (body) => ({
  type: VALIDATE_INSPECTION_SUCCESS,
  payload: body.inspections,
});

export const validateInspectionError = (error) => ({
  type: VALIDATE_INSPECTION_ERROR,
  error,
});

export const validateInspection = async (dispatch, inspectionId) => {
  try {
    const result = await InspectionsServices.validateInspection(inspectionId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(validateInspectionError(result.body.message || 'Failed'));
    return dispatch(validateInspectionSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestInspection = () => ({
  type: REQUEST_INSPECTION,
});

export const getInspectionSuccess = (inspection, view) => ({
  type: GET_INSPECTION_SUCCESS,
  inspection,
  view,
});

export const getInspectionError = (error) => ({
  type: GET_INSPECTION_ERROR,
  error,
});

export const getInspectionDrawerSuccess = (inspection) => ({
  type: GET_INSPECTION_DRAWER_SUCCESS,
  inspection,
});

export const getInspectionDrawerError = (error) => ({
  type: GET_INSPECTION_DRAWER_ERROR,
  error,
});

export const getInspection = async (dispatch, inspectionId, view, noLoading = false) => {
  try {
    let result;
    if (!noLoading) dispatch(requestInspection());
    if (view === 'modalEditionReport') {
      result = await InspectionsServices.getInspectionById(inspectionId);
    } else {
      result = await InspectionsServices.getPlannedInspectionsDetails({ inspectionId });
    }
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) {
      if (view === 'drawer') {
        return dispatch(getInspectionDrawerError(result.body.message || 'Failed'));
      }
      return dispatch(getInspectionError(result.body.message || 'Failed'));
    }
    if (view === 'drawer') {
      return dispatch(getInspectionDrawerSuccess(result.body));
    }
    return dispatch(getInspectionSuccess(result.body, view));
  } catch (e) {
    throw new Error(e);
  }
};

export const clearInspection = () => ({
  type: CLEAR_INSPECTION,
});

export const clearInspectionDrawer = () => ({
  type: CLEAR_INSPECTION_DRAWER,
});

export const requestUpdateInspection = () => ({
  type: REQUEST_UPDATE_COMMENT_INSPECTION,
});

export const updateCommentInspectionSuccess = (inspection) => ({
  type: UPDATE_COMMENT_INSPECTION_SUCCESS,
  inspection,
});

export const updateCommentInspectionError = (error) => ({
  type: UPDATE_COMMENT_INSPECTION_ERROR,
  error,
});

export const updateCommentInspection = async (dispatch, inspectionId, comment) => {
  try {
    dispatch(requestUpdateInspection());
    const result = await InspectionsServices.addNewCommentForAPlannedInspection({ inspectionId, text: comment });
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(updateCommentInspectionError(result.body.message || 'Failed'));
    return dispatch(updateCommentInspectionSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const updatePlannedInspections = (dispatch, since, resetPreviousInspections = false, filterOnInspectionsCanceledByMSA = false) => {
  InspectionsServices.getPlannedInspections(since, filterOnInspectionsCanceledByMSA).then((results) => {
    if (results.status === 200) {
      dispatch({
        type: UPDATE_INSPECTIONS,
        payload: results.body.inspections,
        resetPreviousInspections,
      });
    } else if (results.status === 401) {
      dispatch(serverKilledSession());
    } else {
      dispatch({
        type: UPDATE_INSPECTIONS_FAIL,
        payload: results.body.message || 'Failed',
      });
    }
  });
};

export const requestSaveDocumentInfosInspection = () => ({
  type: REQUEST_SAVE_DOCUMENT_INFOS,
});

export const saveDocumentInfosSuccess = (documentsUpdated) => ({
  type: SAVE_DOCUMENT_INFOS_SUCCESS,
  documentsUpdated,
});

export const saveDocumentInfosError = (error) => ({
  type: SAVE_DOCUMENT_INFOS_ERROR,
  error,
});

export const saveDocumentInfos = async (dispatch, inspectionId, documentsInfos, addNewDocument = false) => {
  try {
    dispatch(requestSaveDocumentInfosInspection());
    const result = await InspectionsServices.saveDocumentInfos(inspectionId, documentsInfos, addNewDocument);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(saveDocumentInfosError(result.body.error || 'Failed'));
    return dispatch(saveDocumentInfosSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestCopyDocumentInfosInspection = () => ({
  type: REQUEST_COPY_DOCUMENT_INFOS,
});

export const copyDocumentInfosSuccess = () => ({
  type: COPY_DOCUMENT_INFOS_SUCCESS,
});

export const copyDocumentInfosError = (error) => ({
  type: COPY_DOCUMENT_INFOS_ERROR,
  error,
});

export const copyDocumentInfos = async (dispatch, oldInspectionId, newInspectionId, documentsToCopy) => {
  try {
    dispatch(requestCopyDocumentInfosInspection());
    const result = await InspectionsServices.copyDocumentInfos(oldInspectionId, newInspectionId, documentsToCopy);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(copyDocumentInfosError(result.body.error || 'Failed'));
    return dispatch(copyDocumentInfosSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestDeleteDocumentInfosInspection = () => ({
  type: REQUEST_DELETE_DOCUMENT_INFOS,
});

export const deleteDocumentInfosSuccess = (resultServer) => ({
  type: DELETE_DOCUMENT_INFOS_SUCCESS,
  resultServer,
});

export const deleteDocumentInfosError = (error) => ({
  type: DELETE_DOCUMENT_INFOS_ERROR,
  error,
});

export const deleteDocumentInfos = async (dispatch, inspectionId, documentToDelete) => {
  try {
    dispatch(requestDeleteDocumentInfosInspection());
    const result = await InspectionsServices.deleteDocumentInfos(inspectionId, documentToDelete);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(deleteDocumentInfosError(result.body.error || 'Failed'));
    return dispatch(deleteDocumentInfosSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const updateNumberOfMonthsSelectedInspections = (dispatch, numberOfMonthsSelected) => dispatch({
  type: UPDATE_NUMBER_OF_MONTHS_SELECTED_INSPECTIONS,
  numberOfMonthsSelected,
});
