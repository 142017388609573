import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import AdminsActions from '../../../actions/AdminsActions';
import UsersActions from '../../../actions/UsersActions';

class Users extends React.Component {
  setUserState = async () => {
    await AdminsActions.setUserState({
      isActive: !this.props.isActive,
      status: this.props.isActive ? null : 'active',
      fromAdmin: true,
      _id: this.props._id,
    })(this.props.dispatch);
    let type = '';
    if (this.props.isAdmin) {
      type = this.props.admin.activeMenuFilters.admin || this.props.admin.optionsFilters.admin[0].menuSelector;
    }
    this.props.dispatch(AdminsActions.getActionToDispatch({ type }));
  };

  editUser = () => {
    this.props.dispatch(
      UsersActions.getActionToDispatch({
        type: 'PREPARE_USER_TO_EDIT',
        data: {
          _id: this.props._id,
          email: this.props.email,
          firstname: this.props.firstname,
          lastname: this.props.lastname,
          phone: this.props.phone,
          isActive: this.props.isActive,
          comment: this.props.comment,
          isAdmin: this.props.isAdmin,
        },
      }),
    );
    this.props.dispatch(UsersActions.getActionToDispatch({ type: 'SHOW_MODAL_EDIT_USER_INFOS' }));
  };

  uploadPiece = async (upload) => {
    const toUpload = {
      _id: this.props._id,
      fromAdmin: true,
    };
    toUpload[upload.id] = upload.url;
    await UsersActions.updateUser(toUpload)(this.props.dispatch);
    let type = '';
    type = this.props.admin.activeMenuFilters.specialist || this.props.admin.optionsFilters.specialist[0].menuSelector;
    this.props.dispatch(
      AdminsActions.getActionToDispatch({
        type,
      }),
    );
  };

  renderBtnTextActive = () => {
    if (this.props.admin.loadingUserState) {
      return <i className='fa fa-spinner fa-pulse'></i>;
    }
    if (this.props.isActive) {
      return 'Bloquer';
    }
    return 'Activer';
  };

  render() {
    return (
      <div className='users'>
        <div className='id'>
          <div className='value'>
            <b>{this.props.readableId || '_'}</b>
          </div>
          {this.props.comment ? <i className='fa fa-comments'></i> : null}
        </div>
        <div className='date'>
          <div className='value'>{moment(this.props.createdAt).format('LLL')}</div>
        </div>
        <div className='name'>
          <div className='value'>
            {this.props.firstname || ''} {this.props.lastname || ''}
          </div>
          <div className='mt10px'>{this.props.isActive ? <span className='badge success'>actif</span> : <span className='badge error'>inactif</span>}</div>
        </div>
        <div className='email'>
          <div className='value'>{this.props.email}</div>
        </div>
        <div className='phone'>
          <div className='value'>{this.props.phone || 'Non renseigné'}</div>
        </div>
        <div className='actions'>
          <div>
            <button className='btnaction' onClick={this.editUser.bind(this)} title='Éditer'>
              <i className='far fa-edit'></i>
            </button>
            <button className='btnaction' onClick={this.setUserState.bind(this)} title='Changer le statut'>
              <i className={this.props.isActive ? 'fa fa-thumbs-down' : 'fa fa-thumbs-up'}></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  dispatch: PropTypes.func,
  admin: PropTypes.object,
  application: PropTypes.object,
  _id: PropTypes.string,
  email: PropTypes.string,
  createdAt: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  readableId: PropTypes.number,
  comment: PropTypes.string,
  phone: PropTypes.string,
  isActive: PropTypes.bool,
  emailVerified: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default Users;
