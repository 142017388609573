import React from 'react';
import PropTypes from 'prop-types';

const CreateInspectionStep3 = ({ closeModal }) => (
  <div className='inspectionModalPart3'>
    <div className='center'>
      <img src='https://res.cloudinary.com/monspecialisteauto-com/image/upload/v1521020214/fix-sign.png' />
    </div>
    <React.Fragment>
      <p className='content center'>Votre inspection a bien été prise en charge par MonSpecialisteAuto.</p>
      <p className='content center'>Vous serez prévénu par mail de l'avancement de celle-ci.</p>
    </React.Fragment>
    <div className='formButton mt20px'>
      <button className='negativ' type='button' onClick={() => closeModal({})}>
        Fermer
      </button>
    </div>
  </div>
);

CreateInspectionStep3.propTypes = {
  closeModal: PropTypes.func,
};

export default CreateInspectionStep3;
