import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../constants';

const RenderValidButtonText = ({ data }) => {
  const { isLoading } = data;
  let result;
  if (isLoading) {
    result = (
      <span>
        {' '}
        <i className='fa fa-spinner fa-pulse' /> {constants.label.saveInLoading}{' '}
      </span>
    );
  } else {
    result = constants.label.save;
  }
  return (
    <button type='submit' className='positiv' disabled={isLoading}>
      {result}
    </button>
  );
};

RenderValidButtonText.propTypes = {
  data: PropTypes.any,
};

export default RenderValidButtonText;
