import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import ActionButton from '../atoms/ActionButton';
import QuickFilterInput from '../atoms/QuickFilterInput';

const ActionsBar = forwardRef(
  (
    {
      onClickRefresh,
      onClickExportExcel,
      onQuickFilterChange,
      adminInterface,
      selectedRows,
      onClickMultipleAssign,
      onSelectAllInspection,
      onClickMultipleUnassign,
      onClickMultipleNoShow,
      onClickMultipleCancel,
      onClickChangeStatus,
      onClickSendMissionsNotificationsToStaffers,
      onClickSendNotificationsToSpecialists,
      onSelectMonthsLoadingInspections,
      onClickSendSmsToStaffers,
      specialistKind,
      interfaceView = '',
      mapView = false,
      billingView = false,
      specialistView = false,
      loadingInspectionsByMonths,
      numberOfMonthsSelected = 3,
    },
    ref,
  ) => {
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState(true);
    const [unassignButtonDisabled, setUnassignButtonDisabled] = useState(true);
    const [noShowButtonDisabled, setNoShowButtonDisabled] = useState(true);
    const [assignButtonDisabled, setAssignButtonDisabled] = useState(true);
    const [changeStatusBillingButtonDisabled, setChangeStatusBillingButtonDisabled] = useState(true);
    const [changeStatusMissionButtonDisabled, setChangeStatusMissionButtonDisabled] = useState(true);
    const [sendNotificationsToSpecialistsButtonDisabled, setSendNotificationsToSpecialistsButtonDisabled] = useState(true);
    const [sendMissionsNotificationsToStaffersButtonDisabled, setSendMissionsNotificationsToStaffersButtonDisabled] = useState(true);

    const onChange = (e) => {
      onSelectAllInspection(e.target.checked);
    };

    useEffect(() => {
      if (selectedRows) {
        setCancelButtonDisabled(
          selectedRows.length < 1 ||
          selectedRows.some(
            (i) =>
              interfaceView === 'missionInterface' ? !['ordered', 'scheduled', 'ongoing', 'pending_validation'].includes(i.status) : !['ordered', 'scheduled', 'pending_validation'].includes(i.status),
          ),
        );
        setNoShowButtonDisabled(selectedRows.length < 1 || selectedRows.some((i) => !['ordered', 'scheduled', 'pending_validation', 'done', 'canceled'].includes(i.status)));
        setUnassignButtonDisabled(selectedRows.length < 1 || selectedRows.some((i) => i.status !== 'scheduled'));
        setAssignButtonDisabled(selectedRows.length < 1 || selectedRows.some((i) => !['pending_validation', 'ordered'].includes(i.status)));
        setChangeStatusBillingButtonDisabled(selectedRows.length < 1 || selectedRows.some((i) => i.status !== selectedRows[0].status));
        setChangeStatusMissionButtonDisabled(selectedRows.length < 1 || selectedRows.some((i) => !['scheduled', 'ongoing'].includes(i.status)));
        setSendNotificationsToSpecialistsButtonDisabled(selectedRows.length < 1);
        setSendMissionsNotificationsToStaffersButtonDisabled(selectedRows.length < 1 || selectedRows.some((i) => !['pending_validation', 'ordered'].includes(i.status)));
      }
    }, [selectedRows]);

    /* --------------- Custom Render Select Months ---------- */

    const renderSelectMonthsInspections = () => (
      <>
        <div
          className='select'
          style={{
            display: 'inline-block',
            marginLeft: '10px',
            borderLeft: '1px solid #a9a9a9',
            paddingLeft: '10px',
          }}
        >
          {loadingInspectionsByMonths && (
            <span style={{ marginRight: '5px' }}>
              <i className='fa fa-spinner fa-pulse' />
            </span>
          )}
          <select
            onChange={(e) => {
              onSelectMonthsLoadingInspections(parseInt(e.target.value, 10));
            }}
            value={numberOfMonthsSelected}
          >
            <option value='3'>3 mois</option>
            <option value='6'>6 mois</option>
            <option value='9'>9 mois</option>
            <option value='12'>12 mois</option>
          </select>
        </div>
      </>
    );

    /* --------------- Custom Render View ------------------ */
    const renderInspectionActionButton = () => {
      let typeOfAssignement;
      if (interfaceView === 'missionInterface') {
        typeOfAssignement = 'mission(s)';
      } else {
        typeOfAssignement = 'inspection(s)';
      }

      return (
        <>
          <ActionButton
            shape='circle'
            title={`${assignButtonDisabled ? `Assigner des ${typeOfAssignement}` : `Assigner ${selectedRows.length} ${typeOfAssignement}`}`}
            disabled={assignButtonDisabled}
            text={<i className='fas fa-user-plus' />}
            onClick={onClickMultipleAssign}
            gutterRight
          />
          <ActionButton
            shape='circle'
            title={`${unassignButtonDisabled ? `Remettre en attente des ${typeOfAssignement}` : `Mettre en attente ${selectedRows.length} ${typeOfAssignement}`}`}
            disabled={unassignButtonDisabled}
            text={<i className='fas fa-user-clock' />}
            onClick={onClickMultipleUnassign}
            gutterRight
          />
          {interfaceView !== 'missionInterface' && (
            <ActionButton
              shape='circle'
              title={`${noShowButtonDisabled ? 'Marquer comme non présentée' : `Marquer ${selectedRows.length} ${typeOfAssignement} comme non présentées`}`}
              color='orange'
              disabled={noShowButtonDisabled}
              text={<i className='fas fa-user-slash' />}
              onClick={onClickMultipleNoShow}
              gutterRight
            />
          )}
          <ActionButton
            shape='circle'
            title={`${cancelButtonDisabled ? `Annuler des ${typeOfAssignement}` : `Annuler ${selectedRows.length} ${typeOfAssignement}`}`}
            color='red'
            disabled={cancelButtonDisabled}
            text={<i className='fas fa-ban' />}
            onClick={onClickMultipleCancel}
          />

          {interfaceView === 'missionInterface' && (
            <>
              <ActionButton
                shape='circle'
                title={`${changeStatusMissionButtonDisabled ? 'Modifier le statut des missions sélectionnées' : `Modifier le statut de ${selectedRows.length} missions`}`}
                disabled={changeStatusMissionButtonDisabled}
                text={<i className='fas fa-edit' />}
                gutterLeft={true}
                onClick={onClickChangeStatus}
              />
              <ActionButton
                shape='circle'
                title={`${
                  sendMissionsNotificationsToStaffersButtonDisabled
                    ? 'Envoyer une notification pour la mission sélectionnée'
                    : `Envoyer une notification pour les ${selectedRows.length} missions sélectionnées`
                }`}
                disabled={sendMissionsNotificationsToStaffersButtonDisabled}
                text={<i className='fas fa-paper-plane' />}
                gutterLeft={true}
                onClick={onClickSendMissionsNotificationsToStaffers}
              />
            </>
          )}
          {renderSelectMonthsInspections()}
        </>
      );
    };

    const renderBillingActionButton = () => (
      <>
        <ActionButton
          shape='circle'
          title={`${changeStatusBillingButtonDisabled ? 'Modifier le statut des factures sélectionnées' : `Modifier le statut de ${selectedRows.length} factures`}`}
          disabled={changeStatusBillingButtonDisabled}
          text={<i className='fas fa-edit' />}
          onClick={onClickChangeStatus}
        />
      </>
    );

    const renderSpecialistActionButton = (view) => (
      <>
        <ActionButton
          shape='circle'
          title={`${
            sendNotificationsToSpecialistsButtonDisabled
              ? 'Envoyer une notification au spécialiste sélectionné'
              : `Envoyer une notification aux ${selectedRows.length} spécialistes sélectionnés`
          }`}
          disabled={sendNotificationsToSpecialistsButtonDisabled}
          text={<i className='fa fa-paper-plane' />}
          onClick={onClickSendNotificationsToSpecialists}
        />
        { view === 'staff' && (
          <>
            <span className='separator' />
            <ActionButton
              shape='circle'
              title='Envoyer un sms à un ou plusieurs staffeurs sélectionnés'
              text={<i className='fas fa-sms' />}
              onClick={onClickSendSmsToStaffers}
            />
          </>
        )}
      </>
    );

    const renderMapActionBar = () => (
      <div className='inspectionTileItem'>
        <div className='inspectionButton'>
          <div className='firstBlocButton'>
            <span className='separator' />
            {renderInspectionActionButton()}
          </div>
          <Checkbox onChange={onChange}> Tout sélectionner </Checkbox>
        </div>
      </div>
    );

    const renderActionBar = () => (
      <div className='actionsContainer'>
        <div>
          <ActionButton shape='circle' title='Actualiser' text={<i className='fas fa-sync' />} onClick={onClickRefresh} />
          <span className='separator' />
        </div>
        <div>
          <>
            { ['adminInterface', 'missionInterface'].includes(interfaceView) && adminInterface && renderInspectionActionButton()}
            {interfaceView === 'concessionInterface' && renderSelectMonthsInspections()}
            {billingView && renderBillingActionButton()}
            {specialistView && renderSpecialistActionButton(specialistKind)}
            <span className='separator' />
          </>
          <ActionButton shape='circle' title='Exporter le tableau' text={<i className='fas fa-file-excel' />} onClick={onClickExportExcel} />
          <span className='separator' />
          <QuickFilterInput ref={ref} onQuickFilterChange={onQuickFilterChange} />
        </div>
      </div>
    );

    /* --------------- End Custom View ----------------- */

    return <>{mapView ? renderMapActionBar() : renderActionBar()}</>;
  },
);

ActionsBar.propTypes = {
  adminInterface: PropTypes.bool,
  selectedRows: PropTypes.array,
  onClickMultipleAssign: PropTypes.func,
  onClickMultipleUnassign: PropTypes.func,
  onClickMultipleNoShow: PropTypes.func,
  onClickMultipleCancel: PropTypes.func,
  onClickChangeStatus: PropTypes.func,
  onClickSendMissionsNotificationsToStaffers: PropTypes.func,
  onClickRefresh: PropTypes.func,
  onClickExportExcel: PropTypes.func,
  onQuickFilterChange: PropTypes.func,
  onSelectAllInspection: PropTypes.func,
  onSelectMonthsLoadingInspections: PropTypes.func,
  onClickSendNotificationsToSpecialists: PropTypes.func,
  interfaceView: PropTypes.string,
  mapView: PropTypes.bool,
  billingView: PropTypes.bool,
  specialistView: PropTypes.bool,
  loadingInspectionsByMonths: PropTypes.bool,
  numberOfMonthsSelected: PropTypes.number,
  onClickSendSmsToStaffers: PropTypes.func,
  specialistKind: PropTypes.string,
};

ActionsBar.displayName = 'ActionsBar';

export default ActionsBar;
