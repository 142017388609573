import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';

const PriceBox = (props) => {
  const { entity, kindVehicle, value, valueMax, isPrivate, isInterval, name } = props;
  const [intervalState, setIntervalState] = useState(!!valueMax);

  const onIntervalClick = () => {
    setIntervalState(!intervalState);
    if (props.isGlobalMsa) {
      return props.handleIntervalCheckbox(props.globalMsaRepairCostId, kindVehicle, props.globalMsaRepairCostsInfo, isPrivate);
    }
    return props.handleIntervalCheckbox(entity, kindVehicle, isPrivate);
  };

  return (
    <React.Fragment>
      <div>
        <DebounceInput
          debounceTimeout={600}
          style={{
            padding: 12,
            paddingRight: 0,
            width: 85,
            borderRadius: 6,
            backgroundColor: '#fdfdfd',
            border: 'solid 1px #ebebeb',
            height: 'auto',
            marginBottom: 5,
          }}
          type='text'
          className='input-frevo fullWidth'
          value={value !== -1 ? value : null} // -1 will be set by the API when Global prices differ
          placeholder={value !== -1 ? 'non-défini' : 'prix differents'}
          name={props.name}
          onChange={(e) => {
            if (props.isGlobalMsa) {
              return props.handlePriceUpdate(e, props.globalMsaRepairCostId, kindVehicle, props.globalMsaRepairCostsInfo, isPrivate);
            }
            return props.handlePriceUpdate(entity, kindVehicle, e, isPrivate);
          }}
        />
        {isInterval && (
          <DebounceInput
            debounceTimeout={600}
            style={{
              padding: 12,
              paddingRight: 0,
              width: 85,
              borderRadius: 6,
              backgroundColor: '#fdfdfd',
              border: 'solid 1px #ebebeb',
              height: 'auto',
              marginBottom: 5,
            }}
            type='text'
            className='input-frevo fullWidth'
            value={valueMax !== -1 ? valueMax : null}
            placeholder={valueMax !== -1 ? 'non-défini' : 'prix differents'}
            name={`${props.name}Max`}
            onChange={(e) => {
              if (props.isGlobalMsa) {
                return props.handlePriceUpdate(e, props.globalMsaRepairCostId, kindVehicle, props.globalMsaRepairCostsInfo, isPrivate, isInterval);
              }
              return props.handlePriceUpdate(entity, kindVehicle, e, isPrivate, isInterval);
            }}
          />
        )}
      </div>
      <div>
        <label className='checkboxContainer' htmlFor={`${name}${entity}interval`}>
          Intervale
          <input type='checkbox' name={name + entity} checked={intervalState} onChange={onIntervalClick} id={`${name}${entity}interval`} />
          <span className='checkmark' />
        </label>
      </div>
    </React.Fragment>
  );
};

PriceBox.propTypes = {
  name: PropTypes.string,
  entity: PropTypes.string,
  kindVehicle: PropTypes.string,
  isPrivate: PropTypes.bool,
  value: PropTypes.number,
  valueMax: PropTypes.number,
  isInterval: PropTypes.bool,
  handlePriceUpdate: PropTypes.func,
  handleIntervalCheckbox: PropTypes.func,
  globalMsaRepairCostId: PropTypes.object,
  isGlobalMsa: PropTypes.bool,
  globalMsaRepairCostsInfo: PropTypes.array,
};
export default PriceBox;
