import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useToasts } from 'react-toast-notifications';
import MissionsServices from '../../../services/MissionsServices';
import RenderError from '../../shared/atoms/RenderError';
import { orangeLine } from '../../../helpers/styles';

Modal.setAppElement('body');

const ModalSendMissionsNotificationsToStaffers = (props) => {
  const [missionsIds, setMissionsIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { addToast } = useToasts();

  useEffect(() => {
    props.allMissions.modalData.map((mission) => setMissionsIds((prevArray) => [...prevArray, mission._id]));
  }, []);

  async function sendMissionsNotificationsToStaffers() {
    try {
      setLoading(true);
      const response = await MissionsServices.sendMissionsNotificationsToStaffers({
        missionsIds,
      });
      if (response && response.status !== 200) {
        setLoading(false);
        const { body: { error: errorServer } } = response;
        throw new Error(errorServer || "Une Erreur est survenue pendant l'envoi de la notification");
      }
      addToast('Notification envoyée avec succès', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 2000 });
      setLoading(false);
      props.closeModal(true);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  const selectedMissions = (
    <div className='mission-inputs-container'>
      <ul className='mission-ids-list'>
        {props.allMissions.modalData.map((selectedMission, i) => (
          <li key={i}>
            Id Mission : {selectedMission.readableId}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <Modal onRequestClose={props.closeModal} shouldCloseOnOverlayClick={false} isOpen={true} contentLabel='Modal envoi notification' overlayClassName='modal-overlay' className='modal'>
      <div className='mission-modal '>
        <>
          <div className='mission-title'>Envoi de notification(s)</div>
          <div className='mission-subtitle'>Vous êtes sur le point d'envoyer une notification pour la ou les mission(s) suivante(s) :</div>
          {selectedMissions}
        </>
        <div>
          {orangeLine()}
          <p className='content'>Souhaitez-vous confirmer ?</p>
          {!loading ? (
          <div className='center formButton mt20px'>
            <button type='button' onClick={() => props.closeModal()} className='negativ'>
              Non
            </button>
            <button type='button' onClick={() => sendMissionsNotificationsToStaffers()} className='positiv'>
              Oui, je confirme
            </button>
          </div>
          ) : (
            <div className='center formButton mt20px'>
              {loading
                ? <button disabled type='button' className='positiv'>
                <span>
                  <i className='fa fa-spinner fa-pulse'/>
                  Envoi de la notification en cours
                </span>
                </button>
                : <button onClick={props.closeModal} type='button' className='negativ'>
                  Annuler
                </button>
              }
            </div>
          )}
        </div>
      </div>

      {error && <RenderError error={error} />}
    </Modal>
  );
};

ModalSendMissionsNotificationsToStaffers.propTypes = {
  allMissions: PropTypes.object,
  closeModal: PropTypes.func,
};

export default ModalSendMissionsNotificationsToStaffers;
