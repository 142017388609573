import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ConcessionsActions from '../../../../actions/Concessions';

const EntitiesActionsCell = ({ data, dispatch }) => {
  if (!data) {
    return <div></div>;
  }

  return (
    <React.Fragment>
      <div style={{ fontSize: 'x-large', display: 'inline-flex', padding: 5 }}>
        <div
          style={{ marginRight: 15 }}
          title={'Voir le groupe'}
          onClick={() => dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'viewEntity', modalData: data }))}
        >
          <i className={'fa fa-eye'} />
        </div>
        <div
          style={{ marginRight: 15 }}
          title={'Éditer le groupe'}
          onClick={() => dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'editEntity', modalData: data }))}
        >
          <i className={'fa fa-cog'} />
        </div>
      </div>
    </React.Fragment>
  );
};

EntitiesActionsCell.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(null, mapDispatchToProps)(EntitiesActionsCell);
