import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ConcessionsServices from '../../../../services/ConcessionsServices';

const ConcessionSelect = ({
  onSelectMultiple,
  onSelect,
  entityId,
  isMulti,
  selectedSites,
  styles,
  disabledInputConcessionView,
  isClearable = true,
}) => {
  const [concessions, setConcessions] = useState([]);
  const [options, setOptions] = useState([]);

  const getAllConcessions = async () => {
    const allConcessions = await ConcessionsServices.getAllConcessions();
    const allConcessionsByEntityId = allConcessions?.filter((concession) => String(concession?.entity?._id) === String(entityId));
    const concessionsToDisplay = entityId ? allConcessionsByEntityId : allConcessions;
    setConcessions(concessionsToDisplay);
  };

  const handleSelect = (e) => {
    if (isMulti) {
      return onSelectMultiple(e);
    }
    return onSelect(e.concession);
  };

  useEffect(() => {
    const allOptions = concessions.map((concession) => ({ value: concession._id, label: concession.name, concession }));
    setOptions(allOptions);
  }, [concessions]);

  useEffect(() => {
    (async () => getAllConcessions())();
  }, [entityId]);

  return (
    <div data-cy='selectConcession' className='search-input max'>
      <Select
        styles={styles}
        className='multi-select'
        value={selectedSites && !isMulti ? { value: selectedSites._id, label: selectedSites?.name } : selectedSites}
        onChange={(e) => handleSelect(e)}
        options={options}
        isSearchable
        isClearable={isClearable}
        isMulti={isMulti}
        placeholder='Rechercher un site'
        isDisabled={disabledInputConcessionView}
      />
    </div>
  );
};

ConcessionSelect.propTypes = {
  onSelect: PropTypes.func,
  onSelectMultiple: PropTypes.func,
  entityId: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  selectedSites: PropTypes.any,
  styles: PropTypes.object,
  concessions: PropTypes.array,
  options: PropTypes.array,
  disabledInputConcessionView: PropTypes.bool,
};

export default ConcessionSelect;
