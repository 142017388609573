/* eslint-disable import/prefer-default-export */

export const msaCarPartsDataList = (userCanEdit) => {
  const data = {
    example: {
      title: 'Pièce auto MSA',
      menu: [
        {
          icon: 'fa fa-plus',
          displayName: 'Nouvelle pièce auto MSA',
          handler: (openModal) => openModal('createMsaCarPart'),
        },
      ],
      fields: [
        {
          displayName: 'ID',
          variable: '_id',
          sortable: true,
          size: {
            xs: 2,
          },
        },
        {
          displayName: 'Nom pièce',
          variable: 'label',
          sortable: true,
          size: {
            xs: 8,
          },
        },
        {
          displayName: 'Actions',
          action: true,
          actions: [
            {
              name: 'editMsaCarPart',
              icon: 'fas fa-edit',
              tooltip: 'Éditer la pièce auto MSA',
              handler: (openModal) => openModal('editMsaCarPart'),
            },
          ],
          customClass: 'action',
          size: {
            xs: 2,
          },
        },
      ],
    },
  };

  if (!userCanEdit) {
    data.example.menu = [];
    data.example.fields[5].actions[0].icon = 'fa fa-eye';
    data.example.fields[5].actions[0].tooltip = 'Voir la pièce auto MSA';
    delete data.example.fields[5].actions[1];
  }
  return data;
};

export const cupiCarPartsDataList = (userCanEdit) => {
  const data = {
    example: {
      title: 'Pièce auto CUPI',
      menu: [],
      fields: [
        {
          displayName: 'ID',
          variable: '_id',
          sortable: true,
          size: {
            xs: 2,
          },
        },
        {
          displayName: 'CUPI',
          variable: 'cupiShortNumber',
          sortable: true,
          size: {
            xs: 1,
          },
        },
        {
          displayName: 'Nom MSA',
          variable: 'labelMsa',
          sortable: true,
          size: {
            xs: 3,
          },
        },
        {
          displayName: 'Nom GT',
          variable: 'labelGt',
          sortable: true,
          size: {
            xs: 3,
          },
        },
        {
          displayName: 'Groupe',
          variable: 'functionalGroup',
          sortable: true,
          size: {
            xs: 1,
          },
        },
        {
          displayName: 'Utilisé',
          variable: 'usedByMsa',
          sortable: true,
          type: 'bool',
          size: {
            xs: 1,
          },
        },
        {
          displayName: 'Actions',
          action: true,
          actions: [
            {
              name: 'editMsaCarPart',
              icon: 'fas fa-edit',
              tooltip: 'Éditer',
              handler: (openModal) => openModal('editCupiCarPart'),
            },
          ],
          customClass: 'action',
          size: {
            xs: 1,
          },
        },
      ],
    },
  };

  if (!userCanEdit) {
    data.example.menu = [];
    data.example.fields[5].actions[0].icon = 'fa fa-eye';
    data.example.fields[5].actions[0].tooltip = 'Voir la pièce';
    delete data.example.fields[5].actions[1];
  }
  return data;
};
