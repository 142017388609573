import PropTypes from 'prop-types';
import React from 'react';
import RenderCancelButtonText from '../atoms/RenderCancelButtonText';
import RenderValidButtonText from '../atoms/RenderValidButtonText';

const ModalFooterButton = ({ actionCancel, data }) => (
  <div className='center formButton'>
    <RenderCancelButtonText actionCancel={actionCancel} />
    <RenderValidButtonText data={data} />
  </div>
);

ModalFooterButton.propTypes = {
  actionCancel: PropTypes.func,
  data: PropTypes.any,
};

export default ModalFooterButton;
