import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const EditCreateInspectionResellerInfo = ({ newInspection, onInputValueChange }) => (
  <div>
    <div className='formInput'>
      <label>Nom du client*</label>
      <input data-cy='sellerName' type='text' value={_.get(newInspection, 'seller.name') || ''} placeholder='Nom du client' onChange={(e) => onInputValueChange('seller.name')(e.target.value)} />
    </div>
    <div className='formInput'>
      <label>Téléphone du client*</label>
      <input
        data-cy='sellerPhone'
        type='text'
        value={_.get(newInspection, 'seller.phone') || ''}
        placeholder='Téléphone du client'
        onChange={(e) => onInputValueChange('seller.phone')(e.target.value)}
      />
    </div>
    <div className='formInput'>
      <label>Email du client (suivi qualité)</label>
      <input
        data-cy='sellerEmail'
        type='email'
        pattern='^[^@ ]+@[^@ ]+\.[^@ ]+$'
        value={_.get(newInspection, 'seller.email') || ''}
        placeholder='Email du client'
        onChange={(e) => onInputValueChange('seller.email')(e.target.value)}
      />
    </div>
  </div>
);

EditCreateInspectionResellerInfo.propTypes = {
  newInspection: PropTypes.shape({}),
  onInputValueChange: PropTypes.func,
};

export default EditCreateInspectionResellerInfo;
