import _ from 'lodash';
import React from 'react';
import DefaultGridOptions from '../../../shared/atoms/DefaultGridOptions';
import ConcessionsActionsCell from '../../shared/atoms/ConcessionsActionsCell';
import { roleTranslation } from '../../shared/atoms/gridUtils';
import UsersActionsCell from '../../shared/atoms/UsersActionsCell';
import { missionStatusTranslation } from '../../../missions/atoms/filterOptions';
import MissionsActionsCell from '../../../missions/atoms/MissionsActionsCell';

const createConcessionOptions = () => ({
  ...DefaultGridOptions,
  columnDefs: [
    { headerName: 'Nom', field: 'name', enableRowGroup: false },
    { headerName: 'Rapport Inspections MSA (par défaut)', field: 'reportTemplates.plannedInspection.default.title' },
    { headerName: 'Rapport AutoInspect (par défaut)', field: 'reportTemplates.onDemandInspection.default.title' },
    {
      headerName: 'Crée le',
      field: 'createdAt',
      type: 'dateColumn',
      sort: 'desc',
    },
    {
      field: '_id',
      headerName: 'Actions',
      type: 'actionsColumn',
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: (data) => <ConcessionsActionsCell data={data.value} />,
      cellClass: 'editionCell',
      suppressNavigable: true,
      enableRowGroup: false,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ],
});

const createUsersOptions = () => ({
  ...DefaultGridOptions,
  columnDefs: [
    {
      headerName: 'ID',
      field: 'readableId',
      enableRowGroup: false,
      type: 'idColumn',
    },
    {
      headerName: 'Crée le',
      field: 'createdAt',
      type: 'dateColumn',
      hide: true,
      sort: 'desc',
    },
    { headerName: 'Prénom', field: 'firstname', enableRowGroup: false },
    { headerName: 'Nom', field: 'lastname', enableRowGroup: false },
    {
      headerName: 'Rôle',
      field: 'role',
      maxWidth: 150,
      valueGetter: (p) => roleTranslation[p.data.role],
      filter: 'agSetColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: false },
    },
    {
      headerName: 'Site',
      field: 'concession.name',
      valueGetter: (p) => {
        if (_.get(p, 'data.concession.names') && p.data.concession.names.length) {
          // eslint-disable-next-line default-param-last
          return p.data.concession.names.reduce((str = '', name) => `${str}, ${name}`);
        }
        return _.get(p, 'data.concession.name');
      },
    },
    { headerName: 'email', field: 'email', enableRowGroup: false },
    {
      field: '_id',
      headerName: 'Actions',
      type: 'actionsColumn',
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: (data) => <UsersActionsCell data={data.value} />,
      cellClass: 'editionCell',
      suppressNavigable: true,
      enableRowGroup: false,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ],
});

const createMissionsOptions = (role) => ({
  ...DefaultGridOptions,
  pagination: true,
  immutableData: true,
  columnDefs: [
    {
      headerName: 'ID',
      type: 'numberColumn',
      field: 'readableId',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressMovable: true,
      lockPosition: true,
      sort: 'desc',
      width: 100,
      maxWidth: 120,
      minWidth: 100,
    },
    {
      headerName: 'Spécialiste',
      field: 'specialist',
      editable: false,
      width: 150,
      valueGetter: (p) => (p?.data?.specialist?._id ? `${p?.data?.specialist?.firstname} ${p?.data?.specialist?.lastname}` : ''),
    },
    {
      headerName: 'Compétence(s) requise(s)',
      field: 'requiredSkills',
      editable: false,
      width: 120,
      valueGetter: (p) => p.data?.requiredSkills?.map((object) => object.label),
    },
    {
      headerName: 'Date de début',
      type: 'dateColumn',
      field: 'start',
      editable: false,
      width: 150,
    },
    {
      headerName: 'Date de fin',
      type: 'dateColumn',
      field: 'end',
      editable: false,
      width: 150,
    },
    {
      headerName: 'Jours travaillés',
      field: 'workedDays',
      editable: false,
      width: 100,
      valueGetter: (p) => p?.data?.billing?.workedDays,
    },
    {
      headerName: 'Adresse',
      field: 'appointment.address',
      editable: false,
      width: 180,
    },
    {
      headerName: 'Site',
      field: 'concession.name',
      editable: false,
      width: 150,
    },
    ...(['chief', 'superChief'].includes(role) ? [
        {
          headerName: 'Commandé par',
          cellStyle: { textAlign: 'center' },
          field: 'userName',
          editable: false,
          width: 150,
          valueGetter: (p) => `${p?.data?.user?.firstname} ${p?.data?.user?.lastname}`,
        },
      ] : []),
    {
      headerName: 'Statut',
      field: 'status',
      editable: false,
      width: 120,
      valueGetter: (p) =>
        p?.data.status
          ? missionStatusTranslation()
            .filter((element) => element.menuSelector === p.data.status)
            .map((object) => object.value)
          : '',
      hide: true,
    },
    {
      field: 'statusFilter',
      width: 145,
      filterValueGetter: (p) => _.get(p, 'data.status'),
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      field: 'createdAt',
      headerName: 'Créée le',
      type: 'dateTimeColumn',
      width: 90,
      hide: true,
    },

    {
      headerName: 'Infos Complémentaires',
      field: 'description',
      editable: false,
      width: 150,
      hide: true,
    },
    {
      headerName: 'Infos Contact sur place',
      field: `${'appointment.contactPhone'} ${'appointment.contactName'}`,
      valueGetter: (p) => `${p?.data?.appointment?.contactName} - ${p?.data?.appointment?.contactPhone}`,
      editable: false,
      width: 150,
      hide: true,
    },
    {
      field: '_id',
      headerName: 'Options',
      type: 'actionsColumn',
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: (data) => <MissionsActionsCell data={_.get(data, 'value')} />,
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      maxWidth: 160,
      notExportable: true, // Custom command to avoid importing this Column in excel file
    },
  ],
});

export { createConcessionOptions, createUsersOptions, createMissionsOptions };
