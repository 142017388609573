/* eslint-disable import/no-extraneous-dependencies */
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { clearSpecialist, getSpecialists, getSpecialistSkills } from '../../../../actions/specialistsActions';
import constants from '../../../../constants';
import { selectColumnsToExport } from '../../../../helpers/agGridCustom';
import { filterSpecialistsByMenu, formatSpecialist, getSpecialistAndSkills } from '../../../../helpers/specialistsFilter';
import SpecialistsServices from '../../../../services/SpecialistsServices';
import Filters from '../../../shared/atoms/Filters';
import { exportExcelDateAndDateTimeFormatter } from '../../../shared/atoms/gridDateUtils';
import ActionsBar from '../../../shared/organisms/ActionsBar';
import filtersOptions from '../atoms/filtersOptions';
import options from '../atoms/gridOptions';
import SpecialistDetailsDrawer from '../organisms/SpecialistDetailsDrawer';
import ModalSendNotificationsToSpecialists from '../organisms/ModalSendNotificationsToSpecialists';
import ModalSendSmsToStaffers from '../organisms/ModalSendSmsToStaffers';

const AdminSpecialistsContainer = ({ adminInterface, menu, specialists, specialistSkills, dispatch, updatedAt }) => {
  const [specialistsData, setSpecialistsData] = useState();
  const [skillsData, setSkillsData] = useState();
  const [filterOptionSelected, setFilterOptionSelected] = useState('actif');
  const [showSpecialist, setShowSpecialist] = useState(false);
  const [specialistDetail, setSpecialistDetail] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalToDisplay, setModalToDisplay] = useState('');
  const gridApiRef = useRef();
  const gridColRef = useRef();
  const inputQuickFilterRef = useRef();
  const quickFilterTimeoutRef = useRef();
  const specialistRef = useRef();

  const resizeGrid = () => gridApiRef.current && gridApiRef.current.sizeColumnsToFit();

  const getRowData = () => {
    const rowData = [];
    if (gridApiRef.current) {
      gridApiRef.current.forEachNode((node) => {
        rowData.push(node.data);
      });
    }
    return rowData;
  };

  const waitUntilGridIsReady = () =>
    new Promise((resolve) => {
      const intervalCheckRef = setInterval(() => {
        if (gridApiRef) {
          clearInterval(intervalCheckRef);
          resolve();
        }
      }, 0);
    });

  const closeDetail = () => {
    setSpecialistDetail({});
    setShowSpecialist(false);
    dispatch(clearSpecialist());
  };

  const onQuickFilterChange = (newValue) => {
    if (quickFilterTimeoutRef.current) {
      clearTimeout(quickFilterTimeoutRef.current);
    }
    quickFilterTimeoutRef.current = setTimeout(() => gridApiRef.current.setQuickFilter(newValue), 400);
    resizeGrid();
  };

  const clearFilter = (field) => {
    const FilterComponent = gridApiRef.current.getFilterInstance(field);
    FilterComponent.setModel(null);
  };

  const onClickQuickStatusFilter = (status) => {
    inputQuickFilterRef.current.reset();
    gridApiRef.current.deselectAll();
    if (status !== 'aucun') {
      gridApiRef.current.setFilterModel({ statusFilter: { type: 'startsWith', filter: status } });
    } else {
      clearFilter('statusFilter');
    }
    gridApiRef.current.onFilterChanged();
    setFilterOptionSelected(status);
    resizeGrid();
  };

  const onClickMenuItem = async (value) => {
    setSpecialistDetail(value);
    specialistRef.current = value;
    setShowSpecialist(true);
  };

  const gridOptions = options(onClickMenuItem, adminInterface, menu, filterOptionSelected);

  const onClickExportExcel = () => {
    gridApiRef.current.exportDataAsExcel({
      processCellCallback: exportExcelDateAndDateTimeFormatter,
      columnKeys: selectColumnsToExport(gridOptions.columnDefs),
    });
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    gridColRef.current = params.columnApi;
    let setSelectedRowsTimeoutRef = null;
    params.api.addEventListener('rowSelected', () => {
      if (setSelectedRowsTimeoutRef) {
        clearTimeout(setSelectedRowsTimeoutRef);
      }
      setSelectedRowsTimeoutRef = setTimeout(() => {
        setSelectedRows(params.api.getSelectedRows());
      }, 50);
    });
  };

  const updateSpecialist = async (specialist) => {
    const { status, body } = await SpecialistsServices.updateSpecialist(specialist.value, specialist._id, menu);
    if (status === 200 && body) {
      const speFormatted = formatSpecialist(body, skillsData);
      const rowGrid = getRowData();
      gridApiRef.current.applyTransaction({
        update: _.differenceWith([speFormatted], rowGrid, _.isEqual),
      });
      setSpecialistDetail(() => speFormatted);
    } else {
      alert(JSON.stringify(body, null, 2));
    }
  };

  const fetchSpecialistsAndSkills = async () => {
    await Promise.all([getSpecialists(dispatch, updatedAt), getSpecialistSkills(dispatch)]);
  };

  const onClickRefresh = async () => {
    gridApiRef.current.deselectAll();
    await fetchSpecialistsAndSkills();
  };

  const onClickSendNotificationsToSpecialists = () => {
    setModalToDisplay(constants.nameModal.specialist.sendNotificationsToSpecialists);
  };

  const onClickSendSmsToStaffers = () => {
    setModalToDisplay(constants.nameModal.staffer.sendSmsToStaffers);
  };

  const closeModal = () => {
    gridApiRef.current.deselectAll();
    setModalToDisplay('');
    onClickQuickStatusFilter(filterOptionSelected);
  };

  useEffect(() => {
    if (specialistsData && specialistsData.length > 0) {
      waitUntilGridIsReady().then(() => {
        gridApiRef.current.setRowData(specialistsData);
        onClickQuickStatusFilter(filterOptionSelected || 'actif');
      });
    }
  }, [specialistsData]);

  useEffect(() => {
    if (specialists.length > 0 && specialistSkills.length > 0) {
      const [specialistsList, skillsList] = getSpecialistAndSkills(specialists, specialistSkills, menu);
      setSpecialistsData(filterSpecialistsByMenu(specialistsList[0], menu));
      setSkillsData(skillsList[0]);
    }
  }, [specialists]);

  useEffect(() => {
    (async () => fetchSpecialistsAndSkills())();
  }, []);

  // Auto refresh
  useEffect(() => {
    const interval = setInterval(() => {
      if (modalToDisplay || showSpecialist) return;
      (async () => onClickRefresh())();
    }, constants.autoRefresh);
    return () => clearInterval(interval);
  });

  return (
    <div className='specialists-container'>
      <Filters selectOptions={onClickQuickStatusFilter} title='Statut' options={filtersOptions} activeMenu={filterOptionSelected} />
      <ActionsBar
        ref={inputQuickFilterRef}
        selectedRows={selectedRows}
        onClickRefresh={onClickRefresh}
        onClickExportExcel={onClickExportExcel}
        onQuickFilterChange={onQuickFilterChange}
        onClickSendNotificationsToSpecialists={onClickSendNotificationsToSpecialists}
        onClickSendSmsToStaffers={onClickSendSmsToStaffers}
        specialistView={true}
        specialistKind={menu}
      />
      <div className='specialists-container-grid'>
        <div className='ag-theme-balham'>
          <AgGridReact {...gridOptions} onGridReady={onGridReady} onColumnVisible={resizeGrid} onToolPanelVisibleChanged={resizeGrid} />
        </div>
      </div>
      {showSpecialist && specialistDetail && (
        <SpecialistDetailsDrawer
          isOpen={showSpecialist}
          appVersions={specialistDetail.appConfig && specialistDetail.appConfig.appVersions}
          specialistSkillsList={skillsData}
          specialistId={specialistDetail._id}
          updateSpecialist={updateSpecialist}
          closeCard={closeDetail}
          specialistKind={menu}
        />
      )}
      {modalToDisplay === constants.nameModal.specialist.sendNotificationsToSpecialists && (
        <ModalSendNotificationsToSpecialists specialistsSelected={selectedRows} isOpen={modalToDisplay === constants.nameModal.specialist.sendNotificationsToSpecialists} closeModal={closeModal} />
      )}
      {modalToDisplay === constants.nameModal.staffer.sendSmsToStaffers && (
        <ModalSendSmsToStaffers specialistKind={menu} isOpen={modalToDisplay === constants.nameModal.staffer.sendSmsToStaffers} closeModal={closeModal} />
      )}
    </div>
  );
};

AdminSpecialistsContainer.propTypes = {
  dispatch: PropTypes.func,
  clearSpecialist: PropTypes.func,
  application: PropTypes.object,
  adminInterface: PropTypes.bool,
  specialists: PropTypes.array,
  specialistSkills: PropTypes.array,
  getSpecialists: PropTypes.func,
  getSpecialistSkills: PropTypes.func,
  updatedAt: PropTypes.number,
  menu: PropTypes.string,
};

const mapStateToProps = (state) => ({
  application: state.application,
  specialists: state.specialists.specialists,
  specialistSkills: state.specialists.specialistSkills,
  updatedAt: state.specialists.updatedAt,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getSpecialists,
  getSpecialistSkills,
  clearSpecialist,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSpecialistsContainer);
