import { Checkbox, List } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../../constants';

const CheckboxGroup = Checkbox.Group;

const ListInspections = ({
  inspections, handleInspectionClick, inspectionsSelected, checkAll,
}) => {
  const [inspectionSelected, setInspectionSelected] = useState([]);

  const onCheckInspectionSelected = (inspectionChecked) => {
    let allInspectionSelected = [];
    if (inspectionSelected.length === 0) {
      allInspectionSelected.push(inspectionChecked);
    } else {
      allInspectionSelected = _.find(inspectionSelected, inspectionChecked)
        ? inspectionSelected.filter((inspection) => inspection.readableId !== inspectionChecked.readableId)
        : [...inspectionSelected, inspectionChecked];
    }
    setInspectionSelected(allInspectionSelected);
    inspectionsSelected(allInspectionSelected);
  };

  useEffect(() => {
    setInspectionSelected([]);
  }, [inspections]);

  useEffect(() => {
    if (checkAll === null) {
      setInspectionSelected([]);
      inspectionsSelected([]);
    } else {
      setInspectionSelected(inspections);
      inspectionsSelected(inspections);
    }
  }, [checkAll]);

  return (
    <List
      size='small'
      style={{ overflowY: 'auto' }}
      itemLayout='horizontal'
      dataSource={inspections}
      renderItem={(inspection) => (
        <CheckboxGroup value={inspectionSelected} onChange={(e) => onCheckInspectionSelected(inspection, e)}>
          <List.Item className='inspectionTileItem card'>
            <Checkbox value={inspection}>
              <List.Item.Meta
                avatar={
                  <div>
                    <h4 className='inspectionTileId'>
                      Nº {inspection.readableId} {inspection.customId && `/ ${inspection.customId}`}
                    </h4>
                    <h2 className={`inspectionTileStatus ${inspection.status}`}>{constants.statusLabels[inspection?.status]}</h2>
                  </div>
                }
                title={
                  <div>
                    <h4>
                      {_.get(inspection, 'entity.name', '')} - {_.get(inspection, 'concession.name', '')}
                    </h4>
                    <div className='rightMenuCard'>
                      <div className='inspectionTileContact'>
                        <span style={{ display: 'block' }}>
                          <i className='fas fa-envelope' /> <a href={`mailto:${_.get(inspection, 'seller.email', '')}`}>{_.get(inspection, 'seller.name', '')}</a>
                        </span>
                        <span>
                          <i className='fas fa-phone' /> <a href={`tel:${_.get(inspection, 'seller.phone', '')}`}>{_.get(inspection, 'seller.phone', '')}</a>
                        </span>
                      </div>
                      <div className='icon-action-inspection'>
                        <a onClick={() => handleInspectionClick(inspection)}>
                          <i className='fas fa-eye' /> Voir la fiche
                        </a>
                        {_.get(inspection, 'appointment.lat') && _.get(inspection, 'appointment.lng') && (
                          <a
                            rel='noreferrer'
                            target='_blank'
                            href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${_.get(inspection, 'appointment.lat')},${_.get(inspection, 'appointment.lng')}`}>
                            <i className='fas fa-street-view' /> StreetView
                          </a>
                        )}
                      </div>
                    </div>
                    <p className='addressCard'>{_.get(inspection, 'appointment.address')}</p>
                  </div>
                }
                description={
                  <p>
                    {_.get(inspection, 'vehicle.brand', '')} {_.get(inspection, 'vehicle.model', '')} {_.get(inspection, 'vehicle.year', '')} -{' '}
                    {_.get(inspection, 'vehicle.immat.displayFormat', '')}
                  </p>
                }
              />
            </Checkbox>
          </List.Item>
        </CheckboxGroup>
      )}
    />
  );
};

ListInspections.propTypes = {
  inspections: PropTypes.array,
  handleInspectionClick: PropTypes.func,
  inspectionsSelected: PropTypes.func,
  checkAll: PropTypes.bool,
};

export default ListInspections;
