import constants from '../constants';
import utils from '../utils';

const getSpecialists = async (updatedAt) => {
  let queryString = '';
  if (Number.isInteger(updatedAt)) {
    queryString = utils.queryStringV2({ updatedAt });
  }
  const response = await fetch(constants.URI.v3.specialists.index.concat(queryString), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const getSpecialist = async (specialistId) => {
  const response = await fetch(constants.URI.v3.specialists.index.concat(`/${specialistId}`), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const updateSpecialist = async (data, specialistId, specialistKind = 'inspect') => {
  const response = await fetch(`${constants.URI.v3.specialists.index}/${specialistId}?specialistKind=${specialistKind}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const addProfessionalExperience = async (professionalUserExperienceData, specialistId) => {
  const response = await fetch(`${constants.URI.v3.specialists.createProfessionalExperience}/${specialistId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(professionalUserExperienceData),
  });
  return { status: response.status, body: await response.json() };
};

const updateProfessionalExperiences = async (data) => {
  const response = await fetch(constants.URI.v3.specialists.professionalExperience, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const deleteProfessionalExperience = async (professionalUserExperienceId, specialistId) => {
  const response = await fetch(`${constants.URI.v3.specialists.professionalExperience}/${professionalUserExperienceId}/${specialistId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const getAllSpecialistSkills = async () => {
  const response = await fetch(constants.URI.v3.specialistSkill, {
    method: 'GET',
    credentials: 'include',
  });
  return { status: response.status, body: await response.json() };
};

const getAllSpecialistPropositions = async (specialistId) => {
  const response = await fetch(constants.URI.v3.specialistProposition.concat(`/${specialistId}`), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const sendNotifications = async (notificationInfos) => {
  const response = await fetch(`${constants.URI.v3.specialists.sendNotifications}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(notificationInfos),
  });
  return { status: response.status, body: await response.json() };
};

const sendSms = async (smsInfos) => {
  const response = await fetch(`${constants.URI.v3.specialists.sendSms}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(smsInfos),
  });
  return { status: response.status, body: await response.json() };
};

export default {
  getAllSpecialistSkills,
  getAllSpecialistPropositions,
  getSpecialists,
  getSpecialist,
  updateSpecialist,
  addProfessionalExperience,
  updateProfessionalExperiences,
  deleteProfessionalExperience,
  sendNotifications,
  sendSms,
};
