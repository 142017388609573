import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ConcessionsActions from '../../../../actions/Concessions';

const ConcessionsActionsCell = (props) => {
  const {
    data, isAdmin, dispatch, role,
  } = props;

  if (!data) {
    return <div></div>;
  }

  return (
    <React.Fragment>
      <div style={{ fontSize: 'x-large', display: 'inline-flex', padding: 5 }}>
        <div
          style={{ marginRight: 15 }}
          title={'Voir le site'}
          onClick={() => dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'viewConcession', modalData: data }))}>
          <i className={'fa fa-eye'} />
        </div>
        {(isAdmin || role === 'superChief') && (
          <div title={'Éditer le site'} onClick={() => dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'editConcession', modalData: data }))}>
            <i className={'fa fa-cog'} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

ConcessionsActionsCell.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
  isAdmin: PropTypes.bool,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({ isAdmin: state.application.user.isAdmin, role: state.application.user.role });

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ConcessionsActionsCell);
