import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const InspectAndStaffSubMenu = ({ allowedRessources, menu = 'inspect' }) => {
  const activeStyle = { borderBottom: '2px solid #FFF' };
  const menuItems = [
    {
      label: menu === 'inspect' ? 'Inspections' : 'Missions',
      link: menu === 'inspect' ? 'inspection' : 'missions',
    },
    {
      label: `Base de données ${menu === 'inspect' ? 'inspecteurs' : 'staffeurs'} `,
      link: menu === 'inspect' ? 'specialist' : 'staff',
    },
    {
      label: `Carte des ${menu === 'inspect' ? 'inspecteurs' : 'staffeurs'} `,
      link: menu === 'inspect' ? 'map' : 'mapStaff',
    },
  ];
  const { pathname } = window.location;
  const path = pathname.substring(pathname.lastIndexOf('/') + 1);
  const [active, setActive] = useState(path);

  return (
    <div className='container-sub-menu'>
      <div className='wrapper'>
        <nav className='main__menu'>
          <ul>
            {menuItems.map((menuItem, index) => (
              <li key={index}>
                {allowedRessources.includes(menuItem.link) && (
                  <Link as={Link} style={active === menuItem.link ? activeStyle : {}} onClick={() => setActive(menuItem.link)} to={menuItem.link} className={menuItem.class}>
                    {menuItem.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

InspectAndStaffSubMenu.propTypes = {
  menu: PropTypes.string,
  allowedRessources: PropTypes.array,
};

export default InspectAndStaffSubMenu;
