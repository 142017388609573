import MissionsServices from '../services/MissionsServices';
import { replacePendingValidationStatusByOrderedStatus } from '../helpers/mission';

// Action types
export const SERVER_KILLED_SESSION = 'SERVER_KILLED_SESSION';
export const REQUEST_MISSIONS = 'REQUEST_MISSIONS';
export const GET_MISSIONS = 'GET_MISSIONS';
export const GET_MISSIONS_SUCCESS = 'GET_MISSIONS_SUCCESS';
export const GET_MISSIONS_ERROR = 'GET_MISSIONS_ERROR';

export const REQUEST_CONCESSIONS_MISSIONS = 'REQUEST_CONCESSIONS_MISSIONS';
export const GET_CONCESSIONS_MISSIONS = 'GET_CONCESSIONS_MISSIONS';
export const GET_CONCESSIONS_MISSIONS_SUCCESS = 'GET_CONCESSIONS_MISSIONS_SUCCESS';
export const GET_CONCESSIONS_MISSIONS_ERROR = 'GET_CONCESSIONS_MISSIONS_ERROR';

export const REQUEST_MISSION = 'REQUEST_MISSION';
export const GET_MISSION = 'GET_MISSION';
export const GET_MISSION_SUCCESS = 'GET_MISSION_SUCCESS';
export const GET_MISSION_ERROR = 'GET_MISSION_ERROR';
export const REQUEST_UPDATE_MISSION = 'REQUEST_UPDATE_MISSION';
export const UPDATE_MISSION = 'UPDATE_MISSION';
export const UPDATE_MISSION_SUCCESS = 'UPDATE_MISSION_SUCCESS';
export const UPDATE_MISSION_ERROR = 'UPDATE_MISSION_ERROR';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL_NEW_MISSION = 'SHOW_MODAL_NEW_MISSION';
export const UPDATE_NUMBER_OF_MONTHS_SELECTED_MISSIONS = 'UPDATE_NUMBER_OF_MONTHS_SELECTED_MISSIONS';

// Action creator
export const serverKilledSession = () => ({
  type: SERVER_KILLED_SESSION,
});

export const requestMissions = () => ({
  type: REQUEST_MISSIONS,
});

export const getMissionsSuccess = (missions) => ({
  type: GET_MISSIONS_SUCCESS,
  missions,
});

export const getMissionsError = (error) => ({
  type: GET_MISSIONS_ERROR,
  error,
});

export const getMissions = async (dispatch, since) => {
  try {
    dispatch(requestMissions());
    const result = await MissionsServices.getMissions(since);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getMissionsError(result.body.message || 'Failed'));
    return dispatch(getMissionsSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestConcessionsMissions = () => ({
  type: REQUEST_CONCESSIONS_MISSIONS,
});

export const getConcessionsMissionsSuccess = (missions) => ({
  type: GET_CONCESSIONS_MISSIONS_SUCCESS,
  missions,
});

export const getConcessionsMissionsError = (error) => ({
  type: GET_CONCESSIONS_MISSIONS_ERROR,
  error,
});

export const getConcessionsMissions = async (dispatch, since) => {
  try {
    dispatch(requestConcessionsMissions());
    const result = await MissionsServices.getConcessionsMissions(since);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getConcessionsMissionsError(result.body.message || 'Failed'));
    const newMissions = replacePendingValidationStatusByOrderedStatus(result.body);
    return dispatch(getConcessionsMissionsSuccess(newMissions));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestMission = () => ({
  type: REQUEST_MISSION,
});

export const getMissionSuccess = (mission) => ({
  type: GET_MISSION_SUCCESS,
  mission,
});

export const getMissionError = (error) => ({
  type: GET_MISSION_ERROR,
  error,
});

export const getMission = async (dispatch, missionId) => {
  try {
    dispatch(requestMission());
    const result = await MissionsServices.getMission(missionId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getMissionError(result.body.message || 'Failed'));
    return dispatch(getMissionSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestUpdateMission = () => ({
  type: REQUEST_UPDATE_MISSION,
});

export const updateMissionSuccess = (mission) => ({
  type: UPDATE_MISSION_SUCCESS,
  mission,
});

export const updateMissionError = (error) => ({
  type: UPDATE_MISSION_ERROR,
  error,
});

export const updateMission = async (dispatch, missionData, missionId) => {
  try {
    dispatch(requestUpdateMission());
    const result = await MissionsServices.updateMission(missionData, missionId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(updateMissionError(result.body.error || result.body.message || 'Failed'));
    return dispatch(updateMissionSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const getActionToDispatch = (params) => {
  switch (params.type) {
    case SHOW_MODAL_NEW_MISSION: {
      return {
        type: SHOW_MODAL_NEW_MISSION,
      };
    }
    case SHOW_MODAL: {
      return {
        type: params.type,
        modalName: params.modalName,
        modalData: params.modalData,
      };
    }
    case HIDE_MODAL: {
      return {
        type: params.type,
      };
    }
    default:
      return null;
  }
};

export const updateNumberOfMonthsSelectedMissions = (dispatch, numberOfMonthsSelected) =>
  dispatch({
    type: UPDATE_NUMBER_OF_MONTHS_SELECTED_MISSIONS,
    numberOfMonthsSelected,
  });
