import { LicenseManager } from '@ag-grid-enterprise/all-modules';
import moment from 'moment';
import 'moment/locale/fr';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import PropTypes from 'prop-types';
import Router from './components/Router';
import './index.scss';
import configureStore from './stores/configureStore';
// import Modal from './components/shared/templates/ModalRedirectCustomer';

// ag-grid license
LicenseManager.setLicenseKey(
  'monspecialisteauto.com_monspecialisteauto.com_single_1_Devs_1_Deployment_License_12_November_2020_[v2]_MTYwNTEzOTIwMDAwMA==7e8d653881e009ea79cb67dac4a725a7',
);

moment.locale('fr');

const App = (props) => {
  console.log(props);
  // const { application } = props;
  // const userRole = application && application?.user?.role;

  return (
    <Provider store={configureStore()}>
      <ToastProvider>
        <Router {...props} />
      </ToastProvider>
    </Provider>
  );
};

App.propTypes = {
  application: PropTypes.object,
};

const mapStateToProps = (state) => ({ application: state.application });

export default connect(mapStateToProps)(App);

ReactDOM.render(<App />, document.getElementById('app'));
