import { Checkbox } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => (
  <span style={{ marginRight: 8, fontSize: 12 }}>
    <i className='fas fa-arrows-alt-v' />
  </span>
));

const SortableItem = SortableElement(({ label, handleDelete, sortIndex, handleCheckboxChange, addField, highlight, replaceMsaCarPart, isAdmin }) => (
  <li className='SortableItem infinite'>
    <div style={{ maxWidth: '85%' }}>
      <DragHandle />
      {sortIndex} - {label}
    </div>
    <div>
      <div className='formInput' style={{ display: 'flex', marginBottom: 0, alignItems: 'center' }}>
        <label style={{ letterSpacing: 0 }}>Signaler</label>
        <Checkbox data-cy='signalCheckbox' style={{ marginLeft: 5, marginRight: 10, marginTop: 8 }} checked={highlight || false} name='highlight' onChange={(e) => handleCheckboxChange(e, sortIndex)} />
        <label style={{ letterSpacing: 0 }}>PC Conditionnel</label>
        <Checkbox data-cy='conditionalCheckbox' style={{ marginLeft: 5, marginRight: 10, marginTop: 8 }} checked={addField || false} name='addField' onChange={(e) => handleCheckboxChange(e, sortIndex)} />
        {isAdmin ? (
          <React.Fragment>
            <label style={{ letterSpacing: 0 }}>Remplacement GT</label>
            <Checkbox style={{ marginLeft: 5, marginTop: 8 }} checked={replaceMsaCarPart || false} name='replaceMsaCarPart' onChange={(e) => handleCheckboxChange(e, sortIndex)} />
          </React.Fragment>
        ) : null}
        <button className='btnIcon delete' style={{ zIndex: 1000 }} onClick={(e) => handleDelete(e, sortIndex)} title={'Supprimer ce point de contrôle'}>
          <i className='fas fa-trash-alt' />
        </button>
      </div>
    </div>
  </li>
));

const SortableList = SortableContainer(({ items, handleDelete, handleCheckboxChange, isAdmin }) => (
  <ul className='SortableList'>
    {_.map(items, (item, i) => (
      <SortableItem
        key={`item-${i}`}
        index={i}
        sortIndex={i}
        label={_.get(item, 'label')}
        helperClass='SortableHelper'
        addField={_.get(item, 'addField')}
        replaceMsaCarPart={_.get(item, 'replaceMsaCarPart')}
        highlight={_.get(item, 'highlight')}
        handleDelete={handleDelete}
        handleCheckboxChange={handleCheckboxChange}
        isAdmin={isAdmin}
      />
    ))}
  </ul>
));

class DragDropCombo extends Component {
  render() {
    return (
      <SortableList
        items={this.props.items}
        onSortEnd={this.props.onSortEnd}
        distance={5}
        handleDelete={this.props.handleDeleteItem}
        handleCheckboxChange={this.props.handleCheckboxChange}
        isAdmin={this.props.isAdmin}
      />
    );
  }
}

DragDropCombo.propTypes = {
  items: PropTypes.array,
  isAdmin: PropTypes.bool,
  onSortEnd: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
};

export default DragDropCombo;
