import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import constants from '../../../constants';

const SpecialistProposition = ({ listPropositions }) => (
  <div>
    <div>
      {listPropositions.map((proposition) => (
        <div className='formInput' key={proposition._id}>
          <h4>
            {proposition.specialistId.firstname} {proposition.specialistId.lastname} ({proposition.specialistId.readableId})
          </h4>
          <ul>
            {proposition.propositionTimeSlots.map((propositionOfSpecialist) => (
              <li key={propositionOfSpecialist.start}>
                {moment(propositionOfSpecialist.start).format(constants.formatDateTimeAppointment)} -{' '}
                {moment(propositionOfSpecialist.end).format(constants.formatDateTimeAppointment)}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);

SpecialistProposition.propTypes = {
  listPropositions: PropTypes.array,
};

export default SpecialistProposition;
