import { Select } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../../constants';
import GooglePlacesAutoComplete from '../../../shared/atoms/GooglePlacesAutoComplete';
import filtersOptions from '../atoms/filtersOptions';

export default class InfosGeneralesEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      phone: null,
      experienceYear: null,
      city: '',
      profile: {
        legalStatus: '',
        availability: '',
      },
    };
    this.allDiplomas = ['BEP', 'BAC', 'CAP', 'BTS', 'Autres'];
  }

  componentDidMount() {
    const {
      email, phone, diplomaLevel, experienceYear, city, firstname, lastname, profile, inactiveReasons, rank,
    } = this.props;
    const status = filtersOptions.find((option) => option.menuSelector === this.props.status);
    const stafferStatus = filtersOptions.find((option) => option.menuSelector === this.props.stafferStatus);
    this.setState({
      email,
      firstname,
      lastname,
      phone,
      ...(diplomaLevel && this.allDiplomas.includes(diplomaLevel) ? { diplomaLevel } : null),
      status: this.props.status || status?.menuSelector,
      stafferStatus: this.props.stafferStatus || stafferStatus?.menuSelector,
      experienceYear,
      city,
      profile,
      inactiveReasons,
      rank,
    });
  }

  fillUserToEdit = (value, label) => {
    const { state } = this;
    state[label] = value;
    this.setState(state);
  };

  handleAddressDetails = (fullAddress, addressCoordinates) =>
    this.setState({
      city: fullAddress,
      lng: addressCoordinates.lng || this.props.lng,
      lat: addressCoordinates.lat || this.props.lat,
      location: {
        type: 'Point',
        coordinates: [addressCoordinates.lng || this.props.lng, addressCoordinates.lat || this.props.lat],
      },
    });

  render() {
    const {
      email, phone, diplomaLevel, experienceYear, status, stafferStatus, firstname, lastname, profile, inactiveReasons, rank,
    } = this.state;

    return (
      <React.Fragment>
        <ul>
          <li>
            <label>Prénom</label>
            <div>
              <input data-cy='firstName' type='text' value={firstname || ''} placeholder='Firstname' onChange={(e) => this.fillUserToEdit(e.target.value, 'firstname')} />
            </div>
          </li>
          <li>
            <label>Nom</label>
            <div>
              <input data-cy='lastName' type='text' value={lastname || ''} placeholder='Lastname' onChange={(e) => this.fillUserToEdit(e.target.value, 'lastname')} />
            </div>
          </li>
          <li>
            <label>Téléphone</label>
            <div>
              <input data-cy='phone' type='text' value={phone || ''} placeholder='Phone' onChange={(e) => this.fillUserToEdit(e.target.value, 'phone')} />
            </div>
          </li>
          <li className='full'>
            <label>Email</label>
            <div>
              <input data-cy='email' type='text' value={email || ''} placeholder='Email' onChange={(e) => this.fillUserToEdit(e.target.value, 'email')} />
            </div>
          </li>
          <li className='full'>
            <label>Adresse</label>
            <div>
              <GooglePlacesAutoComplete
                getAddressDetails={(fullAddress, addressCoordinates) => this.handleAddressDetails(fullAddress, addressCoordinates)}
                placeholder='Votre adresse complète'
                value={this.props.city || this.state.city}
              />
            </div>
          </li>
          <li>
            <label>Année d'expérience</label>
            <div>
              <input
                data-cy='experience'
                type='text'
                value={experienceYear || ''}
                placeholder="Années d'expérience"
                onChange={(e) => this.fillUserToEdit(e.target.value, 'experienceYear')}
              />
            </div>
          </li>
          <li>
            <label>Diplome</label>
            <select
              data-cy='diploma'
              value={diplomaLevel || ''}
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.fillUserToEdit(e.target.value, 'diplomaLevel');
                }
              }}
            >
              <option value='' />
              {this.allDiplomas.map((diploma, keyDiploma) => (
                <option data-cy='diplomaOptions' key={keyDiploma} value={diploma}>
                  {diploma}
                </option>
              ))}
            </select>
          </li>
          {status && (
            <li>
              <label>Statut</label>
              <select
                disabled={this.props.specialistKind === 'staff'}
                data-cy='status'
                value={status || ''}
                onChange={(e) => {
                  const { state } = this;
                  const newState = {
                    ...state,
                    status: e.target.value,
                  };
                  this.setState(newState);
                }}
              >
                {filtersOptions.map(({ menuSelector, value }, keyStatus) => (
                  <option data-cy={menuSelector} key={keyStatus} value={menuSelector}>
                    {value}
                  </option>
                ))}
              </select>
            </li>
          )}
          {stafferStatus && (
            <li>
              <label>Statut Staffeur</label>
              <select
                disabled={this.props.specialistKind === 'inspect'}
                data-cy='stafferStatus'
                value={stafferStatus || ''}
                onChange={(e) => {
                  const { state } = this;
                  const newState = {
                    ...state,
                    stafferStatus: e.target.value,
                  };
                  this.setState(newState);
                }}
              >
                {filtersOptions.map(({ menuSelector, value }, keyStatus) => (
                  <option data-cy={menuSelector} key={keyStatus} value={menuSelector}>
                    {value}
                  </option>
                ))}
              </select>
            </li>
          )}
          {status === 'inactifs' && (
            <li>
              <label>Raison Inactif</label>
              <div data-cy='InactiveReasons'>
                <Select
                  mode='multiple'
                  size='small'
                  style={{ width: '100%' }}
                  placeholder='Aucune'
                  value={inactiveReasons}
                  defaultValue={inactiveReasons}
                  onChange={(e) => {
                    this.fillUserToEdit(e, 'inactiveReasons');
                  }}
                  dropdownStyle={{ zIndex: 10000000 }}
                  optionFilterProp='children'
                  options={constants.inactiveOptions}
                  maxTagCount='responsive'
                />
              </div>
            </li>
          )}
          <li>
            <label>Statut légal</label>
            <select
              data-cy='legalStatus'
              value={profile?.legalStatus || ''}
              onChange={(e) => {
                const { state } = this;
                const newState = {
                  ...state,
                  profile: {
                    ...profile,
                    legalStatus: e.target.value,
                  },
                };
                this.setState(newState);
              }}
            >
              <option value='' />
              {Object.entries(constants.legalStatus).map((legalStatus, keyLegalStatus) => (
                <option data-cy='legalStatusValue' key={keyLegalStatus} value={legalStatus[0]}>
                  {legalStatus[1]}
                </option>
              ))}
            </select>
          </li>
          <li>
            <label>Disponibilité</label>
            <select
              data-cy='availability'
              value={profile?.availability || ''}
              onChange={(e) => {
                const { state } = this;
                const newState = {
                  ...state,
                  profile: {
                    ...profile,
                    availability: e.target.value,
                  },
                };
                this.setState(newState);
              }}
            >
              <option value='' />
              {Object.entries(constants.availabilities).map((availability, keyAvailability) => (
                <option data-cy='availabilityOptions' key={keyAvailability} value={availability[0]}>
                  {availability[1]}
                </option>
              ))}
            </select>
          </li>
          <li>
            <label>Rang</label>
            <Select
              size='small'
              style={{ width: '100%' }}
              placeholder='Membre'
              onChange={(selectedRank) => {
                this.setState({ rank: selectedRank === 'member' ? null : selectedRank });
              }}
              value={rank}
              defaultValue='member'
              options={_.map(constants.rankOptions, (label, key) => ({ label, value: key }))}

            />
          </li>
        </ul>
        <div className='specialistFormButton'>
          <button data-cy='cancelButton' className='specialistFormButtonCancel' onClick={() => this.props.cancel()}>
            annuler
          </button>
          <button
            data-cy='saveButton'
            className='specialistFormButtonSubmit'
            onClick={async () => {
              this.props.save(this.state);
            }}
          >
            sauvegarder
          </button>
        </div>
      </React.Fragment>
    );
  }
}

InfosGeneralesEditor.propTypes = {
  email: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  status: PropTypes.string,
  stafferStatus: PropTypes.string,
  inactiveReasons: PropTypes.array,
  phone: PropTypes.string,
  city: PropTypes.string,
  location: PropTypes.object,
  diplomaLevel: PropTypes.string,
  experienceYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastRefresh: PropTypes.number,
  cancel: PropTypes.func,
  save: PropTypes.func,
  lng: PropTypes.number,
  lat: PropTypes.number,
  profile: PropTypes.shape({
    legalStatus: PropTypes.string,
    availability: PropTypes.string,
  }),
  rank: PropTypes.string,
  specialistKind: PropTypes.string,
};
