import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import ConcessionsActions from '../../../../actions/Concessions';
import ConcessionsServices from '../../../../services/ConcessionsServices';
import Text from '../../../shared/atoms/Text';
import locale from '../../locale';
import ConcessionSelect from '../../shared/atoms/ConcessionSelect';

Modal.setAppElement('body');

class ModalEditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  closeModal = () => {
    const { dispatch, application } = this.props;

    dispatch(ConcessionsActions.getActionToDispatch({ type: 'HIDE_MODAL' }));
    ConcessionsActions.reloadTable({ index: 'users' })(dispatch, application);
  };

  async componentDidMount() {
    const { modalData } = this.props.concessions;

    if (!modalData) {
      return false;
    }
    const savedConcessions = [];
    if (modalData.role === 'chief') {
      this.props.concessions.modalData.concession.concessionIds.forEach((concession) => {
        savedConcessions.push({
          label: this.props.concessions.modalData.concession.names[this.props.concessions.modalData.concession.concessionIds.indexOf(concession)],
          value: concession,
        });
      });
    }

    this.setState({
      userId: modalData._id,
      firstname: modalData.firstname,
      lastname: modalData.lastname,
      concessionId: modalData.concession.concessionId,
      concessionIds: modalData.concession.concessionIds,
      notifyConcession: modalData.concession.notifyConcession,
      canCreateMsaInspection: modalData.concession.canCreateMsaInspection,
      entityId: modalData.organisation?.entity || modalData.concession.entityId,
      concessionsSelected: savedConcessions,
    });

    const findResponse = await ConcessionsServices.getAccount({
      userId: modalData._id,
    }).catch((err) => ({ err }));

    if (findResponse && findResponse.status < 400) {
      this.setState({ editedUser: findResponse.user });
    }
    return true;
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  onChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  onChangeCheckbox = (e) => {
    const { target } = e;
    const { name, checked } = target;

    this.setState({ [name]: checked });
  };

  renderValidButtonText = () => {
    const { isLoading } = this.props.concessions;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }

    return 'Sauvegarder';
  };

  getRole = (role) => {
    switch (role) {
      case 'superChief':
        return 'Administrateur de groupe';
      case 'chief':
        return 'Responsable';
      case 'reseller':
        return 'Utilisateur';
      default:
        return null;
    }
  };

  submit = async (e) => {
    e.preventDefault();

    const {
      concessionSelected,
      concessionsSelected,
      editedUser,
      firstname,
      lastname,
      notifyConcession = false,
      canCreateMsaInspection = false,
      entityId,
      concessionId,
      concessionIds,
    } = this.state;

    const editedResponse = await ConcessionsServices.editUser({
      userId: editedUser._id,
      firstname,
      lastname,
      concessionId: (concessionSelected && concessionSelected._id) || concessionId,
      concessionIds: (concessionsSelected && _.map(concessionsSelected, (s) => s.value)) || concessionIds,
      notifyConcession,
      canCreateMsaInspection,
      entityId,
    }).catch((error) => {
      this.setState({ error: error.error || 'Une erreur est survenue' });
    });

    if (editedResponse && editedResponse.status === 200) {
      this.closeModal();
    }
  };

  onChangeUser = (name, user) => {
    this.setState({ [name]: user });
  };

  onClearInput = (name) => {
    this.setState({ [name]: null });
  };

  convertConcessions = (concession) => {
    const init = [];

    if (concession.names && concession.concessionIds) {
      concession.concessionIds.forEach((id, key) => {
        init.push({
          value: String(id),
          label: concession.names[key],
        });
      });
    }

    return init;
  };

  renderConcession = () => {
    const { user } = this.props.application;
    const { editedUser, concessionSelected, concessionsSelected } = this.state;

    if (concessionSelected) {
      return (
        <div className='formInput withSearch'>
          <div className='formInput'>
            <input type='text' disabled defaultValue={`${concessionSelected.name}`} />
            <i className='fa fa-times-circle' onClick={(c) => this.onClearInput('concessionSelected', c)} />
            <Text fontStyle='normal' size={10} onClick={(c) => this.onClearInput('concessionSelected', c)}>
              Supprimer le champs
            </Text>
          </div>
        </div>
      );
    }

    if (['chief', 'superChief'].includes(user.role)) {
      return (
        <React.Fragment>
          <div className='formInput withSearch'>
            <div className='formInput'>
              {editedUser.role === 'chief' ? (
                <React.Fragment>
                  <label>Modifier le(s) site(s) attribué(s)</label>
                  <ConcessionSelect
                    isMulti
                    selectedSites={concessionsSelected}
                    entityId={editedUser.concession.entityId}
                    onSelectMultiple={(c) => this.onChangeUser('concessionsSelected', c)}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <label>Modifier le site attribué</label>
                  <ConcessionSelect selectedSites={editedUser.concession} isClearable={false} onSelect={(c) => this.onChangeUser('concessionSelected', c)} />
                </React.Fragment>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  render() {
    const { editedUser } = this.state;
    const { user } = this.props.application;
    const { concessions } = this.props;
    const listConcession = editedUser && editedUser.concession ? editedUser.concession.listConcession : [];
    const names = editedUser && editedUser.concession ? editedUser.concession.names : [];

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Modal annuler inspection'
        overlayClassName='modal-overlay'
        className='modal modalConcession'
      >
        <div className='modalContent '>
          <div className='mainTitle'>
            Édition de {editedUser && editedUser.firstname} {editedUser && editedUser.lastname}
          </div>
          {editedUser ? (
            <form className='mt10px' onSubmit={this.submit}>
              {editedUser && (
                <Row>
                  <Col xs={12} md={6}>
                    <div className='formInput'>
                      <label>Nom</label>
                      <input data-cy='userLastName' type='text' defaultValue={editedUser.lastname} name='lastname' placeholder='Nom' onChange={this.onChange} />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className='formInput'>
                      <label>Prenom</label>
                      <input type='text' defaultValue={editedUser.firstname} name='firstname' placeholder='Prenom' onChange={this.onChange} />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className='formInput'>
                      <label>Email</label>
                      <Text fontStyle='normal' size={12}>
                        {editedUser.email}
                      </Text>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className='formInput'>
                      <label>Identifiant interne</label>
                      <Text fontStyle='normal' size={12}>
                        {editedUser.readableId}
                      </Text>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className='formInput'>
                      <label>Type de compte</label>
                      <Text fontStyle='normal' size={12}>
                        {this.getRole(editedUser.role)}
                      </Text>
                    </div>
                  </Col>
                  {editedUser.phone && (
                    <Col xs={12} md={6}>
                      <div className='formInput'>
                        <label>Numéro de téléphone</label>
                        <Text fontStyle='normal' size={12}>
                          {editedUser.phone}
                        </Text>
                      </div>
                    </Col>
                  )}
                  {editedUser.concession && (
                    <React.Fragment>
                      {user && user.role === 'chief' && (
                        <React.Fragment>
                          <Col xs={12} md={6}>
                            <div className='formInput'>
                              <label>{locale.ACCESS_NEW_INSPECTION_MSA}</label>
                              <input type='checkbox' defaultChecked={editedUser.concession.canCreateMsaInspection} name='canCreateMsaInspection' onChange={this.onChangeCheckbox} />
                            </div>
                          </Col>
                          <Col xs={12} md={6}>
                            <div className='formInput'>
                              <label>{locale.GET_NOTIFIED_NEW_INSPECTION}</label>
                              <input type='checkbox' defaultChecked={editedUser.concession.notifyConcession} name='notifyConcession' onChange={this.onChangeCheckbox} />
                            </div>
                          </Col>
                        </React.Fragment>
                      )}
                      {names && (
                        <Col xs={12} md={6}>
                          <div className='formInput'>
                            <label>Concession</label>
                            {names.map((elem, key) => (
                              <Text key={key} fontStyle='normal' className='inline mr5px' size={12}>
                                {elem}
                              </Text>
                            ))}
                          </div>
                        </Col>
                      )}
                      {listConcession && (
                        <Col xs={12} md={6}>
                          <div className='formInput'>
                            <label>Site(s)</label>
                            {listConcession.map((elem, key) => (
                              <Text key={key} fontStyle='normal' className='inline mr5px' size={12}>
                                {elem}
                                {listConcession[key + 1] ? ', ' : ''}
                              </Text>
                            ))}
                          </div>
                        </Col>
                      )}
                    </React.Fragment>
                  )}
                  <Col xs={12}>{this.renderConcession()}</Col>
                </Row>
              )}
              {this.renderError()}
              <div className='center formButton'>
                <button type='button' onClick={this.closeModal} className='negativ'>
                  Fermer
                </button>
                <button type='submit' className='positiv' disabled={concessions.isLoading}>
                  {this.renderValidButtonText()}
                </button>
              </div>
            </form>
          ) : (
            <Text fontStyle='normal' size={16}>
              Chargement
            </Text>
          )}
        </div>
      </Modal>
    );
  }
}

ModalEditUser.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
};

export default ModalEditUser;
