import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../constants';

const BillingMenu = ({ value, onClickEditBilling, onClickDetailsSpecialist }) => {
  const editableInvoice = [constants.statusBilling.estimate.name, constants.statusBilling.computed.name].includes(value.status);

  return (
    <div className='icon-grid'>
      <span onClick={() => onClickEditBilling(value)}>
        <i data-cy={editableInvoice ? 'billingActionEdit' : 'billingActionView'} className={editableInvoice ? 'fas fa-pen' : 'fas fa-eye'} />
      </span>
      <span onClick={() => onClickDetailsSpecialist(value)}>
        <i className='fas fa-user' />
      </span>
    </div>
  );
};

BillingMenu.propTypes = {
  value: PropTypes.object,
  onClickEditBilling: PropTypes.func,
  onClickViewBillingPaid: PropTypes.func,
  onClickDetailsSpecialist: PropTypes.func,
};

export default BillingMenu;
