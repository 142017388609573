const showHideError = (spanBox, display) => {
  const row = spanBox.parentNode.parentNode;
  const errorMsg = row.getElementsByTagName('span')[0];
  if (errorMsg != null) {
    errorMsg.style.display = display;
  }
};

export const isNumeric = (input, e) => {
  const separator = '/';
  const { keyCode } = e;
  if (((keyCode >= 48 && keyCode <= 57) || keyCode === 8 || keyCode <= 37 || keyCode <= 39 || (keyCode >= 96 && keyCode <= 105))) {
    if ((input.value.length === 2 || input.value.length === 5) && keyCode !== 8) {
      // eslint-disable-next-line no-param-reassign
      input.value += separator;
    }
    return true;
  }
  return false;
};

export const validateDateFormat = (divElement) => {
  const dateString = divElement.value;
  const regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;
  // Check whether valid dd/MM/yyyy Date Format.
  if (regex.test(dateString) || dateString.length === 0) {
    showHideError(divElement, 'none');
  } else {
    showHideError(divElement, 'block');
  }
};
