import _ from 'lodash';
import * as actions from '../actions/plannedInspectionsActions';

const initialState = {
  inspections: [],
  oldInspections: [],
  lastUpdatedAt: 0,
  numberOfMonthsSelected: 3,
  updateFailError: '',
  inspection: {},
  inspectionDrawer: {},
  documentsUpdated: [],
  isLoading: false,
  error: null,
};

const plannedInspectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_VALIDATE_INSPECTION:
    case actions.REQUEST_DRAWER_INSPECTION:
    case actions.REQUEST_INSPECTION:
    case actions.REQUEST_SAVE_DOCUMENT_INFOS:
    case actions.REQUEST_DELETE_DOCUMENT_INFOS: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case actions.GET_INSPECTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        inspection: action.view === 'modalEditionReport' ? action.inspection : action.inspection.inspection,
      };
    }
    case actions.GET_INSPECTION_DRAWER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        inspectionDrawer: action.inspection.inspection,
      };
    }
    case actions.CLEAR_INSPECTION: {
      return {
        ...state,
        isLoading: false,
        error: null,
        inspection: {},
      };
    }
    case actions.CLEAR_INSPECTION_DRAWER: {
      return {
        ...state,
        isLoading: false,
        error: null,
        inspectionDrawer: {},
      };
    }
    case actions.UPDATE_COMMENT_INSPECTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case actions.VALIDATE_INSPECTION_SUCCESS:
    case actions.UPDATE_INSPECTIONS: {
      if (action?.payload?.length === 0) {
        return {
          ...state,
          oldInspections: state.inspections,
          inspections: state.inspections,
        };
      }

      const oldInspectionsLoaded = action.resetPreviousInspections ? [] : state.inspections;
      const notModified = _.differenceBy(oldInspectionsLoaded, action.payload, (i) => i._id);
      const allInspections = [...notModified, ...action.payload];
      const lastUpdatedDateFromBd = Math.round(new Date(_.maxBy(allInspections, 'updatedAt').updatedAt) / 1000);
      const lastUpdatedDate = _.min([Math.round(Date.now() / 1000), lastUpdatedDateFromBd + 1]);

      return {
        ...state,
        oldInspections: state.inspections,
        inspections: allInspections,
        lastUpdatedAt: lastUpdatedDate,
      };
    }

    case actions.SAVE_DOCUMENT_INFOS_SUCCESS: {
      return {
        ...state,
        documentsUpdated: action.documentsUpdated.url,
      };
    }

    case actions.DELETE_DOCUMENT_INFOS_SUCCESS: {
      return {
        ...state,
        resultServer: action.resultServer,
      };
    }

    case actions.GET_INSPECTION_DRAWER_ERROR:
    case actions.GET_INSPECTION_ERROR:
    case actions.SAVE_DOCUMENT_INFOS_ERROR:
    case actions.VALIDATE_INSPECTION_ERROR:
    case actions.DELETE_DOCUMENT_INFOS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isLoadingUpdate: false,
        error: action.error,
      };
    }
    case actions.UPDATE_INSPECTIONS_FAIL:
    case actions.UPDATE_COMMENT_INSPECTION_ERROR: {
      return {
        ...state,
        updateFailError: action.payload,
        isLoading: false,
        error: action.error,
      };
    }
    case actions.UPDATE_NUMBER_OF_MONTHS_SELECTED_INSPECTIONS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        numberOfMonthsSelected: action.numberOfMonthsSelected,
      };
    }
    default:
      return state;
  }
};

export default plannedInspectionsReducer;
