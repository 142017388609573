import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getActionToDispatch, getMissions, updateNumberOfMonthsSelectedMissions } from '../../../actions/MissionsActions';
import NavigationActions from '../../../actions/NavigationActions';
import constants from '../../../constants';
import { selectColumnsToExport } from '../../../helpers/agGridCustom';
import loadInspectionsByNumberOfMonthsSelected from '../../../helpers/inspection';
import Filters from '../../shared/atoms/Filters';
import { exportExcelDateAndDateTimeFormatter } from '../../shared/atoms/gridDateUtils';
import Text from '../../shared/atoms/Text';
import ActionsBar from '../../shared/organisms/ActionsBar';
import NavBar from '../../shared/templates/NavBar';
import { missionStatusTranslation } from '../atoms/filterOptions';
import gridOptions from '../atoms/gridOptions';
import MissionDetailsDrawer from '../organisms/MissionDetailsDrawer';
import ModalAssignSpecialistOrChangeStatusMissions from '../organisms/ModalAssignSpecialistOrChangeStatusMissions';
import ModalCreateEditViewMission from '../organisms/ModalCreateEditMission';
import ModalSendMissionsNotificationsToStaffers from '../organisms/ModalSendMissionsNotificationsToStaffers';

const Missions = (props) => {
  const [missionData, setMissionsData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterOptionSelected, setFilterOptionSelected] = useState('ordered');
  const [monthsSelected, setMonthsSelected] = useState(false);
  const [numberOfMonthsSelected, setNumberOfMonthsSelected] = useState(props.numberOfMonthsSelectedMissions);

  const gridApiRef = useRef();
  const gridColRef = useRef();
  const inputQuickFilterRef = useRef();
  const quickFilterTimeoutRef = useRef();

  const resizeGrid = () => gridApiRef.current && gridApiRef.current.sizeColumnsToFit();

  const fetchMissions = async () => {
    await getMissions(props.dispatch, props.updatedAt);
  };

  const onClickRefresh = async () => {
    gridApiRef.current.deselectAll();
    await fetchMissions();
  };

  const waitUntilGridIsReady = () =>
    new Promise((resolve) => {
      const intervalCheckRef = setInterval(() => {
        if (gridApiRef) {
          clearInterval(intervalCheckRef);
          resolve();
        }
      }, 500);
    });

  const onSelectedMonths = (months) => {
    setMonthsSelected(true);
    setNumberOfMonthsSelected(months);
    updateNumberOfMonthsSelectedMissions(props.dispatch, months);
    loadInspectionsByNumberOfMonthsSelected(months, getMissions, props.dispatch);
  };

  // Auto refresh
  useEffect(() => {
    const interval = setInterval(() => {
      onClickRefresh();
    }, constants.autoRefresh);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    resizeGrid();
    setMonthsSelected(false);
    if (props.allMissions.missions.length > 0) {
      setMissionsData(props.allMissions.missions);
    }
  }, [props.allMissions.missions]);

  const clearFilter = (field) => {
    const FilterComponent = gridApiRef.current.getFilterInstance(field);
    FilterComponent.setModel(null);
  };

  const onClickQuickStatusFilter = (status) => {
    if (status === 'ordered') {
      gridColRef.current.setColumnVisible('specialist', false);
      resizeGrid();
    } else {
      gridColRef.current.setColumnVisible('specialist', true);
    }
    // reset filters
    inputQuickFilterRef.current.reset();
    gridApiRef.current.deselectAll();
    // set filters
    if (status !== 'none') {
      gridApiRef.current.setFilterModel({ statusFilter: { type: 'startsWith', filter: status } });
    } else {
      clearFilter('statusFilter');
    }

    gridApiRef.current.onFilterChanged();

    setFilterOptionSelected(status);
  };

  useEffect(() => {
    resizeGrid();
    waitUntilGridIsReady().then(() => {
      gridApiRef.current.setRowData(missionData);
      onClickQuickStatusFilter(filterOptionSelected || 'ordered');
    });
  }, [missionData]);

  useEffect(() => {
    props.dispatch(NavigationActions.setNavigation({ navigation: 'missions' }));
    (() => fetchMissions())();
  }, []);

  const onClickExportExcel = () => {
    gridApiRef.current.exportDataAsExcel({
      processCellCallback: exportExcelDateAndDateTimeFormatter,
      columnKeys: selectColumnsToExport(gridOptions.columnDefs),
    });
  };

  const onQuickFilterChange = (newValue) => {
    if (quickFilterTimeoutRef.current) {
      clearTimeout(quickFilterTimeoutRef.current);
    }
    quickFilterTimeoutRef.current = setTimeout(() => gridApiRef.current.setQuickFilter(newValue), 400);
    resizeGrid();
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    gridColRef.current = params.columnApi;
    gridApiRef.current.setQuickFilter('ordered');

    let setSelectedRowsTimeoutRef = null;
    // When clicking "select all" this even is called for each row (so many times !)
    // We only need to run setSelectedRows once so we use a debounce to do it at the end
    params.api.addEventListener('rowSelected', () => {
      if (setSelectedRowsTimeoutRef) {
        clearTimeout(setSelectedRowsTimeoutRef);
      }
      setSelectedRowsTimeoutRef = setTimeout(() => {
        setSelectedRows(params.api.getSelectedRows());
      }, 50);
    });
  };

  const closeModal = async (withUpdate = false) => {
    props.dispatch(getActionToDispatch({ type: 'HIDE_MODAL' }));
    if (withUpdate) {
      await getMissions(props.dispatch, props.updatedAt);
    }
  };

  const { dispatch, application, allMissions } = props;

  const myProps = {
    dispatch,
    application,
    allMissions,
  };

  const renderModals = () => {
    const { modalName } = allMissions;

    switch (modalName) {
      case 'createMission':
        return <ModalCreateEditViewMission {...myProps} closeModal={closeModal} />;
      case 'editMission':
        return <ModalCreateEditViewMission {...myProps} closeModal={closeModal} />;
      case 'viewMission':
        return <MissionDetailsDrawer adminInterface={true} isOpen={true} missionId={allMissions.modalData._id} mission={allMissions.modalData} closeCard={closeModal} />;
      case 'assignSpecialist':
        return <ModalAssignSpecialistOrChangeStatusMissions {...myProps} closeModal={closeModal} />;
      case 'unassignSpecialist':
        return <ModalAssignSpecialistOrChangeStatusMissions {...myProps} closeModal={closeModal} />;
      case 'cancelMission':
        return <ModalAssignSpecialistOrChangeStatusMissions {...myProps} closeModal={closeModal} />;
      case 'changeStatus':
        return <ModalAssignSpecialistOrChangeStatusMissions {...myProps} closeModal={closeModal} />;
      case 'sendMissionsNotificationsToStaffers':
        return <ModalSendMissionsNotificationsToStaffers {...myProps} closeModal={closeModal} />;
      default:
        return null;
    }
  };

  const onClickMultiple = (modalName, rowsData) => async () => {
    props.dispatch(getActionToDispatch({ type: 'SHOW_MODAL', modalName, modalData: rowsData }));
    gridApiRef.current.deselectAll();
  };

  return (
    <React.Fragment>
      <NavBar />
      {renderModals()}
      <div className='inspections-grid-container mtp'>
        <div className='mission-header'>
          <Filters selectOptions={onClickQuickStatusFilter} title='Statut' options={missionStatusTranslation(props.allMissions)} activeMenu={filterOptionSelected} />
          <div className='action-bar'>
            <div
              data-cy='newMission'
              className='item'
              onClick={() => {
                props.dispatch(getActionToDispatch({ type: 'SHOW_MODAL_NEW_MISSION' }));
                props.dispatch(getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'createMission' }));
              }}
            >
              <i className='fas fa-plus-circle' />
              <Text fontStyle='normal'>Commander une mission</Text>
            </div>
          </div>
        </div>
        <ActionsBar
          ref={inputQuickFilterRef}
          selectedRows={selectedRows}
          onClickRefresh={onClickRefresh}
          onClickExportExcel={onClickExportExcel}
          onQuickFilterChange={onQuickFilterChange}
          adminInterface={true}
          interfaceView='missionInterface'
          onClickMultipleAssign={onClickMultiple('assignSpecialist', selectedRows)}
          onClickMultipleUnassign={onClickMultiple('unassignSpecialist', selectedRows)}
          onClickMultipleCancel={onClickMultiple('cancelMission', selectedRows)}
          onClickChangeStatus={onClickMultiple('changeStatus', selectedRows)}
          onClickSendMissionsNotificationsToStaffers={onClickMultiple('sendMissionsNotificationsToStaffers', selectedRows)}
          onSelectMonthsLoadingInspections={onSelectedMonths}
          loadingInspectionsByMonths={monthsSelected}
          numberOfMonthsSelected={numberOfMonthsSelected}
        />
        <div className='ag-theme-balham'>
          {props.allMissions.missions ? (
            <AgGridReact {...gridOptions} rowData={props.allMissions.missions} onGridReady={onGridReady} onColumnVisible={resizeGrid} onToolPanelVisibleChanged={resizeGrid} />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

Missions.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  updatedAt: PropTypes.number,
  allMissions: PropTypes.object,
  concessions: PropTypes.object,
  entities: PropTypes.object,
  numberOfMonthsSelectedMissions: PropTypes.number,
};

const mapStateToProps = (state) => ({
  application: state.application,
  updatedAt: state.missions.lastUpdatedAt,
  allMissions: state.missions,
  numberOfMonthsSelectedMissions: state.missions.numberOfMonthsSelected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getMissions,
  updateNumberOfMonthsSelectedMissions,
});

export default connect(mapStateToProps, mapDispatchToProps)(Missions);
