import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DatePicker, Checkbox } from 'antd';

const { RangePicker } = DatePicker;

const InfosProfessionalExperienceEditor = ({
  save,
  cancel,
}) => {
  const [companyName, setCompanyName] = useState('');
  const [positionTitle, setPositionTitle] = useState('');
  const [duration, setDuration] = useState({});
  const [currentPosition, setCurrentPosition] = useState(false);
  const [errorState, setErrorState] = useState(null);

  const saveInfos = async () => {
    const dataToSend = [{
      companyName,
      positionTitle,
      duration,
    }];
    if (!companyName || !positionTitle || !duration) setErrorState('Tous les champs sont réquis');
    else await save({ professionalUserExperiences: dataToSend });
  };

  const onChange = (date) => {
    setDuration({
      start: date[0],
      end: !currentPosition ? date[1] : null,
      currentPosition,
    });
  };

  const onChangeCheckBox = (e) => {
    setCurrentPosition(e.target.checked);
  };

  return (
    <>
      <ul>
        <div style={{ display: 'block', width: '100%', position: 'relative' }}>
          <Checkbox onChange={onChangeCheckBox}>Poste actuel</Checkbox>
          <li className='full'>
            <label>Nom Entreprise</label>
            <div>
              <input data-cy='companyName' type='text' placeholder='Nom entreprise' onChange={(e) => setCompanyName(e.target.value)} />
            </div>
          </li>
          <li className='full'>
            <label>Poste</label>
            <div>
              <input data-cy='positionTitle' type='text' placeholder='Poste' onChange={(e) => setPositionTitle(e.target.value)} />
            </div>
          </li>
          <li className='full'>
            <label>Durée</label>
            <div>
              <RangePicker onChange={onChange} />
            </div>
          </li>
        </div>
      </ul>
      { errorState && <div className='error'> { errorState } </div> }
      <div className='specialistFormButton'>
        <button className='specialistFormButtonCancel' onClick={cancel}>
          Annuler
        </button>
        <button data-cy='saveProfessionalExperience' className='specialistFormButtonSubmit' onClick={saveInfos}>
          Sauvegarder
        </button>
      </div>
    </>
  );
};

InfosProfessionalExperienceEditor.propTypes = {
  cancel: PropTypes.func,
  save: PropTypes.func,
  addProfessionalExperience: PropTypes.bool,
};

export default InfosProfessionalExperienceEditor;
