/* eslint-disable no-unused-vars, class-methods-use-this */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import NavigationActions from '../../actions/NavigationActions';
import ModalCreateCupiCarPart from '../reportsV3/admin/modals/createCupiCarPart';
import ModalCreateMsaCarPart from '../reportsV3/admin/modals/createMsaCarPart';
import DefectTypologiesTab from '../reportsV3/admin/templates/DefectTypologiesTab';
import LabelTranslationTable from '../reportsV3/admin/templates/LabelTranslationTable';
import Card from '../reportsV3/Card';
import Table from '../reportsV3/Table';
import Filters from '../shared/atoms/Filters';
import NavBar from '../shared/templates/NavBar';
import { cupiCarPartsDataList, msaCarPartsDataList } from './data';

class Referentiels extends React.Component {
  static defaultProps = {};

  state = {
    option: 'defectTypologies',
  };

  componentDidMount() {
    this.props.dispatch(NavigationActions.setNavigation({ navigation: 'referentiels' }));
  }

  renderModals = () => {
    const { dispatch, application, reportsV3 } = this.props;
    const myProps = {
      dispatch,
      application,
      reportsV3,
    };
    const { modalName } = reportsV3;

    switch (modalName) {
      case 'createMsaCarPart':
        return <ModalCreateMsaCarPart {...myProps} />;
      case 'editMsaCarPart':
        return <ModalCreateMsaCarPart isEdit {...myProps} />;
      case 'editCupiCarPart':
        return <ModalCreateCupiCarPart isEdit {...myProps} />;
      default:
        return null;
    }
  };

  changeOption = (option) => {
    this.setState({ option });
  };

  render() {
    const { isAdmin } = this.props.application.user;
    const { option } = this.state;
    const filterOptions = this.props.application.optionsFilters.referentiels;

    return (
      <React.Fragment>
        <NavBar />
        {this.renderModals()}
        <div className='concession mtp'>
          <Filters selectOptions={this.changeOption} title='Configuration des référentiels' options={filterOptions} activeMenu={option} />

          {option === 'msaCarParts' && (
            <Card xs={12}>
              <Table options={{ additionnalQueryParams: '&includeCupis=true' }} list={msaCarPartsDataList(isAdmin)} index='msaCarParts' />
            </Card>
          )}
          {option === 'cupiCarParts' && (
            <Card xs={12}>
              <Table options={{}} list={cupiCarPartsDataList(isAdmin)} index='cupiCarParts' />
            </Card>
          )}
          {isAdmin && option === 'defectTypologies' && (
            <Card xs={12}>
              {' '}
              <DefectTypologiesTab />{' '}
            </Card>
          )}
          {isAdmin && option === 'labelTranslations' && (
            <Card xs={12}>
              {' '}
              <LabelTranslationTable />{' '}
            </Card>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  application: state.application,
  reportsV3: state.reportsV3,
  admin: state.admin, // remove?
});

Referentiels.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  reportsV3: PropTypes.object,
  admin: PropTypes.object, // remove?
};

export default connect(mapStateToProps)(Referentiels);
