import _ from 'lodash';

function difference(object, base) {
  // eslint-disable-next-line no-shadow
  function changes(object, base) {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        // eslint-disable-next-line no-param-reassign
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export default {
  difference,
};
