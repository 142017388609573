import PropTypes from 'prop-types';
import React from 'react';

const RenderCancelButtonText = ({ actionCancel }) => (
  <button type='button' onClick={actionCancel} className='negativ'>
    Annuler
  </button>
);

RenderCancelButtonText.propTypes = {
  actionCancel: PropTypes.func,
};

export default RenderCancelButtonText;
