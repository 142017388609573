import PropTypes from 'prop-types';
import React from 'react';

export default class CommentEditor extends React.Component {
  state = {
    comments: {
      personality: '',
      experience: '',
      availability: '',
      warnings: '',
      other: '',
      specialistFeedback: '',
    },
  };

  componentDidMount() {
    if (this.props.comments) {
      this.setState({
        comments: this.props.comments,
      });
    }
  }

  capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  fillUserToEdit = (tempValue, label) => {
    const { comments } = this.state;

    let value = tempValue;

    if (value && value.length < 3) {
      value = this.capitalizeFirstLetter(value);
    }
    comments[label] = value;

    this.setState(() => ({ comments }));
  };

  render() {
    const { comments } = this.state;
    const { fields } = this.props;
    return (
      <React.Fragment>
        <ul>
          {fields.map((field, key) => (
            <li className='full' key={key}>
              <label className={`${field.value === 'warnings' && 'red'}`}>{field.label}</label>
              <div>
                <textarea
                data-cy={field.label}
                  value={comments && comments[field.value] !== null ? comments[field.value] : ''}
                  placeholder={field.label}
                  onChange={(e) => this.fillUserToEdit(e.target.value, field.value)}
                />
              </div>
            </li>
          ))}
        </ul>
        <div className='specialistFormButton'>
          <button className='specialistFormButtonCancel' onClick={() => this.props.cancel()}>
            annuler
          </button>
          <button
          data-cy='saveComments'
            className='specialistFormButtonSubmit'
            onClick={async () => {
              this.props.save(this.state);
            }}>
            sauvegarder
          </button>
        </div>
      </React.Fragment>
    );
  }
}

CommentEditor.propTypes = {
  comments: PropTypes.object,
  fields: PropTypes.array,
  lastRefresh: PropTypes.number,
  cancel: PropTypes.func,
  save: PropTypes.func,
};
