import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../constants';
import DefaultGridOptions from '../../shared/atoms/DefaultGridOptions';
import BillingMenu from '../molecules/BillingMenu';

const actionMenuRow = (onClickOnMenuItem) => (menuProps) =>
  (
    <>
      <BillingMenu
        {...menuProps}
        onClickEditBilling={(value) => onClickOnMenuItem(constants.nameModal.billing.editOrViewBilling, value)}
        onClickDetailsSpecialist={(value) => onClickOnMenuItem(constants.nameModal.specialist.detailsSpecialist, value)}
      />
    </>
  );

const gridOptions = (onClickOnMenuItem) => ({
  ...DefaultGridOptions,
  rowBuffer: 5,
  pagination: true,
  paginationPageSize: 50,
  getRowNodeId: (a) => a._id,
  columnDefs: [
    {
      field: 'readableId',
      headerName: 'Id',
      width: 60,
      hide: true,
      enableRowGroup: false,
      type: 'numberColumn',
    },
    {
      field: 'specialist.name',
      headerName: 'Spécialiste',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      enableRowGroup: true,
      width: 140,
      valueGetter: (params) => (_.get(params, 'data.specialistHeader') ? params.data.specialistHeader.companyName : ''),
    },
    {
      field: 'iban',
      headerName: 'Iban',
      hide: true,
      width: 140,
      valueGetter: (params) => (_.get(params, 'data.iban') ? params.data.iban : ''),
    },
    {
      field: 'status',
      headerName: 'Statut',
      width: 115,
      enableRowGroup: true,
      valueGetter: (p) => constants.statusBilling[_.get(p, 'data.status')].label,
      filterValueGetter: (p) => _.get(p, 'data.status'),
    },
    {
      field: 'statusFilter',
      width: 145,
      filterValueGetter: (p) => _.get(p, 'data.status'),
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      notExportable: true,
    },
    {
      headerName: 'Montant inspections en €',
      field: 'inspectionsTotal',
      width: 130,
      enableRowGroup: false,
      valueGetter: (p) => (_.get(p, 'data.inspectionsTotal') ? parseFloat(`${_.get(p, 'data.inspectionsTotal')}`) : 0),
    },
    {
      headerName: 'Somme des ajustements en €',
      field: 'adjustmentsTotal',
      width: 130,
      enableRowGroup: false,
      valueGetter: (p) => (_.get(p, 'data.adjustmentsTotal') ? parseFloat(`${_.get(p, 'data.adjustmentsTotal')}`) : 0),
    },
    {
      headerName: 'TVA en €',
      field: 'vat',
      width: 130,
      enableRowGroup: false,
      valueGetter: (p) => (_.get(p, 'data.vat') ? parseFloat(`${_.get(p, 'data.vat')}`) : 0),
    },
    {
      headerName: 'Total en €',
      field: 'total',
      width: 130,
      enableRowGroup: false,
      valueGetter: (p) => (_.get(p, 'data.total') ? parseFloat(`${_.get(p, 'data.total')}`) : 0),
    },
    {
      headerName: 'Généré le',
      field: 'createdAt',
      width: 145,
      valueGetter: (p) => moment(_.get(p, 'data.createdAt')).format(constants.formatDateTimeAppointment),
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: 'Accepté le',
      field: 'orderedAt',
      width: 145,
      valueGetter: (p) => (_.get(p, 'data.acceptedDate') ? moment(_.get(p, 'data.acceptedDate')).format(constants.formatDateTimeAppointment) : ''),
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: `Début facture`,
      field: 'invoiceFrom',
      width: 100,
      valueGetter: (p) => moment(_.get(p, 'data.invoiceFrom')).format(constants.formatDateAppointment),
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: `Fin facture`,
      field: 'invoiceTo',
      width: 100,
      valueGetter: (p) => moment(_.get(p, 'data.invoiceTo')).format(constants.formatDateAppointment),
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      field: '_id',
      headerName: '',
      width: 45,
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: actionMenuRow(onClickOnMenuItem),
      cellClass: 'editionCell',
      suppressNavigable: true,
      suppressMovable: true,
      suppressSizeToFit: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      pinned: 'right',
      notExportable: true,
    },
  ],
  suppressDragLeaveHidesColumns: true,
  suppressMakeColumnVisibleAfterUnGroup: true,
});

gridOptions.propTypes = {
  onClickMenuItem: PropTypes.func,
  adminInterface: PropTypes.bool,
};

export default gridOptions;
