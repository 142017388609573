import PropTypes from 'prop-types';
import React from 'react';

const CardItemInDrawer = ({ title, icon, children, addNewDocument, showButton = false }) => (
  <details open>
    <summary>
      <span>
        {' '}
        {icon || ''} {title}{' '}
      </span>
      {showButton && (
        <button className='buttonUpdate' onClick={addNewDocument}>
          Ajouter des documents
        </button>
      )}
    </summary>
    <div className='body'>{children}</div>
  </details>
);

CardItemInDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  icon: PropTypes.element,
  addNewDocument: PropTypes.func,
  showButton: PropTypes.bool,
};

export default CardItemInDrawer;
