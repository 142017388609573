/* eslint-disable class-methods-use-this */
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConcessionsActions from '../../../actions/Concessions';
import NavigationActions from '../../../actions/NavigationActions';
import { updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import {
  userConcessionsOnDemandInspectionsOptions as createOnDemandOptions,
  userConcessionsPlannedInspectionsOptions as createPlannedOptions,
} from '../../inspections/atoms/gridOptions';
import ModalCancelInspection from '../../inspections/organisms/ModalCancelInspection';
import ModalEditCreateInspection from '../../inspections/organisms/ModalEditCreateInspection';
import ModalImportSpreadsheet from '../../inspections/organisms/ModalImportSpreadsheet';
import ModalViewInspection from '../../inspections/organisms/ModalViewInspection';
import InspectionsTable from '../../inspections/templates/InspectionsTable';
import Filters from '../../shared/atoms/Filters';
import NavBar from '../../shared/templates/NavBar';
import ActionsBar from './organisms/ActionsBar';
import InspectConcessSubMenu from '../../shared/organisms/InspectConcessSubMenu';

const Inspections = (props) => {
  const [status, setStatus] = useState('ordered');
  const [option, setOption] = useState('');

  const onChangeStatus = (statusF) => {
    setStatus(statusF);
  };

  useEffect(() => {
    props.dispatch(NavigationActions.setNavigation({ navigation: 'inspections' }));

    const { search } = props.location;

    const params = queryString.parse(search);
    const tab = params.menu;

    if (tab === 'domicile') {
      setOption('planned');
    } else if (!tab) {
      setOption('planned');
    }
  }, []);

  const renderModals = () => {
    const { dispatch, application, concessions, userRole, lastUpdate } = props;
    const myProps = {
      inspectionType: option,
      dispatch,
      application,
      concessions,
      userRole,
    };

    const { modalName, modalData } = concessions;

    const onRequestClose = async () => {
      await updatePlannedInspections(dispatch, lastUpdate);
      return dispatch({ type: 'HIDE_MODAL' });
    };
    let modalToDisplay = '';
    if (modalName === 'duplicateConcessionInspection') modalToDisplay = 'duplicate';

    switch (modalName) {
      case 'importSpreadsheet':
        return <ModalImportSpreadsheet closeModal={() => dispatch({ type: 'HIDE_MODAL' })} />;
      case 'createConcessionInspection':
      case 'duplicateConcessionInspection':
        return <ModalEditCreateInspection
          modalToDisplay={modalToDisplay}
          isOpen
          create={(modalName !== 'duplicateConcessionInspection')}
          {...myProps}
          inspection={(modalName === 'duplicateConcessionInspection') ? modalData : {}}
          onRequestClose={() => dispatch({ type: 'HIDE_MODAL' })}
        />;
      case 'viewInspection':
        return <ModalViewInspection {...myProps} />;
      case 'cancelInspection':
        return <ModalCancelInspection isOpen {...myProps} inspection={modalData} onRequestClose={() => onRequestClose()} />;
      default:
        return null;
    }
  };

  const changeOption = (selectedOption) => {
    const { dispatch } = props;

    dispatch(ConcessionsActions.getActionToDispatch({ type: 'UPDATE_STATUS', status: '' }));
    dispatch(ConcessionsActions.getActionToDispatch({ type: 'UPDATE_SEARCH', search: '' }));
    dispatch(ConcessionsActions.getActionToDispatch({ type: 'UPDATE_FACETS_CONCESSION', facetsConcession: null }));
    setOption(selectedOption);
    props.history.push({ search: selectedOption === 'planned' ? '?menu=domicile' : '' });
  };

  return (
    <div>
      {renderModals()}
      <NavBar />
      <div className='appcontainer'>
        <Filters
          selectOptions={changeOption}
          className='concessionFilter'
          title=''
          options={[
            { menuSelector: 'planned', value: 'MSA Inspect' },
            { menuSelector: 'onDemand', value: 'MSA App' },
          ]}
          activeMenu={option}
        />
        { option === 'planned' && <InspectConcessSubMenu
          onChangeStatus={onChangeStatus}
          inspectionsOrMissions={props.inspections}
        /> }
        <div className='concession-header'>
          <ActionsBar planned={option === 'planned'} />
        </div>

        <div className='inspections-grid-container'>
          {option === 'onDemand' && <InspectionsTable onDemand options={createOnDemandOptions(props.userRole, option)} />}
          {option === 'planned' && <InspectionsTable status={status} planned options={createPlannedOptions(props.userRole, status)} />}
        </div>
      </div>
    </div>
  );
};

Inspections.defaultProps = {};

const mapStateToProps = (state) => ({
  application: state.application,
  concessions: state.concessions,
  inspections: state.plannedInspections.inspections,
  userRole: state.application.user.role,
  userEntity: state.application.user.concession.entityId,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
});

Inspections.propTypes = {
  dispatch: PropTypes.func,
  concessions: PropTypes.object,
  inspections: PropTypes.array,
  application: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  userRole: PropTypes.string,
  userEntity: PropTypes.string,
  lastUpdate: PropTypes.number,
};

export default connect(mapStateToProps)(withRouter(Inspections));
