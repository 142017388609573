import fr from 'date-fns/locale/fr';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import constants from '../../../constants';
import InspectionsServices from '../../../services/InspectionsServices';
import SpecialistSelectors from '../../shared/atoms/SpecialistSelectors';
import AddEditTimeSlotPicker from '../../shared/molecules/AddEditTimeSlotPicker';

const hourFormat = 'HH:mm';

class ModalAssignInspections extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      days: [
        {
          date: moment().startOf('d').toDate(),
          startingTime: moment('09:00', hourFormat).toDate(),
          endingTime: moment('18:00', hourFormat).toDate(),
        },
      ],
      specialistId: null,
      inspectionDuration: moment('01:00', hourFormat).toDate(),
      appointments: [],
      error: '',
      appointmentsCreated: false,
      loading: false,
      apiCalled: false,
    };

    this.state = {
      ...this.initialState,
    };
  }

  async assignToAll() {
    if (!this.state.specialistId) {
      this.setState({ error: "Vous n'avez pas sélectionné de spécialiste", loading: false });
    } else {
      this.setState({ apiCalled: true });
      InspectionsServices.updateInspections(
        this.state.appointments.map((a) => ({
          _id: a.inspectionId,
          appointment: {
            date: a.appointmentDate.format(constants.formatDateTimeAppointment),
          },
          specialist: this.state.specialistId,
          // duration: this.state.inspectionDuration.getMinutes() + (this.state.inspectionDuration.getHours() * 60),
        })),
      )
        .then((results) => {
          if (results.status === 200) {
            this.props.updatePlannedInspections(this.props.dispatch, this.props.lastUpdate);
            this.closeModal({ didUpdate: true });
          } else {
            throw new Error(_.get(results, 'body.error') || "Une erreur s'est produite");
          }
        })
        .catch((e) => {
          this.setState({ apiCalled: false });
          this.setState({ loading: false, error: e.message });
        });
    }
  }

  componentAddEditDateTimePickerUpdated = (days) => this.setState({ days, appointmentsCreated: false });

  createAppointments = () => {
    try {
      if (!moment(this.state.inspectionDuration).isValid()) {
        throw new Error('durationMissing');
      }

      if (this.state.days.some((d) => !moment(d.date).isValid() || !moment(d.startingTime).isValid() || !moment(d.endingTime).isValid())) {
        throw new Error('someDatesAreInvalid');
      }

      if (moment(this.state.inspectionDuration).isValid()) {
        const sortedMomentDays = [...this.state.days]
          .filter((d) => moment(d.date).isValid())
          .map((d) => ({ date: moment(d.date), startingTime: moment(d.startingTime), endingTime: moment(d.endingTime) }))
          .sort((a, b) => (a.date.isSame(b.date, 'day') ? a.startingTime.isAfter(b.startingTime) : a.date.isAfter(b.date)));
        const appointments = [];
        const inspectionsThatNeedAppointments = [...this.props.inspections.map((i) => i._id)];

        if (sortedMomentDays.some((d) => d.endingTime.isSameOrBefore(d.startingTime))) {
          throw new Error('someTimesAreNotInOrder');
        }

        let doOverlap = false;
        sortedMomentDays.some((d1, index1) => {
          if (index1 < sortedMomentDays.length - 1) {
            let index2 = index1 + 1;
            let d2 = sortedMomentDays[index2];
            while (!doOverlap && index2 < sortedMomentDays.length && d1.date.isSame(d2.date, 'day')) {
              doOverlap =
                (d2.startingTime.isBefore(d1.endingTime) && d2.startingTime.isSameOrAfter(d1.startingTime)) ||
                (d2.endingTime.isSameOrBefore(d1.endingTime) && d2.endingTime.isAfter(d1.startingTime));
              index2 += 1;
              d2 = sortedMomentDays[index2];
            }
          }
          return doOverlap;
        });

        if (doOverlap) {
          throw new Error('someOverlap');
        }

        sortedMomentDays.forEach((day) => {
          let appntStartingTime = moment(day.date).hours(day.startingTime.hours()).minutes(day.startingTime.minutes());
          const end = moment(day.date).hours(day.endingTime.hours()).minutes(day.endingTime.minutes());
          let appointmentDidFit = true;
          while (appointmentDidFit && inspectionsThatNeedAppointments.length) {
            const endOfAppointment = moment(appntStartingTime).add(this.state.inspectionDuration.getHours(), 'hours').add(this.state.inspectionDuration.getMinutes(), 'minutes');
            if (endOfAppointment.isAfter(end)) {
              appointmentDidFit = false;
            } else {
              appointments.push({
                inspectionId: inspectionsThatNeedAppointments.pop(),
                appointmentDate: moment(appntStartingTime),
              });
              appntStartingTime = endOfAppointment;
            }
          }
        });

        this.setState({ appointments });

        if (inspectionsThatNeedAppointments.length) {
          throw new Error('notEnoughTime');
        }

        this.setState({ error: '', appointmentsCreated: true });
      }
    } catch (e) {
      if (e.message === 'someOverlap') {
        this.setState({
          error: 'Certaines des périodes se chevauchent',
          appointments: [],
          appointmentsCreated: false,
        });
      } else if (e.message === 'someTimesAreNotInOrder') {
        this.setState({
          error: 'Certaines périodes ont leur heure de fin avant leur heure de début',
          appointments: [],
          appointmentsCreated: false,
        });
      } else if (e.message === 'notEnoughTime') {
        this.setState({
          error: "Il n'y a pas assez de temps pour faire toutes les inspections sélectionnées avec leur durée estimée",
          appointmentsCreated: false,
        });
      } else if (e.message === 'durationMissing') {
        this.setState({
          error: "Vous n'avez pas mis de durée d'inspection",
          appointmentsCreated: false,
        });
      } else if (e.message === 'someDatesAreInvalid') {
        this.setState({
          error: 'Certaines dates ou heures rentrées sont invalides',
          appointmentsCreated: false,
        });
      } else {
        this.setState({
          error: "Une erreur s'est produite",
          appointments: [],
          appointmentsCreated: false,
        });
      }
    }
  };

  onInspectionDurationChange = (value) => this.setState({ inspectionDuration: value, appointmentsCreated: false });

  onSpecialistChange = (specialist) => this.setState({ specialistId: specialist ? specialist._id : null });

  closeModal = ({ didUpdate }) => {
    this.setState(this.initialState);
    this.props.onRequestClose({ didUpdate });
  };

  render() {
    const appointmentsToDisplay = this.state.appointments.map((a, i) => (
      <div key={`multipleAssignAppointments-${i}`} style={{ marginBottom: '4px', fontSize: '1em' }}>
        {a.appointmentDate.format('dddd DD/MM/YYYY HH:mm')}
      </div>
    ));

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        contentLabel='Modal multiple assign'
        overlayClassName='modal-overlay'
        className='modal modalMultipleAssign'
      >
        <div className='modalContent'>
          <div className='mainTitle'>Assigner un spécialiste à {this.props?.inspections?.length} inspections</div>
          <div className='horizontalForm'>
            <div className='horizontalFormPart'>
              <div className='formTitle'>Informations inspections</div>
              <form className=''>
                <div className='formInput'>
                  <SpecialistSelectors type='text' placeholder='Sélectionnez un spécialiste' selectOptions={this.onSpecialistChange} label='Spécialiste' />
                </div>
                <div className='formInput'>
                  <label>Durée d'une inspection</label>
                  <DatePicker
                    selected={this.state.inspectionDuration}
                    onChange={this.onInspectionDurationChange}
                    dateFormat={hourFormat}
                    timeIntervals={5}
                    showTimeSelect
                    showTimeSelectOnly
                    locale={fr}
                  />
                </div>
                <div className='formInput'>
                  <label>Plages horaires d'inspections</label>
                  <AddEditTimeSlotPicker handler={this.componentAddEditDateTimePickerUpdated} days={this.initialState.days} />
                </div>
              </form>
            </div>
            <div className='horizontalFormPart'>
              <div className='formTitle'>Dates des inspections</div>
              <div className='formInput'>{appointmentsToDisplay}</div>
            </div>
          </div>
          {this.state.apiCalled && <p>Assignation en cours...</p>}
          {this.state.error && (
            <div className='error'>
              <p>{this.state.error}</p>
            </div>
          )}
          {!this.state.error && <div style={{ height: '50px' }} />}
          <div className='spacearound formButton'>
            <button type='button' onClick={() => this.closeModal({})} className='negativ'>
              Annuler
            </button>
            {!this.state.loading && this.state.appointmentsCreated && !this.state.apiCalled && (
              <button type='button' onClick={() => this.assignToAll()} className='positiv'>
                Assigner au spécialiste
              </button>
            )}
            {!this.state.loading && !this.state.appointmentsCreated && (
              <button type='button' onClick={() => this.createAppointments()} className='positiv'>
                Générer les rendez-vous
              </button>
            )}
            {this.state.loading && (
              <button type='button' disabled className='positiv'>
                <i className='fa fa-spinner fa-spin' />
              </button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

ModalAssignInspections.propTypes = {
  application: PropTypes.object,
  isOpen: PropTypes.bool,
  inspections: PropTypes.array,
  onRequestClose: PropTypes.func,
  updatePlannedInspections: PropTypes.func,
  lastUpdate: PropTypes.number,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  application: state.application,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updatePlannedInspections,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAssignInspections);
