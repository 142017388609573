export default {
  optionsStatus: [
    {
      value: 'pending_validation',
      label: 'En attente de validation',
      displayName: 'En attente de validation',
    },
    { value: 'ordered', label: 'Commandées', displayName: 'Commandée' },
    { value: 'scheduled', label: 'Programmées', displayName: 'Programmée' },
    { value: 'done', label: 'Réalisée', displayName: 'Réalisée' },
    { value: 'refused_validation', label: 'Refusées', displayName: 'Refusée' },
    { value: 'canceled', label: 'Annulées', displayName: 'Annulée' },
  ],
};
