import { Checkbox, Switch, Tooltip } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const getItemIcon = (kind) => {
  switch (kind) {
    case 'BoolCheckpoint':
      return (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Type: Checkbox'>
          <i className='fas fa-toggle-on typeIcons' />{' '}
        </Tooltip>
      );
    case 'TextCheckpoint':
      return (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Type: Texte'>
          <i className='fas fa-paragraph typeIcons' />
        </Tooltip>
      );
    case 'NumberCheckpoint':
      return (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Type: Numéro'>
          <i className='fas fa-sort-numeric-up typeIcons' />
        </Tooltip>
      );
    case 'EnumCheckpoint':
      return (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Type: Liste'>
          <i className='fas fa-list typeIcons' />
        </Tooltip>
      );
    case 'DateCheckpoint':
      return (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Type: Date'>
          <i className='fas fa-calendar typeIcons' />
        </Tooltip>
      );
    case 'PhotoCheckpoint':
      return (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Type: Photo'>
          <i className='fas fa-camera typeIcons' />
        </Tooltip>
      );
    case 'DividerCheckpoint':
      return (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Type: Diviseur'>
          <i className='fas fa-grip-lines typeIcons' />
        </Tooltip>
      );
    case 'CommentCheckpoint':
      return (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Type: Commentaire'>
          <i className='fas fa-comment typeIcons' />
        </Tooltip>
      );
    default:
      return (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Type: Inconnu'>
          <i className='fas fa-paragraph typeIcons' />
        </Tooltip>
      );
  }
};

const DragHandle = SortableHandle(() => (
  <span style={{ marginRight: 8, fontSize: 12 }}>
    <i className='fas fa-arrows-alt-v' />
  </span>
));

const SortableItem = SortableElement(({
  label, handleDelete, hideCheckpoint, sortIndex, description, kind, answerRequired, hideInViewer, isReport, handleRequired, itemId = '',
}) => (
  <li data-cy={itemId} className='SortableItem infinite'>
    <div style={{ maxWidth: '100%', width: '75%' }}>
      <DragHandle />
      {label} - {description}
    </div>
    <div style={{ width: '28%', textAlign: 'center' }}>
      {isReport && (
        <Tooltip overlayStyle={{ zIndex: 1300 }} title='Champs obligatoire'>
          <Checkbox name='answerRequired' style={{ marginRight: 10 }} checked={answerRequired} onChange={(e) => handleRequired(e, sortIndex)} />
        </Tooltip>
      )}
      {getItemIcon(kind)}
      {isReport && (
        <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 1300 }} title='Cacher ce point de contrôler dans le viewer'>
          <Switch name='hideInViewer' checked={hideInViewer} onChange={(e) => hideCheckpoint(e, sortIndex)} />
        </Tooltip>
      )}
      <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 1300 }} title='Supprimer ce point de contrôle'>
        <button className='btnIcon delete' style={{ zIndex: 1000 }} onClick={(e) => handleDelete(e, sortIndex)}>
          <i className='fas fa-trash-alt' />
        </button>
      </Tooltip>
    </div>
  </li>
));

const SortableList = SortableContainer(({
  items, handleDelete, hideCheckpoint, handleRequired, isReport,
}) => (
  <ul className='SortableList'>
    {items.checkpoints
      && items.checkpoints.map((item, i) => (
        <SortableItem
          itemId={item?.refCheckpoint?._id}
          key={`item-${i}`}
          index={i}
          sortIndex={i}
          kind={_.get(item, 'refCheckpoint.kind') || _.get(item, 'kind')}
          label={_.get(item, 'refCheckpoint.label') || _.get(item, 'label')}
          description={_.get(item, 'refCheckpoint.description') || _.get(item, 'description')}
          answerRequired={isReport && _.get(item, 'answerRequired')}
          hideInViewer={isReport && _.get(item, 'hideInViewer')}
          isReport={isReport}
          helperClass='SortableHelper'
          hideCheckpoint={hideCheckpoint}
          handleDelete={handleDelete}
          handleRequired={handleRequired}
        />
      ))}
  </ul>
));

class DragDropList extends Component {
  render() {
    return (
      <SortableList
        items={this.props.sectionItems}
        onSortEnd={this.props.onSortEnd}
        distance={5}
        isReport={this.props.isReport}
        handleDelete={this.props.handleDeleteItem}
        hideCheckpoint={this.props.hideCheckpointItem}
        handleRequired={this.props.handleRequiredItem}
      />
    );
  }
}

DragDropList.propTypes = {
  sectionItems: PropTypes.any,
  onSortEnd: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  hideCheckpointItem: PropTypes.func,
  handleRequiredItem: PropTypes.func,
  isReport: PropTypes.bool,
};

export default DragDropList;
