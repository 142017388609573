import CountriesServices from '../services/CountriesServices';

// Action types
export const REQUEST_COUNTRIES = 'REQUEST_COUNTRIES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';
export const SERVER_KILLED_SESSION = 'SERVER_KILLED_SESSION';

// Action creator
export const serverKilledSession = () => ({
  type: SERVER_KILLED_SESSION,
});

export const requestCountries = () => ({
  type: REQUEST_COUNTRIES,
});

export const getCountriesSuccess = (countries) => ({
  type: GET_COUNTRIES_SUCCESS,
  countries,
});

export const getCountriesError = (error) => ({
  type: GET_COUNTRIES_ERROR,
  error,
});

export const getCountries = async (dispatch) => {
  try {
    dispatch(requestCountries());
    const result = await CountriesServices.getCountries();
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getCountriesError(result.body.message || 'Failed'));
    return dispatch(getCountriesSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};
