import React from 'react';
import PropTypes from 'prop-types';
import Urlify from 'urlify';
import _ from 'lodash';

const statusTranslation = {
  ordered: 'En attente',
  scheduled: 'Programmée',
  done: 'Réalisée',
  canceled: 'Annulée',
  waitForAdminValidation: 'Validation Admin',
  pending_validation: 'Attente validation',
  refused_validation: 'Refusée',
  no_show: 'Non présentée',
};

const urlify = Urlify.create({
  spaces: '',
  toLower: true,
  nonPrintable: '',
  trim: true,
});

const statusStyles = {
  ordered: '#ffad05',
  scheduled: '#5cd693',
  done: '#1E824C',
  canceled: '#a40606',
  pending_validation: '#60686d',
  refused_validation: '#a40606',
};

const StatusCellRenderer = ({ data, value }) => (
  <span>
    <svg style={{ marginRight: '6px', marginBottom: '1px' }} xmlns='http://www.w3.org/2000/svg' width={6} height={6} viewBox='0 0 6 6'>
      <circle fill={statusStyles[_.get(data, 'status')]} cx={3} cy={3} r={3} />
    </svg>
    {value}
  </span>
);

StatusCellRenderer.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object,
};

const compareStatus = (a, b) => {
  const statusPriorities = {
    ordered: '0',
    scheduled: '1',
    done: '2',
    pending_validation: '3',
    refused_validation: '4',
    canceled: '5',
  };

  if (a === b) return 0;
  return statusPriorities[a] < statusPriorities[b] ? -1 : 1;
};

// TODO: delete this as ag-grid supports filtering
const statusFilterComparator = (filter, value, filterText) => {
  const cleanFilterText = urlify(filterText);
  const cleanFrenchStatus = urlify(statusTranslation[value]);

  switch (filter) {
    case 'contains':
      return cleanFrenchStatus.indexOf(cleanFilterText) >= 0;
    case 'notContains':
      return cleanFrenchStatus.indexOf(cleanFilterText) === -1;
    case 'equals':
      return cleanFrenchStatus === cleanFilterText;
    case 'notEqual':
      return cleanFrenchStatus !== cleanFilterText;
    case 'startsWith':
      return cleanFrenchStatus.indexOf(cleanFilterText) === 0;
    case 'endsWith':
      return cleanFrenchStatus.endsWith(cleanFilterText);
    default:
      return false;
  }
};

export { StatusCellRenderer, compareStatus, statusFilterComparator, statusTranslation };
