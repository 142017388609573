import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import moment from 'moment';
import Text from '../../shared/atoms/Text';

/* eslint-disable */

const cancellationTexts = {
  MSA_CANCEL: 'MSA',
  CLIENT_CANCEL: 'Le client',
  SELLER_CANCEL: 'Le vendeur',
};

const ViewCancelInspectionInfos = ({ inspection }) => (
  <form className='mt10px formModal'>
    <div className='mainTitle'>
      <Text fontStyle='bold'>Inspection n*{inspection.readableId}</Text>
    </div>
    <div className='center'>
      <Text fontStyle='demi' color='#9b9b9b'>
        Annulée le {moment(_.get(inspection, 'cancelInfos.date')).lang('fr').format('LLL')}
      </Text>
    </div>
    <div className='segmented-bar m-b-30'>
      <div className='selected'>
        <Text fontStyle='demi' color='#1f5288'>
          Détail de l'inspection
        </Text>
      </div>
    </div>
    <Row>
      <Col xs={12} md={4}>
        <label>Annulée par</label>
        <Text fontStyle='normal' size={14}>
          {cancellationTexts[_.get(inspection, 'cancelInfos.reason')]}
        </Text>
      </Col>
      <Col xs={12} md={4}>
        <label>Raison</label>
        <Text fontStyle='normal' size={14}>
          {_.get(inspection, 'cancelInfos.comment') || <span>&nbsp;</span>}
        </Text>
      </Col>
    </Row>
  </form>
);

export default ViewCancelInspectionInfos;
