const missionsCounterByStatus = (missions, status) => missions.filter((mission) => mission.status === status).length;

const missionStatusTranslation = (props) => [
  { menuSelector: 'pending_validation', value: `En attente de validation ${props ? `(${missionsCounterByStatus(props.missions, 'pending_validation')})` : ''} ` },
  { menuSelector: 'ordered', value: `En attente ${props ? `(${missionsCounterByStatus(props.missions, 'ordered')})` : ''} ` },
  { menuSelector: 'scheduled', value: `Programmée${props ? `s (${missionsCounterByStatus(props.missions, 'scheduled')})` : ''} ` },
  { menuSelector: 'ongoing', value: `En cours ${props ? `(${missionsCounterByStatus(props.missions, 'ongoing')})` : ''} ` },
  { menuSelector: 'done', value: `Réalisée${props ? `s (${missionsCounterByStatus(props.missions, 'done')})` : ''} ` },
  { menuSelector: 'canceled', value: `Annulée${props ? `s (${missionsCounterByStatus(props.missions, 'canceled')})` : ''} ` },
  { menuSelector: 'none', value: `Toutes (${props ? props.missions.length : ''})` },
];

const statusOptionsSelect = (status) => [
  {
    value: 'scheduled',
    label: `Programmée`,
    disabled: status === 'scheduled',
  },
  {
    value: 'ongoing',
    label: `En cours`,
    disabled: status === 'ongoing',
  },
  {
    value: 'done',
    label: `Réalisée`,
    disabled: status === 'scheduled',
  },
];

export { missionStatusTranslation, statusOptionsSelect };
