/* eslint-disable no-plusplus, no-inner-declarations, prefer-arrow-callback */

import { Col, Row } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import constants from '../../../constants';
import ReportsServices from '../../../services/ReportsService';
import Text from '../../shared/atoms/Text';
import Notifications, { notify } from '../FRE/components/toast/notify';
import TechnicalCostFormContainer from './TechnicalCostFormContainer';
import TechnicalCostSearchBar from './TechnicalCostSearchBar';

const contextOfConfiguration = {
  msa: 'défaut MSA',
  group: 'groupe',
  site: 'site',
  new_site: 'nouveau site',
  multi_sites: 'plusieurs sites',
  all_sites_group: 'groupe et tous les sites',
};

class Index extends React.Component {
  state = {
    active: true,
    selection: {}, // repair-cost  loaded from selected item
    confMSA: {}, // conf by default of msa
    groups: [], // all groups / entities
    selectedGroup: '', // current selected group / entity
    sites: [], // list of the sites from the conf of the group
    confOfGroup: {}, // the conf of the group
    listConfByGroup: [], // list of all the conf of the concessions of the group
    selectedSites: [], // list of all the ids of the sites that are concerned
    contextOperation: '', // context of the configuration : msa, groupe, site
    currentlyModified: false, // state of the form : original or modified?
    loadingMenu: false,
    data: null,
  };

  componentDidMount() {
    const isAdmin = this.props.user && this.props.user.isAdmin;

    if (isAdmin === true) {
      ReportsServices.getAll(`${constants.URI.v3.entities.index}`).then((res) => this.setState({ groups: _.get(res, 'body') }));
      ReportsServices.getAll(`${constants.URI.v3.technicalCostByLevel}`).then((confMSA) => {
        if (!confMSA) {
          const emptyConfiguration = {
            _id: 'emptyConfiguration',
            mechanics: { T1: 0, T2: 0, T3: 0 },
            electricity: { T1: 0, T2: 0, T3: 0 },
            trim: { T1: 0, T2: 0, T3: 0 },
            body: { T1: 0, T2: 0, T3: 0 },
            paint: { T1: 0, T2: 0, T3: 0 },
          };
          this.setState({ confMSA: emptyConfiguration, selection: emptyConfiguration });
        } else {
          this.setState({ confMSA, selection: confMSA });
        }
        this.setState({ contextOperation: contextOfConfiguration.msa });
      });
    } else {
      const selectedGroup = _.get(this.props.user, 'concession.entityId');

      this.setState({ bySiteByReport: false, selectedGroup }, () => {
        this.getTechnicalConfigurationsOfGroup();
      });
      this.setState({ contextOperation: contextOfConfiguration.group });
    }
  }

  cleanInterface = () =>
    Promise.resolve()
      .then(() => this.setState({ selection: {} }))
      .then(() => this.setState({ contextOperation: '' }))
      .then(() => this.setState({ currentlyModified: false }));

  // GROUPS

  handleSelectGroup = (selectedGroup) => {
    Promise.resolve()
      .then(() => this.cleanInterface())
      .then(() => this.setState({ selectedSites: [] }))
      .then(() => this.setState({ sites: [] }))
      .then(() => this.setState({ confOfGroup: [] }))
      .then(() => this.setState({ listConfByGroup: [] }))
      .then(() => this.setState({ selectedGroup }))
      .then(() => this.setState({ contextOperation: contextOfConfiguration.group }))
      .then(() => {
        this.getTechnicalConfigurationsOfGroup();
      });
  };

  refreshTechnicalConfOfGroup = async () => {
    const listConfByGroup = await ReportsServices.getAll(`${constants.URI.v3.global}entities/${this.state.selectedGroup}/technicityCostByLevel/concessions`, ``);
    this.setState({ listConfByGroup });
  };

  getTechnicalConfigurationsOfGroup = async () => {
    await this.refreshTechnicalConfOfGroup();

    const res = await ReportsServices.getAll(`${constants.URI.v3.global}concessions`, ``);
    const sitesOfGroup = _.filter(_.get(res, 'concessions.items'), { entity: { _id: this.state.selectedGroup } });
    const sites = _.map(sitesOfGroup, (site) => {
      const _id = _.get(site, '_id');
      return {
        key: _id,
        value: _id,
        title: _.get(site, 'name'),
      };
    });
    this.setState({ sites });

    let confOfGroup = await ReportsServices.getAll(`${constants.URI.v3.global}entities/${this.state.selectedGroup}/technicityCostByLevel`, ``);

    if (!confOfGroup) {
      confOfGroup = _.cloneDeep(this.state.confMSA);
    }

    this.setState({ confOfGroup });
    this.setState({ selection: confOfGroup });
  };

  // SITES

  handleSelectSite = (selectedSites) => {
    Promise.resolve()
      .then(() => this.cleanInterface())
      .then(() => this.setState({ selectedSites }))
      .then(() => this.getTechnicalConfigurationsOfSites());
  };

  getTechnicalConfigurationsOfSites = async () => {
    const foundConfigurations = this.state.listConfByGroup.filter(
      (concessionConfiguration) => this.state.selectedSites.some((selectedConfiguration) => selectedConfiguration === concessionConfiguration.refId),
      // eslint-disable-next-line function-paren-newline
    );

    if (this.state.selectedSites.length === 1) {
      if (foundConfigurations.length !== 1) {
        this.setState({ contextOperation: contextOfConfiguration.new_site });
        notify.show('Nouvelle configuration de site', 'info', 0);
        const newConf = _.cloneDeep(this.state.confOfGroup);
        this.setState({ selection: newConf }); // on utilise la conf du nouveau site comme ref
      } else {
        this.setState({ contextOperation: contextOfConfiguration.site });
        this.setState({ selection: foundConfigurations[0] }); // on utilise la conf found
      }
    } else if (this.state.selectedSites.length > 1) {
      if (this.state.selectedSites.length === this.state.sites.length) {
        this.setState({ contextOperation: contextOfConfiguration.all_sites_group });
      } else {
        this.setState({ contextOperation: contextOfConfiguration.multi_sites });
      }

      if (foundConfigurations.length > 0) {
        // using map reduce to keep only common values between all the sites conf found for those sites id
        function reduceConfigs(a, b) {
          function configValCmp(firstValue, secondValue) {
            if (firstValue === secondValue) {
              return firstValue;
            }
            return 0;
          }

          return {
            body: { T1: configValCmp(a.body.T1, b.body.T1), T2: configValCmp(a.body.T2, b.body.T2), T3: configValCmp(a.body.T3, b.body.T3) },
            electricity: {
              T1: configValCmp(a.electricity.T1, b.electricity.T1),
              T2: configValCmp(a.electricity.T2, b.electricity.T2),
              T3: configValCmp(a.electricity.T3, b.electricity.T3),
            },
            mechanics: { T1: configValCmp(a.mechanics.T1, b.mechanics.T1), T2: configValCmp(a.mechanics.T2, b.mechanics.T2), T3: configValCmp(a.mechanics.T3, b.mechanics.T3) },
            paint: { T1: configValCmp(a.paint.T1, b.paint.T1), T2: configValCmp(a.paint.T2, b.paint.T2), T3: configValCmp(a.paint.T3, b.paint.T3) },
            trim: { T1: configValCmp(a.trim.T1, b.trim.T1), T2: configValCmp(a.trim.T2, b.trim.T2), T3: configValCmp(a.trim.T3, b.trim.T3) },
          };
        }

        const newConfig = foundConfigurations.reduce((a, b) => reduceConfigs(a, b));
        newConfig._id = 'SHARED';

        this.setState({ selection: newConfig });
      } else {
        // no config found : using group conf as base
        const newConf = _.cloneDeep(this.state.confOfGroup);
        this.setState({ selection: newConf });
      }
    } else {
      // no site selected : back to group conf
      this.setState({ contextOperation: contextOfConfiguration.group });
      this.setState({ selection: this.state.confOfGroup });
    }
  };

  submitTechConf = (data) => {
    async function postPayload(uriEndpoint, payload) {
      // eslint-disable-next-line no-return-await
      return await ReportsServices.post(`${constants.URI.v3.global}${uriEndpoint}`, payload);
    }

    const newTechConf = {
      mechanics: {
        T1: data.mechanicsTechT1,
        T2: data.mechanicsTechT2,
        T3: data.mechanicsTechT3,
      },
      electricity: {
        T1: data.electricityTechT1,
        T2: data.electricityTechT2,
        T3: data.electricityTechT3,
      },
      trim: {
        T1: data.trimTechT1,
        T2: data.trimTechT2,
        T3: data.trimTechT3,
      },
      body: {
        T1: data.bodyTechT1,
        T2: data.bodyTechT2,
        T3: data.bodyTechT3,
      },
      paint: {
        T1: data.paintTechT1,
        T2: data.paintTechT2,
        T3: data.paintTechT3,
      },
    };

    let uriEndpoint = null;
    let requestPayload = {};

    if (this.state.contextOperation === contextOfConfiguration.msa) {
      uriEndpoint = 'technicityCostByLevel';
      requestPayload = newTechConf;
    } else if (this.state.contextOperation === contextOfConfiguration.group) {
      uriEndpoint = `entities/${this.state.selectedGroup}/technicityCostByLevel`;
      requestPayload = newTechConf;
    } else if (
      this.state.contextOperation === contextOfConfiguration.site ||
      this.state.contextOperation === contextOfConfiguration.new_site ||
      this.state.contextOperation === contextOfConfiguration.multi_sites ||
      this.state.contextOperation === contextOfConfiguration.all_sites_group
    ) {
      uriEndpoint = `entities/${this.state.selectedGroup}/technicityCostByLevel/concessions`;
      requestPayload = {
        concessionsIds: this.state.selectedSites,
        technicalCostConfiguration: newTechConf,
      };
    } else {
      this.setState({ error: "La configuration n' a pas de cible" });
    }

    postPayload(uriEndpoint, requestPayload)
      .then(() => notify.show('Sauvegardé!', 'info', 0))
      .then(() => {
        if (this.state.contextOperation === contextOfConfiguration.group) {
          this.getTechnicalConfigurationsOfGroup();
        } else if (
          this.state.contextOperation === contextOfConfiguration.site ||
          this.state.contextOperation === contextOfConfiguration.new_site ||
          this.state.contextOperation === contextOfConfiguration.multi_sites ||
          this.state.contextOperation === contextOfConfiguration.all_sites_group
        ) {
          this.refreshTechnicalConfOfGroup().then(() => {
            this.getTechnicalConfigurationsOfSites();
          });
        }
      })
      .catch((err) => {
        notify.show('Erreur!', 'error', 0);
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  renderToast = () => <Notifications options={{ wrapperId: 'techConf-toast' }} />;

  render() {
    return (
      <React.Fragment>
        <Row style={{ marginLeft: 10 }}>
          <Col xs={16} className='table-search'>
            <TechnicalCostSearchBar
              index={this.props.index}
              handleSelectGroup={this.handleSelectGroup}
              handleSelectSite={this.handleSelectSite}
              isAdmin={this.props.user && this.props.user.isAdmin}
              groups={this.state.groups}
              selectedGroup={this.state.selectedGroup}
              sites={this.state.sites}
              selectedSite={this.state.selectedSites}
            />
          </Col>
          <Col xs={8} className='center' style={{ marginTop: 35 }}>
            <Text fontStyle='normal' size={14} style={{ color: '#1f5288' }}>
              Modification de :&nbsp; <span fontStyle='bold'> {this.state.contextOperation} </span>
            </Text>
          </Col>
        </Row>

        <TechnicalCostFormContainer index={this.props.index} technicalCostDataset={this.state.selection} onsubmitFunc={this.submitTechConf} />
        {this.renderToast()}
      </React.Fragment>
    );
  }
}

Index.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.application.user,
  reportsV3: state.reportsV3,
});

Index.propTypes = {
  dispatch: PropTypes.func,
  reportsV3: PropTypes.object,
  type: PropTypes.string,
  index: PropTypes.string,
  options: PropTypes.object,
  user: PropTypes.object,
  light: PropTypes.bool,
};

export default connect(mapStateToProps)(Index);
