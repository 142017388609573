import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import EntitiesServices from '../../../../services/EntitiesServices';

const EntitySelect = ({ onSelect, selectedEntity, styles, disabledInputConcessionView }) => {
  const [entities, setEntities] = useState([]);
  const [options, setOptions] = useState([]);

  const getAllEntities = async () => {
    const allEntities = await EntitiesServices.getAllEntities();
    setEntities(allEntities);
  };

  useEffect(() => {
    const allOptions = entities.map((entity) => ({ value: entity, label: entity.name }));
    setOptions(allOptions);
  }, [entities]);

  useEffect(() => {
    (async () => getAllEntities())();
  }, []);

  return (
    <div data-cy='entityName' className='search-input max'>
      <Select
        isClearable={true}
        onChange={(e) => {
          onSelect(e?.value);
        }}
        styles={styles}
        defaultValue={selectedEntity ? { value: selectedEntity, label: selectedEntity?.name } : ''}
        options={options}
        isSearchable
        placeholder='Rechercher un groupe...'
        isDisabled={disabledInputConcessionView}
      />
    </div>
  );
};

EntitySelect.propTypes = {
  onSelect: PropTypes.func,
  selectedEntity: PropTypes.any,
  styles: PropTypes.object,
  entities: PropTypes.array,
  options: PropTypes.array,
  disabledInputConcessionView: PropTypes.bool,
};

export default EntitySelect;
