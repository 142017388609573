/* eslint react/prop-types: 0 */
import _ from 'lodash';
import React from 'react';
import DefaultGridOptions from '../../../shared/atoms/DefaultGridOptions';
import ConcessionsActionsCell from '../../shared/atoms/ConcessionsActionsCell';
import { roleTranslation } from '../../shared/atoms/gridUtils';
import UsersActionsCell from '../../shared/atoms/UsersActionsCell';
import EntitiesActionsCell from './EntitiesActionsCell';

const createConcessionOptions = () => ({
  ...DefaultGridOptions,
  columnDefs: [
    { headerName: 'Nom', field: 'name', enableRowGroup: false },
    { headerName: 'Groupe', field: 'entity.name' },
    {
      headerName: 'Pays',
      field: 'country.name',
      suppressMovable: true,
      enableRowGroup: true,
    },
    { headerName: 'Rapport Inspections MSA (par défaut)', field: 'reportTemplates.plannedInspection.default.title' },
    { headerName: 'Rapport AutoInspect (par défaut)', field: 'reportTemplates.onDemandInspection.default.title' },
    {
      headerName: 'Crée le',
      field: 'createdAt',
      type: 'dateColumn',
      sort: 'desc',
    },
    {
      field: '_id',
      headerName: 'Actions',
      type: 'actionsColumn',
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => _.get(p, 'data') && p.data,
      cellRendererFramework: (data) => <ConcessionsActionsCell data={_.get(data, 'value')} />,
      cellClass: 'editionCell',
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ],
});

const createEntitiesOptions = () => ({
  ...DefaultGridOptions,
  columnDefs: [
    {
      headerName: 'Nom',
      field: 'name',
      suppressMovable: true,
      enableRowGroup: false,
    },
    {
      headerName: 'Pays',
      field: 'country.name',
      suppressMovable: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Crée le',
      field: 'createdAt',
      type: 'dateColumn',
      sort: 'desc',
    },
    {
      field: '_id',
      headerName: 'Actions',
      type: 'actionsColumn',
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: (data) => <EntitiesActionsCell data={_.get(data, 'value')} />,
      cellClass: 'editionCell',
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ],
});

const createUsersOptions = () => ({
  ...DefaultGridOptions,
  columnDefs: [
    {
      headerName: 'ID',
      field: 'readableId',
      type: 'idColumn',
      enableRowGroup: false,
    },
    {
      headerName: 'Crée le',
      field: 'createdAt',
      type: 'dateColumn',
      hide: true,
      sort: 'desc',
    },
    { headerName: 'Prénom', field: 'firstname', enableRowGroup: false },
    { headerName: 'Nom', field: 'lastname', enableRowGroup: false },
    {
      headerName: 'Rôle',
      field: 'role',
      maxWidth: 150,
      valueGetter: (p) => _.get(p, 'data.role') && roleTranslation[_.get(p, 'data.role')],
      filter: 'agSetColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: false },
    },
    { headerName: 'Groupe', field: 'concession.entityName' },
    {
      headerName: 'Site',
      field: 'concession.name',
      valueGetter: (p) => {
        if (_.get(p, 'data.concession.names') && p.data.concession.names.length) {
          // eslint-disable-next-line default-param-last
          return p.data.concession.names.reduce((str = '', name) => `${str}, ${name}`);
        }
        return _.get(p, 'data.concession.name');
      },
    },
    { headerName: 'email', field: 'email', enableRowGroup: false },
    {
      field: '_id',
      headerName: 'Actions',
      type: 'actionsColumn',
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: (data) => <UsersActionsCell data={_.get(data, 'value')} />,
      cellClass: 'editionCell',
      suppressNavigable: true,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ],
});

export { createConcessionOptions, createEntitiesOptions, createUsersOptions };
