import React from 'react';
import PropTypes from 'prop-types';

class Text extends React.Component {
  render() {
    const { fontStyle = '', className, size = 15, color = 'inherit', onClick, transform = 'inherit', style = {} } = this.props;

    let classNames = fontStyle;

    if (className) {
      classNames = `${classNames} ${className}`;
    }
    return (
      <p
        className={classNames}
        onClick={onClick}
        style={{
          fontSize: size,
          color,
          textTransform: transform,
          marginTop: '7px',
          marginBottom: '7px',
          ...style,
        }}>
        {this.props.children}
      </p>
    );
  }
}

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  fontStyle: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  transform: PropTypes.string,
};

export default Text;
