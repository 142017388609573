import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import InspectionsServices from '../../../services/InspectionsServices';
import CommentBox from '../molecules/CommentBox';
import DateBox from '../molecules/DateBox';
import SelectBox from '../molecules/SelectBox';
import { getInspection } from '../../../actions/plannedInspectionsActions';
import ModalBody from '../../shared/molecules/ModalBody';

const ModalEditReport = ({
  dispatch,
  inspection,
  inspectionId,
  isOpen,
  error,
  onRequestClose,
  application,
  isLoading,
}) => {
  const [needsRefresh, setNeedsRefresh] = useState(true);
  const [needsPdfRebuild, setNeedsPdfRebuild] = useState(false);
  const [reportSections, setReportSections] = useState([]);

  const getInspectionById = async (noLoading = false) => {
    await getInspection(dispatch, inspectionId, 'modalEditionReport', noLoading);
  };

  useEffect(() => {
    if (Object.keys(inspection).length >= 1 && needsRefresh) {
      setReportSections(inspection?.report?.sections);
      setNeedsRefresh(false);
    }
  }, [inspection]);

  useEffect(() => {
    if (needsRefresh) {
      (async () => getInspectionById())();
    }
  }, []);

  const handleCloseModal = () => {
    // Call to regenerate PDF here
    if (needsPdfRebuild === true) {
      if (application.user.isAdmin === true) {
        InspectionsServices.fillFromWorkerInspection(inspectionId);
      } else {
        InspectionsServices.documentRegen(inspectionId);
      }
    }
    setNeedsRefresh(true);
    setNeedsPdfRebuild(false);
    onRequestClose({});
  };

  const handleNewValueSubmitted = async () => {
    setNeedsRefresh(true);
    setNeedsPdfRebuild(true);
    await getInspectionById(true);
  };

  const ValuesSectionElement = (sectionElem) => {
    const sectionInfo = sectionElem.sectionElem;
    const filterCheckpoints = (cp) => {
      if (cp.refCheckpoint.kind === 'CommentCheckpoint') {
        return true;
      }
      if (cp.refCheckpoint.kind === 'BoolCheckpoint' && _.isBoolean(cp.refCheckpoint.value)) {
        return true;
      }
      if (cp.refCheckpoint.kind === 'EnumCheckpoint' && _.isString(cp.refCheckpoint.value)) {
        return true;
      }
      return !!(['TextCheckpoint', 'DateCheckpoint', 'NumberCheckpoint'].includes(cp.refCheckpoint.kind)
        && (_.get(cp, 'refCheckpoint.value') || _.get(cp, 'refCheckpoint.specialType.key')));
    };
    const filteredCheckpointsArray = sectionInfo.checkpoints.filter(filterCheckpoints);

    return (
      <>
        {filteredCheckpointsArray?.length > 0 && (
          <div
            style={{
              backgroundColor: '#EEEEEE',
              padding: 15,
              marginBottom: 20,
              borderRadius: 12,
            }}
          >
            <div
              style={{
                fontSize: 16,
                marginBottom: 10,
                fontWeight: 800,
                color: '#1f5288',
              }}
            >
              {sectionInfo.sectionName}
            </div>
            {filteredCheckpointsArray.map((cp) => {
              if (['BoolCheckpoint', 'EnumCheckpoint'].includes(cp.refCheckpoint.kind)) {
                return (
                  <div key={`item-cpDiv-${cp.refCheckpoint._id}`}>
                    <div key={`item-clLabel-${cp.refCheckpoint._id}`} className='formTitle' style={{ fontSize: 12, marginBottom: 5, marginTop: 5 }}>
                      {cp.refCheckpoint.label}
                    </div>
                    <SelectBox
                      key={`item-cp-${cp.refCheckpoint._id}`}
                      value={cp.refCheckpoint.value}
                      inspectionId={inspectionId}
                      sectionId={sectionInfo._id}
                      checkpoint={cp.refCheckpoint}
                      reload={() => handleNewValueSubmitted()}
                    />
                  </div>
                );
              }
              if (['CommentCheckpoint', 'TextCheckpoint', 'NumberCheckpoint'].includes(cp.refCheckpoint.kind)) {
                return (
                  <div key={`item-cpDiv-${cp.refCheckpoint._id}`}>
                    <div key={`item-clLabel-${cp.refCheckpoint._id}`} className='formTitle' style={{ fontSize: 12, marginBottom: 5, marginTop: 5 }}>
                      {cp.refCheckpoint.label}
                    </div>
                    <CommentBox
                      key={`item-cp-${cp.refCheckpoint._id}`}
                      value={cp.refCheckpoint.value}
                      inspectionId={inspectionId}
                      sectionId={sectionInfo._id}
                      checkpoint={cp.refCheckpoint}
                      reload={() => handleNewValueSubmitted()}
                    />
                  </div>
                );
              }

              if (cp.refCheckpoint.kind === 'DateCheckpoint') {
                return (
                  <div key={`item-cpDiv-${cp.refCheckpoint._id}`}>
                    <div key={`item-clLabel-${cp.refCheckpoint._id}`} className='formTitle' style={{ fontSize: 12, marginBottom: 5, marginTop: 5 }}>
                      {cp.refCheckpoint.label}
                    </div>
                    <DateBox
                      key={`item-cp-${cp.refCheckpoint._id}`}
                      value={cp.refCheckpoint.value}
                      inspectionId={inspectionId}
                      sectionId={sectionInfo._id}
                      checkpoint={cp.refCheckpoint}
                      reload={handleNewValueSubmitted}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </>
    );
  };

  const ReportSectionsList = (payload) => {
    const { sections } = payload;

    return (
      <div>
        {sections.map((section) => {
          if (section.checkpoints && section.checkpoints.length > 0) {
            return <ValuesSectionElement sectionElem={section} key={`itemReport-${section._id}`} />;
          }
          return null;
        })}
      </div>
    );
  };
  return (
    <ModalBody
      closeModal={handleCloseModal}
      title='Edition du rapport'
      isOpen={isOpen}
      actionSave={null}
      isLoading={isLoading}
      updateAction={false}
      error={error}
      loadingButton={null}
      classToAdd='editReport'>
      <div className='modalEditReport'>
        <Row>
          <Col xs={12}>
            {/* <h2>Edition des points de controle</h2> */}
            {reportSections && reportSections.length > 0 ? <ReportSectionsList sections={reportSections} /> : <p>Aucune valeur</p>}
          </Col>
        </Row>
      </div>
    </ModalBody>
  );
};

ModalEditReport.propTypes = {
  dispatch: PropTypes.func,
  inspectionId: PropTypes.string,
  inspection: PropTypes.object,
  onRequestClose: PropTypes.func,
  getInspection: PropTypes.func,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  application: PropTypes.object,
  error: PropTypes.string,
};

const mapStateToProps = (state) => ({
  application: state.application,
  inspection: state.plannedInspections.inspection,
  isLoading: state.plannedInspections.isLoading,
  error: state.plannedInspections.error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getInspection,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditReport);
