import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import ConcessionsActions from '../../../../actions/Concessions';
import ConcessionsServices from '../../../../services/ConcessionsServices';
import Text from '../../../shared/atoms/Text';
import locale from '../../locale';
import ConcessionSelect from '../../shared/atoms/ConcessionSelect';
import EntitySelect from '../../shared/atoms/EntitySelect';

Modal.setAppElement('body');

class ModalCreateUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      silentNotify: false,
      canCreateMsaInspection: false,
      notifyConcession: false,
    };
  }

  closeModal = () => {
    const { dispatch, application } = this.props;
    const { role = 'reseller' } = this.state;

    dispatch(ConcessionsActions.getActionToDispatch({ type: 'HIDE_MODAL' }));
    ConcessionsActions.reloadTable({
      index: 'users',
      role,
    })(dispatch, application);
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  onChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  onChangeCheckbox = (e) => {
    const { target } = e;
    const { name, checked } = target;

    this.setState({ [name]: checked });
  };

  onChangeUser = (name, user) => {
    this.setState({ [name]: user });
  };

  onClearInput = (name) => {
    this.setState({ [name]: null });
  };

  renderValidButtonText = () => {
    const { isLoading } = this.props.concessions;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }
    return 'Sauvegarder';
  };

  submit = async (e) => {
    e.preventDefault();

    const {
      role = 'reseller',
      concessionSelected,
      entitySelected,
      concessionsSelected,
      silentNotify,
      email,
      firstname,
      lastname,
      password,
      phone,
      canCreateMsaInspection,
      notifyConcession,
    } = this.state;

    let concessionsIdsByRole = [];

    if (role === 'reseller') {
      concessionsIdsByRole = [concessionSelected._id];
    }
    if (role === 'chief') {
      concessionsIdsByRole = concessionsSelected && _.map(concessionsSelected, (s) => s.value);
    }

    if (role === 'superChief') {
      concessionsIdsByRole = undefined;
    }

    const createdResponse = await ConcessionsServices.createUser({
      role,
      concessionId: concessionSelected && concessionSelected._id,
      concessionIds: concessionsIdsByRole,
      entityId: entitySelected && entitySelected._id,
      silentNotify,
      email,
      firstname,
      lastname,
      password,
      phone,
      canCreateMsaInspection,
      notifyConcession,
    }).catch((err) => this.setState({ error: err ? err.error : 'Une erreur est survenue' }));

    if (createdResponse && createdResponse.status === 200) {
      this.closeModal();
    }
  };

  renderEntity = () => {
    const { role = 'reseller', entitySelected } = this.state;

    if (role === 'superChief' || role === 'chief') {
      return (
        <React.Fragment>
          <div className='formInput withSearch'>
            {!entitySelected ? (
              <EntitySelect onSelect={(c) => this.onChangeUser('entitySelected', c)} />
            ) : (
              <React.Fragment>
                <input type='text' disabled defaultValue={`${entitySelected.name}`} />
                <i className='fa fa-times-circle' onClick={(c) => this.onClearInput('entitySelected', c)} />
                <Text fontStyle='normal' size={10} onClick={(c) => { this.onClearInput('entitySelected', c); this.onClearInput('concessionsSelected', c); }}>
                  Supprimer le champs
                </Text>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  renderConcession = () => {
    const { role, concessionSelected, entitySelected, concessionsSelected } = this.state;

    if (role === 'reseller') {
      return (
        <React.Fragment>
          <div className='formInput withSearch'>
            {!concessionSelected ? (
              <ConcessionSelect onSelect={(c) => this.onChangeUser('concessionSelected', c)} />
            ) : (
              <React.Fragment>
                <input type='text' disabled defaultValue={`${concessionSelected.name}`} />
                <i className='fa fa-times-circle' onClick={(c) => this.onClearInput('concessionSelected', c)} />
                <Text fontStyle='normal' size={10} onClick={(c) => this.onClearInput('concessionSelected', c)}>
                  Supprimer le champs
                </Text>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      );
    }

    if (role === 'chief') {
      return (
        <React.Fragment>
          {entitySelected ? (
            <div className='formInput withSearch'>
              <label>Assigner des sites</label>
              <ConcessionSelect isMulti entityId={entitySelected._id} onSelectMultiple={(c) => this.onChangeUser('concessionsSelected', c)} selectedSites={concessionsSelected} />
            </div>
          ) : (
            <div className='formInput withSearch'>
              <label>Assigner des sites</label>
              <input type='text' name='search' autoComplete='none' disabled />
            </div>
          )}
        </React.Fragment>
      );
    }

    return null;
  };

  render() {
    const { concessions } = this.props;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Modal annuler inspection'
        overlayClassName='modal-overlay'
        className='modal modalConcession'
      >
        <div className='modalContent '>
          <div className='mainTitle'>Création d'un utilisateur</div>
          <form className='mt10px'>
            <div className='formInput'>
              <label>Nom</label>
              <input data-cy='lastName' type='text' name='lastname' placeholder='Nom' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Prénom(s)</label>
              <input data-cy='firstName' type='text' name='firstname' placeholder='Prénom(s)' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Téléphone</label>
              <input data-cy='phoneNumber' type='text' name='phone' placeholder='Ex : 06125637623' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Email</label>
              <input data-cy='email' type='email' name='email' placeholder='Ex: john@doe.com' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Nouveau mot de passe</label>
              <input data-cy='password' type='password' name='password' placeholder='**********' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>{locale.NOTIFY_USER_MAIL}</label>
              <input data-cy='silentNotify' type='checkbox' name='silentNotify' onChange={this.onChangeCheckbox} />
            </div>
            {(!this.state.role || this.state.role === 'reseller') && (
              <div className='formInput'>
                <label>{locale.ACCESS_NEW_INSPECTION_MSA}</label>
                <input data-cy='canCreateMsaInspection' type='checkbox' name='canCreateMsaInspection' onChange={this.onChangeCheckbox} />
              </div>
            )}
            {(!this.state.role || this.state.role === 'reseller') && (
              <div className='formInput'>
                <label>{locale.GET_NOTIFIED_NEW_INSPECTION}</label>
                <input data-cy='notifyConcession' type='checkbox' name='notifyConcession' onChange={this.onChangeCheckbox} />
              </div>
            )}
            <div className='formInput'>
              <label>Type de compte</label>
              <select data-cy='selectRole' name='role' onChange={this.onChange}>
                <option value>Selectionnez un type</option>
                <option value='reseller'>Utilisateur</option>
                <option value='chief'>Responsable</option>
                <option value='superChief'>Admin de groupe</option>
              </select>
            </div>
            {this.renderEntity()}
            {this.renderConcession()}
            {this.renderError()}
            <div className='center formButton'>
              <button type='button' onClick={this.closeModal} className='negativ'>
                Annuler
              </button>
              <button type='button' onClick={this.submit} className='positiv' disabled={concessions.isLoading}>
                {this.renderValidButtonText()}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

ModalCreateUser.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
};

export default ModalCreateUser;
