import constants from '../constants';
import utils from '../utils';

const getMissions = async (since) => {
  let queryString = '';
  if (Number.isInteger(since)) {
    queryString = utils.queryStringV2({ since });
  }

  const response = await fetch(constants.URI.v3.missions.concat(queryString), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const getConcessionsMissions = async (since) => {
  let queryString = '';
  if (Number.isInteger(since)) {
    queryString = utils.queryStringV2({ since });
  }

  const response = await fetch(constants.URI.v3.concessionsMissions.concat(queryString), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const getMission = async (missionId) => {
  const response = await fetch(constants.URI.v3.missions.concat(`/${missionId}`), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const createMission = async (data) => {
  const response = await fetch(constants.URI.v3.missions, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const updateMission = async (data, missionId) => {
  const response = await fetch(constants.URI.v3.missions.concat(`/${missionId}`), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const addNewComments = async (data) => {
  const response = await fetch(constants.URI.v3.missions.concat(`/addNewComments`), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const assignSpecialist = async (data) => {
  const response = await fetch(constants.URI.v3.missions.concat(`/assignMission`), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const unassignSpecialist = async (data) => {
  const response = await fetch(constants.URI.v3.missions.concat(`/unassignMission`), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const changeStatus = async (data) => {
  const response = await fetch(constants.URI.v3.missions.concat(`/changeStatus`), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return { status: response.status, body: await response.json() };
};

const changeToOrderedStatusAndSendNotification = async (data, missionId) => {
  const response = await fetch(constants.URI.v3.missions.concat(`/${missionId}/changeToOrderedStatusAndSendNotification`), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const sendMissionsNotificationsToStaffers = async (missionsIds) => {
  const response = await fetch(`${constants.URI.v3.sendMissionsNotificationsToStaffers}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(missionsIds),
  });
  return { status: response.status, body: await response.json() };
};

export default {
  createMission,
  getMissions,
  getConcessionsMissions,
  getMission,
  updateMission,
  assignSpecialist,
  unassignSpecialist,
  changeStatus,
  addNewComments,
  changeToOrderedStatusAndSendNotification,
  sendMissionsNotificationsToStaffers,
};
