import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Text from '../../shared/atoms/Text';

const ViewInspectionCarInfos = ({ data: { brand, model, year } }) => (
  <Row>
    <Col xs={10} md={4}>
      <label>Marque</label>
      <Text fontStyle='normal' size={14}>
        {brand}
      </Text>
    </Col>
    <Col xs={10} md={4}>
      <label>Modèle</label>
      <Text fontStyle='normal' size={14}>
        {model}
      </Text>
    </Col>
    <Col xs={10} md={4}>
      <label>Année</label>
      <Text fontStyle='normal' size={14}>
        {year}
      </Text>
    </Col>
  </Row>
);

ViewInspectionCarInfos.propTypes = {
  data: PropTypes.shape({
    brand: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.number,
  }),
};

export default ViewInspectionCarInfos;
