import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import InspectionsServices from '../../../services/InspectionsServices';

const ModalNoShowInspection = (props) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const resetForm = () => {
    setLoading(false);
    setComment('');
    setErrorMessage('');
  };

  const markNoShowInspection = () => {
    setErrorMessage('');
    setLoading(true);
    InspectionsServices.markNoShowInspection({
      comment,
      inspectionsIds: props.inspections.map((i) => i._id),
    })
      .then((results) => {
        setLoading(false);
        if (results.status === 200) {
          setComment('');
          props.updatePlannedInspections(props.dispatch, props.lastUpdate);
          props.onRequestClose({ didUpdate: true });
        } else {
          throw new Error(_.get(results, 'body.error') || "Une erreur s'est produite");
        }
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(e.message);
      });
  };

  useEffect(() => {
    resetForm();
  }, [_.get(props, 'inspection._id')]);

  const text =
    props.inspections.length === 1 ? (
      <p className='content'>
        Vous êtes sur le point de marquer comme non présentée l'inspection concernant le véhicule{' '}
        <strong>
          {_.get(props, 'inspections.0.vehicle.brand')} {_.get(props, 'inspections.0.vehicle.model')} {_.get(props, 'inspections.0.vehicle.year')}
        </strong>{' '}
        à <strong>{_.get(props, 'inspections.0.appointment.address')}</strong>.
      </p>
    ) : (
      <p className='content'>
        Vous êtes sur le point marquer comme non présentées <strong>{props.inspections.length}</strong> inspections.
      </p>
    );

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={props.isOpen}
      onRequestClose={() => props.onRequestClose({})}
      contentLabel='Modal inspection non presentee'
      overlayClassName='modal-overlay'
      className='modal modalInspectionBan'
    >
      <div className='modalContent'>
        <div className='center inspectionModalBan'>
          <img className='' src='https://res.cloudinary.com/monspecialisteauto-com/image/upload/v1521061595/car-parts.png' />
        </div>
        <div className='mainTitle mt10px'>Marquer cette inspection comme non présentée </div>
        {text}
        <div className='formInput'>
          <label>Commentaire</label>
          <textarea rows='2' value={comment} onChange={(e) => setComment(e.target.value)} />
        </div>
        <p className='content'>Ceci entrainera la facturation du client même si l'inspection n'a pas eu lieu. Souhaitez-vous continuer ?</p>
        {errorMessage && (
          <div className='error'>
            <p>{errorMessage}</p>
          </div>
        )}
        <div className='center formButton mt20px'>
          <button type='button' onClick={() => props.onRequestClose({})} className='negativ'>
            Non
          </button>
          <button type='button' onClick={markNoShowInspection} className='positiv' disabled={loading}>
            {loading ? <i className='fa fa-spinner fa-pulse'></i> : 'Oui'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalNoShowInspection.propTypes = {
  isOpen: PropTypes.bool,
  inspections: PropTypes.array,
  onRequestClose: PropTypes.func,
  updatePlannedInspections: PropTypes.func,
  lastUpdate: PropTypes.number,
  dispatch: PropTypes.func,
};
const mapStateToProps = (state) => ({
  application: state.application,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updatePlannedInspections,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalNoShowInspection);
