import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCountries } from '../../../actions/CountriesActions';
import NavigationActions from '../../../actions/NavigationActions';
import { adminConcessionsOnDemandInspectionsOptions as createAdminOptions } from '../../inspections/atoms/gridOptions';
import ModalViewInspection from '../../inspections/organisms/ModalViewInspection';
import InspectionsTable from '../../inspections/templates/InspectionsTable';
import Filters from '../../shared/atoms/Filters';
import ModalCreateEditViewSite from '../../shared/organisms/ModalCreateEditViewSite';
import ModalCreateEditViewGroup from '../../shared/organisms/ModalCreateEditViewGroup';
import NavBar from '../../shared/templates/NavBar';
import Table from '../shared/templates/Table';
import ModalResetPasswordUser from '../user/modals/resetPwdUser';
import { createConcessionOptions, createEntitiesOptions, createUsersOptions } from './atoms/gridOptions';
import ModalCreateUser from './modals/createUser';
import ModalEditUser from './modals/editConcessionUser';
import ModalViewUser from './modals/viewUser';

const AdminConcessions = ({ dispatch, application, concessions }) => {
  const [option, setOption] = useState('users');

  useEffect(() => {
    dispatch(NavigationActions.setNavigation({ navigation: 'concessions' }));
    dispatch(getCountries);
  }, []);

  const changeOption = (optionSelected) => {
    setOption(optionSelected);
  };

  const renderModals = () => {
    const myProps = {
      dispatch,
      application,
      concessions,
    };
    const { modalName } = concessions;

    switch (modalName) {
      case 'createConcessionUser':
        return <ModalCreateUser {...myProps} />;
      case 'viewConcessionUser':
        return <ModalViewUser {...myProps} />;
      case 'editConcessionUser':
        return <ModalEditUser {...myProps} />;
      case 'editConcession':
        return <ModalCreateEditViewSite {...myProps} />;
      case 'editEntity':
        return <ModalCreateEditViewGroup {...myProps} />;
      case 'createConcession':
        return <ModalCreateEditViewSite {...myProps} />;
      case 'createEntity':
        return <ModalCreateEditViewGroup {...myProps} />;
      case 'viewConcession':
        return <ModalCreateEditViewSite {...myProps} />;
      case 'viewEntity':
        return <ModalCreateEditViewGroup {...myProps} />;
      case 'viewInspection':
        return <ModalViewInspection {...myProps} />;
      case 'resetPwdUser':
        return <ModalResetPasswordUser {...myProps} />;
      default:
        return null;
    }
  };

  return (
    <>
      <NavBar />
      {renderModals()}
      <div className='concession mtp'>
        <Filters selectOptions={changeOption} title='Configuration des sites' options={application.optionsFilters.admin} activeMenu={option} />
        {option === 'users' && <Table table={'users'} options={createUsersOptions()} />}
        {option === 'concessions' && <Table table={'concessions'} options={createConcessionOptions()} />}
        {option === 'entities' && <Table table={'entities'} options={createEntitiesOptions()} />}
        {option === 'inspections' && <InspectionsTable onDemand options={createAdminOptions()} />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  application: state.application,
  concessions: state.concessions,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getCountries,
});

AdminConcessions.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
  admin: PropTypes.object,
  getCountries: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminConcessions);
