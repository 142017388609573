import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import InspectionsServices from '../../../services/InspectionsServices';

const ModalCancelInspection = (props) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const resetForm = () => {
    setLoading(false);
    setReason('');
    setComment('');
    setErrorMessage('');
  };

  function checkRequiredFields() {
    if (comment !== '' && reason !== '') return false;
    setErrorMessage("Assurez-vous d'avoir saisi un commentaire et sélectionné le motif d'annulation ");
    setLoading(false);
    return true;
  }

  const cancelInspection = () => {
    setErrorMessage('');
    setLoading(true);
    if (checkRequiredFields()) return;
    InspectionsServices.cancelPlannedInspections([
      {
        reason,
        comment,
        inspectionsIds: props.inspections.map((i) => i._id),
      },
    ])
      .then((results) => {
        setLoading(false);
        if (results.status === 200) {
          setReason('');
          setComment('');
          props.updatePlannedInspections(props.dispatch, props.lastUpdate);
          props.onRequestClose({ didUpdate: true });
        } else {
          throw new Error(_.get(results, 'body.error') || "Une erreur s'est produite");
        }
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(e.message);
      });
  };

  useEffect(() => {
    resetForm();
  }, [props.inspections]);

  const text = props.inspections.length === 1 ? (
    <p className='content'>
      Vous êtes sur le point d'annuler l'inspection concernant le véhicule{' '}
      <strong>
        {_.get(props, 'inspections.0.vehicle.brand')} {_.get(props, 'inspections.0.vehicle.model')} {_.get(props, 'inspections.0.vehicle.year')}
      </strong>{' '}
      à <strong>{_.get(props, 'inspections.0.appointment.address')}</strong>.
    </p>
  ) : (
    <p className='content'>
      Vous êtes sur le point d'annuler <strong>{props.inspections.length}</strong> inspections.
    </p>
  );

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={props.isOpen}
      onRequestClose={() => props.onRequestClose({})}
      contentLabel='Modal annuler inspection'
      overlayClassName='modal-overlay'
      className='modal modalInspectionBan'
    >
      <div className='modalContent'>
        <div className='center inspectionModalBan'>
          <img className='' src='https://res.cloudinary.com/monspecialisteauto-com/image/upload/v1521061595/car-parts.png' alt='car-parts' />
        </div>
        <div className='mainTitle mt10px'>Annulation d'inspection</div>
        {text}
        <div className='formInput'>
          <label>Motif de l'annulation</label>
          <textarea rows='2' value={comment} placeholder="Motif de l'annulation " onChange={(e) => setComment(e.target.value)} />
        </div>
        <div className='formInput'>
          <label>Précision du motif d'annulation</label>
          <div>
            <input type='radio' name='annulation_submotif' value='MSA_CANCEL' checked={reason === 'MSA_CANCEL'} onChange={() => setReason('MSA_CANCEL')} />{' '}
            <span style={{ fontSize: '12px', marginRight: '15px' }}>Annulation MSA</span>
          </div>
          <div>
            <input type='radio' name='annulation_submotif' value='CLIENT_CANCEL' checked={reason === 'CLIENT_CANCEL'} onChange={() => setReason('CLIENT_CANCEL')} />{' '}
            <span style={{ fontSize: '12px', marginRight: '15px' }}>Annulation Client</span>
          </div>
          <div>
            <input type='radio' name='annulation_submotif' value='SELLER_CANCEL' checked={reason === 'SELLER_CANCEL'} onChange={() => setReason('SELLER_CANCEL')} />{' '}
            <span style={{ fontSize: '12px', marginRight: '15px' }}>Annulation Utilisateur</span>
          </div>
        </div>
        <p className='content'>Souhaitez-vous continuer ?</p>
        {errorMessage && (
          <div className='error'>
            <p>{errorMessage}</p>
          </div>
        )}
        <div className='center formButton mt20px'>
          <button type='button' onClick={() => props.onRequestClose({})} className='negativ'>
            Non
          </button>
          <button type='button' onClick={cancelInspection} className='positiv' disabled={loading}>
            {loading ? <i className='fa fa-spinner fa-pulse' /> : 'Oui, annuler'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalCancelInspection.propTypes = {
  isOpen: PropTypes.bool,
  inspections: PropTypes.array,
  onRequestClose: PropTypes.func,
  updatePlannedInspections: PropTypes.func,
  lastUpdate: PropTypes.number,
  dispatch: PropTypes.func,
};
const mapStateToProps = (state) => ({
  application: state.application,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updatePlannedInspections,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalCancelInspection);
