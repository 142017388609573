/* eslint-disable no-unused-vars, class-methods-use-this */

import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'react-flexbox-grid';

class Card extends React.Component {
  render() {
    const { xs = 12, sm = 12, md = 12, lg = 12 } = this.props;

    return (
      <Col xs={xs} sm={sm} md={md} lg={lg} className='card'>
        {this.props.children}
      </Col>
    );
  }
}

Card.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
};

export default Card;
