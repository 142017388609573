import constants from '../constants';
import makeRequest from '../helpers/request';
import utils from '../utils';

// ----------------------
// fonction de connexion
// ----------------------
const login = async ({ email, password }) => {
  const userData = { email, password };
  const response = await fetch(constants.URI.login, {
    credentials: 'include', // with CORS this is essential to use the set-cookie header response
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(userData),
  });
  return { status: response.status, body: await response.json() };
};

const logout = async () => {
  const response = await fetch(constants.URI.logout, {
    credentials: 'include', // with CORS this is essential to use the set-cookie header response
    method: 'POST',
  });
  return { status: response.status };
};

const getUser = async () => {
  const response = await fetch(constants.URI.getUser, {
    method: 'GET',
    credentials: 'include',
  });
  return { status: response.status, body: await response.json() };
};

const getAllSpecialistPropositions = async (specialistId) => {
  const response = await fetch(constants.URI.v3.specialistProposition.concat(`/${specialistId}`), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const getUserDetails = async ({ id }) => {
  const response = await fetch(constants.URI.getUser.concat(`/${id}`), {
    method: 'GET',
    credentials: 'include',
  });
  return { status: response.status, body: await response.json() };
};

const getUsers = async (params, uploadProgress) => {
  const mparams = utils.paramsToArrayQs(params);
  const queryString = utils.queryString(mparams);
  const result = await makeRequest(
    {
      credentials: 'include',
      url: constants.URI.getUser.concat(queryString),
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
    uploadProgress,
  );
  return { status: result.status, body: result && result.body };
};

const getSpecialist = async (userId) => {
  const response = await makeRequest({
    url: `${constants.URI.v3.specialists.index}/${userId}`,
    method: 'GET',
    credentials: 'include',
  });
  return { status: response.status, body: response && response.body };
};

const getSpecialists = async () => {
  const response = await makeRequest({
    url: constants.URI.v3.specialists.index,
    method: 'GET',
    credentials: 'include',
  });
  return { status: response.status, body: response && response.body };
};

const updateSpecialist = async (params, userId) => {
  const response = await makeRequest({
    url: `${constants.URI.v3.specialists.index}/${userId}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return response;
};

const verifyEmail = async ({ token }) => {
  const response = await fetch(constants.URI.verifyEmail.concat(token || ''), {
    method: 'GET',
  });
  return { status: response.status, body: await response.json() };
};

const updateUser = async (params) => {
  const response = await makeRequest({
    url: constants.URI.editUser,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(params),
  });
  return response;
};

const createUser = async (params) => {
  const response = await fetch(constants.URI.editUser, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  return { status: response.status, body: await response.json() };
};

const searchUsers = async (params) => {
  const mparams = utils.paramsToArrayQs(params);
  const queryString = utils.queryString(mparams);
  const response = await fetch(constants.URI.searchUsers.concat(queryString), {
    method: 'GET',
    credentials: 'include',
  });
  return { status: response.status, body: await response.json() };
};

const resetPassword = async ({ email, createNew }) => {
  const queryString = utils.queryString([
    { key: 'email', value: email },
    { key: 'createNew', value: createNew },
  ]);

  const response = await fetch(constants.URI.forgotPassword.concat(queryString), {
    method: 'GET',
  });
  return { status: response.status, body: await response.json() };
};

const validPasswordToken = async ({ token }) => {
  const response = await fetch(constants.URI.checkpasswordtoken.concat(token), {
    method: 'GET',
  });
  return { status: response.status, body: await response.json() };
};

const setNewPassword = async ({ token, email, password }) => {
  const queryString = utils.queryString([
    { key: 'email', value: email },
    { key: 'password', value: password },
  ]);
  const response = await fetch(constants.URI.editpassword.concat(token).concat(queryString), {
    method: 'GET',
    credentials: 'include',
  });
  return { status: response.status, body: await response.json() };
};

const getCase = (value) => {
  if (!value) {
    return null;
  }
  if (value.isActive) {
    if (value.accepted) {
      // specialiste actif et accepte (1 inspection)
      return 'accepted';
    }
    if (value.confirmed) {
      // specialiste actif et confirme (0 inspection)
      return 'confirmed';
    }
    if (value.notAccepted) {
      return 'notAccepted';
    }
    return null;
  }
  if (value.confirmed) {
    return 'confirmed';
  }
  if (value.notAccepted) {
    // specialiste refusé ou renvoyé
    return 'notAccepted';
  }
  if (value.inActive) {
    return 'inActive';
  }
  if (value.unConfirmed) {
    return 'unConfirmed';
  }
  return null;
};

const UsersServices = {
  login,
  logout,
  getUser,
  getUsers,
  getUserDetails,
  getAllSpecialistPropositions,
  getSpecialists,
  getSpecialist,
  updateSpecialist,
  updateUser,
  createUser,
  searchUsers,
  resetPassword,
  validPasswordToken,
  setNewPassword,
  verifyEmail,
  getCase,
};

export default UsersServices;
