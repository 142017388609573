import c from '../constants';
import utils from '../utils';

// ///////////////////
// Handle Response from any API (successful or not)
// ///////////////////
async function handleResponse(response) {
  const responseBody = await response
    .clone()
    .json()
    .catch(() => response.text());
  if (!response.ok) {
    const resNOK = await response;
    // logout in case user has an invalid token
    if (resNOK.status === 401) {
      // Delete local storage and send user back to login page
      window.location.assign('/');
    }
    // return rejection for Redux Thunk
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ ...responseBody, status: response.status });
  }
  return Promise.resolve(responseBody);
}

// ///////////////////
// GET from the Backend
// ///////////////////

function getAll(url, query) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };
  // It is possible to send queries of types objects or string
  let queryString = '';
  if (typeof query === 'string') {
    queryString = query ? `?${query}` : '';
  } else if (typeof query === 'object') {
    queryString = query ? utils.queryStringV2(query) : '';
  }
  return fetch(url.concat(queryString), requestOptions).then(handleResponse);
}

const getAllPromise = (url, query) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    // It is possible to send queries of types objects or string
    let queryString = '';
    if (typeof query === 'string') {
      queryString = query ? `?${query}` : '';
    } else if (typeof query === 'object') {
      queryString = query ? utils.queryStringV2(query) : '';
    }
    // eslint-disable-next-line no-promise-executor-return
    return fetch(url.concat(queryString), requestOptions)
      .then((resp) => resolve(resp.json()))
      .catch((e) => reject(e.error));
  });

function getPlanned() {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetch(c.URI.v3.plannedReports, requestOptions).then(handleResponse);
}

// ///////////////////
// GET one specific item from the Backend
// ///////////////////
function getOne(url) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

// ///////////////////
// POST to the Backend
// ///////////////////
function post(url, payload) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// ///////////////////
// PUT to the Backend
// ///////////////////
function put(url, payload) {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

// ///////////////////
// DELETE from the Backend
// ///////////////////
function deleteOne(url) {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

// ///////////////////
// UPLOAD to the Backend
// ///////////////////
function upload(url, payload) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    body: payload,
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export const ReportsService = {
  post,
  put,
  getAll,
  getAllPromise,
  deleteOne,
  getOne,
  upload,
  getPlanned,
};

export default ReportsService;
