import PropTypes from 'prop-types';
import React from 'react';
import Text from './Text';

const findTypeDatabox = (type) => {
  switch (type) {
    case 'percentage':
      return '%';
    case 'euro':
      return '€';
    default:
      return '';
  }
};

const DataboxUrl = (props) => (
  <a href={props.url} target={props.target || ''} style={{ textDecoration: 'none' }}>
      <div className={`databox${props.className || ''}`} onClick={props.onClick || (() => {})}>
    <div className='value'>
      {props.text && (
        <Text size={20} className='noMargin' color={props.color} fontStyle='normal'>
          {props.text}
        </Text>
      )}
      {props.value && (
        <Text size={45} className='noMargin' color={props.color} fontStyle='normal'>
          {props.value || '0'}
        </Text>
      )}
      <Text size={25} className='noMargin' color={props.color} fontStyle='normal'>
        {findTypeDatabox(props.type)}
      </Text>
    </div>
    <Text className='noMargin' fontStyle='normal' color='#9b9b9b'>
      {props.title}
    </Text>
  </div>
  </a>
);

DataboxUrl.propTypes = {
  url: PropTypes.string,
  target: PropTypes.string,
  value: PropTypes.number,
  type: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default DataboxUrl;
