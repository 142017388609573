import React from 'react';
import PropTypes from 'prop-types';
import { defaults, mergeOptions } from '../defaults';

/* Export notification container */
export default class Container extends React.Component {
  static propTypes = {
    options: PropTypes.object,
  };

  static defaultProps = {
    options: {},
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    mergeOptions(this.props.options);
  }

  render() {
    return <div id={defaults.wrapperId} />;
  }
}
