import _ from 'lodash';
import { UPDATE_INSPECTIONS, UPDATE_INSPECTIONS_FAIL } from '../actions/onDemandInspectionsActions';

const initialState = {
  inspections: [],
  oldInspections: [],
  lastUpdatedAt: 0,
  updateFailError: '',
};

const onDemandInspectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INSPECTIONS: {
      if (action.payload.length === 0) {
        return {
          ...state,
          oldInspections: state.inspections,
          inspections: state.inspections,
        };
      }

      const oldInspectionsLoaded = action.resetPreviousInspections ? [] : state.inspections;
      const notModified = _.differenceBy(oldInspectionsLoaded, action.payload, (i) => i._id);
      const allInspections = [...notModified, ...action.payload];
      const lastUpdatedDateFromBd = Math.round(new Date(_.maxBy(allInspections, 'updatedAt').updatedAt) / 1000);
      const lastUpdatedDate = _.min([Math.round(Date.now() / 1000), lastUpdatedDateFromBd + 1]);

      return {
        ...state,
        oldInspections: state.inspections,
        inspections: allInspections,
        lastUpdatedAt: lastUpdatedDate,
      };
    }
    case UPDATE_INSPECTIONS_FAIL: {
      return {
        ...state,
        updateFailError: action.payload,
      };
    }
    default:
      return state;
  }
};

export default onDemandInspectionsReducer;
