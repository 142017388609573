import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import InspectionsServices from '../../../services/InspectionsServices';

const ModalUnassignInspections = (props) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const resetForm = () => {
    setLoading(false);
    setComment('');
    setErrorMessage('');
  };

  const unAssignInspection = () => {
    if (comment === '') {
      setErrorMessage('Veuillez entrer un commentaire');
    } else {
      setErrorMessage('');
      setLoading(true);
      InspectionsServices.updateInspections(
        props.inspections.map((i) => ({
          _id: i._id,
          unassignedInfos: {
            comment,
          },
          appointment: {
            date: null,
          },
          specialist: null,
        })),
      )
        .then((results) => {
          setLoading(false);
          if (results.status === 200) {
            setComment('');
            props.updatePlannedInspections(props.dispatch, props.lastUpdate);
            props.onRequestClose({ didUpdate: true });
          } else {
            throw new Error(_.get(results, 'body.error') || "Une erreur s'est produite");
          }
        })
        .catch((e) => {
          setLoading(false);
          setErrorMessage(e.message);
        });
    }
  };

  useEffect(() => {
    resetForm();
  }, [props.inspections]);

  const text = props.inspections.length === 1 ? (
      <p className='content'>
        Vous êtes sur le point de remettre en attente l'inspection concernant le véhicule{' '}
        <strong>
          {_.get(props, 'inspections.0.vehicle.brand')} {_.get(props, 'inspections.0.vehicle.model')} {_.get(props, 'inspections.0.vehicle.year')}
        </strong>{' '}
        à <strong>{_.get(props, 'inspections.0.appointment.address')}</strong>.
      </p>
  ) : (
      <p className='content'>
        Vous êtes sur le point de remettre en attente <strong>{props.inspections.length}</strong> inspections.
      </p>
  );

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={props.isOpen}
      onRequestClose={() => props.onRequestClose({})}
      contentLabel='Modal multiple assign'
      overlayClassName='modal-overlay'
      className='modal'
    >
      <div className='modalContent'>
        <div className='mainTitle mt10px'>Remise en attente d'inspections</div>
        {text}
        <div className='formInput'>
          <label>Commentaire</label>
          <textarea rows='2' value={comment} onChange={(e) => setComment(e.target.value)} />
        </div>
        <p className='content'>Souhaitez-vous continuer ?</p>
        {errorMessage && (
          <div className='error'>
            <p>{errorMessage}</p>
          </div>
        )}
        <div className='center formButton mt20px'>
          <button type='button' onClick={() => props.onRequestClose({})} className='negativ'>
            Non
          </button>
          <button type='button' onClick={unAssignInspection} className='positiv' disabled={loading}>
            {loading ? <i className='fa fa-spinner fa-pulse' /> : 'Oui, remettre en attente'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalUnassignInspections.propTypes = {
  isOpen: PropTypes.bool,
  inspections: PropTypes.array,
  onRequestClose: PropTypes.func,
  updatePlannedInspections: PropTypes.func,
  lastUpdate: PropTypes.number,
  dispatch: PropTypes.func,
};
const mapStateToProps = (state) => ({
  application: state.application,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updatePlannedInspections,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUnassignInspections);
