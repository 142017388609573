/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class HomePage extends React.Component {
  state = {
    redirect: null,
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return null;
  };

  render() {
    return <div>{/* {this.renderRedirect()} */}</div>;
  }
}

const mapStateToProps = (state) => ({ application: state.application, inspections: state.inspections });

HomePage.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
};

export default connect(mapStateToProps)(HomePage);
