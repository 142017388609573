import React from 'react';
import PropTypes from 'prop-types';

const SpecialistMenu = (props) => (
  <span>
    <i onClick={() => props.onClickDetailsPanel(props.value)}>
      <i className='fas fa-eye' style={{ paddingRight: 8, cursor: 'pointer' }} />
    </i>
  </span>
);
SpecialistMenu.propTypes = {
  onClickDetailsPanel: PropTypes.func,
  value: PropTypes.object,
};

export default SpecialistMenu;
