import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Col } from 'react-flexbox-grid';

import constants from '../../../../constants';
import ReportsServices from '../../../../services/ReportsService';
import DefectTypologiesTree from '../organisms/DefectTypologiesTree';
import DefectTypologiesSelect from '../organisms/DefectTypologiesSelect';

const DefectTypologiesTab = () => {
  const [typologies, setTypologies] = useState([]);
  const [selectedTypologyId, setSelectedTypologyId] = useState();

  useEffect(() => {
    ReportsServices.getAll(constants.URI.v3.sortedTypologies).then((res) => setTypologies(res.typologies || []));
  }, []);

  return (
    <div className='table'>
      <Col xs={12} className='table-search'>
        <div className='search-container' style={{ display: 'inherit' }}>
          <div className='search-item'>
            <label>Liste des typologies de défauts</label>
            <div className='search-input'>
              <DefectTypologiesSelect typologies={typologies} onSelect={(selection) => setSelectedTypologyId(selection)} />
            </div>
          </div>
        </div>
      </Col>
      <DefectTypologiesTree typology={_.find(typologies, (typo) => typo.typologyId === selectedTypologyId)} />
    </div>
  );
};

export default DefectTypologiesTab;
