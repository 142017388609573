import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConcessionsActions from '../../../../actions/Concessions';

const UsersActionsCell = ({ data, dispatch, role, isAdmin }) => {
  const checkRights = () => {
    if (isAdmin) {
      return true;
    }
    if (role === 'superChief' && data.role !== 'superChief') {
      return true;
    }
    if (role === 'chief' && data.role === 'reseller') {
      return true;
    }
    return false;
  };

  if (!data) {
    return <div></div>;
  }

  const showModifs = checkRights();

  return (
    <React.Fragment>
      <div style={{ fontSize: 'x-large', display: 'inline-flex', padding: 5 }}>
        <div
          style={{ marginRight: 15 }}
          title={"Voir l'utilisateur"}
          onClick={() => dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'viewConcessionUser', modalData: data }))}>
          <i className={'fa fa-eye'} />
        </div>
        {showModifs && (
          <div
            data-cy="editUser"
            style={{ marginRight: 15 }}
            title={"Éditer l'utilisateur"}
            onClick={() => dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'editConcessionUser', modalData: data }))}>
            <i className={'fas fa-user-edit'} />
          </div>
        )}
        {showModifs && (
          <div
            title={'Réinitialiser le mot de passe'}
            onClick={() => dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'resetPwdUser', modalData: data }))}>
            <i className={'fa fa-key'} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

UsersActionsCell.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
  role: PropTypes.string,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = (state) => ({ role: state.application.user.role, isAdmin: state.application.user.isAdmin });

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(UsersActionsCell);
