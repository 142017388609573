import _ from 'lodash';
import * as actions from '../actions/BillingsActions';

const initialState = {
  billings: [],
  oldBillings: [],
  billing: [],
  billingConfiguration: {},
  billingHistoryConfiguration: [],
  lastUpdatedAt: 0,
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_BILLINGS:
    case actions.REQUEST_BILLING:
    case actions.REQUEST_BILLING_CONFIGURATION:
    case actions.REQUEST_BILLING_HISTORY_CONFIGURATION: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case actions.REQUEST_UPDATE_BILLING: {
      return {
        ...state,
        isLoadingUpdate: true,
      };
    }
    case actions.REQUEST_UPDATE_STATUS_BILLING: {
      return {
        ...state,
        isLoadingUpdate: true,
      };
    }
    case actions.GET_BILLINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        billings: [...action.billings],
      };
    }
    case actions.GET_BILLING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        billing: [...action.billing],
      };
    }
    case actions.UPDATE_BILLING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoadingUpdate: false,
        error: null,
      };
    }
    case actions.CLEAR_BILLING: {
      return {
        ...state,
        isLoading: false,
        error: null,
        billing: [],
      };
    }
    case actions.UPDATE_STATUS_BILLING_SUCCESS: {
      const oldBillingLoaded = state.billings || [];
      const billingsNotModified = _.differenceBy(oldBillingLoaded, action.billings.billings, (i) => i._id);
      const allBillings = [...billingsNotModified, ...action.billings.billings];
      const lastUpdatedDateFromBd = Math.round(new Date(_.maxBy(allBillings, 'updatedAt').updatedAt) / 1000);
      const lastUpdatedDate = _.min([Math.round(Date.now() / 1000), lastUpdatedDateFromBd + 1]);
      return {
        ...state,
        isLoading: false,
        isLoadingUpdate: false,
        oldBillings: oldBillingLoaded,
        billings: allBillings,
        lastUpdatedAt: lastUpdatedDate,
        error: null,
      };
    }
    case actions.CLEAR_BILLING_CONFIGURATION: {
      return {
        ...state,
        isLoading: false,
        error: null,
        billingConfiguration: {},
        billingHistoryConfiguration: [],
      };
    }
    case actions.GET_BILLING_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        billingConfiguration: action.billingConfiguration,
      };
    }
    case actions.GET_BILLING_HISTORY_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        billingHistoryConfiguration: action.billingHistoryConfiguration,
      };
    }
    case actions.GET_BILLINGS_ERROR:
    case actions.GET_BILLING_ERROR:
    case actions.UPDATE_BILLING_ERROR:
    case actions.UPDATE_STATUS_BILLING_ERROR:
    case actions.GET_BILLING_CONFIGURATION_ERROR:
    case actions.GET_BILLING_HISTORY_CONFIGURATION_ERROR: {
      return {
        ...state,
        isLoading: false,
        isLoadingUpdate: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
