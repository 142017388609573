import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchBox extends Component {
  componentDidMount({ map, mapApi } = this.props) {
    this.searchBox = new mapApi.places.SearchBox(this.searchInput);
    this.searchBox.addListener('places_changed', this.onPlacesChanged);
    this.searchBox.bindTo('bounds', map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  onPlacesChanged = ({ map, addPlace } = this.props) => {
    const selected = this.searchBox.getPlaces();
    const { 0: place } = selected;
    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    addPlace(selected);
    this.searchInput.blur();
  };

  clearSearchBox = () => {
    this.searchInput.value = '';
  };

  render() {
    return (
      <div className='searchBox'>
        <input
          ref={(ref) => {
            this.searchInput = ref;
          }}
          type='text'
          placeholder='Entrer une adresse'
        />
        <button onClick={this.clearSearchBox} className='close-icon' type='reset' />
      </div>
    );
  }
}

SearchBox.propTypes = {
  mapApi: PropTypes.object,
  map: PropTypes.object,
  addPlace: PropTypes.func,
};

export default SearchBox;
