import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { deleteDocumentInfos, getInspection, updateCommentInspection } from '../../../actions/plannedInspectionsActions';
import constants from '../../../constants';
import { uploadInspectionFile } from '../../../helpers/manageUpload';
import formatPhoneNumber from '../../../helpers/phone';
import GooglePlacesDetailsCard from '../../shared/atoms/GooglePlacesDetailsCard';
import CardItemInDrawer from '../../shared/molecules/CardItemInDrawer';
import DrawerWrapperBody from '../../shared/organisms/DrawerWrapperBody';
import FileInput from '../../users/specialists/atoms/FileInput';
import AddNewDocuments from '../molecules/AddNewDocuments';
import Documents from '../molecules/Documents';
import SpecialistProposition from '../molecules/SpecialistProposition';

const InspectionDetailsDrawer = ({
  isOpen,
  closeCard,
  inspectionId,
  dispatch,
  inspection,
  isLoading,
  adminInterface,
  error,
  placement = 'right',
  mask = true,
}) => {
  const [loadingAddComment, setLoadingAddComment] = useState(false);
  const [newCommentText, setNewCommentText] = useState('');
  const [focusTextArea, setFocusTextArea] = useState(false);
  const [typeToUpload, setTypeToUpload] = useState('');
  const [addNewDocument, setAddNewDocument] = useState(false);
  const [errorState, setErrorState] = useState(error);
  const uploadInputRef = useRef();
  const commentsContainerRef = useRef();

  const cancellationTexts = {
    MSA_CANCEL: 'MSA',
    CLIENT_CANCEL: 'Le client',
    SELLER_CANCEL: 'Le vendeur',
  };

  const scrollToBottomOfMessages = () => {
    if (commentsContainerRef && commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight - commentsContainerRef.current.clientHeight;
    }
  };

  const addNewComment = async () => {
    const result = await updateCommentInspection(dispatch, inspectionId, newCommentText);
    if (result.type === 'UPDATE_COMMENT_INSPECTION_SUCCESS') {
      setNewCommentText('');
      await getInspection(dispatch, inspectionId, 'drawer');
    }
  };

  const upload = (type) => {
    setTypeToUpload(type);
    uploadInputRef.current.click();
  };

  const deleteDownloadedFiles = async (fileName) => {
    const resultDeleteDocumentInfos = await deleteDocumentInfos(dispatch, inspectionId, { fileName });
    if (resultDeleteDocumentInfos.type === 'DELETE_DOCUMENT_INFOS_SUCCESS') {
      await getInspection(dispatch, inspectionId, 'drawer');
    }
  };

  const lat = _.get(inspection, 'appointment.lat');
  const lng = _.get(inspection, 'appointment.lng');

  const updateStateComment = (e) => {
    setNewCommentText(e.target.value);
  };

  const onFocusTextArea = () => {
    setFocusTextArea(true);
  };

  const onBlurTextArea = () => {
    setFocusTextArea(false);
  };

  const goToBack = () => {
    setAddNewDocument(false);
    setErrorState('');
  };

  const renderInspectionDocuments = () => {
    if (!addNewDocument) {
      return <Documents inspection={inspection} upload={upload} deleteDownloadedFiles={deleteDownloadedFiles} />;
    }
    return <AddNewDocuments error={error} goToBack={goToBack} errorState={errorState} />;
  };

  // eslint-disable-next-line consistent-return
  const onInputFileChange = async () => {
    if (!uploadInputRef.current.files[0]) {
      return null;
    }
    if (uploadInputRef.current.files[0] && uploadInputRef.current.files[0].size > 5007200) {
      return alert('Le fichier sélectionné est trop volumineux');
    }

    try {
      await uploadInspectionFile({
        type: uploadInputRef.current.files[0].type,
        body: uploadInputRef.current.files[0],
        inspectionId,
        oldFileName: typeToUpload,
      });
      await getInspection(dispatch, inspectionId, 'drawer');
    } catch {
      return alert("Echec de l'upload");
    }
  };

  // Auto refresh
  useEffect(() => {
    const interval = setInterval(() => {
      if (newCommentText || focusTextArea) {
        return clearInterval(interval);
      }
      return (async () => getInspection(dispatch, inspectionId, 'drawer'))();
    }, constants.autoRefresh);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (Object.keys(inspection).length > 0) {
      setLoadingAddComment(false);
      setNewCommentText('');
      scrollToBottomOfMessages();
    }
  }, [inspection]);

  useEffect(() => {
    setErrorState(error);
  }, [error]);

  useEffect(() => {
    (async () => getInspection(dispatch, inspectionId, 'drawer'))();
  }, [inspectionId]);

  return (
    <DrawerWrapperBody
      title={ Object.keys(inspection).length >= 1 && (
        <div className='topContainerMainElement'>
          <div className='inspectionDetailsStatus'>
            <strong>
              N°{inspection.readableId} {inspection.customId ? ` / ${inspection.customId}` : ''}
            </strong>
            {inspection.status === 'done' && <span className='badge done-status'>Réalisée</span>}
            {inspection.status === 'ordered' && <span className='badge ordered-status'>En attente</span>}
            {inspection.status === 'scheduled' && <span className='badge scheduled-status'>Programmée</span>}
            {inspection.status === 'canceled' && <span className='badge canceled-status'>Annulée</span>}
            {_.get(inspection, 'summary.viewerUrl') && (
              <span>
                <a href={_.get(inspection, 'summary.viewerUrl')} title='Voir le rapport' target='_blank' rel='noreferrer noopener'>
                  <i className='fas fa-file-pdf' />
                </a>
              </span>
            )}
          </div>
        </div>
      )}
      isLoading={isLoading}
      isOpen={isOpen}
      closeDrawer={() => closeCard()}
      placement={placement}
      mask={mask}
    >
      <div className='inspectionDetails'>
        <CardItemInDrawer title='Informations complémentaires'>
          <div className='inspectionDetailsContent'>
            <div className='text'>{inspection.infos || ''}</div>
            {inspection.status === 'canceled' && inspection.cancelInfos && (
              <ul className='inspectionDetailsContentInfos'>
                <li className='twoItems'>
                  <div className='smallCaps'>Annulée par</div>
                  <div className='text'>{cancellationTexts[inspection.cancelInfos.reason]}</div>
                </li>
                <li className='twoItems'>
                  <div className='smallCaps'>Raison</div>
                  <div className='text'>{_.get(inspection, 'cancelInfos.comment') || ''}</div>
                </li>
              </ul>
            )}
          </div>
        </CardItemInDrawer>
        <CardItemInDrawer title='Informations client'>
          <div className='inspectionDetailsContent'>
            <ul className='inspectionDetailsContentInfos'>
              {_.get(inspection, 'appointment.timeSlots.propositionTimeSlots') && _.get(inspection, 'appointment.timeSlots.propositionTimeSlots').length && (
                <div>
                  {_.get(inspection, 'appointment.timeSlots.propositionTimeSlots').map((propositionTimeSlot, index) => (
                    <li className='threeItems' key={index}>
                      <div className='smallCaps'>DISPO {index + 1}</div>
                      <div data-cy='availabilityDate' className='text'>
                        {moment(propositionTimeSlot.start).format('DD MMM YYYY')}
                      </div>
                      <div data-cy='appointmentHour' className='text'>
                        {moment(propositionTimeSlot.start).format(constants.formatHourAppointment)} à {moment(propositionTimeSlot.end).format(constants.formatHourAppointment)}
                      </div>
                    </li>
                  ))}
                </div>
              )}
            </ul>
            <ul className='inspectionDetailsContentInfos'>
              <li className='twoItems'>
                <div className='smallCaps'>Vendeur</div>
                <div data-cy='sellerName' className='text'>
                  {_.get(inspection, 'seller.name') || <span>&nbsp;</span>}
                </div>
              </li>
              <li className='twoItems'>
                <div className='smallCaps'>Téléphone</div>
                <div data-cy='sellerPhone' className='text'>
                  {formatPhoneNumber(_.get(inspection, 'seller.phone')) || <span>&nbsp;</span>}
                </div>
              </li>
            </ul>
            <div className='smallCaps'>Adresse de l'inspection</div>
            <div data-cy='appointmentAddress' className='text'>
              {lat && lng && (
                <a rel='noreferrer' target='_blank' href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`}>
                  {_.get(inspection, 'appointment.address')}
                </a>
              )}
            </div>
            <ul className='inspectionDetailsContentInfos'>
              <li className='twoItems'>
                <div className='smallCaps'>Spécialiste</div>
                <div data-cy='assignedSpecialist' className='text'>
                  {inspection.specialist ? `${inspection.specialist.firstname} ${inspection.specialist.lastname}` : <span className='badge error'>Non assigné</span>}
                </div>
              </li>
              <li className='twoItems'>
                <div className='smallCaps'>RDV</div>
                <div data-cy='appointmentDate' className='text'>
                  {_.get(inspection, 'appointment.date') ? (
                    moment(inspection.appointment.date).format(constants.formatDateTimeAppointment)
                  ) : (
                    <span className='badge error'>Non assigné</span>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </CardItemInDrawer>
        {!!_.get(inspection, 'appointment.specialistPropositions.length') && (
          <CardItemInDrawer title='Disponibilités spécialistes'>
            <SpecialistProposition listPropositions={inspection.appointment.specialistPropositions} />
          </CardItemInDrawer>
        )}
        <CardItemInDrawer title='Compétences requises'>
          <div className='body'>
            <div className='specialistFiche'>
              <div className='specialistFichePart'>
                <Select
                  disabled={true}
                  mode='multiple'
                  size='small'
                  style={{ width: '100%', paddingBottom: '10px' }}
                  placeholder='Aucune'
                  value={inspection?.reportSkills?.skills}
                  optionFilterProp='children'
                  data-cy='reportSkills'
                />
              </div>
            </div>
          </div>
        </CardItemInDrawer>
        {inspection?.appointment?.locationMetadata?.prioritizedInfos?.businessStatus ? (
          <CardItemInDrawer title='Informations Google'>
            <GooglePlacesDetailsCard inspectionDrawer={inspection} />
          </CardItemInDrawer>
        ) : null}
        <CardItemInDrawer title='Véhicule'>
          <div className='inspectionDetailsContent'>
            <ul className='inspectionDetailsContentInfos'>
              <li>
                <div className='smallCaps'>Marque</div>
                <div data-cy='vehicleBrand' className='text'>
                  {_.get(inspection, 'vehicle.brand') || <span>&nbsp;</span>}
                </div>
              </li>
              <li>
                <div className='smallCaps'>Modèle</div>
                <div data-cy='vehicleModel' className='text'>
                  {_.get(inspection, 'vehicle.model') || <span>&nbsp;</span>}
                </div>
              </li>
              <li>
                <div className='smallCaps'>Année</div>
                <div data-cy='vehicleYear' className='text'>
                  {_.get(inspection, 'vehicle.year') || <span>&nbsp;</span>}
                </div>
              </li>
              <li>
                <div className='smallCaps'>Immat</div>
                <div data-cy='vehicleImmat' className='text'>
                  {_.get(inspection, 'vehicle.immat.displayFormat') || <span>&nbsp;</span>}
                </div>
              </li>
              <li>
                <div className='smallCaps'>Groupe</div>
                <div data-cy='entityName' className='text'>
                  {_.get(inspection, 'entity.name') || <span>&nbsp;</span>}
                </div>
              </li>
              <li>
                <div className='smallCaps'>Site</div>
                <div data-cy='concessionName' className='text'>
                  {_.get(inspection, 'concession.name') || <span>&nbsp;</span>}
                </div>
              </li>
            </ul>
          </div>
        </CardItemInDrawer>
        <CardItemInDrawer title='Documents' showButton={!addNewDocument} addNewDocument={() => setAddNewDocument(true)}>
          <div className='specialistFiche'>
            <div className='specialistFichePart'> {renderInspectionDocuments()}</div>
          </div>
        </CardItemInDrawer>
        <CardItemInDrawer title='Infos inspection'>
          <div className='inspectionDetailsContent'>
            <ul className='inspectionDetailsContentInfos'>
              <li className='twoItems'>
                <div className='smallCaps'>Commandée par</div>
                <div data-cy='userFirstname' className='text'>
                  {_.get(inspection, 'user.firstname') || ''}&nbsp;{_.get(inspection, 'user.lastname') || ''}
                </div>
              </li>
              <li className='twoItems'>
                <div className='smallCaps'>Rapport</div>
                <div data-cy='reportTitle' className='text'>
                  {_.get(inspection, 'report.title') || ''}
                </div>
              </li>
              {inspection.status === 'done' && (
                <li className='twoItems'>
                  <div className='smallCaps mt15px'>Durée de l'inspection</div>
                  <div data-cy='inspectionDuration' className='text'>
                    {inspection.summary.inspectionDuration ? `${Math.floor(inspection.summary.inspectionDuration / 60)}  minutes` : 'Non communiquée'}
                  </div>
                </li>
              )}
              {['scheduled', 'done', 'canceled', 'no_show'].includes(inspection.status) && _.get(inspection, 'appointment.internal.distanceMeters') && (
                <li className='oneItems'>
                  <div className='smallCaps mt15px'>Distance - durée trajet domicile</div>
                  <div data-cy='inspectionDistance' className='text'>
                    {(_.get(inspection, 'appointment.internal.distanceMeters') / 1000).toLocaleString('fr-FR', { maximumFractionDigits: 1 })} km -{' '}
                    {(_.get(inspection, 'appointment.internal.durationSeconds') / 60).toLocaleString('fr-FR', { maximumFractionDigits: 0 })} mins
                  </div>
                </li>
              )}
              {inspection.status === 'done' && inspection.billingInfos && (
                <div>
                  <li className='twoItems'>
                    <div className='smallCaps mt15px'>Rémunération</div>
                    <div data-cy='billingInfosInspectionPrice' className='text'>
                      {inspection.billingInfos.inspectionPrice}€
                    </div>
                  </li>
                  {inspection.billingInfos.distanceCompensation && (
                    <li className='twoItems'>
                      <div className='smallCaps mt15px'>Prime de déplacement</div>
                      <div data-cy='billingInfosDistanceCompensation' className='text'>
                        {inspection.billingInfos.distanceCompensation}€
                      </div>
                    </li>
                  )}
                  {inspection.billingInfos.adjustment && (
                    <li className='twoItems'>
                      <div className='smallCaps mt15px'>Ajustement</div>
                      <div data-cy='billingInfosAdjustment' className='text'>
                        {inspection.billingInfos.adjustment}€
                      </div>
                    </li>
                  )}
                  {inspection.billingInfos.comment && (
                    <li className='twoItems'>
                      <div className='smallCaps mt15px'>Commentaire</div>
                      <div data-cy='billingInfosComment' className='text'>
                        {inspection.billingInfos.comment}
                      </div>
                    </li>
                  )}
                </div>
              )}
              {inspection.status === 'done' && _.get(inspection, 'signature.clientRefusal') && (
                <li className='oneItems'>
                  <div className='smallCaps mt15px'>Le client a refusé de signer le rapport</div>
                </li>
              )}
            </ul>
          </div>
        </CardItemInDrawer>
        {inspection?.unassignedInfos && (
          <CardItemInDrawer title='Infos désassignation'>
            <div className='inspectionDetailsContent'>
              <ul className='inspectionDetailsContentInfos'>
                <li className='twoItems'>
                  <div className='smallCaps'>Déassignée par</div>
                  <div data-cy='unassignedInfosUser' className='text'>
                    {_.get(inspection, 'unassignedInfos.user.firstname') || ''}&nbsp;{_.get(inspection, 'unassignedInfos.user.lastname') || ''}
                  </div>
                </li>
                <li className='twoItems'>
                  <div className='smallCaps'>Date</div>
                  <div data-cy='unassignedInfosDate' className='text'>
                    {moment(inspection.unassignedInfos?.date).format(constants.formatDateTimeAppointment)}
                  </div>
                </li>
                {inspection?.unassignedInfos?.comment && (
                  <li className='oneItems'>
                    <div className='smallCaps'>Commentaire</div>
                    <div data-cy='unassignedInfosComment' className='text'>
                      {inspection?.unassignedInfos?.comment}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </CardItemInDrawer>
        )}
        <CardItemInDrawer title='Commentaires' icon={<i className='far fa-comments' />}>
          <div className='commentsContainer'>
            {adminInterface && inspection.comments && (
              <div>
                <div className='commentsList'>
                  <ul ref={commentsContainerRef}>
                    {inspection.comments.map((c, i) => {
                      const commentMomentDate = moment(c.createdAt);
                      const commentDateText = commentMomentDate > moment().startOf('day').subtract(2, 'day') ? commentMomentDate.fromNow('') : commentMomentDate.format('DD/MM/YYYY à HH:mm');
                      return (
                        <li key={`comment-${i}`}>
                          <div className='commentsListLeft'>
                            {c.user ? (
                              <div>
                                {c.user.profilePicture && <img src={c.user.profilePicture} alt={c.user.profilePicture} />}
                                {!c.user.profilePicture && <i className='far fa-user-circle fa-2xl' />}
                                {c.user.firstname || ''} {c.user.lastname || ''}
                              </div>
                            ) : null}
                          </div>
                          <div className='commentsListMessage'>
                            <div data-cy='adminComment' className='message'>
                              {c.message}
                            </div>
                            <div data-cy='adminCommentDate' className='timestamp'>
                              {commentDateText}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className='messageBoxPart'>
                  <textarea value={newCommentText} onChange={updateStateComment} onFocus={onFocusTextArea} onBlur={onBlurTextArea} />
                  <button className={!newCommentText.trim().length ? 'disable' : ''} disabled={loadingAddComment && newCommentText.trim().length} onClick={addNewComment}>
                    {!loadingAddComment && 'Envoyer'}
                    {loadingAddComment && <i className='fas fa-spinner fa-pulse' />}
                  </button>
                </div>
              </div>
            )}
          </div>
        </CardItemInDrawer>
      </div>
      <FileInput
        type='file'
        style={{
          display: 'none',
        }}
        ref={uploadInputRef}
        onChange={onInputFileChange}
      />
    </DrawerWrapperBody>
  );
};

InspectionDetailsDrawer.propTypes = {
  dispatch: PropTypes.func,
  adminInterface: PropTypes.bool,
  title: PropTypes.string,
  application: PropTypes.object,
  inspection: PropTypes.object,
  isOpen: PropTypes.bool,
  closeCard: PropTypes.func,
  inspectionId: PropTypes.any,
  isLoading: PropTypes.bool,
  getInspection: PropTypes.func,
  placement: PropTypes.string,
  mask: PropTypes.bool,
  lastUpdate: PropTypes.number,
  error: PropTypes.string,
};

const mapStateToProps = (state) => ({
  application: state.application,
  inspection: state.plannedInspections.inspectionDrawer,
  isLoading: state.plannedInspections.isLoading,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
  error: state.plannedInspections.error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getInspection,
  updateCommentInspection,
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionDetailsDrawer);
