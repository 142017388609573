import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const ReportSelector = ({ reportType, isMulti, selection, handleSelect, options }) => (
  <div className='formInput customElementSelector'>
    <label>Rechercher un rapport {!isMulti ? ' (par défaut)' : ''}</label>
    <div data-cy={reportType}>
      <Select
        placeholder={"Nom d'un rapport..."}
        className='multi-select'
        value={selection}
        onChange={handleSelect}
        options={options}
        isMulti={isMulti}
        name={reportType}
        isClearable
      />
    </div>
  </div>
);

ReportSelector.propTypes = {
  reportType: PropTypes.string,
  isMulti: PropTypes.bool,
  selection: PropTypes.any,
  handleSelect: PropTypes.func,
  options: PropTypes.array,
};

export default ReportSelector;
