import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, InputNumber, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { clearBillingConfiguration, getBillingConfiguration, getBillingHistoryConfiguration } from '../../../../actions/BillingsActions';
import ReportsActions from '../../../../actions/ReportsV3Actions';
import constants from '../../../../constants';
import convertToFloat from '../../../../helpers/convertToFloat';
import ReportServices from '../../../../services/ReportsService';
import ModalBody from '../../../shared/molecules/ModalBody';

Modal.setAppElement('body');

const { Panel } = Collapse;

const ModalBillingConfiguration = ({ reportsV3: { modalData }, dispatch, pageNumber, isLoading, billingConfiguration, billingHistoryConfiguration, errorState }) => {
  const [billingConfigurationData, setBillingConfigurationData] = useState();
  const [billingHistoryConfigurationData, setBillingHistoryConfigurationData] = useState();
  const [title, setTitle] = useState();
  const [qualityBonus, setQualityBonus] = useState(0);
  const [inspectionsPrices, setInspectionsPrices] = useState([]);
  const [creatorHistory, setCreatorHistory] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [error, setError] = useState(null);
  const { addToast } = useToasts();
  const columnsOfHistoryTable = [
    {
      title: 'Nom',
      dataIndex: 'lastname',
    },
    {
      title: 'Prénom(s)',
      dataIndex: 'firstname',
    },
    {
      title: 'Date',
      dataIndex: 'updatedAt',
    },
  ];

  const closeModal = () => {
    dispatch(ReportsActions.hideReportsV3Modal());
    dispatch(clearBillingConfiguration());
  };

  const onChange = (value, name = '', index = 0) => {
    if (name === 'qualityBonus') {
      setQualityBonus(value);
    } else if (name === 'inspectionPrice') {
      const inspectionsPrice = [...inspectionsPrices];
      inspectionsPrice.splice(index, 1, value);
      setInspectionsPrices(inspectionsPrice);
    }
  };

  // eslint-disable-next-line consistent-return
  const saveBillingHistory = async (e) => {
    e.preventDefault();
    setLoadingUpdate(true);
    try {
      const data = {
        qualityBonus: convertToFloat(qualityBonus),
        inspectionsPrices: inspectionsPrices.map((inspection) => convertToFloat(inspection)),
      };
      await ReportServices.post(constants.URI.v3.billings.getOrUpdateConfiguration.concat(modalData._id), data);
      dispatch(ReportsActions.executeAction('reports', { pageNumber }));
      addToast('Mise à jour effectuée avec succès', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 2000 });
      closeModal();
    } catch (err) {
      setLoadingUpdate(false);
      setError(err ? err.error : 'Une erreur est survenue');
    }
  };

  const addNewInspectionPrice = () => {
    setInspectionsPrices([...inspectionsPrices, 0]);
  };

  useEffect(() => {
    if (billingConfigurationData && billingHistoryConfigurationData) {
      if (Object.keys(billingConfigurationData).length > 0 && billingHistoryConfigurationData.length > 0) {
        setCreatorHistory(
          billingHistoryConfigurationData.map((billingHisto, index) => ({
            key: index + 1,
            lastname: _.get(billingHisto, 'creator.lastname'),
            firstname: _.get(billingHisto, 'creator.firstname'),
            updatedAt: moment(_.get(billingHisto, 'updatedAt')).format(constants.formatDateTimeAppointment),
          })),
        );
        setTitle(modalData.title);
        setQualityBonus(billingConfigurationData.qualityBonus);
        setInspectionsPrices(billingConfigurationData.inspectionsPrices);
      } else {
        setCreatorHistory([]);
        setQualityBonus(0);
        setInspectionsPrices([10, 20, 30, 40, 50]);
      }
    }
  }, [billingConfigurationData, billingHistoryConfigurationData]);

  useEffect(() => {
    if (Object.keys(billingConfiguration).length > 0 && billingHistoryConfiguration.length > 0) {
      setBillingConfigurationData(billingConfiguration);
      setBillingHistoryConfigurationData(billingHistoryConfiguration);
    }
  }, [billingConfiguration, billingHistoryConfiguration]);

  useEffect(() => {
    setBillingHistoryConfigurationData(null);
    setBillingConfigurationData(null);
    (async () => {
      await Promise.all([getBillingConfiguration(dispatch, modalData._id), getBillingHistoryConfiguration(dispatch, modalData._id)]);
    })();
  }, []);

  return (
    <ModalBody
      closeModal={closeModal}
      title='Configuration du barème de rémunération'
      isOpen={true}
      updateAction={true}
      actionSave={saveBillingHistory}
      isLoading={isLoading}
      error={error || errorState}
      loadingButton={loadingUpdate}
    >
      {
        <div>
          <div className='mainTitle title'>
            {' '}
            <span className='modalFontBig'>Rapport : </span> <span>{title}</span>
          </div>
          <Collapse
            bordered={false}
            defaultActiveKey={['2']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className='site-collapse-custom-collapse scaleBloc'
          >
            <Panel header='Prime de qualité par véhicule' key='1' className='site-collapse-custom-panel'>
              <Row>
                <Col xs={10} className='labelBloc'>
                  <Row>
                    <Col xs={6}>
                      <div className='formInput'>
                        <InputNumber
                          className='input-value'
                          name='Prime de qualité'
                          placeholder='Prime de qualité'
                          defaultValue={qualityBonus}
                          formatter={(value) => `${value}€`}
                          parser={(value) => value.replace('€', '')}
                          onChange={(value) => onChange(value, 'qualityBonus')}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
            <Panel header='Barème' key='2' className='site-collapse-custom-panel scaleBloc'>
              <Row>
                <Col xs={10} className='labelBloc'>
                  <Row>
                    <Col xs={6}>
                      <label>Nombre de véhicules</label>
                    </Col>
                    <Col xs={6}>
                      <label>Rémuneration</label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className='scrollIntoView styleScroll'>
                {inspectionsPrices.map((inspectionPrice, index) => (
                  <Row key={index}>
                    <Col xs={10}>
                      <Row>
                        <Col xs={6}>
                          <div className='formInput'>
                            <input readOnly={true} type='text' value={index + 1} placeholder='Nombre de voitures' style={{ minHeight: 38, borderRadius: 8 }} />
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className='formInput'>
                            <InputNumber
                              key='Rémuneration'
                              name='Rémuneration'
                              className='input-value'
                              placeholder='Rémuneration'
                              defaultValue={inspectionPrice}
                              formatter={(value) => `${value}€`}
                              parser={(value) => value.replace('€', '')}
                              onChange={(value) => onChange(value, 'inspectionPrice', index)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
                {
                  <button type='button' onClick={(e) => addNewInspectionPrice(e)} className='circle'>
                    +
                  </button>
                }
              </div>
            </Panel>
            <Panel header='Historique' key='3' className='site-collapse-custom-panel scaleHistoryBloc'>
              <div>
                <Table className='historyTable' columns={columnsOfHistoryTable} dataSource={creatorHistory} size='middle' pagination={{ pageSize: 4 }} scroll={{ y: 200 }} />
              </div>
            </Panel>
          </Collapse>
        </div>
      }
    </ModalBody>
  );
};

ModalBillingConfiguration.propTypes = {
  dispatch: PropTypes.func,
  getBillingConfiguration: PropTypes.func,
  getBillingHistoryConfiguration: PropTypes.func,
  clearBillingConfiguration: PropTypes.func,
  reportsV3: PropTypes.object,
  pageNumber: PropTypes.number,
  billingConfiguration: PropTypes.object,
  billingHistoryConfiguration: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  errorState: PropTypes.any,
};

const mapStateToProps = (state) => ({
  billingConfiguration: state.billings.billingConfiguration,
  billingHistoryConfiguration: state.billings.billingHistoryConfiguration,
  isLoading: state.billings.isLoading,
  errorState: state.billings.error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getBillingConfiguration,
  getBillingHistoryConfiguration,
  clearBillingConfiguration,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalBillingConfiguration);
