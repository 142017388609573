import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getMission } from '../../../actions/MissionsActions';
import constants from '../../../constants';
import formatPhoneNumber from '../../../helpers/phone';
import MissionsServices from '../../../services/MissionsServices';
import RenderError from '../../shared/atoms/RenderError';
import CardItemInDrawer from '../../shared/molecules/CardItemInDrawer';
import DrawerWrapperBody from '../../shared/organisms/DrawerWrapperBody';

const MissionDetailsDrawer = (props) => {
  const {
    isOpen, closeCard, missionId, dispatch, placement = 'right', mask = true, mission, adminInterface, isLoading,
  } = props;
  const [loadingAddComment, setLoadingAddComment] = useState(false);
  const [newCommentText, setNewCommentText] = useState('');
  const [focusTextArea, setFocusTextArea] = useState(false);
  const [error, setError] = useState('');

  const commentsContainerRef = useRef();

  const scrollToBottomOfMessages = () => {
    if (commentsContainerRef && commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight - commentsContainerRef.current.clientHeight;
    }
  };

  const addNewComment = async () => {
    const comments = [
      {
        message: newCommentText,
        user: props.application.user._id,
        createdAt: new Date(),
      },
    ];
    const response = await MissionsServices.addNewComments({ missionId, comments });
    if (response && response.status < 400) {
      setNewCommentText('');
      await getMission(dispatch, missionId);
    } else {
      setError(response?.body?.error?.replace('Error:', '') || "Une erreur est survenue pendant l'envoi d'un commentaire");
    }
  };

  const lat = mission?.appointment?.lat;
  const lng = mission?.appointment?.lng;

  const updateStateComment = (e) => {
    setNewCommentText(e.target.value);
  };

  const onFocusTextArea = () => {
    setFocusTextArea(true);
  };

  const onBlurTextArea = () => {
    setFocusTextArea(false);
  };

  // Auto refresh
  useEffect(() => {
    const interval = setInterval(() => {
      if (newCommentText || focusTextArea) {
        return clearInterval(interval);
      }
      return (async () => getMission(dispatch, missionId))();
    }, constants.autoRefresh);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (Object.keys(mission).length > 0) {
      setLoadingAddComment(false);
      setNewCommentText('');
      scrollToBottomOfMessages();
    }
  }, [mission]);

  useEffect(() => {
    (async () => {
      getMission(dispatch, missionId);
    })();
  }, [missionId]);

  return (
    <DrawerWrapperBody
      title={
        <div className='topContainerMainElement'>
          <div className='inspectionDetailsStatus'>
            <strong>
              N°{mission.readableId}
            </strong>
            {mission.status === 'done' && <span className='badge done-status'>Réalisée</span>}
            {mission.status === 'ordered' && <span className='badge ordered-status'>En attente</span>}
            {mission.status === 'scheduled' && <span className='badge scheduled-status'>Programmée</span>}
            {mission.status === 'ongoing' && <span className='badge ongoing-status'>En cours</span>}
            {mission.status === 'canceled' && <span className='badge canceled-status'>Annulée</span>}

          </div>
        </div>
      }
      isOpen={isOpen}
      closeDrawer={() => closeCard()}
      placement={placement}
      mask={mask}
      isLoading={isLoading}
    >
      <div className='inspectionDetails'>
        { mission.status === 'canceled' && mission.cancelInfos && (
          <CardItemInDrawer title="Informations sur l'annulation">
            <div className='inspectionDetailsContent'>
              <ul className='inspectionDetailsContentInfos'>
                <li className='twoItems'>
                  <div className='smallCaps'>Annulée par: </div>
                  <div data-cy='user' className='text'>
                    {mission?.cancelInfos?.user?.firstname || ''}&nbsp;{mission?.cancelInfos.user?.lastname || ''}
                  </div>
                </li>
                <li className='twoItems'>
                  <div className='smallCaps'>Date d'annulation</div>
                  <div data-cy='userName' className='text'>
                    { moment(mission?.cancelInfos?.date).format('DD/MM/YYYY à HH:mm') || ''}
                  </div>
                </li>
                <li className='oneItems'>
                  <div className='smallCaps'>Commentaire(s)</div>
                  <div data-cy='requiredSkills' className='text'>
                    {mission?.cancelInfos?.comment || ''}
                  </div>
                </li>
              </ul>
            </div>
          </CardItemInDrawer>
        )}
        <CardItemInDrawer title='Informations client'>
          <div className='inspectionDetailsContent'>
            <ul className='inspectionDetailsContentInfos'>
              <li className='twoItems'>
                <div className='smallCaps'>Groupe</div>
                <div data-cy='entityName' className='text'>
                {mission?.entity?.name || <span>&nbsp;</span>}
                </div>
              </li>
              <li className='twoItems'>
                <div className='smallCaps'>Site</div>
                <div data-cy='concessionName' className='text'>
                {mission?.concession?.name || <span>&nbsp;</span>}
                </div>
              </li>
              <li className='twoItems'>
                <div className='smallCaps'>Commandé par</div>
                <div data-cy='userName' className='text'>
                  {mission?.user?.firstname || ''}&nbsp;{mission?.user?.lastname || ''}
                </div>
              </li>
              <li className='oneItems'>
                <div className='smallCaps'>Compétence(s) Requise(s)</div>
                <div data-cy='requiredSkills' className='text'>
                {mission?.requiredSkills?.map((object) => object.label).join(', ') || <span>&nbsp;</span>}
                </div>
              </li>
            </ul>
          </div>
        </CardItemInDrawer>

        <CardItemInDrawer title='Informations complémentaires'>
          <div className='inspectionDetailsContent'>
            <div className='smallCaps'>Description:</div>
            <div data-cy='description' className='text'>
            {mission.description || ''}
            </div>
            <div className='smallCaps'>Organisée par:</div>
            <div data-cy='createdBy' className='text'>
              {mission?.createdBy?.firstname || ''} {mission?.createdBy?.lastname || ''}
            </div>
          </div>
        </CardItemInDrawer>

        <CardItemInDrawer title='Informations sur la mission'>
          <div className='inspectionDetailsContent'>
            <ul className='inspectionDetailsContentInfos'>
              <li className='twoItems'>
                <div className='smallCaps'>Dates</div>
                <div data-cy='dates' className='text'>
                 Du {moment(mission.start).format(constants.formatDateAppointmentAgGrid)}
                 <br/> au {moment(mission.end).format(constants.formatDateAppointmentAgGrid)}
                </div>
              </li>
              <li className='twoItems'>
                <div className='smallCaps'>Spécialiste</div>
                <div data-cy='assignedSpecialist' className='text'>
                  {mission.specialist ? `${mission.specialist.firstname} ${mission.specialist.lastname}` : <span className='badge error'>Non assigné</span>}
                </div>
              </li>
              <li className='twoItems'>
                <div className='smallCaps'>Contact Sur Place</div>
                <div data-cy='contactName' className='text'>
                  {mission?.appointment?.contactName || <span>&nbsp;</span>}
                </div>
              </li>
              <li className='twoItems'>
                <div className='smallCaps'>Téléphone</div>
                <div data-cy='contactPhone' className='text'>
                  {formatPhoneNumber(mission?.appointment?.contactPhone) || <span>&nbsp;</span>}
                </div>
              </li>
            </ul>
            <div className='smallCaps'>Adresse</div>
            <div data-cy='appointmentAddress' className='text'>
              {lat && lng && (
                <a rel='noreferrer' target='_blank' href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`}>
                  {mission?.appointment?.address}
                </a>
              )}
            </div>
          </div>
        </CardItemInDrawer>

        <CardItemInDrawer title='Horaires'>
        <div className='inspectionDetailsContent'>
            <ul className='inspectionDetailsContentInfos'>
                {mission && mission.workingWeek && _.map(mission.workingWeek, (dailySchedule, index) => {
                  const weekdayName = [];

                  for (let i = 1; i <= 7; i += 1) {
                    weekdayName.push(moment().day(i).format('dddd'));
                  }
                  const startDuration = moment(dailySchedule?.start).format('HH:mm');
                  const endDuration = moment(dailySchedule?.end).format('HH:mm');

                  return dailySchedule?.start !== 1
                    && <li key={index}>
                          <div className='smallCaps'>{ dailySchedule?.start !== 1 && weekdayName[index][0].toUpperCase() + weekdayName[index].substr(1)}</div>
                          <div data-cy='dailySchedule' className='text'>
                            {dailySchedule?.start !== 1 ? `${startDuration} à ${endDuration}` : ''}
                          </div>
                        </li>;
                })}
            </ul>
            {mission?.excludedDays?.length > 0
              ? <div className='inspectionDetailsContentInfos'>
                <div className='smallCaps'>Jours exclus</div>
                <div data-cy='excludedDays' className='text'>
                  {mission.excludedDays.map((excludedDay, index) => (<div key={index}>{moment(excludedDay, 'DD/MM/YYYY').format('DD MMM YYYY')}</div>))}
                </div>
            </div>
              : ''}
          </div>
        </CardItemInDrawer>
        { props.application?.user?.isAdmin && (
          <>
            <CardItemInDrawer title='Facturation'>
              <div className='inspectionDetailsContent'>
                <ul className='inspectionDetailsContentInfos'>
                  <li className='twoItems'>
                    <div className='smallCaps' style={{ color: mission.billing?.dailyRate === 0 ? 'red' : '' }}>Prix par jour</div>
                    <div data-cy='pricePerDay' className='text' >
                      {mission.billing?.dailyRate || 0} €
                    </div>
                  </li>
                  <li className='twoItems'>
                    <div className='smallCaps'>Ajustement</div>
                    <div data-cy='adjustmentPrice' className='text'>
                      {mission.billing?.adjustment || 0} €
                    </div>
                  </li>
                  <li className='twoItems'>
                    <div className='smallCaps' style={{ color: mission.billing?.dailyRate === 0 ? 'red' : '' }}>Nombre de jours</div>
                    <div data-cy='workedDays' className='text'>
                      {mission.billing?.workedDays || 0} jour(s)
                    </div>
                  </li>
                  <li className='twoItems'>
                    <div className='smallCaps'style={{ color: mission.billing?.dailyRate === 0 ? 'red' : '' }}>Total</div>
                    <div data-cy='totalPrice' className='text'>
                      {mission.billing?.total || 0} €
                    </div>
                  </li>
                  { mission.billing?.adjustmentComment
                    && <li className='twoItems'>
                      <div className='smallCaps'>Commentaire</div>
                      <div data-cy='adjustmentComment' className='text'>
                        {mission.billing?.adjustmentComment || ''}
                      </div>
                    </li>
                  }
                  { mission.billing?.author
                    && <li className='twoItems'>
                      <div className='smallCaps'>Auteur</div>
                      <div data-cy='billingAuthor' className='text'>
                        {_.get(mission, 'billing.author.firstname') || ''}&nbsp;{_.get(mission, 'billing.author.lastname') || ''}
                      </div>
                    </li>
                  }
                </ul>
              </div>
            </CardItemInDrawer>
            <CardItemInDrawer title='Commentaires Internes'>
              <div className='commentsContainer'>
                {adminInterface && (
                  <div>
                    <div className='commentsList'>
                      <ul ref={commentsContainerRef}>
                        {mission?.comments?.map((comment, i) => {
                          const commentMomentDate = moment(comment.createdAt);
                          const commentDateText = commentMomentDate > moment().startOf('day').subtract(2, 'day') ? commentMomentDate.fromNow('') : commentMomentDate.format(constants.formatDateTimeAppointment);

                          return (comment.message !== ''
                            && <li key={i} >
                              <div className='commentsListLeft'>
                                {comment.user ? (
                                  <div>
                                    {comment.user.profilePicture && <img src={comment.user.profilePicture} alt={comment.user.profilePicture} />}
                                    {!comment.user.profilePicture && <i className='far fa-user-circle' />}
                                    {comment.user.firstname || ''} {comment.user.lastname || ''}
                                  </div>
                                ) : null}
                              </div>
                              <div className='commentsListMessage'>
                                <div data-cy='adminComment' className='message'>
                                  {comment.message}
                                </div>
                                <div data-cy='adminCommentDate' className='timestamp'>
                                  {commentDateText}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className='messageBoxPart'>
                      <textarea value={newCommentText} onChange={updateStateComment} onFocus={onFocusTextArea} onBlur={onBlurTextArea} />
                      <button className={!newCommentText.trim().length ? 'disable' : ''} disabled={loadingAddComment && newCommentText.trim().length} onClick={addNewComment}>
                        {!loadingAddComment && 'Envoyer'}
                        {loadingAddComment && <i className='fas fa-spinner fa-pulse' />}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </CardItemInDrawer>
          </>
        )}
        {error && <RenderError error={error} />}
      </div>

    </DrawerWrapperBody>
  );
};

MissionDetailsDrawer.propTypes = {
  dispatch: PropTypes.func,
  adminInterface: PropTypes.bool,
  title: PropTypes.string,
  application: PropTypes.object,
  mission: PropTypes.object,
  isOpen: PropTypes.bool,
  closeCard: PropTypes.func,
  missionId: PropTypes.any,
  isLoading: PropTypes.bool,
  getMission: PropTypes.func,
  placement: PropTypes.string,
  mask: PropTypes.bool,
  lastUpdate: PropTypes.number,
  error: PropTypes.string,
};

const mapStateToProps = (state) => ({
  application: state.application,
  mission: state.missions.mission,
  isLoading: state.missions.mission.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getMission,
});

export default connect(mapStateToProps, mapDispatchToProps)(MissionDetailsDrawer);
