import c from '../constants';

const getSignedUrl = async (routeApi, key) =>
  fetch(`${routeApi}?`.concat(`key=${key}`), {
    method: 'GET',
    credentials: 'include',
  });

const putFileToBucket = async (url, type, file) => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': type,
    },
    body: file,
  });

  return { status: response.status };
};

// eslint-disable-next-line consistent-return
const uploadFile = async ({ docType, body, specialistId, type }) => {
  const key = `${specialistId}/${docType}.${type.split('/')[1]}`;

  try {
    const getUrlSigned = await fetch(`${c.URI.signedUrlPut}?`.concat(`key=${key}`).concat(`&type=${type}`), {
      method: 'GET',
      credentials: 'include',
    });

    if (getUrlSigned?.url) {
      const jsonFormatOfSignedUrl = await getUrlSigned.json();
      if (jsonFormatOfSignedUrl) {
        const resultPutFileToBucket = await putFileToBucket(jsonFormatOfSignedUrl.url, type, body);
        if (resultPutFileToBucket) {
          return resultPutFileToBucket.status;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const uploadInspectionFile = async ({ oldFileName, body, inspectionId, type }) => {
  const documentInfos = [
    {
      oldFileName,
      fileName: body.name,
      type,
    },
  ];

  await fetch(`${c.URI.v3.inspections.saveDocumentInfos}/${inspectionId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ documents: documentInfos }),
  })
    .then(async (res) => {
      const result = await res.json();
      return putFileToBucket(result.url, type, body);
    })
    // eslint-disable-next-line no-console
    .catch((e) => console.error(e.message));
};

export { uploadFile, uploadInspectionFile, getSignedUrl, putFileToBucket };
