import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getActionToDispatch } from '../../../actions/MissionsActions';

const MissionsActionsCell = (props) => {
  const {
    data, dispatch,
  } = props;

  if (!data) {
    return <div/>;
  }

  function handleCloneMission() {
    const cleanedData = {
      ...data, start: undefined, end: undefined,
    };
    dispatch(getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'createMission', modalData: cleanedData }));
  }

  const viewMission = (<div
    style={{ marginRight: 10 }}
    title={'Voir la mission'}
    onClick={() => dispatch(getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'viewMission', modalData: data }))}
    >
      <i className={'fas fa-eye'} />
    </div>);

  const editWheel = (<div
                      style={{ marginRight: 10 }}
                      title={'Éditer la mission'}
                      onClick={() => dispatch(getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'editMission', modalData: data }))}
                      >
                     <i className={'fas fa-edit hover-icon'} />
                    </div>);

  const cloneMission = (<div
                        style={{ marginRight: 10 }}
                        title={'Cloner la mission'}
                        onClick={() => handleCloneMission()}
                        >
                          <i className={'far fa-clone hover-icon'} />
                        </div>);

  return (
    <>
      <div style={{ fontSize: 'x-large', display: 'inline-flex', padding: 5 }}>
          {viewMission}
          {data.status !== 'canceled' && data.status !== 'done' && editWheel}
          {(['chief', 'superChief'].includes(props.user?.role) || props.user?.isAdmin) && cloneMission}
      </div>
    </>
  );
};

MissionsActionsCell.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.application.user,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(MissionsActionsCell);
