import { Tooltip } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ label, handleDelete, sortIndex }) => (
  <li className='SortableItem infinite'>
    <div style={{ maxWidth: '80%' }}>{label}</div>
    <div>
      <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 1300 }} title='Supprimer Pièce automobile'>
        {' '}
        <button className='btnIcon delete' style={{ zIndex: 1000 }} onClick={(e) => handleDelete(e, sortIndex)}>
          <i className='fas fa-trash-alt' />
        </button>
      </Tooltip>
    </div>
  </li>
));

const SortableList = SortableContainer(({ items, handleDelete, type = 'MsaCarPart' }) => {
  if (type === 'MsaCarPart') {
    return (
      <ul className='SortableList'>
        {items &&
          items.map((item, i) => (
            <SortableItem key={`item-${i}`} index={i} sortIndex={i} label={_.get(item, 'refCheckpoint.label') || _.get(item, 'label')} handleDelete={handleDelete} />
          ))}
      </ul>
    );
  }
  return (
    <ul className='SortableList'>
      {items &&
        items.map((item, i) => (
          <SortableItem
            key={`item-${i}`}
            index={i}
            sortIndex={i}
            label={_.get(item, 'labelMsa') ? `${_.get(item, 'labelMsa')} / ${_.get(item, 'labelGt')}` : _.get(item, 'labelGt')}
            handleDelete={handleDelete}
          />
        ))}
    </ul>
  );
});

class DragDropListCarParts extends Component {
  render() {
    return <SortableList items={this.props.carParts} onSortEnd={this.props.onSortEnd} type={this.props.type} distance={5} handleDelete={this.props.handleDeleteItem} />;
  }
}

DragDropListCarParts.propTypes = {
  carParts: PropTypes.array,
  type: PropTypes.string,
  onSortEnd: PropTypes.func,
  handleDeleteItem: PropTypes.func,
};

export default DragDropListCarParts;
