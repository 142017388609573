import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import { getBillings, updateStatusBilling } from '../../../actions/BillingsActions';
import constants from '../../../constants';
import ModalBody from '../../shared/molecules/ModalBody';

Modal.setAppElement('body');

const ModalChangeStatusBilling = ({ dispatch, isOpen, billingsSelected, isLoading, isLoadingUpdate, closeModal, error }) => {
  const { addToast } = useToasts();
  const [billingsData, setBillingsData] = useState({});

  const removeElementFromArray = (array, ArrayElementToRemove) => array.filter((value, index) => ArrayElementToRemove.indexOf(index) === -1);

  const getOptionsBillingStatusToShow = (oldBillingStatus) => {
    switch (oldBillingStatus) {
      case constants.statusBilling.ordered.name:
        return removeElementFromArray(constants.statusBillingsToChange, [0, 3]);
      case constants.statusBilling.due.name:
        return removeElementFromArray(constants.statusBillingsToChange, [1, 2, 3]);
      case constants.statusBilling.paid.name:
        return removeElementFromArray(constants.statusBillingsToChange, [0, 1, 2]);
      case constants.statusBilling.bankError.name:
        return removeElementFromArray(constants.statusBillingsToChange, [0, 1, 3]);
      default:
        return constants.statusBillingsToChange;
    }
  };

  const updateStatusBillingModal = async () => {
    if (!billingsData.status && getOptionsBillingStatusToShow(billingsData.oldStatus).length > 1) return;
    const result = await updateStatusBilling(dispatch, billingsData);
    if (result.type === 'UPDATE_STATUS_BILLING_SUCCESS') {
      addToast('Mise à jour effectuée avec succès', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 2000 });
      closeModal();
    }
  };

  const initialize = async () => {
    const billingIds = [];
    const oldStatus = billingsSelected[0].status;
    billingsSelected.map((billing) => billingIds.push(billing._id));
    setBillingsData({ billingIds, oldStatus });
  };

  useEffect(() => {
    const optionsBillingStatusToShow = getOptionsBillingStatusToShow(billingsData.oldStatus);
    if (optionsBillingStatusToShow.length === 1) {
      setBillingsData({ ...billingsData, status: optionsBillingStatusToShow[0].value });
    }
  }, [billingsData.oldStatus]);

  useEffect(() => {
    (async () => initialize())();
  }, []);

  return (
    <ModalBody
      closeModal={closeModal}
      title='Changement de statut'
      isOpen={isOpen}
      actionSave={updateStatusBillingModal}
      isLoading={isLoading}
      updateAction={true}
      error={error}
      loadingButton={isLoadingUpdate}
      classToAdd='changeStatus'
    >
      <div className='modalChangeStatusBilling'>
        {
          <div>
            <h3>Veuillez sélectionner le nouveau statut : </h3>
            <div data-cy='statusSelection'>
              <Select
                name='billingStatus'
                options={getOptionsBillingStatusToShow(billingsData.oldStatus).map((status) => ({ value: status.value, label: constants.statusBilling[status.value].label }))}
                onChange={(e) => setBillingsData({ ...billingsData, status: e.value })}
                className='basic-multi-select'
                placeholder='Veuillez selectionner le statut'
              />
            </div>
          </div>
        }
      </div>
    </ModalBody>
  );
};

ModalChangeStatusBilling.propTypes = {
  billingsSelected: PropTypes.array,
  isOpen: PropTypes.bool,
  isLoadingUpdate: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func,
  refresh: PropTypes.func,
  dispatch: PropTypes.func,
  updateStatusBilling: PropTypes.func,
  error: PropTypes.any,
};
const mapStateToProps = (state) => ({
  billing: state.billings.billing,
  isLoading: state.billings.isLoading,
  isLoadingUpdate: state.billings.isLoadingUpdate,
  error: state.billings.error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getBillings,
  updateStatusBilling,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangeStatusBilling);
