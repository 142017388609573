import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import _ from 'lodash';
import { mailRegex } from '../../../helpers/regex';

import InspectionsServices from '../../../services/InspectionsServices';
import AdminsActions from '../../../actions/AdminsActions';

const ModalResend = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [emails, setEmails] = useState(['']);
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setErrorMessage(false);
    setEmails(['']);
    setLoading(false);
  };

  const save = async () => {
    if (emails.every((e) => mailRegex.test(e))) {
      try {
        setLoading(true);
        setErrorMessage('');
        const results = await InspectionsServices.sendReportTo({
          inspectionId: _.get(props, 'inspection._id'),
          emails,
        });
        setLoading(false);

        if (results.status === 200) {
          props.onRequestClose({});
          AdminsActions.notify({
            type: 'NOTIFY',
            isError: false,
            message: 'Emails envoyés',
          })(props.dispatch);
        } else {
          setErrorMessage(results.body.error || '');
        }
      } catch (e) {
        setLoading(false);
        setErrorMessage("Une erreur inconnue s'est produite");
      }
    } else {
      setErrorMessage('Veuillez ne fournir que des adresses emails valides');
    }
  };

  const onClickAdd = () => {
    setEmails([...emails, '']);
  };

  const onClickRemove = (index) => () => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails); // spread operator to clone the state. It's forbidden to modify the state
  };

  const onChangeEmail = (index) => (value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  useEffect(() => {
    resetForm();
  }, [_.get(props, 'inspection._id')]);

  const inputs = emails.map((mail, index) => (
    <div className='email' key={`mail-${index}`}>
      <input type='email' value={mail} placeholder='Ex: john@doe.com, mim@doe.com' onChange={(e) => onChangeEmail(index)(e.target.value)} />
      {index !== 0 && (
        <div className='more-email' onClick={onClickRemove(index)}>
          <i className='fa fa-minus' />
        </div>
      )}
      {index === emails.length - 1 && (
        <div className='more-email' onClick={onClickAdd}>
          <i className='fa fa-plus' />
        </div>
      )}
    </div>
  ));

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      contentLabel='Modal nouvelle inspection'
      overlayClassName='modal-overlay'
      className='modal modalInspectionCreation'>
      <div className='modalContent'>
        <div className='mainTitle'>Renvoyer le rapport</div>
        <form>
          <div className='centeredForm'>
            <div className='horizontalFormPart'>
              <div className='formTitle'>Listez les emails qui recevront le rapport</div>
              <div className='formInput'>
                <label>Saisir les adresses emails</label>
                <div>{inputs}</div>
              </div>
            </div>
          </div>
          <div className='center formButton'>
            <button type='button' onClick={props.onRequestClose} className='negativ'>
              Fermer
            </button>
            <button type='button' disabled={loading} onClick={save} className='positiv'>
              {!loading && 'Valider'}
              {loading && <i className='fa fa-spinner fa-pulse' />}
            </button>
          </div>
        </form>
        {errorMessage && (
          <div className='error'>
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

ModalResend.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  inspection: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(null, mapDispatchToProps)(ModalResend);
