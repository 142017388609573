import assign from 'object-assign';

// eslint-disable-next-line import/no-mutable-exports
let defaults = {
  wrapperId: 'notification-wrapper',
  animationDuration: 100,
  timeout: 1500,
  zIndex: 1000,
  // top: 0, // Controls the offset from top of viewport.
  colors: {
    error: {
      color: '#FFFFFF',
      backgroundColor: '#E85742',
    },
    success: {
      color: '#FFFFFF',
      backgroundColor: '#f2784b',
    },
    warning: {
      color: '#333333',
      backgroundColor: '#F5E273',
    },
    info: {
      color: '#FFFFFF',
      backgroundColor: '#1f5288',
    },
  },
};

function mergeOptions(options) {
  defaults = assign(defaults, options);
}

export { defaults, mergeOptions };
