import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from '@ag-grid-community/react';
import NavigationActions from '../../../actions/NavigationActions';
import NavBar from '../../shared/templates/NavBar';
import InspectConcessSubMenu from '../../shared/organisms/InspectConcessSubMenu';
import Text from '../../shared/atoms/Text';
import ActionsBar from '../../shared/organisms/ActionsBar';
import { createMissionsOptions } from './atoms/gridOptions';
import { getActionToDispatch, getConcessionsMissions, updateNumberOfMonthsSelectedMissions } from '../../../actions/MissionsActions';
import loadInspectionsByNumberOfMonthsSelected from '../../../helpers/inspection';
import constants from '../../../constants';
import { exportExcelDateAndDateTimeFormatter } from '../../shared/atoms/gridDateUtils';
import { selectColumnsToExport } from '../../../helpers/agGridCustom';
import ModalCreateEditViewMission from '../../missions/organisms/ModalCreateEditMission';
import MissionDetailsDrawer from '../../missions/organisms/MissionDetailsDrawer';

const Missions = (props) => {
  const [status, setStatus] = useState('ordered');
  const [missionData, setMissionsData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterOptionSelected, setFilterOptionSelected] = useState('ordered');
  const [monthsSelected, setMonthsSelected] = useState(false);
  const [numberOfMonthsSelected, setNumberOfMonthsSelected] = useState(props.numberOfMonthsSelectedMissions);

  const gridApiRef = useRef();
  const gridColRef = useRef();
  const inputQuickFilterRef = useRef();
  const quickFilterTimeoutRef = useRef();

  const resizeGrid = () => gridApiRef.current && gridApiRef.current.sizeColumnsToFit();

  const fetchMissions = async () => {
    await getConcessionsMissions(props.dispatch, props.updatedAt);
  };

  const onClickRefresh = async () => {
    gridApiRef.current.deselectAll();
    await fetchMissions();
  };

  const waitUntilGridIsReady = () =>
    new Promise((resolve) => {
      const intervalCheckRef = setInterval(() => {
        if (gridApiRef) {
          clearInterval(intervalCheckRef);
          resolve();
        }
      }, 500);
    });

  const onSelectedMonths = (months) => {
    setMonthsSelected(true);
    setNumberOfMonthsSelected(months);
    updateNumberOfMonthsSelectedMissions(props.dispatch, months);
    loadInspectionsByNumberOfMonthsSelected(months, getConcessionsMissions, props.dispatch);
  };

  // Auto refresh
  useEffect(() => {
    const interval = setInterval(() => {
      onClickRefresh();
    }, constants.autoRefresh);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    resizeGrid();
    setMonthsSelected(false);
    if (props.allMissions.missions.length > 0) {
      setMissionsData(props.allMissions.missions);
    }
  }, [props.allMissions.missions]);

  const clearFilter = (field) => {
    const FilterComponent = gridApiRef.current.getFilterInstance(field);
    FilterComponent.setModel(null);
  };

  const onClickQuickStatusFilter = (statusF) => {
    // reset filters
    inputQuickFilterRef?.current?.reset();
    gridApiRef?.current?.deselectAll();
    // set filters
    if (statusF !== 'none') {
      gridApiRef.current.setFilterModel({ statusFilter: { type: 'startsWith', filter: statusF } });
    } else {
      clearFilter('statusFilter');
    }

    gridApiRef.current.onFilterChanged();

    setFilterOptionSelected(statusF);
  };

  const onClickExportExcel = () => {
    gridApiRef.current.exportDataAsExcel({
      processCellCallback: exportExcelDateAndDateTimeFormatter,
      columnKeys: selectColumnsToExport(createMissionsOptions().columnDefs),
    });
  };

  const onQuickFilterChange = (newValue) => {
    if (quickFilterTimeoutRef.current) {
      clearTimeout(quickFilterTimeoutRef.current);
    }
    quickFilterTimeoutRef.current = setTimeout(() => gridApiRef.current.setQuickFilter(newValue), 400);
    resizeGrid();
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    gridColRef.current = params.columnApi;
    gridApiRef.current.setQuickFilter('ordered');

    let setSelectedRowsTimeoutRef = null;
    // When clicking "select all" this even is called for each row (so many times !)
    // We only need to run setSelectedRows once, so we use a debounce to do it at the end
    params.api.addEventListener('rowSelected', () => {
      if (setSelectedRowsTimeoutRef) {
        clearTimeout(setSelectedRowsTimeoutRef);
      }
      setSelectedRowsTimeoutRef = setTimeout(() => {
        setSelectedRows(params.api.getSelectedRows());
      }, 50);
    });
  };

  const closeModal = async (withUpdate = false) => {
    props.dispatch(getActionToDispatch({ type: 'HIDE_MODAL' }));
    if (withUpdate) {
      await getConcessionsMissions(props.dispatch, props.updatedAt);
    }
  };

  const onChangeStatus = (statusF) => {
    setStatus(statusF);
  };

  const { dispatch, application, allMissions } = props;

  const myProps = {
    dispatch,
    application,
    allMissions,
  };

  const renderModals = () => {
    const { modalName } = allMissions;

    switch (modalName) {
      case 'createMission':
        return <ModalCreateEditViewMission {...myProps} closeModal={closeModal} />;
      case 'editMission':
        return <ModalCreateEditViewMission {...myProps} editModal={true} closeModal={closeModal} />;
      case 'viewMission':
        return <MissionDetailsDrawer adminInterface={true} isOpen={true} missionId={allMissions.modalData._id} mission={allMissions.modalData} closeCard={closeModal} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel({
        statusFilter: {
          type: 'startsWith',
          filter: status,
        },
      });
    }
  }, [status]);

  useEffect(() => {
    resizeGrid();
    waitUntilGridIsReady().then(() => {
      gridApiRef.current.setRowData(missionData);
      onClickQuickStatusFilter(filterOptionSelected || 'ordered');
    });
  }, [missionData]);

  useEffect(() => {
    props.dispatch(NavigationActions.setNavigation({ navigation: 'missions' }));
    (() => fetchMissions())();
  }, []);

  return (
    <div>
      <NavBar />
      {renderModals()}
      <div className='appcontainer'>
        <InspectConcessSubMenu
          onChangeStatus={onChangeStatus}
          view='ConcessionMissions'
          inspectionsOrMissions={props.allMissions.missions}
          role={props.application?.user?.role === 'reseller'}
        />
        <div className='concession-header concessionsMissions'>
          <div className='action-bar'>
            <div
              data-test-selector='newMissions'
              className='item'
              onClick={() => {
                props.dispatch(getActionToDispatch({ type: 'SHOW_MODAL_NEW_MISSION' }));
                props.dispatch(getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'createMission' }));
              }}
            >
              <i className='fas fa-plus-circle' />
              <Text fontStyle='normal'>Commander une mission</Text>
            </div>
          </div>
        </div>

        <ActionsBar
          ref={inputQuickFilterRef}
          selectedRows={selectedRows}
          onClickRefresh={onClickRefresh}
          onClickExportExcel={onClickExportExcel}
          onQuickFilterChange={onQuickFilterChange}
          interfaceView='concessionInterface'
          onSelectMonthsLoadingInspections={onSelectedMonths}
          loadingInspectionsByMonths={monthsSelected}
          numberOfMonthsSelected={numberOfMonthsSelected}
        />
        <div className='ag-theme-balham'>
          {props.allMissions.missions ? (
            <AgGridReact {...createMissionsOptions(props.application?.user?.role)} rowData={props.allMissions.missions} onGridReady={onGridReady} onColumnVisible={resizeGrid} onToolPanelVisibleChanged={resizeGrid} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

Missions.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  updatedAt: PropTypes.number,
  allMissions: PropTypes.object,
  concessions: PropTypes.object,
  numberOfMonthsSelectedMissions: PropTypes.number,
};

const mapStateToProps = (state) => ({
  application: state.application,
  updatedAt: state.missions.lastUpdatedAt,
  allMissions: state.missions,
  numberOfMonthsSelectedMissions: state.missions.numberOfMonthsSelected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getConcessionsMissions,
  updateNumberOfMonthsSelectedMissions,
});

export default connect(mapStateToProps, mapDispatchToProps)(Missions);
