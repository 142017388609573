/* eslint-disable no-unused-vars */
import React from 'react';
import { DatePicker, Space } from 'antd';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

const DateTimePicker = ({
  // eslint-disable-next-line react/prop-types
  selected, onChange, dateFormat, timeIntervals,
}) => (<DatePicker
                        value={selected}
                        onChange={onChange}
                        dateFormat={dateFormat}
                        showTime
                        format='DD/MM/yy HH:mm'
                        minuteStep={timeIntervals}
                        locale={locale}
                      />);

export default DateTimePicker;
