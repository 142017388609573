import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { read, utils } from 'xlsx';
import { updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import InspectionsServices from '../../../services/InspectionsServices';
import CguCheckBox from '../atoms/CguCheckBox';
import ConcessionsAndReportsSelector from '../molecules/ConcessionsAndReportsSelector';

Modal.setAppElement('body');

const ModalImportSpreadsheet = (props) => {
  const [cguAccepted, setCguAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [concessionSelected, setConcessionSelected] = useState('');
  const [reportId, setReportId] = useState('');
  const [loading, setLoading] = useState(false);
  const [dropInfo, setDropInfo] = useState({});

  const sendSpreadsheet = async () => {
    const role = _.get(JSON.parse(localStorage.getItem('user')), 'role');
    if ((role === 'superChief' || role === 'chief') && !concessionSelected) {
      setErrorMessage('Veuillez sélectionner un site');
      return;
    }

    setLoading(true);
    const { status, body } = await InspectionsServices.importSpreadsheet({
      concessionId: concessionSelected,
      reportId,
      worksheet: dropInfo.worksheet,
    });

    if (status !== 200) {
      if (body.error) {
        setErrorMessage(body.error);
        setLoading(false);
        setDropInfo({
          ...dropInfo,
          isNewfile: false,
        });
      } else {
        setErrorMessage('Une erreur est survenue');
      }
    } else {
      updatePlannedInspections(props.dispatch, props.lastUpdate);
      props.closeModal();
    }
  };

  const onDropFile = (files) => {
    if (files.length !== 1) {
      setErrorMessage("Veuillez n'uploader qu'un seul fichier à la fois");
      return;
    }
    setDropInfo({
      ...dropInfo,
      file: null,
      worksheet: null,
      step: 'waitToSend',
    });
    const file = files[0];
    const authorizedExtensions = ['.csv', '.txt', '.xlsx', '.xls'];
    const isExtentionCorrect = authorizedExtensions.some((extention) => file.name.endsWith(extention));
    if (!isExtentionCorrect) {
      setErrorMessage("Ce format de fichier n'est pas supporté");
      setDropInfo({ file: null, worksheet: null });
      return;
    }
    const osUnit = navigator.appVersion.indexOf('Win') > -1 ? 1024 : 1000;
    if (file.size > 10 * osUnit * osUnit) {
      setDropInfo({ ...dropInfo, file: null, worksheet: null });
      setErrorMessage('La taille du fichier est trop élevée : 10 Mo maxi');
      return;
    }
    let isBinary = false;
    if (['.xlsx', '.xls'].some((extention) => file.name.endsWith(extention))) {
      isBinary = true;
    }

    const reader = new FileReader();

    reader.onerror = () => {
      setDropInfo({ ...dropInfo, file: null, worksheet: null });
      setErrorMessage("Une erreur s'est produite");
    };

    reader.onabort = () => {
      setDropInfo({ ...dropInfo, file: null, worksheet: null });
      setErrorMessage("Une erreur s'est produite");
    };

    reader.onload = async (e) => {
      let workbook;
      let parsedData;
      const data = e.target.result;

      try {
        workbook = read(data, { type: isBinary ? 'binary' : 'string' });
      } catch (error) {
        setDropInfo({ ...dropInfo, file: null, worksheet: null });
        setErrorMessage('Le fichier semble illisible');
        return;
      }

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      try {
        parsedData = utils.sheet_to_json(worksheet, { header: 1 });
      } catch (error) {
        setDropInfo({ ...dropInfo, file: null, worksheet: null });
        setErrorMessage("Une erreur s'est produite");
      }

      setDropInfo({
        file,
        ...dropInfo,
        isNewFile: true,
        worksheet: parsedData,
      });
    };

    if (isBinary) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file);
    }
  };

  const reportChange = () => (report) => {
    setReportId(report);
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={true}
      onRequestClose={props.closeModal}
      contentLabel='Modal annuler inspection'
      overlayClassName='modal-overlay'
      className='modal modalImportSpreadsheet'
    >
      <div className='modalContent'>
        <div className='mainTitle'>Importer un fichier d'inspections</div>
        <form className='mt10px'>
          <div className='formInput'>
            <p>
              Vous pouvez créer plusieurs inspections en une seule fois en important un CSV ou un fichier Excel.
              <br />
              Les formats pris en charge sont : .csv, .xls, .xlsx.
              <br />
              Veillez à ce que votre tableur respecte les règles de formatage :{' '}
              <a href='https://msa-pdf-templates.s3.eu-west-3.amazonaws.com/MSA_Notice_import_Excel_CSV.pdf'>MSA_Notice_import_Excel_CSV.pdf</a>.<br />
              Si besoin vous pouvez utiliser le modèle de fichier suivant :{' '}
              <a href='https://msa-pdf-templates.s3.eu-west-3.amazonaws.com/TemplateImportExcel.xlsx'>TemplateImportExcel.xlsx</a>.
            </p>
          </div>
          <ConcessionsAndReportsSelector concessionIdChange={setConcessionSelected} reportIdChange={reportChange} />
          <Dropzone onDrop={onDropFile} disabled={loading}>
            {({ getRootProps, getInputProps }) => (
              <div data-cy='importSpreadsheetZone' className='importSpreadsheet' {...getRootProps()}>
                <input {...getInputProps()} />
                <i className='fas fa-file-upload' />
              </div>
            )}
          </Dropzone>
          {dropInfo.file && (
            <div className='center'>
              <p> {dropInfo.file.name} </p>
            </div>
          )}
          {errorMessage && (
            <div className='error'>
              <p> {errorMessage} </p>
            </div>
          )}
          <CguCheckBox cguAccepted={cguAccepted} setCguAccepted={setCguAccepted} />
          <div className='center formButton'>
            <button type='button' onClick={props.closeModal} className='negativ'>
              Fermer
            </button>
            {!loading && (
              <button
                data-cy='sendTheFile'
                type='button'
                onClick={sendSpreadsheet}
                className={!dropInfo.file || !dropInfo.isNewFile || !cguAccepted ? 'positiv--disabled' : 'positiv'}
                disabled={!dropInfo.file || !dropInfo.isNewFile || !cguAccepted}
              >
                Envoyer le fichier
              </button>
            )}
            {loading && (
              <button type='button' disabled className='positiv'>
                <i className='fa fa-spinner fa-spin'></i>Chargement
              </button>
            )}
            {dropInfo.step === 'sent' && <span style={{ fontSize: '14px', color: '#1f5288' }}>Inspections créées !</span>}
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = (state) => ({
  lastUpdate: state.plannedInspections.lastUpdatedAt,
});

ModalImportSpreadsheet.propTypes = {
  dispatch: PropTypes.func,
  lastUpdate: PropTypes.number,
  closeModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalImportSpreadsheet);
