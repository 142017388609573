import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ConcessionsActions from '../../../actions/Concessions';
import { getCountries } from '../../../actions/CountriesActions';
import NavigationActions from '../../../actions/NavigationActions';
import ModalCreateEditViewSite from '../../shared/organisms/ModalCreateEditViewSite';
import NavBar from '../../shared/templates/NavBar';
import Table from '../shared/templates/Table';
import { createConcessionOptions } from './atoms/gridOptions';

class Concessions extends React.Component {
  state = {};

  componentDidMount() {
    const { dispatch, application } = this.props;

    this.setState({ user: application.user });
    this.props.dispatch(NavigationActions.setNavigation({ navigation: 'concessions' }));
    dispatch(
      ConcessionsActions.getActionToDispatch({
        type: 'UPDATE_ROLE',
        role: 'reseller',
      }),
    );
    dispatch(ConcessionsActions.getActionToDispatch({ type: 'UPDATE_SEARCH', search: '' }));
    this.props.dispatch(getCountries);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(ConcessionsActions.getActionToDispatch({ type: 'UPDATE_SEARCH', search: '' }));
  }

  renderModals = () => {
    const { dispatch, application, concessions } = this.props;
    const myProps = {
      dispatch,
      application,
      concessions,
    };
    const { modalName } = concessions;

    switch (modalName) {
      case 'createConcession':
        return <ModalCreateEditViewSite {...myProps} />;
      case 'viewConcession':
        return <ModalCreateEditViewSite {...myProps} />;
      case 'editConcession':
        return <ModalCreateEditViewSite {...myProps} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div>
        {this.renderModals()}
        <NavBar />
        <div className='concession mtp'>
          <Table options={createConcessionOptions()} table={'concessions'} />
        </div>
      </div>
    );
  }
}

Concessions.defaultProps = {};

const mapStateToProps = (state) => ({
  application: state.application,
  concessions: state.concessions,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getCountries,
});

Concessions.propTypes = {
  dispatch: PropTypes.func,
  concessions: PropTypes.object,
  application: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Concessions);
