import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../../shared/atoms/Loading';
import Users from './Users';

export default class UsersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      users: [],
      isActive: true,
    };
  }

  componentDidMount() {
    this.updateUsersFromParentComponent(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateUsersFromParentComponent(this.props);
    }
  }

  updateUsersFromParentComponent() {
    const {
      admin: {
        users,
        usersFilters: { isActive },
      },
    } = this.props;
    const usersAfterFiltered = users.filter((user) => user.isActive === isActive);
    this.setState({ users: usersAfterFiltered, isActive });
  }

  renderHeader() {
    if (!this.props.type) {
      return null;
    }
    switch (this.props.type) {
      case 'admin': {
        return (
          <div
            className='userHeader'
            style={{
              display: 'table',
            }}>
            <div className='id'>Id</div>
            <div className='date'>Créé le</div>
            <div className='name'>Nom</div>
            <div className='email'>Email</div>
            <div className='phone'>Téléphone</div>
            <div className='actions'>Actions</div>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }

  render() {
    if (this.props.admin.loadingUsers) {
      return <Loading />;
    }
    if (this.state.users && this.state.users.length > 0) {
      return (
        <React.Fragment>
          {this.renderHeader()}
          <ul className='usersContainerList'>
            {this.state.users.map((user) => (
              <Users key={user._id} {...user} application={this.props.application} dispatch={this.props.dispatch} admin={this.props.admin} />
            ))}
          </ul>
        </React.Fragment>
      );
    }
    if (!this.state.users || this.state.users.length === 0) {
      return (
        <ul className='usersContainerList'>
          <li className='noUsers'>
            <div>Aucun utilisateur n'a été trouvé.</div>
          </li>
        </ul>
      );
    }
    return null;
  }
}

UsersContainer.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  admin: PropTypes.object,
  downloadUser: PropTypes.func,
  type: PropTypes.string,
};
