import fr from 'date-fns/locale/fr';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import constants from '../../../constants';
import RenderError from '../../shared/atoms/RenderError';
import Text from '../../shared/atoms/Text';

const WeeklyCalendar = (props) => {
  const typicalWeek = [];

  for (let i = 1; i <= 7; i += 1) {
    typicalWeek.push({
      label: moment().day(i).format('ddd'),
      value: moment().day(i).format('dddd'),
    });
  }

  const convertSelectedDateTimeToMs = (selectedDateTime) => moment(0).hours(moment(selectedDateTime).hour()).minutes(moment(selectedDateTime).minutes()).valueOf();

  const [weeklySchedule, setWeeklySchedule] = useState(props.workingWeek);
  const [error, setError] = useState();

  const [cloneState, setCloneState] = useState(false);
  const [cloneIndex, setCloneIndex] = useState(undefined);

  const disabledDayLoop = [];
  _.map(props.workingWeek, (day) => (day.start === 1 && day.end === 2 ? disabledDayLoop.push(true) : disabledDayLoop.push(false)));

  const [disabledDay, setDisabledDay] = useState(disabledDayLoop);

  useEffect(() => {
    props.schedule({ ...weeklySchedule });
  }, [weeklySchedule]);

  function handleCloneButton() {
    if (
      weeklySchedule[cloneIndex].start === 1
      || Number.isNaN(weeklySchedule[cloneIndex].start)
      || weeklySchedule[cloneIndex].end === 2
      || Number.isNaN(weeklySchedule[cloneIndex].end)
    ) {
      setError('Veuillez sélectionner une heure de début et de fin avant de cloner les horaires');
    } else {
      setError(null);
    }

    for (let j = 0; j < 7; j += 1) {
      if (!disabledDay[j]) {
        weeklySchedule[j] = {
          start: weeklySchedule[cloneIndex].start,
          end: weeklySchedule[cloneIndex].end,
        };
        setWeeklySchedule(weeklySchedule);
      }
    }
    props.schedule(weeklySchedule);
    setCloneState(false);
  }

  useEffect(() => {
    if (cloneState === true) {
      handleCloneButton();
    }
  }, [cloneState]);

  return (
    <div className='weekly-calendar-container'>
      {error && <RenderError error={error} />}
      {typicalWeek.map((typicalWeekDay, i) => (
        <div key={typicalWeekDay.value} className='formInput weekly-calendar'>
          <div className='weekly-calendar-inputs'>
            <input
              type='checkbox'
              defaultChecked={!disabledDay[i]}
              value={typicalWeekDay.value}
              onClick={(e) => {
                setDisabledDay({ ...disabledDay, [i]: !e.target.checked });
                setWeeklySchedule({ ...weeklySchedule, [i]: { start: 1, end: 2 } });
              }}
            />
            <label className='weekly-calendar-labels'>{typicalWeekDay.label}</label>

            <DatePicker
              selected={weeklySchedule[i]?.start === 1 || disabledDay[i] ? '' : weeklySchedule[i]?.start}
              onChange={(selectedTime) => setWeeklySchedule({ ...weeklySchedule, [i]: { ...weeklySchedule[i], start: convertSelectedDateTimeToMs(selectedTime) } })}
              showTimeSelect
              showTimeSelectOnly
              minDate={moment()}
              timeIntervals={5}
              timeCaption='Début'
              dateFormat={constants.formatHourAppointment}
              locale={fr}
              disabled={disabledDay[i]}
              className='mission-input'
              calendarClassName='mission-input'
            />
            <Text style={{ margin: '10px' }}> à </Text>

            <DatePicker
              selected={weeklySchedule[i]?.end === 2 || disabledDay[i] ? '' : weeklySchedule[i]?.end}
              onChange={(selectedTime) => setWeeklySchedule({ ...weeklySchedule, [i]: { ...weeklySchedule[i], end: convertSelectedDateTimeToMs(selectedTime) } })}
              showTimeSelect
              showTimeSelectOnly
              minDate={moment()}
              timeIntervals={5}
              timeCaption='Fin'
              dateFormat={constants.formatHourAppointment}
              locale={fr}
              disabled={disabledDay[i]}
              className='mission-input'
              calendarClassName='mission-input'
            />
            <div
              style={{ margin: '10px', fontSize: 'x-large' }}
              title={'Cloner les horaires'}
              onClick={() => {
                setCloneState(true);
                setCloneIndex(i);
              }}
            >
              {!disabledDay[i] && <i className={'far fa-clone hover-icon'} />}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

WeeklyCalendar.propTypes = {
  workingWeek: PropTypes.object,
  modalName: PropTypes.string,
  schedule: PropTypes.func,
};

export default WeeklyCalendar;
