/* eslint-disable no-plusplus, no-unused-vars, no-restricted-syntax */
import { Empty, Select, Spin } from 'antd';
import produce from 'immer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import ReportsActions from '../../../actions/ReportsV3Actions';
import constants from '../../../constants';
import ReportsServices from '../../../services/ReportsService';
import Text from '../../shared/atoms/Text';
import PriceTable from './components/priceTable';
import Notifications, { notify } from './components/toast/notify';
import FRESearchBar from './FRESearchBar';
import SideMenuTreeView from './SideMenuTreeView';

class Index extends React.Component {
  state = {
    active: true,
    model: [], // model used in the tree view (right-side) menu
    treeViewSelection: [], // item selected from the right-side the menu
    selection: {}, // repair-cost loaded from selected item
    repairCosts: {}, // all repair-costs from API corresponding the entire model
    selectedReports: '', // string corresponding the selected reports from the select-tree
    selectedReportsGlobal: [], // array corresponding to the selected reports from the select-tree
    selectedReport: '', // selected report from select menu on "per report" mode
    groups: [], // all groups / entities
    treeSelect: [], // select input item (in tree view mode) with concessions and reports
    selectedGroup: '', // current selected group / entity
    bySiteByReport: false, // edit mode: global or per report
    sites: [],
    selectedSite: '',
    reports: [],
    checkpointsCategories: [],
    loadingMenu: false,
    loadingFRE: false,
    selectedMsaGlobalScope: '',
    globalMode: false,
  };

  // Todo: change harded coded _id
  componentDidMount() {
    // Get list of all Groups / Entities if user is admin
    const isAdmin = this.props.user && this.props.user.isAdmin;

    if (isAdmin === true) {
      ReportsServices.getAll(`${constants.URI.v3.entities.index}`).then((res) => this.setState({ groups: _.get(res, 'body') }));

      ReportsServices.getAll(`${constants.URI.v3.cpConstants}`).then((res) => {
        const mappedCat = {};
        res.category.forEach((catCheckpoint) => {
          mappedCat[catCheckpoint._id] = { id: catCheckpoint._id, label: catCheckpoint.label };
        });
        this.setState({ checkpointsCategories: mappedCat });
      });
    } else {
      const selectedGroup = _.get(this.props.user, 'concession.entity._id');

      this.setState({ bySiteByReport: false, selectedGroup }, () => {
        this.getSitesOfGroup();
        this.buildTreeSelect();
      });
    }
  }

  buildTreeSelect = async () => {
    const { selectedGroup } = this.state;
    // const isAdmin = this.props.user && this.props.user.isAdmin;
    // if (isAdmin === true) {
    const res = await ReportsServices.getAll(`${constants.URI.v3.concessions.index}`, ``);
    const concessionsOfGroup = _.filter(_.get(res, 'concessions.items'), { entity: { _id: selectedGroup } });
    const promises = _.map(concessionsOfGroup, (concession) => {
      const _id = _.get(concession, '_id');
      return ReportsServices.getAll(`${constants.URI.v3.global}concessions/${_id}/reports`, ``);
    });
    const allReports = await Promise.all(promises);
    const treeSelect = _.map(concessionsOfGroup, (concession, i) => {
      const _id = _.get(concession, '_id');
      const reports = allReports[i];
      const treeObj = {
        key: _id,
        value: _id,
        title: _.get(concession, 'name'),
        children: _.map(reports, (report) => ({
          title: report.title,
          value: `reportIds[${_id}]=${report._id}`,
          key: `reportIds[${_id}]=${report._id}`,
          concession: _id,
        })),
      };
      return treeObj;
    });
    this.setState({ treeSelect });
  };

  handleSelectReportsGlobal = async (values) => {
    this.setState({ selectedReportsGlobal: values });
    this.setState({ selection: {} });
    this.setState({ loadingMenu: true });
    let reports = '';
    _.forEach(values, (value) => {
      reports = reports.concat(value);
      reports = reports.concat('&');
    });

    this.setState({ selectedReports: reports });
    const selectedGroup = this.state.selectedGroup || _.get(this.props.user, 'concession.entity._id');
    console.log(this.props.user);
    console.log(selectedGroup);
    const url = `${constants.URI.v3.global}entities/${selectedGroup}/repair-cost`;
    const repairCosts = await ReportsServices.getAll(url, reports);
    this.setState({ repairCosts }, async () => {
      await this.buildMenuModelGlobal(selectedGroup, reports, repairCosts);
      this.setState({ loadingMenu: false });
    });
  };

  handleSelectReport = async (value) => {
    this.setState({ selectedReport: value });
    this.setState({ selection: {} });
    this.setState({ loadingMenu: true });
    Promise.resolve()
      .then(async () => {
        const selectedReport = `reportIds[${this.state.selectedSite}]=${this.state.selectedReport}`;
        const selectedGroup = this.state.selectedGroup || _.get(this.props.user, 'concession.entity._id');
        const repairCosts = await ReportsServices.getAll(`${constants.URI.v3.global}entities/${selectedGroup}/repair-cost`, selectedReport);
        this.setState({ repairCosts });
      })
      .then(() => this.props.dispatch(ReportsActions.executeAction('oneReport', value)))
      .then(() => this.props.dispatch(ReportsActions.executeAction('defectsByReport', value)))
      .then(() => {
        this.buildMenuModel();
      })
      .then(() => this.setState({ loadingMenu: false }));
  };

  handleGlobalMsaScopeSelect = async (msaGlobalRenderScope) => {
    this.setState({ selectedMsaGlobalScope: msaGlobalRenderScope });
    this.setState({ selection: {} });
    this.setState({ loadingMenu: true });
    await this.buildMenuModelGlobalMsa(msaGlobalRenderScope);
    this.setState({ loadingMenu: false });
  };

  handleGlobalMsa = async () => {
    this.setState({ selection: {} });
    this.setState({ loadingMenu: true });
    Promise.resolve()
      .then(async () => {
        const repairCosts = await ReportsServices.getAll(`${constants.URI.v3.repairCost}`);
        this.setState({ repairCosts });
      })
      .then(() => this.setState({ loadingMenu: false }));
  };

  getSitesOfGroup = async () => {
    const res = await ReportsServices.getAll(`${constants.URI.v3.global}concessions`, ``);
    const sitesOfGroup = res?.concessions?.items.filter((concession) => String(this.state.selectedGroup) === String(concession.entity?._id));
    const sites = _.map(sitesOfGroup, (site) => {
      const _id = _.get(site, '_id');
      return {
        key: _id,
        value: _id,
        title: _.get(site, 'name'),
      };
    });
    this.setState({ sites });
  };

  getReportsOfSite = async () => {
    const res = await ReportsServices.getAll(`${constants.URI.v3.global}concessions/${this.state.selectedSite}/reports`, ``);
    const reports = _.map(res, (report) => {
      const _id = _.get(report, '_id');
      return {
        key: _id,
        value: _id,
        title: _.get(report, 'title'),
      };
    });
    this.setState({ reports });
  };

  cleanInterface = () =>
    Promise.resolve()
      .then(() => this.setState({ model: [] }))
      .then(() => this.setState({ selectedReport: '' }))
      .then(() => this.setState({ selectedReports: [] }))
      .then(() => this.setState({ reports: [] }))
      .then(() => this.setState({ selection: {} }))
      .then(() => this.setState({ selectedReportsGlobal: [] }));

  handleSelectGroup = (selectedGroup) => {
    Promise.resolve()
      .then(() => this.cleanInterface())
      .then(() => this.setState({ selectedSite: '' }))
      .then(() => this.setState({ sites: [] }))
      .then(() => this.setState({ selectedGroup }))
      .then(() => {
        if (this.state.bySiteByReport === true) {
          this.buildTreeSelect();
        } else {
          this.getSitesOfGroup();
        }
      });
  };

  handleSelectSite = (selectedSite) => {
    Promise.resolve()
      .then(() => this.cleanInterface())
      .then(() => this.setState({ selectedSite }))
      .then(() => this.getReportsOfSite());
  };

  buildMenuModelGlobalMsa = (msaGlobalRenderScope) => {
    const base = _.get(this.state.repairCosts, msaGlobalRenderScope);
    this.setState({ loadingMenu: true });
    const model = [];

    if (msaGlobalRenderScope === 'CheckpointFRE') {
      for (const [checkpointCatId, checkpointCatSectionn] of Object.entries(base)) {
        const foundCat = this.state.checkpointsCategories[checkpointCatId] || {};
        const checkpointCatSection = {
          id: checkpointCatId,
          label: foundCat.label,
          items: [],
        };
        for (const [checkpointRefId, checkpointSectionn] of Object.entries(checkpointCatSectionn)) {
          const checkpointSection = { id: checkpointRefId, label: null, repairCosts: [] }; // name of the checkpoint : etat des roues
          for (const [kindRepairId, kindRepairSectionn] of Object.entries(checkpointSectionn)) {
            const kindRepairSection = { id: kindRepairId, label: null, infoRepair: [] }; // name of the defect CASSE
            for (const [kindVehicleId, kindVehicleSection] of Object.entries(kindRepairSectionn)) {
              // get the true label for the checkpoint section
              if (!checkpointSection.label) {
                checkpointSection.label = _.get(kindVehicleSection, 'checkpoint.refCheckpoint.label');
              }

              // get the true label for the repairSection
              if (!kindRepairSection.label) {
                kindRepairSection.label = _.get(kindVehicleSection, 'checkpoint.labelRc');
              }
              kindRepairSection.infoRepair.push({ id: kindVehicleId, label: _.get(kindVehicleSection, 'kindVehicle.label'), infoRepair: kindVehicleSection }); // kind vehicle as label and checkpoints as value
            }
            checkpointSection.repairCosts.push(kindRepairSection);
          }
          checkpointCatSection.items.push(checkpointSection);
        }
        model.push(checkpointCatSection);
      }
    } else if (msaGlobalRenderScope === 'DefectFRE') {
      for (const [checkpointRefId, checkpointSectionn] of Object.entries(base)) {
        const checkpointSection = {
          id: checkpointRefId,
          label: null,
          items: [],
        };

        for (const [kindRepairId, kindRepairSectionn] of Object.entries(checkpointSectionn)) {
          const kindRepairSection = { id: kindRepairId, label: null, items: [] };
          for (const [defectSecId, defectSecSectionn] of Object.entries(kindRepairSectionn)) {
            const defectSecSection = { id: defectSecId, label: null, repairCosts: [] };
            defectSecSection.repairCosts.push({ id: defectSecId, label: null, infoRepair: [] });
            for (const [kindVehicleId, kindVehicleSection] of Object.entries(defectSecSectionn)) {
              // get the true label for checkpointSection
              if (!checkpointSection.label) {
                checkpointSection.label = _.get(kindVehicleSection, 'defect.partVehicle.label');
              }

              // get the true label for kindRepairSection
              if (!kindRepairSection.label) {
                kindRepairSection.label = _.get(kindVehicleSection, 'defect.category.label');
              }

              // get the true label for defectSecSection
              if (!defectSecSection.label) {
                defectSecSection.label = _.get(kindVehicleSection, 'defect.valueDefect.label');
              }

              // get the true label for valueDefect
              if (!defectSecSection.repairCosts[0].label) {
                defectSecSection.repairCosts[0].label = _.get(kindVehicleSection, 'defect.valueDefect.label');
              }

              defectSecSection.repairCosts[0].infoRepair.push({ id: kindVehicleId, label: _.get(kindVehicleSection, 'kindVehicle.label'), infoRepair: kindVehicleSection });
            }
            kindRepairSection.items.push(defectSecSection);
          }
          checkpointSection.items.push(kindRepairSection);
        }
        model.push(checkpointSection);
      }
    }

    this.setState({ model });
    this.setState({ loadingMenu: false });
  };

  buildMenuModel = () => {
    const fullReport = this.props.reportsV3.modalData;
    const defectFRE = _.get(this.state.repairCosts, 'DefectFRE');
    const { defectsByReport } = this.props.reportsV3;
    this.setState({ loadingMenu: true });
    // Function that gets Model for Each Section of the Report
    const getSectionModel = (section, sectionIndex, isExtraField = false, extraFieldIndex) => {
      const items = [];
      let checkpointIndex = 0;
      _.forEach(section.checkpoints, (cp) => {
        // If checkpoint has an addFieldContent, flatten the checkpoints and run this function recursively
        const hasAddFieldContent = _.get(cp, 'refCheckpoint.details.addFieldContent', []);
        if (hasAddFieldContent.length > 0) {
          const addFieldSection = {
            checkpoints: _.map(hasAddFieldContent, (obj) => ({ refCheckpoint: obj })),
          };
          getSectionModel(addFieldSection, sectionIndex, true, checkpointIndex).forEach((obj) => items.push(obj));
        }
        // Only pick checkpoints which are explicity marked with isDefect
        if (_.get(cp, 'refCheckpoint.isDefect')) {
          const newCheckpoint = {
            label: _.get(cp, 'refCheckpoint.label'),
            id: _.get(cp, 'refCheckpoint._id'),
            isInfo: _.get(cp, 'refCheckpoint.isInfo'),
            details: _.get(cp, 'refCheckpoint.details'),
            sectionIndex,
            checkpointIndex,
          };
          items.push(newCheckpoint);
        }
        // Pick checkpoints of type Photo
        if (_.get(cp, 'refCheckpoint.kind') === 'PhotoCheckpoint') {
          let photoLabel = '';
          if (isExtraField) {
            photoLabel = _.get(fullReport.sections, `[${sectionIndex}].checkpoints[${extraFieldIndex}][refCheckpoint][details][addFieldContent][${checkpointIndex}]label`);
          } else {
            photoLabel = _.get(fullReport.sections, `[${sectionIndex}].checkpoints[${checkpointIndex}][refCheckpoint]label`);
          }
          const newCheckpoint = {
            label: photoLabel,
            id: _.get(cp, 'refCheckpoint._id'),
            isInfo: _.get(cp, 'refCheckpoint.isInfo'),
            details: _.get(cp, 'refCheckpoint.details'),
            sectionIndex,
            checkpointIndex,
            items: [],
          };
          const defectPartVehicle = _.get(cp, 'refCheckpoint.details.defectPartVehicle');
          if (defectPartVehicle && defectFRE) {
            const refItemsLevel1 = defectFRE[defectPartVehicle];
            _.mapKeys(refItemsLevel1, (refItemsLevel2, key) => {
              const labelLevel1 = _.get(defectsByReport, `[${defectPartVehicle}][categories][${key}]label`);
              const newItem = {
                label: labelLevel1,
                items: [],
              };
              _.mapKeys(refItemsLevel2, (theItem, key2) => {
                const labelLevel2 = _.get(defectsByReport, `[${defectPartVehicle}][categories][${key}][defects][${key2}]label`);
                const newItem2 = {
                  label: labelLevel2, // key2
                  fullLabel: `${labelLevel1} - ${labelLevel2}`,
                  refPhotoFilter: defectPartVehicle,
                  refPartVehicle: key,
                  refDefect: key2,
                  isPhotoDefect: true,
                };
                newItem.items.push(newItem2);
              });
              newCheckpoint.items.push(newItem);
            });
            items.push(newCheckpoint);
          }
        }
        checkpointIndex += 1;
      });
      return items;
    };
    // Build Model for All Sections
    if (fullReport) {
      const model = [];
      _.forEach(fullReport.sections, (section, sectionIndex) => {
        const items = getSectionModel(section, sectionIndex);
        const newSection = {
          label: _.get(section, 'sectionName'),
          items,
        };
        if (items.length > 0) model.push(newSection);
      });
      this.setState({ model });
      this.setState({ loadingMenu: false });
    }
  };

  buildMenuModelGlobal = async (group, reports, repairCosts) => {
    const categories = _.get(this.props.reportsV3, 'constants.category');
    const checkpoints = await ReportsServices.getAll(`${constants.URI.v3.global}entities/${group}/checkpoints`, reports);
    const defectFRE = _.get(repairCosts, 'DefectFRE');
    const defectsByReport = await ReportsServices.getAll(`${constants.URI.v3.global}entities/${group}/defects`, reports);
    if (categories && checkpoints) {
      const model = [];
      _.forEach(categories, (category, sectionIndex) => {
        const items = [];
        const categoryId = _.get(category, '_id');
        const categoryCheckpoints = _.get(checkpoints, `${categoryId}`);
        _.forEach(categoryCheckpoints, (cp, checkpointIndex) => {
          // Only pick checkpoints which are explicity marked with isDefect
          if (_.get(cp, 'isDefect')) {
            const newCheckpoint = {
              label: _.get(cp, 'label'),
              id: _.get(cp, '_id'),
              isInfo: _.get(cp, 'isInfo'),
              details: _.get(cp, 'details'),
              sectionIndex,
              checkpointIndex,
            };
            items.push(newCheckpoint);
          }
          // Pick checkpoints of type Photo
          if (_.get(cp, 'kind') === 'PhotoCheckpoint') {
            const newCheckpoint = {
              label: _.get(cp, 'label'),
              id: _.get(cp, '_id'),
              isInfo: _.get(cp, 'isInfo'),
              details: _.get(cp, 'details'),
              sectionIndex,
              checkpointIndex,
              items: [],
            };
            const defectPartVehicle = _.get(cp, 'details.defectPartVehicle');
            if (defectPartVehicle && defectFRE) {
              const refItemsLevel1 = defectFRE[defectPartVehicle];
              _.mapKeys(refItemsLevel1, (refItemsLevel2, key) => {
                const labelLevel1 = _.get(defectsByReport, `[${defectPartVehicle}][categories][${key}]label`);
                const newItem = {
                  label: labelLevel1,
                  items: [],
                };
                _.mapKeys(refItemsLevel2, (theItem, key2) => {
                  const labelLevel2 = _.get(defectsByReport, `[${defectPartVehicle}][categories][${key}][defects][${key2}]label`);
                  const newItem2 = {
                    label: labelLevel2, // key2
                    fullLabel: `${labelLevel1} - ${labelLevel2}`,
                    refPhotoFilter: defectPartVehicle,
                    refPartVehicle: key,
                    refDefect: key2,
                    isPhotoDefect: true,
                  };
                  newItem.items.push(newItem2);
                });
                newCheckpoint.items.push(newItem);
              });
              items.push(newCheckpoint);
            }
          }
        });
        const newSection = {
          label: _.get(category, 'label'),
          items,
        };
        if (items.length > 0) model.push(newSection);
      });
      this.setState({ model });
    }
  };

  /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draftState"] }] */
  handlePriceUpdate = (entity, kindVehicle, e, isPrivate = false, isInterval = false) => {
    const price = parseInt(e.target.value, 10) || '0';
    const nextState = produce(this.state.selection, (draftState) => {
      if (isInterval) {
        if (isPrivate) draftState[entity][kindVehicle].privateCostMax = price;
        else draftState[entity][kindVehicle].publicCostMax = price;
      } else if (isPrivate) draftState[entity][kindVehicle].privateCost = price;
      else draftState[entity][kindVehicle].publicCost = price;
    });
    this.setState({ selection: nextState }, () => {
      this.sendStateToAPI();
    });
  };

  handleCloneCategoryGlobalMsa = (name, id) => {
    const nextState = this.state.selection;
    const repairCostsToUpdate = [];

    nextState.repairCosts.forEach((repairCost) => {
      if (repairCost.id === name && repairCost.infoRepair) {
        const baseToClone = repairCost.infoRepair.find((vehicleId) => vehicleId.id === id);
        const newInfoRepair = {
          privateCost: baseToClone.infoRepair.privateCost,
          publicCost: baseToClone.infoRepair.publicCost,
        };
        const newInfoRepairPayload = _.cloneDeep(newInfoRepair);

        if (baseToClone.infoRepair.privateCostMax) {
          newInfoRepair.privateCostMax = baseToClone.infoRepair.privateCostMax;
          newInfoRepairPayload.privateCostMax = baseToClone.infoRepair.privateCostMax;
        } else {
          delete newInfoRepair.privateCostMax;
          newInfoRepairPayload.privateCostMax = -1;
        }

        if (baseToClone.infoRepair.publicCostMax) {
          newInfoRepair.publicCostMax = baseToClone.infoRepair.publicCostMax;
          newInfoRepairPayload.publicCostMax = baseToClone.infoRepair.publicCostMax;
        } else {
          delete newInfoRepair.publicCostMax;
          newInfoRepairPayload.publicCostMax = -1;
        }

        repairCost.infoRepair.forEach((perVehicle) => {
          // eslint-disable-next-line no-param-reassign
          perVehicle.infoRepair = { ...perVehicle.infoRepair, ...newInfoRepair };
          repairCostsToUpdate.push({ _id: perVehicle.infoRepair._id, ...newInfoRepairPayload });
        });
      }
    });
    this.setState({ selection: nextState }, () => {
      this.updateGlobalMsaRepairCost(repairCostsToUpdate);
    });
  };

  /* eslint no-param-reassign: */
  handlePriceUpdateGlobalMsa = (e, id, kindVehicle, globalMsaRepairCostsInfo, isPrivate = false, isInterval = false) => {
    const nextState = _.cloneDeep(this.state.selection);
    const repairCostsToUpdate = [];
    const price = parseInt(e.target.value, 10) || '0';

    nextState.repairCosts.forEach((repairCost) => {
      if (repairCost.id === id.defect && repairCost.infoRepair) {
        repairCost.infoRepair.forEach((perVehicle) => {
          if (perVehicle.id === kindVehicle && perVehicle.infoRepair) {
            let priceToUpdate = {};
            if (isPrivate) {
              if (isInterval) {
                perVehicle.infoRepair.privateCostMax = price;
                priceToUpdate = { privateCostMax: price };
              } else {
                perVehicle.infoRepair.privateCost = price;
                priceToUpdate = { privateCost: price };
              }
            } else if (isInterval) {
              perVehicle.infoRepair.publicCostMax = price;
              priceToUpdate = { publicCostMax: price };
            } else {
              perVehicle.infoRepair.publicCost = price;
              priceToUpdate = { publicCost: price };
            }
            repairCostsToUpdate.push({ _id: perVehicle.infoRepair._id, ...priceToUpdate });
          }
        });
      }
    });

    this.setState({ selection: nextState }, () => {
      this.updateGlobalMsaRepairCost(repairCostsToUpdate);
    });
  };

  handleCloneCategory = (entity, kindVehicle) => {
    const nextState = produce(this.state.selection, (draftState) => {
      const refPublicCost = _.get(draftState, `[${entity}][${kindVehicle}].publicCost`);
      const refPublicCostMax = _.get(draftState, `[${entity}][${kindVehicle}].publicCostMax`);
      const refPrivateCost = _.get(draftState, `[${entity}][${kindVehicle}].privateCost`);
      const refPrivateCostMax = _.get(draftState, `[${entity}][${kindVehicle}].privateCostMax`);
      _.mapKeys(draftState[entity], (value, key) => {
        if (refPublicCost) _.set(draftState[entity][key], 'publicCost', refPublicCost);
        else if (draftState[entity][key].publicCost) delete draftState[entity][key].publicCost;
        if (refPublicCostMax) _.set(draftState[entity][key], 'publicCostMax', refPublicCostMax);
        else if (draftState[entity][key].publicCostMax) delete draftState[entity][key].publicCostMax;
        if (refPrivateCost) _.set(draftState[entity][key], 'privateCost', refPrivateCost);
        else if (draftState[entity][key].privateCost) delete draftState[entity][key].privateCost;
        if (refPrivateCostMax) _.set(draftState[entity][key], 'privateCostMax', refPrivateCostMax);
        else if (draftState[entity][key].privateCostMax) delete draftState[entity][key].privateCostMax;
      });
    });
    this.setState({ selection: nextState }, () => {
      this.sendStateToAPI();
    });
  };

  handleCopyToUpDown = (refEntity, entityPosition, direction = 'down') => {
    const firstKindId = _.get(this.props.reportsV3, 'constants.kindVehicle[0]._id');
    const nextState = produce(this.state.selection, (draftState) => {
      let i = 0;
      _.mapKeys(draftState, (value, key) => {
        if ((direction === 'up' && i < entityPosition) || (direction === 'down' && i > entityPosition)) {
          if (refEntity !== key && draftState[key][firstKindId].notCalculable !== true) {
            const kindVeihclesKeys = Object.keys(draftState[refEntity]);
            kindVeihclesKeys.forEach((kindVehicle) => {
              _.set(draftState[key][kindVehicle], 'privateCost', draftState[refEntity][kindVehicle].privateCost);
              _.set(draftState[key][kindVehicle], 'publicCost', draftState[refEntity][kindVehicle].publicCost);
              if (draftState[refEntity][kindVehicle].privateCostMax) {
                _.set(draftState[key][kindVehicle], 'privateCostMax', draftState[refEntity][kindVehicle].privateCostMax);
              } else {
                delete draftState[key][kindVehicle].privateCostMax;
              }
              if (draftState[refEntity][kindVehicle].publicCostMax) {
                _.set(draftState[key][kindVehicle], 'publicCostMax', draftState[refEntity][kindVehicle].publicCostMax);
              } else {
                delete draftState[key][kindVehicle].publicCostMax;
              }
            });
          }
        }
        i += 1;
      });
    });
    this.setState({ selection: nextState }, () => {
      this.sendStateToAPI();
    });
  };

  sendStateToAPI = () => {
    const payload = [];
    _.mapKeys(this.state.selection, (obj) => {
      _.mapKeys(obj, (fre, freKey) => {
        if (freKey !== 'notCalculable') {
          // notCalculable on this level is only used by the UI, so we should ignore it
          payload.push(fre);
        }
      });
    });
    this.updateRepairCostAPI(payload);
  };

  updateRepairCostAPI = (payload) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        let res;
        if (this.state.bySiteByReport === false) {
          const selectedReport = `reportIds[${this.state.selectedSite}]=${this.state.selectedReport}`;
          res = await ReportsServices.put(`${constants.URI.v3.global}entities/${this.state.selectedGroup}/repair-cost?${selectedReport}`, payload);
          const repairCosts = await ReportsServices.getAll(`${constants.URI.v3.global}entities/${this.state.selectedGroup}/repair-cost`, selectedReport);
          this.setState({ repairCosts });
          // this.setState({ repairCosts: _.get(this.props.reportsV3, 'repairCostByReport') });
        } else if (this.state.bySiteByReport === true) {
          // bySiteByReport Global
          const { selectedReports } = this.state;
          const selectedGroup = this.state.selectedGroup || _.get(this.props.user, 'concession.entity._id');
          res = await ReportsServices.put(`${constants.URI.v3.global}entities/${selectedGroup}/repair-cost?${selectedReports}`, payload);
          const repairCosts = await ReportsServices.getAll(`${constants.URI.v3.global}entities/${selectedGroup}/repair-cost`, selectedReports);
          this.setState({ repairCosts });
        }
        notify.show('Sauvegardé!', 'info', 0);

        resolve(res);
      } catch (e) {
        if (e.status === 403) {
          notify.show("Vous n'avez pas les droits de modification", 'error', 0);
        } else {
          notify.show('Erreur!', 'error', 0);
        }

        reject();
      }
    });

  updateGlobalMsaRepairCost = (payload) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      try {
        let res = null;
        if (payload.length > 0) {
          res = await ReportsServices.put(`${constants.URI.v3.repairCost}`, payload);
          notify.show('Sauvegardé!', 'info', 0);
        }

        resolve(res);
      } catch (e) {
        notify.show('Erreur!', 'error', 0);
        reject();
      }
    });

  handleIntervalCheckbox = (entity, kindVehicle, isPrivate = false) => {
    const nextState = produce(this.state.selection, (draftState) => {
      if (draftState[entity][kindVehicle].privateCostMax && isPrivate) draftState[entity][kindVehicle] = _.omit(draftState[entity][kindVehicle], ['privateCostMax']);
      else if (draftState[entity][kindVehicle].publicCostMax && !isPrivate) draftState[entity][kindVehicle] = _.omit(draftState[entity][kindVehicle], ['publicCostMax']);
      else if (!draftState[entity][kindVehicle].privateCostMax && isPrivate) draftState[entity][kindVehicle].privateCostMax = 100;
      else if (!draftState[entity][kindVehicle].publicCostMax && !isPrivate) draftState[entity][kindVehicle].publicCostMax = 100;
    });
    this.setState({ selection: nextState }, () => {
      this.sendStateToAPI();
    });
  };

  /* eslint no-param-reassign: */
  handleIntervalCheckboxGlobalMsa = (id, kindVehicle, globalMsaRepairCostsInfo, isPrivate = false) => {
    const nextState = _.cloneDeep(this.state.selection);
    const repairCostsToUpdate = [];

    nextState.repairCosts.forEach((repairCost) => {
      if (repairCost.id === id.defect && repairCost.infoRepair) {
        repairCost.infoRepair.forEach((perVehicle) => {
          if (perVehicle.id === kindVehicle && perVehicle.infoRepair) {
            let maxPriceToUpdate = {};
            if (isPrivate) {
              if (perVehicle.infoRepair.privateCostMax && perVehicle.infoRepair.privateCostMax !== -1) {
                delete perVehicle.infoRepair.privateCostMax;
                maxPriceToUpdate = { privateCostMax: -1 };
              } else {
                perVehicle.infoRepair.privateCostMax = 100;
                maxPriceToUpdate = { privateCostMax: 100 };
              }
            } else {
              // eslint-disable-next-line
              if (perVehicle.infoRepair.publicCostMax && perVehicle.infoRepair.publicCostMax !== -1) {
                delete perVehicle.infoRepair.publicCostMax;
                maxPriceToUpdate = { publicCostMax: -1 };
              } else {
                perVehicle.infoRepair.publicCostMax = 100;
                maxPriceToUpdate = { publicCostMax: 100 };
              }
            }
            repairCostsToUpdate.push({ _id: perVehicle.infoRepair._id, ...maxPriceToUpdate });
          }
        });
      }
    });
    this.setState({ selection: nextState }, () => {
      this.updateGlobalMsaRepairCost(repairCostsToUpdate);
    });
  };

  handleActivationToggle = (entity) => {
    const firstId = _.get(this.props.reportsV3, 'constants.kindVehicle[0]._id');
    const nextState = produce(this.state.selection, (draftState) => {
      const notCalculableProperty = _.get(draftState[entity][firstId], 'notCalculable');
      const currentNotCalculableProperty = typeof notCalculableProperty === 'boolean' && notCalculableProperty === true;
      const newCalculableValue = !currentNotCalculableProperty;
      draftState[entity].notCalculable = newCalculableValue;
      _.mapKeys(draftState[entity], (fre, key) => {
        if (key !== 'notCalculable') draftState[entity][key].notCalculable = newCalculableValue;
      }); // eslint-disable-line
    });
    this.setState({ selection: nextState }, () => {
      this.sendStateToAPI();
    });
  };

  handleActivationToggleGlobalMsa = (name) => {
    const nextState = this.state.selection;
    const repairCostsToUpdate = [];

    nextState.repairCosts.forEach((repairCost) => {
      if (repairCost.id === name && repairCost.infoRepair) {
        const notCalculableProperty = _.get(repairCost, 'infoRepair[0].infoRepair.notCalculable');
        const currentNotCalculableProperty = typeof notCalculableProperty === 'boolean' && notCalculableProperty === true;
        const newCalculableValue = !currentNotCalculableProperty;
        repairCost.infoRepair.forEach((perVehicle) => {
          // eslint-disable-next-line no-param-reassign
          perVehicle.infoRepair.notCalculable = newCalculableValue;
          repairCostsToUpdate.push({ _id: perVehicle.infoRepair._id, notCalculable: perVehicle.infoRepair.notCalculable });
        });
      }
    });
    this.setState({ selection: nextState }, () => {
      this.updateGlobalMsaRepairCost(repairCostsToUpdate);
    });
  };

  showGlobalMsaFRE = async (selected) => {
    this.setState({ treeViewSelection: selected });
    // If we selected a Section Title (and not a Checkpoint)
    if (selected[0]?.items) {
      this.setState({ selection: {} });
      return;
    }
    const selection = selected[0];
    this.setState({ selection });
  };

  loadFRE = async (selected) => {
    this.setState({ treeViewSelection: selected });
    // If we selected a Section Title (and not a Checkpoint)
    if (selected[0]?.items) {
      this.setState({ selection: {} });
      return;
    }
    this.setState({ loadingFRE: true });
    const { repairCosts } = this.state;
    const isPhotoDefect = _.get(selected, '[0].isPhotoDefect', false);
    let selection;
    if (isPhotoDefect) {
      const refPhotoFilter = _.get(selected, '[0].refPhotoFilter');
      const refPartVehicle = _.get(selected, '[0].refPartVehicle');
      const refDefect = _.get(selected, '[0].refDefect');
      const label = _.get(selected, '[0].fullLabel');
      selection = _.get(repairCosts, `DefectFRE[${refPhotoFilter}][${refPartVehicle}][${refDefect}]`);
      selection = { [label]: selection };
    } else {
      const selectionId = _.get(selected, '[0].id');
      selection = _.get(repairCosts, `CheckpointFRE[${selectionId}]`);
      // Rename Obj keys to match Checkpoint labels
      const renameKeys = (obj, newKeys) => {
        const keyValues = obj && Object?.keys(obj)?.map((key) => {
          const newKey = newKeys[key] || key;
          return { [newKey]: obj[key] };
        });
        return { ...keyValues?.reduce((a, c) => ({ ...a, ...c }), {}) };
      };
      const newKeys = {};
      _.forEach(_.get(selected, `[0].details.list`), (obj) => {
        newKeys[obj?.key] = obj?.label;
      });
      selection = renameKeys(selection, newKeys);
    }

    this.setState({ selection });
    this.setState({ loadingFRE: false });
  };

  renderFREGlobalMsa = () => {
    const { selection } = this.state;
    if (selection && selection.repairCosts) {
      return selection.repairCosts.map((repairCostInfo) => (
        <PriceTable
          title={repairCostInfo.label}
          key={repairCostInfo.id}
          name={repairCostInfo.id}
          handlePriceUpdate={this.handlePriceUpdateGlobalMsa}
          handleActivationToggle={this.handleActivationToggleGlobalMsa}
          handleIntervalCheckbox={this.handleIntervalCheckboxGlobalMsa}
          fullRepairCostGlobalMsa={repairCostInfo.infoRepair}
          handleCloneCategory={this.handleCloneCategoryGlobalMsa}
          isGlobalMsa={true}
        />
      ));
    }

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: 100 }} description='Aucun resultat' />;
  };

  renderFRE = () => {
    const { selection } = this.state; // treeViewSelection, kindVehicleMap
    const { modalData } = this.props.reportsV3;
    // const { label, sectionIndex, checkpointIndex } = _.get(treeViewSelection,'[0]');
    if (_.keys(selection).length > 0) {
      const res = [];
      let position = 0;
      _.mapKeys(selection, (fre, key) => {
        if (fre) {
          res.push(
            <PriceTable
              title={key}
              key={`fre${key}`}
              name={key}
              kindVehicleMap={_.sortBy(_.get(this.props.reportsV3, 'constants.kindVehicle'), ['position'])}
              fullReport={modalData}
              fullRepairCost={fre}
              handlePriceUpdate={this.handlePriceUpdate}
              handleActivationToggle={this.handleActivationToggle}
              handleIntervalCheckbox={this.handleIntervalCheckbox}
              handleCloneCategory={this.handleCloneCategory}
              handleCopyToUpDown={this.handleCopyToUpDown}
              position={position}
              isLastPosition={position + 1 === Object.keys(selection).length}
            />,
          );
          position += 1;
        }
        return null;
      });
      return res;
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: 100 }} description='Aucun resultat' />;
  };

  renderToast = () => <Notifications options={{ wrapperId: 'fre-toast' }} />;

  render() {
    return (
      <React.Fragment>
        {this.renderToast()}
        <div className='table'>
          <Row xs={12}>
            <Col xs={8} className='table-header'>
              <div className='title'>
                <Text fontStyle='normal' size={17} style={{ padding: 10 }}>
                  Éditeur de FRE
                </Text>
              </div>
            </Col>
            <Col
              xs={2}
              className='table-header'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                paddingRight: 30,
              }}
            >
              {this.props.user && this.props.user.isAdmin && (
                <React.Fragment>
                  <Text fontStyle='normal' size={14} style={{ marginBottom: 0 }}>
                    Global MSA
                  </Text>
                  <Switch
                    checked={this.state.globalMode}
                    onChange={(val) => {
                      if (val === true) {
                        alert('Attention : vous allez éditer les FRE communs à tout MSA. \nCela impacte potentiellement tous les clients. \nPrudence.');
                      }
                      this.setState({ globalMode: val });
                      this.setState({ sites: [] });
                      this.setState({ selectedSite: '' });
                      this.setState({ selectedGroup: '' });
                      this.cleanInterface();
                      this.handleGlobalMsa();
                    }}
                    handleDiameter={16}
                    offColor='#DDD'
                    onColor='#1f5288'
                    offHandleColor='#fff'
                    onHandleColor='#fff'
                    height={24}
                    width={42}
                    checkedIcon={null}
                    uncheckedIcon={null}
                    className='react-switch'
                    id='small-radius-switch'
                  />
                </React.Fragment>
              )}
            </Col>
            <Col
              xs={2}
              className='table-header'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                paddingRight: 30,
              }}
            >
              {!this.state.globalMode && (
                <React.Fragment>
                  <Text fontStyle='normal' size={14} style={{ marginRight: 8 }}>
                    {this.state.bySiteByReport ? 'Par sites' : 'Par rapport'}
                  </Text>
                  <Switch
                    checked={this.state.bySiteByReport}
                    onChange={(val) => {
                      this.setState({ bySiteByReport: val });
                      this.setState({ sites: [] });
                      this.setState({ selectedSite: '' });
                      this.setState({ selectedGroup: '' });
                      this.cleanInterface();
                    }}
                    handleDiameter={16}
                    offColor='#DDD'
                    onColor='#1f5288'
                    offHandleColor='#fff'
                    onHandleColor='#fff'
                    height={24}
                    width={42}
                    checkedIcon={null}
                    uncheckedIcon={null}
                    className='react-switch'
                    id='small-radius-switch'
                  />
                </React.Fragment>
              )}
            </Col>
          </Row>
          {this.state.globalMode && this.state.loadingMenu !== true ? (
            <Col xs={12} className='table-search'>
              <div className='search-item'>
                <label>Type de FRE</label>
                <div className='search-input'>
                  <Select
                    size={'large'}
                    value={this.state.selectedMsaGlobalScope}
                    onChange={this.handleGlobalMsaScopeSelect}
                    className='multi-select'
                    labelInValue={false}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Select.Option key='CheckpointFRE' value='CheckpointFRE'>
                      Points de contrôles
                    </Select.Option>
                    <Select.Option key='DefectFRE' value='DefectFRE'>
                      Défauts
                    </Select.Option>
                  </Select>
                </div>
              </div>
              <br />
            </Col>
          ) : null}
          {!this.state.globalMode && this.props.index && (
            <Col xs={12} className='table-search'>
              <FRESearchBar
                index={this.props.index}
                handleSelectGroup={this.handleSelectGroup}
                handleSelectSite={this.handleSelectSite}
                handleSelectReport={this.handleSelectReport}
                handleSelectReportsGlobal={this.handleSelectReportsGlobal}
                bySiteByReport={this.state.bySiteByReport}
                isAdmin={this.props.user && this.props.user.isAdmin}
                groups={this.state.groups}
                selectedGroup={this.state.selectedGroup}
                sites={this.state.sites}
                selectedSite={this.state.selectedSite}
                treeData={this.state.treeSelect}
                reports={this.state.reports}
                selectedReport={this.state.selectedReport}
                selectedReportsGlobal={this.state.selectedReportsGlobal}
              />
            </Col>
          )}
          <div className='table-content' style={{ marginTop: 0 }}>
            <Row style={{ minHeight: 340 }}>
              <Col
                xs={4}
                style={{
                  borderRightWidth: 4,
                  borderRightColor: '#ebebeb',
                  borderRightStyle: 'solid',
                  maxHeight: '70vh',
                  overflowY: 'scroll',
                }}
              >
                <div>
                  <Spin spinning={this.state.loadingMenu}>
                    <SideMenuTreeView selection={this.state.treeViewSelection} update={this.state.globalMode ? this.showGlobalMsaFRE : this.loadFRE} model={this.state.model} />
                  </Spin>
                </div>
              </Col>
              <Col xs={8} style={{ overflow: '-webkit-paged-x', marginBottom: 30 }}>
                <Spin spinning={this.state.loadingFRE}>{this.state.globalMode ? this.renderFREGlobalMsa() : this.renderFRE()}</Spin>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Index.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.application.user,
  reportsV3: state.reportsV3,
});

Index.propTypes = {
  dispatch: PropTypes.func,
  reportsV3: PropTypes.object,
  type: PropTypes.string,
  index: PropTypes.string,
  options: PropTypes.object,
  user: PropTypes.object,
  light: PropTypes.bool,
};

export default connect(mapStateToProps)(Index);
