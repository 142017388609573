/* eslint-disable import/no-extraneous-dependencies */
/* eslint react/prop-types: 0 */
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  clearInspection,
  clearInspectionDrawer,
  getInspection,
  updateNumberOfMonthsSelectedInspections,
  updatePlannedInspections,
  validateInspection,
} from '../../../actions/plannedInspectionsActions';
import constants from '../../../constants';
import { rowsToUpdate, selectColumnsToExport } from '../../../helpers/agGridCustom';
import loadInspectionsByNumberOfMonthsSelected from '../../../helpers/inspection';
import Filters from '../../shared/atoms/Filters';
import { exportExcelDateAndDateTimeFormatter } from '../../shared/atoms/gridDateUtils';
import ActionsBar from '../../shared/organisms/ActionsBar';
import filtersOptions from '../atoms/filtersOptions';
import { adminPlannedInspectionOptions } from '../atoms/gridOptions';
import InspectionDetailsDrawer from '../organisms/InspectionDetailsDrawer';
import ModalAssignInspections from '../organisms/ModalAssignInspections';
import ModalCancelInspections from '../organisms/ModalCancelInspections';
import ModalCustomSms from '../organisms/ModalCustomSms';
import ModalEditCreateInspection from '../organisms/ModalEditCreateInspection';
import ModalEditReport from '../organisms/ModalEditReport';
import ModalNoShowInspections from '../organisms/ModalNoShowInspections';
import ModalResend from '../organisms/ModalResend';
import ModalUnassignInspections from '../organisms/ModalUnassignInspections';

const InspectionsContainer = (props) => {
  const [currentInspection, setCurrentInspection] = useState({});
  const [onClickItem, setOnClickItem] = useState(false);
  const [inspectionIdSelected, setInspectionIdSelected] = useState();
  const [modalToDisplay, setModalToDisplay] = useState('');
  const [hiddenColumnsNames, setHiddenColumnsNames] = useState({
    createdAt: true,
    assignedAt: true,
    'cancelInfos.date': true,
    generatedAt: true,
    received: true,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterOptionSelected, setFilterOptionSelected] = useState('ordered');
  const [monthsSelected, setMonthsSelected] = useState(false);
  const [numberOfMonthsSelected, setNumberOfMonthsSelected] = useState(props.numberOfMonthsSelectedInspections);
  const gridApiRef = useRef();
  const gridColRef = useRef();
  const quickFilterTimeoutRef = useRef();
  const inputQuickFilterRef = useRef();

  const onClickRefresh = () => props.updateInspections(props.dispatch, props.lastUpdate);

  const validateInspectionAndUpdateGrid = async (inspectionId) => {
    await props.validateInspection(props.dispatch, inspectionId);
  };

  const onClickOnMenuItem = (itemName) => (inspection) => () => {
    const { _id: inspectionId } = inspection;
    switch (itemName) {
      case 'detailsPanel':
        setInspectionIdSelected(inspectionId);
        setOnClickItem(true);
        setModalToDisplay(itemName);
        break;
      case 'edit':
      case 'duplicate':
        props.getInspection(props.dispatch, inspectionId, 'modalEditionInspection');
        setModalToDisplay(itemName);
        break;
      case 'editReport':
        setInspectionIdSelected(inspectionId);
        setModalToDisplay(itemName);
        break;
      case 'validate':
        validateInspectionAndUpdateGrid(inspectionId);
        break;
      default:
        setModalToDisplay(itemName);
    }
  };

  const gridOptions = adminPlannedInspectionOptions(props.adminInterface, onClickOnMenuItem);

  const waitUntilGridIsReady = () =>
    new Promise((resolve) => {
      const intervalCheckRef = setInterval(() => {
        if (gridApiRef) {
          clearInterval(intervalCheckRef);
          resolve();
        }
      }, 100);
    });

  const resizeGrid = () => gridApiRef.current && gridApiRef.current.sizeColumnsToFit();

  const setColumnHiddenOrVisible = ({ hidden = [], visible = [] }) => {
    if (gridApiRef.current) {
      gridColRef.current.setColumnsVisible(hidden, false);
      gridColRef.current.setColumnsVisible(visible, true);
    }
    const newHiddenColumnsNames = { ...hiddenColumnsNames };
    hidden.forEach((h) => {
      newHiddenColumnsNames[h] = true;
    });
    visible.forEach((v) => {
      delete newHiddenColumnsNames[v];
    });
    setHiddenColumnsNames(newHiddenColumnsNames);
    resizeGrid();
  };

  const onQuickFilterChange = (newValue) => {
    // Debounce quickFilter to avoid freeze while typing
    if (quickFilterTimeoutRef.current) {
      clearTimeout(quickFilterTimeoutRef.current);
    }
    quickFilterTimeoutRef.current = setTimeout(() => gridApiRef.current.setQuickFilter(newValue), 400);
  };

  const onClickExportExcel = () => {
    gridApiRef.current.exportDataAsExcel({
      processCellCallback: exportExcelDateAndDateTimeFormatter,
      columnKeys: selectColumnsToExport(gridOptions.columnDefs),
    });
  };

  const onClickMultiple = (modalName) => () => {
    setModalToDisplay(modalName);
  };

  useEffect(() => setCurrentInspection(props.inspectionDetails), [props.inspectionDetails]);

  const clearFilter = (field) => {
    const FilterComponent = gridApiRef.current.getFilterInstance(field);
    FilterComponent.setModel(null);
  };

  const onClickQuickStatusFilter = (status) => {
    // reset filters
    inputQuickFilterRef.current.reset();
    gridApiRef.current.deselectAll();

    // set filters
    if (status === 'notReceived') {
      gridApiRef.current.setFilterModel({ reportMissing: { type: 'startsWith', filter: 'reportMissing' } });
    } else if (status !== 'none') {
      gridApiRef.current.setFilterModel({ statusFilter: { type: 'startsWith', filter: status } });
    } else {
      clearFilter('statusFilter');
      clearFilter('reportMissing');
    }

    if (status === 'ordered') {
      setColumnHiddenOrVisible({
        visible: ['createdAt', 'seller.phone', 'seller.name', 'groupId'],
        hidden: ['status', 'specialist.name', 'appointment.date', 'cancelInfos.date', 'generatedAt', 'assignedAt'],
      });
      gridApiRef.current.setSortModel([{ colId: 'createdAt', sort: 'desc' }]);
    } else if (status === 'done') {
      setColumnHiddenOrVisible({
        visible: ['specialist.name', 'generatedAt', 'groupId'],
        hidden: ['appointment.date', 'cancelInfos.date', 'assignedAt', 'createdAt', 'status', 'seller.phone'],
      });
      gridApiRef.current.setSortModel([{ colId: 'generatedAt', sort: 'desc' }]);
    } else if (status === 'scheduled') {
      setColumnHiddenOrVisible({
        visible: ['specialist.name', 'appointment.date', 'groupId'],
        hidden: ['cancelInfos.date', 'generatedAt', 'createdAt', 'assignedAt', 'status'],
      });
      gridApiRef.current.setSortModel([{ colId: 'assignedAt', sort: 'desc' }]);
    } else if (status === 'canceled' || status === 'no_show') {
      setColumnHiddenOrVisible({ visible: ['cancelInfos.date', 'groupId'], hidden: ['generatedAt', 'specialist.name', 'appointment.date', 'status'] });
      gridApiRef.current.setSortModel([{ colId: 'cancelInfos.date', sort: 'desc' }]);
    } else if (status === 'notReceived') {
      setColumnHiddenOrVisible({
        visible: ['specialist.name', 'appointment.date', 'groupId'],
        hidden: ['createdAt', 'cancelInfos.date', 'generatedAt', 'status'],
      });
      gridApiRef.current.setSortModel([{ colId: 'appointment.date', sort: 'desc' }]);
    } else if (status === 'none') {
      setColumnHiddenOrVisible({ visible: ['status', 'groupId'], hidden: ['createdAt', 'cancelInfos.date', 'generatedAt', 'specialist.name', 'appointment.date'] });
      gridApiRef.current.setSortModel([{ colId: 'createdAt', sort: 'desc' }]);
    }

    gridApiRef.current.onFilterChanged();
    setFilterOptionSelected(status);
  };

  const closeModal = ({ didUpdate = false }) => {
    if (didUpdate) {
      props.updateInspections(props.dispatch, props.lastUpdate);
    }
    gridApiRef.current.deselectAll();
    setModalToDisplay('');
    setCurrentInspection({});
    setOnClickItem(false);
    setInspectionIdSelected(null);
    props.dispatch(clearInspection());
  };

  const closeCard = () => {
    gridApiRef.current.deselectAll();
    setModalToDisplay('');
    setOnClickItem(false);
    setInspectionIdSelected(null);
    props.dispatch(clearInspectionDrawer());
  };

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.applyTransaction({
        add: _.differenceBy(props.inspections, props.oldInspections, (i) => i._id),
        remove: _.differenceBy(props.oldInspections, props.inspections, (i) => i._id),
        update: rowsToUpdate(props.inspections, props.oldInspections),
      });
      setMonthsSelected(false);
      resizeGrid();
    }
  }, [props.inspections]);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    gridColRef.current = params.columnApi;
    gridApiRef.current.setQuickFilter('ordered');

    let setSelectedRowsTimeoutRef = null;

    // When clicking "select all" this even is called for each row (so many times !)
    // We only need to run setSelectedRows once so we use a debounce to do it at the end
    params.api.addEventListener('rowSelected', () => {
      if (setSelectedRowsTimeoutRef) {
        clearTimeout(setSelectedRowsTimeoutRef);
      }
      setSelectedRowsTimeoutRef = setTimeout(() => {
        setSelectedRows(params.api.getSelectedRows());
      }, 50);
    });
  };

  const onSelectedMonths = (months) => {
    setMonthsSelected(true);
    setNumberOfMonthsSelected(months);
    updateNumberOfMonthsSelectedInspections(props.dispatch, months);
    loadInspectionsByNumberOfMonthsSelected(months, props.updateInspections, props.dispatch);
  };

  useEffect(() => {
    waitUntilGridIsReady().then(() => {
      gridApiRef.current.setRowData(props.inspections);
      loadInspectionsByNumberOfMonthsSelected(numberOfMonthsSelected, props.updateInspections, props.dispatch, props.lastUpdate);
      setColumnHiddenOrVisible({ hidden: ['specialist.name', 'appointment.date', 'cancelInfos.date'], visible: ['createdAt', 'seller.phone'] });
      gridApiRef.current.setSortModel([{ colId: 'createdAt', sort: 'desc' }]);
    });
  }, []);

  // Auto refresh
  useEffect(() => {
    const interval = setInterval(() => {
      if (modalToDisplay) return;
      onClickRefresh();
    }, constants.autoRefresh);
    return () => clearInterval(interval);
  });

  return (
    <div className='inspections-grid-container mtp'>
      <Filters selectOptions={onClickQuickStatusFilter} title='Statut' options={filtersOptions(props)} activeMenu={filterOptionSelected} />
      <ActionsBar
        ref={inputQuickFilterRef}
        onClickRefresh={onClickRefresh}
        onClickExportExcel={onClickExportExcel}
        onQuickFilterChange={onQuickFilterChange}
        selectedRows={selectedRows}
        onClickMultipleCancel={onClickMultiple('cancel-multiple')}
        onClickMultipleNoShow={onClickMultiple('no-show')}
        onClickMultipleUnassign={onClickMultiple('unassign-multiple')}
        onClickMultipleAssign={onClickMultiple('assign-multiple')}
        onSelectMonthsLoadingInspections={onSelectedMonths}
        loadingInspectionsByMonths={monthsSelected}
        numberOfMonthsSelected={numberOfMonthsSelected}
        adminInterface={props.adminInterface}
        interfaceView='adminInterface'
      />
      <div className='ag-theme-balham'>
        <AgGridReact {...gridOptions} onGridReady={onGridReady} onColumnVisible={resizeGrid} onToolPanelVisibleChanged={resizeGrid} />
      </div>
      {['edit', 'duplicate'].includes(modalToDisplay) && <ModalEditCreateInspection isOpen={['edit', 'duplicate'].includes(modalToDisplay)} modalToDisplay={modalToDisplay} inspection={currentInspection} onRequestClose={closeModal} />}
      <ModalNoShowInspections isOpen={modalToDisplay === 'no-show'} inspections={modalToDisplay === 'no-show' ? selectedRows : [currentInspection]} onRequestClose={closeModal} />
      {['cancel', 'cancel-multiple'].includes(modalToDisplay) && (
        <ModalCancelInspections isOpen={['cancel', 'cancel-multiple'].includes(modalToDisplay)} inspections={selectedRows} onRequestClose={closeModal} />
      )}
      {['unassign', 'unassign-multiple'].includes(modalToDisplay) && (
        <ModalUnassignInspections isOpen={['unassign', 'unassign-multiple'].includes(modalToDisplay)} inspections={selectedRows} onRequestClose={closeModal} />
      )}
      <ModalCustomSms isOpen={modalToDisplay === 'sms'} inspection={currentInspection} onRequestClose={closeModal} />
      <ModalAssignInspections isOpen={modalToDisplay === 'assign-multiple'} inspections={selectedRows} onRequestClose={closeModal} />
      <ModalResend isOpen={modalToDisplay === 'resend'} inspection={currentInspection} onRequestClose={closeModal} />
      {modalToDisplay === 'editReport' && <ModalEditReport isOpen={modalToDisplay === 'editReport'} inspectionId={inspectionIdSelected} onRequestClose={closeModal} />}
      {onClickItem && (
        <InspectionDetailsDrawer isOpen={modalToDisplay === 'detailsPanel'} inspectionId={inspectionIdSelected} closeCard={closeCard} adminInterface={props.adminInterface} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  inspections: state.plannedInspections.inspections,
  oldInspections: state.plannedInspections.oldInspections,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
  inspectionDetails: state.plannedInspections.inspection,
  isLoading: state.plannedInspections.isLoading,
  numberOfMonthsSelectedInspections: state.plannedInspections.numberOfMonthsSelected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  validateInspection,
  updateInspections: updatePlannedInspections,
  getInspection,
  updateNumberOfMonthsSelectedInspections,
  clearInspection,
  clearInspectionDrawer,
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionsContainer);
