import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import _ from 'lodash';
import ConcessionsActions from '../../../actions/Concessions';
import { updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import InspectionsServices from '../../../services/InspectionsServices';

const ValidationActionCell = ({ value, dispatch, lastUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateOrNotInspection = (validate) => {
    const request = validate ? InspectionsServices.validatePlannedInspection : InspectionsServices.refusePlannedInspection;
    setErrorMessage('');
    setLoading(true);

    request(value._id)
      .then((results) => {
        if (results.status === 200) {
          updatePlannedInspections(dispatch, lastUpdate);
        } else {
          throw new Error(_.get(results, 'body.error') || "Une erreur s'est produite");
        }
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <div style={{ fontSize: 24, marginTop: '4px' }}>
      {!loading ? (
        <div>
          <i
            title='Dupliquer'
            onClick={() => {
              dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'duplicateConcessionInspection', modalData: value }));
            }}
            style={{ marginRight: 15 }}
            className='fa fa-clone'
          />
          {['scheduled', 'ordered'].includes(value.status) ? (
            <i
              title='Annuler'
              onClick={() => {
                dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'cancelInspection', modalData: value }));
              }}
              style={{ marginRight: 15 }}
              className='fa fa-times'
            />
          ) : (
            <>
              <i title={'Valider'} onClick={() => validateOrNotInspection(true)} style={{ marginRight: 10 }} className={'fa fa-check'} />
              <i title={'Refuser'} onClick={() => validateOrNotInspection(false)} style={{ marginLeft: 10 }} className={'fa fa-times'} />
            </>
          )}
          <p style={{ fontSize: 10, color: 'red' }}>{errorMessage}</p>
        </div>
      ) : (
        <ClipLoader sizeUnit={'px'} size={7} color={'#123abc'} loading={true} />
      )}
    </div>
  );
};

ValidationActionCell.propTypes = {
  value: PropTypes.object,
  updatePlannedInspections: PropTypes.func,
  dispatch: PropTypes.func,
  lastUpdate: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = (state) => ({
  lastUpdate: state.plannedInspections.lastUpdatedAt,
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidationActionCell);
