import InspectionsServices from '../services/InspectionsServices';

// Action types
export const UPDATE_INSPECTIONS = 'UPDATE_ON_DEMAND_INSPECTIONS';
export const UPDATE_INSPECTIONS_FAIL = 'UPDATE_ON_DEMAND_INSPECTIONS_FAIL';

// Action creator

export const updateOnDemandInspections = (dispatch, since) => {
  InspectionsServices.getOnDemandInspections(since).then((results) => {
    if (results.status === 200) {
      dispatch({
        type: UPDATE_INSPECTIONS,
        payload: results.body.inspections,
      });
    } else if (results.status === 401) {
      dispatch({
        type: 'SERVER_KILLED_SESSION',
        payload: {},
      });
    } else {
      dispatch({
        type: UPDATE_INSPECTIONS_FAIL,
        payload: results.body.message || 'Failed',
      });
    }
  });
};
