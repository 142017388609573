import { Checkbox } from 'antd';
import produce from 'immer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import ReportsActions from '../../../../actions/ReportsV3Actions';
import c from '../../../../constants';
import ReportServices from '../../../../services/ReportsService';

Modal.setAppElement('body');

class ModalCreateCupiCarPart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelMsa: '',
      labelGt: '',
      cupiShortNumber: '',
      functionalGroup: '',
      usedByMsa: false,
    };
  }

  componentDidMount() {
    if (this.props.isEdit) {
      const { modalData } = this.props.reportsV3;
      this.setState({
        labelMsa: _.get(modalData, 'labelMsa'),
        labelGt: _.get(modalData, 'labelGt'),
        cupiShortNumber: _.get(modalData, 'cupiShortNumber'),
        functionalGroup: _.get(modalData, 'functionalGroup'),
        usedByMsa: _.get(modalData, 'usedByMsa'),
      });
    }
  }

  onChange = (e, isCheckbox) => {
    const { target } = e;
    const { name, value, checked } = target;

    if (isCheckbox) {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(ReportsActions.hideReportsV3Modal());
  };

  submit = async (e) => {
    e.preventDefault();

    // Currently: only PUT-update of labelMsa and usedByMsa properties is possible
    const {
      labelMsa,
      // labelGt,
      // cupiShortNumber,
      // functionalGroup,
      // usedByMsa,
    } = this.state;

    const data = {
      labelMsa,
      // labelGt,
      // cupiShortNumber,
      // functionalGroup,
      // usedByMsa,
    };
    this.setState({ isLoading: true });
    if (this.props.isEdit === true) {
      try {
        await ReportServices.put(c.URI.v3.cupiCarPart.concat(this.props.reportsV3.modalData._id), data);
        this.props.dispatch(ReportsActions.executeAction('cupiCarParts', { pageNumber: this.props.reportsV3.pageNumber }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    }
    // else {
    //   try {
    //     await ReportServices.post(c.URI.v3.cupiCarPart, data);
    //     this.props.dispatch(ReportsActions.executeAction('cupiCarParts', { pageNumber: this.props.reportsV3.pageNumber }));
    //     this.closeModal();
    //   } catch (err) {
    //     this.setState({ error: err ? err.error : 'Une erreur est survenue' });
    //   }
    // }

    this.setState({ isLoading: false });
  };

  handleDeleteItem = (e, sortIndex) => {
    e.preventDefault();
    const nextCupiParts = produce(this.state.CupiCarPartCupis, (draftState) => {
      draftState.splice(sortIndex, 1);
    });
    this.setState({ CupiCarPartCupis: nextCupiParts });
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  renderValidButtonText = () => {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }
    return (
      <button type='button' onClick={this.submit} className='positiv' disabled={isLoading}>
        Sauvegarder
      </button>
    );
  };

  render() {
    const { isAdmin } = this.props.application.user;
    const userCanEdit = isAdmin === true;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Créer ou modifier une pièce auto CUPI'
        overlayClassName='modal-overlay'
        className='modal modalReport'
        style={!userCanEdit ? { content: { cursor: 'not-allowed' } } : {}}
      >
        <Col className='modalContent' style={!userCanEdit ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
          <div className='mainTitle'>
            {' '}
            <span className='modalFontBig'>Pièce auto CUPI</span>
          </div>
          <form className='mt10px modalFont'>
            <Row xs={12} style={{ marginBottom: 5 }}>
              <Col xs={4}>
                <div className='formInput'>
                  <label>Label GT (par défaut)</label>
                  <input
                    type='text'
                    disabled
                    name='labelGt'
                    value={this.state.labelGt}
                    placeholder='Label GT'
                    // onChange={(e) => this.onChange(e, false)}
                    style={{ minHeight: 38, borderRadius: 8 }}
                  />
                </div>
              </Col>
              <Col xs={4}>
                <div className='formInput'>
                  <label>CUPI</label>
                  <input
                    type='text'
                    disabled
                    name='cupiShortNumber'
                    value={this.state.cupiShortNumber}
                    placeholder='CUPI'
                    // onChange={(e) => this.onChange(e, false)}
                    style={{ minHeight: 38, borderRadius: 8 }}
                  />
                </div>
              </Col>
              <Col xs={4}>
                <div className='formInput'>
                  <label>Groupe fonctionnel</label>
                  <input
                    type='text'
                    disabled
                    name='functionalGroup'
                    value={this.state.functionalGroup}
                    placeholder='Groupe fonctionnel'
                    // onChange={(e) => this.onChange(e, false)}
                    style={{ minHeight: 38, borderRadius: 8 }}
                  />
                </div>
              </Col>
            </Row>

            <Row xs={12} style={{ marginBottom: 5 }}>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Label MSA</label>
                  <input
                    type='text'
                    name='labelMsa'
                    value={this.state.labelMsa}
                    placeholder='Label MSA'
                    onChange={(e) => this.onChange(e, false)}
                    style={{ minHeight: 38, borderRadius: 8 }}
                  />
                </div>
              </Col>
              <Col xs={6}>
                <div style={{ display: 'flex', marginTop: 30 }}>
                  <Checkbox
                    style={{ marginRight: 10, marginBottom: 10 }}
                    disabled
                    checked={this.state.usedByMsa}
                    name='usedByMsa'
                    // onChange={(e) => this.onChange(e, true)}
                  />
                  <label>Pièce CUPI utilisée </label>
                </div>
              </Col>
            </Row>

            {this.renderError()}
          </form>
        </Col>
        <div className='center formButton'>
          <button type='button' onClick={this.closeModal} className='negativ'>
            {userCanEdit ? 'Annuler' : 'Fermer'}
          </button>
          {userCanEdit && this.renderValidButtonText()}
        </div>
      </Modal>
    );
  }
}

ModalCreateCupiCarPart.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  reportsV3: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default ModalCreateCupiCarPart;
