import { Checkbox, InputNumber } from 'antd';
import arrayMove from 'array-move';
import produce from 'immer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import Select from 'react-select';
import ReportsActions from '../../../../actions/ReportsV3Actions';
import c from '../../../../constants';
import ReportServices from '../../../../services/ReportsService';
import Text from '../../../shared/atoms/Text';
import DragDropCombo from '../components/dragDropCombo';
import DragDropList from '../components/dragDropList';
import DragDropListCarParts from '../components/dragDropListCarParts';
import MsaElementList from '../components/msaElementList';

Modal.setAppElement('body');

const textTypes = [
  { value: 'TextCheckpoint', label: 'Texte' },
  { value: 'NumberCheckpoint', label: 'Nombre' },
  { value: 'DateCheckpoint', label: 'Date' },
  { value: 'CommentCheckpoint', label: 'Commentaire' },
];

const textTypeCheckpoints = ['TextCheckpoint', 'NumberCheckpoint', 'DateCheckpoint', 'CommentCheckpoint'];

class ModalCreateControlPoint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      sectionItems: {},
      subCategoryOptions: [],
      addSubCategoryOptions: [],
      cpList: [],
      cpLoading: false,
      showConditionalField: false,
      search: '',
      searchMsaPart: '',
      msaPartList: [],
      msaParts: [],
      showConcernedReports: false,
      maxDurationInSeconds: null,
      unblurLicensePlate: false,
    };
  }

  cpLoading = false;

  cpPage = 0;

  totalPages = 1;

  msaPartLoading = false;

  msaPartPage = 0;

  totalMsaPartPages = 1;

  componentDidMount() {
    const { modalData } = this.props.reportsV3;
    // Get all Defect Typology IDs and Photo Filters from the server
    if (this.props.type === 'PhotoCheckpoint') {
      ReportServices.getAll(c.URI.v3.defectTypologies).then((res) => {
        const defectTypologies = [];
        _.map(res.dTypologies, (type) => _.map(type.defectTypologies, (dTypo) => defectTypologies.push({ label: dTypo.label, value: dTypo.defectTypologyId })));
        this.setState({
          defectTypologies,
          defectPartVehicle: _.find(defectTypologies, { value: _.get(modalData, 'details.defectPartVehicle') }),
        });
      });
      ReportServices.getAll(c.URI.getFilters).then((res) => {
        const photoFilters = _.map(res, (filter) => ({
          value: filter.url,
          label: filter.title,
          _id: filter._id,
          maskId: filter.maskId,
        }));
        this.setState({
          photoFilters,
          filter: _.find(photoFilters, { value: _.get(modalData, 'details.filter') }),
        });
      });
    }
    if (this.props.isEdit) {
      const highlight = _.get(modalData, 'details.highlight', null);
      const replaceMsaCarPart = _.get(modalData, 'details.replaceMsaCarPart', null);
      const defaultValue = _.get(modalData, 'details.defaultValue', null);
      const addFieldCase = _.get(modalData, 'details.addFieldCase', null);

      this.setState(
        {
          category: _.assign(modalData.category, { value: _.get(modalData, 'category.key') }),
          label: _.get(modalData, 'label'),
          description: _.get(modalData, 'description'),
          specialType: _.get(modalData, 'specialType'),
          concernedReports: _.get(modalData, 'concernedReports'),
          subCategory: _.assign(modalData.subCategory, { value: _.get(modalData, 'subCategory.key') }),
          details: _.omit(modalData.details, '_id'),
          kind: _.find(textTypes, { value: _.get(modalData, 'kind') }),
          subCategoryOptions: _.map(_.get(_.find(this.props.reportsV3.constants.category, { _id: _.get(modalData, 'category._id') }), 'subCategories'), (sub) => ({
            ...sub,
            value: sub.key,
          })),
          list: _.get(modalData, 'details.list', []),
          isDefect: _.get(modalData, 'isDefect'),
          defaultValue: defaultValue !== null && { label: defaultValue ? 'Oui' : 'Non', value: defaultValue },
          highlight: highlight !== null && { label: highlight ? 'Oui' : 'Non', value: highlight },
          replaceMsaCarPart: replaceMsaCarPart !== null && { label: replaceMsaCarPart ? 'Oui' : 'Non', value: replaceMsaCarPart },
          addFieldCase: addFieldCase !== null && { label: addFieldCase ? 'Oui' : 'Non', value: addFieldCase },
          multiplePhotoAddFieldTrigger: _.get(modalData.details, 'multiplePhotoAddFieldTrigger'),
          maxDurationInSeconds: _.get(modalData.details, 'maxDurationInSeconds'),
          unblurLicensePlate: _.get(modalData, 'unblurLicensePlate', false),
        },
        () => this.shouldAddFieldsDisplay(),
      );
      const addFieldContent = _.get(modalData, 'details.addFieldContent');
      if (addFieldContent) {
        this.setState({ sectionItems: { checkpoints: addFieldContent } });
      }
      if (modalData.msaCarParts) {
        this.setState({ msaParts: modalData.msaCarParts });
      }
    }
  }

  selectDurationVideo = (durationSelected) => this.setState({ maxDurationInSeconds: parseInt(durationSelected, 10) });

  closeModal = () => {
    const { dispatch } = this.props;

    dispatch(ReportsActions.hideReportsV3Modal());
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }

    return null;
  };

  onChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  onAddToList = () => {
    this.setState({ list: [...this.state.list, { label: this.state.newListTitle }] });
    this.setState({ newListTitle: '' });
  };

  onRemoveFromList = (e, index) => {
    e.preventDefault();
    this.setState({
      list: this.state.list.filter((item, i) => i !== index),
    });
  };

  onChangeListCheckbox = (e, index) => {
    const { target } = e;
    const { name, checked } = target;

    const list = produce(this.state.list, (draftState) => {
      // eslint-disable-next-line no-param-reassign
      draftState[index][name] = checked;
    });
    this.setState({ list }, () => {
      if (name === 'addField') this.shouldAddFieldsDisplay();
    });
  };

  shouldAddFieldsDisplay = () => {
    const { list } = this.state;
    const hasAddFieldArray = list.map((item) => item.addField && item.addField === true);
    const hasAddField = hasAddFieldArray.length > 0 ? hasAddFieldArray.includes(true) : _.get(this.state.sectionItems, 'checkpoints.length', 0) > 0;
    if (hasAddField) {
      this.setState({ showConditionalField: true });
    } else {
      this.setState({ showConditionalField: false, multiplePhotoAddFieldTrigger: false });
    }
  };

  onChangeCheckbox = (e) => {
    const { target } = e;
    const { name, checked } = target;
    const detailsList = ['photoTour', 'isDocument', '4_3', 'isUpperCase', 'orientationPortrait'];
    if (name === 'showConditionalField' && !checked) this.setState({ sectionItems: { checkpoints: [] } });
    if (detailsList.includes(name)) {
      this.setState({ details: { ...this.state.details, [name]: checked } });
    } else {
      this.setState({ [name]: checked });
    }
  };

  handleSelect = (selected, obj) => {
    if (obj.name === 'category') {
      this.setState({ subCategory: null });
      this.setState({ subCategoryOptions: [] });
      if (selected) {
        const newSubcategories = _.map(_.cloneDeep(selected.subCategories), (sub) => ({ ...sub, value: sub.key }));
        this.setState({ subCategoryOptions: newSubcategories });
      }
    } else if (obj.name === 'addCategory') {
      this.setState({ addSubCategory: null });
      this.setState({ addSubCategoryOptions: [] });
      if (selected) {
        const newSubcategories = _.map(_.cloneDeep(selected.subCategories), (sub) => ({ ...sub, value: sub.key }));
        this.setState({ addSubCategoryOptions: newSubcategories });
      }
    }

    this.setState({ [obj.name]: selected });
    if (obj.name === 'addCategory' || obj.name === 'addSubCategory') setTimeout(this.forceListUpdate, 10);
  };

  submit = async (e) => {
    e.preventDefault();
    const {
      label,
      description,
      specialType,
      category,
      subCategory,
      details,
      kind,
      list,
      defectPartVehicle,
      isDefect,
      sectionItems,
      filter,
      defaultValue,
      highlight,
      replaceMsaCarPart,
      addFieldCase,
      msaParts,
      multiplePhotoAddFieldTrigger,
      maxDurationInSeconds,
      unblurLicensePlate,
    } = this.state;

    // insert list and addFieldContent inside details
    const newDetails = {
      ...details,
      list,
      defectPartVehicle: _.get(defectPartVehicle, 'value'),
      filter: _.get(filter, 'value'),
      defaultValue: _.get(defaultValue, 'value'),
      highlight: _.get(highlight, 'value'),
      replaceMsaCarPart: _.get(replaceMsaCarPart, 'value'),
      addFieldCase: _.get(addFieldCase, 'value'),
      multiplePhotoAddFieldTrigger,
      maxDurationInSeconds,
    };

    if (sectionItems.checkpoints) {
      newDetails.addFieldContent = this.state.showConditionalField ? _.map(sectionItems.checkpoints, (cp) => _.get(cp, '_id')) : [];
    }

    const data = {
      label,
      description,
      specialType: _.get(specialType, '_id'),
      category: _.get(category, '_id'),
      subCategory: _.get(subCategory, '_id') || null,
      details: newDetails,
      kind: _.get(kind, 'value') || this.props.type,
      msaCarParts: msaParts ? msaParts.map((part) => part._id) : [],
      isDefect,
      unblurLicensePlate,
    };
    if (
      data.kind === 'TextCheckpoint' ||
      data.kind === 'BoolCheckpoint' ||
      data.kind === 'DateCheckpoint' ||
      data.kind === 'PhotoCheckpoint' ||
      data.kind === 'NumberCheckpoint' ||
      data.kind === 'CommentCheckpoint' ||
      data.kind === 'VideoCheckpoint'
    ) {
      delete data.details.list; // Otherwise not accepted by the API
    }

    if (data.kind === 'VideoCheckpoint') {
      delete data.msaCarParts;
    }
    if (!['BoolCheckpoint', 'EnumCheckpoint'].includes(data.kind)) {
      delete data.details.multiplePhotoAddFieldTrigger;
    }
    if (data.kind !== 'VideoCheckpoint') {
      delete data.details.maxDurationInSeconds;
    }
    this.setState({ isLoading: true });

    if (this.props.isEdit === true) {
      try {
        await ReportServices.put(c.URI.v3.checkpoints.concat(this.props.reportsV3.modalData._id), data);
        this.props.dispatch(ReportsActions.executeAction('checkpoints', { pageNumber: this.props.reportsV3.pageNumber }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    } else {
      try {
        await ReportServices.post(c.URI.v3.checkpoints, data);
        this.props.dispatch(ReportsActions.executeAction('checkpoints', { pageNumber: this.props.reportsV3.pageNumber }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    }

    this.setState({ isLoading: false });
  };

  loadCheckpoints = async () => {
    const { search, addCategory, addSubCategory } = this.state;

    this.cpLoading = true;
    const pages = await ReportServices.getAll(c.URI.v3.checkpoints, {
      pageNumber: this.cpPage > 0 ? this.cpPage : 1,
      searchTerm: search,
      category: _.get(addCategory, '_id'),
      subCategory: _.get(addSubCategory, '_id'),
    });
    this.cpLoading = false;
    if (pages && pages.totalPages) this.totalPages = pages.totalPages;
    if (pages && pages.items && pages.items.length > 0) {
      this.setState({ cpList: [...this.state.cpList, ...pages.items] });
    }
  };

  loadMsaParts = async () => {
    const { searchMsaPart } = this.state;

    this.msaPartLoading = true;
    const pages = await ReportServices.getAll(c.URI.v3.msaCarPart, {
      pageNumber: this.msaPartPage > 0 ? this.msaPartPage : 1,
      searchTerm: searchMsaPart,
    });
    this.msaPartLoading = false;
    if (pages && pages.totalMsaP) this.totalMsaPartPages = pages.totalMsaPartPages;
    if (pages && pages.items && pages.items.length > 0) {
      this.setState({ msaPartList: [...this.state.msaPartList, ...pages.items] });
    }
  };

  handleDeleteItem = (e, sortIndex) => {
    e.preventDefault();
    const sectionItems = produce(this.state.sectionItems, (draftState) => {
      draftState.checkpoints.splice(sortIndex, 1);
    });
    this.setState({ sectionItems });
  };

  handleDeleteMsaPart = (e, sortIndex) => {
    e.preventDefault();
    const msaParts = produce(this.state.msaParts, (msaPartss) => {
      msaPartss.splice(sortIndex, 1);
    });
    this.setState({ msaParts });
  };

  handleAddItem = (e, item) => {
    e.preventDefault();
    const sectionItems = produce(this.state.sectionItems, (draftState) => {
      // eslint-disable-next-line no-param-reassign
      if (typeof draftState.checkpoints === 'undefined') draftState.checkpoints = [];
      draftState.checkpoints.push({ ...item });
    });
    this.setState({ sectionItems });
  };

  handleAddItemMsaPart = (e, item) => {
    e.preventDefault();
    const msaParts = produce(this.state.msaParts, (msaPartss) => {
      msaPartss.push({ ...item });
    });

    this.setState({ msaParts });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const sectionItems = produce(this.state.sectionItems, (draftState) => {
      // eslint-disable-next-line no-param-reassign
      draftState.checkpoints = arrayMove(draftState.checkpoints, oldIndex, newIndex);
    });
    this.setState({ sectionItems });
  };

  onSortEndMsaParts = ({ oldIndex, newIndex }) => {
    const msaParts = produce(this.state.msaParts, (msaPartss) => arrayMove(msaPartss, oldIndex, newIndex));
    this.setState({ msaParts });
  };

  onSortComboEnd = ({ oldIndex, newIndex }) => {
    this.setState({ list: arrayMove(this.state.list, oldIndex, newIndex) });
  };

  // hacky solution to force update on stateless component
  forceListUpdate = () => {
    this.totalPages = 1;
    this.cpPage = 0;
    this.setState({ cpList: [{ label: '...' }] });
    this.setState({ cpList: [] });
  };

  handleSearch = (e) => {
    const { target } = e;
    const { value } = target;

    this.setState({ search: value });
    this.forceListUpdate();
  };

  forceMsaPartListUpdate = () => {
    this.totalMsaPartPages = 1;
    this.msaPartPage = 0;
    this.setState({ msaPartList: [{ label: '...' }] });
    this.setState({ msaPartList: [] });
  };

  handleSearchMsaPart = (e) => {
    const { target } = e;
    const { value } = target;

    this.setState({ searchMsaPart: value });
    this.forceMsaPartListUpdate();
  };

  loadSpecialTypeList = () => {
    let list = _.map(this.props.reportsV3.constants.specialType, (obj) => ({
      value: obj.key,
      label: obj.label,
      _id: obj._id,
    }));
    // sort by alphabetic order, _.deburr will deal with accented characters
    list = _.sortBy(list, (o) => _.deburr(o.label));
    return list;
  };

  toggleRapportsList = () => {
    const currentState = this.state.showConcernedReports;
    this.setState({ showConcernedReports: !currentState });
  };

  renderConditionalFields = () => {
    const { sectionItems } = this.state;
    if (this.state.showConditionalField && (this.props.type === 'BoolCheckpoint' || this.props.type === 'EnumCheckpoint')) {
      return (
        <>
          <div className='formInput flexbox'>
            <Checkbox style={{ marginRight: 10 }} name='multiplePhotoAddFieldTrigger' checked={this.state.multiplePhotoAddFieldTrigger} onChange={this.onChangeCheckbox} />
            <label>Possibilité d’ajouter plusieurs fois la même photo</label>
          </div>
          <Row style={{ marginBottom: 30, marginTop: 20 }}>
            <Col xs={6}>
              {this.props.type === 'BoolCheckpoint' && (
                <div className='formInput'>
                  <label>Déclenche avec</label>
                  <div data-cy='controlPointActivateWith' style={{ width: '92%' }}>
                    <Select
                      placeholder='Déclenche avec'
                      className='multi-select'
                      value={this.state.addFieldCase}
                      onChange={this.handleSelect}
                      options={[
                        { value: true, label: 'Oui' },
                        { value: false, label: 'Non' },
                      ]}
                      isMulti={false}
                      name={'addFieldCase'}
                      isClearable
                    />
                  </div>
                </div>
              )}
              <div className='formInput'>
                <label>Liste de points de contrôle</label>
              </div>
              <div style={{ maxWidth: '92%' }}>
                <div style={{ marginBottom: 20 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderColor: 'hsl(0,0%,80%)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 5,
                    }}
                  >
                    <div style={{ fontSize: 13, marginLeft: 5, width: '90%' }}>
                      <i className='fa fa-search' />
                      <DebounceInput
                        data-cy='searchControlPoints'
                        style={{
                          borderWidth: 0,
                          height: 35,
                          marginLeft: 10,
                          marginRight: 10,
                          width: '90%',
                        }}
                        type='text'
                        name='search'
                        debounceTimeout={300}
                        value={this.state.search}
                        onChange={this.handleSearch}
                        placeholder='Recherchez...'
                      />
                    </div>
                  </div>
                  <div data-cy='controlPointSearchCat' className='search-input'>
                    <Select
                      placeholder='Catégorie'
                      className='multi-select'
                      value={this.state.addCategory}
                      onChange={this.handleSelect}
                      options={_.map(this.props.reportsV3.constants.category, (obj) => ({
                        ...obj,
                        value: obj.key,
                      }))}
                      isMulti={false}
                      name={'addCategory'}
                      // style={{ zIndex: 99999999, width: 450 }}
                      isClearable
                    />
                  </div>
                  <div className='search-input'>
                    <Select
                      placeholder='Sous-catégorie'
                      className='multi-select'
                      value={this.state.addSubCategory}
                      onChange={this.handleSelect}
                      options={this.state.addSubCategoryOptions}
                      isMulti={false}
                      // style={{ zIndex: 99999999, width: 450 }}
                      name={'addSubCategory'}
                      isClearable
                    />
                  </div>
                </div>
              </div>
              <MsaElementList
                list={this.state.cpList}
                width={400}
                type='checkpoint'
                loadNextPage={() => {
                  this.cpPage += 1;
                  this.loadCheckpoints();
                }}
                hasNextPage={this.cpPage < this.totalPages}
                isNextPageLoading={this.cpLoading}
                handleAddItem={this.handleAddItem}
              />
            </Col>
            <Col xs={6}>
              <div className='formInput'>
                <label>Points de contrôle conditionnel(s)</label>
              </div>
              <DragDropList sectionItems={sectionItems} handleDeleteItem={this.handleDeleteItem} onSortEnd={this.onSortEnd} />
            </Col>
          </Row>
        </>
      );
    }
    return null;
  };

  renderMsaParts = () =>
    this.props.type === 'BoolCheckpoint' || this.props.type === 'EnumCheckpoint' ? (
      <Row>
        <Col xs={6}>
          <Row>
            <Col xs={12}>
              <div className='formInput'>
                <label>Liste des pièces auto MSA</label>
              </div>
              <div style={{ maxWidth: '92%' }}>
                <div style={{ marginBottom: 20 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderColor: 'hsl(0,0%,80%)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 5,
                    }}
                  >
                    <div style={{ fontSize: 13, marginLeft: 5, width: '90%' }}>
                      <i className='fa fa-search' />
                      <DebounceInput
                        data-cy='searchMsaCarParts'
                        style={{
                          borderWidth: 0,
                          height: 35,
                          marginLeft: 10,
                          marginRight: 10,
                          width: '90%',
                        }}
                        type='text'
                        name='search'
                        debounceTimeout={300}
                        value={this.state.searchMsaPart}
                        onChange={this.handleSearchMsaPart}
                        placeholder='Recherchez...'
                      />
                    </div>
                  </div>
                  <div className='search-input'></div>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              {/* // TODO: fix my size */}
              <div style={{ maxWidth: '70%' }}>
                <MsaElementList
                  width={400}
                  list={this.state.msaPartList}
                  type='msaCarPart'
                  loadNextPage={() => {
                    this.msaPartPage += 1;
                    this.loadMsaParts();
                  }}
                  hasNextPage={this.msaPartPage < this.totalMsaPartPages}
                  isNextPageLoading={this.msaPartLoading}
                  handleAddItem={this.handleAddItemMsaPart}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <div className='formInput'>
            <label>Pièces auto MSA liées au point de contrôle</label>
          </div>
          <DragDropListCarParts carParts={this.state.msaParts} handleDeleteItem={this.handleDeleteMsaPart} onSortEnd={this.onSortEndMsaParts} />
        </Col>
      </Row>
    ) : null;

  renderFormList = () => {
    if (this.state.list && this.state.list.length > 0) {
      return this.state.list.map((item, key) => (
        <div key={key} style={{ marginLeft: 25 }}>
          <span
            style={{
              display: 'flex',
              maxHeight: 15,
              marginBottom: 3,
              alignItems: 'center',
            }}
          >
            <button type='button' style={{ marginRight: 7 }} className='btnIcon delete' onClick={() => this.onRemoveFromList(key)}>
              <i className='fas fa-trash-alt' />
            </button>
            <Text size={11}>
              {key} - {item.label}
            </Text>
          </span>
          <div className='formInput' style={{ display: 'flex', marginBottom: 10, alignItems: 'flex-end' }}>
            <Checkbox style={{ marginRight: 10 }} defaultChecked={item.addField} name='addField' onChange={(e) => this.onChangeListCheckbox(e, key)} />
            <label>Point de contrôle conditionnel</label>
          </div>
        </div>
      ));
    }
    return null;
  };

  render() {
    const { isAdmin } = this.props.application.user;
    const userCanEdit = isAdmin === true;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Créer ou modifier un point de contrôle'
        overlayClassName='modal-overlay'
        className='modal modalCheckpoint'
        style={!userCanEdit ? { content: { cursor: 'not-allowed' } } : {}}
      >
        <div className='modalContent ' style={!userCanEdit ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
          {userCanEdit ? <div className='mainTitle'>{this.props.isEdit ? 'Edition' : 'Création'} du point de contrôle</div> : <div className='mainTitle'>Point de contrôle</div>}
          {isAdmin && this.state.concernedReports && this.state.concernedReports.length > 0 ? (
            <div className='warning'>
              <p>
                <i className='fas fa-exclamation-triangle' />
                &nbsp; Ce point de contrôle est utilisé dans{' '}
                <a onClick={this.toggleRapportsList}>
                  {this.state.concernedReports.length} rapport{this.state.concernedReports.length > 1 ? 's' : ''}.
                </a>
              </p>
              <ul className={this.state.showConcernedReports ? 'collapse show' : 'collapse hide'}>
                {this.state.concernedReports.map((item) => (
                  <li key={`item-${item}`}>{item}</li>
                ))}
              </ul>
            </div>
          ) : null}
          <form className='mt10px'>
            <Row>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Titre</label>
                  <input data-cy='checkpointTitle' type='text' name='label' value={this.state.label} placeholder='Titre' onChange={this.onChange} />
                </div>
              </Col>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Description</label>
                  <input data-cy='checkpointDescription' type='text' name='description' value={this.state.description} placeholder='Description' onChange={this.onChange} />
                </div>
              </Col>
            </Row>
            {textTypeCheckpoints.includes(this.props.type) && (
              <div className='formInput'>
                <label>Type (text / numéro / date)</label>
                <div data-cy='controlPointType' style={{ width: '50%' }}>
                  <Select
                    placeholder='Type de Texte'
                    className='multi-select'
                    value={this.state.kind}
                    onChange={this.handleSelect}
                    options={textTypes}
                    isMulti={false}
                    name={'kind'}
                    isClearable
                  />
                </div>
              </div>
            )}
            {this.props.type === 'BoolCheckpoint' && (
              <div className='formInput'>
                <label>Valeur Initiale</label>
                <div data-cy='controlPointInitialValue' style={{ width: '50%' }}>
                  <Select
                    placeholder='Valeur Initiale'
                    className='multi-select'
                    value={this.state.defaultValue}
                    onChange={this.handleSelect}
                    options={[
                      { value: true, label: 'Oui' },
                      { value: false, label: 'Non' },
                    ]}
                    isMulti={false}
                    name={'defaultValue'}
                    isClearable
                  />
                </div>
                <label>Signaler</label>
                <div data-cy='controlPointSignal' style={{ width: '50%' }}>
                  <Select
                    placeholder='Valeur à Signaler'
                    className='multi-select'
                    value={this.state.highlight}
                    onChange={this.handleSelect}
                    options={[
                      { value: true, label: 'Oui' },
                      { value: false, label: 'Non' },
                    ]}
                    isMulti={false}
                    name={'highlight'}
                    isClearable
                  />
                </div>
                <label>Remplacement GT</label>
                <div data-cy='controlPointGT' style={{ width: '50%' }}>
                  <Select
                    placeholder='Valeur à Remplacer'
                    className='multi-select'
                    value={this.state.replaceMsaCarPart ? this.state.replaceMsaCarPart : false}
                    onChange={this.handleSelect}
                    options={[
                      { value: true, label: 'Oui' },
                      { value: false, label: 'Non' },
                    ]}
                    isMulti={false}
                    name={'replaceMsaCarPart'}
                    isClearable
                  />
                </div>
              </div>
            )}
            {!['DividerCheckpoint', 'PhotoCheckpoint', 'VideoCheckpoint'].includes(this.props.type) && (
              <div className='formInput'>
                <label>Special Type</label>
                <div data-cy='controlPointSpecialType' style={{ width: '50%' }}>
                  <Select
                    placeholder='Spécial Type'
                    className='multi-select'
                    value={this.state.specialType}
                    onChange={this.handleSelect}
                    options={this.loadSpecialTypeList()}
                    isMulti={false}
                    name={'specialType'}
                    isClearable
                  />
                </div>
              </div>
            )}
            {this.props.type === 'PhotoCheckpoint' && (
              <div className='formInput'>
                <label>Typologie de Défauts</label>
                <div data-cy='controlPointDefaultTypo' style={{ width: '50%' }}>
                  <Select
                    placeholder='Typologie'
                    className='multi-select'
                    value={this.state.defectPartVehicle}
                    onChange={this.handleSelect}
                    options={this.state.defectTypologies}
                    isMulti={false}
                    name={'defectPartVehicle'}
                    isClearable
                  />
                </div>
              </div>
            )}
            {this.props.type === 'PhotoCheckpoint' && (
              <div className='formInput'>
                <label>Filtre de Photo</label>
                <div data-cy='controlPointPhotoFilter' style={{ width: '50%' }}>
                  <Select
                    placeholder='Filtre'
                    className='multi-select'
                    value={this.state.filter}
                    onChange={this.handleSelect}
                    options={this.state.photoFilters}
                    isMulti={false}
                    name={'filter'}
                    isClearable
                  />
                </div>
              </div>
            )}
            <div className='formInput'>
              <label>Catégorie</label>
              <div data-cy='controlPointCategory' style={{ width: '50%' }}>
                <Select
                  data-cy='category'
                  placeholder='Catégorie'
                  className='multi-select cypress__select-checkpoint-category'
                  value={this.state.category}
                  onChange={this.handleSelect}
                  options={_.map(this.props.reportsV3.constants.category, (obj) => ({
                    ...obj,
                    value: obj.key,
                  }))}
                  isMulti={false}
                  name={'category'}
                  isClearable
                />
              </div>
            </div>
            {this.state.category && (
              <div className='formInput'>
                <label>Sous-catégorie</label>
                <div data-cy='controlPointSubCat' style={{ width: '50%' }}>
                  <Select
                    placeholder='Sous-Catégorie'
                    className='multi-select cypress__select-checkpoint-sub-category'
                    value={this.state.subCategory}
                    onChange={this.handleSelect}
                    options={this.state.subCategoryOptions}
                    isMulti={false}
                    name={'subCategory'}
                    isClearable
                    data-cy='subCategory'
                  />
                </div>
              </div>
            )}
            {this.props.type === 'VideoCheckpoint' && (
              <div>
                <div className='formInput'>
                  <label>Durée max en secondes</label>
                  <div style={{ width: '50%' }}>
                    <InputNumber
                      data-cy='maxDurationInSeconds'
                      name='maxDurationInSeconds'
                      style={{ width: '100%' }}
                      min='10'
                      max='60'
                      step='10'
                      value={this.state.maxDurationInSeconds}
                      onChange={this.selectDurationVideo}
                      stringMode
                    />
                  </div>
                </div>
                <div className='formInput flexbox'>
                  <Checkbox
                    data-cy='orientationPortrait'
                    style={{ marginRight: 10 }}
                    name='orientationPortrait'
                    checked={_.get(this.state.details, 'orientationPortrait')}
                    onChange={this.onChangeCheckbox}
                  />
                  <label>Orientation Portrait</label>
                </div>
                <div className='formInput flexbox'>
                  <Checkbox data-cy='photoTour' style={{ marginRight: 10 }} name='photoTour' checked={_.get(this.state.details, 'photoTour')} onChange={this.onChangeCheckbox} />
                  <label>Photo Tour</label>
                </div>
              </div>
            )}
            {this.props.type === 'TextCheckpoint' && (
              <div data-cy='controlPointCheckBoxMAJ' className='formInput flexbox'>
                <Checkbox style={{ marginRight: 10 }} checked={_.get(this.state.details, 'isUpperCase')} name='isUpperCase' onChange={this.onChangeCheckbox} />
                <label>Vérrouillage Majuscule</label>
              </div>
            )}
            {this.props.type === 'PhotoCheckpoint' && (
              <div>
                <div data-cy='controlPointCheckBoxPhotoFormat' className='formInput flexbox'>
                  <Checkbox style={{ marginRight: 10 }} name='4_3' checked={_.get(this.state.details, '4_3')} onChange={this.onChangeCheckbox} />
                  <label>Photo en Format 4/3</label>
                </div>
                <div className='formInput flexbox'>
                  <Checkbox style={{ marginRight: 10 }} name='isDocument' checked={_.get(this.state.details, 'isDocument')} onChange={this.onChangeCheckbox} />
                  <label>Photo de Document</label>
                </div>
                <div data-cy='controlPointCheckBoxPhotoTour' className='formInput flexbox'>
                  <Checkbox style={{ marginRight: 10 }} name='photoTour' checked={_.get(this.state.details, 'photoTour')} onChange={this.onChangeCheckbox} />
                  <label>Photo Tour</label>
                </div>
                <div data-cy='controlPointCheckBoxUnblurLicensePlate' className='formInput flexbox'>
                  <Checkbox style={{ marginRight: 10 }} name='unblurLicensePlate' checked={this.state.unblurLicensePlate} onChange={this.onChangeCheckbox} />
                  <label>Désactiver le floutage de la plaque d'immatriculation</label>
                </div>
              </div>
            )}
            {(this.props.type === 'BoolCheckpoint' || this.props.type === 'EnumCheckpoint') && (
              <div>
                <div data-cy='controlPointCheckBoxFRE' className='formInput flexbox'>
                  <Checkbox style={{ marginRight: 10 }} name='isDefect' checked={this.state.isDefect} onChange={this.onChangeCheckbox} />
                  <label>Génère un FRE</label>
                </div>
              </div>
            )}
            {this.props.type === 'BoolCheckpoint' && (
              <div data-cy='controlPointCheckBoxPCC' className='formInput flexbox'>
                <Checkbox style={{ marginRight: 10 }} name='showConditionalField' checked={this.state.showConditionalField} onChange={this.onChangeCheckbox} />
                <label>Points de Contrôle Conditionnels</label>
              </div>
            )}
            {this.props.type === 'EnumCheckpoint' && (
              <div className='formInput'>
                <label>Liste d'options (minimum 2)</label>
                <input data-cy='optionsList' type='text' value={this.state.newListTitle} name='newListTitle' onChange={this.onChange} />
                <button type='button' className='btnIcon add' onClick={this.onAddToList}>
                  <i data-cy='controlPointAdd' className='fas fa-plus-square' /> Ajouter
                </button>
                <div style={{ maxWidth: '65%' }}>
                  <DragDropCombo
                    items={this.state.list}
                    handleCheckboxChange={this.onChangeListCheckbox}
                    handleDeleteItem={this.onRemoveFromList}
                    onSortEnd={this.onSortComboEnd}
                    isAdmin={isAdmin}
                  />
                </div>
              </div>
            )}
            {this.renderConditionalFields()}
            {userCanEdit ? this.renderMsaParts() : null}
            {this.renderError()}
          </form>
        </div>
        <div className='center formButton'>
          <button type='button' onClick={this.closeModal} className='negativ'>
            {userCanEdit ? 'Annuler' : 'Fermer'}
          </button>
          {userCanEdit && !this.state.isLoading && (
            <button type='button' onClick={this.submit} className='positiv' disabled={this.state.isLoading}>
              Sauvegarder
            </button>
          )}
          {this.state.isLoading && (
            <span>
              <i className='fa fa-spinner fa-pulse' /> Sauvegarde en cours.
            </span>
          )}
        </div>
      </Modal>
    );
  }
}

ModalCreateControlPoint.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  reportsV3: PropTypes.object,
  isEdit: PropTypes.bool,
  type: PropTypes.string,
};

export default ModalCreateControlPoint;
