import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Text from '../../shared/atoms/Text';

const ViewInspectionMainTitle = ({ summary: { viewerUrl }, modalData: { readableId, generatedAt } }) => (
  <div className='modalContent '>
    <div className='mainTitle'>
      <Text fontStyle='bold'>Inspection n*{readableId}</Text>
    </div>
    <React.Fragment>
      <div className='center'>
        <Text fontStyle='demi' color='#9b9b9b'>
          Réalisé le {moment(generatedAt).format('DD/MM/YYYY à HH:mm')}
        </Text>
      </div>
      {viewerUrl && (
        <div className='center'>
          <a href={viewerUrl} target='_blank' rel='noreferrer noopener'>
            <Text fontStyle='demi' className='underline noMargin' color='#f5a623'>
              Rapport en ligne
            </Text>
          </a>
        </div>
      )}
    </React.Fragment>
    <div className='segmented-bar m-b-30'>
      <div className='selected'>
        <Text fontStyle='demi' color='#1f5288'>
          Détail de l'inspection
        </Text>
      </div>
    </div>
  </div>
);

ViewInspectionMainTitle.propTypes = {
  summary: PropTypes.shape({
    viewerUrl: PropTypes.string,
  }),
  modalData: PropTypes.shape({
    readableId: PropTypes.number,
    generatedAt: PropTypes.string,
  }),
};

export default ViewInspectionMainTitle;
