import { Table } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Col } from 'react-flexbox-grid';
import constants from '../../../../constants';
import ReportsServices from '../../../../services/ReportsService';
import EditableCell from './editableTranslationCell';

const columns = [
  {
    title: 'FR',
    dataIndex: 'fr',
    width: '20%',
  },
  {
    title: 'EN',
    dataIndex: 'en',
    render: (text, record) => <EditableCell data={record} lang='en' />,
  },
  {
    title: 'ES',
    dataIndex: 'es',
    render: (text, record) => <EditableCell data={record} lang='es' />,
  },
  {
    title: 'DE',
    dataIndex: 'de',
    render: (text, record) => <EditableCell data={record} lang='de' />,
  },
  {
    title: 'IT',
    dataIndex: 'it',
    render: (text, record) => <EditableCell data={record} lang='it' />,
  },
  {
    title: 'NL',
    dataIndex: 'nl',
    render: (text, record) => <EditableCell data={record} lang='nl' />,
  },
  {
    title: 'PT',
    dataIndex: 'pt',
    render: (text, record) => <EditableCell data={record} lang='pt' />,
  },
];

const LabelTranslationTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ pagination: true, current: 1, pageSize: 25 });

  const fetchLabels = (params = {}) => {
    setLoading(true);
    const paginationApi = {
      pagination: true,
      searchTerm: params.searchTerm === undefined ? searchTerm : params.searchTerm,
      pageNumber: params.pagination.current || pagination.current,
      itemsPerPage: params.pagination.pageSize || pagination.pageSize,
    };

    ReportsServices.getAll(constants.URI.v3.labelTranslations, paginationApi).then((res) => {
      setLoading(false);
      setData(res.items);
      setPagination({ ...params.pagination, total: res.totalItems });
    });
  };

  useEffect(() => {
    fetchLabels({ pagination });
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchLabels({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: { ...pagination, current: newPagination.current },
      ...filters,
    });
  };

  const searchTranslate = (elem) => {
    const newSearchTerm = _.get(elem, 'target.value', '');

    setSearchTerm(newSearchTerm);
    fetchLabels({ pagination, searchTerm: newSearchTerm });
  };

  return (
    <div className='table'>
      <Col xs={12} className='table-search'>
        <div className='search-container' style={{ display: 'inherit' }}>
          <div className='search-item'>
            <label>Traductions</label>
            <div className='search-input'>
              <div style={{ fontSize: 13, marginLeft: 5 }}>
                <i className='fa fa-search' />
                <DebounceInput
                  style={{
                    borderWidth: 0,
                    height: 35,
                    marginLeft: 10,
                    width: '98%',
                  }}
                  type='text'
                  name='search'
                  debounceTimeout={300}
                  value={searchTerm}
                  onChange={(e) => {
                    searchTranslate(e);
                  }}
                  placeholder='Recherchez...'
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Table
        columns={columns}
        scroll={{ y: '60vh', scrollToFirstRowOnChange: true }}
        size={'small'}
        rowKey={(record) => record._id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        // rowClassName={() => 'pointer'}
      />
    </div>
  );
};

export default LabelTranslationTable;
