import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import FileUploader from '../../shared/organisms/FileUploader';

const CreateInspectionStep2 = ({ inspection, filesToUpload, setStep, createNewInspection, loading, errorMessage }) => (
  <div className='inspectionModalPart2'>
    <div className='horizontalForm'>
      <div className='horizontalFormPart'>
        <div className='center'>
          <img src='https://res.cloudinary.com/monspecialisteauto-com/image/upload/v1521020214/car_1.png' alt='car-icon' />
        </div>
        <div className='smalTitleCaps center mt10px'>Informations du véhicule</div>
        <div className='smalTitleCaps mt20px left'>Marque du véhicule</div>
        <p data-cy='carBrand' className='content'>
          {_.get(inspection, 'vehicle.brand')}
        </p>
        <div className='smalTitleCaps left'>Modèle </div>
        <p data-cy='carModel' className='content'>
          {_.get(inspection, 'vehicle.model')}
        </p>
        <div className='smalTitleCaps left'>Date de mise en circulation</div>
        <p data-cy='carYear' className='content'>
          {_.get(inspection, 'vehicle.entryIntoServiceDate')}
        </p>
        <div className='smalTitleCaps left'>Immat</div>
        <p data-cy='carImmat' className='content'>
          {_.get(inspection, 'vehicle.immat')}
        </p>
        <div className='smalTitleCaps left'>Vin</div>
        <p data-cy='carVin' className='content'>
          {_.get(inspection, 'vehicle.vin')}
        </p>
        <div className='smalTitleCaps'>VAT Type</div>
        <p data-cy='vatType' className='content'>
          {_.get(inspection, 'vatType')}
        </p>
        <div className='smalTitleCaps'>Expected Price</div>
        <p data-cy='expectedPrice' className='content'>
          {_.get(inspection, 'expectedPrice')}
        </p>
        {_.get(inspection, 'vehicle.adsUrl') && (
          <>
            <div className='smalTitleCaps left'>Annonce</div>
            <p data-cy='adsUrl' className='content'>
              {_.get(inspection, 'vehicle.adsUrl')}
            </p>
          </>
        )}
      </div>
      <div className='horizontalFormPart'>
        <div className='center'>
          <img src='https://res.cloudinary.com/monspecialisteauto-com/image/upload/v1521020214/id-card_1.png' alt='card-icon' />
        </div>
        <div className='smalTitleCaps center mt10px'>Informations du client</div>
        <div className='smalTitleCaps left mt20px'>Nom du client</div>
        <p data-cy='sellerName' className='content'>
          {_.get(inspection, 'seller.name')}
        </p>
        <div className='smalTitleCaps'>Téléphone du client</div>
        <p data-cy='sellerPhone' className='content'>
          {_.get(inspection, 'seller.phone')}
        </p>
        <div className='smalTitleCaps'>Adresse</div>
        <p data-cy='appointmentAddress' className='content'>
          {_.get(inspection, 'appointment.address')}
        </p>
        <div className='smalTitleCaps'>Informations complémentaires</div>
        <p data-cy='complementaryInfos' className='content'>
          {_.get(inspection, 'infos')}
        </p>
      </div>
    </div>
    {filesToUpload?.length > 0 && (
      <div className='blockDocuments'>
        <div className='specialistFiche'>
          <div className='specialistFichePart'>
            <div className='smalTitleCaps'>Liste des documents téléchargés</div>
            <ul className='documents'>
              {filesToUpload.map((file, i) => (
                <li key={i}>
                  <FileUploader identifier='doc' showOnlyFiles={true} label={file.name} icon='documents' isUploading={false} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )}
    {errorMessage && (
      <div className='error'>
        <p>{errorMessage}</p>
      </div>
    )}
    <div className='center formButton mt20px'>
      <button type='button' onClick={() => setStep(1)} className='negativ'>
        Modifier les informations
      </button>
      <button type='button' onClick={() => createNewInspection()} className='positiv'>
        {loading ? (
          <span>
            <i className='fa fa-spinner fa-pulse' /> création en cours
          </span>
        ) : (
          'Valider'
        )}
      </button>
    </div>
  </div>
);

CreateInspectionStep2.propTypes = {
  inspection: PropTypes.shape({}),
  setStep: PropTypes.func,
  createNewInspection: PropTypes.func,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  filesToUpload: PropTypes.array,
};

export default CreateInspectionStep2;
