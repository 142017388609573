import { AllModules } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import { dateFormatter, dateFormatterWithTime, generateDatesComparator } from './gridDateUtils';

const getNestedValueforRow = (params) => _.get(params.data, params.colDef.field) || '';

const DefaultGridOptions = {
  modules: AllModules,
  suppressContextMenu: true,
  rowStyle: {
    borderTop: '1px solid #efefef',
    fontSize: '12px',
    color: '#1f5288',
  },
  columnTypes: {
    dateTimeColumn: {
      filter: 'agDateColumnFilter',
      filterParams: {
        browserDatePicker: true,
        comparator: generateDatesComparator(),
        filterOptions: ['equals', 'notEqual', 'lessThanOrEqual', 'greaterThanOrEqual'],
      },
      valueFormatter: dateFormatterWithTime,
      maxWidth: 200,
    },
    dateColumn: {
      filter: 'agDateColumnFilter',
      filterParams: {
        browserDatePicker: true,
        comparator: generateDatesComparator(),
      },
      valueFormatter: dateFormatter,
      maxWidth: 150,
    },
    idColumn: {
      maxWidth: 150,
    },
    actionsColumn: {
      maxWidth: 160,
    },
    numberColumn: { filter: 'agTextColumnFilter' },
  },
  defaultColDef: {
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellStyle: { padding: 10 },
    enableRowGroup: true,
    valueGetter: getNestedValueforRow,
    floatingFilterComponentParams: { suppressFilterButton: true },
    floatingFilter: true,
  },
  icons: {
    filter: '<i class="fas fa-filter"/>',
  },
  rowHeight: 32,
  cacheQuickFilter: true,
  rowSelection: 'multiple',
  suppressRowTransform: true,
  suppressColumnVirtualisation: true,
  copyHeadersToClipboard: true,
  enableRangeSelection: true,
  suppressDragLeaveHidesColumns: true,
  rowGroupPanelShow: 'always',
  groupUseEntireRow: true,
  enableCellTextSelection: true,
  getRowNodeId: (rowData) => rowData._id || '',
  statusBar: {
    statusPanels: [
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  },
  localeText: {
    filteredRows: 'Ligne(s) filtrée(s)',
    selectAll: 'Tout sélectionner',
    search: 'Rechercher...',
    searchOoo: 'Rechercher...',
    loadingOoo: 'Chargement...',
    filterOoo: 'Filtrer...',
    sum: 'Somme',
    none: 'Aucune',
    count: 'Nombre de cellule(s)',
    average: 'Moyenne',
    blanks: 'Vide',
    filters: 'Filtres',
    selectedRows: 'Sélectionnée(s)',
    expandAll: 'Développer toutes les lignes',
    collapseAll: 'Réduire toutes les lignes',
    totalRows: 'Nombre de ligne(s) total',
    totalAndFilteredRows: 'Lignes',
    pinLeft: 'Epingler la colonne à gauche',
    pinRight: 'Epingler la colonne à droite',
    noPin: "Annuler 'Epingler'",
    pinColumn: 'Epingler la colonne',
    groupBy: 'Grouper par',
    ungroupBy: "Annuler 'Grouper par'",
    to: 'à',
    of: 'parmi',
    contains: 'Contient',
    notContains: 'Ne contient pas',
    startsWith: 'Commence par',
    endsWith: 'Finit par',
    equals: 'Egal à',
    notEqual: 'Différent de',
    andCondition: 'Et',
    orCondition: 'Ou',
    noRowsToShow: 'Aucune ligne',
    autosizeThiscolumn: 'Ajustement auto de la taille de la colonne',
    autosizeAllColumns: 'Ajustement auto de la taille de toutes les colonne(s)',
    resetColumns: 'Réinitialiser toutes les colonnes',
    rowGroupColumnsEmptyMessage: 'Déplacer ici les colonne(s) à grouper',
    lessThan: 'Inférieur à',
    greaterThan: 'Supérieur à',
    lessThanOrEqual: 'Inférieur ou égal à',
    greaterThanOrEqual: 'Supérieur ou égal à',
    inRange: 'Dans',
    export: 'Exporter',
    csvExport: 'Export CSV',
    excelExport: 'Export Excel (.xlsx)',
    excelXmlExport: 'Export XML (.xml)',
    copy: 'Copier',
    copyWithHeaders: 'Copier avec les en-têtes de colonnes',
    paste: 'Coller',
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Afficher / cacher des colonnes',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressRowGroups: true,
        },
      },
    ],
    defaultToolPanel: '',
  },
};

export default DefaultGridOptions;
