import services from '../services/ReportsService';
import c from '../constants';

const DEV = c.ENV === 'development';

const getAll = (url, query, typeName = '') => {
  const request = () => ({ type: typeName.concat('_REQUEST') });
  const success = (payload) => ({ type: typeName.concat('_SUCCESS'), payload });
  const failure = (payload) => ({ type: typeName.concat('_FAILURE'), payload });

  return async (dispatch) => {
    dispatch(request());
    try {
      const res = await services.getAll(url, query);
      dispatch(success(res));
    } catch (error) {
      // eslint-disable-next-line no-console
      if (DEV) console.log('[ERROR] reportsV3Actions getAll:', url, query, error);
      dispatch(failure(error));
      // Crashlytics.recordError("API device.getAllInfo Error:", JSON.stringify(error));
    }
  };
};

const getOne = (url, query, typeName = '') => {
  const request = () => ({ type: typeName.concat('_REQUEST') });
  const success = (payload) => ({ type: typeName.concat('_SUCCESS'), payload });
  const failure = (payload) => ({ type: typeName.concat('_FAILURE'), payload });

  return async (dispatch) => {
    dispatch(request());
    try {
      const res = await services.getOne(url.concat(query));
      dispatch(success(res));
    } catch (error) {
      // eslint-disable-next-line no-console
      if (DEV) console.log('[ERROR] reportsV3Actions getOne:', url, query, error);
      dispatch(failure(error));
    }
  };
};

const showReportsV3Modal = (payload) => {
  const action = { type: 'SHOW_REPORTSV3_MODAL', payload };
  return (dispatch) => {
    dispatch(action);
  };
};

const hideReportsV3Modal = () => {
  const action = { type: 'HIDE_REPORTSV3_MODAL' };
  return (dispatch) => {
    dispatch(action);
  };
};

const setFilterFacets = (payload) => {
  const action = {
    type: 'SET_FILTER_FACETS',
    payload,
  };
  return (dispatch) => {
    dispatch(action);
  };
};

const setFilterSearch = (payload) => {
  const action = {
    type: 'SET_FILTER_SEARCH',
    payload,
  };
  return (dispatch) => {
    dispatch(action);
  };
};

const executeAction = (actionName, query) => {
  switch (actionName) {
    case 'checkpoints':
      return getAll(c.URI.v3.checkpoints, query, 'RV3_GET_CHECKPOINTS');
    case 'oneCheckpoint':
      return getOne(c.URI.v3.checkpoints, query, 'RV3_GET_ONE_CHECKPOINT');
    case 'reports':
      return getAll(c.URI.v3.inspection, query, 'RV3_GET_REPORTS');
    case 'oneReport':
      return getOne(c.URI.v3.inspection, query, 'RV3_GET_ONE_REPORT');
    case 'rules':
      return getAll(c.URI.v3.cpRules, query, 'RV3_GET_RULES');
    case 'constants':
      return getAll(c.URI.v3.cpConstants, query, 'RV3_GET_CONSTANTS');
    case 'reportByUser':
      return getAll(c.URI.v3.cpConstants, query, 'RV3_GET_CHECKPOINTS_CONSTANTS');
    // case 'repairCostByReport':
    //   return getOne(c.URI.v3.inspection, query.concat('/repair-cost'), 'RV3_GET_REPAIR_COST_BY_REPORT');
    case 'defectsByReport':
      return getOne(c.URI.v3.inspection, query.concat('/defects'), 'RV3_GET_DEFECTS_BY_REPORT');
    case 'msaCarParts':
      return getAll(c.URI.v3.msaCarPart, query, 'RV3_GET_MSA_CAR_PARTS');
    case 'cupiCarParts':
      return getAll(c.URI.v3.cupiCarPart, query, 'RV3_GET_CUPIS_CAR_PARTS');
    default:
      return null;
  }
};
export const ReportsV3 = {
  executeAction,
  setFilterFacets,
  setFilterSearch,
  showReportsV3Modal,
  hideReportsV3Modal,
};

export default ReportsV3;
