import React from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import Loading from '../atoms/Loading';

const DrawerWrapperBody = ({ children, isOpen, title, closeDrawer, isLoading, placement = 'right', mask = true }) => (
  <Drawer title={title} placement={placement} closable={isOpen} onClose={closeDrawer} visible={isOpen} mask={mask}>
    <div>{isLoading ? <Loading /> : children}</div>
  </Drawer>
);

DrawerWrapperBody.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.any,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeDrawer: PropTypes.func,
  placement: PropTypes.string,
  mask: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  application: state.application,
});
export default connect(mapStateToProps, null)(DrawerWrapperBody);
