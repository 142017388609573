import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
import Loading from '../atoms/Loading';

const ModalBody = ({ children, isOpen, updateAction, closeModal, actionSave, isLoading, error, title, application, loadingButton, classToAdd = '' }) => {
  const { isAdmin, role } = application.user;
  const userCanEdit = isAdmin === true || role === 'superChief';

  const renderError = () => {
    if (!error) return null;
    return (
      <div className='error'>
        <p>{error}</p>
      </div>
    );
  };

  const renderValidButtonText = () => {
    if (loadingButton) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse' /> Sauvegarde en cours.
        </span>
      );
    }
    return (
      <button type='button' onClick={actionSave} className='positiv' disabled={loadingButton}>
        Sauvegarder
      </button>
    );
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel='Facturation spécialiste'
      overlayClassName='modal-overlay'
      className='modal modalScaleConfiguration'
      style={!userCanEdit ? { content: { cursor: 'not-allowed' } } : {}}>
      <div className='mainTitle header'>
        <span className='modalFontBig'>{title}</span>
        <span className='close' onClick={closeModal}>
          &times;
        </span>
      </div>
      <Col className={`modalContent ${classToAdd}`} style={!userCanEdit ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
        {isLoading ? <Loading /> : children}
      </Col>
      {error && renderError()}
      <div className='center formButton'>
        <button type='button' onClick={closeModal} className='negativ'>
          {userCanEdit && updateAction ? 'Annuler' : 'Fermer'}
        </button>
        {userCanEdit && updateAction && renderValidButtonText()}
      </div>
    </Modal>
  );
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  application: PropTypes.object,
  isLoading: PropTypes.bool,
  loadingButton: PropTypes.bool,
  userCanEdit: PropTypes.bool,
  isOpen: PropTypes.bool,
  updateAction: PropTypes.bool,
  actionSave: PropTypes.func,
  error: PropTypes.any,
  closeModal: PropTypes.func,
  classToAdd: PropTypes.string,
};

const mapStateToProps = (state) => ({
  application: state.application,
});
export default connect(mapStateToProps, null)(ModalBody);
