import moment from 'moment';
import departements from './departements';

export const getSpecialistStatus = (searchFormData) => {
  if (searchFormData.status === 'actif') {
    return { value: 'actif', label: 'Actif' };
  }
  if (searchFormData.status === 'confirme') {
    return { value: 'confirme', label: 'Confirmé' };
  }
  if (searchFormData.status === 'non_confirme') {
    return { value: 'non_confirme', label: 'Non confirmé' };
  }
  if (searchFormData.status === 'nouveaux') {
    return { value: 'nouveaux', label: 'Nouvel arrivé' };
  }
  if (searchFormData.status === 'bloques') {
    return { value: 'bloques', label: 'Bloqués' };
  }
  if (searchFormData.status === 'inactifs') {
    return { value: 'inactifs', label: 'Inactifs' };
  }
  return { value: 'aucun', label: 'aucun status' };
};

export const statusToParams = (status) => {
  const searchFormData = {};
  switch (status) {
    case 'actif': {
      searchFormData.status = 'actif';
      break;
    }
    case 'confirme': {
      searchFormData.status = 'confirme';
      break;
    }
    case 'non_confirme': {
      searchFormData.status = 'non_confirme';
      break;
    }
    case 'nouveaux': {
      searchFormData.status = 'nouveaux';
      break;
    }
    case 'bloques': {
      searchFormData.status = 'bloques';
      break;
    }
    case 'inactifs': {
      searchFormData.status = 'inactifs';
      break;
    }
    default: {
      delete searchFormData.status;
      break;
    }
  }
  return searchFormData;
};

export const getSearchStatusFunction = (_searchFormData, value) => {
  const searchFormData = _searchFormData;
  switch (value) {
    case 'actif': {
      searchFormData.status = 'actif';
      break;
    }
    case 'confirme': {
      searchFormData.status = 'confirme';
      break;
    }
    case 'non_confirme': {
      searchFormData.status = 'non_confirme';
      break;
    }
    case 'nouveaux': {
      searchFormData.status = 'nouveaux';
      break;
    }
    case 'bloques': {
      searchFormData.status = 'bloques';
      break;
    }
    case 'inactifs': {
      searchFormData.status = 'inactifs';
      break;
    }
    default: {
      delete searchFormData.status;
      break;
    }
  }
  return searchFormData;
};

export const transformDataSpecialist = (specialist, specialistKind) => ({
  ...specialist,
  createdAt: specialist.createdAt && {
    label: moment(specialist.createdAt).format('LL'),
    value: specialist.createdAt,
  },
  experience: specialist.comments && specialist.comments.experience ? specialist.comments.experience : '',
  dateOfBirth: specialist.dateOfBirth && {
    label: moment(specialist.dateOfBirth).format('LL'),
    value: specialist.dateOfBirth,
  },
  age: specialist.dateOfBirth && moment().diff(moment(specialist.dateOfBirth), 'years'),
  dateStatus: (specialistKind === 'inspect')
    ? (specialist.statusHistoryInspect && specialist.statusHistoryInspect.length > 0) && {
      label: moment(specialist.statusHistoryInspect.slice(-1)[0].updatedAt).format('LL'),
      value: specialist.statusHistoryInspect.slice(-1)[0].updatedAt,
    }
    : (specialist.statusHistoryStaff && specialist.statusHistoryStaff.length > 0) && {
      label: moment(specialist.statusHistoryStaff.slice(-1)[0].updatedAt).format('LL'),
      value: specialist.statusHistoryStaff.slice(-1)[0].updatedAt,
    },
  search: `${specialist.firstname} ${specialist.lastname} ${specialist.email} ${specialist.phone} ${specialist.city}`.toLowerCase(),
});

// eslint-disable-next-line consistent-return
export const getSpecialistAndSkills = (specialistsState, specialistSkillsState, specialistKind) => {
  const specialistsList = [];
  const skillsList = [];
  const departmentsList = [];
  try {
    if (!specialistsState || !specialistSkillsState) {
      return null;
    }
    const departementFounds = [];
    const formatListSpecialits = specialistsState.map((specialistToMap) => {
      const specialistSkills = specialistSkillsState.filter((skill) => specialistToMap.skills && specialistToMap.skills.includes(skill._id)).map((skill) => skill.label);
      const departement = specialistToMap.zipCode && specialistToMap.zipCode.slice(0, 2);
      let foundDepartment = departementFounds.find((dep) => dep.code === departement);
      if (!foundDepartment) {
        const depName = departement && departements[departement] ? departements[departement] : '';
        foundDepartment = { code: departement, name: depName };
        departementFounds.push(foundDepartment);
      }
      return {
        ...transformDataSpecialist(specialistToMap, specialistKind),
        departement,
        specialistSkills,
      };
    });
    specialistsList.push(formatListSpecialits);
    departmentsList.push(departementFounds.sort((a, b) => Number(a.code) - Number(b.code)));
    skillsList.push(specialistSkillsState);
    return [specialistsList, skillsList, departmentsList];
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  }
};

export const formatSpecialist = (specialist, skills) => {
  const specialistSkills = skills.filter((skill) => specialist.skills && specialist.skills.includes(skill._id)).map((skill) => skill.label);
  return {
    ...transformDataSpecialist(specialist),
    specialistSkills,
  };
};

export const filterSpecialistsByMenu = (specialists, menu) => (menu === 'inspect') ? specialists.filter((specialist) => specialist.status)
  : specialists.filter((specialist) => specialist.stafferStatus);
