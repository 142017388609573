const defaultState = {
  rows: [],
  length: 0,
  pageNumber: 0,
  itemsPerPage: 0,
  totalPages: 0,
  modalData: null,
  loading: false,
  loadingOne: false,
  loadingConstants: false,
  loadingDefects: false,
  filterFacets: '',
  filterSearch: '',
  // repairCostByReport: {},
  defectsByReport: [],
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'RV3_GET_CHECKPOINTS_REQUEST': {
      return {
        ...state,
        rows: [],
        length: 0,
        pageNumber: 0,
        itemsPerPage: 0,
        totalPages: 0,
        loading: true,
      };
    }
    case 'RV3_GET_CHECKPOINTS_SUCCESS': {
      return {
        ...state,
        rows: action.payload.items,
        length: action.payload.totalItems,
        pageNumber: action.payload.pageNumber,
        itemsPerPage: action.payload.itemsPerPage,
        totalPages: action.payload.totalPages,
        loading: false,
      };
    }
    case 'RV3_GET_CHECKPOINTS_FAILURE': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case 'RV3_GET_RULES_REQUEST': {
      return {
        ...state,
        rows: [],
        length: 0,
        pageNumber: 0,
        itemsPerPage: 0,
        totalPages: 0,
        loading: true,
      };
    }
    case 'RV3_GET_RULES_SUCCESS': {
      return {
        ...state,
        rows: action.payload.items,
        length: action.payload.totalItems,
        pageNumber: action.payload.pageNumber,
        itemsPerPage: action.payload.itemsPerPage,
        totalPages: action.payload.totalPages,
        loading: false,
      };
    }
    case 'RV3_GET_RULES_FAILURE': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case 'RV3_GET_REPORTS_REQUEST': {
      return {
        ...state,
        rows: [],
        length: 0,
        pageNumber: 0,
        itemsPerPage: 0,
        totalPages: 0,
        loading: true,
      };
    }
    case 'RV3_GET_REPORTS_SUCCESS': {
      return {
        ...state,
        rows: action.payload.items,
        length: action.payload.totalItems,
        pageNumber: action.payload.pageNumber,
        itemsPerPage: action.payload.itemsPerPage,
        totalPages: action.payload.totalPages,
        loading: false,
      };
    }
    case 'RV3_GET_REPORTS_FAILURE': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case 'RV3_GET_CONSTANTS_REQUEST': {
      return {
        ...state,
        loadingConstants: true,
      };
    }
    case 'RV3_GET_CONSTANTS_SUCCESS': {
      return {
        ...state,
        constants: action.payload,
        loadingConstants: false,
      };
    }
    case 'RV3_GET_CONSTANTS_FAILURE': {
      return {
        ...state,
        error: action.payload,
        loadingConstants: false,
      };
    }
    case 'RV3_GET_ONE_CHECKPOINT_REQUEST': {
      return {
        ...state,
        modalData: {},
        loadingOne: true,
      };
    }
    case 'RV3_GET_ONE_CHECKPOINT_SUCCESS': {
      return {
        ...state,
        modalData: action.payload,
        loadingOne: false,
      };
    }
    case 'RV3_GET_ONE_CHECKPOINT_FAILURE': {
      return {
        ...state,
        error: action.payload,
        loadingOne: false,
      };
    }
    case 'RV3_GET_ONE_REPORT_REQUEST': {
      return {
        ...state,
        modalData: {},
        loadingOne: true,
      };
    }
    case 'RV3_GET_ONE_REPORT_SUCCESS': {
      return {
        ...state,
        modalData: action.payload,
        loadingOne: false,
      };
    }
    case 'RV3_GET_ONE_REPORT_FAILURE': {
      return {
        ...state,
        error: action.payload,
        loadingOne: false,
      };
    }
    // case 'RV3_GET_REPAIR_COST_BY_REPORT_REQUEST': {
    //   return {
    //     ...state,
    //     repairCostByReport: {},
    //     loadingOne: true,
    //   };
    // }
    // case 'RV3_GET_REPAIR_COST_BY_REPORT_SUCCESS': {
    //   return {
    //     ...state,
    //     repairCostByReport: action.payload,
    //     loadingOne: false,
    //   };
    // }
    // case 'RV3_GET_REPAIR_COST_BY_REPORT_FAILURE': {
    //   return {
    //     ...state,
    //     error: action.payload,
    //     loadingOne: false,
    //   };
    // }
    case 'RV3_GET_DEFECTS_BY_REPORT_REQUEST': {
      return {
        ...state,
        defectsByReport: [],
        loadingDefects: true,
      };
    }
    case 'RV3_GET_DEFECTS_BY_REPORT_SUCCESS': {
      return {
        ...state,
        defectsByReport: action.payload,
        loadingDefects: false,
      };
    }
    case 'RV3_GET_DEFECTS_BY_REPORT_FAILURE': {
      return {
        ...state,
        error: action.payload,
        loadingDefects: false,
      };
    }
    case 'SHOW_REPORTSV3_MODAL': {
      return {
        ...state,
        modalName: action.payload.modalName,
        modalData: action.payload.modalData,
      };
    }
    case 'HIDE_REPORTSV3_MODAL': {
      return {
        ...state,
        modalName: null,
        modalData: {},
      };
    }
    case 'SET_FILTER_FACETS': {
      return {
        ...state,
        filterFacets: action.payload,
      };
    }
    case 'SET_FILTER_SEARCH': {
      return {
        ...state,
        filterSearch: action.payload,
      };
    }
    case 'RV3_GET_MSA_CAR_PARTS_REQUEST':
    case 'RV3_GET_CUPIS_CAR_PARTS_REQUEST': {
      return {
        ...state,
        rows: [],
        length: 0,
        pageNumber: 0,
        itemsPerPage: 0,
        totalPages: 0,
        loading: true,
      };
    }
    case 'RV3_GET_MSA_CAR_PARTS_SUCCESS':
    case 'RV3_GET_CUPIS_CAR_PARTS_SUCCESS': {
      return {
        ...state,
        rows: action.payload.items,
        length: action.payload.totalItems,
        pageNumber: action.payload.pageNumber,
        itemsPerPage: action.payload.itemsPerPage,
        totalPages: action.payload.totalPages,
        loading: false,
      };
    }
    case 'RV3_GET_MSA_CAR_PARTS_FAILURE':
    case 'RV3_GET_CUPIS_CAR_PARTS_FAILURE': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
