import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import InspectionsServices from '../../../services/InspectionsServices';

const ModalCancelInspection = (props) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const resetForm = () => {
    setLoading(false);
    setComment('');
    setErrorMessage('');
  };

  const cancelInspection = () => {
    setErrorMessage('');
    setLoading(true);

    if (comment === '') {
      setErrorMessage("Assurez-vous d'avoir saisi un commentaire");
      setLoading(false);
      return;
    }

    InspectionsServices.cancelPlannedInspections([
      {
        reason: 'CLIENT_CANCEL',
        comment,
        inspectionsIds: [props.inspection._id],
      },
    ])
      .then((results) => {
        setLoading(false);
        if (results.status === 200) {
          setComment('');
          props.onRequestClose();
        } else {
          throw new Error(_.get(results, 'body.error') || "Une erreur s'est produite");
        }
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(e.message);
      });
  };

  useEffect(() => {
    resetForm();
  }, [_.get(props, 'inspection._id')]);

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={props.isOpen}
      onRequestClose={() => props.onRequestClose({})}
      contentLabel='Modal annuler inspection'
      overlayClassName='modal-overlay'
      className='modal modalInspectionBan'
    >
      <div className='modalContent'>
        <div className='center inspectionModalBan'>
          <img className='' src='https://res.cloudinary.com/monspecialisteauto-com/image/upload/v1521061595/car-parts.png' />
        </div>
        <div className='mainTitle mt10px'>Annulation d'inspection</div>
        <p className='content'>
          Vous êtes sur le point d'annuler l'inspection concernant le véhicule{' '}
          <strong>
            {_.get(props, 'inspection.vehicle.brand')} {_.get(props, 'inspection.vehicle.model')} {_.get(props, 'inspection.vehicle.year')}
          </strong>{' '}
          à <strong>{_.get(props, 'inspection.appointment.address')}</strong>.
        </p>
        <div className='formInput'>
          <label>Motif de l'annulation</label>
          <textarea rows='2' value={comment} placeholder="Motif de l'annulation " onChange={(e) => setComment(e.target.value)} />
        </div>
        <p className='content'>Souhaitez-vous continuer ?</p>
        {errorMessage && (
          <div className='error'>
            <p>{errorMessage}</p>
          </div>
        )}
        <div className='center formButton mt20px'>
          <button type='button' onClick={() => props.onRequestClose({})} className='negativ'>
            Non
          </button>
          <button type='button' onClick={cancelInspection} className='positiv' disabled={loading}>
            {loading ? <i className='fa fa-spinner fa-pulse'></i> : 'Oui, annuler'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalCancelInspection.propTypes = {
  isOpen: PropTypes.bool,
  inspection: PropTypes.object,
  onRequestClose: PropTypes.func,
};

export default ModalCancelInspection;
