// Bosket imports
import { TreeView } from '@bosket/react';
import { string } from '@bosket/tools';
import { Empty } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

class SideMenuTreeView extends React.PureComponent {
  state = {
    // Property of the model containing children
    category: 'items',
    strategies: {
      // Select on click
      click: ['select'],
      // Use keyboard modifiers
      selection: ['single'],
      // Use the opener to control element folding
      // fold: ['opener-control'],
      fold: ['not-selected', 'no-child-selection', 'opener-control'],
    },
    // Custom css root class name
    css: { TreeView: 'TreeViewDemo' },
    // Transitions using react transition group
    // transition: {
    //   transitionName: 'TreeViewDemoTransition',
    //   transitionEnterTimeout: 300,
    //   transitionLeaveTimeout: 300,
    // },
  };

  // Custom display (with an anchor tag)
  display = (item) => <a>{item.label}</a>;

  search = (input) => (i) => string(i.label).contains(input);

  // Alphabetical sort
  // sort = (a, b) => a.label.localeCompare(b.label);
  // Unique identifier using an item name
  unique = (item) => item.id || item.label;

  // On selection, update the selection array in the parent component
  onSelect = (items) => this.props.update(items);

  // Disable elements having children
  disabled = (item) => item.items;

  // The TreeView rendering
  render = () => {
    let res = null;
    if (this.props.model && this.props.model.length === 0) {
      res = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{ marginTop: 100 }} description='Aucun resultat' />;
    } else {
      res = (
        <TreeView
          selection={this.props.selection}
          search={this.search}
          display={this.display}
          // sort={this.sort}
          unique={this.unique}
          onSelect={this.onSelect}
          // disabled={this.disabled}
          model={this.props.model}
          {...this.state}></TreeView>
      );
    }
    return res;
  };
}

SideMenuTreeView.propTypes = {
  update: PropTypes.func,
  selection: PropTypes.array,
  data: PropTypes.object,
  model: PropTypes.array,
  fullReport: PropTypes.object,
};

export default SideMenuTreeView;
