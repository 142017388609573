import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import Select from 'react-select';
import Switch from 'react-switch';
import ConcessionsActions from '../../../actions/Concessions';
import EntitiesServices from '../../../services/EntitiesServices';
import CountrySelect from '../../concessions/shared/atoms/CountrySelect';
import RenderError from '../atoms/RenderError';
import Text from '../atoms/Text';
import ModalFooterButton from '../molecules/ModalFooterButton';

Modal.setAppElement('body');

const ModalCreateEditGroup = (props) => {
  const { dispatch, application, concessions } = props;
  const { modalData, modalName } = concessions;

  // if modal is for creation or edition
  const createEntity = modalName === 'createEntity';
  const editEntity = modalName === 'editEntity';
  const viewEntity = modalName === 'viewEntity';
  const [name, setName] = useState(modalData?.name);
  const [country, setCountry] = useState(modalData?.country);
  const [error, setError] = useState(null);

  const [GTEstimate, setGTEstimate] = useState(modalData?.options?.useGtEstimate || false);
  const [blurPhotos, setBlurPhotos] = useState(modalData?.options?.blurPhotos || false);
  const [repairCost, setRepairCost] = useState({});
  const [pdf, setPdf] = useState({});
  const [pdfUrl, setPdfUrl] = useState(modalData?.options?.pdf?.fileUrl || '');

  const repairCostOptions = [
    { value: 'noTotal;noPrices', label: 'Masquer les FRE' },
    { value: 'noPrices', label: 'Montrer le total uniquement' },
    { value: 'completeReport', label: 'Rapport Complet' },
  ];

  const PDFoptions = [
    { value: 'clientRecap', label: 'PDF sans chiffrage (recap dommages)' },
    { value: 'informationSheet', label: 'Fiche de reprise' },
    { value: 'restitutionRecap', label: 'Fiche de reprise + dégâts détaillés' },
    { value: 'pdfViewer', label: 'PDF du viewer' },
  ];

  useEffect(() => {
    const repairCostfound = repairCostOptions.find((object) => object.value === modalData?.viewerConfig);
    setRepairCost(repairCostfound);
    const PDFfound = PDFoptions.find((object) => object.value === modalData?.options?.pdf?.generatedSheet);
    setPdf(PDFfound);
  }, [concessions]);

  const closeModal = () => {
    const { user } = application;
    dispatch(ConcessionsActions.getActionToDispatch({ type: 'HIDE_MODAL' }));
    ConcessionsActions.reloadTable({
      index: 'entities',
      role: user.role === 'superChief' ? 'chiefReseller' : 'reseller',
    })(dispatch, application);
  };

  const onChangeDataSelected = (countrySelected) => {
    setCountry(countrySelected);
  };

  const onClearInput = () => {
    setCountry(null);
  };

  function selectedOptions(selection, obj) {
    switch (obj.name) {
      case 'PDFoption':
        return setPdf(selection);
      case 'repairCostOption':
        return setRepairCost(selection);
      default:
        return null;
    }
  }

  const submit = async (e) => {
    e.preventDefault();

    if (!name) {
      setError('Veuillez entrer le nom du groupe.');
      return;
    }

    if (!country) {
      setError('Veuillez sélectionner un pays.');
      return;
    }

    if (!repairCost?.value) {
      setError('Veuillez sélectionner une option de FRE.');
      return;
    }

    if (createEntity) {
      const createdResponse = await EntitiesServices.createEntity({
        name,
        countryId: country._id,
        viewerConfig: repairCost?.value,
        generatedSheet: pdf?.value,
        useGtEstimate: GTEstimate,
        blurPhotos,
        fileUrl: pdfUrl,
      }).catch((err) => setError(err ? err.error : 'Une erreur est survenue'));

      if (createdResponse && createdResponse.status < 400) {
        closeModal();
      }
    }
    if (editEntity) {
      const updatedResponse = await EntitiesServices.updateEntity(
        {
          countryId: country._id,
          name,
          viewerConfig: repairCost?.value,
          generatedSheet: pdf?.value,
          useGtEstimate: GTEstimate,
          blurPhotos,
          fileUrl: pdfUrl,
        },
        concessions.modalData._id,
      ).catch((err) => setError(err.error || 'Une erreur est survenue'));

      if (updatedResponse && updatedResponse.status < 400) {
        closeModal();
      }
    }
  };

  const repairCostValue = repairCostOptions.find((object) => object.value === modalData?.viewerConfig)?.label;
  const pdfValue = PDFoptions.find((object) => object.value === modalData?.options?.pdf?.generatedSheet)?.label;
  let maintitle;
  switch (modalName) {
    case 'createEntity':
      maintitle = "Création d'un groupe";
      break;
    case 'editEntity':
      maintitle = "Édition d'un groupe";
      break;
    case 'viewEntity':
      maintitle = 'Visualisation du groupe';
      break;
    default:
      break;
  }

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel='Modal voir entité'
      overlayClassName='modal-overlay'
      className='modal modalConcession'
    >
      <div className='modalContent'>
        <div className='mainTitle'>{maintitle}</div>

        {viewEntity ? (
          <div>
            <Row>
              <Col xs={12} md={6}>
                <div data-cy='groupName' className='formInput'>
                  <label>Nom du groupe</label>
                  <Text fontStyle='normal' size={14}>
                    {modalData?.name || 'Non défini'}
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div data-cy='country' className='formInput'>
                  <label>Pays</label>
                  <Text fontStyle='normal' size={14}>
                    {modalData?.country?.name || 'Non défini'}
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div data-cy='GTestimate' className='formInput'>
                  <label>Estimation GT</label>
                  <Text fontStyle='normal' size={14}>
                    {modalData?.options?.useGtEstimate ? 'Oui' : 'Non'}
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div data-cy='blurPhotos' className='formInput'>
                  <label>Floutage des immatriculations</label>
                  <Text fontStyle='normal' size={14}>
                    {blurPhotos ? 'Oui' : 'Non'}
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div data-cy='repairCost' className='formInput'>
                  <label>FRE</label>
                  <Text fontStyle='normal' size={14}>
                    {repairCostValue || 'Non défini'}
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div data-cy='generatedPDF' className='formInput'>
                  <label>PDF généré</label>
                  <Text fontStyle='normal' size={14}>
                    {pdfValue || 'Non défini'}
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div data-cy='url' className='formInput'>
                  <label>URL du Logo pour PDF</label>
                  <Text fontStyle='normal' size={14}>
                    <a href={modalData.options?.pdf?.fileUrl || undefined} target='_blank' rel='noreferrer'>
                      {modalData.options?.pdf?.fileUrl || 'Non Défini'}
                    </a>
                  </Text>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className='center formButton'>
                  <button type='button' onClick={closeModal} className='negativ'>
                    Fermer
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <form className='mt10px' onSubmit={submit}>
              <Row>
                <Col xs={12}>
                  <div className='formInput'>
                    <label>Nom du groupe</label>
                    <input data-cy='groupName' defaultValue={name} type='text' name='name' placeholder='Groupe MSA, etc...' onChange={(e) => setName(e.target.value)} />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className='formInput withSearch'>
                    {!country ? (
                      <CountrySelect onSelect={onChangeDataSelected} />
                    ) : (
                      <>
                        <input data-cy='countrySelect' type='text' disabled defaultValue={country.name} />
                        <i data-cy='deleteCountry' className='fa fa-times-circle' onClick={onClearInput} />
                        <Text fontStyle='normal' size={10} onClick={onClearInput}>
                          Supprimer le pays sélectionné
                        </Text>
                      </>
                    )}
                  </div>
                </Col>
                <Col xs={12}>
                  <div className='formInput'>
                    <label>Afficher/Masquer FRE</label>
                    <Select
                      data-cy='repairCostSelect'
                      placeholder={'Choisissez une Option'}
                      className='multi-select'
                      value={repairCost}
                      onChange={selectedOptions}
                      options={repairCostOptions}
                      name={'repairCostOption'}
                      isClearable
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className='formInput'>
                    <label>Choisir le PDF à générer</label>
                    <Select
                      data-cy='PDFSelect'
                      placeholder={'Choisissez une Option'}
                      className='multi-select'
                      value={pdf}
                      onChange={selectedOptions}
                      options={PDFoptions}
                      name={'PDFoption'}
                      isClearable
                    />
                  </div>
                </Col>

                <Col xs={12}>
                  <Row xs={12}>
                    <Col xs={6}>
                      <div className='formInput'>
                        <label>Floutages immatriculations</label>
                        <div>
                          <Switch
                            data-cy='blurSwitch'
                            checked={blurPhotos}
                            onChange={(value) => setBlurPhotos(value)}
                            handleDiameter={12}
                            offColor='#DDD'
                            onColor='#1f5288'
                            offHandleColor='#fff'
                            onHandleColor='#08f'
                            height={20}
                            width={40}
                            className='react-switch'
                            id='small-radius-switch'
                            name='blurPhotos'
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className='formInput'>
                        <label>Estimation GT</label>
                        <div>
                          <Switch
                            data-cy='GTSwitch'
                            checked={GTEstimate}
                            onChange={(value) => setGTEstimate(value)}
                            handleDiameter={12}
                            offColor='#DDD'
                            onColor='#1f5288'
                            offHandleColor='#fff'
                            onHandleColor='#08f'
                            height={20}
                            width={40}
                            className='react-switch'
                            id='small-radius-switch'
                            name='GTEstimate'
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12}>
                  <div className='formInput'>
                    <label>URL du logo PDF</label>
                    <input
                      type='text'
                      name='urlValue'
                      data-cy='urlInput'
                      placeholder='https://monspecialisteauto.com'
                      defaultValue={pdfUrl}
                      onChange={(e) => setPdfUrl(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              {error && <RenderError error={error} />}
              <ModalFooterButton data={concessions} actionCancel={closeModal} />
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

ModalCreateEditGroup.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
  createEntity: PropTypes.func,
};

export default ModalCreateEditGroup;
