import EntitiesServices from '../services/EntitiesServices';

// Action types
export const REQUEST_ENTITIES = 'REQUEST_ENTITIES';
export const GET_ENTITIES = 'GET_ENTITIES';
export const GET_ENTITIES_SUCCESS = 'GET_ENTITIES_SUCCESS';
export const GET_ENTITIES_ERROR = 'GET_ENTITIES_ERROR';
export const REQUEST_ENTITY = 'REQUEST_ENTITY';
export const GET_ENTITY = 'GET_ENTITY';
export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const GET_ENTITY_ERROR = 'GET_ENTITY_ERROR';
export const REQUEST_UPDATE_ENTITY = 'REQUEST_UPDATE_ENTITY';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export const UPDATE_ENTITY_ERROR = 'UPDATE_ENTITY_ERROR';
export const REQUEST_CLEAR_ENTITY = 'REQUEST_CLEAR_ENTITY';
export const GET_CLEAR_ENTITY = 'GET_CLEAR_ENTITY';
export const GET_CLEAR_ENTITY_SUCCESS = 'GET_CLEAR_ENTITY_SUCCESS';
export const GET_CLEAR_ENTITY_ERROR = 'GET_CLEAR_ENTITY_ERROR';
export const SERVER_KILLED_SESSION = 'SERVER_KILLED_SESSION';

// Action creator
export const serverKilledSession = () => ({
  type: SERVER_KILLED_SESSION,
});

export const requestEntities = () => ({
  type: REQUEST_ENTITIES,
});

export const getEntitiesSuccess = (entities) => ({
  type: GET_ENTITIES_SUCCESS,
  entities,
});

export const getEntitiesError = (error) => ({
  type: GET_ENTITIES_ERROR,
  error,
});

export const getEntities = async (dispatch) => {
  try {
    dispatch(requestEntities());
    const result = await EntitiesServices.getAllEntities();
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getEntitiesError(result.body.message || 'Failed'));
    return dispatch(getEntitiesSuccess(result.body.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestEntity = () => ({
  type: REQUEST_ENTITY,
});

export const getEntitySuccess = (entity) => ({
  type: GET_ENTITY_SUCCESS,
  entity,
});

export const getEntityError = (error) => ({
  type: GET_ENTITY_ERROR,
  error,
});

export const getEntity = async (dispatch, entityId) => {
  try {
    dispatch(requestEntity());
    const result = await EntitiesServices.getEntity(entityId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getEntityError(result.body.message || result.body.error || 'Failed'));
    return dispatch(getEntitySuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestUpdateEntity = () => ({
  type: REQUEST_UPDATE_ENTITY,
});

export const updateEntitySuccess = (entity) => ({
  type: UPDATE_ENTITY_SUCCESS,
  entity,
});

export const updateEntityError = (error) => ({
  type: UPDATE_ENTITY_ERROR,
  error,
});

export const updateEntity = async (dispatch, entitiesData, entityId) => {
  try {
    dispatch(requestUpdateEntity());
    const result = await EntitiesServices.updateEntity(entitiesData, entityId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(updateEntityError(result.body.message || result.body.error || 'Failed'));
    return dispatch(updateEntitySuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};
