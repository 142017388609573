import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

const TechnicalCostFormContainer = ({ technicalCostDataset, onsubmitFunc }) => {
  const [form] = Form.useForm();

  const getDataSetValue = (dataset, type, level) => {
    if (!dataset.technicalCostDataset || !dataset.technicalCostDataset._id || !dataset.technicalCostDataset[type] || !dataset.technicalCostDataset[type][level]) {
      return '';
    }
    return dataset.technicalCostDataset[type][level];
  };

  useEffect(() => {
    form.setFieldsValue({
      /* Mechanics */
      mechanicsTechT1: getDataSetValue({ technicalCostDataset }, 'mechanics', 'T1'),
      mechanicsTechT2: getDataSetValue({ technicalCostDataset }, 'mechanics', 'T2'),
      mechanicsTechT3: getDataSetValue({ technicalCostDataset }, 'mechanics', 'T3'),
      /* Body */
      bodyTechT1: getDataSetValue({ technicalCostDataset }, 'body', 'T1'),
      bodyTechT2: getDataSetValue({ technicalCostDataset }, 'body', 'T2'),
      bodyTechT3: getDataSetValue({ technicalCostDataset }, 'body', 'T3'),
      /* Trim */
      trimTechT1: getDataSetValue({ technicalCostDataset }, 'trim', 'T1'),
      trimTechT2: getDataSetValue({ technicalCostDataset }, 'trim', 'T2'),
      trimTechT3: getDataSetValue({ technicalCostDataset }, 'trim', 'T3'),
      /* Electricity */
      electricityTechT1: getDataSetValue({ technicalCostDataset }, 'electricity', 'T1'),
      electricityTechT2: getDataSetValue({ technicalCostDataset }, 'electricity', 'T2'),
      electricityTechT3: getDataSetValue({ technicalCostDataset }, 'electricity', 'T3'),
      /* Paint */
      paintTechT1: getDataSetValue({ technicalCostDataset }, 'paint', 'T1'),
      paintTechT2: getDataSetValue({ technicalCostDataset }, 'paint', 'T2'),
      paintTechT3: getDataSetValue({ technicalCostDataset }, 'paint', 'T3'),
    });
  }, [technicalCostDataset]);

  return (
    <div className='form-container'>
      <Form form={form} className='technicalConfigurationForm' onFinish={onsubmitFunc}>
        <Row gutter={24} align='middle' justify='center'>
          <Col className='gutter-row' span={3} offset={8}>
            <div className='gutter-box' />
          </Col>
          <Col className='gutter-row' span={4}>
            <div className='center gutter-box bold' style={{ marginBottom: 15 }}>
              T1
            </div>
          </Col>
          <Col className='gutter-row' span={4}>
            <div className='center gutter-box bold' style={{ marginBottom: 15 }}>
              T2
            </div>
          </Col>
          <Col className='gutter-row' span={4}>
            <div className='center gutter-box bold' style={{ marginBottom: 15 }}>
              T3
            </div>
          </Col>
        </Row>
        <Row gutter={24} className='mechanicsTech' justify='space-around' align='middle'>
          <Col className='gutter-row' span={3} offset={6}>
            <div className='right gutter-box bold nameScaleFRE'>
              {' '}
              Mécanique <i className='fas fa-wrench' style={{ paddingLeft: 8 }} />{' '}
            </div>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='mechanicsTechT1' rules={[{ required: true }]}>
              <Input type='number' placeholder='T1' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='mechanicsTechT2' rules={[{ required: true }]}>
              <Input type='number' placeholder='T2' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='mechanicsTechT3' rules={[{ required: true }]}>
              <Input type='number' placeholder='T3' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className='bodyTech' justify='space-around' align='middle'>
          <Col className='gutter-row' span={3} offset={6}>
            <div className='right gutter-box bold nameScaleFRE'>
              Carrosserie <i className='fas fa-hammer' style={{ paddingLeft: 8 }} />
            </div>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='bodyTechT1' rules={[{ required: true }]}>
              <Input type='number' placeholder='T1' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='bodyTechT2' rules={[{ required: true }]}>
              <Input type='number' placeholder='T2' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='bodyTechT3' rules={[{ required: true }]}>
              <Input type='number' placeholder='T3' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className='trimTech' justify='space-around' align='middle'>
          <Col className='gutter-row' span={3} offset={6}>
            <div className='right gutter-box bold nameScaleFRE'>
              {' '}
              Garnissage <i className='fas fa-screwdriver' style={{ paddingLeft: 8 }} />{' '}
            </div>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='trimTechT1' rules={[{ required: true }]}>
              <Input type='number' placeholder='T1' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='trimTechT2' rules={[{ required: true }]}>
              <Input type='number' placeholder='T2' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='trimTechT3' rules={[{ required: true }]}>
              <Input type='number' placeholder='T3' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className='electricityTech' justify='space-around' align='middle'>
          <Col className='gutter-row' span={3} offset={6}>
            <div className='right gutter-box bold nameScaleFRE'>
              {' '}
              Électricité <i className='fas fa-car-battery' style={{ paddingLeft: 8 }} />
            </div>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='electricityTechT1' rules={[{ required: true }]}>
              <Input type='number' placeholder='T1' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='electricityTechT2' rules={[{ required: true }]}>
              <Input type='number' placeholder='T2' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='electricityTechT3' rules={[{ required: true }]}>
              <Input type='number' placeholder='T3' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className='paintTech' justify='space-around' align='middle'>
          <Col className='gutter-row' span={3} offset={6}>
            <div className='right gutter-box bold nameScaleFRE'>
              {' '}
              Peinture <i className='fas fa-paint-roller' style={{ paddingLeft: 8 }} />
            </div>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='paintTechT1' rules={[{ required: true }]}>
              <Input type='number' required placeholder='T1' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='paintTechT2' rules={[{ required: true }]}>
              <Input type='number' placeholder='T2' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={2}>
            <Form.Item name='paintTechT3' rules={[{ required: true }]}>
              <Input type='number' placeholder='T3' />
            </Form.Item>
          </Col>
        </Row>
        <Row className='actionsRow'>
          <Col className='center' span={24}>
            <Form.Item>
              <Button type='primary' size='large' htmlType='submit'>
                Valider
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

TechnicalCostFormContainer.propTypes = {
  technicalCostDataset: PropTypes.object,
  onsubmitFunc: PropTypes.func,
};

export default TechnicalCostFormContainer;
