import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import ConcessionsActions from '../../../../actions/Concessions';
import ConcessionsServices from '../../../../services/ConcessionsServices';
import Text from '../../../shared/atoms/Text';
import locale from '../../locale';

Modal.setAppElement('body');

class ModalViewUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  closeModal = () => {
    this.props.dispatch(ConcessionsActions.getActionToDispatch({ type: 'HIDE_MODAL' }));
  };

  async componentDidMount() {
    const { modalData } = this.props.concessions;

    if (!modalData) {
      return false;
    }

    const findResponse = await ConcessionsServices.getAccount({
      userId: modalData._id,
    }).catch((err) => ({ err }));

    if (findResponse && findResponse.status < 400) {
      this.setState({ user: findResponse.user });
    }
    return true;
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  onChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  renderValidButtonText = () => {
    const { isLoading } = this.props.concessions;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }
    return 'Sauvegarder';
  };

  getRole = (role) => {
    switch (role) {
      case 'superChief':
        return 'Admin de groupe';
      case 'chief':
        return 'Responsable';
      case 'reseller':
        return 'Utilisateur';
      default:
        return null;
    }
  };

  render() {
    const { user = {} } = this.state;
    const listConcession = user.concession ? user.concession.listConcession : [];
    const names = user.concession ? user.concession.names : [];

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Modal annuler inspection'
        overlayClassName='modal-overlay'
        className='modal modalConcession'
      >
        <div className='modalContent '>
          <div className='mainTitle'>Visualisation d'utilisateur</div>
          {user ? (
            <form className='mt10px'>
              <Row>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Nom de l'utilisateur</label>
                    <Text fontStyle='normal' size={12}>
                      {user.firstname} {user.lastname}
                    </Text>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>E-mail</label>
                    <Text fontStyle='normal' size={12}>
                      {user.email}
                    </Text>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Identifiant interne</label>
                    <Text fontStyle='normal' size={12}>
                      {user.readableId}
                    </Text>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Type de compte</label>
                    <Text fontStyle='normal' size={12}>
                      {this.getRole(user.role)}
                    </Text>
                  </div>
                </Col>
                {user.phone && (
                  <Col xs={12} md={6}>
                    <div className='formInput'>
                      <label>Numéro de téléphone</label>
                      <Text fontStyle='normal' size={12}>
                        {user.phone}
                      </Text>
                    </div>
                  </Col>
                )}
                {user.concession && (
                  <React.Fragment>
                    {user.concession.canCreateMsaInspection && (
                      <Col xs={12} md={6}>
                        <div className='formInput'>
                          <label>{locale.ACCESS_NEW_INSPECTION_MSA}</label>
                          <Text fontStyle='normal' size={12}>
                            Oui
                          </Text>
                        </div>
                      </Col>
                    )}
                    {user.concession.notifyConcession && (
                      <Col xs={12} md={6}>
                        <div className='formInput'>
                          <label>{locale.GET_NOTIFIED_NEW_INSPECTION}</label>
                          <Text fontStyle='normal' size={12}>
                            Oui
                          </Text>
                        </div>
                      </Col>
                    )}
                    {/* {user.concession.silentNotify && <Col xs={12} md={6}>
                    <div className="formInput">
                      <label>{locale.NOTIFY_USER_MAIL}</label>
                      <Text fontStyle="normal" size={12}>Oui</Text>
                    </div>
                  </Col>} */}
                    {/* <Col xs={12} md={6}>
                    <div className="formInput">
                      <label>Groupe</label>
                      <Text fontStyle="normal" size={12}>{user.concession.entityName}</Text>
                    </div>
                  </Col> */}
                    {names && (
                      <Col xs={12} md={6}>
                        <div className='formInput'>
                          <label>Sites</label>
                          {names.map((elem, key) => (
                            <Text key={key} fontStyle='normal' className='inline mr5px' size={12}>
                              {elem}
                              {names[key + 1] ? ', ' : ''}
                            </Text>
                          ))}
                        </div>
                      </Col>
                    )}
                    {listConcession && (
                      <Col xs={12} md={6}>
                        <div className='formInput'>
                          <label>Site(s)</label>
                          {listConcession.map((elem, key) => (
                            <Text key={key} fontStyle='normal' className='inline mr5px' size={12}>
                              {elem}
                              {listConcession[key + 1] ? ', ' : ''}
                            </Text>
                          ))}
                        </div>
                      </Col>
                    )}
                  </React.Fragment>
                )}
              </Row>
              {this.renderError()}
              <div className='center formButton'>
                <button type='button' onClick={this.closeModal} className='negativ'>
                  Fermer
                </button>
              </div>
            </form>
          ) : (
            <Text fontStyle='normal' size={16}>
              Chargement
            </Text>
          )}
        </div>
      </Modal>
    );
  }
}

ModalViewUser.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
};

export default ModalViewUser;
