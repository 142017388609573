import constants from '../constants';
import utils from '../utils';

export const notSentInspection = async (offset, emails) => {
  const response = await fetch(`${constants.URI.v3.exportExcel.notSentInspection}?offset=${offset}&emails=${emails}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return { status: response.status };
};

export const ExportExcelServices = async (queryObject, path, request) => {
  const response = await fetch(`${constants.URI.v3.exportExcel[request]}/${path}/recap`.concat(utils.queryStringV2(queryObject)), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status };
};
