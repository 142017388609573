export const rulesList = (userCanEdit) => {
  const data = {
    example: {
      title: 'Règles métier',
      menu: [
        {
          icon: 'fa fa-plus',
          displayName: 'Nouvelle règle',
          handler: (openModal) => openModal('createRule'),
        },
      ],
      fields: [
        {
          displayName: 'Titre',
          variable: 'title',
          sortable: true,
          size: {
            xs: 3,
          },
        },
        {
          displayName: 'Description',
          variable: 'description',
          sortable: true,
          // type: 'description',
          size: {
            xs: 5,
          },
        },
        {
          displayName: 'Type',
          variable: 'level',
          sortable: true,
          size: {
            xs: 2,
          },
        },
        {
          displayName: 'Actions',
          action: true,
          actions: [
            {
              name: 'editRule',
              icon: 'fas fa-edit',
              tooltip: 'Éditer',
              handler: (openModal) => openModal('editRule'),
            },
            {
              name: 'deleteRule',
              icon: 'far fa-trash-alt',
              tooltip: 'Supprimer',
              handler: (openModal) => openModal('deleteRule'),
            },
          ],
          customClass: 'action',
          size: {
            xs: 2,
          },
        },
      ],
    },
  };

  if (!userCanEdit) {
    data.example.menu = [];
    data.example.fields[3].actions[0].icon = 'fa fa-eye';
    data.example.fields[3].actions[0].tooltip = 'Voir la règle';
    delete data.example.fields[3].actions[1];
  }
  return data;
};

export const reportsList = (userCanEdit) => {
  const data = {
    example: {
      title: 'Rapports',
      menu: [
        {
          icon: 'fas fa-plus-circle',
          displayName: 'Nouveau rapport',
          handler: (openModal) => openModal('createReport'),
        },
      ],
      fields: [
        {
          displayName: 'Titre',
          variable: 'title',
          sortable: true,
          size: {
            xs: 3,
          },
        },
        {
          displayName: 'Description',
          variable: 'description',
          sortable: true,
          size: {
            xs: 6,
          },
        },
        {
          displayName: 'Actions',
          action: true,
          actions: [
            {
              name: 'editReport',
              icon: 'fas fa-edit',
              tooltip: 'Éditer le rapport',
              handler: (openModal) => openModal('editReport'),
            },
            {
              name: 'editReportRules',
              icon: 'far fa-lightbulb',
              tooltip: 'Règles métier',
              handler: (openModal) => openModal('editReportRules'),
            },
            {
              name: 'cloneReport',
              icon: 'far fa-clone',
              tooltip: 'Cloner le rapport',
              handler: (openModal) => openModal('cloneReport'),
            },
            {
              name: 'billingConfiguration',
              icon: 'fas fa-euro-sign',
              tooltip: 'Configurer le barème de facturation',
              handler: (openModal) => openModal('billingConfiguration'),
            },
          ],
          customClass: 'action',
          size: {
            xs: 3,
          },
        },
      ],
    },
  };

  if (!userCanEdit) {
    data.example.menu = [];
    data.example.fields[2].actions[0].icon = 'fa fa-eye';
    data.example.fields[2].actions[0].tooltip = 'Voir le rapport';
    data.example.fields[2].actions[1].tooltip = 'Voir les règles métier';
    delete data.example.fields[2].actions[2];
    delete data.example.fields[2].actions[3];
  }
  return data;
};

export const reportsListConcession = (userCanEdit) => {
  const data = {
    example: {
      title: 'Liste des rapports',
      menu: [
        // {
        //   icon: 'fas fa-plus-circle',
        //   displayName: 'Nouveau Rapport',
        //   handler: openModal => openModal('createReport'),
        // },
      ],
      fields: [
        {
          displayName: 'Titre',
          variable: 'title',
          sortable: true,
          size: {
            xs: 2,
          },
        },
        {
          displayName: 'Description',
          variable: 'description',
          sortable: true,
          size: {
            xs: 4,
          },
        },
        {
          displayName: 'Groupe',
          variable: 'group',
          sortable: true,
          size: {
            xs: 2,
          },
        },
        {
          displayName: 'Statut',
          variable: 'status',
          sortable: true,
          size: {
            xs: 1,
          },
        },
        {
          displayName: 'Actions',
          action: true,
          actions: [
            {
              name: 'editReport',
              icon: 'fas fa-edit',
              tooltip: 'Éditer le rapport',
              handler: (openModal) => openModal('editReport'),
            },
            {
              name: 'editReportRules',
              icon: 'far fa-lightbulb',
              tooltip: 'Règles métier',
              handler: (openModal) => openModal('editReportRules'),
            },
          ],
          customClass: 'action',
          size: {
            xs: 3,
          },
        },
      ],
    },
  };

  if (!userCanEdit) {
    data.example.menu = [];
    data.example.fields[4].actions[0].icon = 'fa fa-eye';
    data.example.fields[4].actions[0].tooltip = 'Voir le rapport';
    data.example.fields[4].actions[1].tooltip = 'Voir les règles métier';
  }
  return data;
};

export const checkpointsList = (userCanEdit) => {
  const data = {
    example: {
      title: 'Points de contrôle',
      menu: [
        {
          icon: 'fa fa-plus',
          displayName: 'Nouveau Texte',
          handler: (openModal) => openModal('createPointText'),
        },
        {
          icon: 'fa fa-plus',
          displayName: 'Nouveau Checkbox',
          handler: (openModal) => openModal('createPointCheckbox'),
        },
        {
          icon: 'fa fa-plus',
          displayName: 'Nouvelle Liste',
          handler: (openModal) => openModal('createPointList'),
        },
        {
          icon: 'fa fa-plus',
          displayName: 'Nouvelle Photo',
          handler: (openModal) => openModal('createPointPhoto'),
        },
        {
          icon: 'fa fa-plus',
          displayName: 'Nouveau Séparateur',
          handler: (openModal) => openModal('createPointDivider'),
        },
        {
          icon: 'fa fa-plus',
          displayName: 'Nouvelle Vidéo',
          handler: (openModal) => openModal('createPointVideo'),
        },
      ],
      fields: [
        {
          displayName: 'ID',
          variable: '_id',
          sortable: true,
          size: {
            xs: 1,
          },
        },
        {
          displayName: 'Titre',
          variable: 'label',
          sortable: true,
          size: {
            xs: 2,
          },
        },
        {
          displayName: 'Description',
          variable: 'description',
          sortable: true,
          size: {
            xs: 4,
          },
        },
        {
          displayName: 'Type',
          variable: 'kind',
          type: 'kind',
          sortable: true,
          size: {
            xs: 2,
          },
        },
        {
          displayName: 'Categorie',
          subVariable: 'label',
          variable: 'category',
          sortable: true,
          size: {
            xs: 2,
          },
        },
        {
          displayName: 'Actions',
          action: true,
          actions: [
            {
              name: 'editCheckpoint',
              icon: 'fas fa-edit',
              tooltip: 'Éditer le point de contrôle',
              handler: (openModal) => openModal('editCheckpoint'),
            },
            {
              name: 'deleteCheckpoint',
              icon: 'far fa-trash-alt',
              tooltip: 'Supprimer',
              handler: (deleteItem) => deleteItem('deleteCheckpoint'),
            },
          ],
          customClass: 'action',
          size: {
            xs: 1,
          },
        },
      ],
    },
  };

  if (!userCanEdit) {
    data.example.menu = [];
    data.example.fields[5].actions[0].icon = 'fa fa-eye';
    data.example.fields[5].actions[0].tooltip = 'Voir le point de contrôle';
    delete data.example.fields[5].actions[1];
  }

  return data;
};
