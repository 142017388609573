import produce from 'immer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import ReportsActions from '../../../../actions/ReportsV3Actions';
import c from '../../../../constants';
import ReportServices from '../../../../services/ReportsService';
import DragDropListCarParts from '../components/dragDropListCarParts';
import MsaElementList from '../components/msaElementList';

Modal.setAppElement('body');

class ModalCreateMsaCarPart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cupisList: [],
      cupisLoading: false,
      label: '',
      MsaCarPartCupis: [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  cupisLoading = false;

  cupiPartPage = 0;

  totalPages = 1;

  componentDidMount() {
    if (this.props.isEdit) {
      const { modalData } = this.props.reportsV3;
      this.setState({
        label: _.get(modalData, 'label'),
        MsaCarPartCupis: _.get(modalData, 'cupis') || [],
      });
    } else {
      this.setState({
        MsaCarPartCupis: [],
      });
    }
  }

  handleChange(event) {
    this.setState({ label: event.target.value });
  }

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(ReportsActions.hideReportsV3Modal());
  };

  submit = async (e) => {
    e.preventDefault();

    const { label, MsaCarPartCupis } = this.state;

    const data = {
      label,
      cupis: MsaCarPartCupis.map((cupiElem) => cupiElem._id),
    };
    this.setState({ isLoading: true });
    if (this.props.isEdit === true) {
      try {
        await ReportServices.put(c.URI.v3.msaCarPart.concat(this.props.reportsV3.modalData._id), data);
        this.props.dispatch(ReportsActions.executeAction('msaCarParts', { pageNumber: this.props.reportsV3.pageNumber, includeCupis: true }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    } else {
      try {
        await ReportServices.post(c.URI.v3.msaCarPart, data);
        this.props.dispatch(ReportsActions.executeAction('msaCarParts', { pageNumber: this.props.reportsV3.pageNumber, includeCupis: true }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    }

    this.setState({ isLoading: false });
  };

  handleDeleteItem = (e, sortIndex) => {
    e.preventDefault();
    const nextCupiParts = produce(this.state.MsaCarPartCupis, (draftState) => {
      draftState.splice(sortIndex, 1);
    });
    this.setState({ MsaCarPartCupis: nextCupiParts });
  };

  handleAddItem = (e, item) => {
    e.preventDefault();
    const nextCupiParts = produce(this.state.MsaCarPartCupis, (draftState) => {
      draftState.push(item);
    });
    this.setState({ MsaCarPartCupis: nextCupiParts });
  };

  loadCupiParts = async () => {
    const { search } = this.state;

    this.cupisLoading = true;
    const pages = await ReportServices.getAll(c.URI.v3.cupiCarPart, {
      pageNumber: this.cupiPartPage > 0 ? this.cupiPartPage : 1,
      searchTerm: search,
      usedByMsa: 'true',
    });

    this.cupisLoading = false;
    if (pages && pages.totalPages) this.totalPages = pages.totalPages;
    if (pages && pages.items && pages.items.length > 0) {
      this.setState({ cupisList: [...this.state.cupisList, ...pages.items] });
    }
  };

  // hacky solution to force update on stateless component
  forceListUpdate = () => {
    this.totalPages = 1;
    this.cupiPartPage = 0;
    this.setState({ cupisList: [{ label: '...' }] });
    this.setState({ cupisList: [] });
  };

  handleSearch = (e) => {
    const { target } = e;
    const { value } = target;

    this.setState({ search: value });
    this.forceListUpdate();
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }

    return null;
  };

  renderValidButtonText = () => {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }
    return (
      <button type='button' onClick={this.submit} className='positiv' disabled={isLoading}>
        Sauvegarder
      </button>
    );
  };

  render() {
    const { isAdmin } = this.props.application.user;
    const userCanEdit = isAdmin === true;

    const CupiPartItems = this.state.MsaCarPartCupis || [];
    const dragDropCupiPartsItems = [];
    _.times(CupiPartItems.length, (n) => dragDropCupiPartsItems.push(n));

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Créer ou modifier une pièce auto MSA'
        overlayClassName='modal-overlay'
        className='modal modalReport'
        style={!userCanEdit ? { content: { cursor: 'not-allowed' } } : {}}
      >
        <Col className='modalContent' style={!userCanEdit ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
          <div className='mainTitle'>
            {' '}
            <span className='modalFontBig'>Pièce auto MSA</span>
          </div>
          <form className='mt10px modalFont'>
            <Row xs={12} style={{ marginBottom: 5 }}>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Nom</label>
                  <input type='text' placeholder='Nom de la pièce MSA' value={this.state.label} onChange={this.handleChange} style={{ minHeight: 38, borderRadius: 8 }} />
                </div>
              </Col>
              <Col xs={6}></Col>
            </Row>

            <Row xs={12} style={{ marginBottom: 20 }}>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Liste de Cupis </label>
                </div>
                <div style={{ maxWidth: '92%' }}>
                  <div style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderColor: 'hsl(0,0%,80%)',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 5,
                      }}
                    >
                      <div style={{ fontSize: 13, marginLeft: 5, width: '90%' }}>
                        <i className='fa fa-search' />
                        <DebounceInput
                          style={{
                            borderWidth: 0,
                            height: 35,
                            marginLeft: 10,
                            marginRight: 10,
                            width: '90%',
                          }}
                          type='text'
                          name='search'
                          debounceTimeout={300}
                          value={this.state.search}
                          onChange={this.handleSearch}
                          placeholder='Recherchez...'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <MsaElementList
                  // width={400}
                  list={this.state.cupisList}
                  type='cupiCarPart'
                  loadNextPage={() => {
                    this.cupiPartPage += 1;
                    this.loadCupiParts();
                  }}
                  hasNextPage={this.cupiPartPage < this.totalPages}
                  isNextPageLoading={this.cupisLoading}
                  handleAddItem={this.handleAddItem}
                />
              </Col>
              <Col xs={6}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderWidth: 0,
                  }}
                ></div>

                <div className='formInput'>
                  <label>Pièces CUPI liées</label>
                </div>
                <DragDropListCarParts
                  carParts={CupiPartItems}
                  type={'CupiCarPart'}
                  handleDeleteItem={this.handleDeleteItem}
                  // handleRequiredItem={this.handleRequiredItem}
                  // onSortEnd={this.onSortEnd}
                />
              </Col>
            </Row>
            {this.renderError()}
          </form>
        </Col>
        <div className='center formButton'>
          <button type='button' onClick={this.closeModal} className='negativ'>
            {userCanEdit ? 'Annuler' : 'Fermer'}
          </button>
          {userCanEdit && this.renderValidButtonText()}
        </div>
      </Modal>
    );
  }
}

ModalCreateMsaCarPart.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  reportsV3: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default ModalCreateMsaCarPart;
