import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Text from '../../shared/atoms/Text';

const ViewInspectionCarInfos = ({ data: { firstname, lastname, userRole, inspectionType, inspectionDuration } }) => (
  <Row>
    <Col xs={12} md={4}>
      <label>Inspecté par</label>
      <Text fontStyle='normal' size={14}>
        {firstname} {lastname}
      </Text>
    </Col>
    {(!userRole || inspectionType === 'onDemand') && (
      <Col xs={12} md={4}>
        <label>Durée de l'inspection</label>
        <Text fontStyle='normal' size={14}>
          {Math.floor(inspectionDuration / 60) || 0} minutes
        </Text>
      </Col>
    )}
  </Row>
);

ViewInspectionCarInfos.propTypes = {
  data: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    userRole: PropTypes.string,
    inspectionType: PropTypes.string,
    inspectionDuration: PropTypes.number,
  }),
};

export default ViewInspectionCarInfos;
