import fr from 'date-fns/locale/fr';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import constants from '../../../constants';

class AddEditTimeSlotPicker extends Component {
  constructor(props) {
    super(props);
    const { days } = this.props;
    this.state = { days, maxTimeslotReached: false };
  }

  updateStateParent() {
    this.props.handler(this.state.days);
  }

  onItemChange(index, key, value) {
    const days = [...this.state.days];
    const day = { ...this.state.days[index] };
    day[key] = value;
    days[index] = day;
    this.setState({ days });
    this.props.handler(days);
  }

  addNewDay() {
    if (this.state.days.length > 5) {
      this.setState({ maxTimeslotReached: true });
      return null;
    }
    const lastDay = this.state.days[this.state.days.length - 1];
    const newDays = [
      ...this.state.days,
      {
        date: lastDay ? new Date(lastDay.date) : moment().startOf('d').toDate(),
        startingTime: lastDay ? new Date(lastDay.startingTime) : moment('09:00', constants.formatHourAppointment).toDate(),
        endingTime: lastDay ? new Date(lastDay.endingTime) : moment('18:00', constants.formatHourAppointment).toDate(),
      },
    ];
    this.props.handler(newDays);
    return this.setState({ days: newDays });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ days: this.props.days });
      this.updateStateParent();
    }, 500);
  }

  removeDay(index) {
    const days = this.state.days.filter((t, i) => i !== index);
    this.setState({ days, maxTimeslotReached: false });
    this.props.handler(days);
  }

  render() {
    const daysToDisplay = this.state.days.map((d, i) => (
      <div key={`multipleAssigneDatePicker-${i}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
        <DatePicker selected={d.date} onChange={(date) => this.onItemChange(i, 'date', date)} dateFormat='dd/MM/yyyy' locale={fr} />
        <DatePicker
          selected={d.startingTime}
          showTimeSelect
          showTimeSelectOnly
          onChange={(date) => this.onItemChange(i, 'startingTime', date)}
          dateFormat={constants.formatHourAppointment}
          timeIntervals={5}
          timeCaption='Time'
          locale={fr}
        />
        <DatePicker
          selected={d.endingTime}
          showTimeSelect
          showTimeSelectOnly
          onChange={(date) => this.onItemChange(i, 'endingTime', date)}
          dateFormat={constants.formatHourAppointment}
          timeIntervals={5}
          timeCaption='Time'
          locale={fr}
        />
        {
          <button type='button' data-cy='delete-date' className='circle' onClick={(e) => this.removeDay(i, e)}>
            -
          </button>
        }
      </div>
    ));
    return (
      <>
        {daysToDisplay}
        {
          <button type='button' onClick={(e) => this.addNewDay(e)} className='circle'>
            +
          </button>
        }
        {this.state.maxTimeslotReached && <p className='error'>Vous pouvez saisir maximum 6 créneaux ! </p>}
      </>
    );
  }
}

AddEditTimeSlotPicker.propTypes = {
  days: PropTypes.array,
  handler: PropTypes.func,
};

export default AddEditTimeSlotPicker;
