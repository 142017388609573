import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Popconfirm, DatePicker, Checkbox } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const InfosProfessionalExperienceEditor = ({
  professionalExperience,
  save,
  deleteProfessionalExperience,
  cancel,
}) => {
  const [professionalExperiencesState, setProfessionalExperiencesState] = useState(null);
  const [professionalExperiencesToUpdateState, setProfessionalExperiencesToUpdateState] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(false);
  const [errorState, setErrorState] = useState('');
  const dateFormat = 'YYYY-MM-DD';

  const saveInfos = async () => {
    const professionalExperiencesToUpdate = _.differenceWith(professionalExperiencesToUpdateState, professionalExperiencesState, _.isEqual);
    const checkDuration = professionalExperiencesToUpdate.every((professionalExperienceToUpdate) => (!professionalExperienceToUpdate.duration.currentPosition && professionalExperienceToUpdate.duration.end === null));
    if (checkDuration) setErrorState('Veuillez entrer une date de fin pour toutes les expériences hors poste actuel');
    else await save({ professionalUserExperiences: professionalExperiencesToUpdate }, true);
  };

  const handleUpdateProfessionalExperiences = (id, value) => {
    const newArr = professionalExperiencesToUpdateState.map((professionalExperienceToUpdateState) => {
      if (professionalExperienceToUpdateState._id === id) {
        return { ...professionalExperienceToUpdateState, ...value };
      }
      return professionalExperienceToUpdateState;
    });
    setProfessionalExperiencesToUpdateState(newArr);
  };

  const confirmDeleteProfessionalExperience = async (id) => {
    await deleteProfessionalExperience(id);
  };

  useEffect(() => {
    if (professionalExperience.length >= 1) {
      setProfessionalExperiencesState(professionalExperience);
      setProfessionalExperiencesToUpdateState(professionalExperience);
    }
  }, []);

  return (
    <>
      <ul> { professionalExperiencesState?.map((professionalExperienceState, i) => (
        <div className='professionalExperience' key={i}>
          <Checkbox
            defaultChecked={professionalExperienceState.duration.currentPosition}
            onChange={(e) => {
              handleUpdateProfessionalExperiences(professionalExperienceState._id, { duration: {
                  start: professionalExperienceState.duration.start,
                  end: !e.target.checked ? professionalExperienceState.duration.end : null,
                  currentPosition: e.target.checked,
                } });
              setCurrentPosition(e.target.checked);
            }}
          >
            Poste actuel
          </Checkbox>
          <li className='full'>
            <label>Nom Entreprise</label>
            <div>
              <input data-cy='companyName' type='text' defaultValue={professionalExperienceState.companyName || ''} placeholder='Nom entreprise' onChange={(e) => handleUpdateProfessionalExperiences(professionalExperienceState._id, { companyName: e.target.value })} />
            </div>
          </li>
          <li className='full'>
            <label>Poste</label>
            <div>
              <input data-cy='positionTitle' type='text' defaultValue={professionalExperienceState.positionTitle || ''} placeholder='Poste' onChange={(e) => handleUpdateProfessionalExperiences(professionalExperienceState._id, { positionTitle: e.target.value })} />
            </div>
          </li>
          <li className='full'>
            <label>Durée</label>
            <div>
              <RangePicker
                onChange={(date) => handleUpdateProfessionalExperiences(professionalExperienceState._id, { duration: {
                    start: date[0],
                    end: !currentPosition ? date[1] : null,
                    currentPosition,
                  } })}
                defaultValue={[
                  moment(professionalExperienceState.duration.start, dateFormat),
                  professionalExperienceState.duration.currentPosition ? null : moment(professionalExperienceState.duration.end, dateFormat),
                ]}
                format={dateFormat}
              />
            </div>
          </li>
          <Popconfirm
            title="Confirmez-vous la suppression?"
            onConfirm={() => confirmDeleteProfessionalExperience(professionalExperienceState._id)}
            okText="Oui"
            cancelText="Non"
          >
            <span><i className="far fa-trash-alt" style={{ fontSize: '15px', position: 'absolute', right: 0, top: '2%', cursor: 'pointer' }}></i></span>
          </Popconfirm>
        </div>
      ))
      }
      </ul>
      { errorState && <div className='error'> { errorState } </div> }
      <div className='specialistFormButton'>
        <button className='specialistFormButtonCancel' onClick={cancel}>
          Annuler
        </button>
        <button data-cy='saveProfessionalExperience' className='specialistFormButtonSubmit' onClick={saveInfos}>
          Sauvegarder
        </button>
      </div>
    </>
  );
};

InfosProfessionalExperienceEditor.propTypes = {
  professionalExperience: PropTypes.arrayOf(PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    positionTitle: PropTypes.string.isRequired,
    duration: PropTypes.object.isRequired,
  })).isRequired,
  cancel: PropTypes.func,
  save: PropTypes.func,
  deleteProfessionalExperience: PropTypes.func,
};

export default InfosProfessionalExperienceEditor;
