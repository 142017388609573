import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getInspection, saveDocumentInfos, updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import { putFileToBucket } from '../../../helpers/manageUpload';
import UploadDocuments from './UploadDocuments';

const AddNewDocuments = ({ inspection, dispatch, lastUpdate, errorState, goToBack }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);

  const selectNewFile = (filesUploaded) => {
    setFilesToUpload(filesUploaded || inspection?.documents);
  };

  const saveDocumentsInfos = async (inspectionId) => {
    if (filesToUpload?.length === 0) return;
    const documentsInfos = filesToUpload?.map((documentsInfo) => ({
      fileName: documentsInfo.name,
      type: documentsInfo.type,
    }));
    const resultSaveDocumentInfos = await saveDocumentInfos(dispatch, inspectionId, documentsInfos, true);

    if (resultSaveDocumentInfos.type === 'SAVE_DOCUMENT_INFOS_SUCCESS') {
      await Promise.all(filesToUpload?.map((fileToUpload, index) => putFileToBucket(resultSaveDocumentInfos.documentsUpdated.url[index], fileToUpload.type, fileToUpload))).catch(
        (e) => console.log(e),
      );
      await updatePlannedInspections(dispatch, lastUpdate);
      await getInspection(dispatch, inspectionId, 'drawer');
      goToBack();
    }
  };

  return (
    <div>
      <UploadDocuments files={inspection?.documents} selectNewFile={selectNewFile} addDocumentDrawerView={true} inspectionId={String(inspection._id)} />
      {errorState && <div className='error'> {errorState}</div>}
      <div className='specialistFormButton'>
        <button className='specialistFormButtonCancel' onClick={() => goToBack()}>
          Annuler
        </button>
        <button className='specialistFormButtonSubmit' onClick={async () => saveDocumentsInfos(inspection._id)} disabled={!filesToUpload}>
          Sauvegarder
        </button>
      </div>
    </div>
  );
};

AddNewDocuments.propTypes = {
  dispatch: PropTypes.func,
  inspection: PropTypes.object,
  lastUpdate: PropTypes.number,
  goToBack: PropTypes.func,
  save: PropTypes.func,
  errorState: PropTypes.string,
};

const mapStateToProps = (state) => ({
  application: state.application,
  inspection: state.plannedInspections.inspectionDrawer,
  isLoading: state.plannedInspections.isLoading,
  lastUpdate: state.plannedInspections.lastUpdatedAt,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  saveDocumentInfos,
  updatePlannedInspections,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewDocuments);
