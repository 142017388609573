import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Space, message } from 'antd';
import moment from 'moment';
import InspectionsServices from '../../../services/InspectionsServices';

message.config({
  top: '90vh',
});

const DateBox = ({ checkpoint, inspectionId, sectionId, value, reload }) => {
  const [valueDate, setValueDate] = useState(moment(value, 'DD/MM/YYYY'));
  const [valueChanged, setValueChanged] = useState(false);

  const handleChange = (date, valueEnter) => {
    if (valueEnter !== valueDate) {
      setValueDate(valueEnter);
      setValueChanged(true);
    }
  };

  const sendNewValue = async () => {
    try {
      const res = await InspectionsServices.updateCheckpoint(inspectionId, sectionId, checkpoint._id, { value: moment(valueDate, 'YYYY-MM-DD').format('DD/MM/YYYY') });
      if (res.status !== 200) {
        message.error(`Erreur lors de la sauvegarde: ${res.error}`);
      } else {
        reload();
        message.success('Sauvegardé');
      }
    } catch (e) {
      message.error(`Erreur: ${e}`);
    }
  };

  const handleBlur = async () => {
    if (valueChanged === true) {
      await sendNewValue();
    }
  };

  return (
    <div className='formInput' style={{ marginBottom: 10, position: 'relative' }}>
      <Space direction='vertical'>
        <DatePicker defaultValue={valueDate} onChange={handleChange} onBlur={handleBlur} style={{ zIndex: 10000 }} />
      </Space>
    </div>
  );
};

DateBox.propTypes = {
  inspectionId: PropTypes.string,
  sectionId: PropTypes.string,
  value: PropTypes.string,
  checkpoint: PropTypes.object,
  reload: PropTypes.func,
};

export default DateBox;
