import SpecialistsServices from '../services/SpecialistsServices';

// Action types
export const REQUEST_SPECIALISTS = 'REQUEST_SPECIALISTS';
export const GET_SPECIALISTS = 'GET_SPECIALISTS';
export const GET_SPECIALISTS_SUCCESS = 'GET_SPECIALISTS_SUCCESS';
export const GET_SPECIALISTS_ERROR = 'GET_SPECIALISTS_ERROR';
export const REQUEST_SPECIALIST = 'REQUEST_SPECIALIST';
export const GET_SPECIALIST = 'GET_SPECIALIST';
export const GET_SPECIALIST_SUCCESS = 'GET_SPECIALIST_SUCCESS';
export const GET_SPECIALIST_ERROR = 'GET_SPECIALIST_ERROR';
export const CLEAR_SPECIALIST = 'CLEAR_SPECIALIST';
export const REQUEST_UPDATE_SPECIALIST = 'REQUEST_UPDATE_SPECIALIST';
export const UPDATE_SPECIALIST = 'UPDATE_SPECIALIST';
export const UPDATE_SPECIALIST_SUCCESS = 'UPDATE_SPECIALIST_SUCCESS';
export const UPDATE_SPECIALIST_ERROR = 'UPDATE_SPECIALIST_ERROR';
export const REQUEST_SPECIALIST_SKILLS = 'REQUEST_SPECIALIST_SKILLS';
export const GET_SPECIALIST_SKILLS = 'GET_SPECIALIST_SKILLS';
export const GET_SPECIALIST_SKILLS_SUCCESS = 'GET_SPECIALIST_SKILLS_SUCCESS';
export const GET_SPECIALIST_SKILLS_ERROR = 'GET_SPECIALIST_SKILLS_ERROR';
export const REQUEST_SPECIALIST_PROPOSITIONS = 'REQUEST_SPECIALIST_PROPOSITIONS';
export const GET_SPECIALIST_PROPOSITIONS = 'GET_SPECIALIST_PROPOSITIONS';
export const GET_SPECIALIST_PROPOSITIONS_SUCCESS = 'GET_SPECIALIST_PROPOSITIONS_SUCCESS';
export const GET_SPECIALIST_PROPOSITIONS_ERROR = 'GET_SPECIALIST_PROPOSITIONS_ERROR';
export const CLEAR_SPECIALIST_PROPOSITIONS = 'CLEAR_SPECIALIST_PROPOSITIONS';
export const REQUEST_SEND_NOTIFICATIONS = 'REQUEST_SEND_NOTIFICATIONS';
export const SEND_NOTIFICATIONS = 'SEND_NOTIFICATIONS';
export const SEND_NOTIFICATIONS_SUCCESS = 'SEND_NOTIFICATIONS_SUCCESS';
export const SEND_NOTIFICATIONS_ERROR = 'SEND_NOTIFICATIONS_ERROR';
export const REQUEST_SEND_SMS = 'REQUEST_SEND_SMS';
export const SEND_SMS = 'SEND_SMS';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_ERROR = 'SEND_SMS_ERROR';
export const REQUEST_ADD_PROFESSIONAL_EXPERIENCE = 'REQUEST_ADD_PROFESSIONAL_EXPERIENCE';
export const ADD_PROFESSIONAL_EXPERIENCE = 'ADD_PROFESSIONAL_EXPERIENCE';
export const ADD_PROFESSIONAL_EXPERIENCE_SUCCESS = 'ADD_PROFESSIONAL_EXPERIENCE_SUCCESS';
export const ADD_PROFESSIONAL_EXPERIENCE_ERROR = 'ADD_PROFESSIONAL_EXPERIENCE_ERROR';
export const REQUEST_UPDATE_PROFESSIONAL_EXPERIENCES = 'REQUEST_UPDATE_PROFESSIONAL_EXPERIENCES';
export const UPDATE_PROFESSIONAL_EXPERIENCES = 'UPDATE_PROFESSIONAL_EXPERIENCES';
export const UPDATE_PROFESSIONAL_EXPERIENCES_SUCCESS = 'UPDATE_PROFESSIONAL_EXPERIENCES_SUCCESS';
export const UPDATE_PROFESSIONAL_EXPERIENCES_ERROR = 'UPDATE_PROFESSIONAL_EXPERIENCES_ERROR';
export const REQUEST_DELETE_PROFESSIONAL_EXPERIENCE = 'REQUEST_DELETE_PROFESSIONAL_EXPERIENCE';
export const DELETE_PROFESSIONAL_EXPERIENCE = 'DELETE_PROFESSIONAL_EXPERIENCE';
export const DELETE_PROFESSIONAL_EXPERIENCE_SUCCESS = 'DELETE_PROFESSIONAL_EXPERIENCE_SUCCESS';
export const DELETE_PROFESSIONAL_EXPERIENCE_ERROR = 'DELETE_PROFESSIONAL_EXPERIENCE_ERROR';
export const SERVER_KILLED_SESSION = 'SERVER_KILLED_SESSION';

// Action creator
export const serverKilledSession = () => ({
  type: SERVER_KILLED_SESSION,
});

export const requestSpecialists = () => ({
  type: REQUEST_SPECIALISTS,
});

export const getSpecialistsSuccess = (specialists) => ({
  type: GET_SPECIALISTS_SUCCESS,
  specialists,
});

export const getSpecialistsError = (error) => ({
  type: GET_SPECIALISTS_ERROR,
  error,
});

export const getSpecialists = async (dispatch, updatedAt) => {
  try {
    dispatch(requestSpecialists());
    const result = await SpecialistsServices.getSpecialists(updatedAt);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getSpecialistsError(result.body.error || result.body.message || 'Failed'));
    return dispatch(getSpecialistsSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestSpecialist = () => ({
  type: REQUEST_SPECIALIST,
});

export const getSpecialistSuccess = (specialist) => ({
  type: GET_SPECIALIST_SUCCESS,
  specialist,
});

export const getSpecialistError = (error) => ({
  type: GET_SPECIALIST_ERROR,
  error,
});

export const getSpecialist = async (dispatch, specialistId) => {
  try {
    dispatch(requestSpecialist());
    const result = await SpecialistsServices.getSpecialist(specialistId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getSpecialistError(result.body.error || result.body.message || 'Failed'));
    return dispatch(getSpecialistSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const clearSpecialist = () => ({
  type: CLEAR_SPECIALIST,
});

export const requestUpdateSpecialist = () => ({
  type: REQUEST_UPDATE_SPECIALIST,
});

export const updateSpecialistSuccess = (specialist) => ({
  type: UPDATE_SPECIALIST_SUCCESS,
  specialist,
});

export const updateSpecialistError = (error) => ({
  type: UPDATE_SPECIALIST_ERROR,
  error,
});

export const updateSpecialist = async (dispatch, specialistData, specialistId, specialistKind) => {
  try {
    dispatch(requestUpdateSpecialist());
    const result = await SpecialistsServices.updateSpecialist(specialistData, specialistId, specialistKind);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(updateSpecialistError(result.body.error || result.body.message || 'Failed'));
    return dispatch(updateSpecialistSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestSpecialistsSkill = () => ({
  type: REQUEST_SPECIALIST_SKILLS,
});

export const getSpecialistsSkillSuccess = (specialistSkills) => ({
  type: GET_SPECIALIST_SKILLS_SUCCESS,
  specialistSkills,
});

export const getSpecialistSkillsError = (error) => ({
  type: GET_SPECIALIST_SKILLS_ERROR,
  error,
});

export const getSpecialistSkills = async (dispatch) => {
  try {
    dispatch(requestSpecialistsSkill());
    const result = await SpecialistsServices.getAllSpecialistSkills();
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getSpecialistSkillsError(result.body.error || result.body.message || 'Failed'));
    return dispatch(getSpecialistsSkillSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestSpecialistPropositions = () => ({
  type: REQUEST_SPECIALIST_PROPOSITIONS,
});

export const getSpecialistPropositionsSuccess = (specialistPropositions) => ({
  type: GET_SPECIALIST_PROPOSITIONS_SUCCESS,
  specialistPropositions,
});

export const getSpecialistPropositionsError = (error) => ({
  type: GET_SPECIALIST_PROPOSITIONS_ERROR,
  error,
});

export const getSpecialistPropositions = async (dispatch, specialistId) => {
  try {
    dispatch(requestSpecialistPropositions());
    const result = await SpecialistsServices.getAllSpecialistPropositions(specialistId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(getSpecialistPropositionsError(result.body.error || result.body.message || 'Failed'));
    return dispatch(getSpecialistPropositionsSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const clearSpecialistPropositions = () => ({
  type: CLEAR_SPECIALIST_PROPOSITIONS,
});

export const requestSendNotifications = () => ({
  type: REQUEST_SEND_NOTIFICATIONS,
});

export const sendNotificationsSuccess = (specialists) => ({
  type: SEND_NOTIFICATIONS_SUCCESS,
  specialists,
});

export const sendNotificationsError = (error) => ({
  type: SEND_NOTIFICATIONS_ERROR,
  error,
});

export const sendNotifications = async (dispatch, notificationInfos) => {
  try {
    dispatch(requestSendNotifications());
    const result = await SpecialistsServices.sendNotifications(notificationInfos);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(sendNotificationsError(result.body.message || result.body.error || 'Failed'));
    return dispatch(sendNotificationsSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestSendSms = () => ({
  type: REQUEST_SEND_SMS,
});

export const sendSmsSuccess = () => ({
  type: SEND_SMS_SUCCESS,
});

export const sendSmsError = (error) => ({
  type: SEND_SMS_ERROR,
  error,
});

export const sendSms = async (dispatch, smsInfos) => {
  try {
    dispatch(requestSendSms());
    const result = await SpecialistsServices.sendSms(smsInfos);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(sendSmsError(result.body.message || result.body.error || 'Failed'));
    return dispatch(sendSmsSuccess());
  } catch (e) {
    throw new Error(e);
  }
};

export const requestAddProfessionalExperience = () => ({
  type: REQUEST_ADD_PROFESSIONAL_EXPERIENCE,
});

export const addProfessionalExperienceSuccess = (result) => ({
  type: ADD_PROFESSIONAL_EXPERIENCE_SUCCESS,
  result,
});

export const addProfessionalExperienceError = (error) => ({
  type: ADD_PROFESSIONAL_EXPERIENCE_ERROR,
  error,
});

export const addProfessionalExperience = async (dispatch, professionalExperienceData, specialistId) => {
  try {
    dispatch(requestAddProfessionalExperience());
    const result = await SpecialistsServices.addProfessionalExperience(professionalExperienceData, specialistId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(addProfessionalExperienceError(result.body.error || result.body.message || 'Failed'));
    return dispatch(addProfessionalExperienceSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestUpdateProfessionalExperiences = () => ({
  type: REQUEST_UPDATE_PROFESSIONAL_EXPERIENCES,
});

export const updateProfessionalExperiencesSuccess = (result) => ({
  type: UPDATE_PROFESSIONAL_EXPERIENCES_SUCCESS,
  result,
});

export const updateProfessionalExperiencesError = (error) => ({
  type: UPDATE_PROFESSIONAL_EXPERIENCES_ERROR,
  error,
});

export const updateProfessionalExperiences = async (dispatch, professionalExperiencesData) => {
  try {
    dispatch(requestUpdateProfessionalExperiences());
    const result = await SpecialistsServices.updateProfessionalExperiences(professionalExperiencesData);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(updateProfessionalExperiencesError(result.body.error || result.body.message || 'Failed'));
    return dispatch(updateProfessionalExperiencesSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};

export const requestDeleteProfessionalExperience = () => ({
  type: REQUEST_DELETE_PROFESSIONAL_EXPERIENCE,
});

export const deleteProfessionalExperienceSuccess = (result) => ({
  type: DELETE_PROFESSIONAL_EXPERIENCE_SUCCESS,
  result,
});

export const deleteProfessionalExperienceError = (error) => ({
  type: DELETE_PROFESSIONAL_EXPERIENCE_ERROR,
  error,
});

export const deleteProfessionalExperience = async (dispatch, professionalExperienceId, specialistId) => {
  try {
    dispatch(requestDeleteProfessionalExperience());
    const result = await SpecialistsServices.deleteProfessionalExperience(professionalExperienceId, specialistId);
    if (result && result.status === 401) return dispatch(serverKilledSession());
    if (!result || result.status !== 200) return dispatch(deleteProfessionalExperienceError(result.body.error || result.body.message || 'Failed'));
    return dispatch(deleteProfessionalExperienceSuccess(result.body));
  } catch (e) {
    throw new Error(e);
  }
};
