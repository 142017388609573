import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InspectionsServices from '../../../services/InspectionsServices';

message.config({
  top: '90vh',
});

const CommentBox = (commentProps) => {
  const [valueCom, setValueCom] = useState(commentProps.value);
  const [valueChanged, setValueChanged] = useState(false);

  function handleChange(value) {
    if (value !== valueCom) {
      setValueCom(value);
      setValueChanged(true);
    }
  }

  async function sendNewValue(value) {
    const { checkpoint, inspectionId, sectionId } = commentProps;

    try {
      const res = await InspectionsServices.updateCheckpoint(inspectionId, sectionId, checkpoint._id, { value });
      // if not updated on API show error toast notification
      if (res.status !== 200) {
        message.error(`Erreur lors de la sauvegarde: ${res.error}`);
      } else {
        commentProps.reload();
        message.success('Sauvegardé');
      }
    } catch (e) {
      message.error(`Erreur: ${e}`);
    }
  }

  function handleBlur(value) {
    handleChange(value);
    if (valueChanged === true) {
      sendNewValue(valueCom);
    }
  }

  return (
    <div className='formInput' style={{ marginBottom: 10 }}>
      <textarea
        data-cy='commentBox'
        rows='2'
        type='text'
        spellCheck='true'
        name={commentProps.checkpoint._id}
        value={valueCom}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
      />
    </div>
  );
};

CommentBox.propTypes = {
  inspectionId: PropTypes.string,
  sectionId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checkpoint: PropTypes.object,
  reload: PropTypes.func,
};

export default CommentBox;
