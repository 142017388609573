import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../../constants';
import formatPhoneNumber from '../../../../helpers/phone';
import DefaultGridOptions from '../../../shared/atoms/DefaultGridOptions';
import SpecialistMenu from '../organisms/SpecialistMenu';

const StatusCellRenderer = ({ value }) => (
  <div className='specialistItemElement specialistItemStatut'>
    <span className={`statusBadge ${value}`}>{value}</span>
  </div>
);

const statusDocumentsAndInspectionCellRenderer = (document) => (
  <div className='specialistItemElement specialistItemStatut'>
    <span className='icon-circle'>{document ? <i className='fas fa-check-circle true' /> : <i className='fas fa-times-circle' />}</span>
  </div>
);

const getValue = (params) => (params ? 'Oui' : 'Non');

StatusCellRenderer.propTypes = {
  value: PropTypes.string,
};

const renderOpenDetail = (onClickMenuItem) => (menuProps) =>
  (
    <SpecialistMenu
      {...menuProps}
      onClickDetailsPanel={(value) => {
        onClickMenuItem(value);
      }}
    />
  );

const gridOptions = (onClickMenuItem, adminInterface, menu, filterOptionSelected) => ({
  ...DefaultGridOptions,
  rowBuffer: 5,
  columnDefs: [
    {
      headerName: '',
      field: '',
      width: 10,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      filter: false,
      notExportable: true,
    },
    {
      field: 'readableId',
      headerName: 'Id',
      width: 105,
      hide: true,
    },
    {
      field: 'createdAt.value',
      headerName: "Date d'inscription",
      type: 'dateColumn',
    },
    {
      headerName: 'Nom',
      field: 'lastname',
      width: 130,
      enableRowGroup: false,
    },
    {
      headerName: 'Prénom',
      field: 'firstname',
      width: 105,
      enableRowGroup: false,
    },
    ...(menu === 'inspect'
    ? [{
        field: 'status',
        headerName: 'Statut',
        width: 145,
        cellRendererFramework: StatusCellRenderer,
        valueGetter: (p) => _.get(p, 'data.status'),
        filterValueGetter: (p) => _.get(p, 'data.status'),
      },
      // status hidden column for filter by status with action bar
      {
        field: 'statusFilter',
        width: 145,
        filterValueGetter: (p) => _.get(p, 'data.status'),
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        notExportable: true,
      },
    ]
    : [
      {
        field: 'stafferStatus',
        headerName: 'Statut',
        width: 145,
        cellRendererFramework: StatusCellRenderer,
        valueGetter: (p) => _.get(p, 'data.stafferStatus'),
        filterValueGetter: (p) => _.get(p, 'data.stafferStatus'),
      },
      // status hidden column for filter by status with action bar
      {
        field: 'statusFilter',
        width: 145,
        filterValueGetter: (p) => _.get(p, 'data.stafferStatus'),
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        notExportable: true,
      }]),
    {
      field: 'statusReason',
      headerName: 'Raison Inactif',
      width: 145,
      valueGetter: (p) =>
        p?.data?.inactiveReasons?.length > 0 ? constants.inactiveOptions.filter((element) => p.data.inactiveReasons.includes(element.value)).map((object) => object.label) : '',
      hide: filterOptionSelected !== 'inactifs',
    },
    {
      field: 'specialistSkills',
      headerName: 'Compétences',
      // eslint-disable-next-line no-confusing-arrow
      valueGetter: (params) => (params.data.specialistSkills && params.data.specialistSkills.length > 0 ? params.data.specialistSkills.join(', ') : ''),
      filterValueGetter: (p) => _.get(p, 'data.specialistSkills'),
    },
    {
      headerName: 'Téléphone',
      field: 'phone',
      width: 130,
      enableRowGroup: false,
      valueGetter: (params) => formatPhoneNumber(params.data.phone),
    },
    {
      headerName: 'Email',
      field: 'email',
      enableRowGroup: false,
    },
    {
      field: 'experience',
      minWidth: 200,
      maxWidth: 350,
      flex: 2,
      headerName: 'Commentaire Expérience',
    },
    {
      headerName: "Nombre d'inspections",
      field: 'nbInspections',
      width: 105,
      filter: false,
      resizable: true,
      hide: menu === 'staff',
    },
    {
      field: 'dateOfBirth.value',
      headerName: 'Date de naissance',
      type: 'dateColumn',
      enableRowGroup: false,
      hide: true,
    },
    { headerName: 'Adresse', field: 'city', hide: true },
    { headerName: 'Code Postal', field: 'zipCode', hide: true },
    {
      headerName: 'Mobile OS',
      field: 'appConfig.platform',
      width: 105,
      hide: true,
    },
    { headerName: 'Mobile version', field: 'appConfig.appVersion', hide: true },
    { headerName: 'Mobile modèle', field: 'appConfig.model', hide: true },
    {
      field: 'age',
      headerName: 'Age',
      width: 30,
      filter: false,
      hide: true,
    },
    {
      field: 'rank',
      headerName: 'Rang',
      hide: true,
      width: 95,
      filter: false,
      valueGetter: (p) => constants.rankOptions[p?.data?.rank] || 'Membre',
    },
    {
      field: 'dateStatus.value',
      headerName: 'Date de changement du status',
      type: 'dateColumn',
      width: 105,
      filter: false,
      hide: true,
    },
    {
      field: 'regulatoryDocumentsSubmitted',
      headerName: 'Documents reçus',
      width: 120,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.regulatoryDocumentsSubmitted')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.regulatoryDocumentsSubmitted')),
    },
    {
      field: 'testInspectionValidated',
      headerName: 'Inspection test validée',
      width: 145,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.testInspectionValidated')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.testInspectionValidated')),
    },
    /* All Documents */
    {
      field: 'cv',
      headerName: 'CV',
      width: 145,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.cv')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.cv')),
    },
    {
      field: 'mechanicDiploma',
      headerName: 'Diplôme',
      width: 145,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.mechanicDiploma')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.mechanicDiploma')),
    },
    {
      field: 'shine',
      headerName: 'Shine / KBIS',
      width: 145,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.shine')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.shine')),
    },
    {
      field: 'drivingLicence',
      headerName: 'Permis Recto',
      width: 145,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.drivingLicence')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.drivingLicence')),
    },
    {
      field: 'drivingLicence2',
      headerName: 'Permis Verso',
      width: 145,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.drivingLicence2')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.drivingLicence2')),
    },
    {
      field: 'ci_recto',
      headerName: 'CI Resto / Passport',
      width: 145,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.ci_recto')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.ci_recto')),
    },
    {
      field: 'ci_verso',
      headerName: 'CI Verso',
      width: 145,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.ci_verso')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.ci_verso')),
    },
    {
      field: 'companyAddressStreet',
      headerName: 'Adresse de facturation',
      width: 145,
      valueGetter: (p) => `${_.get(p, 'data.companyAddressStreet')}, ${_.get(p, 'data.companyAddressPostalCode')} ${_.get(p, 'data.companyAddressCity')}`,
      filter: false,
      hide: true,
    },
    {
      field: 'siret',
      headerName: 'SIRET',
      width: 145,
      filter: false,
      valueGetter: (p) => _.get(p, 'data.siret'),
      hide: true,
    },
    {
      field: 'iban',
      headerName: 'IBAN',
      width: 145,
      filter: false,
      hide: true,
    },
    {
      field: 'bic',
      headerName: 'BIC',
      width: 145,
      filter: false,
      hide: true,
    },
    {
      field: 'vatInvoicing',
      headerName: 'Facture TTC',
      width: 60,
      hide: true,
      filter: false,
      valueGetter: (p) => getValue(_.get(p, 'data.vatInvoicing')),
      cellRendererFramework: (p) => statusDocumentsAndInspectionCellRenderer(_.get(p, 'data.vatInvoicing')),
    },
    {
      field: '_id',
      headerName: '',
      width: 25,
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: renderOpenDetail(onClickMenuItem),
      cellClass: 'editionCell',
      suppressNavigable: true,
      suppressMovable: true,
      suppressSizeToFit: true,
      hide: !adminInterface,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      pinned: 'right',
      notExportable: true,
    },
  ],
});

export default gridOptions;
