import PropTypes from 'prop-types';
import React from 'react';
import utils from '../../../utils';

class Paginator extends React.Component {
  renderFive = () => {
    if (this.props.pageCount <= 5 && this.props.pageCount > 0) {
      const dataArr = Array(this.props.pageCount).fill(null);
      return (
        <React.Fragment>
          {dataArr.map((index, item) => (
            <button key={utils.uuidv4()} className={item + 1 === this.props.current ? 'current' : ''} onClick={() => this.props.onChange(item + 1)}>
              {item + 1}
            </button>
          ))}
        </React.Fragment>
      );
    }
    return null;
  };

  renderMoreThanFive = () => {
    if (this.props.pageCount > 5) {
      let minValue = Math.min(this.props.current, this.props.current - 5);
      if (minValue < 0) {
        minValue = this.props.current;
      } else if (minValue !== this.props.current) {
        minValue = 5;
      }
      let maxValue = Math.min(this.props.pageCount, this.props.current + 5);

      if (maxValue > this.props.pageCount) {
        maxValue = this.props.pageCount - this.props.current;
      } else if (this.props.current === this.props.pageCount) {
        maxValue = 0;
      } else {
        maxValue -= this.props.current;
      }
      const min = Array(minValue).fill(null);
      const max = Array(maxValue).fill(null);

      return (
        <React.Fragment>
          {min.map((item, index) => (
            <button
              key={utils.uuidv4()}
              className={this.props.current - minValue + index + 1 === this.props.current ? 'current' : ''}
              onClick={() => this.props.onChange(this.props.current - minValue + index + 1)}>
              {this.props.current - minValue + index + 1}
            </button>
          ))}
          {max.map((item, index) => (
            <button
              key={utils.uuidv4()}
              className={this.props.current + index + 1 === this.props.current ? 'current' : ''}
              onClick={() => this.props.onChange(this.props.current + index + 1)}>
              {this.props.current + index + 1}
            </button>
          ))}
        </React.Fragment>
      );
    }
    return null;
  };

  render() {
    if (this.props.pageCount > 0) {
      return (
        <div className='appPaginator'>
          <div className='content'>
            {this.renderFive()}
            {this.renderMoreThanFive()}
          </div>
        </div>
      );
    }
    return <div className='appPaginator'></div>;
  }
}

Paginator.propTypes = {
  pageCount: PropTypes.number,
  current: PropTypes.number,
  onChange: PropTypes.func,
};

export default Paginator;
