import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import formatPhoneNumber from '../../../helpers/phone';
import DefaultGridOptions from '../../shared/atoms/DefaultGridOptions';
import InspectionMenu from '../organisms/InspectionMenu';
import {
  compareStatus, StatusCellRenderer, statusFilterComparator, statusTranslation,
} from './gridStatusUtils';
import ValidationActionCell from './ValidationActionCell';
import ViewInfosActionCell from './ViewInfosActionCell';
import constants from '../../../constants';
import getUrlDomain from '../../../helpers/getUrlDomain';

const AddressCellRenderer = (address) => {
  const addressValue = address?.value || '';
  return <span title={addressValue}>{addressValue}</span>;
};

const ActionsCells = (value, role, inspectionType) => {
  if (!value) {
    return <div />;
  }

  if (['chief', 'superChief', 'admin'].includes(role) && ['pending_validation', 'ordered', 'scheduled'].includes(value.status)) {
    return <ValidationActionCell value={value} />;
  }

  if (['done', 'canceled', 'no_show'].includes(value?.status)) {
    return <ViewInfosActionCell value={value} role={role} inspectionType={inspectionType} />;
  }

  return <div />;
};

const renderUserColumn = (params) => {
  const {
    data: { user },
  } = params;

  if (user?.firstname && user?.lastname) {
    return `${user.firstname} ${user.lastname}`;
  }
  if (user?.firstname) {
    return `${user.firstname}`;
  }
  return '';
};

const getRepairCost = (value, field) => {
  const rp = _.get(value, `data.${field}`);

  if (!rp) {
    return '';
  }

  if (rp.maxCostPublic) {
    return `${rp.costPublic} - ${rp.maxCostPublic}`;
  }

  return rp.costPublic;
};

const viewerUrl = (data) => (
  <span>
    {data?.value ? (
      <a style={{ paddingRight: 6, color: 'rgb(31, 82, 136)' }} href={`${data?.value}`} title='Voir le rapport' target='_blank' rel='noreferrer noopener'>
        {data?.value}
      </a>
    ) : (
      '-'
    )}
  </span>
);

const viewerUrlCustomer = (data, role) => {
  const newViewerUrl = (role && data?.data?.entity?.options?.viewerUrl) ? data?.value?.replace(getUrlDomain(data?.value), data.data.entity.options.viewerUrl) : data?.value;
  return (
    <span>
    {data?.value ? (
      <a style={{ paddingRight: 6, color: 'rgb(31, 82, 136)' }} href={`${newViewerUrl}`} title='Voir le rapport' target='_blank' rel='noreferrer noopener'>
        {data?.value}
      </a>
    ) : (
      '-'
    )}
  </span>
  );
};

const adminPlannedInspectionOptions = (adminInterface, onClickOnMenuItem) => ({
  ...DefaultGridOptions,
  animateColumns: false,
  pagination: true,
  paginationPageSize: 50,
  getRowNodeId: (a) => a._id,
  // Set a background color on the inspection lines with at least one scheduled inspection with the same address for the same site
  rowClassRules: {
    'rag-green': (params) => params?.data?.visualReminder === constants.visualReminder.inspectionOfTheSameSiteAndAddress ? params.data.status === 'ordered' : '',
  },
  columnDefs: [
    {
      field: 'readableId',
      headerName: 'Id',
      width: 60,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      type: 'numberColumn',
    },
    {
      field: 'groupId',
      headerName: 'Id Lot',
      width: 60,
      enableRowGroup: true,
      hide: true,
      type: 'numberColumn',
    },
    {
      field: 'status',
      headerName: 'Statut',
      width: 60,
      filter: false,
      valueGetter: (p) => (p?.data.status ? statusTranslation[p.data.status] : ''),
      filterValueGetter: (p) => p?.data.status || '',
      hide: true,
    },
    {
      field: 'createdAt',
      headerName: 'Créée le',
      type: 'dateTimeColumn',
      hide: true,
      width: 90,
    },
    {
      field: 'generatedAt',
      headerName: 'Réalisée le',
      type: 'dateTimeColumn',
      hide: true,
      width: 90,
    },
    {
      field: 'cancelInfos.date',
      headerName: 'Annulée le',
      type: 'dateTimeColumn',
      hide: true,
      width: 90,
    },
    {
      field: 'appointment.date',
      headerName: 'RDV',
      type: 'dateTimeColumn',
      width: 90,
    },
    {
      field: 'specialist.name',
      hide: !adminInterface,
      headerName: 'Spécialiste',
      width: 140,
      valueGetter: (params) => (params?.data?.specialist ? `${params.data.specialist.firstname} ${params.data.specialist.lastname}` : ''),
    },
    // status column for show status in grid without filter input
    { field: 'vehicle.brand', headerName: 'Marque', width: 100 },
    { field: 'vehicle.model', headerName: 'Modèle', width: 120 },
    {
      field: 'vehicle.immat.displayFormat',
      headerName: 'Immat',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'vehicle.vin',
      headerName: 'VIN',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.outline?.vin || value?.data?.summary?.outline?.vin_ocr || value?.data?.vehicle?.vin,
    },
    {
      field: 'summary.outline.trip',
      headerName: 'Kilométrage',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.outline?.trip || value?.data?.vehicle?.trip,
    },
    {
      field: 'summary.repairCost.totalGeneral.costPublic',
      headerName: 'Min FRE',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.totalGeneral?.costPublic || '',
    },
    {
      field: 'summary.repairCost.totalGeneral.maxCostPublic',
      headerName: 'Max FRE',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.totalGeneral?.maxCostPublic || '',
    },
    {
      field: 'summary.repairCost.defects',
      headerName: 'Nombre de dégâts',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.defects?.length || '',
    },
    { field: 'concession.name', headerName: 'Site', width: 130 },
    { field: 'seller.name', headerName: 'Client', width: 130 },
    {
      field: 'seller.phone',
      headerName: 'Tél Client',
      width: 115,
      hide: true,
      valueGetter: (params) => formatPhoneNumber(params?.data?.seller?.phone),
    },
    {
      field: 'appointment.address',
      headerName: 'Adresse',
      cellRendererFramework: AddressCellRenderer,
      width: 220,
    },
    // status hidden column for filter by status with action bar
    {
      field: 'statusFilter',
      width: 105,
      filterValueGetter: (p) => p?.data?.status || '',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      notExportable: true,
    },
    {
      field: 'assignedAt',
      headerName: 'Assignée le',
      type: 'dateTimeColumn',
      hide: true,
      width: 90,
    },
    {
      field: 'customId',
      headerName: 'Id Client',
      hide: true,
      width: 90,
    },
    {
      field: 'handledBy',
      headerName: 'Traité par',
      filter: 'agTextColumnFilter',
      hide: true,
      width: 90,
      valueGetter: (params) => (params?.data?.comments[0]?.user ? `${params.data.comments[0].user.firstname} ${params.data.comments[0].user.lastname}` : ''),
    },
    {
      field: 'assignedBy',
      headerName: 'Assigné par',
      filter: 'agTextColumnFilter',
      hide: true,
      width: 90,
      valueGetter: (params) =>
        params?.data?.appointment?.assignment?.by ? `${params.data.appointment.assignment.by.firstname} ${params.data.appointment.assignment.by.lastname}` : '',
    },
    {
      field: 'entity.name',
      hide: true,
      headerName: 'Groupe',
      width: 130,
    },
    // report missing hidden column for filter by inspection with report missing using action bar
    {
      field: 'reportMissing',
      valueGetter: (params) =>
        params.data.status === 'scheduled' && params.data.appointment.date && moment(params.data.appointment.date) < moment() && !params.data.generatedAt ? 'reportMissing' : '',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      notExportable: true,
    },
    {
      headerName: 'Rapport',
      field: 'report.title',
      width: 150,
      enableRowGroup: true,
      valueGetter: (value) => value?.data?.report?.title || '-',
      hide: true,
    },
    {
      headerName: 'Lien du rapport',
      field: 'viewerUrl',
      width: 150,
      enableRowGroup: false,
      hide: true,
      valueGetter: (p) => p.data?.summary?.viewerUrl,
      cellRendererFramework: (data) => viewerUrl(data),
    },
    {
      field: '_id',
      headerName: '',
      width: 75,
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: (menuProps) => (
        <InspectionMenu
          {...menuProps}
          onClickEdit={onClickOnMenuItem('edit')}
          onClickCancel={onClickOnMenuItem('cancel')}
          onClickResend={onClickOnMenuItem('resend')}
          onClickDetailsPanel={onClickOnMenuItem('detailsPanel')}
          onClickDuplicatePanel={onClickOnMenuItem('duplicate')}
          onClickValidatePanel={onClickOnMenuItem('validate')}
          onClickUnassign={onClickOnMenuItem('unassign')}
          onClickNoShow={onClickOnMenuItem('no-show')}
          onClickSms={onClickOnMenuItem('sms')}
          onClickEditComments={onClickOnMenuItem('editReport')}
        />
      ),
      cellClass: 'editionCell',
      suppressNavigable: true,
      suppressMovable: true,
      suppressSizeToFit: true,
      hide: !adminInterface,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      notExportable: true,
    },
  ],
});

const adminConcessionsOnDemandInspectionsOptions = (role = 'admin') => ({
  ...DefaultGridOptions,
  animateColumns: false,
  pagination: true,
  paginationPageSize: 50,
  getRowNodeId: (a) => a._id,
  columnDefs: [
    {
      headerName: 'ID',
      type: 'idColumn',
      field: 'readableId',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
    },
    {
      headerName: 'Réalisée le',
      field: 'generatedAt',
      type: 'dateTimeColumn',
      sort: 'desc',
    },
    { headerName: 'Marque', field: 'vehicle.brand' },
    { headerName: 'Modèle', field: 'vehicle.model' },
    {
      field: 'vehicle.immat.displayFormat',
      headerName: 'Immat',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      valueGetter(params) {
        return params.data.vehicle?.immat?.displayFormat || params.data.summary?.outline?.immatLacroix || '';
      },
    },
    {
      field: 'vehicle.vin',
      headerName: 'VIN',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.outline?.vin || value?.data?.summary?.outline?.vin_ocr || value?.data?.vehicle?.vin,
    },
    {
      field: 'summary.outline.trip',
      headerName: 'Kilométrage',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.outline?.trip || value?.data?.vehicle?.trip,
    },
    {
      field: 'summary.repairCost.totalGeneral.costPublic',
      headerName: 'Min FRE',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.totalGeneral?.costPublic || '',
    },
    {
      field: 'summary.repairCost.totalGeneral.maxCostPublic',
      headerName: 'Max FRE',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.totalGeneral?.maxCostPublic || '',
    },
    {
      field: 'summary.repairCost.defects',
      headerName: 'Nombre de dégâts',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.defects?.length || '',
    },
    {
      headerName: 'Utilisateur',
      valueGetter: renderUserColumn,
      filter: 'agSetColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: false },
    },
    {
      headerName: 'Groupe',
      field: 'entity.name',
      filter: 'agSetColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: false },
    },
    {
      headerName: 'Site',
      field: 'concession.name',
      filter: 'agSetColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: false },
    },
    {
      field: '_id',
      headerName: 'Actions',
      type: 'actionsColumn',
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p?.data || '',
      cellRendererFramework: (value) => ActionsCells(value.getValue(), role, 'onDemandInspection'),
      cellClass: 'editionCell',
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      notExportable: true, // custom property for desactivate this column in exportDataAsCsv
    },
  ],
});

const userConcessionsOnDemandInspectionsOptions = (role) => ({
  ...DefaultGridOptions,
  animateColumns: false,
  pagination: true,
  paginationPageSize: 50,
  getRowNodeId: (a) => a._id,
  columnDefs: [
    {
      headerName: 'ID',
      type: 'idColumn',
      field: 'readableId',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      sort: 'desc',
      width: 120,
    },
    {
      headerName: 'Réalisée le',
      field: 'generatedAt',
      type: 'dateTimeColumn',
      sort: 'desc',
    },
    { headerName: 'Marque', field: 'vehicle.brand' },
    { headerName: 'Modèle', field: 'vehicle.model' },
    ...(['chief', 'superChief'].includes(role)
      ? [
        {
          headerName: 'Utilisateur',
          valueGetter: renderUserColumn,
          filter: 'agSetColumnFilter',
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
      ]
      : []),
    ...(['chief', 'superChief'].includes(role)
      ? [
        {
          headerName: 'Utilisateur',
          valueGetter: renderUserColumn,
          filter: 'agSetColumnFilter',
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
      ]
      : []),
    {
      headerName: 'Immatriculation',
      field: 'vehicle.immat.displayFormat',
      width: 150,
      enableRowGroup: false,
      valueGetter(params) {
        return params.data.vehicle?.immat?.displayFormat || params.data.summary?.outline?.immatLacroix || '';
      },
    },
    {
      field: 'vehicle.vin',
      headerName: 'VIN',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.outline?.vin || value?.data?.summary?.outline?.vin_ocr || value?.data?.vehicle?.vin,
    },
    {
      field: 'summary.outline.trip',
      headerName: 'Kilométrage',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.outline?.trip || value?.data?.vehicle?.trip,
    },
    {
      field: 'summary.repairCost.totalGeneral.costPublic',
      headerName: 'Min FRE',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.totalGeneral?.costPublic || '',
    },
    {
      field: 'summary.repairCost.defects',
      headerName: 'Nombre de dégâts',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.defects?.length || '',
    },
    ...(['chief', 'superChief'].includes(role)
      ? [
        {
          headerName: 'Site',
          field: 'concession.name',
          filter: 'agSetColumnFilter',
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
      ]
      : []),
    {
      field: 'status',
      headerName: 'Statut',
      cellRendererFramework: StatusCellRenderer,
      valueGetter: (p) => p?.data?.status && statusTranslation[p.data.status],
      comparator: compareStatus,
      filterParams: { textCustomComparator: statusFilterComparator },
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: false },
      sortable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Rapport',
      field: 'report.title',
      hide: true,
    },
    {
      headerName: 'FRE Carrosserie',
      field: 'summary.repairCost.totalBody',
      width: 150,
      enableRowGroup: false,
      valueGetter: (value) => getRepairCost(value, 'summary.repairCost.totalBody'),
      hide: true,
    },
    {
      headerName: 'FRE Mécanique',
      field: 'summary.repairCost.totalMechanical',
      width: 150,
      enableRowGroup: false,
      valueGetter: (value) => getRepairCost(value, 'summary.repairCost.totalMechanical'),
      hide: true,
    },
    {
      headerName: 'FRE Autre',
      field: 'summary.repairCost.totalOther',
      width: 150,
      enableRowGroup: false,
      valueGetter: (value) => getRepairCost(value, 'summary.repairCost.totalOther'),
      hide: true,
    },
    {
      headerName: 'FRE Total',
      field: 'summary.repairCost.totalGeneral',
      width: 150,
      enableRowGroup: false,
      valueGetter: (value) => getRepairCost(value, 'summary.repairCost.totalGeneral'),
      hide: false,
    },
    {
      headerName: "Durée d'inspection (mins)",
      field: 'summary.inspectionDuration',
      width: 150,
      enableRowGroup: false,
      valueGetter: (value) => (value?.data?.summary?.inspectionDuration ? Math.floor(value.data.summary.inspectionDuration / 60) : ''),
      hide: true,
    },
    {
      headerName: 'Lien du rapport',
      field: 'viewerUrl',
      width: 150,
      enableRowGroup: false,
      hide: true,
      valueGetter: (p) => p.data?.summary?.viewerUrl,
      cellRendererFramework: (data) => viewerUrlCustomer(data, role),
    },
    {
      headerName: 'Lien du rapport sans frais',
      field: 'noCostViewerUrl',
      width: 150,
      enableRowGroup: false,
      hide: true,
      valueGetter: (p) => p.data?.summary?.noCostViewerUrl,
      cellRendererFramework: (data) => viewerUrlCustomer(data, role),
    },
    {
      field: '_id',
      headerName: 'Actions',
      type: 'actionsColumn',
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: (value) => ActionsCells(value.getValue(), role, 'onDemandInspection'),
      cellClass: 'editionCell',
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      notExportable: true, // custom property for desactivate this column in exportDataAsCsv
      width: 150,
    },
  ],
});

const userConcessionsPlannedInspectionsOptions = (role, status) => ({
  ...DefaultGridOptions,
  animateColumns: false,
  pagination: true,
  paginationPageSize: 50,
  getRowNodeId: (a) => a._id,
  columnDefs: [
    {
      headerName: 'ID',
      type: 'idColumn',
      field: 'readableId',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      sort: 'desc',
      width: 120,
    },
    {
      field: 'generatedAt',
      headerName: 'Réalisée le',
      type: 'dateTimeColumn',
      hide: status !== 'done',
    },
    {
      headerName: 'Date RDV',
      field: 'appointment.date',
      type: 'dateTimeColumn',
    },
    {
      field: 'status',
      headerName: 'Statut',
      cellRendererFramework: StatusCellRenderer,
      valueGetter: (p) => p?.data?.status && statusTranslation[p.data.status],
      width: 100,
    },
    // status hidden column for filter by status with action bar
    {
      field: 'statusFilter',
      width: 105,
      filterValueGetter: (p) => p?.data?.status || '',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      notExportable: true,
    },
    { headerName: 'Client', field: 'seller.name', width: 130 },
    {
      headerName: 'Immatriculation',
      field: 'vehicle.immat.displayFormat',
      width: 100,
      enableRowGroup: false,
    },
    {
      field: 'vehicle.vin',
      headerName: 'VIN',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.outline?.vin || value?.data?.summary?.outline?.vin_ocr || value?.data?.vehicle?.vin,
    },
    {
      field: 'summary.outline.trip',
      headerName: 'Kilométrage',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.outline?.trip || value?.data?.vehicle?.trip,
    },
    {
      field: 'summary.repairCost.totalGeneral.costPublic',
      headerName: 'Min FRE',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.totalGeneral?.costPublic || '',
    },
    {
      field: 'summary.repairCost.defects',
      headerName: 'Nombre de dégâts',
      enableRowGroup: false,
      width: 90,
      filter: 'agTextColumnFilter',
      hide: true,
      valueGetter: (value) => value?.data?.summary?.repairCost?.defects?.length || '',
    },
    { headerName: 'Marque', field: 'vehicle.brand', width: 100 },
    { headerName: 'Modèle', field: 'vehicle.model', width: 120 },
    {
      headerName: 'Adresse',
      field: 'appointment.address',
    },
    {
      headerName: 'Créée le',
      field: 'createdAt',
      type: 'dateTimeColumn',
      hide: true,
    },
    {
      headerName: 'FRE Carrosserie',
      field: 'summary.repairCost.totalBody',
      width: 150,
      enableRowGroup: false,
      valueGetter: (value) => getRepairCost(value, 'summary.repairCost.totalBody'),
      hide: true,
    },
    {
      headerName: 'FRE Mécanique',
      field: 'summary.repairCost.totalMechanical',
      width: 150,
      enableRowGroup: false,
      valueGetter: (value) => getRepairCost(value, 'summary.repairCost.totalMechanical'),
      hide: true,
    },
    {
      headerName: 'FRE Autre',
      field: 'summary.repairCost.totalOther',
      width: 150,
      enableRowGroup: false,
      valueGetter: (value) => getRepairCost(value, 'summary.repairCost.totalOther'),
      hide: true,
    },
    {
      headerName: 'FRE Total',
      field: 'summary.repairCost.totalGeneral',
      width: 150,
      enableRowGroup: false,
      valueGetter: (value) => getRepairCost(value, 'summary.repairCost.totalGeneral'),
      hide: false,
    },
    {
      headerName: 'Rapport',
      field: 'report.title',
      width: 150,
      enableRowGroup: true,
      valueGetter: (value) => value?.data?.report?.title || '-',
      hide: true,
    },
    {
      headerName: 'Lien du rapport',
      field: 'viewerUrl',
      width: 150,
      enableRowGroup: false,
      hide: true,
      valueGetter: (p) => p.data?.summary?.viewerUrl,
      cellRendererFramework: (data) => viewerUrlCustomer(data, role),
    },
    {
      headerName: 'Lien du rapport sans frais',
      field: 'noCostViewerUrl',
      width: 150,
      enableRowGroup: false,
      hide: true,
      valueGetter: (p) => p.data?.summary?.noCostViewerUrl,
      cellRendererFramework: (data) => viewerUrlCustomer(data, role),
    },
    ...(['chief', 'superChief'].includes(role)
      ? [
        {
          headerName: 'Utilisateur',
          valueGetter: renderUserColumn,
          filter: 'agSetColumnFilter',
          floatingFilterComponentParams: { suppressFilterButton: false },
          hide: true,
        },
      ]
      : []),
    ...(['chief', 'superChief'].includes(role)
      ? [
        {
          headerName: 'Site',
          field: 'concession.name',
          filter: 'agSetColumnFilter',
          floatingFilterComponentParams: { suppressFilterButton: false },
          hide: true,
        },
      ]
      : []),
    {
      field: 'customId',
      headerName: 'Id Interne',
      hide: true,
      width: 90,
    },
    {
      headerName: 'VAT Type',
      field: 'vatType',
      width: 100,
      valueGetter: (p) => (p?.data.vatType
        ? constants.vatType
          .filter((vat) => vat.value === p.data.vatType)
          .map((result) => result.label)
        : ''),
      filterValueGetter: (p) => p?.data.vatType ? constants.vatType : '',
      hide: true,
    },
    { headerName: 'Expected Price', field: 'expectedPrice', width: 100, hide: true },
    {
      field: '_id',
      headerName: 'Actions',
      type: 'actionsColumn',
      resizable: false,
      sortable: false,
      filter: false,
      valueGetter: (p) => p.data,
      cellRendererFramework: (value) => ActionsCells(value.getValue(), role),
      cellClass: 'editionCell',
      suppressNavigable: true,
      suppressMovable: true,
      enableRowGroup: false,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      notExportable: true, // custom property for desactivate this column in exportDataAsCsv
    },
  ],
});

export {
  userConcessionsOnDemandInspectionsOptions, userConcessionsPlannedInspectionsOptions, adminConcessionsOnDemandInspectionsOptions, adminPlannedInspectionOptions,
};
