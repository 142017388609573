import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import ConcessionsActions from '../../../../actions/Concessions';
import ConcessionsServices from '../../../../services/ConcessionsServices';
import Text from '../../../shared/atoms/Text';

Modal.setAppElement('body');

class viewUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  closeModal = () => {
    this.props.dispatch(ConcessionsActions.getActionToDispatch({ type: 'HIDE_MODAL' }));
  };

  async componentDidMount() {
    const { modalData } = this.props.concessions;

    if (!modalData) {
      return false;
    }

    const findResponse = await ConcessionsServices.getAccount({
      userId: modalData._id,
    }).catch((err) => ({ err }));

    if (findResponse && findResponse.status < 400) {
      this.setState({ user: findResponse.user });
    }
    return true;
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  onChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  renderValidButtonText = () => {
    const { isLoading } = this.props.concessions;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }
    return 'Sauvegarder';
  };

  getRole = (role) => {
    switch (role) {
      case 'superChief':
        return 'Admin de groupe';
      case 'chief':
        return 'Responsable';
      case 'reseller':
        return 'Utilisateur';
      default:
        return null;
    }
  };

  connectOnAccount = () => {
    const { modalData } = this.props.concessions;
    const userId = modalData._id;

    ConcessionsServices.authorizeAdminConnect({ userId }).then(() => {
      alert('Vous pouvez vous connecter sur le compte - 1 minute restante');
    });
  };

  render() {
    const { user = {} } = this.state;
    const names = user.concession ? user.concession.names : [];

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Modal annuler inspection'
        overlayClassName='modal-overlay'
        className='modal modalConcession'
      >
        <div className='modalContent '>
          <div className='mainTitle'>Visualisation d'utilisateur</div>
          {user ? (
            <form className='mt10px'>
              <Row>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Nom de l'utilisateur</label>
                    <Text fontStyle='normal' size={12}>
                      {user.firstname} {user.lastname}
                    </Text>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>E-mail</label>
                    <Text fontStyle='normal' size={12}>
                      {user.email}
                    </Text>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Identifiant interne</label>
                    <Text fontStyle='normal' size={12}>
                      {user.readableId}
                    </Text>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Type de compte</label>
                    <Text fontStyle='normal' size={12}>
                      {this.getRole(user.role)}
                    </Text>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Se connecter sur ce compte</label>
                    <Text fontStyle='normal' size={12} onClick={this.connectOnAccount}>
                      Cliquez-ici - Vous aurez 1 minute pour vous connecter avec le mot de passe admin
                    </Text>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Numéro de téléphone</label>
                    <Text fontStyle='normal' size={12}>
                      {user.phone || 'Non défini'}
                    </Text>
                  </div>
                </Col>
                {user.appConfig && (
                  <Col xs={12} md={6}>
                    <div className='formInput'>
                      <label>Version du mobile</label>
                      <Text fontStyle='normal' size={12}>
                        [{user.appConfig.platform}] - v{user.appConfig.appVersion} - {user.appConfig.brand} {user.appConfig.buidNumber} {user.appConfig.model}
                      </Text>
                    </div>
                  </Col>
                )}
                {user.concession && (
                  <React.Fragment>
                    <Col xs={12} md={6}>
                      <div className='formInput'>
                        <label>Groupe</label>
                        <Text fontStyle='normal' size={12}>
                          {user.concession.entityName}
                        </Text>
                      </div>
                    </Col>
                    {user.concession.name && (
                      <Col xs={12} md={6}>
                        <div className='formInput'>
                          <label>Site</label>
                          <Text fontStyle='normal' size={12}>
                            {user.concession.name}
                          </Text>
                        </div>
                      </Col>
                    )}
                    {names && (
                      <Col xs={12} md={6}>
                        <div className='formInput'>
                          <label>Sites</label>
                          {names.map((elem, key) => (
                            <Text key={key} fontStyle='normal' className='inline mr5px' size={12}>
                              {elem}
                              {names[key + 1] ? ', ' : ''}
                            </Text>
                          ))}
                        </div>
                      </Col>
                    )}
                  </React.Fragment>
                )}
              </Row>
              {this.renderError()}
              <div className='center formButton'>
                <button type='button' onClick={this.closeModal} className='negativ'>
                  Fermer
                </button>
              </div>
            </form>
          ) : (
            <Text fontStyle='normal' size={16}>
              Chargement
            </Text>
          )}
        </div>
      </Modal>
    );
  }
}

viewUser.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
};

export default viewUser;
