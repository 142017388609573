import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import UsersActions from '../../actions/UsersActions';

class VerifyEmailPage extends React.Component {
  state = {
    email: '',
    password: '',
    error: null,
    timer: null,
    redirect: null,
  };

  componentDidMount() {
    // console.log(this.props);
    const { dispatch } = this.props;
    UsersActions.verifyEmail({ token: this.props.match.params.token })(dispatch);
  }

  componentWillUnmount() {
    this.setState({ timer: null });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.application.verifyStep === 2 && !this.state.timer) {
      this.setState({
        timer: setTimeout(() => {
          this.setState({ redirect: '/login' });
        }, 3000),
      });
    }
  }

  setValueOfPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  setValueOfEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  setValueOfShowPassword = (e) => {
    this.setState({ showPassword: e.target.checked });
  };

  handleLoginProcess = (e) => {
    // Make api call
    this.setState({ error: null });
    e.preventDefault();
    e.stopPropagation();
    const { dispatch } = this.props;
    UsersActions.login({
      email: this.state.email,
      password: this.state.password,
    })(dispatch);
  };

  renderError = () => {
    let toRender = null;
    if (this.state.error) {
      toRender = (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return toRender;
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return null;
  };

  renderBody = () => {
    switch (this.props.application.verifyStep) {
      case -1: {
        return (
          <div className='verifyPart step-1'>
            <div className='error'>
              <p>{this.props.application.verifyEmailError}</p>
            </div>
          </div>
        );
      }
      case 1: {
        return (
          <div className='verifyPart step-2'>
            <i className='fa fa-spinner fa-pulse'></i> Vérification en cours
          </div>
        );
      }
      case 2: {
        return (
          <div className='verifyPart step-3'>
            <div>
              <i className='fa fa-check green'></i>
            </div>
            <p className='content'>Votre adresse a bien été vérifiée.</p>
            <p className='content'>Vous allez être redirigé vers la page de connexion dans 3 secondes.</p>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  render() {
    return (
      <div className='verifyEmailPage'>
        <h1 className='logo'>
          Mon<span>Specialiste</span>Auto
        </h1>
        {this.renderRedirect()}
        {this.renderBody()}
        <div className='linkContainer'>
          <Link to='/inscription'>Créer un compte professionel</Link>
          <Link to='/motdepasseoublie'>Première connexion / J'ai oublié mon mot de passe</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ application: state.application });

VerifyEmailPage.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  match: PropTypes.object,
  verifyEmailError: PropTypes.string,
};

export default connect(mapStateToProps)(VerifyEmailPage);
