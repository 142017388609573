import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from 'react-modal';
import Select from 'react-select';

import RuleCondition from '../components/ruleCondition';

import ReportsActions from '../../../../actions/ReportsV3Actions';
import ReportServices from '../../../../services/ReportsService';
import c from '../../../../constants';

Modal.setAppElement('body');

class ModalCreateRule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: '',
      matching: [],
      level: '',
      message: '',
    };
  }

  componentDidMount() {
    const { modalData } = this.props.reportsV3;

    if (this.props.isEdit) {
      const level = _.get(modalData, 'level');
      const levelLabel = level === 'warn' ? 'Commentaire' : 'Pop-up';

      const originalMatching = _.get(modalData, 'matching');
      const matching = _.cloneDeep(originalMatching);
      const conditionsListLabels = {
        '<': { label: 'Inférieur', value: '<' },
        '<=': { label: 'Inférieur ou égal', value: '<=' },
        '===': { label: 'Égal', value: '===' },
        '!==': { label: 'Différent', value: '!==' },
        '=>': { label: 'Supérieur ou égal', value: '=>' },
        '>': { label: 'Supérieur', value: '>' },
      };
      _.forEach(matching, (match, i) => {
        matching[i].condition = conditionsListLabels[match.condition];
      });
      this.setState({
        title: _.get(modalData, 'title'),
        description: _.get(modalData, 'description'),
        matching,
        level: { value: level, label: levelLabel },
        message: _.get(modalData, 'message'),
      });
    }
  }

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(ReportsActions.hideReportsV3Modal());
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }

    return null;
  };

  submit = async (e) => {
    e.preventDefault();
    const { title, description, matching, level, message } = this.state;

    const newMatching = _.cloneDeep(matching);
    _.forEach(newMatching, (match, i) => {
      newMatching[i].specialType = _.get(match, 'specialType._id');
      newMatching[i].condition = _.get(match, 'condition.value');
    });

    const data = {
      title,
      description,
      matching: newMatching,
      level: _.get(level, 'value'),
      message,
    };
    this.setState({ isLoading: true });
    if (this.props.isEdit === true) {
      try {
        await ReportServices.put(c.URI.v3.cpRules.concat(this.props.reportsV3.modalData._id), data);
        this.props.dispatch(ReportsActions.executeAction('rules', { pageNumber: this.props.reportsV3.pageNumber }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    } else {
      try {
        await ReportServices.post(c.URI.v3.cpRules, data);
        this.props.dispatch(ReportsActions.executeAction('rules', { pageNumber: this.props.reportsV3.pageNumber }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    }

    this.setState({ isLoading: false });
  };

  onChange = (e, index = null) => {
    const { target } = e;
    const { name, value } = target;

    if (index !== null && name === 'value') {
      const matching = [...this.state.matching];
      const item = { ...matching[index] };
      item.value = value;
      matching[index] = item; // eslint-disable-line
      this.setState({ matching });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleDeleteCondition = (e, index) => {
    e.preventDefault();
    this.setState({ matching: this.state.matching.filter((condition, i) => i !== index) });
  };

  handleAddCondition = (e) => {
    e.preventDefault();
    const newRule = { value: '', condition: '===', specialType: '' };
    this.setState({ matching: [...this.state.matching, newRule] });
  };

  handleSelect = (selected, obj, index = null) => {
    // console.log('[DEV] select: selected, obj, index', selected, obj, index);
    if (index !== null && (obj === 'specialType' || obj === 'condition')) {
      const matching = [...this.state.matching];
      const item = { ...matching[index] };
      item[obj] = selected;
      matching[index] = item; // eslint-disable-line
      this.setState({ matching });
    } else {
      this.setState({ [obj.name]: selected });
    }
  };

  render() {
    const { isAdmin } = this.props.application.user;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Créer ou modifier une règle métier'
        overlayClassName='modal-overlay'
        className='modal modalCheckpoint'
        style={!isAdmin ? { content: { cursor: 'not-allowed' } } : {}}>
        <div className='modalContent ' style={!isAdmin ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
          <div className='mainTitle'>Nouvelle règle métier</div>
          <form className='mt10px'>
            <div className='formInput'>
              <label>Titre</label>
              <input type='text' name='title' value={this.state.title} placeholder='Titre' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Description</label>
              <input type='text' name='description' value={this.state.description} placeholder='Description' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Message à Afficher</label>
              <input type='text' name='message' value={this.state.message} placeholder='Description' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Type de règle</label>
              <div style={{ width: '50%' }}>
                <Select
                  placeholder='Type de règle'
                  className='multi-select'
                  value={this.state.level}
                  onChange={this.handleSelect}
                  options={[
                    { label: 'Commentaire', value: 'warn' },
                    { label: 'Pop-up', value: 'alert' },
                  ]}
                  isMulti={false}
                  name={'level'}
                  isClearable
                />
              </div>
            </div>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
              <RuleCondition
                rulesList={this.state.matching}
                specialTypes={this.props.reportsV3.constants.specialType}
                handleAdd={this.handleAddCondition}
                handleChangeValue={this.onChange}
                handleDelete={this.handleDeleteCondition}
                handleSelect={this.handleSelect}
              />
            </div>
            {this.renderError()}
          </form>
        </div>
        <div className='center formButton'>
          <button type='button' onClick={this.closeModal} className='negativ'>
            {isAdmin ? 'Annuler' : 'Fermer'}
          </button>
          {isAdmin && !this.state.isLoading && (
            <button type='button' onClick={this.submit} className='positiv' disabled={this.state.isLoading}>
              Sauvegarder
            </button>
          )}
          {this.state.isLoading && (
            <span>
              <i className='fa fa-spinner fa-pulse' /> Sauvegarde en cours.
            </span>
          )}
        </div>
      </Modal>
    );
  }
}

ModalCreateRule.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  reportsV3: PropTypes.object,
  isEdit: PropTypes.bool,
  type: PropTypes.string,
};

export default ModalCreateRule;
