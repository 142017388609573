import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { sendNotifications } from '../../../../actions/specialistsActions';
import ModalBody from '../../../shared/molecules/ModalBody';

Modal.setAppElement('body');

const ModalSendNotificationsToSpecialists = ({ dispatch, isOpen, specialistsSelected, isLoading, isLoadingUpdate, closeModal, error }) => {
  const { addToast } = useToasts();
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [specialistIds, setSpecialistIds] = useState({});

  const sendNotificationsToSpecialistsModal = async () => {
    const result = await sendNotifications(dispatch, { ...specialistIds, title, message });
    if (result.type === 'SEND_NOTIFICATIONS_SUCCESS') {
      addToast('Notification envoyée avec succès', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 2000 });
      closeModal();
    }
  };

  const initialize = async () => {
    const specialistIdsData = specialistsSelected.map((specialistSelectedData) => specialistSelectedData._id);
    setSpecialistIds({ specialistIds: specialistIdsData });
  };

  useEffect(() => {
    (async () => initialize())();
  }, []);

  return (
    <ModalBody
      closeModal={closeModal}
      title='Envoyer une notification mobile aux spécialiste(s) sélectionné(s)'
      isOpen={isOpen}
      actionSave={sendNotificationsToSpecialistsModal}
      isLoading={isLoading}
      updateAction={true}
      error={error}
      loadingButton={isLoadingUpdate}
      classToAdd='changeStatus'
    >
      <div className='modalChangeStatusBilling'>
        {
          <div>
            <h3>Veuillez saisir les champs ci-dessous : </h3>
            <form className='mt10px'>
              <Row>
                <Col xs={12}>
                  <div className='formInput sendNotifications'>
                    <label>Titre</label>
                    <input type='text' name='label' placeholder='Titre' value={title} onChange={(e) => setTitle(e.target.value)} />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className='formInput sendNotifications'>
                    <label>Message</label>
                    <textarea rows='5' cols='33' placeholder='Message' name='message' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        }
      </div>
    </ModalBody>
  );
};

ModalSendNotificationsToSpecialists.propTypes = {
  specialistsSelected: PropTypes.array,
  isOpen: PropTypes.bool,
  isLoadingUpdate: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func,
  refresh: PropTypes.func,
  dispatch: PropTypes.func,
  sendNotifications: PropTypes.func,
  error: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isLoading: state.billings.isLoading,
  isLoadingUpdate: state.billings.isLoadingUpdate,
  error: state.billings.error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  sendNotifications,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSendNotificationsToSpecialists);
