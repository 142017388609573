/* eslint-disable import/no-extraneous-dependencies */
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { updateOnDemandInspections } from '../../../actions/onDemandInspectionsActions';
import { updatePlannedInspections } from '../../../actions/plannedInspectionsActions';
import { rowsToUpdate, selectColumnsToExport } from '../../../helpers/agGridCustom';
import loadInspectionsByNumberOfMonthsSelected from '../../../helpers/inspection';
import { exportExcelDateAndDateTimeFormatter } from '../../shared/atoms/gridDateUtils';
import ActionsBar from '../../shared/organisms/ActionsBar';
import constants from '../../../constants';

/*
 * InspectionsTable working with gridOptions creator in ../atoms/gridOptions and onDemand/planned boolean
 * both props are not optional
 * ex: <InspectionsTable onDemand options={ creatorForUser(user.role) from '../atoms/gridOptions' } />
 */

const InspectionsTable = (props) => {
  const [monthsSelected, setMonthsSelected] = useState(false);
  const [numberOfMonthSelected, setNumberOfMonthSelected] = useState(3);
  const quickFilterTimeoutRef = useRef();
  const inputQuickFilterRef = useRef();
  const gridApiRef = useRef();
  const gridColRef = useRef();

  if ((!props.onDemand && !props.planned) || !props.options) {
    return false;
  }

  const gridOptions = props.options;
  const inspections = props.onDemand ? props.onDemandInspections : props.plannedInspections;
  const oldInspections = props.onDemand ? props.onDemandOldInspections : props.plannedOldInspections;
  const updateInspections = props.onDemand ? props.updateOnDemandInspections : props.updatePlannedInspections;
  const lastUpdate = props.onDemand ? props.onDemandlastUpdate : props.plannedLastUpdate;

  const resizeGrid = () => gridApiRef.current && gridApiRef.current.sizeColumnsToFit();

  window.addEventListener('resize', () => {
    resizeGrid();
  });

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    gridColRef.current = params.columnApi;
    gridApiRef.current.setFilterModel({ statusFilter: { type: 'startsWith', filter: 'ordered' } });
    gridApiRef.current.onFilterChanged();
    resizeGrid();
    // setFilterOptionSelected(status);
  };

  const waitUntilGridIsReady = () =>
    new Promise((resolve) => {
      const intervalCheckRef = setInterval(() => {
        if (gridApiRef) {
          clearInterval(intervalCheckRef);
          resolve();
        }
      }, 100);
    });

  const onClickExportExcel = () => {
    gridApiRef.current.exportDataAsExcel({
      processCellCallback: exportExcelDateAndDateTimeFormatter,
      columnKeys: selectColumnsToExport(gridOptions.columnDefs),
    });
  };

  const onQuickFilterChange = (newValue) => {
    // Debounce quickFilter to avoid freeze while typing
    gridApiRef.current.setFilterModel(null);
    if (quickFilterTimeoutRef.current) {
      clearTimeout(quickFilterTimeoutRef.current);
    }
    quickFilterTimeoutRef.current = setTimeout(() => gridApiRef.current.setQuickFilter(newValue), 400);
  };

  const onClickRefresh = () => {
    gridApiRef.current.deselectAll();
    updateInspections(props.dispatch, lastUpdate);
  };

  const onSelectedMonths = (months) => {
    setMonthsSelected(true);
    setNumberOfMonthSelected(months);
    loadInspectionsByNumberOfMonthsSelected(months, updateInspections, props.dispatch);
  };

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel({
        statusFilter: {
          type: 'startsWith',
          filter: props.status,
        },
      });
    }
  }, [props.status]);

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.applyTransaction({
        add: _.differenceBy(inspections, oldInspections, (i) => i._id),
        remove: _.differenceBy(oldInspections, inspections, (i) => i._id),
        update: rowsToUpdate(inspections, oldInspections),
      });
      setMonthsSelected(false);
      resizeGrid();
    }
  }, [inspections]);

  useEffect(() => {
    waitUntilGridIsReady().then(() => {
      gridApiRef.current.setRowData(inspections);
      gridApiRef.current.setFilterModel({ statusFilter: { type: 'startsWith', filter: props.status } });
      loadInspectionsByNumberOfMonthsSelected(3, updateInspections, props.dispatch, lastUpdate);
    });
  }, []);

  // Auto refresh
  useEffect(() => {
    const interval = setInterval(() => {
      if (props.modalName) return;
      onClickRefresh();
    }, constants.autoRefresh);
    return () => clearInterval(interval);
  });

  return (
    <div className='ag-theme-balham'>
      <ActionsBar
        ref={inputQuickFilterRef}
        onClickRefresh={onClickRefresh}
        onClickExportExcel={onClickExportExcel}
        onQuickFilterChange={onQuickFilterChange}
        interfaceView='concessionInterface'
        onSelectMonthsLoadingInspections={onSelectedMonths}
        numberOfMonthsSelected={numberOfMonthSelected}
        loadingInspectionsByMonths={monthsSelected}
      />
      <AgGridReact {...gridOptions} onGridReady={onGridReady} onColumnVisible={resizeGrid} onToolPanelVisibleChanged={resizeGrid} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.application.user,
  onDemandInspections: state.onDemandInspections.inspections,
  onDemandOldInspections: state.onDemandInspections.oldInspections,
  plannedInspections: state.plannedInspections.inspections,
  plannedOldInspections: state.plannedInspections.oldInspections,
  onDemandlastUpdate: state.onDemandInspections.lastUpdatedAt,
  plannedLastUpdate: state.plannedInspections.lastUpdatedAt,
  modalName: state.concessions.modalName,
});

InspectionsTable.propTypes = {
  dispatch: PropTypes.func,
  onDemand: PropTypes.bool,
  planned: PropTypes.bool,
  onDemandInspections: PropTypes.array,
  plannedInspections: PropTypes.array,
  onDemandOldInspections: PropTypes.array,
  plannedOldInspections: PropTypes.array,
  updatePlannedInspections: PropTypes.func,
  updateOnDemandInspections: PropTypes.func,
  onDemandlastUpdate: PropTypes.number,
  plannedLastUpdate: PropTypes.number,
  options: PropTypes.object,
  updateInspections: PropTypes.func,
  status: PropTypes.string,
  modalName: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updateOnDemandInspections,
  updatePlannedInspections,
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionsTable);
