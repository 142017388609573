import produce from 'immer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import c from '../../../../constants';
import ReportServices from '../../../../services/ReportsService';
import DragDropListCarParts from '../components/dragDropListCarParts';
import MsaElementList from '../components/msaElementList';

Modal.setAppElement('body');

class ModalCreateDefectTypologyCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allMsaCarParts: [],
      msaCarPartLoading: false,
      label: '',
      categoryMsaCarParts: [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  msaCarPartLoading = false;

  allMsaCarPartPage = 0;

  allMsaCarPartTotalPages = 1;

  componentDidMount() {
    if (this.props.isEdit) {
      const { modalData } = this.props;
      this.setState({
        typologyId: _.get(modalData, 'typologyId'),
        defectTypologyId: _.get(modalData, 'defectTypologyId'),
        categoryId: _.get(modalData, 'categoryId'),
        label: _.get(modalData, 'label'),
        categoryMsaCarParts: _.get(modalData, 'msaCarParts') || [],
      });
    } else {
      this.setState({
        categoryMsaCarParts: [],
      });
    }
  }

  handleChange(event) {
    this.setState({ label: event.target.value });
  }

  submit = async (e) => {
    e.preventDefault();

    const { typologyId, defectTypologyId, categoryId, label, categoryMsaCarParts } = this.state;

    const data = {
      label,
      msaCarParts: categoryMsaCarParts.map((msaCarPart) => msaCarPart._id),
    };

    this.setState({ isLoading: true });
    if (this.props.isEdit === true) {
      try {
        await ReportServices.put(c.URI.v3.defectTypologies.concat(`/${typologyId}/defectTypology/${defectTypologyId}/category/${categoryId}`), data);
        this.props.onSubmit('defectTypologyCategory', this.props.modalData, { label, msaCarParts: categoryMsaCarParts }); // need the labels for UI
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    }

    this.setState({ isLoading: false });
  };

  handleDeleteItem = (e, sortIndex) => {
    e.preventDefault();
    const nextCarParts = produce(this.state.categoryMsaCarParts, (draftState) => {
      draftState.splice(sortIndex, 1);
    });
    this.setState({ categoryMsaCarParts: nextCarParts });
  };

  handleAddItem = (e, item) => {
    e.preventDefault();
    const nextCarParts = produce(this.state.categoryMsaCarParts, (draftState) => {
      draftState.push(item);
    });
    this.setState({ categoryMsaCarParts: nextCarParts });
  };

  loadCarParts = async () => {
    const { search } = this.state;

    this.msaCarPartLoading = true;
    const pages = await ReportServices.getAll(c.URI.v3.msaCarPart, {
      pageNumber: this.allMsaCarPartPage > 0 ? this.allMsaCarPartPage : 1,
      searchTerm: search,
      usedByMsa: 'true',
    });

    this.msaCarPartLoading = false;
    if (pages && pages.allMsaCarPartTotalPages) this.allMsaCarPartTotalPages = pages.allMsaCarPartTotalPages;
    if (pages && pages.items && pages.items.length > 0) {
      this.setState({ allMsaCarParts: [...this.state.allMsaCarParts, ...pages.items] });
    }
  };

  // hacky solution to force update on stateless component
  forceListUpdate = () => {
    this.allMsaCarPartTotalPages = 1;
    this.allMsaCarPartPage = 0;
    this.setState({ allMsaCarParts: [{ label: '...' }] });
    this.setState({ allMsaCarParts: [] });
  };

  handleSearch = (e) => {
    const { target } = e;
    const { value } = target;

    this.setState({ search: value });
    this.forceListUpdate();
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }

    return null;
  };

  renderValidButtonText = () => {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }
    return (
      <button type='button' onClick={this.submit} className='positiv' disabled={isLoading}>
        Sauvegarder
      </button>
    );
  };

  render() {
    const userCanEdit = this.props.isEdit;

    const CarPartItems = this.state.categoryMsaCarParts || [];
    const dragDropCarPartsItems = [];
    _.times(CarPartItems.length, (n) => dragDropCarPartsItems.push(n));

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Modifier une catégorie'
        overlayClassName='modal-overlay'
        className='modal modalReport'
        style={!userCanEdit ? { content: { cursor: 'not-allowed' } } : {}}
      >
        <Col className='modalContent' style={!userCanEdit ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
          <div className='mainTitle'>
            {' '}
            <span className='modalFontBig'>Catégorie de défaut pour {this.state.defectTypologyId}</span>
          </div>
          <form className='mt10px modalFont'>
            <Row xs={12} style={{ marginBottom: 5 }}>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Nom</label>
                  <input type='text' placeholder='Nom de la catégorie' value={this.state.label} onChange={this.handleChange} style={{ minHeight: 38, borderRadius: 8 }} />
                </div>
              </Col>
              <Col xs={6}></Col>
            </Row>

            <Row xs={12} style={{ marginBottom: 20 }}>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Liste de pièces MSA</label>
                </div>
                <div style={{ maxWidth: '92%' }}>
                  <div style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderColor: 'hsl(0,0%,80%)',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 5,
                      }}
                    >
                      <div style={{ fontSize: 13, marginLeft: 5, width: '90%' }}>
                        <i className='fa fa-search' />
                        <DebounceInput
                          style={{
                            borderWidth: 0,
                            height: 35,
                            marginLeft: 10,
                            marginRight: 10,
                            width: '90%',
                          }}
                          type='text'
                          name='search'
                          debounceTimeout={300}
                          value={this.state.search}
                          onChange={this.handleSearch}
                          placeholder='Recherchez...'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <MsaElementList
                  // width={400}
                  list={this.state.allMsaCarParts}
                  type='msaCarPart'
                  loadNextPage={() => {
                    this.allMsaCarPartPage += 1;
                    this.loadCarParts();
                  }}
                  hasNextPage={this.allMsaCarPartPage < this.allMsaCarPartTotalPages}
                  isNextPageLoading={this.msaCarPartLoading}
                  handleAddItem={this.handleAddItem}
                />
              </Col>
              <Col xs={6}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderWidth: 0,
                  }}
                ></div>

                <div className='formInput'>
                  <label>Pièces MSA liées</label>
                </div>
                <DragDropListCarParts
                  carParts={CarPartItems}
                  type={'MsaCarPart'}
                  handleDeleteItem={this.handleDeleteItem}
                  // handleRequiredItem={this.handleRequiredItem}
                  // onSortEnd={this.onSortEnd}
                />
              </Col>
            </Row>
            {this.renderError()}
          </form>
        </Col>
        <div className='center formButton'>
          <button type='button' onClick={this.props.onSubmit} className='negativ'>
            {userCanEdit ? 'Annuler' : 'Fermer'}
          </button>
          {userCanEdit && this.renderValidButtonText()}
        </div>
      </Modal>
    );
  }
}

ModalCreateDefectTypologyCategory.propTypes = {
  onSubmit: PropTypes.func,
  modalData: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default ModalCreateDefectTypologyCategory;
