import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { transformDataSpecialist } from '../../helpers/specialistsFilter';
import SpecialistsServices from '../../services/SpecialistsServices';
import SpecialistDetailsDrawer from '../users/specialists/organisms/SpecialistDetailsDrawer';
import SearchBox from './MapSearchBox';

const Marker = (
  {
    iconPath, onClick, className, orderedInspectionQuantity, busySpecialist,
  },
) => (
  <div className='marker-container'>
     {busySpecialist && <div>
     <img src={`/img/specialist-busy.png`} className='inspections-busy' alt='markerIcon' />
       </div>}
   <img src={iconPath} className={className} onClick={onClick} alt='markerIcon' />
     <div className='inspections-quantity'>{orderedInspectionQuantity}</div>
  </div>
);

const getIcon = (status, hurry = null) => {
  let url;

  switch (status) {
    case 'ordered':
      url = 'inspection-ordered.png';
      break;
    case 'ordered-grouped':
      url = 'inspection-ordered-grouped.png';
      break;
    case 'scheduled':
      url = 'inspection-scheduled.png';
      break;
    case 'ongoing':
      url = 'inspection-ongoing.png';
      break;
    case 'done':
      url = 'inspection-done.png';
      break;
    case 'canceled':
      url = 'inspection-canceled.png';
      break;

    case 'mechanic-actif':
      url = 'specialist-active.png';
      break;
    case 'mechanic-confirme':
      url = 'specialist-confirmed.png';
      break;
    case 'mechanic-non_confirme':
      url = 'specialist-notConfirmed.png';
      break;
    case 'mechanic-nouveaux':
      url = 'specialist-new.png';
      break;
    case 'mechanic-bloques':
      url = 'specialist-blocked.png';
      break;
    case 'mechanic-inactifs':
      url = 'specialist-inactif.png';
      break;
    default:
      url = 'other-marker.svg';
  }
  if (hurry) {
    url = 'inspection-hurry.png';
  }

  return url;
};
const MapComponent = ({
  mapPosition,
  mapZoom,
  inspectionsOrMissions,
  inspectionsPositions,
  specialists,
  skills: specialistSkillsList,
  specialistPositions,
  handleInspectionOrMissionClick,
  handleSpecialistUpdate,
  specialistKind,
  missions,
}) => {
  const [showSpecialist, setShowSpecialist] = useState(false);
  const [specialistSelected, setSpecialistSelected] = useState(null);
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [places, setPlaces] = useState([]);

  const apiHasLoaded = (map, maps) => {
    setMapApi(maps);
    setMapInstance(map);
    setMapApiLoaded(true);
  };

  const mapRef = useRef();

  const addPlace = (place) => {
    setPlaces(place);
  };

  const updateSpecialist = async (specialistToUpdate) => {
    const { status, body } = await SpecialistsServices.updateSpecialist(specialistToUpdate.value, specialistToUpdate._id, specialistKind);
    if (status !== 200 || !body) {
      alert(body.error || 'Une erreur est survenue. Veuillez réessayer.');
    } else {
      // Refresh the data in the state
      handleSpecialistUpdate(transformDataSpecialist(body));

      // Refresh the SpecialistView
      setSpecialistSelected(transformDataSpecialist(body));
    }
  };

  const handleSpecialistClick = (event, specialistChosen) => {
    setShowSpecialist(true);
    setSpecialistSelected(specialistChosen);
  };

  const points = [];

  inspectionsOrMissions.forEach((inspectionOrMission) => {
    points.push({
      type: 'Feature',
      properties: { cluster: false, inspectionOrMissionId: inspectionOrMission._id, category: inspectionOrMission.status },
      geometry: {
        type: 'Point',
        coordinates: [
          parseFloat(inspectionOrMission.appointment.lng),
          parseFloat(inspectionOrMission.appointment.lat),
        ],
      },
    });
    return points;
  });

  const onGoingMissionsSpecialists = [];
  if (specialistKind === 'staff') {
    missions.forEach((mission) => (mission.status === 'ongoing' || mission.status === 'scheduled') && onGoingMissionsSpecialists.push(mission.specialist._id));
  }

  return (
    <div style={{ height: 'calc(100vh - 80px)', width: '100%' }}>
      {mapApiLoaded && <SearchBox map={mapInstance} mapApi={mapApi} addPlace={addPlace} />}
      <GoogleMapReact
          // The API Key is protected by source domain
          bootstrapURLKeys={{
            key: 'AIzaSyDRUVsXomsHUVzdHTVVLvE53g2gqOJ9RKI',
            libraries: ['places', 'geometry'],
          }}
          center={mapPosition}
          zoom={mapZoom}
          options={{
            zoomControl: true,
            streetViewControl: true,
            fullscreenControl: false,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => { apiHasLoaded(map, maps); mapRef.current = map; }}
        >
        {inspectionsPositions
          && inspectionsPositions.map((elem, index) => (
            <Marker
              key={inspectionsOrMissions[index]._id}
              lat={elem[0]}
              lng={elem[1]}
              iconPath={`/img/${getIcon(inspectionsOrMissions[index].status, inspectionsOrMissions[index].hurry)}`}
              onClick={() => handleInspectionOrMissionClick(inspectionsOrMissions[index])}
            />
          ))}
        {specialistPositions
          && specialistPositions.map((elem, index) => (
              <Marker
                key={specialists[index]._id}
                lat={elem[0]}
                lng={elem[1]}
                iconPath={`/img/${getIcon(specialistKind === 'staff' ? `mechanic-${specialists[index].stafferStatus}` : `mechanic-${specialists[index].status}`)}`}
                onClick={(e) => handleSpecialistClick(e, specialists[index])}
                className='marker'
                busySpecialist={onGoingMissionsSpecialists.some((onGoingMission) => onGoingMission === specialists[index]._id)}
              />
          ))}

        {!_.isEmpty(places)
              && places.map((place, item) => <Marker className='marker' key={item} text={place.name} iconPath='/img/location.svg' lat={place.geometry.location.lat()} lng={place.geometry.location.lng()} />)}
      </GoogleMapReact>
      {showSpecialist && specialistSelected && (
        <SpecialistDetailsDrawer
          streetView
          isOpen={showSpecialist}
          specialistId={specialistSelected._id}
          specialistSkillsList={specialistSkillsList}
          updateSpecialist={updateSpecialist}
          closeCard={() => {
            setShowSpecialist(false);
            setSpecialistSelected(null);
          }}
          mask={false}
          specialistKind={specialistKind}
        />
      )}
    </div>
  );
};

MapComponent.propTypes = {
  mapPosition: PropTypes.array,
  mapZoom: PropTypes.number,
  positions: PropTypes.array,
  inspectionsOrMissions: PropTypes.array,
  inspectionsPositions: PropTypes.array,
  specialists: PropTypes.array,
  skills: PropTypes.array,
  specialistPositions: PropTypes.array,
  handleInspectionOrMissionClick: PropTypes.func,
  handleSpecialistUpdate: PropTypes.func,
  dataForCluster: PropTypes.object,
  specialistKind: PropTypes.string,
  missions: PropTypes.array,
};

Marker.propTypes = {
  iconPath: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isCluster: PropTypes.bool,
  clusterSize: PropTypes.number,
  status: PropTypes.string,
  orderedInspectionQuantity: PropTypes.number,
  groupByConcessionInAddress: PropTypes.number,
  busySpecialist: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  missions: state.missions.missions,
});

export default connect(mapStateToProps, null)(MapComponent);
