import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const InfosFacturationEditor = ({
  companyName,
  companyAddressStreet,
  companyAddressStreetComplement,
  companyAddressCity,
  companyAddressPostalCode,
  companyAddressRegion,
  siret,
  bic,
  iban,
  vatInvoicing,
  save,
  cancel,
}) => {
  const [companyNameState, setCompanyName] = useState(null);
  const [companyAddressStreetState, setCompanyAddressStreetState] = useState(null);
  const [companyAddressStreetComplementState, setCompanyAddressStreetComplementState] = useState(null);
  const [companyAddressCityState, setCompanyAddressCityState] = useState(null);
  const [companyAddressPostalCodeState, setCompanyAddressPostalCodeState] = useState(null);
  const [companyAddressRegionState, setCompanyAddressRegionState] = useState(null);
  const [siretState, setSiretState] = useState(null);
  const [bicState, setBicState] = useState(null);
  const [ibanState, setIbanState] = useState(null);
  const [vatInvoicingState, setVatInvoicingState] = useState(null);

  const saveInfos = async () => {
    await save({
      companyName: companyNameState,
      companyAddressStreet: companyAddressStreetState,
      companyAddressStreetComplement: companyAddressStreetComplementState,
      companyAddressCity: companyAddressCityState,
      companyAddressPostalCode: companyAddressPostalCodeState,
      companyAddressRegion: companyAddressRegionState,
      siret: siretState,
      bic: bicState,
      iban: ibanState,
      vatInvoicing: vatInvoicingState,
    });
  };

  useEffect(() => {
    setCompanyName(companyName);
    setCompanyAddressStreetState(companyAddressStreet);
    setCompanyAddressStreetComplementState(companyAddressStreetComplement);
    setCompanyAddressCityState(companyAddressCity);
    setCompanyAddressPostalCodeState(companyAddressPostalCode);
    setCompanyAddressRegionState(companyAddressRegion);
    setSiretState(siret);
    setBicState(bic);
    setIbanState(iban);
    setVatInvoicingState(vatInvoicing);
  }, []);

  return (
    <>
      <ul>
        <li className='full'>
          <label>Nom Entreprise (si applicable)</label>
          <div>
            <input data-cy='companyName' type='text' value={companyNameState || ''} placeholder='Nom entreprise' onChange={(e) => setCompanyName(e.target.value)} />
          </div>
        </li>
        <li className='full'>
          <label>Siret</label>
          <div>
            <input data-cy='siret' type='text' value={siretState || ''} placeholder='Siret' onChange={(e) => setSiretState(e.target.value)} />
          </div>
        </li>
        <li className='full'>
          <label>Numéro et rue de l'adresse de facturation</label>
          <div>
            <input
            data-cy='billingAddress'
              type='text'
              value={companyAddressStreetState || ''}
              placeholder='Ex: 16 avenue des champs élysées'
              onChange={(e) => setCompanyAddressStreetState(e.target.value)}
            />
          </div>
        </li>
        <li>
          <label>Ville</label>
          <div>
            <input data-cy='cityAddress' type='text' value={companyAddressCityState || ''} placeholder='Ville' onChange={(e) => setCompanyAddressCityState(e.target.value)} />
          </div>
        </li>
        <li>
          <label>Code postal</label>
          <div>
            <input data-cy='zipCode' type='text' value={companyAddressPostalCodeState || ''} placeholder='Code postal' onChange={(e) => setCompanyAddressPostalCodeState(e.target.value)} />
          </div>
        </li>
        <li className='full'>
          <label>Région</label>
          <div>
            <input data-cy='region' type='text' value={companyAddressRegionState || ''} placeholder='Région. Ex: Ile de France' onChange={(e) => setCompanyAddressRegionState(e.target.value)} />
          </div>
        </li>
        <li className='full'>
          <label>{"Complément d'adresse"}</label>
          <div>
            <input
            data-cy='complementaryAddress'
              type='text'
              value={companyAddressStreetComplementState || ''}
              placeholder="Complément d'adresse"
              onChange={(e) => setCompanyAddressStreetComplementState(e.target.value)}
            />
          </div>
        </li>
        <li className='full'>
          <label>BIC</label>
          <div>
            <input data-cy='bic' type='text' value={bicState || ''} placeholder='BIC' onChange={(e) => setBicState(e.target.value)} />
          </div>
        </li>
        <li className='full'>
          <label>IBAN</label>
          <div>
            <input data-cy='iban' type='text' value={ibanState || ''} placeholder='IBAN' onChange={(e) => setIbanState(e.target.value)} />
          </div>
        </li>
        <li data-cy='vatInvoicing' className='full'>
          <Checkbox checked={vatInvoicingState} onChange={(e) => setVatInvoicingState(e.target.checked)}>
            Facturation TTC
          </Checkbox>
        </li>
      </ul>
      <div className='specialistFormButton'>
        <button className='specialistFormButtonCancel' onClick={cancel}>
          annuler
        </button>
        <button data-cy='saveBilling' className='specialistFormButtonSubmit' onClick={saveInfos}>
          sauvegarder
        </button>
      </div>
    </>
  );
};

InfosFacturationEditor.propTypes = {
  companyName: PropTypes.string,
  companyAddressStreet: PropTypes.string,
  siret: PropTypes.string,
  companyAddressStreetComplement: PropTypes.string,
  companyAddressCity: PropTypes.string,
  companyAddressPostalCode: PropTypes.string,
  companyAddressRegion: PropTypes.string,
  bic: PropTypes.string,
  iban: PropTypes.string,
  vatInvoicing: PropTypes.bool,
  cancel: PropTypes.func,
  save: PropTypes.func,
};

export default InfosFacturationEditor;
