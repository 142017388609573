/* eslint-disable import/no-extraneous-dependencies */
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { clearBilling, getBillings } from '../../../actions/BillingsActions';
import { getSpecialist, getSpecialistSkills, updateSpecialist } from '../../../actions/specialistsActions';
import constants from '../../../constants';
import { rowsToUpdate, selectColumnsToExport } from '../../../helpers/agGridCustom';
import Filters from '../../shared/atoms/Filters';
import { exportExcelDateAndDateTimeFormatter } from '../../shared/atoms/gridDateUtils';
import ActionsBar from '../../shared/organisms/ActionsBar';
import SpecialistDetailsDrawer from '../../users/specialists/organisms/SpecialistDetailsDrawer';
import filtersOptions from '../atoms/filtersOptions';
import options from '../atoms/gridOptions';
import ModalChangeStatusBilling from '../organisms/ModalChangeStatusBilling';
import ModalViewBilling from '../organisms/ModalViewBilling';

const BillingsContainer = ({ dispatch, billings, oldBillings, specialistState, specialistSkillsState }) => {
  const [billingsData, setBillingsData] = useState(billings);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalToDisplay, setModalToDisplay] = useState('');
  const [onClickItem, setOnClickItem] = useState(false);
  const [specialistSelected, setSpecialistSelected] = useState({});
  const [billingSelected, setBillingSelected] = useState({});
  const [skills, setSkills] = useState([]);
  const [filterOptionSelected, setFilterOptionSelected] = useState(constants.statusBilling.computed.name);
  const gridApiRef = useRef();
  const gridColRef = useRef();
  const inputQuickFilterRef = useRef();
  const quickFilterTimeoutRef = useRef();

  const getBillingsAndSpecialistSkills = async (refresh = false) => {
    if (billings.length === 0 || refresh) {
      await getBillings(dispatch);
    }
    if (specialistSkillsState.length === 0 || refresh) {
      await getSpecialistSkills(dispatch);
    }
  };

  const resizeGrid = () => gridApiRef.current && gridApiRef.current.sizeColumnsToFit();

  const waitUntilGridIsReady = () =>
    new Promise((resolve) => {
      const intervalCheckRef = setInterval(() => {
        if (gridApiRef) {
          clearInterval(intervalCheckRef);
          resolve();
        }
      }, 500);
    });

  const onQuickFilterChange = (newValue) => {
    if (quickFilterTimeoutRef.current) {
      clearTimeout(quickFilterTimeoutRef.current);
    }
    quickFilterTimeoutRef.current = setTimeout(() => gridApiRef.current.setQuickFilter(newValue), 400);
  };

  const onClickQuickStatusFilter = (status) => {
    if (gridApiRef.current) {
      inputQuickFilterRef.current.reset();
      gridApiRef.current.deselectAll();

      switch (status) {
        case constants.statusBilling.estimate.name:
        case constants.statusBilling.computed.name:
          gridApiRef.current.setRowData(billingsData.filter((e) => e.status === status));
          break;
        case constants.statusBilling.ordered.name:
          gridApiRef.current.setRowData(billingsData.filter((e) => ![constants.statusBilling.estimate.name, constants.statusBilling.computed.name].includes(e.status)));
          break;
        default:
          throw new Error('Status billing unknown');
      }

      gridApiRef.current.setSortModel([{ colId: 'updatedAt', sort: 'asc' }]);
      setFilterOptionSelected(status);
      resizeGrid();
    }
  };

  const onClickOnMenuItem = async (itemName, billingValue) => {
    if (itemName === constants.nameModal.billing.editOrViewBilling) {
      setBillingSelected(billingValue);
      setModalToDisplay(constants.nameModal.billing.editOrViewBilling);
    } else if (itemName === constants.nameModal.specialist.detailsSpecialist) {
      setModalToDisplay(constants.nameModal.specialist.detailsSpecialist);
      await getSpecialist(dispatch, _.get(billingValue, 'specialist'));
    }
    setOnClickItem(true);
  };

  const gridOptions = options(onClickOnMenuItem);

  const closeModal = () => {
    gridApiRef.current.deselectAll();
    setModalToDisplay('');
    setSpecialistSelected(null);
    onClickQuickStatusFilter(filterOptionSelected);
    dispatch(clearBilling());
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    gridColRef.current = params.columnApi;
    let setSelectedRowsTimeoutRef = null;
    onClickQuickStatusFilter(filterOptionSelected);

    // When clicking "select all" this even is called for each row (so many times !)
    // We only need to run setSelectedRows once so we use a debounce to do it at the end
    params.api.addEventListener('rowSelected', () => {
      if (setSelectedRowsTimeoutRef) {
        clearTimeout(setSelectedRowsTimeoutRef);
      }
      setSelectedRowsTimeoutRef = setTimeout(() => {
        setSelectedRows(params.api.getSelectedRows());
      }, 50);
    });
  };

  const onClickRefresh = async () => getBillingsAndSpecialistSkills(true);

  const updateSpecialistView = async (specialistToUpdate) => {
    await updateSpecialist(dispatch, specialistToUpdate.value, specialistToUpdate._id);
  };

  const onClickChangeStatus = () => {
    setModalToDisplay(constants.nameModal.billing.changeStatusBilling);
  };

  const onClickExportExcel = () => {
    gridApiRef.current.exportDataAsExcel({
      processCellCallback: exportExcelDateAndDateTimeFormatter,
      columnKeys: selectColumnsToExport(options().columnDefs),
    });
  };

  useEffect(() => {
    if (Object.keys(specialistState).length > 0) {
      setSpecialistSelected(specialistState);
      setModalToDisplay(constants.nameModal.specialist.detailsSpecialist);
    }
  }, [specialistState]);

  useEffect(() => {
    if (gridApiRef.current) {
      waitUntilGridIsReady().then(() => {
        gridApiRef.current.applyTransaction({
          add: _.differenceBy(billings, billingsData, (i) => i._id),
          remove: _.differenceBy(oldBillings, billings, (i) => i._id),
          update: rowsToUpdate(billings, oldBillings),
        });
        onClickQuickStatusFilter(filterOptionSelected);
        resizeGrid();
      });
    }
  }, [billingsData]);

  useEffect(() => {
    waitUntilGridIsReady().then(() => {
      gridApiRef.current.setRowData(billings);
      setBillingsData(billings);
      onClickQuickStatusFilter(filterOptionSelected);
    });
  }, [billings]);

  useEffect(() => {
    setSkills(specialistSkillsState);
  }, [specialistSkillsState]);

  useEffect(() => {
    (async () => getBillingsAndSpecialistSkills())();
  }, []);

  return (
    <div className='billings-container'>
      <Filters selectOptions={onClickQuickStatusFilter} options={filtersOptions} activeMenu={filterOptionSelected} />
      <ActionsBar
        ref={inputQuickFilterRef}
        selectedRows={selectedRows}
        onClickRefresh={onClickRefresh}
        onClickExportExcel={onClickExportExcel}
        onQuickFilterChange={onQuickFilterChange}
        onClickChangeStatus={onClickChangeStatus}
        billingView={filterOptionSelected !== constants.statusBilling.computed.name}
      />
      <div className='billings-container-grid'>
        <div className='ag-theme-balham'>
          <AgGridReact {...gridOptions} onGridReady={onGridReady} onColumnVisible={resizeGrid} onToolPanelVisibleChanged={resizeGrid} />
        </div>
        {modalToDisplay === constants.nameModal.billing.editOrViewBilling && onClickItem && (
          <ModalViewBilling billingSelected={billingSelected} isOpen={modalToDisplay === constants.nameModal.billing.editOrViewBilling} closeModal={closeModal} />
        )}
        {modalToDisplay === constants.nameModal.billing.changeStatusBilling && (
          <ModalChangeStatusBilling billingsSelected={selectedRows} isOpen={modalToDisplay === constants.nameModal.billing.changeStatusBilling} closeModal={closeModal} />
        )}
        {modalToDisplay === constants.nameModal.specialist.detailsSpecialist && specialistSelected && onClickItem && (
          <SpecialistDetailsDrawer
            isOpen={modalToDisplay === constants.nameModal.specialist.detailsSpecialist}
            specialistId={specialistSelected._id}
            specialistSkillsList={skills}
            updateSpecialist={updateSpecialistView}
            closeCard={closeModal}
          />
        )}
      </div>
    </div>
  );
};

BillingsContainer.propTypes = {
  adminInterface: PropTypes.bool,
  isLoading: PropTypes.bool,
  billings: PropTypes.array,
  oldBillings: PropTypes.array,
  specialistState: PropTypes.object,
  specialistSkillsState: PropTypes.array,
  dispatch: PropTypes.func,
  getBillings: PropTypes.func,
  clearBilling: PropTypes.func,
  clearObjectBilling: PropTypes.func,
  getSpecialist: PropTypes.func,
  getSpecialistSkills: PropTypes.func,
};

const mapStateToProps = (state) => ({
  billings: state.billings.billings,
  oldBillings: state.billings.oldBillings,
  isLoading: state.billings.isLoading,
  specialistState: state.specialists.specialist,
  specialistSkillsState: state.specialists.specialistSkills,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getBillings,
  getSpecialist,
  updateSpecialist,
  getSpecialistSkills,
  clearBilling,
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingsContainer);
