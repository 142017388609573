import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { isNumeric, validateDateFormat } from '../../../helpers/maskDate';
import constants from '../../../constants';

const EditCreateInspectionCarInfo = ({ newInspection, onInputValueChange }) => {
  const elementRef = useRef();
  const [entryIntoServiceDateState, setEntryIntoServiceDateState] = useState(newInspection?.vehicle?.entryIntoServiceDate || null);

  useEffect(() => {
    if (_.get(newInspection, 'vehicle.entryIntoServiceDate')) {
      setEntryIntoServiceDateState(moment(_.get(newInspection, 'vehicle.entryIntoServiceDate')).format(constants.formatDateAppointment));
    }
  }, [_.get(newInspection, 'vehicle.entryIntoServiceDate')]);

  useEffect(() => {
    const divElement = elementRef.current;
    if (divElement) {
      // Set Max Length.
      divElement.setAttribute('maxlength', 10);
      // Only allow Numeric Keys.
      divElement.onkeydown = (e) => {
        isNumeric(divElement, e);
      };
      // Validate Date as User types.
      divElement.onkeyup = () => {
        validateDateFormat(divElement);
      };
    }
  }, []);

  return (
    <div>
      <div name='vehicle.brand' className='formInput'>
        <label>Marque du véhicule.* Ex: Peugeot</label>
        <input
          data-cy='carBrand'
          type='text'
          value={_.get(newInspection, 'vehicle.brand') || ''}
          placeholder='Marque. Ex: Peugeot'
          onChange={(e) => onInputValueChange('vehicle.brand')(e.target.value)}
        />
      </div>
      <div name='vehicle.model' className='formInput'>
        <label>Modèle du véhicule.* Ex: 508</label>
        <input data-cy='carModel' type='text' value={_.get(newInspection, 'vehicle.model') || ''} placeholder='Modèle. Ex: 508' onChange={(e) => onInputValueChange('vehicle.model')(e.target.value)} />
      </div>
      <div className='formInput'>
        <label>Date de mise en circulation.* Ex: 05/12/2018 </label>
        <input
          ref={elementRef}
          data-cy='carYear'
          type='text'
          defaultValue={entryIntoServiceDateState || ''}
          placeholder='JJ/MM/AAAA. Ex: 05/12/2018'
          onChange={(e) => onInputValueChange('vehicle.entryIntoServiceDate')(e.target.value)}
        />
        <span className="error" style={{ display: 'none' }}>
          Date invalide, format de date (JJ/MM/AAAA).
        </span>
      </div>
      <div className='formInput'>
        <label>Immatriculation du véhicule (si disponible)</label>
        <input
          data-cy='carImmat'
          name='vehicle.immat'
          type='text'
          value={_.get(newInspection, 'vehicle.immat') || ''}
          placeholder='Immatriculation. Ex: AB-123-CD'
          onChange={(e) => onInputValueChange('vehicle.immat')(e.target.value)}
        />
      </div>
      <div className='formInput'>
        <label>VIN </label>
        <input
          data-cy='vin'
          type='text'
          value={_.get(newInspection, 'vehicle.vin') || ''}
          placeholder='Vin. Ex: 4Y1SL65848Z411439'
          onChange={(e) => onInputValueChange('vehicle.vin')(e.target.value)}
        />
      </div>
      <div className='formInput'>
        <label>Url de l'annonce (si disponible)</label>
        <input
          data-cy='adsUrl'
          name='url'
          type='text'
          value={_.get(newInspection, 'vehicle.adsUrl') || ''}
          placeholder="Url de l'annonce"
          onChange={(e) => onInputValueChange('vehicle.adsUrl')(e.target.value)}
        />
      </div>
    </div>
  );
};

EditCreateInspectionCarInfo.propTypes = {
  newInspection: PropTypes.object,
  onInputValueChange: PropTypes.func,
};

export default EditCreateInspectionCarInfo;
