import constants from '../constants';

const createEntity = async ({ name, countryId, viewerConfig, generatedSheet, useGtEstimate, blurPhotos, fileUrl }) => {
  const response = await fetch(constants.URI.v3.entities.index, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      countryId,
      viewerConfig,
      generatedSheet,
      useGtEstimate,
      blurPhotos,
      fileUrl,
    }),
  });

  return { status: response.status, body: await response.json() };
};

const getAllEntities = async () => {
  const response = await fetch(constants.URI.v3.entities.index, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const results = await response.json();
  return results.body;
};

const getEntity = async (entityId) => {
  const response = await fetch(constants.URI.v3.entities.index.concat(`/${entityId}`), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const results = await response.json();
  return results.body;
};

const updateEntity = async (data, entityId) => {
  const response = await fetch(constants.URI.v3.entities.index.concat(`/${entityId}`), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const EntitiesServices = {
  createEntity,
  getAllEntities,
  getEntity,
  updateEntity,
};

export default EntitiesServices;
