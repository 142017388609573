const filtersOptions = [
  { menuSelector: 'actif', value: `Actif` },
  { menuSelector: 'confirme', value: `Confirmé` },
  { menuSelector: 'non_confirme', value: `Non confirmé` },
  { menuSelector: 'nouveaux', value: `Nouvel arrivé` },
  { menuSelector: 'bloques', value: `Bloqués` },
  { menuSelector: 'inactifs', value: `Inactifs` },
  { menuSelector: 'aucun', value: `Aucun` },
];

export default filtersOptions;
