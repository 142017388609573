import PropTypes from 'prop-types';
import React from 'react';
import FileUploader from '../../shared/organisms/FileUploader';

export default function Documents({ inspection, upload, deleteDownloadedFiles }) {
  return (
    <ul className='documents'>
      {inspection?.appointment?.documents?.map((document, index) => (
        <li key={index}>
          <FileUploader
            drawerInspection={true}
            identifier={document.fileName}
            select={(e) => upload(e)}
            deleteDownloadedFiles={(e) => deleteDownloadedFiles(e)}
            uri={document.fileName}
            inspectionId={inspection._id}
            label={document.fileName}
            icon='documents'
            isUploading={false}
          />
        </li>
      ))}
    </ul>
  );
}

Documents.propTypes = {
  inspection: PropTypes.object,
  upload: PropTypes.func,
  deleteDownloadedFiles: PropTypes.func,
};
