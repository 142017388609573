import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../../constants';
import filtersOptions from '../atoms/filtersOptions';

export default function InfosGeneralesViewer(props) {
  const status = (props.specialistKind === 'staff') ? props.stafferStatus : props.status;
  const statusLabel = filtersOptions.find((option) => option.menuSelector === status);
  const { appConfig, appVersions } = props;
  const lastHistory = props.statusHistory && props.statusHistory.length > 0 && props.statusHistory.slice(-1)[0].updatedAt;

  const isOutdatedIOS = appConfig && appVersions && appConfig.platform === 'ios' && appConfig.appVersion !== appVersions.ios;
  const isOutdatedANDROID = appConfig && appVersions && appConfig.platform === 'android' && appConfig.appVersion !== appVersions.android;
  const dateOfBirth = props.dateOfBirth && props.dateOfBirth.value;
  const { godFather } = props;
  return (
    <ul>
      <li>
        <label>Téléphone</label>
        <div data-cy='phone'>{props.phone || '_'}</div>
      </li>
      <li className='full'>
        <label>Email</label>
        <div data-cy='email'>{props.email}</div>
      </li>
      <li className='full'>
        <label>Adresse</label>
        <div data-cy='address'>{props.city || '_'}</div>
      </li>
      <li>
        <label>Date de naissance</label>
        <div data-cy='dateOfBirth'>{dateOfBirth ? moment(dateOfBirth).format(constants.formatDateAppointment) : '_'}</div>
      </li>
      <li>
        <label>Année d'expérience</label>
        <div data-cy='experience'>{props.experienceYear || '0'} ans</div>
      </li>
        {props.status && (
          <>
            <li>
              <label>Statut</label>
              <div>
                <div data-cy='status' className={`statusBadge ${props.status}`}>{props.status}</div>
              </div>
            </li>
            { props.status === 'inactifs' && props?.inactiveReasons?.length > 0
            && <li>
                <label>Raison</label>
                  <div>
                    {props.inactiveReasons.map((reason) =>
                      (<div data-cy='reason' key={reason} className={`statusBadge inactive-reason`}>
                        {constants.inactiveOptions.filter((option) => option.value === reason)[0].label}
                      </div>))
                    }
                    </div>
                </li>
               }
          </>
        )}
      {props.stafferStatus && (
        <>
          <li>
            <label>Statut Staffeur</label>
            <div>
              <div data-cy='status' className={`statusBadge ${props.stafferStatus}`}>{props.stafferStatus}</div>
            </div>
          </li>
          { props.stafferStatus === 'inactifs' && props?.inactiveReasons?.length > 0
            && <li>
              <label>Raison Staffeur</label>
              <div>
                {props.inactiveReasons.map((reason) =>
                  (<div data-cy='reason' key={reason} className={`statusBadge inactive-reason`}>
                    {constants.inactiveOptions.filter((option) => option.value === reason)[0].label}
                  </div>))
                }
              </div>
            </li>
          }
        </>
      )}
        <li>
            <label>Rang</label>
            <div data-cy='rank'> { constants.rankOptions[props?.rank] || 'Membre'}</div>
        </li>
      <li>
        <label>Diplome</label>
        <div data-cy='diploma'>{props.diplomaLevel || '_'}</div>
      </li>
      { godFather && <li>
          <label>Parrain</label>
          <div>
            { godFather }
          </div>
        </li>
      }
      {appConfig && (
        <li className='full'>
          <label>Version du mobile</label>
          <div className='mobileVersion'>
            <div className={`statusBadge ${appConfig.needUpdate ? 'outdated' : ''}`}>v{appConfig.appVersion}</div>
            <div className={`statusBadge`}>
              {appConfig.brand} {appConfig.buidNumber} {appConfig.model}
            </div>
            <div className={`statusBadge`}>{appConfig.platform}</div>
          </div>
        </li>
      )}
      {((isOutdatedIOS && appConfig.platform === 'ios') || (isOutdatedANDROID && appConfig.platform === 'android')) && (
        <li className='full'>
          <div className='caution'>
            <i className='fa fa-exclamation' />
            L'utilisateur doit faire une mise à jour sur son mobile (dernière version: ios: {appVersions.ios} android: {appVersions.android} )
          </div>
        </li>
      )}
      {lastHistory && (
        <li className='full'>
          <label>Date de dernière modification du statut </label>
          <div>
            {statusLabel.value} depuis le {moment(lastHistory).format('LL')}
          </div>
        </li>
      )}
      {props.lastSpecialistInspection && (
        <li className='full'>
          <label>Dernière inspection réalisée le</label>
          <div data-cy='lastInspection'>{moment(props.lastSpecialistInspection).format(constants.formatDateTimeAppointmentAgGrid)}</div>
        </li>
      )}
      {props.profile?.legalStatus && (
        <li className='full'>
          <label>Statut légal </label>
          <div data-cy='legalStatus'>{constants.legalStatus[props.profile?.legalStatus]}</div>
        </li>
      )}
      {props.profile?.availability && (
        <li className='full'>
          <label> Disponibilité </label>
          <div data-cy='availability'>{constants.availabilities[props.profile?.availability]}</div>
        </li>
      )}
    </ul>
  );
}

InfosGeneralesViewer.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  status: PropTypes.string,
  stafferStatus: PropTypes.string,
  specialistKind: PropTypes.string,
  inactiveReasons: PropTypes.array,
  statusHistory: PropTypes.array,
  appConfig: PropTypes.object,
  appVersions: PropTypes.object,
  city: PropTypes.string,
  diplomaLevel: PropTypes.string,
  dateOfBirth: PropTypes.object,
  lastSpecialistInspection: PropTypes.string,
  experienceYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  profile: PropTypes.shape({
    legalStatus: PropTypes.string,
    availability: PropTypes.string,
  }),
  rank: PropTypes.string,
  godFather: PropTypes.string,
};
