import PropTypes from 'prop-types';
import React from 'react';

const getAddress = ({ companyAddressStreet, companyAddressCity, companyAddressPostalCode }) => {
  if (!(companyAddressStreet || companyAddressCity || companyAddressPostalCode)) return null;
  return `${companyAddressStreet || ''}, ${companyAddressPostalCode || 'Code postal à définir'} ${companyAddressCity || 'Ville à définir'}`;
};

export default function InfosFacturationViewer(props) {
  return (
    <ul>
      {props.companyName && (
        <li className='full'>
          <label>Nom entreprise</label>
          <div data-cy='companyName'>{props.companyName || 'Non renseignée'}</div>
        </li>
      )}
      <li className='full'>
        <label>Adresse de facturation</label>
        <div data-cy='billingAddress' >{getAddress(props) || 'Non renseignée'}</div>
      </li>
      <li className='full'>
        <label>SIRET</label>
        <div data-cy='siret' >{props.siret || 'Non renseigné'}</div>
      </li>
      <li className='full'>
        <label>IBAN</label>
        <div data-cy='iban' >{props.iban || 'Non renseigné'}</div>
      </li>
      <li className='full'>
        <label>BIC</label>
        <div data-cy='bic' >{props.bic || 'Non renseigné'}</div>
      </li>
      <li data-cy='vatInvoicing' className='full'>{props.vatInvoicing ? <i className='fas fa-check-square' /> : <i className='far fa-square' />} Facturation TTC </li>
    </ul>
  );
}

InfosFacturationViewer.propTypes = {
  companyName: PropTypes.string,
  companyAddressStreet: PropTypes.string,
  companyAddressStreetComplement: PropTypes.string,
  companyAddressCity: PropTypes.string,
  companyAddressPostalCode: PropTypes.string,
  companyAddressRegion: PropTypes.string,
  siret: PropTypes.string,
  bic: PropTypes.string,
  iban: PropTypes.string,
  vatInvoicing: PropTypes.bool,
};
