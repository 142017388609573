import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import NavigationActions from '../../../actions/NavigationActions';
import DataboxUrl from '../../shared/atoms/Databox';
import Filters from '../../shared/atoms/Filters';
import NavBar from '../../shared/templates/NavBar';

class WebHooks extends React.Component {
  state = {};

  componentDidMount() {
    this.props.dispatch(NavigationActions.setNavigation({ navigation: 'api' }));
  }

  render() {
    return (
      <div>
        <NavBar />
        <div className='appContainer'>
          <Filters title={'Documentation API'} options={[]} />
          <div className='mt20px'>
            <DataboxUrl
              text='Lien de la documentation'
              title='Cliquez-ici'
              type='text'
              color='rgb(31, 82, 135)'
              className=' shadow-1 pointer'
              url='https://documenter.getpostman.com/view/6952334/S1ETQbxb'
              target='_blank'
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ application: state.application, logs: state.logs, concessions: state.concessions });

WebHooks.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  logs: PropTypes.object,
};

export default connect(mapStateToProps)(WebHooks);
