import PropTypes from 'prop-types';
import React from 'react';
import ViewInspectionCarInfos from '../atoms/ViewInspectionCarInfos';
import ViewInspectionEntityConcessionInfos from '../atoms/ViewInspectionEntityConcessionInfos';
import ViewInspectionMainTitle from '../atoms/ViewInspectionMainTitle';
import ViewInspectionUserInfos from '../atoms/ViewInspectionUserInfos';

const ViewInspectionInfos = ({ modalData }) => (
  <form className='mt10px formModal'>
    {modalData && modalData.summary && <ViewInspectionMainTitle modalData={modalData} summary={modalData.summary} />}
    {modalData.vehicle && <ViewInspectionCarInfos data={modalData.vehicle} />}
    {modalData.user && modalData.summary && (
      <ViewInspectionUserInfos
        data={{
          ...modalData.user,
          userRole: modalData.userRole,
          inspectionDuration: modalData.summary.inspectionDuration,
          inspectionType: modalData.inspectionType,
        }}
      />
    )}
    {modalData.concession && modalData.entity && <ViewInspectionEntityConcessionInfos entityData={modalData.entity} concessionData={modalData.concession} />}
  </form>
);

ViewInspectionInfos.propTypes = {
  modalData: PropTypes.object,
};

export default ViewInspectionInfos;
