import PropTypes from 'prop-types';
import React from 'react';

function GooglePlacesDetailsCard(props) {
  if (props.inspectionDrawer?.appointment?.locationMetadata) {
    return (
      <div className='googlePlacesDetailsCard'>
        <div className='inspectionDetailsContent'>
          <div className='body'>
            <h2>{props.inspectionDrawer?.appointment?.locationMetadata?.prioritizedInfos?.name}</h2>
            {props.inspectionDrawer?.appointment?.locationMetadata?.prioritizedInfos?.openTime?.length > 0 && (
              <>
                <div className='smallCaps mb10px'>Horaires</div>
                {props.inspectionDrawer?.appointment?.locationMetadata?.prioritizedInfos?.openTime.map((dailySchedule, i) => (
                  <p className='text' key={i}>
                    {dailySchedule}
                  </p>
                ))}
              </>
            )}
            {props.inspectionDrawer?.appointment?.locationMetadata?.prioritizedInfos?.website && (
              <>
                <div className='smallCaps'>Site Web</div>
                <div className='text'>
                  <a target='_blank' href={props.inspectionDrawer?.appointment?.locationMetadata?.prioritizedInfos?.website} rel='noreferrer'>
                    {props.inspectionDrawer?.appointment?.locationMetadata?.prioritizedInfos?.website}
                  </a>
                </div>
              </>
            )}
            {props.inspectionDrawer?.appointment?.locationMetadata?.prioritizedInfos?.phone && (
              <>
                <div className='smallCaps'>Téléphone</div>
                <div className='text'>{props.inspectionDrawer?.appointment?.locationMetadata?.prioritizedInfos?.phone}</div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
}

GooglePlacesDetailsCard.propTypes = {
  inspectionDrawer: PropTypes.object,
};

export default GooglePlacesDetailsCard;
