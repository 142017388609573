import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Input, Popconfirm, message } from 'antd';
import ReportsServices from '../../../../services/ReportsService';
import constants from '../../../../constants';

message.config({
  top: '5vh',
});

const EditableCell = ({ data, lang }) => {
  const [text, setText] = useState(data[lang]);
  const [isEditing, setEditing] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const confirm = () => {
    const payload = {
      lang,
      translation: text,
    };

    ReportsServices.put(constants.URI.v3.labelTranslations.concat(`/${data._id}`), payload)
      .then(() => {
        message.success('Sauvegardé');
        setEditing(false);
        setConfirmVisible(false);
      })
      .catch((err) => message.error(`Erreur: ${err}`));
  };

  const cancel = () => {
    setText(data[lang]);
    setEditing(false);
    setConfirmVisible(false);
  };

  const handleBlur = (event) => {
    if (event.target.value !== text) {
      setText(event.target.value);
    } else {
      setEditing(false);
    }
  };

  if (isEditing) {
    return (
      <Popconfirm title='Sauvegarder ?' onConfirm={confirm} onCancel={cancel} okText='Oui' cancelText='Non' visible={confirmVisible}>
        <Input
          onPressEnter={(event) => {
            setText(event.target.value);
          }}
          onBlur={handleBlur}
          defaultValue={text}
          onChange={() => {
            setConfirmVisible(true);
          }}
          autoFocus={true}
        />
      </Popconfirm>
    );
  }
  return (
    <span style={{ cursor: 'pointer' }} onClick={() => setEditing(true)}>
      {text && text !== '' ? text : '------'}
    </span>
  );
};

EditableCell.propTypes = {
  data: PropTypes.object,
  lang: PropTypes.string,
};

export default EditableCell;
