import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NavigationActions from '../../../actions/NavigationActions';
import ReportsActions from '../../../actions/ReportsV3Actions';
import Filters from '../../shared/atoms/Filters';
import NavBar from '../../shared/templates/NavBar';
import Card from '../Card';
import { checkpointsList, reportsList, rulesList } from '../data';
import FRE from '../FRE/index';
import Table from '../Table';
import TECHCONF from '../technicalCostByLevel/index';
import ModalAssociateRule from './modals/associateRule';
import ModalBillingConfiguration from './modals/billingConfiguration';
import ModalCreateControlPoint from './modals/createControlPoint';
import ModalCreateReport from './modals/createReport';
import ModalCreateRule from './modals/createRule';

const ReportsV3 = ({ dispatch, application, reportsV3 }) => {
  const [optionReport, setOptionReport] = useState('fre');
  const {
    user: { role, isAdmin },
    optionsFilters: { reportsv3Admin, reportsv3 },
  } = application;
  const isAdminOrSuperChief = isAdmin || role === 'superChief';
  const filterOptions = isAdmin ? reportsv3Admin : reportsv3;

  const changeOption = (option) => {
    setOptionReport(option);
  };

  const renderModals = () => {
    const myProps = {
      dispatch,
      application,
      reportsV3,
    };
    const { modalName } = reportsV3;

    switch (modalName) {
      case 'createRule':
        return <ModalCreateRule {...myProps} />;
      case 'editRule':
        return <ModalCreateRule isEdit {...myProps} />;
      case 'editReportRules':
        return <ModalAssociateRule isEdit {...myProps} />;
      case 'createReport':
        return <ModalCreateReport {...myProps} />;
      case 'editReport':
        return <ModalCreateReport isEdit {...myProps} />;
      case 'createPointText':
        return <ModalCreateControlPoint type='TextCheckpoint' {...myProps} />;
      case 'createPointCheckbox':
        return <ModalCreateControlPoint type='BoolCheckpoint' {...myProps} />;
      case 'createPointList':
        return <ModalCreateControlPoint type='EnumCheckpoint' {...myProps} />;
      case 'createPointPhoto':
        return <ModalCreateControlPoint type='PhotoCheckpoint' {...myProps} />;
      case 'createPointDivider':
        return <ModalCreateControlPoint type='DividerCheckpoint' {...myProps} />;
      case 'createPointVideo':
        return <ModalCreateControlPoint type='VideoCheckpoint' {...myProps} />;
      case 'editCheckpoint':
        return <ModalCreateControlPoint type={reportsV3.modalData.kind} isEdit {...myProps} />;
      case 'billingConfiguration':
        return <ModalBillingConfiguration {...myProps} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(NavigationActions.setNavigation({ navigation: 'reportsv3' }));
    dispatch(ReportsActions.executeAction('constants'));
  }, []);

  return (
    <>
      <NavBar />
      {renderModals()}
      <div className='concession mtp'>
        <Filters selectOptions={changeOption} title='Configuration des rapports' options={filterOptions} activeMenu={optionReport} />
        {optionReport === 'points' && (
          <Card xs={12}>
            <Table
              options={{ filterCategories: true, filterCheckPointType: true, additionnalQueryParams: '&includeReports=true' }}
              list={checkpointsList(isAdmin)}
              index='checkpoints'
            />
          </Card>
        )}
        {optionReport === 'rapports' && (
          <Card xs={12}>
            <Table list={reportsList(isAdminOrSuperChief)} index='reports' />
          </Card>
        )}
        {optionReport === 'regles' && (
          <Card xs={12}>
            <Table options={{ filterRuleType: true }} list={rulesList(isAdmin)} index='rules' />
          </Card>
        )}
        {optionReport === 'technicalCost' && (
          <Card xs={12}>
            <TECHCONF
              options={{
                filterGroups: true,
                filterSites: true,
                filterReports: true,
                hideSearch: true,
              }}
              index='tech'
            />
          </Card>
        )}
        {optionReport === 'fre' && (
          <Card xs={12}>
            <FRE
              options={{
                filterGroups: true,
                filterSites: true,
                filterReports: true,
                hideSearch: true,
              }}
              index='fre'
            />
          </Card>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  application: state.application,
  reportsV3: state.reportsV3,
});

ReportsV3.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  reportsV3: PropTypes.object,
};

export default connect(mapStateToProps)(ReportsV3);
