import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import c from '../../../constants';
import { getSignedUrl } from '../../../helpers/manageUpload';

const FileUploader = ({ uri, icon, label, identifier, select, inspectionId, newInspection = false, deleteDocument, deleteDownloadedFiles, drawerInspection = false, showOnlyFiles = false }) => {
  const open = async (url) => {
    if (!url) {
      return;
    }
    const routeApi = drawerInspection ? c.URI.v3.inspections.signedUrlGetDocument : c.URI.signedUrlGet;
    const documentUrl = drawerInspection ? `${inspectionId}/${url}` : url;
    const signedUrl = await getSignedUrl(routeApi, documentUrl);
    const res = await signedUrl.json();
    window.open(res.url, '_blank');
  };

  return (
    <div className='specialistFichePartUploadContainer'>
      <button onClick={() => open(`${uri}`)} className={uri ? 'fill' : ''}>
        {uri && icon ? <ion-icon name={`${icon}-sharp`} size='large' /> : <ion-icon name={`${icon}-outline`} size='large' />}
        <div className='specialistFichePartUploadLibelle'>{label}</div>
      </button>
      <div className='specialistFichePartUploadMenu'>
        {newInspection ? (
          <span>
            <i onClick={deleteDocument} className='far fa-trash-alt' style={{ fontSize: 18 }} />
          </span>
        ) : (
          !showOnlyFiles && (
            <div>
              <button
                className='buttonLoadDocument'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  select(identifier);
                }}
              >
                {uri ? <span>Modifier</span> : <span>Uploader</span>}
              </button>
              {deleteDownloadedFiles && (
                <span>
                  <i
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      deleteDownloadedFiles(identifier);
                    }}
                    className='far fa-trash-alt'
                    style={{ fontSize: 16 }} />
                </span>
              )
              }
            </div>
          )
        )}
      </div>
    </div>
  );
};

FileUploader.propTypes = {
  identifier: PropTypes.string,
  uri: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  select: PropTypes.func,
  deleteDownloadedFiles: PropTypes.func,
  isUploading: PropTypes.bool,
  newInspection: PropTypes.bool,
  deleteDocument: PropTypes.func,
  drawerInspection: PropTypes.bool,
  inspectionId: PropTypes.string,
  showOnlyFiles: PropTypes.bool,
};

export default connect()(FileUploader);
