import { InputNumber } from 'antd';
import fr from 'date-fns/locale/fr';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getSpecialistSkills } from '../../../actions/specialistsActions';
import MissionsServices from '../../../services/MissionsServices';
import ConcessionSelect from '../../concessions/shared/atoms/ConcessionSelect';
import EntitySelect from '../../concessions/shared/atoms/EntitySelect';
import GooglePlacesAutoComplete from '../../shared/atoms/GooglePlacesAutoComplete';
import RenderError from '../../shared/atoms/RenderError';
import UsersSelect from '../atoms/UsersSelect';
import WeeklyCalendar from '../molecules/weeklyCalendar';
import { orangeLine, blueLine } from '../../../helpers/styles';

Modal.setAppElement('body');

const ModalCreateEditViewMission = (props) => {
  const { modalName } = props.allMissions;

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(props?.allMissions?.modalData?.start ? new Date(props?.allMissions?.modalData?.start) : '');
  const [endDate, setEndDate] = useState(props?.allMissions?.modalData?.end ? new Date(props?.allMissions?.modalData?.end) : '');
  const [excludedDays, setExcludedDays] = useState(props?.allMissions?.modalData?.excludedDays || []);
  const [selectedDate, setSelectedDate] = useState();

  const [selectedEntity, setSelectedEntity] = useState(props?.allMissions?.modalData?.entity);
  const [oldSelectedEntity, setOldSelectedEntity] = useState(props?.allMissions?.modalData?.entity);

  const [selectedConcession, setSelectedConcession] = useState(props?.allMissions?.modalData?.concession);
  const [oldSelectedConcession, setOldSelectedConcession] = useState(props?.allMissions?.modalData?.concession);

  const [selectedUser, setSelectedUser] = useState(props?.allMissions?.modalData?.user);

  const [contactName, setContactName] = useState(props?.allMissions?.modalData?.appointment?.contactName || '');
  const [contactPhone, setContactPhone] = useState(props?.allMissions?.modalData?.appointment?.contactPhone || '');

  const [description, setDescription] = useState(props?.allMissions?.modalData?.description);
  const [missionAddress, setMissionAddress] = useState(props?.allMissions?.modalData?.appointment?.address);

  const [dailyRate, setDailyRate] = useState(props?.allMissions?.modalData?.billing?.dailyRate || 0);
  const [minRate, setMinRate] = useState(props?.allMissions?.modalData?.billing?.indicativeDayAverageSalary?.minRate || 0);
  const [maxRate, setMaxRate] = useState(props?.allMissions?.modalData?.billing?.indicativeDayAverageSalary?.maxRate || 0);
  const [workedDays, setWorkedDays] = useState(props?.allMissions?.modalData?.billing?.workedDays || 0);
  const [adjustment, setAdjustment] = useState(props?.allMissions?.modalData?.billing?.adjustment || 0);
  const [adjustmentComment, setAdjustmentComment] = useState(props?.allMissions?.modalData?.billing?.adjustmentComment);

  const [workingWeek, setWorkingWeek] = useState({});

  const [error, setError] = useState('');
  const [ccMails, setCCmails] = useState(props?.allMissions?.modalData?.appointment?.cc);

  const [skillsIds, setSkillsIds] = useState(props?.allMissions?.modalData?.requiredSkills?.map((selectedSkill) => selectedSkill._id) || []);

  //  set entity and concession and specialistSkills on initialization for comparing purpose
  useEffect(() => {
    setOldSelectedEntity(props?.allMissions?.modalData?.entity);
    setOldSelectedConcession(props?.allMissions?.modalData?.concession);
    if (!props.editModal && !props.application?.user?.isAdmin) {
      setSelectedEntity({ _id: props?.user?.concession?.entityId, name: props?.user?.concession?.entityName });
    }
    (async () => {
      await getSpecialistSkills(props.dispatch);
    })();
  }, []);

  // We empty/reset concession when selectedEntity is changed to load new concessions connected to current Entity
  useEffect(() => {
    if (oldSelectedEntity !== selectedEntity) {
      setSelectedConcession('');
    }
  }, [selectedEntity]);

  // Similar to entities/concessions, we empty users when the concession or entity change to get the connected users
  useEffect(() => {
    if (oldSelectedConcession !== selectedConcession) {
      setSelectedUser('');
    }
  }, [selectedConcession]);

  // block adding the same day in the array of excluded days
  const addNewDay = () => {
    if (excludedDays.indexOf(moment(selectedDate).format('DD/MM/YYYY')) === -1) {
      setExcludedDays([...excludedDays, moment(selectedDate).format('DD/MM/YYYY')]);
    }
  };

  // readonly purpose - math and real total saved in backend
  const missionTotal = dailyRate * workedDays + Number(adjustment) || 0;

  async function submitMission(e, changeToOrderedStatusAndSendNotification = false, createMissionAndSetStatusInPending = false) {
    e.preventDefault();

    let errorMessage = '';

    if (!selectedEntity) {
      errorMessage += 'Il manque des informations sur le groupe. <br/>';
    }
    if (!selectedConcession) {
      errorMessage += 'Il manque des informations sur le site. <br/>';
    }
    if (!selectedUser && !props.application?.user?.role) {
      errorMessage += 'Il manque des informations sur le commandé par. <br/>';
    }
    if (!contactName || !contactPhone) {
      errorMessage += 'Il manque des informations sur la mission. <br/>';
    }
    if (!missionAddress) {
      errorMessage += "Il manque l'adresse de la mission. <br/>";
    }
    if (!startDate || !endDate) {
      errorMessage += 'Il manque des informations sur les horaires. <br/>';
    }

    if (!description) {
      errorMessage += 'Il manque des informations complémentaires - Description. <br/>';
    }

    if (errorMessage !== '') {
      setError(errorMessage);
    } else if (modalName === 'createMission') {
      setLoading(true);
      try {
        const response = await MissionsServices.createMission({
          start: startDate,
          end: endDate,
          workingWeek,
          requiredSkills: skillsIds,
          excludedDays,
          description,
          billing: {
            dailyRate,
            workedDays,
            adjustment,
            adjustmentComment,
            indicativeDayAverageSalary: {
              minRate,
              maxRate,
            },
          },
          appointment: {
            address: missionAddress,
            contactPhone,
            contactName,
            cc: ccMails,
          },
          concession: selectedConcession._id,
          user: selectedUser._id || props.application?.user?._id,
          createdBy: props.application.user._id,
          isConcess: ['superChief', 'chief', 'reseller'].includes(props.application.user.role),
          createMissionAndSetStatusInPending,
        });
        if (response && response.status !== 200) {
          const { body: { error: errorServer } } = response;
          throw new Error(errorServer || 'Une Erreur est survenue pendant la sauvegarde de la mission');
        }
        setLoading(false);
        props.closeModal(true);
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    } else if (modalName === 'editMission') {
      setLoading(true);
      try {
        const dataToSendToServer = {
            start: startDate,
            end: endDate,
            workingWeek,
            requiredSkills: skillsIds,
            excludedDays,
            description,
            billing: {
              dailyRate,
              workedDays,
              adjustment,
              adjustmentComment,
              indicativeDayAverageSalary: {
                minRate,
                maxRate,
              },
            },
            appointment: {
              address: missionAddress,
              contactPhone,
              contactName,
              cc: ccMails,
            },
            concession: selectedConcession._id,
            user: selectedUser._id || props.application?.user?._id,
            changeToOrderedStatusAndSendNotification,
          };
        const response = (changeToOrderedStatusAndSendNotification) ? (
          await MissionsServices.changeToOrderedStatusAndSendNotification(
            dataToSendToServer,
            props.allMissions.modalData._id,
          )
        ) : (
          await MissionsServices.updateMission(
            dataToSendToServer,
            props.allMissions.modalData._id,
          )
        );
        if (response && response.status !== 200) {
          const { body: { error: errorServer } } = response;
          throw new Error(errorServer || 'Une erreur est survenue pendant la mise à jour de la mission');
        }
        setLoading(false);
        props.closeModal(true);
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    }
  }
  let maintitle;
  switch (modalName) {
    case 'createMission':
      maintitle = 'Création de mission';
      break;
    case 'editMission':
      maintitle = 'Édition de mission';
      break;
    default:
      break;
  }

  const defaultWeeklyScheduleArray = [];
  for (let i = 0; i < 7; i += 1) {
    defaultWeeklyScheduleArray.push({ start: 1, end: 2 });
  }
  const defaultWeeklySchedule = { ...defaultWeeklyScheduleArray };
  const customSelectStyles = {
    control: (base) => ({
      ...base,
      width: '33%',
      minWidth: '200px',
      textAlign: 'left',
      alignItems: 'center',
      fontSize: '0.8rem',
    }),
  };

  const customStyles = useMemo(() => ({
    valueContainer: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'center',
      alignItems: 'center',
      fontSize: '12px',
    }),

    control: (base) => ({
      ...base,
      fontSize: '0.8rem',
      marginTop: '10px',
    }),
  }), []);

  const staffSkillsOptions = props?.specialistSkills?.filter((skill) => skill.kind === 'staff').sort();
  const disabledInputConcessionView = !!(!props.application?.user?.isAdmin && props?.allMissions?.modalData && props?.allMissions?.modalData.start);

  return (
    <Modal onRequestClose={props.closeModal} shouldCloseOnOverlayClick={false} isOpen={true} contentLabel='Modal create mission' overlayClassName='modal-overlay' className='mission-modal'>
      <form className='mt10px'>
        <div>
          <div className='mission-title'>{maintitle}</div>
          {orangeLine()}

          <div className='mission-inputs-container'>
            <div className='mission-subtitle'>Informations sur le donneur d'ordre</div>
            <div className='mission-select'>
              <div>
                <label className='mission-label'>Groupe *</label>
                {props.application?.user?.isAdmin ? (
                  <EntitySelect
                    styles={customSelectStyles}
                    selectedEntity={selectedEntity}
                    data-cy='GroupName'
                    className='mission-select'
                    onSelect={(selection) => setSelectedEntity(selection)}
                    disabledInputConcessionView={disabledInputConcessionView}
                  />
                ) : (
                  <Select
                    defaultValue={{ value: props?.user?.concession?.entityId, label: props?.user?.concession?.entityName }}
                    data-cy='GroupName'
                    styles={customStyles}
                    isDisabled={true}
                  />
                )}
              </div>
              {selectedEntity?._id && (
                <div>
                  <label className='mission-label'>Site *</label>
                  <ConcessionSelect
                    styles={customSelectStyles}
                    data-cy='SiteName'
                    selectedSites={selectedConcession}
                    entityId={selectedEntity?._id}
                    onSelect={(selection) => setSelectedConcession(selection)}
                    disabledInputConcessionView={disabledInputConcessionView}
                  />
                </div>
              )}
              {(selectedConcession?._id && selectedEntity?._id && !['reseller', 'chief', 'superChief'].includes(props.application?.user?.role)) && (
                <div>
                  <label className='mission-label'>Commandé par *</label>
                  <UsersSelect
                    styles={customSelectStyles}
                    data-cy='User'
                    selection={selectedUser}
                    entityId={selectedEntity?._id}
                    concessionId={selectedConcession?._id}
                    onSelect={(selection) => setSelectedUser(selection)}
                    disabledInputConcessionView={disabledInputConcessionView}
                  />
                </div>
              )}
            </div>
          </div>
          <div>{blueLine()}</div>

          <div className='mission-inputs-container'>
            <div className='mission-skills-select'>
              <div>
                <label className='mission-label'>Compétence(s) requise(s) *</label>
                <Select
                  placeholder='Choisir les compétences requises...'
                  options={staffSkillsOptions?.map((skill) => ({ value: skill._id, label: skill.label }))}
                  defaultValue={props?.allMissions?.modalData?.requiredSkills?.map((skill) => ({ value: skill._id, label: skill.label }))}
                  data-cy='requiredSkills'
                  styles={customStyles}
                  isMulti
                  isDisabled={disabledInputConcessionView}
                  onChange={(selectedSkills) => {
                    setSkillsIds(selectedSkills.map((selectedSkill) => selectedSkill?.value || selectedSkill._id));
                  }}
                />
              </div>
            </div>
          </div>
          {orangeLine()}
          <div className='mission-inputs-container'>
            <div className='mission-subtitle'>Informations sur la mission</div>
            <div className='mission-input-subcontainer'>
              <label className='mission-label'>Contact sur place *</label>
              <input
                data-cy='Contact'
                className='mission-input'
                type='text'
                name='contactName'
                defaultValue={contactName}
                onChange={(e) => setContactName(e.target.value)}
                placeholder='Nom du contact référent'
              />
            </div>
            <div className='mission-input-subcontainer'>
              <label className='mission-label'>Numéro de téléphone *</label>
              <input
                data-cy='Phone'
                className='mission-input'
                type='text'
                name='contactPhone'
                defaultValue={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                placeholder='Numéro du référent'
              />
            </div>
            <GooglePlacesAutoComplete
              headerClassName='mission-input-subcontainer  mission-text-area-subcontainer'
              className='mission-input-mail'
              label='Adresse *'
              labelClassName='mission-label'
              getAddressDetails={(fullAddressName) => setMissionAddress(fullAddressName)}
              placeholder='Rechercher par business ou par adresse'
              value={missionAddress}
            />
            <div className='mission-input-subcontainer mission-text-area-subcontainer'>
              <label className='mission-label'>Mail(s) en copie</label>
              <input
                pattern='(([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*'
                className='mission-input-mail'
                placeholder='Exemple : msa@monspecialisteauto.com;msa2@monspecialisteauto.com;...'
                defaultValue={ccMails}
                onChange={(e) => setCCmails(e.target.value)}
              />
            </div>

            <div className='mission-input-subcontainer mission-text-area-subcontainer'>
              <label className='mission-label'>Informations complémentaires - Description *</label>
              <textarea className='mission-input mission-text-area' defaultValue={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
          </div>

          {orangeLine()}
          <div className='mission-inputs-container'>
            <div className='mission-subtitle'>Informations sur les horaires</div>

            <div className='mission-date-subcontainer'>
              <div className='mission-date-block'>
                <label className='mission-label date-labels'>Date de début *</label>
                <DatePicker
                  className='mission-input mission-date'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date(moment(endDate))}
                  locale={fr}
                  dateFormat='dd/MM/yyyy'
                />
              </div>
              <div className='mission-date-block'>
                <label className='mission-label date-labels'>Date de fin *</label>
                <DatePicker
                  className='mission-input mission-date'
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  locale={fr}
                  dateFormat='dd/MM/yyyy'
                />
              </div>
            </div>
            <div>
              {blueLine()}
              <WeeklyCalendar
                modalName={modalName}
                workingWeek={props?.allMissions?.modalData?.workingWeek || defaultWeeklySchedule}
                schedule={(schedule) => {
                  setWorkingWeek(schedule);
                }}
              />
            </div>
            <div className='mission-date-block'>
              {blueLine()}
              <label className='date-labels'>Jours exclus (hors jours fériés)</label>
              <div className='excluded-days'>
                <DatePicker
                  className='mission-input mission-date'
                  onChange={(date) => {
                    setSelectedDate(date);
                  }}
                  locale={fr}
                  minDate={startDate}
                  maxDate={endDate}
                  dateFormat='dd/MM/yyyy'
                  selected={selectedDate}
                  textAlign
                />
                <i className='fas fa-plus-circle' style={{ paddingLeft: '10px', fontSize: '20px', color: 'green' }} onClick={() => addNewDay()} />
              </div>
              {excludedDays.length > 0
                // we map over the array to display the dates added to excluded days
                && excludedDays.map((day, i) => (
                  <div className='excluded-days date-labels' key={i}>
                    <span className='excluded-days excluded-span' key={i}>
                      {day}
                    </span>
                    <i
                      style={{ paddingLeft: '10px', fontSize: '20px', color: '#bd0000' }}
                      className='fas fa-minus-circle'
                      onClick={() => {
                        setExcludedDays(excludedDays.filter((item) => item !== day));
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
          { props.application?.user?.isAdmin && (
            <>
              <div>{orangeLine()}</div>
              <div className='mission-inputs-container'>
                <div className='mission-subtitle'>Informations de facturation</div>

                <div className='mission-price-range-container'>
                  <label className='mission-label'>Fourchette de prix indicative</label>
                  <div className='mission-price-range-subcontainer'>
                    <label className='mission-label'>Min</label>
                    <InputNumber
                      data-cy='minPrice'
                      className='mission-price-range'
                      name='minRate'
                      formatter={(value) => `${value} €`}
                      value={minRate}
                      onChange={(value) => setMinRate(value)}
                    />
                    <label className='mission-label'>Max</label>
                    <InputNumber
                      data-cy='maxPrice'
                      className='mission-price-range'
                      name='maxRate'
                      formatter={(value) => `${value} €`}
                      value={maxRate}
                      onChange={(value) => setMaxRate(value)}
                    />
                  </div>
                </div>
              </div>
              {blueLine()}
              <div className='mission-inputs-container'>
                <div className='mission-input-subcontainer mission-billing'>
                  <label className='mission-label'>Prix par jour</label>
                  <InputNumber
                    data-cy='pricePerDay'
                    className='mission-input-number'
                    name='dailyRate'
                    value={dailyRate}
                    formatter={(value) => `${value} €`}
                    onChange={(value) => setDailyRate(value)}
                  />
                </div>
                <div className='mission-input-subcontainer mission-billing'>
                  <label className='mission-label'>Nombre de jours travaillés</label>
                  <InputNumber
                    data-cy='daysTotal'
                    className='mission-input-number'
                    name='workedDays'
                    value={workedDays}
                    onChange={(value) => setWorkedDays(value)}
                  />
                </div>
                <div className='mission-input-subcontainer mission-billing'>
                  <label className='mission-label' >
                    Total (HT)
                  </label>
                  <input className='mission-input' value={`${missionTotal || 0} €`} readOnly />
                </div>
              </div>
              {blueLine()}
              <div className='mission-inputs-container'>
            <div className='mission-input-subcontainer mission-billing'>
              <label className='mission-label'>Ajustement(s) </label>
              <InputNumber
                data-cy='adjustment'
                className='mission-input-number'
                name='adjustment'
                value={adjustment}
                formatter={(value) => `${value} €`}
                onChange={(value) => setAdjustment(value)}
              />
            </div>
            <div className='mission-input-subcontainer'>
              <label className='mission-label'>Commentaires d'ajustement(s) </label>
              <textarea data-cy='adjustmentComment' className='mission-input mission-text-area' defaultValue={adjustmentComment} onChange={(e) => setAdjustmentComment(e.target.value)} />
            </div>
          </div>
            </>
          )}
          {orangeLine()}
        </div>

        {error && <RenderError error={error} />}
        {!loading ? ((
          <div className='center formButton  mt20px'>
            <button type='button' onClick={() => props.closeModal()} className='negativ'>Annuler</button>
            <button type='button' onClick={(e) => submitMission(e, false, true)} className='positiv'>Sauvegarder</button>
            { (props.user.isAdmin && (props?.allMissions?.modalData?.status === 'pending_validation' || modalName === 'createMission')) && (
              <button type='button' onClick={(e) => submitMission(e, true)} className='orange'>Passer en attente</button>
            )}
          </div>
        )) : (
          <div className='center formButton mt20px'>
            {loading
              ? <button disabled type='button' className='positiv'>
                <span>
                  <i className='fa fa-spinner fa-pulse'/>
                  {modalName === 'createMission' && 'Création en cours'}
                  {modalName === 'editMission' && 'Modification en cours'}
                </span>
              </button>
              : <button onClick={props.closeModal} type='button' className='negativ'>
                Annuler
              </button>
            }
          </div>
        )}
      </form>
    </Modal>
  );
};

ModalCreateEditViewMission.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  allMissions: PropTypes.object,
  updatedAt: PropTypes.number,
  workingWeek: PropTypes.object,
  closeModal: PropTypes.func,
  specialistSkills: PropTypes.array,
  user: PropTypes.object,
  editModal: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.application.user,
  specialistSkills: state.specialists.specialistSkills,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getSpecialistSkills,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateEditViewMission);

