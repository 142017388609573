import moment from 'moment';

const inspectionsCounterByStatus = (inspecs, status) => {
  if (status === 'notReceived') {
    return inspecs.filter((inspec) => inspec.status === 'scheduled' && moment(inspec.appointment.date) < moment() && !inspec.generatedAt).length;
  }
  return inspecs.filter((inspec) => inspec.status === status).length;
};

const filtersOptions = (props) => [
  { menuSelector: 'ordered', value: `En attente (${inspectionsCounterByStatus(props.inspections, 'ordered')}) ` },
  { menuSelector: 'scheduled', value: `Programmées (${inspectionsCounterByStatus(props.inspections, 'scheduled')}) ` },
  { menuSelector: 'waitForAdminValidation', value: `Validation Admin (${inspectionsCounterByStatus(props.inspections, 'waitForAdminValidation')}) ` },
  { menuSelector: 'done', value: `Réalisées (${inspectionsCounterByStatus(props.inspections, 'done')}) ` },
  { menuSelector: 'canceled', value: `Annulées (${inspectionsCounterByStatus(props.inspections, 'canceled')}) ` },
  { menuSelector: 'no_show', value: `Non présentées (${inspectionsCounterByStatus(props.inspections, 'no_show')}) ` },
  { menuSelector: 'notReceived', value: `Non reçues (${inspectionsCounterByStatus(props.inspections, 'notReceived')}) ` },
  { menuSelector: 'none', value: `Toutes (${props.inspections.length})` },
];

export default filtersOptions;
