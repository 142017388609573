/* eslint-disable no-unused-vars, class-methods-use-this */

import PropTypes from 'prop-types';
import React from 'react';
import Text from '../../../shared/atoms/Text';

class ToggleTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handler = (data) => {
    const { openModal } = this.props;

    if (data.handler && typeof data.handler === 'function') {
      data.handler(openModal);
    }
  };

  refresh = () => {
    const { searchAction } = this.props;

    searchAction({});
  };

  render() {
    const { menu = [] } = this.props;

    return (
      <div className='table-toggle'>
        <span className='refresh'>
          <i className='fa fa-sync-alt' onClick={this.refresh} />
        </span>
        {/* <span className="search">
          <i className="fa fa-search" />
        </span> */}
        <span className='menu'>
          {menu.length > 0 && <i className='fa fa-ellipsis-h' />}
          <ul className='toggle-menu left'>
            {menu.map((data, key) => (
              <li key={key} onClick={() => this.handler(data)}>
                <i data-cy={`menu${key}`} className={data.icon}></i>{' '}
                <Text fontStyle='normal' size={14}>
                  {data.displayName}
                </Text>
              </li>
            ))}
          </ul>
        </span>
      </div>
    );
  }
}

ToggleTable.propTypes = {
  menu: PropTypes.array,
  searchAction: PropTypes.func,
  openModal: PropTypes.func,
};

export default ToggleTable;
