import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import Select from 'react-select';
import ConcessionsActions from '../../../../actions/Concessions';
import constants from '../../../../constants';
import ConcessionsServices from '../../../../services/ConcessionsServices';
import ReportServices from '../../../../services/ReportsService';
import Text from '../../../shared/atoms/Text';
import ConcessionSelect from '../../shared/atoms/ConcessionSelect';

Modal.setAppElement('body');

class ModalEditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  closeModal = () => {
    const { dispatch, application } = this.props;

    dispatch(ConcessionsActions.getActionToDispatch({ type: 'HIDE_MODAL' }));
    ConcessionsActions.reloadTable({ index: 'users' })(dispatch, application);
  };

  async componentDidMount() {
    const { modalData } = this.props.concessions;

    if (!modalData) {
      return false;
    }
    const savedConcessions = [];
    if (modalData.role === 'chief') {
      modalData.concession.concessionIds.forEach((concession) => {
        savedConcessions.push({
          label: modalData.concession.names[modalData.concession.concessionIds.indexOf(concession)],
          value: concession,
        });
      });
    }

    this.setState({
      userId: modalData._id,
      firstname: modalData.firstname,
      lastname: modalData.lastname,
      concessionId: modalData.concession.concessionId,
      concessionIds: modalData.concession.concessionIds,
      notifyConcession: modalData.concession.notifyConcession,
      canCreateMsaInspection: modalData.concession.canCreateMsaInspection,
      names: modalData.names,
      phone: modalData.phone,
      email: modalData.email,
      status: modalData.status,
      concessionSelected: modalData.concession,
      concessionsSelected: savedConcessions,
      role: modalData.role,
    });

    // Get User from API
    const findResponse = await ConcessionsServices.getAccount({ userId: modalData._id }).catch((err) => ({ err }));
    if (findResponse && findResponse.status < 400) {
      this.setState({ user: findResponse.user });
    }
    // Get all mobile environments
    const mobileEnv = await ReportServices.getAll(`${constants.URI.mobileEnvironments}`);

    if (mobileEnv) {
      const mobileEnvOptions = _.map(mobileEnv, (env) => ({
        label: env.env,
        value: {
          ios: _.get(_.find(env.keys, ['target', 'ios']), 'key'),
          android: _.get(_.find(env.keys, ['target', 'android']), 'key'),
        },
      }));
      this.setState({ mobileEnvOptions });
      // if user already has a mobile environment set it to state
      let mobileTargetEnvironment = findResponse?.user?.mobileTargetEnvironment;
      if (mobileTargetEnvironment) {
        mobileTargetEnvironment = _.find(mobileEnvOptions, ['value.ios', mobileTargetEnvironment.ios]);
        this.setState({ mobileTargetEnvironment });
      }
    }

    const status = findResponse?.user?.status;

    if (status === 'active') {
      this.setState({ status: { value: true, label: 'Activé' } });
    } else {
      this.setState({ status: { value: false, label: 'Désactivé' } });
    }

    return true;
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  onChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  onSelect = (selection, obj) => {
    const { name } = obj;
    this.setState({ [name]: selection });
  };

  handleRoleChange = (roleSelected) => {
    this.setState({ role: roleSelected.value });
  };

  renderValidButtonText = () => {
    const { isLoading } = this.props.concessions;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }
    return 'Sauvegarder';
  };

  submit = async (e) => {
    e.preventDefault();

    const {
      firstname, lastname, concessionId, concessionIds, concessionSelected, concessionsSelected, user, email, password, phone, mobileTargetEnvironment, status, role,
    } = this.state;

    let filterConcessionByEditedRole = concessionSelected?._id || concessionId;
    let filterConcessionsByEditedRole = _.map(concessionsSelected, (s) => s.value) || concessionIds;

    if (role.value === 'reseller') {
      filterConcessionsByEditedRole = undefined;
    }
    if (role.value === 'chief') {
      filterConcessionByEditedRole = undefined;
    }

    if (role.value === 'superChief') {
      filterConcessionByEditedRole = undefined;
      filterConcessionsByEditedRole = undefined;
    }

    const editedResponse = await ConcessionsServices.editUser({
      userId: user._id,
      mobileTargetEnvironment: mobileTargetEnvironment?.value || {},
      concessionId: filterConcessionByEditedRole,
      concessionIds: filterConcessionsByEditedRole,
      names: concessionsSelected && _.map(concessionsSelected, (s) => s.label),
      phone,
      firstname,
      lastname,
      email,
      password,
      status: status.value ? 'active' : 'inactive',
      role,
    }).catch((err) => this.setState({ error: err.error || 'Une erreur est survenue' }));

    if (editedResponse && editedResponse.status === 200) {
      this.closeModal();
    }
  };

  onChangeUser = (name, user) => {
    this.setState({ [name]: user });
  };

  onClearInput = (name) => {
    this.setState({ [name]: null });
  };

  convertConcessions = (concession) => {
    const init = [];

    if (concession.names && concession.concessionIds) {
      concession.concessionIds.map((id, key) => init.push({ value: String(id), label: concession.names[key] }));
    }
    return init;
  };

  renderConcession = (user) => {
    const { role } = this.state;
    if (role === 'reseller') {
      return (
        <React.Fragment>
           <Col xs={12} md={6}>
            <div className='formInput'>
                <label>Sites</label>
            </div>
            <div className='formInput withSearch'>
              <ConcessionSelect
                selectedSites={this.state.concessionSelected}
                isClearable={false}
                entityId={user?.concession?.entityId}
                onSelect={(c) => this.onChangeUser('concessionSelected', c)}
              />
            </div>
          </Col>
        </React.Fragment>
      );
    }

    if (role === 'chief') {
      return (
        <React.Fragment>
          <Col xs={12} md={6}>
            <div className='formInput'>
                <label>Sites</label>
            </div>
            <div className='formInput withSearch'>
              <ConcessionSelect
                isMulti
                entityId={user?.concession?.entityId}
                selectedSites={this.state.concessionsSelected}
                onSelectMultiple={(c) => this.onChangeUser('concessionsSelected', c)}
              />
            </div>
          </Col>
        </React.Fragment>
      );
    }
    return null;
  };

  render() {
    const { user = {} } = this.state;
    const { concessions } = this.props;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Modal annuler inspection'
        overlayClassName='modal-overlay'
        className='modal modalConcession'
      >
        <div className='modalContent '>
          <div className='mainTitle'>
            Édition de {user.firstname} {user.lastname}
          </div>
          {user ? (
            <form className='mt10px' onSubmit={this.submit}>
              <Row>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Prénom</label>
                    {<input type='text' data-cy='firstName' name='firstname' placeholder='ex. Jean' value={this.state.firstname} onChange={this.onChange} />}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Nom</label>
                    {<input type='text' name='lastname' placeholder='ex. Doe' value={this.state.lastname} onChange={this.onChange} />}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>E-mail</label>
                    {user.email && <input type='email' name='email' placeholder='Email' value={this.state.email} onChange={this.onChange} />}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Numéro de téléphone</label>
                    <input type='text' name='phone' placeholder='Ex. 0123123123' value={this.state.phone} onChange={this.onChange} />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Type de compte</label>
                    <Select
                      placeholder={'Choisissez un type de compte'}
                      className='multi-select'
                      value={{ label: constants.roles[this.state.role], value: this.state.role }}
                      onChange={this.handleRoleChange}
                      options={Object.entries(constants.roles).map((role) => ({ value: role[0], label: role[1] }))}
                      name='role'
                    />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='formInput'>
                    <label>Identifiant interne</label>
                    <Text fontStyle='normal' size={12}>
                      {user.readableId}
                    </Text>
                  </div>
                </Col>
                {user.appConfig && (
                  <Col xs={12} md={6}>
                    <div className='formInput'>
                      <label>Version du mobile</label>
                      <Text fontStyle='normal' size={12}>
                        [{user.appConfig.platform}] - v{user.appConfig.appVersion} - {user.appConfig.brand} {user.appConfig.buidNumber} {user.appConfig.model}
                      </Text>
                    </div>
                  </Col>
                )}
                {this.renderConcession(user)}
                {_.get(this.props.application, 'user.isAdmin') && (
                  <React.Fragment>
                    <Col xs={12} md={6} style={{ marginTop: '1px', borderTopColor: '#EEFFFF' }}>
                      <div className='formInput'>
                        <label>User Mobile Environment</label>
                        <Select
                          placeholder={'Choisissez un Environment'}
                          className='multi-select'
                          value={this.state.mobileTargetEnvironment}
                          onChange={this.onSelect}
                          options={this.state.mobileEnvOptions}
                          name='mobileTargetEnvironment'
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className='formInput'>
                        <label>Désactiver l'utilisateur</label>
                        <Select
                          placeholder={"Activer / désactiver l'utilisateur"}
                          className='multi-select'
                          value={this.state.status}
                          onChange={this.onSelect}
                          options={[
                            { value: true, label: 'Activé' },
                            { value: false, label: 'Désactivé' },
                          ]}
                          name={'status'}
                        />
                      </div>
                    </Col>
                  </React.Fragment>
                )}
                {this.renderError()}
                <Col xs={12} md={12}>
                  <div className='center formButton'>
                    <button type='button' onClick={this.closeModal} className='negativ'>
                      Fermer
                    </button>
                    <button type='submit' className='positiv' disabled={concessions.isLoading}>
                      {this.renderValidButtonText()}
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          ) : (
            <Text fontStyle='normal' size={16}>
              Chargement
            </Text>
          )}
        </div>
      </Modal>
    );
  }
}

ModalEditUser.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
};

export default ModalEditUser;
