import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

const CguCheckbox = ({ cguAccepted, setCguAccepted }) => (
  <div className='formInput center mt20px'>
    <input data-cy='CGUcheckbox' type='checkbox' defaultChecked={cguAccepted} onChange={() => setCguAccepted(!cguAccepted)} />
    En cochant cette case, j'accepte les{' '}
    <a href='https://www.monspecialisteauto.com/cgu' rel='noopener noreferrer' target='_blank'>
      conditions générales
    </a>{' '}
    de vente de Mon Spécialiste Auto.
  </div>
);

CguCheckbox.propTypes = {
  cguAccepted: PropTypes.bool,
  setCguAccepted: PropTypes.func,
};

export default CguCheckbox;
