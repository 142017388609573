import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const DefectTypologiesSelect = ({ typologies, onSelect, isDisabled, defaultValue }) => {
  const options = typologies.map((typo) => ({ value: typo.typologyId || '', label: typo.label || '' }));
  const defaultOption = options.find((option) => option.value === defaultValue);

  return (
    <div className='search-input max'>
      <Select
        size={'large'}
        isClearable={true}
        onChange={(e) => onSelect(e.value)}
        options={options}
        isSearchable
        isDisabled={isDisabled}
        placeholder={defaultOption ? defaultOption.label : 'Rechercher une typologie...'}
        className='cypress__select-typology'
      />
    </div>
  );
};

DefectTypologiesSelect.propTypes = {
  onSelect: PropTypes.func,
  isDisabled: PropTypes.bool,
  typologies: PropTypes.array,
  defaultValue: PropTypes.string,
};

export default DefectTypologiesSelect;
