import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Popconfirm } from 'antd';

const InspectionMenu = (props) => {
  const { value } = props;
  return (
    <span>
      {_.get(props, 'value.summary.viewerUrl') ? (
        <a
          style={{ paddingRight: 6, color: 'rgb(31, 82, 136)' }}
          href={`${_.get(props, 'value.summary.viewerUrl')}?lang=fr`}
          title='Voir le rapport' target='_blank'
          rel='noreferrer noopener'
        >
          <i className='fas fa-external-link-alt' />
        </a>
      ) : null}
      {_.get(props, 'value.summary.noCostViewerUrl') && (
        <a
          style={{ paddingRight: 6, color: 'rgb(31, 82, 136)' }}
          href={`${_.get(props, 'value.summary.noCostViewerUrl')}?lang=fr`}
          title='Voir le rapport sans frais'
          target='_blank'
          rel='noreferrer noopener'
        >
          <i className='fab fa-creative-commons-nc-eu' />
        </a>
      )}
      {value.comments && value.comments.length > 0 && (
        <span className='mr5px'>
          <i className='fa fa-comments' />
        </span>
      )}
      {_.get(props, 'value.status') === 'waitForAdminValidation' && (
        <Popconfirm
          placement='topRight'
          title={`Souhaitez-vous valider l'inspection ?`}
          okText='Oui'
          cancelText='Non'
          onConfirm={props.onClickValidatePanel(value)}
        >
          <i>
            <i className='fas fa-check' data-cy='validateInspection' style={{ paddingRight: 8, cursor: 'pointer' }} />
          </i>
        </Popconfirm>)}
      <i onClick={props.onClickDetailsPanel(value)}>
        <i className='fas fa-eye' data-cy='viewInspection' style={{ paddingRight: 8, cursor: 'pointer' }} />
      </i>
      <i onClick={props.onClickDuplicatePanel(value)}>
        <i className='fas fa-clone' data-cy='duplicateInspection' style={{ paddingRight: 8, cursor: 'pointer' }} />
      </i>
      <span className='element menu'>
        <i className='fas fa-ellipsis-h' style={{ marginRight: 8 }} />
        <div className='btnContainer'>
          <div className='btnContainerItem bg-white'>
            <button onClick={props.onClickEdit(value)}>
              <i className='far fa-edit' data-cy='inspectionActionsEditButton' /> Modifier
            </button>
          </div>
          {_.get(props, 'value.status') === 'done' || _.get(props, 'value.status') === 'waitForAdminValidation' ? (
            <div className='btnContainerItem bg-white'>
              <button onClick={props.onClickEditComments(value)}>
                <i className='fas fa-pen-nib' /> Editer rapport
              </button>
            </div>
          ) : null}
          <div className='btnContainerItem bg-white'>
            <button onClick={props.onClickSms(value)}>
              <i className='fas fa-sticky-note' /> SMS au vendeur
            </button>
          </div>
          {value.status === 'scheduled' && (
            <div className='btnContainerItem bg-white'>
              <button onClick={props.onClickUnassign(value)}>
                <i className='fas fa-user-slash' /> Remettre en attente
              </button>
            </div>
          )}
          {value.status === 'scheduled' && (
            <div className='btnContainerItem bg-white'>
              <button onClick={props.onClickNoShow(value)}>
                <i className='fas fa-user-slash' /> Non présentée
              </button>
            </div>
          )}
          {value.status === 'done' && (
            <div className='btnContainerItem bg-white'>
              <button onClick={props.onClickResend(value)}>
                <i className='fas fa-envelope' /> Renvoyer le rapport
              </button>
            </div>
          )}
          {['ordered', 'scheduled', 'pending_validation'].includes(value.status) && (
            <div className='btnContainerItem bg-white'>
              <button onClick={props.onClickCancel(value)}>
                <i className='fas fa-ban' /> Annuler
              </button>
            </div>
          )}
          <div className='btnContainerItem bg-white'>
            <button onClick={props.onClickDetailsPanel(value)}>
              <i className='fas fa-eye' /> Voir la fiche
            </button>
          </div>
        </div>
      </span>
    </span>
  );
};

InspectionMenu.propTypes = {
  onClickEdit: PropTypes.func,
  onClickCancel: PropTypes.func,
  onClickResend: PropTypes.func,
  onClickSms: PropTypes.func,
  onClickDetailsPanel: PropTypes.func,
  onClickDuplicatePanel: PropTypes.func,
  onClickValidatePanel: PropTypes.func,
  onClickUnassign: PropTypes.func,
  onClickNoShow: PropTypes.func,
  onClickEditComments: PropTypes.func,
  value: PropTypes.object,
};

export default InspectionMenu;
