import { message } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import InspectionsServices from '../../../services/InspectionsServices';

message.config({
  top: '90vh',
});

class SelectBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      originalCom: String(this.props.value),
      valueCp: String(this.props.value),
      valueChanged: false,
    };
  }

  toggleEditingState = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  handleChange = (valueCp) => {
    if (valueCp !== this.state.valueCp) {
      this.setState({ valueCp });
      this.setState({ valueChanged: true });
    }
  };

  submitValue = () => {
    this.toggleEditingState();
    if (this.state.valueChanged === true) {
      this.sendNewValue(this.state.valueCp);
    }
    this.setState({ valueChanged: false });
  };

  restorePreviousValue = () => {
    if (this.state.valueChanged === true) {
      this.setState({ valueCp: this.state.originalCom });
    }
    this.toggleEditingState();
    this.setState({ valueChanged: false });
  };

  sendNewValue = async (value) => {
    const { checkpoint, inspectionId, sectionId } = this.props;

    const res = await InspectionsServices.updateCheckpoint(inspectionId, sectionId, checkpoint._id, { value });
    // if price was not updated on API show error toast notification
    if (res.status !== 200) {
      message.error(`Erreur lors de la sauvegarde: ${res.error}`);
    } else {
      message.success('Sauvegardé');
      this.props.reload();
    }
  };

  render() {
    let selectedValue;

    switch (this.props.checkpoint.kind) {
      case 'EnumCheckpoint':
        selectedValue = this.props.checkpoint.details.list.find((val) => val.key === this.props.value)?.label;
        break;
      case 'BoolCheckpoint':
        selectedValue = this.props.checkpoint.value ? 'Oui' : 'Non';
        break;
      default:
        throw new Error('Kind checkpoint unexpected');
    }

    const selector =
      this.props.checkpoint.kind === 'BoolCheckpoint' ? (
        <>
          <label>
            <input type='radio' value='true' checked={this.state.valueCp === 'true'} onChange={(e) => this.handleChange(e.target.value)} />
            Oui
          </label>
          <label>
            <input type='radio' value='false' checked={this.state.valueCp === 'false'} onChange={(e) => this.handleChange(e.target.value)} />
            Non
          </label>
        </>
      ) : (
        <select value={this.state.valueCp} onChange={(e) => this.handleChange(e.target.value)}>
          {_.get(this.props.checkpoint, 'details.list') &&
            this.props.checkpoint.details.list.map((opt) => (
              <option key={opt.key} value={opt.key}>
                {opt.label}
              </option>
            ))}
          ;
        </select>
      );

    if (this.state.isEditing === false) {
      return (
        <React.Fragment>
          <span>{selectedValue}</span>
          <button
            style={{
              backgroundColor: 'transparent',
              border: 0,
              color: '#1f5288',
              fontSize: 14,
              paddingLeft: 5,
            }}
            onClick={this.toggleEditingState}>
            <i className='far fa-edit' />
          </button>
        </React.Fragment>
      );
    }

    return (
      <div>
        <form onSubmit={this.submitValue}>
          {selector}
          <button
            style={{
              backgroundColor: 'rgb(247, 247, 247)',
              border: '1px solid #bbb',
              width: 20,
              height: 20,
              padding: '0 3px',
              margin: '0 2px',
              color: '#1f5288',
              fontSize: 14,
            }}
            type='submit'>
            <i className='fas fa-check' />
          </button>
          <button
            style={{
              backgroundColor: 'rgb(247, 247, 247)',
              border: '1px solid #bbb',
              width: 20,
              height: 20,
              padding: '0 4px',
              color: 'crimson',
              fontSize: 14,
            }}
            type='button'
            onClick={this.restorePreviousValue}>
            <i className='fas fa-times' />
          </button>
        </form>
      </div>
    );
  }
}

SelectBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inspectionId: PropTypes.string,
  sectionId: PropTypes.string,
  reload: PropTypes.func,
  checkpoint: PropTypes.object,
};

export default SelectBox;
