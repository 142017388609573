/* eslint-disable no-async-promise-executor */
/* eslint-disable no-promise-executor-return */
import axios from 'axios';
import _ from 'lodash';
import constants from '../constants';
import utils from '../utils';

const getAllAccounts = async (params = {}) => {
  const response = await fetch(constants.URI.v3.concessions.accounts.concat(utils.queryStringV2(params)), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const body = await response.json();
  return body.users.items;
};

const getAccount = async ({ userId }) => {
  const response = await fetch(constants.URI.v3.concessions.accounts.concat(`/${userId}`), {
    method: 'GET',
    credentials: 'include',
  });
  const body = await response.json();
  return { status: response.status, user: body };
};

const getAllConcessions = async () => {
  const response = await fetch(constants.URI.v3.concessions.index, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const body = await response.json();
  return body.concessions.items;
};

const getConcession = async ({ concessionId }) => {
  const response = await fetch(constants.URI.v3.concessions.index.concat(`/${concessionId}`), {
    method: 'GET',
    credentials: 'include',
  });
  const body = await response.json();
  return { status: response.status, concession: body.concession };
};

const authorizeAdminConnect = (data) =>
  new Promise(async (resolve) => {
    await axios.post(constants.URI.v3.concessions.index.concat('/toggleaccess'), data, { withCredentials: true }).catch((err) => ({ status: 500, err }));
    return resolve({});
  });

const sendWelcomeToResellerFromConcession = (data) =>
  new Promise(async (resolve) => {
    await axios.post(constants.URI.v3.concessions.index.concat('/notifyreseller'), data, { withCredentials: true }).catch((err) => ({ status: 500, err }));
    return resolve({});
  });

const createUser = (data) =>
  new Promise(async (resolve, reject) => {
    const response = await axios.post(constants.URI.v3.concessions.accounts, data, { withCredentials: true }).catch((err) => ({ status: 500, err }));

    if (response.status === 500) {
      return reject(response.err.response.data);
    }
    return resolve({ status: response.status, body: response.body });
  });

const editConcession = ({ concessionId, countryId, name, entity, reportTemplates, plateLogoUrl }) =>
  new Promise((resolve, reject) => {
    axios
      .put(
        constants.URI.v3.concessions.index.concat(`/${concessionId}`),
        {
          name,
          countryId,
          reportTemplates,
          entity,
          plateLogoUrl,
        },
        { withCredentials: true },
      )
      .then((response) => resolve({ status: response.status, body: response.body }))
      .catch((err) => {
        reject(_.get(err, 'response.data') || err);
      });
  });

const editUser = ({
  userId,
  firstname,
  lastname,
  notifyConcession,
  canCreateMsaInspection,
  concessionId,
  concessionIds,
  email,
  password,
  phone,
  mobileTargetEnvironment,
  status,
  signedTermsAt,
  role,
}) =>
  new Promise((resolve, reject) => {
    axios
      .put(
        constants.URI.v3.concessions.accounts.concat(`/${userId}`),
        {
          firstname,
          lastname,
          notifyConcession,
          canCreateMsaInspection,
          concessionId,
          concessionIds,
          email,
          password,
          phone,
          mobileTargetEnvironment,
          status,
          signedTermsAt,
          role,
        },
        { withCredentials: true },
      )
      .then((response) => resolve({ status: response.status, body: response.body }))
      .catch((err) => {
        reject(_.get(err, 'response.data') || err);
      });
  });

const createConcession = ({ name, entityId, countryId, reportTemplates, plateLogoUrl }) =>
  new Promise(async (resolve, reject) => {
    const response = await axios
      .post(
        constants.URI.v3.concessions.index,
        {
          name,
          entityId,
          countryId,
          reportTemplates,
          plateLogoUrl,
        },
        { withCredentials: true },
      )
      .catch((err) => ({ status: 500, err }));

    if (response.status === 500) {
      return reject(response.err.response.data);
    }
    return resolve({ status: response.status, body: response.body });
  });

const ConcessionsServices = {
  getAccount,
  getAllAccounts,
  getConcession,
  getAllConcessions,
  createUser,
  createConcession,
  editUser,
  editConcession,
  authorizeAdminConnect,
  sendWelcomeToResellerFromConcession,
};

export default ConcessionsServices;
