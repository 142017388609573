import Concessions from '../actions/Concessions';

const { SET_REFRESH_AT_FALSE } = Concessions;

const defaultState = {
  rows: [],
  length: 0,
  facets: [],
  refreshTable: false,
  // status: '',
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_INPUT_ROWS': {
      return {
        ...state,
        inputRows: action.inputRows,
        inputLength: action.inputLength,
      };
    }
    case 'UPDATE_INPUT_ROWS_ENTITIES': {
      return {
        ...state,
        entitiesOptions: action.entitiesOptions,
      };
    }
    case 'UPDATE_INPUT_ROWS_CONCESSIONS': {
      return {
        ...state,
        concessionsOptions: action.concessionsOptions,
      };
    }
    case 'UPDATE_INPUT_ROWS_USERS': {
      return {
        ...state,
        usersOptions: action.usersOptions,
      };
    }
    case 'UPDATE_TABLE_ROWS': {
      return {
        ...state,
        rows: action.rows,
        length: action.length,
        index: action.index,
      };
    }
    case 'UPDATE_TABLE_OPTIONS': {
      return {
        ...state,
        options: action.options,
      };
    }
    case 'UPDATE_ROLE': {
      return {
        ...state,
        role: action.role,
      };
    }
    case 'UPDATE_STATUS': {
      return {
        ...state,
        status: action.status,
      };
    }
    case 'UPDATE_FACETS': {
      return {
        ...state,
        facets: action.facets,
      };
    }
    case 'UPDATE_FACETS_CONCESSION': {
      return {
        ...state,
        facetsConcession: action.facetsConcession,
      };
    }
    case 'UPDATE_SEARCH': {
      return {
        ...state,
        search: action.search,
      };
    }
    case 'SHOW_MODAL': {
      return {
        ...state,
        modalName: action.modalName,
        modalData: action.modalData,
      };
    }
    case 'HIDE_MODAL': {
      return {
        ...state,
        modalName: null,
        modalData: {},
        refreshTable: true,
      };
    }
    case SET_REFRESH_AT_FALSE: {
      return {
        ...state,
        refreshTable: false,
      };
    }
    case 'CHANGE_PAGE': {
      return {
        ...state,
        page: action.page,
      };
    }
    default:
      return state;
  }
};

export default reducer;
