import PropTypes from 'prop-types';
import React from 'react';
import UsersServices from '../../../services/UsersServices';

class SpecialistSelectors extends React.Component {
  state = {
    value: '',
    items: [],
  };

  updateSpecialistFromParentComponent(specialistFromParentComponent) {
    const { init } = specialistFromParentComponent;
    if (init && init._id && init.name) {
      init.name = init.name.replace(/\n/g, ' ');
      this.setState({ value: init.name });
    }
  }

  componentDidMount() {
    this.updateSpecialistFromParentComponent(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateSpecialistFromParentComponent(this.props);
    }
  }

  setValue = (e) => {
    this.setState({ value: e.target.value }, async () => {
      this.props.selectOptions(null);
      if (this.state.value && this.state.value.length > 0) {
        const params = {
          q: this.state.value,
          isSpecialist: true,
          isActive: true,
        };
        const { status, body } = await UsersServices.searchUsers(params);
        if (status === 200) {
          this.setState({ items: body.users });
        } else {
          this.setState({ items: [] });
        }
      } else {
        this.setState({ items: [] });
      }
    });
  };

  selectOptions = (item) => {
    this.setState({ value: `${item.firstname || ''} ${item.lastname || ''}`.trim(), items: [] });
    this.props.selectOptions(item);
  };

  renderItems = () => {
    if (this.state.items && this.state.items.length > 0) {
      return (
        <div className='itemsList'>
          {this.state.items.map((item) => (
            <div className='item' key={item._id} onClick={this.selectOptions.bind(this, item)}>
              <div>
                {item.firstname} {item.lastname}
              </div>
              <div className='email'>{item.email}</div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className='formInput customElementSelector'>
        <label>{this.props.label}</label>
        <input
          type={this.props.type || 'text'}
          value={this.state.value}
          className={this.props.className}
          placeholder={this.props.placeholder}
          onChange={this.setValue}
          disabled={this.props.scheduled}
          data-cy="specialistSelector"
        />
        {this.renderItems()}
      </div>
    );
  }
}

SpecialistSelectors.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  init: PropTypes.object,
  scheduled: PropTypes.bool,
  selectOptions: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SpecialistSelectors;
