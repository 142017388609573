const adminReducerDefaultState = {
  logs: [],
  loadingLogs: 0,
  users: [],
  loadingUsers: false,
  loadingUserState: -1,
  activeMenuFilters: {
    specialist: 'MAP_SPECIALIST',
    admin: 'ADMIN_ACTIFS',
    rapports: 'RAPPORTS_LIST',
  },
  lastSearch: {
    pro: new Date().getTime(),
    specialist: new Date().getTime(),
    admin: new Date().getTime(),
    rapports: new Date().getTime(),
  },
  optionsFilters: {
    specialist: [
      {
        menuSelector: 'MAP_SPECIALIST',
        value: 'Carte',
        icon: 'fa fa-map',
      },
      {
        menuSelector: 'SPECIALIST_NEW',
        value: 'Nouveaux arrivés',
        icon: null,
      },
      {
        menuSelector: 'SPECIALIST_ACTIFS',
        value: 'Actifs',
        icon: null,
      },
      {
        menuSelector: 'SPECIALIST_CONFIRMES',
        value: 'Confirmés',
        icon: null,
      },
      {
        menuSelector: 'SPECIALIST_NON_CONFIRMES',
        value: 'Non confirmés',
        icon: null,
      },
      // {
      //   menuSelector: 'SPECIALIST_INATIFS',
      //   value: 'Inactifs',
      //   icon: null,
      // },
      {
        menuSelector: 'SPECIALIST_REFUSES',
        value: 'Refusés',
        icon: null,
      },
      {
        menuSelector: 'STATS_SPECIALISTS',
        value: 'Stats',
        icon: null,
      },
      {
        menuSelector: 'SEARCH_SPECIALIST',
        value: 'Recherche',
        icon: 'fa fa-search',
      },
    ],
    admin: [
      {
        menuSelector: 'ADMIN_ACTIFS',
        value: 'Admins actifs',
        icon: null,
      },
      {
        menuSelector: 'ADMIN_INACTIFS',
        value: 'Admins en attente',
        icon: null,
      },
      {
        menuSelector: 'ADD_ADMIN',
        value: 'Créer un admin',
        icon: 'fa fa-plus',
      },
    ],
    rapports: [
      {
        menuSelector: 'RAPPORTS_LIST',
        value: 'Rapports',
        icon: null,
      },
      // {
      //   menuSelector: 'NEW_RAPPORT',
      //   value: 'Nouveau rapport',
      //   icon: 'fa fa-plus',
      // },
    ],
    reports: [
      {
        menuSelector: 'REPORT_LIST',
        value: 'Rapports',
        icon: null,
      },
      // {
      //   menuSelector: 'NEW_RAPPORT',
      //   value: 'Nouveau rapport',
      //   icon: 'fa fa-plus',
      // },
    ],
  },
  usersFilters: {
    isSearch: false,
    isSpecialist: false,
    isAdmin: false,
    isActive: false,
    accepted: false,
    confirmed: false,
    unConfirmed: false,
    inActive: false,
    notAccepted: false,
    email: null,
    lat: null,
    lng: null,
    address: null,
    skip: 0,
  },
  skip: 0,
  activeMenu: 'DOCUMENTATION',
};

const adminReducer = (state = adminReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_USERS': {
      return {
        ...state,
        users: action.users,
      };
    }
    case 'SET_LOADING_USER': {
      return {
        ...state,
        loadingUsers: action.state,
      };
    }
    case 'SET_USER_STATE_LOADING': {
      return {
        ...state,
        loadingUserState: action.state,
      };
    }
    case 'SET_ACTIVE_MENU_FILTERS': {
      return {
        ...state,
        lastSearch: {
          ...state.lastSearch,
          pro: new Date().getTime(),
          admin: new Date().getTime(),
          specialist: new Date().getTime(),
        },
        activeMenuFilters: {
          ...action.activeMenu,
        },
      };
    }
    case 'SKIP_USERS_NEXT': {
      let toAdd = 30;
      if (!action.next) {
        toAdd = -30;
      }
      if (state.usersFilters.skip + toAdd >= 0) {
        return {
          ...state,
          usersFilters: {
            ...state.usersFilters,
            skip: state.usersFilters.skip + toAdd,
          },
        };
      }
      return {
        ...state,
      };
    }
    case 'SET_USERS_FILTERS': {
      return {
        ...state,
        lastSearch: {
          ...state.lastSearch,
          pro: new Date().getTime(),
          admin: new Date().getTime(),
          specialist: new Date().getTime(),
        },
        usersFilters: {
          ...state.usersFilters,
          ...action.filters,
        },
      };
    }
    // case 'EDIT_RAPPORT': {
    //   return {
    //     ...state,
    //     // lastSearch: {
    //     //   ...state.lastSearch,
    //     //   pro: new Date().getTime(),
    //     //   admin: new Date().getTime(),
    //     //   specialist: new Date().getTime(),
    //     // },
    //     ...action,
    //   };
    // }
    default:
      return state;
  }
};

export default adminReducer;
