import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Select } from 'antd';
import { sendSms } from '../../../../actions/specialistsActions';
import ModalBody from '../../../shared/molecules/ModalBody';
import filtersOptions from '../atoms/filtersOptions';
import { getSpecialistAndSkills } from '../../../../helpers/specialistsFilter';

Modal.setAppElement('body');

const { Option } = Select;

const ModalSendSmsToStaffers = ({
  dispatch,
  isOpen,
  isLoading,
  isLoadingUpdate,
  closeModal,
  error,
  specialistsState,
  specialistSkillsState,
}) => {
  const { addToast } = useToasts();
  const [status, setStatus] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [message, setMessage] = useState('');

  const selectFilter = (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  const statusSelectFilter = (inputValue, option) => option.props.filter.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  const sendSmsToStaffersModal = async () => {
    const result = await sendSms(dispatch, {
      status,
      departments: selectedDepartment,
      skills: selectedSkills,
      message,
    });
    if (result.type === 'SEND_SMS_SUCCESS') {
      addToast('Sms envoyé avec succès', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 2000 });
      closeModal();
    }
  };

  useEffect(() => {
    const [, skillsList, departmentsList] = getSpecialistAndSkills(specialistsState, specialistSkillsState);
    setSkills(skillsList[0].filter((skill) => skill.kind === 'staff'));
    setDepartmentList(departmentsList[0]);
  }, [specialistsState.length > 1 && specialistSkillsState.length > 1]);

  return (
    <ModalBody
      closeModal={closeModal}
      title='Envoyer un sms aux staffeur(s) sélectionné(s)'
      isOpen={isOpen}
      actionSave={sendSmsToStaffersModal}
      isLoading={isLoading}
      updateAction={true}
      error={error}
      loadingButton={isLoadingUpdate}
      classToAdd='changeStatus'
    >
      <div className='modalChangeStatusBilling'>
        <div>
          <h3>Veuillez remplir les champs ci-dessous : </h3>
          <form className='mt10px'>
            <Row>
              <Col md={12}>
                <div className='formInput'>
                  <label>Statut</label>
                  <Select
                    dropdownMatchSelectWidth={false}
                    allowClear={true}
                    mode='multiple'
                    style={{ width: '100%' }}
                    placeholder='Filtrer'
                    onChange={(value) => setStatus(value)}
                    filterOption={statusSelectFilter}
                    defaultValue={[]}>
                    {filtersOptions.map(({ menuSelector, value }, keyStatus) => (
                      <Option data-cy={menuSelector} key={keyStatus} value={menuSelector}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col md={12}>
                <div className='formInput'>
                  <label>Département</label>
                  <Select
                    mode='multiple'
                    defaultValue={[]}
                    dropdownMatchSelectWidth={false}
                    style={{ width: '100%' }}
                    placeholder='Filtrer'
                    onChange={(value) => setSelectedDepartment(value)}
                    filterOption={selectFilter}
                    value={selectedDepartment}>
                    {departmentList.map((dep) => (
                      <Option key={`dep_${dep.code}`} value={dep.code}>{`${dep.code ? dep.code : 'Aucun'} - ${dep.name}`}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col xs={12}>
                <div className='formInput'>
                  <label>Compétences</label>
                  <Select
                    dropdownMatchSelectWidth={false}
                    allowClear={true}
                    showSearch
                    mode='multiple'
                    style={{ width: '100%' }}
                    placeholder='Filtrer'
                    onChange={(value) => setSelectedSkills(value)}
                    filterOption={selectFilter}>
                    {skills.map((skill) => (
                      <Option key={`skill_${skill._id}`} value={skill._id}>
                        {skill.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col xs={12}>
                <div className='formInput sendNotifications'>
                  <label>Message</label>
                  <textarea rows='5' cols='33' placeholder='Message' name='message' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </ModalBody>
  );
};

ModalSendSmsToStaffers.propTypes = {
  isOpen: PropTypes.bool,
  isLoadingUpdate: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func,
  refresh: PropTypes.func,
  dispatch: PropTypes.func,
  sendNotifications: PropTypes.func,
  error: PropTypes.any,
  specialistsState: PropTypes.array,
  specialistSkillsState: PropTypes.array,
  specialistKind: PropTypes.string,
};
const mapStateToProps = (state) => ({
  specialistsState: state.specialists.specialists,
  specialistSkillsState: state.specialists.specialistSkills,
  isLoading: state.billings.isLoading,
  isLoadingUpdate: state.billings.isLoadingUpdate,
  error: state.billings.error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  sendSms,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSendSmsToStaffers);
