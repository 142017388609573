import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import UsersActions from '../../../actions/UsersActions';
import Svg from '../atoms/Svg';
import ChangeInfosConcession from '../organisms/ChangeInfosConcession';
import EditUser from '../organisms/EditUser';
import InspectAndStaffSubMenu from '../organisms/InspectAndStaffSubMenu';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  showInfos() {
    this.props.dispatch(UsersActions.getActionToDispatch({ type: 'SHOW_MODAL_EDIT_INFOS' }));
  }

  showModal(name) {
    this.props.dispatch(UsersActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: name }));
  }

  logout() {
    const { dispatch } = this.props;
    UsersActions.logout()(dispatch);
  }

  renderMenuAdmin = (allowedRessources) => {
    const { activeMenu } = this.props.application;
    const inspect = ['inspection', 'specialist', 'map'];
    const staff = ['missions', 'staff', 'mapStaff'];

    return (
      <div className='navbar_actions'>
        {inspect.some((val) => allowedRessources.includes(val)) && (
          <Link className={inspect.includes(activeMenu) ? 'active' : ''} to='/admin/inspect/inspection'>
            MSA Inspect
            <div className='line' />
          </Link>
        )}
        {staff.some((val) => allowedRessources.includes(val)) && (
          <Link className={staff.includes(activeMenu) ? 'active' : ''} to='/admin/staff/missions'>
            MSA Staff
            <div className='line' />
          </Link>
        )}
        {allowedRessources.includes('admin') && (
          <Link className={activeMenu === 'admins' ? 'active' : ''} to='/admin/admins'>
            Admin
            <div className='line' />
          </Link>
        )}
        {allowedRessources.includes('invoice') && (
          <Link className={activeMenu === 'billings' ? 'active' : ''} to='/admin/billings'>
            Facturation
            <div className='line' />
          </Link>
        )}
        {allowedRessources.includes('concession') && (
          <Link className={activeMenu === 'concessions' ? 'active' : ''} to='/admin/concessions'>
            Clients
            <div className='line' />
          </Link>
        )}
        {allowedRessources.includes('report') && (
          <Link className={activeMenu === 'reportsv3' ? 'active' : ''} to='/admin/reports'>
            Rapports
            <div className='line' />
          </Link>
        )}
        {allowedRessources.includes('referential') && (
          <Link className={activeMenu === 'referentiels' ? 'active' : ''} to='/admin/referentiels'>
            Référentiels
            <div className='line' />
          </Link>
        )}
        {allowedRessources.includes('excelExports') && (
          <Link className={activeMenu === 'excelExports' ? 'active' : ''} to='/admin/excelExports'>
            Exports Excel
            <div className='line' />
          </Link>
        )}
        <button data-cy='logout' className='link' onClick={this.logout.bind(this)}>
          Déconnexion
        </button>
        <div className='space' />
      </div>
    );
  };

  renderMenuConcession = () => {
    const { activeMenu = 'home', user } = this.props.application;
    const { role } = user;

    return (
      <div className='navbar_actions'>
        <Link className={activeMenu === 'inspections' ? 'active' : ''} to='/concession/inspections'>
          Inspections <div className='line' />
        </Link>
        <Link className={activeMenu === 'missions' ? 'active' : ''} to='/concession/missions'>
          Missions Staff <div className='line' />
        </Link>
        {(role === 'chief' || role === 'superChief') && (
          <Link className={activeMenu === 'users' ? 'active' : ''} to='/concession/users'>
            Utilisateurs <div className='line' />
          </Link>
        )}
        {(role === 'chief' || role === 'superChief') && (
          <Link className={activeMenu === 'concessions' ? 'active' : ''} to='/concession/list'>
            Sites <div className='line' />
          </Link>
        )}
        {(role === 'chief' || role === 'superChief') && (
          <Link className={activeMenu === 'reportsv3' ? 'active' : ''} to='/concession/reports'>
            Rapports
            <div className='line' />
          </Link>
        )}
        {(role === 'chief' || role === 'superChief') && (
          <Link className={activeMenu === 'api' ? 'active' : ''} to='/concession/api'>
            API <div className='line' />
          </Link>
        )}
        <div style={{ flex: 1 }} />
        <button className='view-phone font-inbutton-white'>
          <div>
            Contactez-nous <br /> 7J/7 au 01 86 65 20 43
          </div>
        </button>
        <div id='menuToggle'>
          <input type='checkbox' />
          <span />
          <span />
          <span />
          <ul id='menu'>
            <a onClick={this.showInfos.bind(this)}>
              <li>Modifier mon profil</li>
            </a>
            <a data-cy='logout' onClick={this.logout.bind(this)}>
              <li>Déconnexion</li>
            </a>
          </ul>
        </div>
      </div>
    );
  };

  renderMenuUser = () => (
    <div className='navbar_actions'>
      <div style={{ flex: 1 }} />
      <button className='edit-infos font-inbutton-white' onClick={this.showInfos.bind(this)}>
        <Svg name='edit-pen' size={20} />
        <span>Modifier mes informations</span>
      </button>
      <button className='view-phone font-inbutton-white'>
        <Svg name='phone' size={20} />
        <span>01 86 65 20 43 - 7j/7</span>
      </button>
      <Link data-cy='logout' to='/' onClick={this.logout.bind(this)}>
        <span>Déconnexion</span>
        <div className='line' />
      </Link>
    </div>
  );

  renderEditUserModal = () => {
    if (this.props.application.showModalEditUserInfos) {
      return <EditUser {...this.props} />;
    }
    return null;
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return null;
  };

  renderConcession = (role) => {
    switch (role) {
      case 'reseller':
        return (
          <div className='navbar_v2_item'>
            <div className='logo'>
              <Link to='/concession/inspections'>
                <img src='/img/logo.svg' alt='logo' title='Mon spécialiste auto' />
              </Link>
            </div>
            {this.renderMenuConcession()}
            <ChangeInfosConcession {...this.props} />
            {this.renderEditUserModal()}
          </div>
        );
      case 'chief':
        return (
          <div className='navbar_v2_item'>
            <div className='logo'>
              <Link to='/concession/inspections'>
                <img src='/img/logo.svg' alt='logo' title='Mon spécialiste auto' />
              </Link>
            </div>
            {this.renderMenuConcession()}
            <ChangeInfosConcession {...this.props} />
            {this.renderEditUserModal()}
          </div>
        );
      case 'superChief':
        return (
          <div className='navbar_v2_item'>
            <div className='logo'>
              <Link to='/concession/inspections'>
                <img src='/img/logo.svg' alt='logo' title='Mon spécialiste auto' />
              </Link>
            </div>
            {this.renderMenuConcession()}
            <ChangeInfosConcession {...this.props} />
            {this.renderEditUserModal()}
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { isAdmin, accessRole, role } = this.props.application.user;
    const isConcession = role === 'reseller' || role === 'chief' || role === 'superChief';
    const to = isConcession ? '/concession' : '/';
    const allowedRessources = accessRole?.allowedRessources?.map((allowedRessourceValue) => allowedRessourceValue.key) || [];
    const tabsWithSubmenu = ['inspect', 'staff'];
    const { pathname } = window.location;
    const menu = pathname.split('/')[2];

    return (
      <React.Fragment>
        {this.renderRedirect()}
        {isAdmin && (
          <div className='navbar_v2_item'>
            <div className='logo'>
              <Link to={to}>
                <img src='/img/logo.svg' alt='logo' title='Mon spécialiste auto' />
              </Link>
            </div>
            {this.renderMenuAdmin(allowedRessources)}
            {this.renderEditUserModal()}
          </div>
        )}
        {tabsWithSubmenu.includes(menu) && <InspectAndStaffSubMenu allowedRessources={allowedRessources} menu={menu} />}
        {isConcession && this.renderConcession(role)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  application: state.application,
  admin: state.admin,
  inspections: state.inspections,
});

NavBar.propTypes = {
  dispatch: PropTypes.func,
  admin: PropTypes.object,
  application: PropTypes.object,
  inspections: PropTypes.object,
  location: PropTypes.object,
};

export default connect(mapStateToProps)(NavBar);
