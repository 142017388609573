import React from 'react';

export const orangeLine = () => (
  <>
    <hr
      style={{
        backgroundColor: '#e66834',
        height: '2px',
        width: '50%',
        borderStyle: 'none',
        margin: '20px auto',
      }}
    />
  </>
);

export const blueLine = () => (
  <hr
    style={{
      backgroundColor: '#1E5383',
      height: '1px',
      width: '20%',
      borderRadius: '21px',
      opacity: 1,
      margin: '20px auto',
    }}
  />
);
