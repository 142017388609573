import React from 'react';
import PropTypes from 'prop-types';

const CreateInspectionsProgressStep = ({ step }) => (
  <ul className='stepForm'>
    <li className={step === 1 ? 'active' : ''}>
      <div className='num'>1</div>
      <div>Informations</div>
    </li>
    <li className={step === 2 ? 'active' : ''}>
      <div className='num'>2</div>
      <div>Vérification</div>
    </li>
    <li className={step === 3 ? 'active' : ''}>
      <div className='num'>
        <i className='fa fa-check'></i>
      </div>
      <div>Inspection validée</div>
    </li>
  </ul>
);

CreateInspectionsProgressStep.propTypes = {
  step: PropTypes.number,
};

export default CreateInspectionsProgressStep;
