const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV;

const API_ENDPOINT =
  (ENV === 'local' && 'http://localhost:8081/') ||
  (ENV === 'development' && 'https://api-dev.monspecialisteauto.com/') ||
  (ENV === 'pre-prod' && 'https://api-pre-prod.monspecialisteauto.com/') ||
  'https://api.monspecialisteauto.com/';

const constants = {
  API_ENDPOINT,
  URI: {
    createInspection: API_ENDPOINT.concat('api/v3/inspections'),
    getPlannedInspections: API_ENDPOINT.concat('api/v3/inspections/plannedInspections/outline'),
    updatePlannedInspections: API_ENDPOINT.concat('api/v3/inspections/plannedInspections'),
    cancelPlannedInspections: API_ENDPOINT.concat('api/v3/Inspections/plannedInspections/cancel'),
    noShowInspections: API_ENDPOINT.concat('api/v3/Inspections/plannedInspections/noShow'),
    validatePlannedInspection: API_ENDPOINT.concat('api/v3/inspections/plannedInspections/validation'),
    refusePlannedInspection: API_ENDPOINT.concat('api/v3/inspections/plannedInspections/validation'),
    importSpreadsheet: API_ENDPOINT.concat('api/v3/inspections/plannedInspections/spreadsheetImport'),
    sendSmsToSeller: API_ENDPOINT.concat('api/v3/inspections/plannedInspections/sendSmsToSeller'),
    getOnDemandInspections: API_ENDPOINT.concat('api/v3/inspections/onDemandInspections/outline'),
    sendReportTo: API_ENDPOINT.concat('api/v3/inspections/sendReportTo'),
    getPlannedInspectionsDetails: API_ENDPOINT.concat('api/v3/inspections/plannedInspections/getDetails'),
    addNewCommentForAPlannedInspection: API_ENDPOINT.concat('api/v3/inspections/plannedInspections/addComment'),
    fillFromWorker: API_ENDPOINT.concat('api/v3/inspections/fillFromWorker'),
    validateInspection: API_ENDPOINT.concat('api/v3/inspections/isVerified'),
    editReport: API_ENDPOINT.concat('api/v3/inspections/report'),
    entities: API_ENDPOINT.concat('api/v3/entities'),

    v3: {
      checkpoints: API_ENDPOINT.concat('api/v3/checkpoints/'),
      msaCarPart: API_ENDPOINT.concat('api/v3/msaCarPart/'),
      cupiCarPart: API_ENDPOINT.concat('api/v3/cupiCarPart/'),
      cpConstants: API_ENDPOINT.concat('api/v3/checkpoints/constants/'),
      cpRules: API_ENDPOINT.concat('api/v3/checkpoints/rules/'),
      technicalCostByLevel: API_ENDPOINT.concat('api/v3/technicityCostByLevel'),
      inspection: API_ENDPOINT.concat('api/v3/reportTemplates/'),
      inspectionById: API_ENDPOINT.concat('api/v3/inspections/inspectionId'),
      repairCost: API_ENDPOINT.concat('api/v3/repairCosts/'),
      defectTypologies: API_ENDPOINT.concat('api/v3/typologies/defects'),
      sortedTypologies: API_ENDPOINT.concat('api/v3/typologies/sortedTypologies'),
      global: API_ENDPOINT.concat('api/v3/'),
      plannedReports: API_ENDPOINT.concat('api/v3/reportTemplates/plannedInspection/outline'),
      concessions: {
        accounts: API_ENDPOINT.concat('api/v3/concessions/accounts'),
        index: API_ENDPOINT.concat('api/v3/concessions'),
      },
      specialists: {
        index: API_ENDPOINT.concat('api/v3/specialists'),
        sendNotifications: API_ENDPOINT.concat('api/v3/specialists/notifications'),
        sendSms: API_ENDPOINT.concat('api/v3/specialists/sms'),
        createProfessionalExperience: API_ENDPOINT.concat('api/v3/admin/professionalUserExperience'),
        professionalExperience: API_ENDPOINT.concat('api/v3/professionalUserExperience'),
      },
      specialistSkill: API_ENDPOINT.concat('api/v3/specialistSkill'),
      specialistProposition: API_ENDPOINT.concat('api/v3/specialistProposition'),
      labelTranslations: API_ENDPOINT.concat('api/v3/utils/translation'),
      billings: {
        /* ---------------- For Report Menu - Billing Configuration --------- */
        historyConfiguration: API_ENDPOINT.concat('api/v3/reportTemplates/billingHisto/'),
        getOrUpdateConfiguration: API_ENDPOINT.concat('api/v3/reportTemplates/billing/'),
        /* ---------------- For Billing Menu --------- */
        getBillings: API_ENDPOINT.concat('api/v3/billing/orders'),
        getOrUpdateBillingByOrderId: API_ENDPOINT.concat('api/v3/billing/order/'),
        updateStatusBilling: API_ENDPOINT.concat('api/v3/billing/changeStatus'),
      },
      utils: {
        countries: API_ENDPOINT.concat('api/v3/utils/countries'),
      },
      entities: {
        index: API_ENDPOINT.concat('api/v3/entities'),
      },
      documents: {
        regen: API_ENDPOINT.concat('api/v3/inspections/documents/regen'),
      },
      inspections: {
        signedUrlGetDocument: API_ENDPOINT.concat('api/v3/inspections/signedUrlGetDocument'),
        saveDocumentInfos: API_ENDPOINT.concat('api/v3/inspections/saveDocumentInfos'),
        copyDocumentInfos: API_ENDPOINT.concat('api/v3/inspections/copyDocumentInfos'),
        deleteDocumentInfos: API_ENDPOINT.concat('api/v3/inspections/deleteDocumentInfos'),
      },

      exportExcel: {
        notSentInspection: API_ENDPOINT.concat('api/v1/statistics/plannedInspection/notSentInspection'),
        onDemandInspectionRecap: API_ENDPOINT.concat('api/v1/statistics/onDemandInspection'),
        plannedInspectionRecap: API_ENDPOINT.concat('api/v1/statistics/plannedInspection'),
        allPlannedInspectionRecap: API_ENDPOINT.concat('api/v1/statistics/allPlannedInspection'),
        canceledInspectionRecap: API_ENDPOINT.concat('api/v1/statistics/canceledInspectionRecap'),
        billingRecap: API_ENDPOINT.concat('api/v1/statistics/billing'),
      },
      missions: API_ENDPOINT.concat('api/v3/missions'),
      concessionsMissions: API_ENDPOINT.concat('api/v3/missions/concessions'),
      sendMissionsNotificationsToStaffers: API_ENDPOINT.concat('api/v3/missions/sendMissionsNotificationsToStaffers'),
    },

    /* V1 */
    /* Users */
    login: API_ENDPOINT.concat('api/v1/users/login'),
    logout: API_ENDPOINT.concat('api/v1/users/logout'),
    editUser: API_ENDPOINT.concat('api/v1/users'),
    getUser: API_ENDPOINT.concat('api/v1/users'),
    searchUsers: API_ENDPOINT.concat('api/v1/users/search'),
    verifyEmail: API_ENDPOINT.concat('api/v1/users/valid/'),
    forgotPassword: API_ENDPOINT.concat('api/v1/users/forgotpassword'),
    checkpasswordtoken: API_ENDPOINT.concat('api/v1/users/checkpasswordtoken/'),
    editpassword: API_ENDPOINT.concat('api/v1/users/editpassword/'),

    /* Utils */
    signedUrlPut: API_ENDPOINT.concat('api/v1/utils/signedUrlPut'),
    signedUrlGet: API_ENDPOINT.concat('api/v1/utils/signedUrlGet'),
    mobileEnvironments: API_ENDPOINT.concat('api/v1/utils/mobileEnvironments'),
    getFilters: API_ENDPOINT.concat('api/v3/utils/filters'),
  },

  /* TimeOut Auto Refresh */
  autoRefresh: 60000,

  /* Label App */
  label: {
    save: 'Sauvegarder',
    saveInLoading: 'Sauvegarde en cours',
    cancel: 'Annuler',
  },

  /* Date Time Format */
  formatDateTimeAppointment: 'DD/MM/YYYY HH:mm',

  /* Date Time Format AG Grid */
  formatDateTimeAppointmentAgGrid: 'DD MMM YYYY - HH:mm',

  /* Database Date Time Format */
  formatDateTimeAppointmentDB: 'YYYY-MM-DDTHH:mm:ss',

  /* Date Format */
  formatDateAppointment: 'DD/MM/YYYY',

  /* Date Format Ag Grid */
  formatDateAppointmentAgGrid: 'DD MMM YYYY',

  /* Time Format */
  formatHourAppointment: 'HH:mm',

  /* Inspection Type Test */
  inspectionTypeTest: [
    { label: 'Mecanique', value: 0 },
    { label: 'Carrosserie', value: 1 },
  ],

  /* Name Modal On Click Item - Billing Menu */
  nameModal: {
    billing: {
      editOrViewBilling: 'editOrViewBilling',
      changeStatusBilling: 'changeStatusBilling',
    },
    specialist: {
      detailsSpecialist: 'detailsSpecialist',
      sendNotificationsToSpecialists: 'SendNotificationsToSpecialists',
    },
    staffer: {
      sendSmsToStaffers: 'SendSmsToStaffers',
    },
  },

  /* Billing Status */
  statusBilling: {
    estimate: {
      name: 'estimate',
      label: 'Facture estimée',
      value: 'Ebauches de factures',
    },
    computed: {
      name: 'computed',
      label: 'Factures à valider par les spécialistes',
      value: 'Factures à valider par les spécialistes',
    },
    ordered: {
      name: 'ordered',
      label: 'Paiement envoyé à la banque',
      value: 'Factures à traiter ou payées',
    },
    due: {
      name: 'due',
      label: 'A payer',
    },
    paid: {
      name: 'paid',
      label: 'Paiement confirmé',
    },
    bankError: {
      name: 'bankError',
      label: 'Erreur banque',
    },
  },

  /* Comment Type - Billing Reference */
  commentTypeBillingReference: [
    {
      value: 'Mauvaise identification du véhicule',
      label: 'Mauvaise identification du véhicule',
    },
    {
      value: "Non identification d'un ou plusieurs défaut(s)",
      label: "Non identification d'un ou plusieurs défaut(s)",
    },
    {
      value: "Mauvaise évaluation d'un ou plusieurs défaut(s)",
      label: "Mauvaise évaluation d'un ou plusieurs défaut(s)",
    },
    {
      value: "Manque d'informations importantes concernant un ou plusieurs défaut(s)",
      label: "Manque d'informations importantes concernant un ou plusieurs défaut(s)",
    },
    {
      value: 'Non respect du cahier des charges',
      label: 'Non respect du cahier des charges',
    },
    {
      value: 'Non respect de la charte de bonne conduite',
      label: 'Non respect de la charte de bonne conduite',
    },
    {
      value: 'Non respect des critères de qualité de plusieurs photos',
      label: 'Non respect des critères de qualité de plusieurs photos',
    },
  ],

  /* Billing Status */
  statusBillingsToChange: [{ value: 'ordered' }, { value: 'due' }, { value: 'paid' }, { value: 'bankError' }],

  /* Specialist Legal Status */
  legalStatus: {
    MicroContractor: 'Statut micro-entrepreneur',
    Contractor: 'Statut SARL, EURL, SAS, SASU ou SA',
    NoStatusLegal: 'Pas de statut légal',
  },
  rankOptions: {
    member: 'Membre',
    confirmed: 'Confirmé',
    ambassador: 'Ambassadeur',
  },

  /* Specialist Availability Status */
  availabilities: {
    NightAndWeekEnd: 'Salarié et disponible le soir et le week-end',
    AllDay: 'Salarié et disponible toute la journée',
    AllTheTimeSelfEmployed: 'A mon compte et disponible tout le temps',
    FromTimeToTime: 'A mon compte et disponible de temps en temps, en fonction de mon emploi du temps',
    AllTheTimeUnemployed: 'En demande d’emploi et disponible tout le temps',
  },

  /* ROLES */
  roles: {
    superChief: 'Admin de groupe',
    chief: 'Responsable',
    reseller: 'Utilisateur',
  },

  /* When specialist status is inactive, we need to add a valid reason */
  inactiveOptions: [
    { label: 'Injoignable', value: 'unreachable' },
    { label: 'Litige client', value: 'dispute' },
    { label: 'Manque de temps', value: 'lackTime' },
    { label: 'Manque d’expériences', value: 'lackExperience' },
    { label: 'Non défini', value: 'undefinedReason' },
    { label: 'Problème de micro-entreprise', value: 'businessStatusIssue' },
    { label: 'Ne souhaite plus faire parti du réseau', value: 'leaveNetwork' },
  ],

  statusLabels: {
    ordered: 'En attente',
    scheduled: 'Programmée',
    ongoing: 'En cours',
    done: 'Réalisée',
    canceled: 'Annulée',
  },

  vatType: [
    { label: 'Déductible', value: 'deductible' },
    { label: 'Non-déductible', value: 'non-deductible' },
  ],
  /* VISUAL REMINDER */
  visualReminder: {
    inspectionOfTheSameSiteAndAddress: 'inspectionOfTheSameSiteAndAddress',
  },
};

export default constants;
