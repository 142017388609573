import InspectionsServices from '../services/InspectionsServices';
import ConcessionsActions from './Concessions';

const getActionToDispatch = (params) => {
  switch (params.type) {
    // --------------------------------------
    // On récupère les informations
    // --------------------------------------
    case 'LOADING_INSPECTION_REFRESHING': {
      return {
        type: 'LOADING_INSPECTION_REFRESHING',
      };
    }
    // --------------------------------------
    // Réglage des filtres
    // --------------------------------------
    case 'SET_FILTERS': {
      return {
        type: params.type,
        filters: params.filters,
      };
    }
    case 'RESET_FILTERS': {
      return {
        type: params.type,
        filters: params.filters,
      };
    }
    case 'LOADING_FILTERS': {
      return {
        type: params.type,
        loadingFilters: params.loadingFilters,
      };
    }
    // --------------------------------------
    // Chargement des inspections sans soucis
    // --------------------------------------
    case 'LOADING_INSPECTION_SUCCESS': {
      return {
        type: params.type,
      };
    }
    // --------------------------------------
    // Chargement des inspections avec soucis
    // --------------------------------------
    case 'LOADING_INSPECTION_ERROR': {
      return {
        type: 'LOADING_INSPECTION_ERROR',
        error: params.error,
      };
    }
    // --------------------------------------
    // Set inspections
    // --------------------------------------
    case 'SET_INSPECTIONS': {
      return {
        type: 'SET_INSPECTIONS',
        inspections: params.inspections,
        count: params.count,
      };
    }
    // case 'SET_INSPECTIONS_COUNT': {
    //   return {
    //     type: 'SET_INSPECTIONS_COUNT',
    //     count: params.count,
    //   };
    // }
    // --------------------------------------
    // Afficher la modal pour ajouter une nouvelle audience
    // --------------------------------------
    case 'SHOW_MODAL_NOUVELLE_INSPECTION': {
      return {
        type: 'SHOW_MODAL_NOUVELLE_INSPECTION',
      };
    }
    // --------------------------------------
    // Modal 'renvoyer le rapport'
    // --------------------------------------
    case 'SHOW_MODAL_RESEND': {
      return {
        type: params.type,
        inspectionId: params.inspectionId,
      };
    }
    case 'HIDE_MODAL_RESEND': {
      return {
        type: params.type,
      };
    }
    // --------------------------------------
    // Afficher la modal pour ajouter une nouvelle audience
    // --------------------------------------
    case 'SHOW_MODAL_ASSIGNMENT': {
      return {
        type: 'SHOW_MODAL_ASSIGNMENT',
      };
    }
    case 'SHOW_MODAL_MULTIPLE_ASSIGN': {
      return {
        type: 'SHOW_MODAL_MULTIPLE_ASSIGN',
      };
    }
    case 'SHOW_MODAL_MULTIPLE_UNASSIGN': {
      return {
        type: 'SHOW_MODAL_MULTIPLE_UNASSIGN',
      };
    }
    case 'SHOW_MODAL_MULTIPLE_CANCEL': {
      return {
        type: 'SHOW_MODAL_MULTIPLE_CANCEL',
      };
    }
    case 'SHOW_MODAL_NOTATION': {
      return {
        type: 'SHOW_MODAL_NOTATION',
      };
    }
    case 'SHOW_MODAL_MODIFY_COMMENTS': {
      return {
        type: 'SHOW_MODAL_MODIFY_COMMENTS',
      };
    }
    // --------------------------------------
    // Afficher la modal pour annuler une inspection
    // --------------------------------------
    case 'SHOW_MODAL_BAN_INSPECTION': {
      return {
        type: 'SHOW_MODAL_BAN_INSPECTION',
      };
    }
    // --------------------------------------
    // Cacher la modal pour ajouter une inspection
    // --------------------------------------
    case 'HIDE_MODAL_NOUVELLE_INSPECTION': {
      return {
        type: 'HIDE_MODAL_NOUVELLE_INSPECTION',
      };
    }
    // --------------------------------------
    // Cacher la modal pour ajouter une inspection
    // --------------------------------------
    case 'HIDE_MODAL_ASSIGNMENT': {
      return {
        type: 'HIDE_MODAL_ASSIGNMENT',
      };
    }
    case 'HIDE_MODAL_MULTIPLE_ASSIGN': {
      return {
        type: 'HIDE_MODAL_MULTIPLE_ASSIGN',
      };
    }
    case 'HIDE_MODAL_MULTIPLE_UNASSIGN': {
      return {
        type: 'HIDE_MODAL_MULTIPLE_UNASSIGN',
      };
    }
    case 'HIDE_MODAL_MULTIPLE_CANCEL': {
      return {
        type: 'HIDE_MODAL_MULTIPLE_CANCEL',
      };
    }
    case 'HIDE_MODAL_NOTATION': {
      return {
        type: 'HIDE_MODAL_NOTATION',
      };
    }
    case 'HIDE_MODAL_MODIFY_COMMENTS': {
      return {
        type: 'HIDE_MODAL_MODIFY_COMMENTS',
      };
    }
    // --------------------------------------
    // Cacher la modal pour annuler une inspection
    // --------------------------------------
    case 'HIDE_MODAL_BAN_INSPECTION': {
      return {
        type: 'HIDE_MODAL_BAN_INSPECTION',
      };
    }
    case 'RESET_INSPECTION_BAN': {
      return {
        type: 'RESET_INSPECTION_BAN',
      };
    }
    // --------------------------------------
    // Cacher la modal les customs sms
    // --------------------------------------
    case 'SHOW_MODAL_CUSTOM_SMS': {
      return {
        type: params.type,
        inspectionId: params.inspectionId,
        data: params.data,
      };
    }
    case 'HIDE_MODAL_CUSTOM_SMS': {
      return {
        type: params.type,
      };
    }
    // --------------------------------------
    // Réinitialiser l'inspection
    // --------------------------------------
    case 'RESET_INSPECTION': {
      return {
        type: 'RESET_INSPECTION',
      };
    }
    // --------------------------------------
    // Éditer l'inspection
    // --------------------------------------
    case 'EDIT_INSPECTION': {
      return {
        type: 'EDIT_INSPECTION',
        data: params.data,
      };
    }
    case 'EDIT_TYPE': {
      return {
        type: 'EDIT_TYPE',
        data: params.data,
      };
    }
    case 'SET_INSPECTION_TO_EDIT': {
      const { data } = params;
      data.cgu = true;
      return {
        type: 'SET_INSPECTION_TO_EDIT',
        data,
      };
    }
    case 'UPDATE_INSPECTION': {
      return {
        type: 'UPDATE_INSPECTION',
        data: params.data,
      };
    }
    case 'UPDATE_INSPECTION_DETAILS': {
      return {
        type: 'UPDATE_INSPECTION_DETAILS',
        inspection: params.inspection,
      };
    }
    // --------------------------------------
    // ANNULER UNE INSPECTION
    // --------------------------------------
    case 'BAN_INSPECTION': {
      const { data } = params;
      return {
        type: 'BAN_INSPECTION',
        data,
      };
    }
    // --------------------------------------
    // SAUVEGARDE D'UNE INSPECTION OK
    // --------------------------------------
    case 'LOADING_INSPECTION_SAVE_DONE': {
      return {
        type: 'LOADING_INSPECTION_SAVE_DONE',
      };
    }
    // ----------------------------------------------------------------------------
    // ETAPE DU FORMULAIRE PRO DE CREATION D'INSPECTION
    // ----------------------------------------------------------------------------
    case 'SET_INSPECTION_STATE': {
      return {
        type: 'SET_INSPECTION_STATE',
        state: params.state,
      };
    }
    // --------------------------------------
    // ERREUR SI CHAMPS MANQUANTS
    // --------------------------------------
    case 'INSPECTION_FILL_ALL': {
      return {
        type: 'INSPECTION_FILL_ALL',
        error: 'Remplissez tous les champs obligatoires avant de continuer',
      };
    }
    // --------------------------------------
    // REINITIALISER LES ERREURS CHAMPS MANQUANTS
    // --------------------------------------
    case 'RESET_INSPECTION_FILL_ALL': {
      return {
        type: 'RESET_INSPECTION_FILL_ALL',
      };
    }
    // --------------------------------------
    // ERREUR LORS DE LA SAUVEGARDE D'UNE INSPECTION
    // --------------------------------------
    case 'LOADING_INSPECTION_SAVE_ERROR': {
      return {
        type: 'LOADING_INSPECTION_SAVE_ERROR',
        error: params.error,
      };
    }
    // --------------------------------------
    // SAUVEGARDE D'UNE INSPECTION OK
    // --------------------------------------
    case 'LOADING_INSPECTION_SAVE': {
      return {
        type: 'LOADING_INSPECTION_SAVE',
      };
    }
    // --------------------------------------
    // REINITIALISER LES ERREURS LORS D'UNE INSPECTION
    // --------------------------------------
    case 'RESET_INSPECTION_ERROR': {
      return {
        type: 'RESET_INSPECTION_ERROR',
      };
    }
    case 'GET_BOOKING': {
      return {
        type: 'GET_BOOKING',
      };
    }
    case 'SET_LOADING_TYPE': {
      return {
        type: 'SET_LOADING_TYPE',
        value: params.value,
      };
    }
    case 'SET_LOADING_TYPE_ERROR': {
      return {
        type: 'SET_LOADING_TYPE_ERROR',
        error: params.error,
      };
    }
    case 'SET_RAPPORTS': {
      return {
        type: 'SET_RAPPORTS',
        list: params.rapports,
      };
    }
    case 'SET_RAPPORT_TO_EDIT': {
      return {
        type: 'SET_RAPPORT_TO_EDIT',
        rapport: params.rapport,
      };
    }
    case 'SET_MODE': {
      return {
        type: 'SET_MODE',
        mode: params.mode,
      };
    }
    // -------------------------------------
    // GESTION DES TYPES DE RAPPORT
    // -------------------------------------
    case 'ADD_REPORT_TYPE_PART': {
      return {
        type: 'ADD_REPORT_TYPE_PART',
      };
    }
    case 'EDIT_PART_TITLE': {
      return {
        type: 'EDIT_PART_TITLE',
        value: params.value,
        index: params.index,
      };
    }
    case 'EDIT_PART_ADD_ELEMENT': {
      return {
        type: 'EDIT_PART_ADD_ELEMENT',
        item: params.item,
        index: params.index,
      };
    }
    case 'SET_INSPECTION_DETAILS': {
      return {
        type: 'SET_INSPECTION_DETAILS',
        inspection: params.inspection,
      };
    }
    case 'RESET_INSPECTION_RAPPEL': {
      return {
        type: 'RESET_INSPECTION_RAPPEL',
      };
    }
    case 'SET_INSPECTION_RAPPEL': {
      return {
        type: 'SET_INSPECTION_RAPPEL',
        rappel: params.rappel,
      };
    }
    case 'SET_RAPPELS': {
      return {
        type: 'SET_RAPPELS',
        rappels: params.rappels,
      };
    }
    case 'SERVER_KILLED_SESSION': {
      return {
        type: 'SERVER_KILLED_SESSION',
        payload: {},
      };
    }
    default:
      return {
        type: 'UNKNOWN',
      };
  }
};

const saveInspection = (data, isConcession) => async (dispatch) => {
  dispatch(
    getActionToDispatch({
      type: 'LOADING_INSPECTION_SAVE',
    }),
  );
  try {
    dispatch(getActionToDispatch({ type: 'LOADING_INSPECTION_SAVE' }));
    // {_id, car, model, year, address, lng, lat, sellerName, sellerPhone, infos, adsUrl, apikey, cgu,}
    /* ------------------------------------------*/
    // Modificatino d'une inspection
    /* ------------------------------------------*/
    if (data._id) {
      const { status, body } = await InspectionsServices.updateInspection(data);
      if (status === 200) {
        dispatch(getActionToDispatch({ type: 'LOADING_INSPECTION_SAVE_DONE' }));
        dispatch(getActionToDispatch({ type: 'RESET_INSPECTION_BAN' }));
        dispatch(getActionToDispatch({ type: 'HIDE_MODAL_BAN_INSPECTION' }));
      } else if (status === 401) {
        dispatch(getActionToDispatch({ type: 'SERVER_KILLED_SESSION' }));
      } else {
        dispatch(
          getActionToDispatch({
            type: 'LOADING_INSPECTION_SAVE_ERROR',
            error: body.error || 'Une erreur est survenue.',
          }),
        );
      }
    }
    /* ------------------------------------------*/
    // Creation d'une inspection
    /* ------------------------------------------*/
    if (!data._id) {
      const { status, body } = await InspectionsServices.createInspection(data);

      if (isConcession && status === 200) {
        ConcessionsActions.reloadTable({ index: 'inspections', type: 'msa' })(dispatch);
        dispatch(getActionToDispatch({ type: 'RESET_INSPECTION_BAN' }));
        dispatch(getActionToDispatch({ type: 'HIDE_MODAL_BAN_INSPECTION' }));
        dispatch(getActionToDispatch({ type: 'LOADING_INSPECTION_SAVE_DONE' }));
        return;
      }
      if (status === 200) {
        dispatch(getActionToDispatch({ type: 'LOADING_INSPECTION_SAVE_DONE' }));
      } else if (status === 401) {
        dispatch(getActionToDispatch({ type: 'SERVER_KILLED_SESSION' }));
      } else {
        dispatch(
          getActionToDispatch({
            type: 'LOADING_INSPECTION_SAVE_ERROR',
            error: body.error || 'Une erreur est survenue.',
          }),
        );
      }
    }
  } catch (e) {
    dispatch(
      getActionToDispatch({
        type: 'LOADING_INSPECTION_SAVE_ERROR',
        error: e.reason || e.message || 'Une erreur est survenue.',
      }),
    );
  }
};

const resetFilters = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch(
      getActionToDispatch({
        type: 'RESET_FILTERS',
        filters: {
          status: '',
          sort: 'createdAt',
          asc: 1,
          perimeter: 50,
          skip: 0,
        },
      }),
    );
    resolve();
  });

const InspectionsActions = {
  getActionToDispatch,
  saveInspection,
  resetFilters,
};

export default InspectionsActions;
