import React from 'react';

const FileInput = ({ type, style, onChange }, ref) => <input type={type} style={style} onChange={() => onChange()} ref={ref} />;

/* eslint react/prop-types: 0 */
// Disable PropTypes because React.forwardRef doesn't support it.

// FileInput.propTypes = {
//   setRef: PropTypes.func,
//   type: PropTypes.string,
//   style: PropTypes.object,
//   onChange: PropTypes.func,
// };

export default React.forwardRef(FileInput);
