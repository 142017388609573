import constants from '../constants';
import utils from '../utils';

const sendCustomSMS = async ({ inspectionId, text }) => {
  const response = await fetch(`${constants.URI.sendSmsToSeller}/${inspectionId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text }),
  });

  return { status: response.status, body: await response.json() };
};

const sendReportTo = async ({ inspectionId, emails }) => {
  const response = await fetch(`${constants.URI.sendReportTo}/${inspectionId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ emails }),
  });

  return { status: response.status, body: await response.json() };
};

const getPlannedInspectionsDetails = async ({ inspectionId }) => {
  const response = await fetch(`${constants.URI.getPlannedInspectionsDetails}/${inspectionId}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return { status: response.status, body: await response.json() };
};

const getInspectionById = async (inspectionId) => {
  const response = await fetch(`${constants.URI.v3.inspectionById}/${inspectionId}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return { status: response.status, body: await response.json() };
};

const addNewCommentForAPlannedInspection = async ({ inspectionId, text }) => {
  const response = await fetch(`${constants.URI.addNewCommentForAPlannedInspection}/${inspectionId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text }),
  });

  return { status: response.status, body: await response.json() };
};

const createInspection = async (newInspection, concessionId, entityId) => {
  const response = await fetch(constants.URI.createInspection, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ inspections: newInspection, concessionId, entityId }),
  });

  return { status: response.status, body: await response.json() };
};

const createTestInspection = async (testedSpecialist, inspectionTypeTest) => {
  const response = await fetch(constants.URI.createInspection, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ testInspection: true, testedSpecialist, inspectionTypeTest }),
  });

  return { status: response.status, body: await response.json() };
};

const updateInspection = async (newInspection) => {
  const inspection = {
    ...newInspection,
    fromApp: true,
  };
  inspection.fromApp = true;
  const response = await fetch(constants.URI.createInspection.concat(`/${inspection._id}`), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(inspection),
  });
  return { status: response.status, body: await response.json() };
};

const validatePlannedInspection = async (inspectionId) => {
  const response = await fetch(`${constants.URI.validatePlannedInspection}/${inspectionId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return { status: response.status, body: await response.json() };
};

const refusePlannedInspection = async (inspectionId) => {
  const response = await fetch(`${constants.URI.refusePlannedInspection}/${inspectionId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return { status: response.status, body: await response.json() };
};

/* *************************************** */
//              Rapports
/* *************************************** */

const importSpreadsheet = async (myData) => {
  const jsonFile = {
    ...myData,
  };

  const response = await fetch(constants.URI.importSpreadsheet, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jsonFile),
  });
  return { status: response.status, body: await response.json() };
};

const updateInspections = async (newInspections) => {
  const response = await fetch(`${constants.URI.updatePlannedInspections}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ inspections: newInspections }),
  });
  return { status: response.status, body: await response.json() };
};

const getPlannedInspections = async (since, filterOnInspectionsCanceledByMSA = false) => {
  let queryString = '';
  if (Number.isInteger(since)) {
    queryString = utils.queryStringV2({ since, filterOnInspectionsCanceledByMSA });
  }
  const response = await fetch(constants.URI.getPlannedInspections.concat(queryString), {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const getOnDemandInspections = async (since) => {
  let queryString = '';

  if (Number.isInteger(since)) {
    queryString = utils.queryStringV2({ since });
  }

  try {
    const response = await fetch(constants.URI.getOnDemandInspections.concat(queryString), {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return { status: response.status, body: await response.json() };
  } catch (err) {
    return { status: 500, body: err };
  }
};

const cancelPlannedInspections = async (inspectionsToCancel) => {
  const response = await fetch(`${constants.URI.cancelPlannedInspections}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ cancelInfos: inspectionsToCancel }),
  });
  return { status: response.status, body: await response.json() };
};

const markNoShowInspection = async (inspectionsToMarkNoShow) => {
  const response = await fetch(`${constants.URI.noShowInspections}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(inspectionsToMarkNoShow),
  });
  return { status: response.status, body: await response.json() };
};

/**
 * V3 : spell inspection
 */

const updateCheckpoint = async (inspectionId, sectionId, checkpointId, data) => {
  const response = await fetch(constants.URI.editReport.concat(`/${inspectionId}/editReport/${sectionId}/${checkpointId}`), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const fillFromWorkerInspection = async (inspectionId) => {
  const queryString = utils.queryStringV2({ inspectionId });
  const response = await fetch(constants.URI.fillFromWorker.concat(queryString), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const validateInspection = async (inspectionId) => {
  const queryString = utils.queryStringV2({ inspectionId });
  const response = await fetch(constants.URI.validateInspection.concat(queryString), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const documentRegen = async (inspectionId) => {
  const queryString = utils.queryStringV2({ inspectionId });
  const response = await fetch(constants.URI.v3.documents.regen.concat(queryString), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const saveDocumentInfos = async (inspectionId, documentsInfos, addNewDocument) => {
  const response = await fetch(`${constants.URI.v3.inspections.saveDocumentInfos}/${inspectionId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ documents: documentsInfos, addNewDocument }),
  });

  return { status: response.status, body: await response.json() };
};

const copyDocumentInfos = async (oldInspectionId, newInspectionId, documentsToCopy) => {
  const response = await fetch(constants.URI.v3.inspections.copyDocumentInfos, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ oldInspectionId, newInspectionId, documentsToCopy }),
  });

  return { status: response.status, body: await response.json() };
};

const deleteDocumentInfos = async (inspectionId, documentToDelete) => {
  const response = await fetch(`${constants.URI.v3.inspections.deleteDocumentInfos}/${inspectionId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ documentToDelete }),
  });

  return { status: response.status, body: await response.json() };
};

const InspectionsServices = {
  createInspection,
  createTestInspection,
  importSpreadsheet,
  updateInspection,
  cancelPlannedInspections,
  markNoShowInspection,
  sendCustomSMS,
  updateInspections,
  validatePlannedInspection,
  refusePlannedInspection,
  getPlannedInspections,
  getInspectionById,
  getOnDemandInspections,
  sendReportTo,
  getPlannedInspectionsDetails,
  addNewCommentForAPlannedInspection,
  updateCheckpoint,
  fillFromWorkerInspection,
  validateInspection,
  documentRegen,
  saveDocumentInfos,
  copyDocumentInfos,
  deleteDocumentInfos,
};

export default InspectionsServices;
