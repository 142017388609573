import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-flexbox-grid';
import Text from '../../../shared/atoms/Text';
import ToggleTable from '../../shared/molecules/ToggleTable';

const TableActionBar = ({ table, onClickRefresh, openModal, length }) => {
  const setData = () => {
    switch (table) {
      case 'concessions':
        return {
          title: 'Liste des sites',
          menu: [
            {
              icon: 'fas fa-plus-circle',
              displayName: 'Créer un site',
              handler: () => openModal('createConcession'),
            },
          ],
        };

      case 'entities':
        return {
          title: 'Liste des groupes',
          menu: [
            {
              icon: 'fa fa-plus',
              displayName: 'Créer un groupe',
              handler: () => openModal('createEntity'),
            },
          ],
        };

      case 'users':
        return {
          title: 'Utilisateurs',
          menu: [
            {
              icon: 'fa fa-plus',
              displayName: 'Créer un compte',
              handler: () => openModal('createConcessionUser'),
            },
          ],
        };

      default:
        return null;
    }
  };

  const data = setData();

  if (!data) {
    return false;
  }

  return (
    <div className='table'>
      <Col xs={12} className='table-header'>
        <div className='title'>
          <Text fontStyle='normal' size={17}>
            {data.title} ({length || '0'})
          </Text>
        </div>
        <div>
          <ToggleTable menu={data.menu} searchAction={onClickRefresh} openModal={data.handler} />
        </div>
      </Col>
    </div>
  );
};

TableActionBar.propTypes = {
  table: PropTypes.string,
  onClickRefresh: PropTypes.func,
  openModal: PropTypes.func,
  length: PropTypes.any,
};

export default TableActionBar;
