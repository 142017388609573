import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ConcessionsActions from '../../../../actions/Concessions';
import InspectionsActions from '../../../../actions/InspectionsActions';
import Text from '../../../shared/atoms/Text';

const ActionsBar = ({ dispatch, planned }) => (
  <div className='action-bar'>
    {planned && (
      <>
        <div data-cy='importExcelSheet' className='item' onClick={() => dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'importSpreadsheet' }))}>
          <i className="fas fa-users"/>
          <Text fontStyle='normal'>Commande d'inspection groupée</Text>
        </div>
        <div className='divider' />
        <div
          data-test-selector='newInspection' // TESTING cypress best practices for more cypress-specific selector
          className='item'
          onClick={() => {
            dispatch(InspectionsActions.getActionToDispatch({ type: 'RESET_INSPECTION' }));
            dispatch(InspectionsActions.getActionToDispatch({ type: 'SHOW_MODAL_NOUVELLE_INSPECTION' }));
            dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName: 'createConcessionInspection' }));
          }}>
          <i className='fas fa-user-plus' />
          <Text fontStyle='normal'>Commande d'inspection unitaire</Text>
        </div>
      </>
    )}
  </div>
);

ActionsBar.propTypes = {
  dispatch: PropTypes.func,
  planned: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(null, mapDispatchToProps)(ActionsBar);
