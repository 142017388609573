import React from 'react';
import PropTypes from 'prop-types';

import Text from '../shared/atoms/Text';

const ToggleTable = (props) => {
  const handler = (data) => {
    const { openModal } = props;
    if (data.handler && typeof data.handler === 'function') {
      data.handler(openModal);
    }
  };

  const refresh = () => {
    const { searchAction } = props;
    searchAction({});
  };

  const { menu = [] } = props;

  return (
    <div className='table-toggle'>
      <span className='refresh'>
        <i className='fa fa-sync-alt' onClick={refresh} />
      </span>
      <span className='menu'>
        {menu.length > 0 && <i className='fas fa-plus' />}
        <ul className='toggle-menu left'>
          {menu.map((data, key) => (
            <li key={key} onClick={() => handler(data)}>
              <i className={data.icon} />{' '}
              <Text fontStyle='normal' size={14}>
                {data.displayName}
              </Text>
            </li>
          ))}
        </ul>
      </span>
    </div>
  );
};

ToggleTable.propTypes = {
  menu: PropTypes.array,
  searchAction: PropTypes.func,
  openModal: PropTypes.func,
};

export default ToggleTable;
