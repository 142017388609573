import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Admin from './authPages/Admin';
import Concession from './authPages/Concession';
import Private from './authPages/Private';
import Public from './authPages/Public';
import BillingsPage from './billings/pages/BillingsPage';
import AdminConcessions from './concessions/admin';
import ConcessionUsers from './concessions/user/accounts';
import ConcessionList from './concessions/user/concessions';
import ConcessionInspections from './concessions/user/inspections';
import ConcessionMissions from './concessions/user/missions';
import ConcessionHooks from './concessions/user/webhooks';
import ExcelExports from './excelExports';
import FirstConnectPage from './generalPages/FirstConnectPage';
import HomePage from './generalPages/HomePage';
import LoginPage from './generalPages/LoginPage';
import NoMatchPage from './generalPages/NoMatchPage';
import PasswordForgotPage from './generalPages/PasswordForgotPage';
import ResetPasswordPage from './generalPages/ResetPasswordPage';
import VerifyEmailPage from './generalPages/VerifyEmailPage';
import InspectionsPage from './inspections/pages/InspectionsPage';
import MapTab from './map/MapTab';
import Missions from './missions/pages/MissionPage';
import Referentiels from './referentiels';
import ReportsV3 from './reportsV3/admin';
import AdminAdminsPage from './users/AdminAdminsPage';
// Admin component
import AdminSpecialistsPage from './users/specialists/pages/AdminSpecialistsPage';

const Router = (props) => {
  const { application } = props;

  return (
    <BrowserRouter>
      <Switch>
        <Private exact path='/' item={props} component={HomePage} user={application.user} {...application} />
        <Concession exact path='/concession/inspections' item={props} component={ConcessionInspections} {...application} />
        <Concession exact path='/concession/missions' item={props} component={ConcessionMissions} {...application} />
        <Concession exact path='/concession/users' item={props} component={ConcessionUsers} {...application} />
        <Concession exact path='/concession/list' item={props} component={ConcessionList} {...application} />
        <Concession exact path='/concession/api' item={props} component={ConcessionHooks} {...application} />
        <Concession exact path='/concession/reports' item={props} component={ReportsV3} {...application} />

        <Admin exact path='/admin/inspect/inspection' ressource={'inspection'} item={props} component={InspectionsPage} {...application} />
        <Admin exact path='/admin/inspect/specialist' ressource={'specialist'} item={props} component={AdminSpecialistsPage} {...application} />
        <Admin exact path='/admin/inspect/map' ressource={'map'} item={props} component={MapTab} {...application} />

        <Admin exact path='/admin/staff/missions' ressource={'missions'} item={props} component={Missions} {...application} />
        <Admin exact path='/admin/staff/staff' ressource={'staff'} item={props} component={AdminSpecialistsPage} {...application} />
        <Admin exact path='/admin/staff/mapStaff' ressource={'mapStaff'} item={props} component={MapTab} {...application} />

        <Admin exact path='/admin/admins' ressource={'admin'} item={props} component={AdminAdminsPage} {...application} />
        <Admin exact path='/admin/concessions' ressource={'concession'} item={props} component={AdminConcessions} {...application} />
        <Admin exact path='/admin/reports' ressource={'report'} item={props} component={ReportsV3} {...application} />
        <Admin exact path='/admin/referentiels' ressource={'referential'} item={props} component={Referentiels} {...application} />
        <Admin exact path='/admin/excelExports' ressource={'excelExports'} item={props} component={ExcelExports} {...application} />
        <Admin exact path='/admin/billings' ressource={'invoice'} item={props} component={BillingsPage} {...application} />

        <Public exact path='/login' component={LoginPage} {...application} />
        <Public exact path='/motdepasseoublie' component={PasswordForgotPage} {...application} />
        <Public exact path='/premiereconnexion' component={FirstConnectPage} {...application} />
        <Public exact path='/verify-email/:token' component={VerifyEmailPage} {...application} />
        <Public exact path='/reset-password/:token' component={ResetPasswordPage} {...application} />
        <Route component={NoMatchPage} />
      </Switch>
    </BrowserRouter>
  );
};

Router.propTypes = {
  application: PropTypes.object,
};

const mapStateToProps = (state) => ({ application: state.application });

export default connect(mapStateToProps)(Router);
