import PropTypes from 'prop-types';
import React from 'react';

const Filters = ({ selectOptions, title, options, activeMenu, className = '' }) => {
  const setMenu = (activeMenus) => {
    selectOptions(activeMenus);
  };

  const renderTitle = () => {
    if (!title) {
      return null;
    }
    return (
      <>
        <div className='font-title-blue'>{title}</div>
      </>
    );
  };

  const renderLink = () => (
    <ul className='filtersList'>
      {options.map((item, index) => (
        <li key={index} onClick={() => setMenu(item.menuSelector)} className={activeMenu === item.menuSelector ? 'selected' : ''}>
          {item.icon ? <i className={item.icon} /> : ''} {item.value}
        </li>
      ))}
    </ul>
  );

  return (
    <div className={`filtersLogs ${className}`}>
      {renderTitle()}
      {renderLink()}
      <div />
    </div>
  );
};

Filters.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  activeMenu: PropTypes.string,
  className: PropTypes.string,
  concessions: PropTypes.object,
  onChangeConcession: PropTypes.func,
  frevo: PropTypes.bool,
  selectOptions: PropTypes.func,
};

export default Filters;
