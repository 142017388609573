import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

const CountrySelect = ({ onSelect, countriesState, selection }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setCountries(countriesState);
  }, []);

  const options = countries.map((country) => ({ value: country, label: country.name }));

  return (
    <div className='search-input max'>
      <label>Sélectionnez un pays</label>
      <div data-cy='countrySelect'>
        <Select
          isClearable={true}
          onChange={(e) => e?.value && onSelect(e.value)}
          options={options}
          isSearchable
          defaultValue={{ value: selection, label: selection?.name }}
          placeholder={'Sélectionnez un pays...'}
          className='cypress__select-country'
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  countriesState: state.countries.countries,
});

CountrySelect.propTypes = {
  onSelect: PropTypes.func,
  countriesState: PropTypes.array,
  selection: PropTypes.any,
};

export default connect(mapStateToProps, null)(CountrySelect);
