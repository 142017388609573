import { Tooltip } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Switch from 'react-switch';
import PriceBox from './priceBox';

// Styles
const tableRow = {
  marginBottom: 10,
  textAlign: 'center',
  alignItems: 'baseline',
  borderTopStyle: 'solid',
  borderTopWidth: 2,
  borderTopColor: '#fff',
  paddingTop: 15,
};
const categoryText = { fontSize: 13, fontWeight: 400, alignText: 'left' };

// const IconFont = Icon.createFromIconfontCN({
//   scriptUrl: '//use.fontawesome.com/releases/v5.0.8/js/solid.js',
// });

// Todo: instead of 4 fixed vehicles catégories, we should get it dynamically from API constants

const PriceTable = (props) => {
  const { name, fullRepairCost, kindVehicleMap } = props;
  const parseTitle = (label) => {
    switch (label) {
      case 'true':
        return 'Oui';
      case 'false':
        return 'Non';
      default:
        return label;
    }
  };

  if (props.isGlobalMsa === true) {
    const { fullRepairCostGlobalMsa } = props;

    return (
      <React.Fragment>
        <div
          style={{
            // overflow: 'scroll',
            marginTop: 40,
            paddingRight: 30,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}>
          <div
            style={{
              margin: '5px 15px 15px 15px',
              display: 'flex',
              width: '73%',
              minWidth: 580,
              justifyContent: 'space-between',
              alignSelf: 'center',
            }}>
            <span style={{ fontSize: 14, fontWeight: 600 }}>{parseTitle(props.title)}</span>
            <div>
              <Switch
                checked={!_.get(fullRepairCostGlobalMsa[0], 'infoRepair.notCalculable', true)}
                onChange={() => props.handleActivationToggle(name, fullRepairCostGlobalMsa)}
                handleDiameter={12}
                offColor='#DDD'
                onColor='#1f5288'
                offHandleColor='#fff'
                onHandleColor='#08f'
                height={20}
                width={40}
                uncheckedIcon={null}
                className='react-switch'
                id='small-radius-switch'
              />
              <span style={{ marginLeft: 7, fontSize: 13, fontWeight: 800 }}>
                {_.get(fullRepairCostGlobalMsa[0], 'infoRepair.notCalculable', true) ? 'FRE desactivé' : 'FRE activé'}
              </span>
            </div>
          </div>
          {_.get(fullRepairCostGlobalMsa[0], 'infoRepair.notCalculable', true) === false && (
            <div
              style={{
                width: '75%',
                minWidth: 580,
                minHeight: 300,
                backgroundColor: '#F9F9F9',
                borderRadius: '25px',
                padding: '30px 25px',
                alignSelf: 'center',
              }}>
              <Row xs={12} style={{ marginBottom: 30, textAlign: 'center' }}>
                <Col xs={4}>
                  <span style={{ fontSize: 13, fontWeight: 600 }}>Type de Véhicule</span>
                </Col>
                <Col xs={4}>
                  <span style={{ fontSize: 13, fontWeight: 600 }}>Prix Publique</span>
                </Col>
                <Col xs={4}>
                  <span style={{ fontSize: 13, fontWeight: 600 }}>Prix Interne</span>
                </Col>
              </Row>
              {_.map(fullRepairCostGlobalMsa, (kind) => (
                <Row key={kind.id} xs={12} style={tableRow}>
                  <Col xs={3} style={{ textAlign: 'right' }}>
                    <span style={categoryText}>{kind.label}</span>
                  </Col>
                  <Col xs={1} style={{ textAlign: 'left' }}>
                    <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 10 }} title='Copier vers toutes catégories'>
                      <i className='fas fa-clone' style={{ fontSize: 13, cursor: 'pointer' }} onClick={() => props.handleCloneCategory(props.name, kind.id)} />
                    </Tooltip>
                  </Col>
                  <Col xs={4}>
                    <PriceBox
                      name={`${props.name}${kind.id}pub`}
                      value={_.get(kind, 'infoRepair.publicCost')}
                      valueMax={_.get(kind, 'infoRepair.publicCostMax')}
                      entity='globalMsa'
                      kindVehicle={_.get(kind, 'infoRepair.kindVehicle._id')}
                      handlePriceUpdate={props.handlePriceUpdate}
                      handleIntervalCheckbox={props.handleIntervalCheckbox}
                      isInterval={!!_.get(kind, 'infoRepair.publicCostMax')}
                      isGlobalMsa={true}
                      globalMsaRepairCostId={{ defect: name, repairCostId: _.get(kind, 'infoRepair._id') }}
                      globalMsaRepairCostsInfo={fullRepairCostGlobalMsa}
                    />
                  </Col>
                  <Col xs={4}>
                    <PriceBox
                      name={`${props.name}${kind.id}priv`}
                      value={_.get(kind, 'infoRepair.privateCost')}
                      valueMax={_.get(kind, 'infoRepair.privateCostMax')}
                      entity='globalMsa'
                      kindVehicle={_.get(kind, 'infoRepair.kindVehicle._id')}
                      handlePriceUpdate={props.handlePriceUpdate}
                      handleIntervalCheckbox={props.handleIntervalCheckbox}
                      isInterval={!!_.get(kind, 'infoRepair.privateCostMax')}
                      isGlobalMsa={true}
                      globalMsaRepairCostId={{ defect: name, repairCostId: _.get(kind, 'infoRepair._id') }}
                      globalMsaRepairCostsInfo={fullRepairCostGlobalMsa}
                      isPrivate
                    />
                  </Col>
                </Row>
              ))}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  // console.log('[DEV] PriceTable name, fullRepairCost:', name, fullRepairCost);
  const mappedRepairCost = {};
  _.each(kindVehicleMap, (kind) => {
    mappedRepairCost[`${kind.key}priv`] = _.get(fullRepairCost[kind._id], 'privateCost');
    mappedRepairCost[`${kind.key}pub`] = _.get(fullRepairCost[kind._id], 'publicCost');
    mappedRepairCost[`${kind.key}privMax`] = _.get(fullRepairCost[kind._id], 'privateCostMax');
    mappedRepairCost[`${kind.key}pubMax`] = _.get(fullRepairCost[kind._id], 'publicCostMax');
  });
  // console.log(mappedRepairCost);
  return (
    <React.Fragment>
      <div
        style={{
          // overflow: 'scroll',
          marginTop: 40,
          paddingRight: 30,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}>
        <div
          style={{
            margin: '5px 15px 15px 15px',
            display: 'flex',
            width: '73%',
            minWidth: 580,
            justifyContent: 'space-between',
            alignSelf: 'center',
          }}>
          <span style={{ fontSize: 14, fontWeight: 600 }}>{parseTitle(props.title)}</span>
          <div>
            <Switch
              checked={!_.get(fullRepairCost[kindVehicleMap[0]._id], 'notCalculable', true)}
              onChange={() => props.handleActivationToggle(name)}
              handleDiameter={12}
              offColor='#DDD'
              onColor='#1f5288'
              offHandleColor='#fff'
              onHandleColor='#08f'
              height={20}
              width={40}
              uncheckedIcon={null}
              className='react-switch'
              id='small-radius-switch'
            />
            <span style={{ marginLeft: 7, fontSize: 13, fontWeight: 800 }}>
              {_.get(fullRepairCost[kindVehicleMap[0]._id], 'notCalculable', true) ? 'FRE desactivé' : 'FRE activé'}
            </span>
          </div>
        </div>
        {_.get(fullRepairCost[kindVehicleMap[0]._id], 'notCalculable', true) === false && (
          <div
            style={{
              width: '75%',
              minWidth: 580,
              minHeight: 300,
              backgroundColor: '#F9F9F9',
              borderRadius: '25px',
              padding: '30px 25px',
              alignSelf: 'center',
            }}>
            <Row xs={12} style={{ marginBottom: 30, textAlign: 'center' }}>
              <Col xs={4}>
                <span style={{ fontSize: 13, fontWeight: 600 }}>Type de Véhicule</span>
              </Col>
              <Col xs={4}>
                <span style={{ fontSize: 13, fontWeight: 600 }}>Prix Publique</span>
              </Col>
              <Col xs={4}>
                <span style={{ fontSize: 13, fontWeight: 600 }}>Prix Interne</span>
              </Col>
            </Row>
            {_.map(kindVehicleMap, (kind) => (
              <Row key={kind.key} xs={12} style={tableRow}>
                <Col xs={3} style={{ textAlign: 'right' }}>
                  <span style={categoryText}>{kind.label}</span>
                </Col>
                <Col xs={1} style={{ textAlign: 'left' }}>
                  <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 10 }} title='Copier vers toutes catégories'>
                    <i className='fas fa-clone' style={{ fontSize: 13, cursor: 'pointer' }} onClick={() => props.handleCloneCategory(props.title, kind._id)} />
                  </Tooltip>
                </Col>
                <Col xs={4}>
                  <PriceBox
                    name={`${props.name}${kind.key}pub`}
                    value={mappedRepairCost[`${kind.key}pub`]}
                    valueMax={mappedRepairCost[`${kind.key}pubMax`]}
                    entity={name}
                    kindVehicle={kind._id}
                    handleIntervalCheckbox={props.handleIntervalCheckbox}
                    handlePriceUpdate={props.handlePriceUpdate}
                    isInterval={!!mappedRepairCost[`${kind.key}pubMax`]}
                  />
                </Col>
                <Col xs={4}>
                  <PriceBox
                    name={`${props.name}${kind.key}priv`}
                    value={mappedRepairCost[`${kind.key}priv`]}
                    valueMax={mappedRepairCost[`${kind.key}privMax`]}
                    entity={name}
                    kindVehicle={kind._id}
                    handleIntervalCheckbox={props.handleIntervalCheckbox}
                    handlePriceUpdate={props.handlePriceUpdate}
                    isInterval={!!mappedRepairCost[`${kind.key}privMax`]}
                    isPrivate
                  />
                </Col>
              </Row>
            ))}
            <Row
              xs={12}
              style={{
                marginTop: 10,
                textAlign: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: 2,
                borderTopColor: '#fff',
              }}>
              <Col xs={12}>
                {props.position > 0 && (
                  <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 10 }} title='Copier prix vers le haut'>
                    <i
                      className='fas fa-file-upload'
                      style={{
                        fontSize: 22,
                        padding: 5,
                        marginTop: 15,
                        cursor: 'pointer',
                      }}
                      onClick={() => props.handleCopyToUpDown(props.title, props.position, 'up')}
                    />
                  </Tooltip>
                )}
                {props.isLastPosition === false && (
                  <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 10 }} title='Copier prix vers le bas'>
                    <i
                      className='fas fa-file-download'
                      style={{
                        fontSize: 22,
                        padding: 5,
                        marginTop: 15,
                        cursor: 'pointer',
                      }}
                      onClick={() => props.handleCopyToUpDown(props.title, props.position, 'down')}
                    />
                  </Tooltip>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

PriceTable.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  position: PropTypes.number,
  isLastPosition: PropTypes.bool,
  priceTable: PropTypes.object,
  kindVehicleMap: PropTypes.object,
  // isActivated: PropTypes.bool,
  handleIntervalCheckbox: PropTypes.func,
  handlePriceUpdate: PropTypes.func,
  handleActivationToggle: PropTypes.func,
  handleCopyToUpDown: PropTypes.func,
  handleCloneCategory: PropTypes.func,
  fullReport: PropTypes.object,
  fullRepairCost: PropTypes.object,
  isGlobalMsa: PropTypes.bool,
  fullRepairCostGlobalMsa: PropTypes.array,
};
export default PriceTable;
