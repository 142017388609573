import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const Admin = ({ activeSession, isLogged, component, user, ressource, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      activeSession &&
      (isLogged || localStorage.getItem('user')) &&
      user &&
      user.isAdmin &&
      (user?.accessRole?.allowedRessources?.map((allowedRessourceValue) => allowedRessourceValue.key) || []).includes(ressource) ? (
        React.createElement(component, { ...props, isLogged })
      ) : (
        <Redirect to='/login' />
      )
    }
  />
);

Admin.propTypes = {
  activeSession: PropTypes.bool,
  ressource: PropTypes.string,
  isLogged: PropTypes.bool,
  user: PropTypes.object,
  component: PropTypes.object,
};

export default Admin;
