import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import UsersActions from '../../../actions/UsersActions';

class ChangeInfosConcession extends React.Component {
  state = {};

  componentDidMount() {
    this.props.dispatch(UsersActions.getActionToDispatch({ type: 'SET_USER_TO_EDIT' }));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.application.showModalEditInfos && !this.props.application.showModalEditInfos) {
      this.props.dispatch(UsersActions.getActionToDispatch({ type: 'SET_USER_TO_EDIT' }));
    }
  }

  updateUser = (e) => {
    e.preventDefault();
    const { userEdited, user } = this.props.application;

    UsersActions.updateUser({
      _id: user._id,
      firstname: userEdited.firstname,
      lastname: userEdited.lastname,
      phone: userEdited.phone,
    })(this.props.dispatch);
  };

  fillUserToEdit = (type, e) => {
    const params = {};
    params[type] = e.target.value;
    this.props.dispatch(
      UsersActions.getActionToDispatch({
        type: 'SET_DATA_TO_EDIT',
        data: params,
      }),
    );
  };

  closeModalEditInfos = () => {
    this.props.dispatch(UsersActions.getActionToDispatch({ type: 'RESET_EDIT_INFOS' }));
    this.props.dispatch(UsersActions.getActionToDispatch({ type: 'HIDE_MODAL_EDIT_INFOS' }));
  };

  renderError() {
    if (this.props.application.updateUserError) {
      return (
        <div className='error'>
          <p>{this.props.application.updateUserError}</p>
        </div>
      );
    }
    return null;
  }

  render() {
    const { userEdited } = this.props.application;
    const { user } = this.props.application;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={this.props.application.showModalEditInfos}
        onRequestClose={this.closeModalEditInfos}
        contentLabel='Modal edition des informations'
        overlayClassName='modal-overlay'
        className='modal modalEditUserInfos'>
        <div className='modalContent'>
          <div className='mainTitle'>Modification de vos informations</div>
          <form className='mt10px' onSubmit={this.updateUser.bind(this)}>
            <div className='formInput'>
              <label>Email (non modifiable)</label>
              <input type='email' value={user.email} disabled placeholder='Votre email' />
            </div>
            <div className='formInput'>
              <label>Nom</label>
              <input type='text' value={userEdited.lastname} placeholder='Votre nom' onChange={this.fillUserToEdit.bind(this, 'lastname')} />
            </div>
            <div className='formInput'>
              <label>Prénom(s)</label>
              <input type='text' value={userEdited.firstname} placeholder='Prénom(s)' onChange={this.fillUserToEdit.bind(this, 'firstname')} />
            </div>
            <div className='formInput'>
              <label>Téléphone</label>
              <input type='text' value={userEdited.phone} placeholder='Ex : 06125637623' onChange={this.fillUserToEdit.bind(this, 'phone')} />
            </div>
            {this.renderError()}
            <div className='center formButton'>
              <button type='button' onClick={this.closeModalEditInfos} className='negativ'>
                Annuler
              </button>
              <button type='submit' className='positiv' disabled={this.props.application.loadingUpdate}>
                {this.props.application.loadingUpdate ? (
                  <span>
                    <i className='fa fa-spinner fa-pulse'></i> modification
                  </span>
                ) : (
                  'Valider'
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

ChangeInfosConcession.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
};

export default ChangeInfosConcession;
