/* eslint-disable class-methods-use-this */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ConcessionsActions from '../../../actions/Concessions';
import NavigationActions from '../../../actions/NavigationActions';
import NavBar from '../../shared/templates/NavBar';
import Table from '../shared/templates/Table';
import { createUsersOptions } from './atoms/gridOptions';
import ModalCreateUser from './modals/createUser';
import ModalEditUser from './modals/editConcessionUser';
import ModalResetPasswordUser from './modals/resetPwdUser';
import ModalViewUser from './modals/viewUser';

class Accounts extends React.Component {
  state = {};

  componentDidMount() {
    const { dispatch } = this.props;

    this.setState({ user: this.props.application.user });
    this.props.dispatch(NavigationActions.setNavigation({ navigation: 'users' }));
    dispatch(ConcessionsActions.getActionToDispatch({ type: 'UPDATE_SEARCH', search: '' }));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(ConcessionsActions.getActionToDispatch({ type: 'UPDATE_SEARCH', search: '' }));
  }

  changeOption = (option) => {
    this.setState({ option });
  };

  renderModals = () => {
    const { dispatch, application, concessions } = this.props;
    const myProps = {
      dispatch,
      application,
      concessions,
    };
    const { modalName } = concessions;

    switch (modalName) {
      case 'createConcessionUser':
        return <ModalCreateUser {...myProps} />;
      case 'editConcessionUser':
        return <ModalEditUser {...myProps} />;
      case 'resetPwdUser':
        return <ModalResetPasswordUser {...myProps} />;
      case 'viewConcessionUser':
        return <ModalViewUser {...myProps} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div>
        {this.renderModals()}
        <NavBar />
        <div className='concession mtp'>
          <Table table={'users'} options={createUsersOptions()} />
        </div>
      </div>
    );
  }
}

Accounts.defaultProps = {};

const mapStateToProps = (state) => ({
  application: state.application,
  concessions: state.concessions,
});

Accounts.propTypes = {
  dispatch: PropTypes.func,
  concessions: PropTypes.object,
  application: PropTypes.object,
};

export default connect(mapStateToProps)(Accounts);
