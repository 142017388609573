import PropTypes from 'prop-types';
import React from 'react';

const RenderError = ({ error }) => {
  if (!error) return null;
  return (
    <div dangerouslySetInnerHTML={{ __html: error }} className='error'>
    </div>
  );
};

RenderError.propTypes = {
  error: PropTypes.any,
};

export default RenderError;
