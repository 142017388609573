import * as actions from '../actions/CountriesActions';

const initialState = {
  countries: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_COUNTRIES: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case actions.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        countries: [...action.countries],
      };
    }
    case actions.GET_COUNTRIES_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
