import { Checkbox, Input, Tooltip } from 'antd';
import arrayMove from 'array-move';
import produce from 'immer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import Select from 'react-select';
import ReportsActions from '../../../../actions/ReportsV3Actions';
import c from '../../../../constants';
import ReportServices from '../../../../services/ReportsService';
import DragDropList from '../components/dragDropList';
import DragDropSections from '../components/dragDropSections';
import MsaElementList from '../components/msaElementList';
import DefectTypologiesSelect from '../organisms/DefectTypologiesSelect';

Modal.setAppElement('body');
const { TextArea } = Input;
const endSignatureOptions = [
  { value: '', label: 'Pas de signature de fin de rapport' },
  { value: 'showSummary', label: 'Signature fin d’inspection pour accord' },
  { value: 'showSingleInspectorSignature', label: 'Signature fin d’inspection pour l’inspecteur seul' },
  { value: 'repairCostEstimateClientValidation', label: 'Signature fin d’inspection avec accord client sur rapport chiffré' },
];
class ModalCreateReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formList: [],
      cpList: [],
      cpLoading: false,
      title: '',
      sections: [],
      chosenSection: 0,
      reportSection: {},
      typologies: [],
      specialistSkillsList: [],
      typoSelectedId: '',
      editingSectionTitle: false,
      inspectionDurationMinutes: 0,
      selectedSkills: [],
      userAdditionalReportInformation: '',
      selectedEndSignature: [],
      inspectionProgressDescription: '',
    };
  }

  cpLoading = false;

  cpPage = 0;

  totalPages = 1;

  componentDidMount() {
    ReportServices.getAll(c.URI.v3.sortedTypologies).then((res) => this.setState({ typologies: res.typologies || [] }));
    ReportServices.getAll(c.URI.v3.specialistSkill).then((res) => this.setState({ specialistSkillsList: res || [] }));
    if (this.props.isEdit) {
      const { modalData } = this.props.reportsV3;

      this.setState({
        title: _.get(modalData, 'title'),
        description: _.get(modalData, 'description'),
        userAdditionalReportInformation: _.get(modalData, 'userAdditionalReportInformation'),
        sections: _.get(modalData, 'sections'),
        typoSelectedId: _.get(modalData, 'defectTypology'),
        inspectionDurationMinutes: {
          label: `${_.get(modalData, 'inspectionDurationMinutes', 60)} minutes`,
          value: _.get(modalData, 'inspectionDurationMinutes', 60),
        },
        selectedEndSignature: endSignatureOptions.map((option) => {
          if (modalData[option.value] === true) {
            return option;
          }
          return null;
        }),
        selectedSkills: _.get(modalData, 'skills', []).map((skill) => ({
          label: skill.label,
          value: skill._id,
        })),
        reportSection: {
          value: 0,
          label: `1 - ${_.get(modalData, 'sections[0].sectionName')}`,
        },
        showSummary: _.get(modalData, 'showSummary', false),
        showRules: _.get(modalData, 'showRules', false),
        showSingleInspectorSignature: _.get(modalData, 'showSingleInspectorSignature', false),
        repairCostEstimateClientValidation: _.get(modalData, 'repairCostEstimateClientValidation', false),
        inspectionProgressDescription: _.get(modalData, 'inspectionProgressDescription'),
      });
    }
  }

  onChange = (e, isCheckbox = false) => {
    const { target } = e;
    const { name, value, checked } = target;

    if (isCheckbox) {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
  };

  onChangePhotoScan = (e) => {
    const { target } = e;
    const { value, checked } = target;

    const nextSections = produce(this.state.sections, (draftState) => {
      draftState[this.state.chosenSection].photoScan = value || checked;
    });
    this.setState({ sections: nextSections });
  };

  handleSelect = (selected, obj) => {
    if (obj?.name === 'category') {
      this.setState({ subCategory: null });
      this.setState({ subCategoryOptions: [] });
      if (selected) {
        const newSubcategories = _.map(_.cloneDeep(selected.subCategories), (sub) => ({ ...sub, value: sub.key }));
        this.setState({ subCategoryOptions: newSubcategories });
      }
    }

    this.setState({ [obj?.name]: selected });
    if (obj?.name === 'reportSection') this.setState({ chosenSection: selected.value });
    if (obj?.name === 'category' || obj?.name === 'subCategory') setTimeout(this.forceListUpdate, 10);

    if (endSignatureOptions.includes(selected)) {
      this.setState({ selectedEndSignature: selected });
      this.setState({ [selected.value]: true });

      endSignatureOptions.forEach((option) => {
        if (selected !== option) {
          this.setState({ [option.value]: false });
        }
      });
    }
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(ReportsActions.hideReportsV3Modal());
  };

  submit = async (e) => {
    e.preventDefault();

    const {
      title,
      description,
      sections,
      showSummary,
      showRules,
      showSingleInspectorSignature,
      repairCostEstimateClientValidation,
      typoSelectedId,
      inspectionDurationMinutes,
      selectedSkills,
      userAdditionalReportInformation,
      inspectionProgressDescription,
    } = this.state;

    const data = {
      title,
      description,
      sections,
      showSummary,
      showRules,
      showSingleInspectorSignature,
      repairCostEstimateClientValidation,
      userAdditionalReportInformation,
      inspectionDurationMinutes: inspectionDurationMinutes.value,
      skills: selectedSkills.map((skill) => skill.value),
      defectTypology: typoSelectedId,
      inspectionProgressDescription,
    };
    this.setState({ isLoading: true });
    if (!data.defectTypology) {
      this.setState({ error: 'Champs obligatoire : veuillez selectionner une typologie de défauts' });
    } else if (this.props.isEdit === true) {
      try {
        await ReportServices.put(c.URI.v3.inspection.concat(this.props.reportsV3.modalData._id), data);
        this.props.dispatch(ReportsActions.executeAction('reports', { pageNumber: this.props.reportsV3.pageNumber }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    } else {
      try {
        await ReportServices.post(c.URI.v3.inspection, data);
        this.props.dispatch(ReportsActions.executeAction('reports', { pageNumber: this.props.reportsV3.pageNumber }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    }

    this.setState({ isLoading: false });
  };

  handleDeleteSection = (e, index) => {
    e.preventDefault();
    if (this.state.sections.length > 1) {
      this.setState({ sections: this.state.sections.filter((section, i) => i !== index) }, () => {
        this.setState({
          chosenSection: 0,
          reportSection: {
            value: 0,
            label: `1 - ${_.get(this.state.sections, '[0].sectionName', '')}`,
          },
        });
      });
    }
  };

  handleAddSection = (e) => {
    e.preventDefault();
    this.setState(
      {
        sections: [...this.state.sections, { sectionName: 'Nouvelle Section', checkpoints: [] }],
      },
      () => {
        this.setState({
          chosenSection: this.state.sections.length - 1,
          reportSection: {
            value: this.state.sections.length - 1,
            label: 'Nouvelle Section',
          },
          editingSectionTitle: !this.state.editingSectionTitle,
        });
      },
    );
  };

  toggleEditSectionTitle = (e) => {
    e.preventDefault();
    this.setState({ editingSectionTitle: !this.state.editingSectionTitle });
  };

  handleEditSectionTitle = (e) => {
    const { target } = e;
    const { value } = target;

    const nextSections = produce(this.state.sections, (draftState) => {
      draftState[this.state.chosenSection].sectionName = value;
    });
    this.setState({ sections: nextSections }, () => {
      this.setState({
        reportSection: {
          value: this.state.reportSection.value,
          label: `${this.state.reportSection.value + 1} - ${value}`,
        },
      });
    });
  };

  handleSwitchSection = (index) => {
    this.setState({ chosenSection: index }, () => {
      this.setState({
        reportSection: {
          value: index,
          label: `${index + 1} - ${this.state.sections[this.state.chosenSection].sectionName}`,
        },
      });
    });
  };

  handleMoveSections = ({ oldIndex, newIndex }) => {
    const nextSectionsState = produce(this.state.sections, (draftState) => arrayMove(draftState, oldIndex, newIndex));

    this.setState({ sections: nextSectionsState }, () => {
      this.setState({ chosenSection: newIndex }, () => {
        this.setState({
          reportSection: {
            value: newIndex,
            label: `${newIndex + 1} - ${this.state.sections[this.state.chosenSection].sectionName}`,
          },
        });
      });
    });
  };

  handleDeleteItem = (e, sortIndex) => {
    e.preventDefault();
    const nextSections = produce(this.state.sections, (draftState) => {
      draftState[this.state.chosenSection].checkpoints.splice(sortIndex, 1);
    });
    this.setState({ sections: nextSections });
  };

  handleRequiredItem = (e, sortIndex) => {
    const { target } = e;
    const { name, checked } = target;

    const nextSections = produce(this.state.sections, (draftState) => {
      draftState[this.state.chosenSection].checkpoints[sortIndex][name] = checked;
    });
    this.setState({ sections: nextSections });
  };

  hideCheckpointItem = (value, sortIndex) => {
    const nextSections = produce(this.state.sections, (draftState) => {
      draftState[this.state.chosenSection].checkpoints[sortIndex].hideInViewer = value;
    });
    this.setState({ sections: nextSections });
  };

  handleAddItem = (e, item) => {
    e.preventDefault();
    const nextSections = produce(this.state.sections, (draftState) => {
      draftState[this.state.chosenSection].checkpoints.push({ refCheckpoint: item });
    });
    this.setState({ sections: nextSections });
  };

  /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draftState"] }] */
  onSortEnd = ({ oldIndex, newIndex }) => {
    const nextSectionsState = produce(this.state.sections, (draftState) => {
      draftState[this.state.chosenSection].checkpoints = arrayMove(draftState[this.state.chosenSection].checkpoints, oldIndex, newIndex);
    });
    this.setState({ sections: nextSectionsState });
  };

  loadCheckpoints = async () => {
    const { search, category, subCategory } = this.state;

    this.cpLoading = true;
    const pages = await ReportServices.getAll(c.URI.v3.checkpoints, {
      pageNumber: this.cpPage > 0 ? this.cpPage : 1,
      searchTerm: search,
      category: _.get(category, '_id'),
      subCategory: _.get(subCategory, '_id'),
    });

    this.cpLoading = false;
    if (pages && pages.totalPages) this.totalPages = pages.totalPages;
    if (pages && pages.items && pages.items.length > 0) {
      this.setState({ cpList: [...this.state.cpList, ...pages.items] });
    }
  };

  // hacky solution to force update on stateless component
  forceListUpdate = () => {
    this.totalPages = 1;
    this.cpPage = 0;
    this.setState({ cpList: [{ label: '...' }] });
    this.setState({ cpList: [] });
  };

  handleSearch = (e) => {
    const { target } = e;
    const { value } = target;

    this.setState({ search: value });
    this.forceListUpdate();
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }

    return null;
  };

  renderValidButtonText = () => {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse' /> Sauvegarde en cours.
        </span>
      );
    }
    return (
      <button type='button' onClick={this.submit} className='positiv' disabled={isLoading}>
        Sauvegarder
      </button>
    );
  };

  render() {
    const { isAdmin, role } = this.props.application.user;
    const userCanEdit = isAdmin === true || role === 'superChief';

    const sectionItems = this.state.sections[this.state.chosenSection] ? this.state.sections[this.state.chosenSection] : [];
    const dragDropSectionsItems = [];
    _.times(this.state.sections.length, (n) => dragDropSectionsItems.push(n));

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Créer ou modifier un Rapport'
        overlayClassName='modal-overlay'
        className='modal modalReport'
        style={!userCanEdit ? { content: { cursor: 'not-allowed' } } : {}}
      >
        <Col className='modalContent' style={!userCanEdit ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
          <div className='mainTitle'>
            {' '}
            <span className='modalFontBig'>Constructeur de rapport</span>
          </div>
          <form className='mt10px modalFont'>
            <Row xs={12} style={{ marginBottom: 5 }}>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Nom du rapport</label>
                  <input type='text' name='title' value={this.state.title} placeholder='Nom du rapport' onChange={this.onChange} style={{ minHeight: 38, borderRadius: 8 }} />
                </div>
                <div className='formInput'>
                  <label>Description</label>
                  <input
                    type='text'
                    name='description'
                    value={this.state.description}
                    placeholder='Description'
                    onChange={this.onChange}
                    style={{ minHeight: 38, borderRadius: 8 }}
                  />
                </div>
                <div className='formInput'>
                  <label>Aide spécialiste / utilisateur</label>
                  <TextArea
                    name='userAdditionalReportInformation'
                    value={this.state.userAdditionalReportInformation}
                    onChange={this.onChange}
                    placeholder='Ajouter une info complémentaire pour le spécialiste'
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    style={{ borderRadius: 8, width: '90%', maxWidth: '900px' }}
                  />
                </div>
              </Col>
              <Col xs={6}>
                <Row>
                  <Col xs={6}>
                    <div className='formInput'>
                      <label>Typologie de défauts</label>
                      <DefectTypologiesSelect
                        typologies={this.state.typologies}
                        defaultValue={this.state.typoSelectedId}
                        onSelect={(selection) => this.setState({ typoSelectedId: selection })}
                        isDisabled={!isAdmin}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className='formInput'>
                      <label>Durée indicative de l'inspection</label>
                      <Select
                        placeholder='Durée indicative'
                        className='multi-select cypress__indicative-duration'
                        value={this.state.inspectionDurationMinutes}
                        onChange={this.handleSelect}
                        options={_.map(
                          [...Array(24).keys()].map((x) => (x + 1) * 5),
                          (obj) => ({
                            label: `${obj} minutes`,
                            value: obj,
                          }),
                        )}
                        isMulti={false}
                        name='inspectionDurationMinutes'
                        style={{ zIndex: 99999999, width: 450 }}
                        isClearable
                        isDisabled={!isAdmin}
                      />
                    </div>
                  </Col>
                </Row>
                {isAdmin ? (
                  <Row>
                    <Col xs={12}>
                      <div className='formInput'>
                        <label>Compétences requises</label>
                        <Select
                          placeholder='Aucune'
                          className='multi-select cypress__required-skills'
                          value={this.state.selectedSkills}
                          onChange={this.handleSelect}
                          options={_.map(this.state.specialistSkillsList, (obj) => ({
                            label: obj.label,
                            value: obj._id,
                          }))}
                          isMulti={true}
                          name='selectedSkills'
                          style={{ zIndex: 99999999, width: 450 }}
                          isClearable
                        />
                      </div>
                    </Col>
                  </Row>
                ) : null}
                <Col xs={12}>
                  <Row>
                    <Col xs={6}>
                      <div className='formInput'>
                        <label>Signature début d'inspection</label>
                        <div style={{ display: 'flex' }}>
                          <div data-cy='startInspectionSignature'>
                            <Checkbox
                              style={{ marginRight: 10, marginBottom: 10 }}
                              checked={this.state.showRules}
                              name='showRules'
                              disabled={!isAdmin}
                              onChange={(e) => this.onChange(e, true)}
                            />
                          </div>
                          <p>Signature début d’inspection (instructions spécialiste, réservé MSA)</p>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className='formInput'>
                        <label data-cy='endInspectionSignature'>Signature fin d'inspection</label>

                        <Select
                          isMulti={false}
                          style={{ zIndex: 99999999, width: 450 }}
                          placeholder={endSignatureOptions[0].label}
                          className='multi-select'
                          value={this.state.selectedEndSignature}
                          defaultValue={endSignatureOptions[0]}
                          name='endOfReportSignature'
                          onChange={this.handleSelect}
                          options={endSignatureOptions}
                          isClearable
                          isDisabled={!isAdmin}
                        />
                      </div>
                    </Col>
                    {this.state.showRules && (
                      <Col xs={12}>
                        <div className='formInput'>
                          <label>Description du déroulement de l'inspection</label>
                          <TextArea
                            name='inspectionProgressDescription'
                            value={this.state.inspectionProgressDescription}
                            onChange={this.onChange}
                            placeholder="Décrivez le déroulement de l'inspection"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            style={{ borderRadius: 8, width: '90%', maxWidth: '900px' }}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Col>
            </Row>
            <Row xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Points de contrôle disponibles</label>
                </div>
                <div style={{ maxWidth: '92%' }}>
                  <div style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderColor: 'hsl(0,0%,80%)',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 5,
                      }}
                    >
                      <div style={{ fontSize: 13, marginLeft: 5, width: '90%' }}>
                        <i className='fa fa-search' />
                        <DebounceInput
                          data-cy='searchControlPoints'
                          style={{
                            borderWidth: 0,
                            height: 35,
                            marginLeft: 10,
                            marginRight: 10,
                            width: '90%',
                          }}
                          type='text'
                          name='search'
                          debounceTimeout={300}
                          value={this.state.search}
                          onChange={this.handleSearch}
                          placeholder='Rechercher...'
                        />
                      </div>
                    </div>
                    <div className='search-input'>
                      <Select
                        placeholder='Catégorie'
                        className='multi-select'
                        value={this.state.category}
                        onChange={this.handleSelect}
                        options={_.map(this.props.reportsV3.constants.category, (obj) => ({
                          ...obj,
                          value: obj.key,
                        }))}
                        isMulti={false}
                        name={'category'}
                        style={{ zIndex: 99999999, width: 450 }}
                        isClearable
                      />
                    </div>
                    <div className='search-input'>
                      <Select
                        placeholder='Sous-catégorie'
                        className='multi-select'
                        value={this.state.subCategory}
                        onChange={this.handleSelect}
                        options={this.state.subCategoryOptions}
                        isMulti={false}
                        style={{ zIndex: 99999999, width: 450 }}
                        name={'subCategory'}
                        isClearable
                      />
                    </div>
                  </div>
                </div>
                <MsaElementList
                  list={this.state.cpList}
                  type='checkpoint'
                  loadNextPage={() => {
                    this.cpPage += 1;
                    this.loadCheckpoints();
                  }}
                  hasNextPage={this.cpPage < this.totalPages}
                  isNextPageLoading={this.cpLoading}
                  handleAddItem={this.handleAddItem}
                />
              </Col>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Simulateur de rapport d'inspection</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderWidth: 0,
                  }}
                >
                  {this.state.editingSectionTitle ? (
                    <input
                      type='text'
                      name='sectionTitle'
                      data-cy='sectionTitle'
                      value={this.state.sections[this.state.chosenSection].sectionName}
                      onChange={this.handleEditSectionTitle}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          this.toggleEditSectionTitle(event);
                        }
                      }}
                      style={{
                        width: '85%',
                        height: 35,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderStyle: 'solid',
                      }}
                    />
                  ) : (
                    <Select
                      placeholder='Section'
                      className='multi-select cypress__inspection-report-simulator'
                      value={this.state.reportSection}
                      onChange={this.handleSelect}
                      options={_.map(this.state.sections, (obj, index) => ({
                        value: index,
                        label: `${index + 1} - ${_.get(obj, 'sectionName')}`,
                      }))}
                      isMulti={false}
                      style={{ zIndex: 99999999 }}
                      name={'reportSection'}
                    />
                  )}
                  {this.state.sections.length > 1 && !this.state.editingSectionTitle && (
                    <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 1300 }} title='Supprimer Section du Rapport'>
                      <button className='btnIcon delete' onClick={(e) => this.handleDeleteSection(e, this.state.chosenSection)}>
                        <i className='fa fa-trash-alt' />
                      </button>
                    </Tooltip>
                  )}
                  {this.state.sections && this.state.sections.length > 0 && (
                    <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 1300 }} title='Changer Titre de la Section'>
                      <button className='btnIcon add' onClick={this.toggleEditSectionTitle}>
                        {this.state.editingSectionTitle ? <i data-cy='confirmSectionName' className='fas fa-check' /> : <i data-cy='editSectionName' className='fas fa-edit' />}
                      </button>
                    </Tooltip>
                  )}
                  {!this.state.editingSectionTitle && (
                    <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 1300 }} title='Ajouter une Section au Rapport'>
                      <button
                        className='btnIcon add'
                        onClick={this.handleAddSection}
                        // title={'Ajouter une Section du Rapport'}
                      >
                        <i data-cy='addReportSection' className='fas fa-plus-square' />
                      </button>
                    </Tooltip>
                  )}
                </div>
                <DragDropSections
                  onSortEnd={this.handleMoveSections}
                  sections={dragDropSectionsItems}
                  selectedIndex={this.state.chosenSection}
                  handleChangeSections={this.handleSwitchSection}
                />
                <div className='formInput' style={{ display: 'flex', marginTop: 18, alignItems: 'center' }}>
                  {this.state.sections.length > 0 && !this.state.editingSectionTitle && (
                    <div className='checkBoxAndTitle'>
                      <Tooltip mouseEnterDelay={1.0} overlayStyle={{ zIndex: 1300 }} title='Afficher les photos de cette section en grand'>
                        <Checkbox checked={_.get(this.state.sections[this.state.chosenSection], 'photoScan', false)} name='photoScan' onChange={this.onChangePhotoScan} />
                      </Tooltip>
                      <label style={{ marginLeft: 5, paddingTop: '3px' }}> Afficher les photos de cette section en grand</label>
                    </div>
                  )}
                </div>
                <div className='formInput' style={{ marginTop: 15 }}>
                  <label>Points de contrôle de l'onglet {this.state.chosenSection + 1}</label>
                </div>
                <DragDropList
                  sectionItems={sectionItems}
                  handleDeleteItem={this.handleDeleteItem}
                  handleRequiredItem={this.handleRequiredItem}
                  hideCheckpointItem={this.hideCheckpointItem}
                  onSortEnd={this.onSortEnd}
                  isReport
                />
              </Col>
            </Row>
            {this.renderError()}
          </form>
        </Col>
        <div className='center formButton'>
          <button type='button' onClick={this.closeModal} className='negativ'>
            {userCanEdit ? 'Annuler' : 'Fermer'}
          </button>
          {userCanEdit && this.renderValidButtonText()}
        </div>
      </Modal>
    );
  }
}

ModalCreateReport.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  reportsV3: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default ModalCreateReport;
