import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Text from '../../../shared/atoms/Text';

import ConcessionsActions from '../../../../actions/Concessions';
// import ConcessionsServices from '../../../../services/ConcessionsServices';
import UsersServices from '../../../../services/UsersServices';

Modal.setAppElement('body');

class ModalResetPasswordUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  closeModal = () => {
    this.props.dispatch(ConcessionsActions.getActionToDispatch({ type: 'HIDE_MODAL' }));
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  renderValidButtonText = () => {
    const { isLoading } = this.props.concessions;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Réinitialisation en cours.
        </span>
      );
    }
    return 'Réinitialisation';
  };

  submit = async (e) => {
    e.preventDefault();

    const { email } = this.props.concessions.modalData;
    const resetStatus = await UsersServices.resetPassword({ email });

    if (resetStatus && resetStatus.status < 400) {
      this.closeModal();
    } else {
      this.setState({ error: 'Une erreur est survenue' });
    }
  };

  render() {
    const { concessions } = this.props;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Modal annuler inspection'
        overlayClassName='modal-overlay'
        className='modal modalConcession'>
        <div className='modalContent'>
          <div className='mainTitle'>Confirmation de réinitialisation</div>
          <Text className='center' fontStyle='normal'>
            Un mail sera envoyé pour la réinitialisation du mot de passe
          </Text>
          <form className='mt10px' onSubmit={this.submit}>
            {/* {this.renderError()} */}
            <div className='center formButton'>
              <button type='button' onClick={this.closeModal} className='negativ'>
                Annuler
              </button>
              <button type='submit' className='positiv' disabled={concessions.isLoading}>
                {this.renderValidButtonText()}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

ModalResetPasswordUser.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
};

export default ModalResetPasswordUser;
