import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import ReportServices from '../../../../services/ReportsService';
import constants from '../../../../constants';

export default class SettingsEditor extends React.Component {
  state = {
    mobileTargetEnvironment: null,
    mobileEnv: null,
    formerSpecialist: null,
  };

  async getEnv() {
    // Get all mobile environments
    if (!this.state.mobileEnv) {
      const mobileEnv = await ReportServices.getAll(`${constants.URI.mobileEnvironments}`);
      this.setState({ mobileEnv });
    }

    if (this.state.mobileEnv) {
      const mobileEnvOptions = _.map(this.state.mobileEnv, (env) => ({
        label: env.env,
        value: {
          ios: _.get(_.find(env.keys, ['target', 'ios']), 'key'),
          android: _.get(_.find(env.keys, ['target', 'android']), 'key'),
        },
      }));
      this.setState({ mobileEnvOptions });
      // if user already has a mobile environment set it to state
      let { mobileTargetEnvironment } = this.props;
      if (mobileTargetEnvironment) {
        mobileTargetEnvironment = _.find(mobileEnvOptions, ['value.ios', mobileTargetEnvironment.ios]);
        this.setState({ mobileTargetEnvironment });
      }
    }
  }

  componentDidUpdate() {
    if (this.props._id && this.state.formerSpecialist !== this.props._id) {
      this.getEnv();
      this.setState({ formerSpecialist: this.props._id });
    }
  }

  onSelect = (selection, obj) => {
    const { name } = obj;
    this.setState({ [name]: selection });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.isEditing ? (
          <div>
            <ul>
              <li className='full'>
                <div className='formInput'>
                  <label style={{ color: '#b5b5b5' }}>User Mobile Environment</label>
                  <Select
                    placeholder={'Choisissez un Environment'}
                    className='multi-select'
                    value={this.state.mobileTargetEnvironment}
                    onChange={this.onSelect}
                    options={this.state.mobileEnvOptions}
                    name={'mobileTargetEnvironment'}
                    isClearable
                  />
                </div>
              </li>
            </ul>
            <div className='specialistFormButton'>
              <button className='specialistFormButtonCancel' onClick={() => this.props.cancel()}>
                annuler
              </button>
              <button
                className='specialistFormButtonSubmit'
                onClick={async () => {
                  this.props.save({ mobileTargetEnvironment: _.get(this.state, 'mobileTargetEnvironment.value', {}) });
                }}>
                sauvegarder
              </button>
            </div>
          </div>
        ) : (
          <div className='specialistFicheComment'>
            <label style={{ color: '#b5b5b5' }}>User Mobile Environment</label>
            <div>{_.get(this.state, 'mobileTargetEnvironment.label', "Pas d'environment")}</div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

SettingsEditor.propTypes = {
  mobileTargetEnvironment: PropTypes.object,
  _id: PropTypes.string,
  lastRefresh: PropTypes.number,
  cancel: PropTypes.func,
  save: PropTypes.func,
  isEditing: PropTypes.bool,
};
