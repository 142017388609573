import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Select from 'react-select';

const conditionsList = [
  { label: 'Inférieur', value: '<' },
  { label: 'Inférieur ou égal', value: '<=' },
  { label: 'Égal', value: '===' },
  { label: 'Différent', value: '!==' },
  { label: 'Supérieur ou égal', value: '=>' },
  { label: 'Supérieur', value: '>' },
];

const RuleCondition = (props) => {
  const { rulesList, handleDelete, handleAdd, handleChangeValue, specialTypes, handleSelect } = props;
  const optionSpecialTypes = _.map(specialTypes, (obj) => ({
    value: obj.key,
    label: obj.label,
    _id: obj._id,
  }));
  return (
    <React.Fragment>
      <Row>
        <Col xs={3} className='formInput' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label>Conditions</label>
          <button className='btnIcon add' onClick={handleAdd}>
            Ajouter <i className='fas fa-plus-square' />
          </button>
        </Col>
      </Row>
      <Row className='formInput'>
        <Col xs={4}>
          <label>Special Type</label>
        </Col>
        <Col xs={3}>
          <label>Condition</label>
        </Col>
        <Col xs={5}>
          <label>Valeur</label>
        </Col>
      </Row>
      {_.map(rulesList, (rule, index) => (
        <Row className='formInput' key={`condition${index}`}>
          <Col xs={4}>
            <Select
              placeholder='Special Type'
              className='multi-select'
              value={rule.specialType}
              onChange={(e) => handleSelect(e, 'specialType', index)}
              options={optionSpecialTypes}
              isMulti={false}
              name={'specialType'}
              isClearable
            />
          </Col>
          <Col xs={3}>
            <Select
              placeholder='Condition'
              className='multi-select'
              value={rule.condition}
              onChange={(e) => handleSelect(e, 'condition', index)}
              options={conditionsList}
              isMulti={false}
              name={'condition'}
              isClearable
            />
          </Col>
          <Col xs={5} style={{ display: 'float ' }}>
            <input type='text' name='value' value={rule.value} placeholder='Valeur' style={{ maxWidth: 150 }} onChange={(e) => handleChangeValue(e, index)} />
            <button className='btnIcon delete' onClick={(e) => handleDelete(e, index)}>
              <i className='fas fa-trash-alt' />
            </button>
          </Col>
        </Row>
      ))}
    </React.Fragment>
  );
};

RuleCondition.propTypes = {
  rulesList: PropTypes.array,
  specialTypes: PropTypes.array,
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  handleSelect: PropTypes.func,
  handleChangeValue: PropTypes.func,
};

export default RuleCondition;
