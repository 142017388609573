import {
  Col, DatePicker, Row, Select, Tag, TreeSelect,
} from 'antd';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;
const { RangePicker } = DatePicker;

const MapFilters = ({
  inspectionsOrMissionsStatusNb,
  inspectionsOrMissionsFilteredNb,
  specialistStatusNb,
  specialistFilteredNb,
  inspectionsOrMissionsStatusSelected,
  filterInspectionsOrMissions,

  specialistsStatusSelected,
  filterSpecialists,
  clientList,
  departementList,
  skillsList,
  availabilities,

  inspectionsOrMissionsSearchEnter,
  specialistsSearchEnter,
  specialistDepartementSelected,
  specialistSkillsSelected,
  specialistDateSelected,
  inspectionsOrMissionsDateSelected,
  inspectionsOrMissionsClientConcessionSelected,
  specialistAvailabilitySelected,

  searchFilter,
  clientFilter,
  dateFilter,
  specialistDateFilter,
  specialistSkillsFilter,
  specialistDepartementFilter,
  specialistAvailabilityFilter,

  specialistKind,
}) => {
  const [searchTermSpecialist, setSearchTermSpecialist] = useState('');
  const [searchTermInspection, setSearchTermInspection] = useState('');
  const [searchTermMission, setSearchTermMission] = useState('');

  const filterInspectionHandler = (value) => {
    if (value.includes('all')) {
      const inspectionStatus = ['ordered', 'scheduled', 'done', 'canceled'];
      const missionStatus = ['ordered', 'ongoing', 'scheduled', 'done', 'canceled'];
      filterInspectionsOrMissions('status', specialistKind === 'inspect' ? inspectionStatus : missionStatus);
    } else {
      filterInspectionsOrMissions('status', value);
    }
  };

  const filterSpecialistHandler = (value) => {
    const filter = specialistKind === 'inspect' ? 'status' : 'stafferStatus';
    if (value.includes('all')) {
      filterSpecialists(filter, ['confirme', 'actif', 'non_confirme', 'nouveaux', 'bloques', 'inactifs']);
    } else {
      filterSpecialists(filter, value);
    }
  };

  const searchInspection = (elem) => {
    setSearchTermInspection(elem?.target?.value?.toLowerCase());
    searchFilter(elem?.target?.value?.toLowerCase(), 'inspection');
  };

  const searchMission = (elem) => {
    setSearchTermMission(elem?.target?.value?.toLowerCase());
    searchFilter(elem?.target?.value?.toLowerCase(), 'mission');
  };

  const searchSpecialist = (elem) => {
    setSearchTermSpecialist(elem?.target?.value?.toLowerCase());
    searchFilter(elem?.target?.value?.toLowerCase(), 'specialist');
  };

  const selectFilter = (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  const statusSelectFilter = (inputValue, option) => option.props.filter.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  // render tag for Select
  const tagRender = (tagProps) => {
    const {
      label, value, closable, onClose,
    } = tagProps;
    return (
      <Tag closable={closable} onClose={onClose} className={`mapButton ${value}`} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  };

  return (
    <div className='filterDiv'>
      <Row>
        <Col md={12}>
          <div className='font-title-blue'>
          {specialistKind === 'inspect' ? <label>Inspections</label> : <label>Missions</label>}
          </div>
        </Col>
        <Col md={12}>
          <div className='font-title-blue'>
            {specialistKind === 'inspect' ? <label>Inspecteurs</label> : <label>Staffeurs</label>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className='formInput'>
          {specialistKind === 'inspect' ? <label>Recherche inspection</label> : <label>Recherche missions</label>}
            <div className='search-input'>
              <span className='fa fa-search form-control-feedback' />
              <DebounceInput
                className='input-search'
                type='text'
                name='search'
                debounceTimeout={300}
                defaultValue={specialistKind === 'inspect' ? (searchTermInspection || inspectionsOrMissionsSearchEnter) : (searchTermMission || inspectionsOrMissionsSearchEnter)}
                onChange={(e) => {
                  if (specialistKind === 'inspect') {
                    searchInspection(e);
                  } else { searchMission(e); }
                }}
                placeholder='Client adresse groupe ...'
              />
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className='formInput'>
          {specialistKind === 'inspect' ? <label>Recherche inspecteur</label> : <label>Recherche staffeurs</label>}
            <div className='search-input'>
              <span className='fa fa-search form-control-feedback' />
              <DebounceInput
                className='input-search'
                type='text'
                name='search'
                debounceTimeout={300}
                defaultValue={searchTermSpecialist || specialistsSearchEnter}
                onChange={(e) => {
                  searchSpecialist(e);
                }}
                placeholder='Nom email adresse téléphone ...'
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <div className='formInput'>
                <label>Statut</label>
                <Select
                  showSearch
                  style={{ minWidth: '100%', fontWeight: 'bold' }}
                  mode='multiple'
                  placeholder='Filtrer par statut'
                  value={inspectionsOrMissionsStatusSelected}
                  onChange={filterInspectionHandler}
                  tagRender={tagRender}
                  filterOption={statusSelectFilter}
                  >
                  <Option className='mapButton ordered' key='ordered' value='ordered' filter='En attente'>
                    En attente ({inspectionsOrMissionsStatusNb.ordered})
                  </Option>
                  <Option className='mapButton scheduled' key='scheduled' value='scheduled' filter='Programmées'>
                    Programmées ({inspectionsOrMissionsStatusNb.scheduled})
                  </Option>
                 { specialistKind === 'staff'
                 && <Option className='mapButton ongoing' key='ongoing' value='ongoing' filter='En cours'>
                    En cours ({inspectionsOrMissionsStatusNb.ongoing})
                  </Option>
                  }
                  <Option className='mapButton done' key='done' value='done' filter='Réalisées'>
                    Réalisées ({inspectionsOrMissionsStatusNb.done})
                  </Option>
                  <Option className='mapButton canceled' key='canceled' value='canceled' filter='Annulées'>
                    Annulées MSA ({inspectionsOrMissionsStatusNb.canceled})
                  </Option>
                </Select>
              </div>
            </Col>
            <Col md={12}>
              <div className='formInput'>
                <label>Clients</label>
                <TreeSelect
                  style={{ width: '100%' }}
                  showSearch={false}
                  placeholder='Filtrer par groupe / site'
                  allowClear
                  multiple
                  treeCheckable
                  showCheckedStrategy={SHOW_PARENT}
                  treeData={clientList}
                  onChange={clientFilter}
                  value={inspectionsOrMissionsClientConcessionSelected}
                  dropdownMatchSelectWidth={false}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <div className='formInput'>
                <label>Statut</label>
                <Select
                dropdownMatchSelectWidth={false}
                  allowClear={true}
                  showSearch
                  mode='multiple'
                  style={{ width: '100%' }}
                  placeholder='Filtrer par statut'
                  value={specialistsStatusSelected}
                  onChange={filterSpecialistHandler}
                  tagRender={tagRender}
                  filterOption={statusSelectFilter}>
                  <Option className='mapButton' key='all' value='all' filter='Tout'>
                    Tout ({specialistStatusNb.all})
                  </Option>
                  <Option className='mapButton actif' key='actif' value='actif' filter='Actifs'>
                    Actifs ({specialistStatusNb.actif})
                  </Option>
                  <Option className='mapButton confirme' key='confirme' value='confirme' filter='Confirmés'>
                    Confirmés ({specialistStatusNb.confirme})
                  </Option>
                  <Option className='mapButton non_confirme' key='non_confirme' value='non_confirme' filter='Non confirmés'>
                    Non confirmés ({specialistStatusNb.non_confirme})
                  </Option>
                  <Option className='mapButton nouveaux' key='nouveaux' value='nouveaux' filter='Nouveaux'>
                    Nouveaux ({specialistStatusNb.nouveaux})
                  </Option>
                  <Option className='mapButton bloques' key='bloques' value='bloques' filter='Bloqués'>
                    Bloqués ({specialistStatusNb.bloques})
                  </Option>
                  <Option className='mapButton inactifs' key='inactifs' value='inactifs' filter='Inactifs'>
                    Inactifs ({specialistStatusNb.inactifs})
                  </Option>
                </Select>
              </div>
            </Col>
            <Col md={12}>
              <div className='formInput'>
                <label>Département</label>
                <Select
                dropdownMatchSelectWidth={false}
                  allowClear={true}
                  mode='multiple'
                  style={{ width: '100%' }}
                  placeholder='Filtrer'
                  onChange={specialistDepartementFilter}
                  filterOption={selectFilter}
                  value={specialistDepartementSelected}>
                  {departementList.map((dep) => (
                    <Option key={`dep_${dep.code}`} value={dep.code}>{`${dep.code ? dep.code : 'Aucun'} - ${dep.name}`}</Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col md={12} offset={12}>
              <div className='formInput'>
                <label>Disponibilités</label>
                <Select
                  dropdownMatchSelectWidth={false}
                  allowClear={true}
                  showSearch
                  mode='multiple'
                  style={{ width: '100%' }}
                  placeholder='Filtrer'
                  onChange={specialistAvailabilityFilter}
                  filterOption={selectFilter}
                  value={specialistAvailabilitySelected}>
                  {availabilities.map((availability) => (
                    <Option key={`availability_${availability[0]}`} value={availability[0]}>
                      {availability[1]}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col md={12} offset={12}>
              <div className='formInput'>
                <label>Compétences</label>
                <Select
                  dropdownMatchSelectWidth={false}
                  allowClear={true}
                  showSearch
                  mode='multiple'
                  style={{ width: '100%' }}
                  placeholder='Filtrer'
                  onChange={specialistSkillsFilter}
                  filterOption={selectFilter}
                  value={specialistSkillsSelected}>
                  {skillsList.map((skill) => (
                    <Option key={`skill_${skill._id}`} value={skill._id}>
                      {skill.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Row>
            <Col md={18} offset={3}>
              <div className='formInput'>
                { specialistKind === 'inspect' ? <label>Date de l'inspection</label> : <label>Date de la mission</label>}
                <RangePicker
                  small
                  format='DD/MM/YYYY'
                  placeholder={['Début', 'Fin']}
                  onChange={dateFilter}
                  value={inspectionsOrMissionsDateSelected?.length > 1 && [moment(inspectionsOrMissionsDateSelected[0]), moment(inspectionsOrMissionsDateSelected[1])]}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <Col md={18} offset={3}>
              <div className='formInput'>
              { specialistKind === 'inspect' ? <label>Date d'inscription inspecteur</label> : <label>Date d'inscription staffeur</label>}
                <RangePicker
                  small
                  format='DD/MM/YYYY'
                  placeholder={['Début', 'Fin']}
                  onChange={specialistDateFilter}
                  value={specialistDateSelected?.length > 1 && [moment(specialistDateSelected[0]), moment(specialistDateSelected[1])]}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Row>
            <Col md={18} offset={3}>
              <p className='filterIndicator'>
                <span>{inspectionsOrMissionsFilteredNb}</span>  {specialistKind === 'inspect' ? 'Inspections' : 'Missions' }
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <Col md={18} offset={3}>
              <p className='filterIndicator'>
                <span>{specialistFilteredNb}</span> {specialistKind === 'inspect' ? 'Inspecteurs' : 'Staffeurs'}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

MapFilters.propTypes = {
  inspectionsOrMissionsStatusNb: PropTypes.object,
  inspectionsOrMissionsFilteredNb: PropTypes.number,
  specialistStatusNb: PropTypes.object,
  specialistFilteredNb: PropTypes.number,
  inspectionsOrMissionsStatusSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filterInspectionsOrMissions: PropTypes.func,

  specialistsStatusSelected: PropTypes.array,
  filterSpecialists: PropTypes.func,

  clientList: PropTypes.array,
  departementList: PropTypes.array,
  availabilities: PropTypes.array,
  skillsList: PropTypes.array,

  searchFilter: PropTypes.func,
  clientFilter: PropTypes.func,
  dateFilter: PropTypes.func,
  specialistDateFilter: PropTypes.func,
  specialistSkillsFilter: PropTypes.func,
  specialistDepartementFilter: PropTypes.func,
  resetFilters: PropTypes.func,
  specialistAvailabilityFilter: PropTypes.func,

  specialistDepartementSelected: PropTypes.array,
  specialistAvailabilitySelected: PropTypes.array,
  specialistSkillsSelected: PropTypes.array,
  specialistDateSelected: PropTypes.array,
  inspectionsOrMissionsClientConcessionSelected: PropTypes.array,
  inspectionsOrMissionsDateSelected: PropTypes.array,
  inspectionsOrMissionsSearchEnter: PropTypes.array,
  specialistsSearchEnter: PropTypes.array,

  specialistKind: PropTypes.string,
};

export default MapFilters;
