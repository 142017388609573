/* eslint-disable no-unused-vars, class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Select } from 'antd';

const renderReportFilter = (props) => {
  const { handleSelectGroup, handleSelectSite, isAdmin, groups, selectedGroup, sites, selectedSite } = props;
  return (
    <React.Fragment>
      {isAdmin && (
        <div className='search-item'>
          <label>Groupes</label>
          <div className='search-input'>
            <Select
              size={'large'}
              onChange={handleSelectGroup}
              className='multi-select'
              value={selectedGroup}
              labelInValue={false}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              showSearch>
              {_.map(groups, (option) => (
                <Select.Option key={option._id} value={option._id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      )}
      <div className='search-item'>
        <label>Sites</label>
        <div className='search-input'>
          <Select
            size={'large'}
            mode='multiple'
            onChange={handleSelectSite}
            value={selectedSite}
            className='multi-select'
            labelInValue={false}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={sites.length === 0}
            showSearch>
            {_.map(sites, (option) => (
              <Select.Option key={option.key} value={option.value}>
                {option.title}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </React.Fragment>
  );
};

renderReportFilter.propTypes = {
  handleSelectGroup: PropTypes.func,
  handleSelectSite: PropTypes.func,
  isAdmin: PropTypes.bool,
  groups: PropTypes.array,
  selectedGroup: PropTypes.string,
  sites: PropTypes.array,
  selectedSite: PropTypes.array,
};

const TechnicalCostSearchBar = (props) => {
  const { selectedGroup, sites, selectedSite, handleSelectSite, handleSelectGroup, isAdmin, groups } = props;

  return (
    <div className='search-container'>
      {renderReportFilter({
        handleSelectGroup,
        handleSelectSite,
        isAdmin,
        groups,
        selectedGroup,
        sites,
        selectedSite,
      })}
    </div>
  );
};

TechnicalCostSearchBar.propTypes = {
  isAdmin: PropTypes.bool,
  handleSelectGroup: PropTypes.func,
  handleSelectSite: PropTypes.func,
  groups: PropTypes.array,
  selectedGroup: PropTypes.string,
  sites: PropTypes.array,
  selectedSite: PropTypes.array,
};

export default TechnicalCostSearchBar;
