import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const Private = ({ activeSession, isLogged, component, user, ...rest }) => (
  <Route
    {...rest}
    render={() => {
      if (activeSession && (isLogged || user) && user.isAdmin) {
        return <Redirect to='/admin/inspect/inspection' />;
      }
      if (activeSession && (isLogged || user) && (user.role === 'reseller' || user.role === 'chief' || user.role === 'superChief')) {
        return <Redirect to='/concession/inspections' />;
      }
      return <Redirect to='/login' />;
    }}
  />
);

Private.propTypes = {
  activeSession: PropTypes.bool,
  isLogged: PropTypes.bool,
  user: PropTypes.object,
  component: PropTypes.object,
};

export default Private;
