import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import UsersServices from '../../services/UsersServices';

class ResetPasswordPage extends React.Component {
  state = {
    email: '',
    password: '',
    error: null,
    timer: null,
    redirect: null,
    expired: false,
    loaded: false,
    loading: false,
    redirectTime: 0,
    timerInterval: null,
    token: null,
  };

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.token) {
      this.setState({ token: this.props.match.params.token }, () => {
        this.checkToken();
      });
    } else {
      this.setState({ expired: true, loaded: true });
    }
  }

  checkToken = async () => {
    const { status } = await UsersServices.validPasswordToken({
      token: this.props.match.params.token,
    });
    if (status === 200) {
      this.setState({ loaded: true });
    } else {
      this.setState({ expired: true, loaded: true });
    }
  };

  componentWillUnmount() {
    clearTimeout(this.state.timer);
    clearInterval(this.state.timerInterval);
    this.setState({ timer: null, timerInterval: null });
  }

  setValueOfPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  setValueOfEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  setValueOfShowPassword = (e) => {
    this.setState({ showPassword: e.target.checked });
  };

  handleNewPasswordProcess = async (e) => {
    // Make api call
    this.setState({ error: null });
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.email || this.state.email.length === 0) {
      this.setState({ error: 'Entrez une adresse mail valide.' });
    } else if (!this.state.password || this.state.password.length < 6) {
      this.setState({
        error: 'Le nouveau mot de passe doit contenir au moins 5 caractères.',
      });
    } else {
      this.setState({ loading: true });
      const response = await UsersServices.setNewPassword({
        token: this.state.token,
        email: this.state.email,
        password: this.state.password,
      });
      const { status } = response;
      this.setState({ loading: false });
      if (status === 200) {
        this.setState({ success: true, redirectTime: 4 }, () => {
          const timer = setTimeout(() => {
            this.setState({ redirect: '/' });
          }, 4000);
          const timerInterval = setInterval(() => {
            this.setState({ redirectTime: this.state.redirectTime - 1 });
          }, 1000);
          this.setState({ timer, timerInterval });
        });
      } else {
        this.setState({
          error: response?.body?.error || 'Une erreur est survenue. Réessayer ou contacter le support à tech@monspecialisteauto.com.',
        });
      }
    }
  };

  renderError = () => {
    let toRender = null;
    if (this.state.error) {
      toRender = (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return toRender;
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return null;
  };

  renderBody = () => {
    if (this.state.expired && this.state.loaded) {
      return (
        <div className='error mt20px'>
          <p>Le lien a expiré.</p>
        </div>
      );
    }
    if (this.state.loaded && !this.state.success) {
      return (
        <React.Fragment>
          <p className='mt10px content'>Entrez l'adresse mail utilisée lors de votre inscription et choisissez un nouveau mot de passe avec au minimum 5 caractères</p>
          <form onSubmit={this.handleNewPasswordProcess}>
            <div className='formInput'>
              <label>Email</label>
              <input type='email' value={this.state.email} onChange={this.setValueOfEmail} />
            </div>
            <div className='formInput'>
              <label>Nouveau mot de passe</label>
              <input type='password' value={this.state.password} onChange={this.setValueOfPassword} />
            </div>
            {this.renderError()}
            <div className='formButton'>
              <button disabled={this.state.loading} className='positiv'>
                {this.state.loading ? (
                  <span>
                    <i className='fa fa-spinner fa-pulse'></i> En cours
                  </span>
                ) : (
                  'Sauvegarder mon nouveau mot de passe'
                )}
              </button>
            </div>
          </form>
        </React.Fragment>
      );
    }
    if (this.state.success) {
      return (
        <div className='success'>
          <p>
            <i className='fa fa-success'></i>
          </p>
          <p className=''>Félicitations, votre mot de passe a bien été modifié. Vous pouvez à présent vous connecter.</p>
          <p>
            Vous allez être redirigé vers la page de connexion dans
            {` ${this.state.redirectTime}`} seconde
            {`${this.state.redirectTime > 1 ? 's' : ''}`}.
          </p>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className='passwordForgetPage'>
        <h1 className='logo'>
          Mon<span>Specialiste</span>Auto
        </h1>
        {this.renderRedirect()}
        {this.renderBody()}
        <div className='linkContainer'>
          <Link to='/'>Retour à la page de connexion</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ application: state.application });

ResetPasswordPage.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  match: PropTypes.object,
  verifyEmailError: PropTypes.string,
};

export default connect(mapStateToProps)(ResetPasswordPage);
