import constants from '../constants';

const getBillings = async () => {
  const response = await fetch(constants.URI.v3.billings.getBillings, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const getBillingByOrderId = async (billingOrderId) => {
  const response = await fetch(constants.URI.v3.billings.getOrUpdateBillingByOrderId.concat(billingOrderId), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const updateBillingByOrderId = async (data, billingOrderId) => {
  const response = await fetch(constants.URI.v3.billings.getOrUpdateBillingByOrderId.concat(billingOrderId), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return { status: response.status, body: await response.json() };
};

const updateStatusBilling = async (billingsToUpdateStatus) => {
  const response = await fetch(constants.URI.v3.billings.updateStatusBilling, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(billingsToUpdateStatus),
  });
  return { status: response.status, body: await response.json() };
};

const getBillingConfigurationByReportId = async (reportId) => {
  const response = await fetch(constants.URI.v3.billings.getOrUpdateConfiguration.concat(reportId), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

const getBillingHistoryConfigurationByReportId = async (reportId) => {
  const response = await fetch(constants.URI.v3.billings.historyConfiguration.concat(reportId), {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return { status: response.status, body: await response.json() };
};

export default {
  getBillings,
  getBillingByOrderId,
  updateBillingByOrderId,
  updateStatusBilling,
  getBillingConfigurationByReportId,
  getBillingHistoryConfigurationByReportId,
};
