import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Text from '../../shared/atoms/Text';

const ViewInspectionEntityConcessionInfos = ({ concessionData, entityData }) => (
  <Row>
    <Col xs={12} md={4}>
      <label>Concession</label>
      <Text fontStyle='normal' size={14}>
        {concessionData.name}
      </Text>
    </Col>
    <Col xs={12} md={4}>
      <label>Groupe</label>
      <Text fontStyle='normal' size={14}>
        {entityData.name}
      </Text>
    </Col>
  </Row>
);

ViewInspectionEntityConcessionInfos.propTypes = {
  concessionData: PropTypes.shape({
    name: PropTypes.string,
  }),
  entityData: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default ViewInspectionEntityConcessionInfos;
