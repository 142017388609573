import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import constants from '../../../../constants';

const DocumentsAndInspectionTestStatus = ({
  regulatoryDocumentsSubmitted, testInspectionValidated, isEditing, cancel, save, specialistsKind,
}) => {
  const setIcon = (value) => (value ? <i className='fas fa-check-square' /> : <i className='far fa-square' />);
  const [regulatoryDocumentsSubmittedC, setRegulatoryDocumentsSubmittedC] = useState(regulatoryDocumentsSubmitted);
  const [testInspectionValidatedC, setTestInspectionValidatedC] = useState(testInspectionValidated);

  return (
    <div className='specialistStatusDocumentsAndInspectionTest'>
      {!isEditing ? (
        <ul className='viewer' style={{ display: 'block', fontSize: 12 }}>
          <li className='full'>{setIcon(regulatoryDocumentsSubmittedC)} Documents reçus </li>
          {specialistsKind !== 'staff' && <li className='full'>{setIcon(testInspectionValidatedC)} Inspection test validée </li> }
        </ul>
      ) : (
        <>
          <ul style={{ display: 'block' }}>
            <li className='full'>
              <Checkbox checked={regulatoryDocumentsSubmittedC} onChange={(e) => setRegulatoryDocumentsSubmittedC(e.target.checked)}>
                Documents reçus
              </Checkbox>
            </li>
            {specialistsKind !== 'staff'
            && <li className='full'>
              <Checkbox checked={testInspectionValidatedC} onChange={(e) => setTestInspectionValidatedC(e.target.checked)}>
                Inspection test validée
              </Checkbox>
            </li>
            }
          </ul>
          <div className='specialistFormButton'>
            <button className='specialistFormButtonCancel' onClick={() => cancel()}>
              {constants.label.cancel}
            </button>
            <button
              className='specialistFormButtonSubmit'
              onClick={async () => {
                save({ regulatoryDocumentsSubmitted: regulatoryDocumentsSubmittedC, testInspectionValidated: testInspectionValidatedC });
              }}>
              {constants.label.save}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

DocumentsAndInspectionTestStatus.propTypes = {
  regulatoryDocumentsSubmitted: PropTypes.bool,
  testInspectionValidated: PropTypes.bool,
  isEditing: PropTypes.bool,
  save: PropTypes.func,
  cancel: PropTypes.func,
  specialistsKind: PropTypes.string,
};

export default DocumentsAndInspectionTestStatus;
