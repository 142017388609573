/* eslint-disable import/no-extraneous-dependencies */
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ConcessionsActions from '../../../../actions/Concessions';
import ConcessionsServices from '../../../../services/ConcessionsServices';
import EntitiesServices from '../../../../services/EntitiesServices';
import TableActionBar from '../../admin/organisms/TableActionBar';

const { SET_REFRESH_AT_FALSE } = ConcessionsActions;

const Table = ({ table, options, dispatch, needRefresh }) => {
  const defineService = () => {
    switch (table) {
      case 'concessions':
        return ConcessionsServices.getAllConcessions;
      case 'entities':
        return EntitiesServices.getAllEntities;
      case 'users':
        return ConcessionsServices.getAllAccounts;
      default:
        return null;
    }
  };

  const gridApiRef = useRef();
  const getItems = defineService([]);
  const [items, setItems] = useState([]);
  const [oldItems, setOldItems] = useState([]);

  if (!options || !getItems) {
    return false;
  }

  const gridOptions = options;

  const resizeGrid = () => gridApiRef.current && gridApiRef.current.sizeColumnsToFit();

  window.addEventListener('resize', () => {
    resizeGrid();
  });

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    resizeGrid();
  };

  const onClickRefresh = (resetFilters = true) => {
    if (gridApiRef.current && resetFilters) {
      gridApiRef.current.deselectAll();
      gridApiRef.current.setFilterModel(null);
    }
    getItems().then((res) => {
      const itemsToUpdate = _.differenceWith(res, items, _.isEqual);

      if (itemsToUpdate && itemsToUpdate.length) {
        setOldItems(items);
        setItems(res);
      }
    });
  };

  const openModal = (modalName) => {
    dispatch(ConcessionsActions.getActionToDispatch({ type: 'SHOW_MODAL', modalName }));
  };

  useEffect(() => {
    if (!needRefresh) {
      getItems().then((res) => setItems(res));
    }
  }, []);

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.applyTransaction({
        add: _.differenceBy(items, oldItems, (i) => i._id),
        update: _.differenceWith(items, oldItems, _.isEqual),
      });
      gridApiRef.current.sizeColumnsToFit();
    }
  }, [items, gridApiRef]);

  if (needRefresh) {
    dispatch(ConcessionsActions.getActionToDispatch({ type: SET_REFRESH_AT_FALSE }));
    onClickRefresh(false);
  }

  return (
    <React.Fragment>
      <TableActionBar onClickRefresh={onClickRefresh} openModal={openModal} table={table} length={items ? items.length : 0} />
      {items && (
        <div className='ag-theme-balham'>
          <AgGridReact {...gridOptions} onGridReady={onGridReady} onColumnVisible={resizeGrid} onToolPanelVisibleChanged={resizeGrid} />
        </div>
      )}
    </React.Fragment>
  );
};

Table.propTypes = {
  dispatch: PropTypes.func,
  options: PropTypes.object,
  table: PropTypes.string,
  needRefresh: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  needRefresh: state.concessions.refreshTable,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Table);
