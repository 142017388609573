import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import Select from 'react-select';
import ReportsActions from '../../../../actions/ReportsV3Actions';
import c from '../../../../constants';
import ReportServices from '../../../../services/ReportsService';

Modal.setAppElement('body');

class ModalAssociateRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: [],
      selectedRule: {},
      allRules: [],
    };
  }

  async componentDidMount() {
    const { modalData } = this.props.reportsV3;

    if (this.props.isEdit) {
      this.setState({
        rules: _.get(modalData, 'rules', []),
      });
      const res = await ReportServices.getAll(`${c.URI.v3.cpRules}?pagination=no`);
      const allRules = _.map(res.items, (rule) => ({
        label: _.get(rule, 'message'),
        value: _.get(rule, '_id'),
        _id: _.get(rule, '_id'),
        description: _.get(rule, 'description'),
      }));
      this.setState({ allRules });
    }
  }

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(ReportsActions.hideReportsV3Modal());
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }

    return null;
  };

  submit = async (e) => {
    e.preventDefault();
    const { rules } = this.state;

    const { modalData } = this.props.reportsV3;

    const data = {
      ...modalData,
      rules,
    };
    this.setState({ isLoading: true });
    if (this.props.isEdit === true) {
      try {
        await ReportServices.put(c.URI.v3.inspection.concat(this.props.reportsV3.modalData._id), data);
        this.props.dispatch(ReportsActions.executeAction('reports', { pageNumber: this.props.reportsV3.pageNumber }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    } else {
      try {
        await ReportServices.post(c.URI.v3.inspection, data);
        this.props.dispatch(ReportsActions.executeAction('reports', { pageNumber: this.props.reportsV3.pageNumber }));
        this.closeModal();
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    }

    this.setState({ isLoading: false });
  };

  handleDeleteRule = (e, index) => {
    e.preventDefault();
    this.setState({ rules: this.state.rules.filter((rule, i) => i !== index) });
  };

  handleAddRule = (e) => {
    e.preventDefault();
    const { selectedRule, rules } = this.state;
    // console.log('[DEV] selectedRule, ', selectedRule);
    // console.log('[DEV] rules, ', rules);
    this.setState({ rules: [...rules, selectedRule] });
  };

  handleSelect = (selected, obj) => {
    this.setState({ [obj.name]: selected });
  };

  renderRuleList = () => {
    const { rules } = this.state;
    return _.map(
      rules,
      (rule, index) =>
        rule && (
          <div key={`rule${index}`} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'first baseline' }}>
              <i style={{ fontSize: 14, color: '#1f5288' }} className='fas fa-info-circle' />
              <div style={{ marginLeft: 5, fontSize: 11, fontFamily: 'Avenir Next LT Pro Regular, sans-serif' }}>
                {rule.label || rule.message} - {rule.description}
              </div>
            </div>
            <div>
              <button className='btnIcon delete' onClick={(e) => this.handleDeleteRule(e, index)}>
                <i className='fas fa-trash-alt' />
              </button>
            </div>
          </div>
        ),
    );
  };

  render() {
    const { isAdmin } = this.props.application.user;
    const userCanEdit = isAdmin === true;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Associer une règle à un rapport'
        overlayClassName='modal-overlay'
        className='modal modalCheckpoint'
      >
        <div className='modalContent ' style={!userCanEdit ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
          <Row>
            <Col xs={2} />
            <Col xs={8}>
              <div className='mainTitle'>Associer une règle métier</div>
              <form className='mt10px'>
                <div className='formInput'>
                  <label>Règles métier</label>
                  <div style={{ display: 'flex' }}>
                    <Select
                      placeholder='Règles métier'
                      className='multi-select'
                      value={this.state.selectedRule}
                      onChange={this.handleSelect}
                      options={this.state.allRules}
                      isMulti={false}
                      name={'selectedRule'}
                      isClearable
                    />
                    <button style={{ minWidth: 100 }} className='btnIcon add' onClick={this.handleAddRule}>
                      Ajouter <i className='fas fa-plus-square' />
                    </button>
                  </div>
                </div>
                {this.renderRuleList()}
                {this.renderError()}
              </form>
            </Col>
            <Col xs={2} />
          </Row>
        </div>
        <div className='center formButton' style={{ marginTop: 25 }}>
          <button type='button' onClick={this.closeModal} className='negativ'>
            {userCanEdit ? 'Annuler' : 'Fermer'}
          </button>
          {userCanEdit && !this.state.isLoading && (
            <button type='button' onClick={this.submit} className='positiv' disabled={this.state.isLoading}>
              Sauvegarder
            </button>
          )}
          {this.state.isLoading && (
            <span>
              <i className='fa fa-spinner fa-pulse' /> Sauvegarde en cours.
            </span>
          )}
        </div>
      </Modal>
    );
  }
}

ModalAssociateRule.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  reportsV3: PropTypes.object,
  isEdit: PropTypes.bool,
  type: PropTypes.string,
};

export default ModalAssociateRule;
