const formatPhoneNumber = (phone) => {
  if (typeof phone === 'string' && phone.length > 2 && !phone.includes(' ')) {
    let formatedPhone = '';

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < phone.length; i++) {
      formatedPhone += phone[i] + (i % 2 === 1 ? ' ' : '');
    }

    return formatedPhone;
  }

  return phone;
};

export default formatPhoneNumber;
