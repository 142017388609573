import _ from 'lodash';
import * as actions from '../actions/MissionsActions';

const initialState = {
  missions: [],
  oldMissions: [],
  lastUpdatedAt: 0,
  numberOfMonthsSelected: 3,
  isLoading: false,
  error: null,
  mission: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_MISSIONS:
    case actions.REQUEST_MISSION:
    case actions.REQUEST_UPDATE_MISSION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.GET_CONCESSIONS_MISSIONS_SUCCESS:
    case actions.GET_MISSIONS_SUCCESS: {
      if (action?.missions && action.missions.length === 0) {
        return {
          ...state,
          oldMissions: state.missions,
          missions: state.missions,
        };
      }
      const missionsNotModified = state.missions ? _.differenceBy(state.missions, action.missions, (i) => i._id) : [];
      const missions = [...missionsNotModified, ...action.missions];
      const lastUpdatedDateFromBd = Math.round(new Date(_.maxBy(missions, 'updatedAt').updatedAt) / 1000);
      const lastUpdatedDate = _.min([Math.round(Date.now() / 1000), lastUpdatedDateFromBd + 1]);

      return {
        ...state,
        isLoading: false,
        error: null,
        oldMissions: state.missions,
        missions,
        lastUpdatedAt: lastUpdatedDate,
      };
    }

    case actions.GET_MISSIONS_ERROR:
    case actions.GET_CONCESSIONS_MISSIONS_ERROR:
    case actions.GET_MISSION_ERROR:
    case actions.UPDATE_MISSION_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case actions.UPDATE_MISSION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        missions: [...action.payload],
      };
    }
    case actions.SHOW_MODAL: {
      return {
        ...state,
        modalName: action.modalName,
        modalData: action.modalData,
      };
    }
    case actions.HIDE_MODAL: {
      return {
        ...state,
        modalName: null,
        modalData: {},
        refreshTable: true,
      };
    }
    case actions.UPDATE_NUMBER_OF_MONTHS_SELECTED_MISSIONS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        numberOfMonthsSelected: action.numberOfMonthsSelected,
      };
    }

    case actions.GET_MISSION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        mission: action.mission,
      };
    }

    default: {
      return state;
    }
  }
};
