import UsersServices from '../services/UsersServices';

const getActionToDispatch = (params) => {
  switch (params.type) {
    // App Versions
    case 'UPDATE_APP_VERSIONS': {
      return {
        type: params.type,
        versions: params.versions,
      };
    }
    // Rapport
    case 'UPDATE_RAPPORT': {
      return {
        type: params.type,
        rapport: params.rapport,
      };
    }
    case 'UPDATE_DUPLICATE': {
      return {
        type: params.type,
        duplicate: params.duplicate,
        clear: params.clear,
      };
    }
    case 'RESET_RAPPORT': {
      return {
        type: params.type,
      };
    }
    //
    case 'SET_USERS_FILTERS': {
      return {
        type: 'SET_USERS_FILTERS',
        filters: params.filters,
      };
    }
    case 'SET_ACTIVE_MENU_FILTERS': {
      return {
        type: 'SET_ACTIVE_MENU_FILTERS',
        activeMenu: params.activeMenu,
      };
    }
    case 'SET_USERS': {
      return {
        type: 'SET_USERS',
        users: params.users || [],
      };
    }
    case 'SET_USER_STATE_LOADING': {
      return {
        type: 'SET_USER_STATE_LOADING',
        state: params.state,
      };
    }
    case 'SET_LOADING_USER': {
      return {
        type: 'SET_LOADING_USER',
        state: params.state,
      };
    }
    case 'SKIP_USERS_NEXT': {
      return {
        type: 'SKIP_USERS_NEXT',
        next: params.next,
      };
    }
    case 'MAP_SPECIALIST': {
      return {
        type: 'MAP_SPECIALIST',
      };
    }
    case 'SPECIALIST_ACTIFS': {
      return {
        type: 'SET_USERS_FILTERS',
        filters: {
          isSearch: false,
          isSpecialist: true,
          isAdmin: false,
          isActive: true,
          status: 'actif',
          email: params.email,
          lat: params.lat,
          lng: params.lng,
          address: params.address,
          skip: params.skip || 0,
        },
      };
    }
    case 'SPECIALIST_CONFIRMES': {
      return {
        type: 'SET_USERS_FILTERS',
        filters: {
          isSearch: false,
          isSpecialist: true,
          isAdmin: false,
          status: 'confirme',
          email: params.email,
          lat: params.lat,
          lng: params.lng,
          address: params.address,
          skip: params.skip || 0,
        },
      };
    }
    case 'SPECIALIST_NON_CONFIRMES': {
      return {
        type: 'SET_USERS_FILTERS',
        filters: {
          isSearch: false,
          isSpecialist: true,
          isAdmin: false,
          status: 'non_confirme',
          email: params.email,
          lat: params.lat,
          lng: params.lng,
          address: params.address,
          skip: params.skip || 0,
        },
      };
    }
    case 'SPECIALIST_REFUSES': {
      return {
        type: 'SET_USERS_FILTERS',
        filters: {
          isSearch: false,
          isSpecialist: true,
          isAdmin: false,
          status: 'bloques',
          email: params.email,
          lat: params.lat,
          lng: params.lng,
          address: params.address,
          skip: params.skip || 0,
        },
      };
    }
    case 'SPECIALIST_NEW': {
      return {
        type: 'SET_USERS_FILTERS',
        filters: {
          isSearch: false,
          isSpecialist: true,
          isAdmin: false,
          status: 'nouveaux',
          email: params.email,
          lat: params.lat,
          lng: params.lng,
          address: params.address,
          skip: params.skip || 0,
        },
      };
    }
    case 'SPECIALIST_SEARCH': {
      return {
        type: 'SET_USERS_FILTERS',
        filters: {
          isSearch: true,
          skip: 0,
        },
      };
    }
    case 'ADMIN_ACTIFS': {
      return {
        type: 'SET_USERS_FILTERS',
        filters: {
          isSearch: false,
          isSpecialist: false,
          isAdmin: true,
          isActive: true,
          email: params.email,
          lat: params.lat,
          lng: params.lng,
          address: params.address,
          skip: params.skip || 0,
        },
      };
    }
    case 'ADMIN_INACTIFS': {
      return {
        type: 'SET_USERS_FILTERS',
        filters: {
          isSearch: false,
          isSpecialist: false,
          isAdmin: true,
          isActive: false,
          email: params.email,
          lat: params.lat,
          lng: params.lng,
          address: params.address,
          skip: params.skip || 0,
        },
      };
    }
    case 'NOTIFY': {
      return {
        type: 'NOTIFY',
        isError: params.isError,
        createdAt: new Date().getTime(),
        message: params.message,
      };
    }
    case 'DENOTIFY': {
      return {
        type: 'DENOTIFY',
      };
    }
    case 'SERVER_KILLED_SESSION': {
      return {
        type: 'SERVER_KILLED_SESSION',
        payload: {},
      };
    }
    default:
      return {};
  }
};

const getUsers = (params) => async (dispatch) => {
  dispatch(getActionToDispatch({ type: 'SET_LOADING_USER', state: true }));
  try {
    const { status, body } = await UsersServices.getUsers(params);
    dispatch(getActionToDispatch({ type: 'SET_LOADING_USER', state: false }));
    if (status === 200) {
      dispatch(getActionToDispatch({ type: 'SET_USERS', users: body.users || [] }));
    } else if (status === 401) {
      dispatch(getActionToDispatch({ type: 'SERVER_KILLED_SESSION' }));
    } else {
      // eslint-disable-next-line no-console
      console.log('Une erreur est survenue');
    }
  } catch (e) {
    dispatch(getActionToDispatch({ type: 'SET_LOADING_USER', state: false }));
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

const notify = (params) => async (dispatch) => {
  dispatch(
    getActionToDispatch({
      type: 'NOTIFY',
      isError: params.isError,
      message: params.message,
    }),
  );
  setTimeout(() => {
    dispatch(getActionToDispatch({ type: 'DENOTIFY' }));
  }, 4000);
};

const setUserState = (params) => async (dispatch) => {
  dispatch(getActionToDispatch({ type: 'SET_USER_STATE_LOADING', state: params.apikey }));
  try {
    const { status, body } = await UsersServices.updateUser(params);
    dispatch(getActionToDispatch({ type: 'SET_USER_STATE_LOADING', state: -1 }));
    if (status === 200) {
      dispatch(
        getActionToDispatch({
          type: 'NOTIFY',
          isError: false,
          message: params.message || `L'utilisateur a bien été ${params.isActive ? 'activé' : 'désactivé'}`,
        }),
      );
      setTimeout(() => {
        dispatch(getActionToDispatch({ type: 'DENOTIFY' }));
      }, 4000);
    } else if (status === 401) {
      dispatch(getActionToDispatch({ type: 'SERVER_KILLED_SESSION' }));
    } else {
      dispatch(
        getActionToDispatch({
          type: 'NOTIFY',
          isError: false,
          message: `${body.error || 'Une erreur est survenue'}`,
        }),
      );
      setTimeout(() => {
        dispatch(getActionToDispatch({ type: 'DENOTIFY' }));
      }, 4000);
    }
  } catch (e) {
    dispatch(getActionToDispatch({ type: 'SET_USER_STATE_LOADING', state: -1 }));
    dispatch(
      getActionToDispatch({
        type: 'NOTIFY',
        isError: false,
        message: `${e.reason || e.message || 'Une erreur est survenue.'}`,
      }),
    );
    setTimeout(() => {
      dispatch(getActionToDispatch({ type: 'DENOTIFY' }));
    }, 4000);
  }
};

const AdminsActions = {
  getActionToDispatch,
  getUsers,
  setUserState,
  notify,
};

export default AdminsActions;
