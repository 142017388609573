import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import PropTypes from 'prop-types';

const DragHandle = SortableHandle(() => (
  <span>
    <i className='fas fa-arrows-alt-h' />
  </span>
));

const SortableItem = SortableElement(({ sortIndex, selectedIndex, handleChangeSections }) => (
  <li className={`SortableItemHorizontal infinite ${sortIndex === selectedIndex && 'selected'}`} onClick={() => handleChangeSections(sortIndex)}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <DragHandle />
      <span>{sortIndex + 1}</span>
    </div>
  </li>
));

const SortableList = SortableContainer(({ items, selectedIndex, handleChangeSections }) => (
  <ul className='SortableList'>
    {items.map((item, i) => (
      <SortableItem key={`item-${i}`} index={i} selectedIndex={selectedIndex} handleChangeSections={handleChangeSections} sortIndex={i} helperClass='SortableHelper' />
    ))}
  </ul>
));

class DragDropList extends Component {
  render() {
    return (
      <SortableList
        items={this.props.sections}
        onSortEnd={this.props.onSortEnd}
        selectedIndex={this.props.selectedIndex}
        handleChangeSections={this.props.handleChangeSections}
        distance={10}
        axis={'x'}
      />
    );
  }
}

DragDropList.propTypes = {
  sections: PropTypes.array,
  onSortEnd: PropTypes.func,
  selectedIndex: PropTypes.number,
  handleChangeSections: PropTypes.func,
};

export default DragDropList;
