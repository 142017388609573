import { TreeView } from '@bosket/react';
import { Empty } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ModalCreateDefectTypologyCategory from '../modals/createDefectTypologyCategory';
import ModalCreateDefectTypologyDefect from '../modals/createDefectTypologyDefect';

const LANG = 'fr';

const DefectTypologiesTree = ({ typology }) => {
  if (!typology) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{ marginTop: 100 }} description='Aucun resultat' />;
  }

  const [selection, setSelection] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const [modalShown, showModal] = useState(false);
  const [model, setModel] = useState([]);
  const [typologyId, setTypologyId] = useState(null);

  if (typologyId !== typology.typologyId) {
    setTypologyId(typology.typologyId);
    setModel(
      _.map(typology.vehicleParts, (part, defectTypologyId) => {
        const children = _.map(part.categories, (category, categoryId) => {
          const subChildren = _.map(category.defects, (defect, defectId) => ({
            modalType: 'defect',
            modalContent: {
              defectId,
              typologyId: typology.typologyId,
              repairCostInfos: defect.repairCostInfos,
              label: _.get(defect, `labelTranslation[${LANG}]`) || defect.label || '',
            },
            label: _.get(defect, `labelTranslation[${LANG}]`) || defect.label || '',
          }));

          return {
            modalType: 'categoryDefectTypology',
            modalContent: {
              categoryId,
              defectTypologyId,
              typologyId: typology.typologyId,
              msaCarParts: category.msaCarParts,
              label: _.get(category, `labelTranslation[${LANG}]`) || category.label || '',
            },
            label: _.get(category, `labelTranslation[${LANG}]`) || category.label || '',
            children: subChildren,
          };
        });

        return {
          label: _.get(part, `labelTranslation[${LANG}]`) || part.label || '',
          children,
        };
      }),
    );
  }

  const submitModalFunc = (type, path, value) => {
    showModal(false);
    if (type && path && value) {
      const newModel = model;

      if (type === 'defect') {
        // if defect we change the value for all the defect that matches the defectId
        model.forEach((catTypology) => {
          catTypology.children.forEach((category) => {
            category.children.forEach((defect) => {
              if (_.get(defect, 'modalContent.defectId') === path.defectId) {
                // eslint-disable-next-line no-param-reassign
                defect.modalContent = { ...defect.modalContent, ...value };
              }
            });
          });
        });
      } else if (type === 'defectTypologyCategory') {
        // if defect we change the value for all the defect that matches the defectId
        model.forEach((catTypology) => {
          catTypology.children.forEach((category) => {
            if (_.get(category, 'modalContent.defectTypologyId') === path.defectTypologyId && _.get(category, 'modalContent.categoryId') === path.categoryId) {
              // eslint-disable-next-line no-param-reassign
              category.modalContent = { ...category.modalContent, ...value };
            }
          });
        });
      }

      setModel(newModel);
    }
  };

  const search = (input) => (item) => item.label.match(new RegExp(`.*${input}.*`, 'gi'));

  function conditionalToggleFold(item, clickEvent) {
    // this is the same behaviour as Bosket classical toggle-fold strategy but only for this class target
    if (clickEvent.target.className.includes('foldTarget')) {
      const newUnfolded = this.state.get().unfolded.filter((i) => i !== item);
      if (newUnfolded.length === this.state.get().unfolded.length) {
        newUnfolded.push(item);
      }
      this.state.set({ unfolded: newUnfolded });
    }
  }

  const options = {
    // Property of the model containing children
    category: 'children',
    strategies: { fold: ['opener-control'], click: [conditionalToggleFold] },
    model,
    search,
    selection,
    openerOpts: { position: 'left' },
    onSelect: (selected) => setSelection(selected),
    display: (item) =>
      item.label ? (
        <React.Fragment>
          <a className='foldTarget lool'>{item.label}</a>
          {item.modalType ? (
            <a
              onClick={() => {
                setModalContent(item.modalContent);
                showModal(item.modalType);
              }}
            >
              <i className='fas fa-edit'></i>
            </a>
          ) : null}
        </React.Fragment>
      ) : null,

    // Alphabetical sort
    sort: (a, b) => a.label && b.label && a.label.localeCompare(b.label),

    // Custom css root class name
    css: { TreeView: 'TreeViewDemo' },
  };

  return (
    <React.Fragment>
      <TreeView {...options} />
      {modalShown === 'defect' ? <ModalCreateDefectTypologyDefect modalData={modalContent} isEdit onSubmit={submitModalFunc} /> : null}
      {modalShown === 'categoryDefectTypology' ? <ModalCreateDefectTypologyCategory modalData={modalContent} isEdit onSubmit={submitModalFunc} /> : null}
    </React.Fragment>
  );
};

DefectTypologiesTree.propTypes = {
  typology: PropTypes.object,
};

export default DefectTypologiesTree;
