import React from 'react';
import PropTypes from 'prop-types';

class Svg extends React.Component {
  render() {
    const { name, color = '#FFF' } = this.props;

    switch (name) {
      case 'logo':
        return (
          <svg
            version='1.1'
            id='Layer_1'
            xmlns='http://www.w3.org/2000/svg'
            xlink='http://www.w3.org/1999/xlink'
            x='0px'
            y='0px'
            width='942px'
            height='942px'
            viewBox='0 0 942 942'
            enableBackground='new 0 0 942 942'
            space='preserve'>
            {' '}
            <image
              id='image0'
              width='942'
              height='942'
              x='0'
              y='0'
              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA64AAAOuCAMAAAAw2LpAAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
          AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA81BMVEX////s7Oza2trU1NTG
          xsbe3t7x8fHj4+O4t7ezsrK8u7vo6Oj7+/vQ0ND29vaipLCbnq6oqbHCwcHLy8uTl6x6gqNkc5xM
          ZJUwVY0HSIU+XZFwe6CtrrFYa5iDiqcdT4qLkamOmL+cpMgxWJJRa6HV1ufGyd+4vNiNl79fdadQ
          a6B+jLe7vMDx8fdBYZhugK8cT4zj5PAwWJKbpMiqr88dUIxCYZnHyuBcdKbS1OZvgK9/jLeqsNDy
          8vfV1+i5vdhgdqft7e3k5fDHxsa9vLzf39/V1dXy8/iJkK729/qgpb6Ciank5e2mqLSwsruRl7So
          rMRE2SaFAAAAAWJLR0QAiAUdSAAAAAd0SU1FB+IJGwIiHzRuPX0AAABzdEVYdFJhdyBwcm9maWxl
          IHR5cGUgaXB0YwAKaXB0YwogICAgICAzOAozODQyNDk0ZDA0MDQwMDAwMDAwMDAwMTkxYzAyNjcw
          MDE0NWE0OTYzNjQ2OTUwNTA2Yjc4NzI3NjM2MzE2MzMyNzEzNTM2NGEKNDYwMAqJwHqnAAA4qUlE
          QVR42u3d+0PbOL+gcTdlSGgubaG0ZdppG8h02pnt3CG7QLhD9+w5e/n//5qNlACByJYV2/pK9vP5
          6ez77lsC+MG2JMtJAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
          AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACA1ZMnrdbTtTut1g9P1qU/E4BH1ttP
          1zobRs/WWt2e9OcDoK230kpdaLbfHkh/TqDheu3+41Sfv5h7+ei/2GxxZQxI6bW3FjN9tf36zdud
          B3588277xfuFk2yLcywg4Mn9efX9q3dvdtK9/enD87ti19rSHxxomF772d1Z9d2PO3Yff/p0e5bt
          cIoF/Bk8vT2xvnr9MUert5fGH26L7RMs4MWgf3dedWh15qdXBAt4cxfrpzzXwIYb2e33BAv40JvH
          +nLb+cR67/V8iqfF6gmgMr3W7J715evVW10MtsMoMVCR7rNyYl0Ido0rYqACg7XZHGsZsSrze9iW
          9PcF1M/sOvh9kXvWRz5+mq1NZGkiUKr1zdk069vilS54M7si/kH6uwPqpDW7aX1TvNDHV8SzO1iG
          iIGSzO9aS7wOvvejXk3ceSL9PQL10NV3rc9XWxVh94ELYqAk84UR2xXFOvVGDxH3uSAGChpsut61
          fnyzrfyUf1Tq4ws9QswULFDI7EL4Ve671o/v7p9sffkhd7H6grjDjA5QwA+6u9wLI+Yzqfde5A32
          9XvWJAJFzG5bX+YeY/rp/caSvPe8P+r/Lb0Cq+np29YXuS+EZ6fWTr+rbkJ73aezFcZ5//cfn7Mk
          EVjVus7tQ95YZ7V22gsDvLMNYp7n7VU/ud6X/raBCK13nG5bZ7VuPZqNabn0Ovsn6BVw1Va1vs+/
          NOKdea3DE/XPfHJKnl4BN23HhUxv36cMFK2rf+invP/MNr0CrtpOF7FTr1IzU//Uy9z/zmt6Bdw4
          1/qj2qE/ZRXhltMtML0CTnStn1yev/mUMWc6cDq9znp9Kv0jACKhbzdzDw9p0zvXZ6n/nlps4fA4
          zzbrJYC89AzOc6da32Q+Aded/rfvHE/V9ArkoNcyudy3zk+IGevzp/2/cvnndK+s9wes1KXrS8d9
          I1RfGf/kmuvZ+hPP5wA5tN1WR8y82NhYy/g3n07/Tad/T68ffsbz6kCmQcdp2iVfri3XXGe9bkr/
          MICwrTkPCleS6+x5OqZfgQzdFW5cZ7lmnQr77rnqwWaGh4EM6om3FTYT/mAdanrh/G+q5RIdbl+B
          NGqcyWnKZU49jpO+U3BvpQtsPTzM0+pAGnVyXeW1Gj9mrhtsrzJ6NfVyenqV/okAoWqvdhqcl5V6
          4aqGr1Z5AYA6Z3elfyZAoNZWPLnOljWlXbg+WfEKe+ctg8NAmsHKJ9edj++np1fztt69FYevpp5z
          NQykeOqy74Ph9LppvBxWszju48J3/yhLEQGTTafnUh+dXl+mXLmqWt+v+FbYN0y9AmYFroV3ZoPD
          hve09t22U3z0N4AH1QGzdoFr4Z35LhDPHs6+zt65vvrfgJfZixuBxuqvON/yoNeNtfupl/XZqyZX
          r1Vt2MZYE2Cw6fpU6mPzV+R0tlpPnjz5oT9754bTPhKPqbEm1iECy4qdCJW3L5beaFXsnes/ZS5u
          BBprUMYr0t88DPblqoNMt/8c65oAkycrr2Z4eIbdvi32+YdCZ1blI6v84dVgehvXCtDSNWa3nFx1
          ZW/evFlxqvWR7FyfSP8Q9Q/yCZfrtfCktfVsI1RLFbQKDgxX4mXmquGW9A/xVmet1WVILGKDH9ak
          j6Fsxlyl61ySvatMMLlqm09ZMBml3g+b0seOFblW4NnTQYK4DPod6cMmB3KtxhZ3sjEZ9Bd/eS8+
          bL95U3iAtGzx5PrBnqvwJ3z75s277RfvF37nawQbi95CrK/eBdfpXDy5qmVN6T/tEHKde/v6032y
          a9zERuGHu8vgV6+DG2W9R66V+Om+2KeMEwdvcDsY/P5DObOOVUnNNbg/MVHlurPz8d3L+SHwjCvi
          wHXnp9b328Ed9Y8Ycy1pVVO5Ist16vVtsKzGCtrT+a/pQ+ixxpTrp+CHmpa9m18Sr3FBHKze/EK4
          2BMonhhzVe9ML/KwWyVyTORIf8RlH1/NL4gZcQpUb74uovAjLV6Yr9VC/PhR5nr/5C+9Bmle68sY
          Tq07abk+W3nHwupMD/ut9B97sLnufJw9ltTh6b8AzWt9Ef5d64w51+nl/HvpT/aI5QG6cHOdveVr
          g6d1AzSvteBWDB6Zc1UHf2ATUJadS0POdb51FdfDoYmu1pRcn4Q31qROURnHe9C50muYCu/3513K
          tGCnwLbglVB7jT/L+MmHneu812fM54SkFV2tabn2Axsb/vjcstog8Fxvt3KVPkJx70l8tablqjZX
          C+dy+O22mg3JfH966LnOe2V9UzD0+9UK7s/rXdoxpA//lx9+evPAu22rR/+LlTz8Fz89zzGyGnyu
          8/Fh1g+HYkuvEo5lBmcu9U/+g2d1A9DJPtDDz3XnBbevAZldCge31NYi/Qrt6aphVRJry3KYR5Dr
          R72+ifdyhUFfCn+QPiZcpeeaDJ6GsXljZ+2pfYVBBLnqqWPeUhuG2c2e9BHhLCPXqd6Th+y7hT3+
          X6xkhU3JYsh1dvvK6HAAevoJ19guhW25xiOKXGeXw4w2ydOHS1xzOBq5+jR78a30zwqzk2tgy2zz
          IFev9JxUu/i3i0JiPbmSq19vuHsNQawnV3L17AWDw/LasZ5cydUzfffaL/79ooCtWE+u5Oqb2h2x
          w9ImSXpBfHC7peSi/9iXMVcqrB9LrtsMNkn7Qf0KXksfCCuRXrJUKukfZh5v1QfdKn7MYWX6Wjiy
          tf1z0oU1LtfZXA5Xw3J66hfwSvowWI10Yc3L9Z36pGyzJqerfgHBPMvtRrqw5uWqr4Z5LkeOHpWM
          clx4Z6eEh8nDIf3DzEeNDW9KH7MNpl6yEd+zOBDySf11lz5mG0z9+CO9dYV/eqUEj+VI0bOuIW0b
          iKD9qI6XH6SP2sbSu75Ect+EADDWJCnmkSYIUDOvPJUjJZrlqgjDC4aGBak1TaG9rw0B+8DQsCA1
          jxPn+n6I2CZXQeQKJ+QqiVzhhFwlkSuckKskcoUTcpVErnBCrpLIFU7IVRK5wgm5SiJXOCFXSeQK
          J+QqiVzhhFwlkSuckKskcoUTcpVErnBCrpLIFU7IVRK5wgm5SiJXOCFXSeQKJ+QqiVzhhFwlkSuc
          kKskcoUTcpVErnBCrpLIFU7IVRK5wgm5SiJXOCFXSeQKJ+QqiVzhhFwlkSuckKskcoUTcpVErnBC
          rpLIFU7IVRK5wgm5SiJXOCFXSeQKJ+QqiVzhhFwlkSuckKskcoUTcpVErnBCrpLIFU7IVRK5wgm5
          SiJXOCFXSeQKJ+QqiVzhhFwlkSuckKskcoUTcpVErnBCrpLIFU7IVRK5wgm5SiJXOCFXSeQKJ+Qq
          iVzhhFwlkSuckKskcoUTcpVErnBCrpLIFU7IVRK5wgm5SiJXOCFXSeQKJ+QqiVzhhFwlkSuckKsk
          coUTcpVErnBCrpLIFU7IVRK5wgm5SiJXOCFXSeQKJ+QqiVzhhFwlkSuckKskcoUTcpVErnBCrpLI
          FU7IVVKluQ6lvzs8MCTXyJFrg5Br7Mi1Qcg1duTaIOQaO3JtEHKNXaW57kp/d3hgl1wjx9m1QTi7
          xo5cG4RcY0euDUKusSPXBiHX2FWa694IIdkj18ixZhhOyFUSucIJuRr8/PmXL1Nff/31ty92n1f3
          38gVLsj1gW+fv3z93d9P/wW5wgW53vvlN4+lauQKJ+Q698sff/r/6ZMrnJCr8teXv0V++uQKJ+Q6
          jfUPqZ8+ucIJucrFSq5w1PRcvwnGSq5w1PBc/xEYX1pArnDS6Fz/+lX4p0+ucNLkXP+VPbXukCsc
          NTdX2bvWGXKFk8bm+s33CiYTcoWTpub6s/iFsEKucNLQXMOolVzhppm5yg8yzZArnDQy11+kf+q3
          yBVOmphrIFfCO+QKRw3MNZxayRVumpfrt3BqJVe4aV6uIcy33iJXOGlcrgGsZbpHrnDStFz/lf6B
          P0CucNKwXP8K6MZ1h1zhqGG5Sj8x9wi5wkmzcv1H+sf9CLnCSaNyDexSmFzhqFG5BnYpTK5w1KRc
          P0v/sJeQK5w0KVeZrb+zkCucNCjXsKZcNXKFkwbl6nBy/fPXP758+fJvgVdB8sJIVKA5ueY9uf79
          2y/ffH0mXscMJ83JNd/J9Y+ffX4mcoWTxuSa6+T69S+/H4pc4aQxueaYc/3zF98filzhpCm5/mz/
          Ufzt9TpYI1c4aUqu9sdcf/c2wHSPXOGkIbl+s/4g/vR826qRK5w0JFf7ozj+r4QTcoWjhuRqncX5
          IvKxyBVOmpGrdR/w32U+F7nCSTNytc7ifJb5XOQKJ43I9S/bT+EPoQ9GrnDSiFx/s/0UJEaFFXKF
          kybkat23X+rkSq5w04RcrcuFpU6u1eY6lP6544EhueZie83GV7FPRq4NQq65WLdoEhoWTsi1Ucg1
          F9ty4b/lPhq5Ngi55mGdxflH7rORa4OQax5fLD+BPwWexLlVaa670j95PLBLrjnYlguLzeIknF0b
          hbNrDtZZHJFHcebItUHINQfbcuFfJT8cuTYIudpZB5r+lfx05Nog5Gpnm8X5U/TTVZrr3ggh2SNX
          G+tyYZnH0m+xZhhOap6rddMXseXCGrnCSc1ztc3iyC0X1sgVTuqdq3W5sPeNwB8iVzipd65fLd+9
          4HJhjVzhpNa5hrxcWCNXOKl1rtZNXwSXC2vkCie1zjXYTV9ukSuc1DnXoJcLa+QKJ3XO1bbpi9Be
          4AvIFU5qnKv1HZGiy4U1coWTGuca9nJhjVzhpL65Wt8R+Zv0JyRXOKpvrrZNX4SXC2vkCif1zTXw
          5cIaucJJbXO1viNSeLmwRq5wUttcbZu+SC8X1sgVTuqaa/DLhTVyhZO65mqbxRFfLqyRK5zUNNdw
          3xH5ALnCSU1ztS4XlnuN1SJyhZOa5mqbxZFfLqyRK5zUM1frpi/yy4U1coWTeuZqXS4cxEATucJR
          LXO1zuIEsFxYI1c4qWWuMSwX1sgVTmqZq20WR/Q1VovIFU7qmKt1FieE5cIaucJJHXONYrmwRq5w
          UsNcrZu+yL7GahG5wkkNc41jubBGrnBSv1ytm76EsVxYI1c4qV+u1ndEhrFcWCNXOKlfrrblwuEM
          NJErHNUuV+umL4EsF9bIFU5ql6ttFieU5cIaucJJ3XK1LhcOaKCJXOGobrla3xEZynJhjVzhpGa5
          Wjd9CWa5sEaucFKzXK3LhUMaaCJXOKpZrrZ3RIY0i5OQKxzVK1frpi/hLBfWyBVO6pWrdblwUANN
          5ApHtco1rlmchFzhqFa5Wjd9CWi5sEaucFKrXGNaLqxVm+v+CCE5INdF1lmcIF5jtajaXEfS3x4e
          GJProqiWC2vk2iTkusi66UtoA03k2izkusg6i/Oz9CdcQq5NQq4LIlsurFWb63+X/vbwwP8g13vW
          TV/CWi6scXZtEs6uC2yzOH9Kf0ADcm0Scr1n3fQlsOXCGrk2Cbne+2r7TgNbLqyRa5OQ6x3rcuGv
          0p/QhFybhFzvWDd9CeY1VotYMwwnNcnVOosT3HJhjVzhpCa5xrdcWCNXOKlJrrZNXwJ6jdUicoWT
          euRq3fQlvOXCGrnCST1yjXC5sEaucFKLXK3viPxd+hOmIFc4qUWu1k1fAlwurJErnNQi1xiXC2vk
          Cid1yNU6i/Ob9CdMQ65wUodcbZu+BLlcWCNXOKlBrtblwgE+lz5HrnBSg1ytszhBLhfWyBVO4s81
          0uXCGrnCSfy5Wjd9CfG59DlyhZP4c7XN4gS6XFgjVziJPtdYlwtr5Aon0ef61fYdhvYaq0XkCiex
          52qdxQl1ubBGrnASe67WTV9CXS6skSucxJ6rbRYnvNdYLSJXOIk8V+ty4ZAHmsgVjiLP1brpS7DL
          hTVyhZO4c7W+IzLc5cIaucJJ3LlalwsHPdBErnAUda7WTV8CXi6skSucRJ2rddOXgJcLa+QKJ1Hn
          al0uHPZAE7nCUcy5Wt8RGeRrrBaRK5zEnKt105eQlwtr5AonEedqXS4c+kATucJRxLlaZ3HCfI3V
          InKFk3hztW76EvZyYY1c4STeXCNfLqyRK5zEm6t1FifQ11gtIlc4iTZX66YvgS8X1sgVTqLN9avt
          Owt8ubBGrnASa67WWZw/pT9hHuQKJ7Hmal0uHOxrrBaRK5zEmqttFif45cIaucJJpLlaZ3GCXy6s
          kSucRJqrdblwuK+xWkSucBJnrtZNX8JfLqyRK5zEmWsNlgtr5AonUeZq3fQl5NdYLSJXOIkyV+s7
          IiNYLqyRK5xEmat1uXDwz6XPkSucxJirddOXoF9jtYhc4STGXK2zODEsF9bIFU4izNW+XDiSgSZy
          haMIc7W+IzKK5cIaucJJfLlaN32JY7mwRq5wEl+u1uXCMTyXPkeucBJfrtZ3REYz0ESucBRdrtZN
          XyJZLqyRK5xEl6t1uXDor7FaRK5wEluu1lmciAaayBWOYsvVuulLLMuFNXKFk9hyrc1yYY1c4SSy
          XK2zODENNJErHEWWq3W5cCTPpc+RK5zElWuNlgtr5AonceVqncWJaqCJXOEoqlzty4UjeI3VInKF
          k6hytW76EtFyYY1c4SSqXK2zOBEtF9bIFU5iytW6XDiygSZyhaOYcv1q+2YiG2giVziKKFf7cuGo
          VjQp5AonEeVqXS4c14omhVzhJKJcrbM48XlBrnART67W5cIRIlc4iSdX66YvESJXOIkmV+s7ImNE
          rnASTa7W5cIxIlc4iSVX+zsiY0SucBJLrtblwlEiVziJJVfrcuEokWvkDkaj0eHwsel/ONqv5OtF
          kqv1HZFxItcojUZHw+PxeGI5aE/GpyrdMr9yJLlaN32JE7nGZXQ2PF2qtHf+yPrjo3d8PDw7KOUD
          xJGrfblwnMg1Fgdnw/HF/QE5OO+2Wv3Ly6uNFJ3Ly8tWq72Y7snxUeEzbRy5Wt8RGSlyjcDe4e74
          7kicZtpPj9Qcbr/Vvav25PiwyHk2ilztm75EilwDNxqe3l77rrdbbp0+tLnVOu/N/qXJ6dGqyUaR
          ax2XC2vkGrDR8Paken7d31w91AVX02Zvkz3cW+EzRZFrHZcLa+QaqIOjeaqD7s1lKaUunGdvurPT
          7Mmu80k2hlytm75Ei1xDdHY8G1QatPsFrn4tyc7OspPjM6ePFkOutVwurJFraPYPT/Ux1+tWluqt
          reuBc7ER5FrP5cIauQblttX163JuVe0n2db6rNi8MzwR5Grd9CVe5BqQeavdm6pPqw9c3ehiL4a5
          Rp4iyLWey4U1cg3F6FhP2HT7HZ+tzott6avi8aH9Y4afa21ncXbINRB7R3psaV2i1ZnNthosnuza
          TrHh51rT5cIauQZgdKwOs0HL6zXwsr4eKx5njzsFn2tdlwtr5Cptf3ZibZc9ubqKq2t1ir04zHj2
          Lvhc6zuLs0Ou0vZ21R3r4EbsIvixvhp3mgxTgw0919ouF9bIVdLsKjiIE+u9y676UMcpN7Gh51rn
          gSZylTRSywx718J3rAZXetjJHGzoudZ2ubBGrlJ0rKVcBV9dXl72W3dupv/PoossOq20YAPPtZa7
          C98jVxmzWPsFitq8vGldn98+Emewfn5+7fporD3YwHMtawPEPbVrhx4DnIzHw0JPCJeJXCXoWM9X
          jPXqcprp4PFxOr5z8fi/Wj+fVrtysI8GnQLPtZRJ17PdpZ/h5PSwmq3q3JCrf7NYVxlfurxpny8c
          QtO/+8Oj0cjwp39vNBoNh7uLm68Nuq0txzOtDvbRKHHguRb/7ewNL1L+7dzrqqtDrr7tqYXBgy3X
          Ujf77bv9Wy7G00rzXp8djIbHd9VOm3X6M9FpqT8Li2sTw8618K3r3nHWP3/i9rBh+cjVr/3dxP2e
          9e5x8ukJdXi40t/4/Wm0J/OD7twl2au2Okzvv2bYuRbcXlj/djKNZc+w5OrV0fQ012utlup4eLbK
          bi2LRken8yu985vcg8eb6vr79PZLh51rsWfnzmz7Niu7kvew5OrRSJ3frh2mbrbasyGlAluhLdk7
          vNuqIu8V+eX0U3wfzv7n9c11/zTf17gQHCYmV2/21W3Ree7Rnk6/W3qqt6bJzk4keR/XU2NOF/o6
          sLa5Hpzk/io5njOsCLn6oq6DB3lvGm9bPdmt7F7pYHjiUGxH3cKe7tc314M8F8K3dkWOoB1y9eVg
          nP+m9bbV06Oi96oWe0ez679cV8XqinhyVNdcD11qTZJj38fPHLl6MUxyXwdf6gW7iadp+f1DvT/q
          IM/KZXVFPP6ftcx15PqFhHolVw/UbVEv17jOfBcWr0to9o70VXGORVZX50nyHzc1zNXpSnhm6O8X
          tIBcq6dOrd08N4hb+iL4oupr4GUH+qnbnn07i5uevkqQrrLkXPfdaxUabyLXquU9tXZu1Il1ciw0
          TTC7KLY+etuZ/kXp3UhXWXKu41W+1ETiF0WuFTv6nuvUmmPblart6cmdc9tflq3eKgd3yLkOV/ta
          JwK/I3KtlJp6z3Fq1Sv95JeQz7aNsi2RvFpf7fAONdeDVb+YwO0ruVZpNMkzIHypVvnZtwz14vDE
          HmxLOspyc13pUljz/xsj1wqpqyzrXKuO9WIYwtOUmv3B+Xrlerb6Vzv1/ssh18rsT4/7gW0h/SxW
          uVVtJrZg65XrRYEv5/3uhVyrcnBhH2O66oYXq5L9AH2tcj0s8uW8n17JtSLqMLixxNoOM1ZFB9u9
          qn+uq9+5Kr7vXsm1GsdJ0suew5ztrHIk/UFTHaqrxFYnrly/un6Xe8W+nu+1/uRahf2TJFnPvhDu
          DzI3yw+Bfpa+H1WuzjurWbePyHbh+VdCrhVQt63tzFj1Dg3HQUzdZNB7oZxv1jnXIgNNiuelTeRa
          PjXbmnnb2rme/qLHoexdm0U997d0RVyjXFdeInHL81IJci3doW0h09bg0e6CITtcfqq+RrkeFf2C
          Y7+/DXIt265ltlUtkpfdn8uNviJ+sMFUjXI9LvwV/f4yyLVkx5ZBJrVC/iSG6+B7o4uHJ9ga5Zp/
          f6Y0fldKkGup1JL+84xa9alV5snmIt+VWk15f4KtUa7Fv6LfmxpyLZOawMkaElan1nHo48Emaqz7
          7hK/PrkWHmny/beXXEtkqVXvJhjuuojsb03dwbbqlqvzFk3L/C6UINfyqFoznmTZHER317pIPwzY
          IddH/A4Nk2tp9rJrvfH+p7hk+lH7S3Il1zpQu+ml16rGmCbSu0UUpWYpW3XKtdDjOOQasexa1YXw
          OJ651tRv8kI9V1efXDm7NtReZq39JMLpGxP92qeA91YjV+SQPcrUrsGF8K3/NZBOMqxc/W7nT65l
          yKy1s54kJzFOthptb8zf4BMk5l1hlVmrum2VegNSBdQrrTrSVZaVK6uaGiiz1stetEsjjMJ+A51r
          rkUfd2XNcHxOM2rtJ8n3M+kPWKZ65ZrzhekZ/P70ybWw44yVh9dJchHvQiaTeuVa+HlXz2/eINei
          hhm1tqe/z/hnWx+oV66Fx5o8r1Mj14IOk+Q8bUi4XYu1EQ/VK9edVV4VucjzjQ65FjNKfxpdTeDU
          aEh4rma5FtxOYuL5p0+uhRxMkl6jaq1brgWvhn3/gsm1iP2LpLeZXmst1h0+UrNcC07l+B5GJNci
          xkmylV5rLJsdOqlbroUeyvG8DyK5FnKcup9wbWutXa68ga4pDlOncOpba/1yLXB69X5yJdfVHaQO
          Cte41vrlWuD06v8pK3JdlRpmumpcrTXMdeWn6AS28iHXVY2TJOWNkOc1rrWGua76FroLgRUw5Lqi
          YeowU7uW8623apjr/mp7+UtsOECuq5leQXWbWGsdc9X7bDkTmVQn15VMb1wH5mGmm3rXWstcd87c
          v5LML5lcV3KaJObVTP36PYPzUC1zdZ/N8T+Ho5HrKo7uXkDxyGbda61prq69Sv2SyXUFB99THpq7
          6iWT2myiZlbTXN16PZb6k0yuKzhJmXHtrCff67V3xLK65qrfAZST3LtTyNXdMG1hf7fOE65ztc1V
          v+Moj4ng75hcnR2kzeG0In9nVS71zVX/HbYTfT8vuTo7SXkifStJTqU/W/XqnOvOwdj2BSRPrTvk
          6i7tUviqV/dBYa3Wue7snGUu+J8MhX/D5Ooo9VK4AcNMSs1z3dkZpe49fHEk/ueYXB2NUy6Frxsw
          zKTUPtednb0jwzXxxW4If4zJ1c1RyqXwVs3XHt5pQK5T+6Ph6d1A8cV49zCQ2XRydbI/MS+QaMiN
          605Tcr01GgXS6Ry5OjlNWSBx3owb152m5RoacnUxSlkr3GrIjesOucoiVxcXycBU62YjZlxnyFUS
          uTo4Mu/30llPJs24cd0hV1nkmt/+xDzlei2zEYgMcpVErvntJolpnOmyCUuF75CrJHLNbS9lnGld
          ZE88KeQqiVxzS1nP1GrSpTC5yiLXvEbmnUqbNCqskKskcs1rbJ7EOW/QqLBCrpLINafpybVvqLXf
          nAUSM+QqiVxzujAuFu70pLawlEKuksg1n0PzColr/y/QFkauksg1H/PJdbNRU64auUoi11xSTq5N
          G2faIVdZ5JqL+eR62bRxph1ylUWueaScXAfJifQn845cJZFrHmPjybXfrPVMM+QqiVxzGBk3aGre
          JI5CrpLINQfzgqZW4yZxFHKVRK52e8YFTdOTazP2PnyIXCWRq91x2sk1rF3y/CBXSeRqtW98zrWh
          J1dyFUWuVkPjc64NPbmSqyhytbpI2pxc76hcO9JVkitSHBp3aGrqyVXl2lmXrpJckcK8RKKpJ9dp
          rgHXSq5NZ57F6Tf15LqzHXKt5Np0u0nPuFq4gQuatP8MuVZybbpJcr1c61YTVwtr+/+VJAEHS67N
          Zh5oOm/gozja/sn03qAlHSW5wuzUNNB01cDnXDVd6wa5iiHXTOaBpnYykf5gIma1kqsccs10lCTL
          K5o6vWQo/cEkzGslVznkmunEtKKpobM4t7WSqxxyzbJnfC59vVmv2Zi7q5Vc5ZBrFuOk62aSnEl/
          MP/uayVXOeSaxbi6v51cSH8u/xZqJVc55JrhwHgt3MSBpsVayVUOuWYwXgs3caDpQa3kKodcMxiv
          hbvNWy78sFZylUOu6Yzjwp3mrWh6VCu5yiHXdEema+GbJGnYa3Ee10qucsg13dh0Ldy4SdelWslV
          Drmm2jetF27c6v7lWslVDrmmOjStF27atbChVnKVQ66pjpP1xl8Lm2olVznkmmpi2A28YePCxlrJ
          VQ65pjlIkk3TGokGXQubayVXOeSaxjiN023StXBKreQqh1zTnCbd5SM1SY6kP5c3abWSqxxyTTNJ
          bpYO1K0GrRdOrTXkXL9I/9QqRq4pjLeu183ZATG91ukdQbDItaGMt66DZFf6c3mSUWtbuklyxWOm
          W9erxuwjkV1ruPuC/yP9g6sYuaYw3br2k0T6Y/lhqTXcF0Z+lv7JVYxczfZMt67thjzqaqs13Ncx
          f/tcb/+NXI0Ok8R069qIbV+stYaba92tkavRruFlG1fNeJFVdq3/pR57kD5qG4tczcaGF88149Y1
          u9aT/9wgVznkamba96URt66WWve3yVUQuRodmN4Tud6AW1dbrTvkKolcjUwjTZ0GzLpaayVXUeRq
          ZBppuqz/w3P2WslVFLkamUaaWrV/2UaOWslVFLkamdY01f5Z1zy1kqsocjXZT5LLpUO27oskctVK
          rqLI1WRkWtNU80US+WolV1HkamJ6eq7mI005ayVXUeRqYhoYvkkm0h+rQnlrJVdR5GpiGhi+rvOa
          pty1kqsocjW5MGwxfF7jnSTy10quosjVpGEDww61Bp7rz7/W2/8m12XGZ9PrOzDsUmvgubJXUwOZ
          5nE2a7tnqVOt5CqKXA1MC/wvS3vYdW80Cin8jFpby7UGnutX6Z9mxcjVYGiYxylpxfDB8UQdVpPj
          UK6sM2rtG2oNPFd28W+gXWOuJczj7O/eH1njIE6xrrWSqyhyNRgb5nG6yXHhf1e1MWhdTq+sbwbT
          M+yB9Pe5Qq2B5/q79A+0YuRqMDZOuxaex1Ft3P3DN73ku3iv7rUGnqv0D7Rq5GpgenyuhGnX3Qdt
          bMr3ukKt5CqKXA1MqySKT7vuJQ+XNor3ukqt5CqKXA2qyfU46XU2Aup1pVrJVRS5LjNtg7iZJEXL
          miw9NyDa62q1kqsocl02qmSVhGmHCsFeV6yVXEWR67Jqcp3+q52NYHpdtVZyFUWuyyrL1dCGUK8r
          10quosh12TBZXzqIW8lJwX/VnKtMr6vXSq4p/v7iw/8h1yXDStYg7pleuyPTa4FaA89VbhHir16+
          Px5PX1ZNrjsXSXcjiF6L1EquKf4gVyEV5Xpoms4V6LVQreSa4gu5Cqko1+nptdeR77VYrYHn+puv
          n+IScpVSVa4HSbIu3mvBWgPPVW43iX/IVYj5fVYlPO56KN9r0VoDz/VfHz9Do8/kKqSqx13V2wGE
          ey1ca+C5fq7+R5iCXKVU9Lircizba/FaA8/1W9U/wVR+vj9yXVZhrrK9llBr4Lkmf1b7E0z1N7lK
          qTJXyV7LqDX0XKVmcvyskiBXA1Ou5b0hR6zXUmoNPVepoWE/8zjkamDKtZSR4RmhXsupNfRcf6nq
          x2fxC7lKqThXmV5LqjX0XKXGmv4iVylV5yoxn1NWraHnKrR1qaeRJnI1qGxX8Dve10uUVmvwucos
          Q/SzwJ9cTapahLjAc6/l1Rp8rjI3r55uXcnVwEOufnstsdbgc5V5Qv0buYox5dpPJuV+EY+9lllr
          +Ll+Lff3lMtXX98cuS4z5Vre+yJveeu11FrDz1Vilf+/5CrHT66+ei231vBzlZjK8XUtTK4GZ+ad
          EF0PbCsvvZZca/i5Jn+U/Xuy8jUuTK4mpj0LO8VfurHMQ69l1xpBrv4fovPz8JxCrsuMW4xWkWv1
          vZZeawS5Jn+X/4vK5GuNREKuJsb99pPkrIIvVXGv5dcaQ66+B5u8DTSRq5Fpy8LSnqB7qNJeK6g1
          hly/+X3o1ePJlVxNPOZaZa9V1BpDrp6fovN4ciVXk4nhKC/vgddHKuu1klqjyNXr6dXnyZVcTSp/
          JOeBinqtptYocvV6evV5ciVXE1Ou/epWo1bSa0W1xpGrx9Pr716/MXI12PWzrOlOBb1WVWscuXoc
          HPY356qQq4FpFWIl6yRuld5rZbVGkqu3LdZ+8/ttkavBoXmdRBUTr/dfscxeq6s1llx/ru53tehP
          b6uFZ8jVwLisqaqZnJlSe62w1lhy9TTa5Oux9FvkarBnmnjtJqdVfs0Se62y1mhy9bJpk+dLYXI1
          M73ovJWcVPo1M3udOPRaaa3x5Orhcvh3z5fC5Gp2YpjJ2ap6X5GSeq221nhyTf6p9tc1vXH92fv3
          RK4m46S93EySVPwKm1J6rbjWiHKt/MFX3zeuCbmamWZypsfoYcVftoReq641ply/VXv76uk9Gw+Q
          q8mh/6Hh269brNfKa40p12oXN/nbQmIBuZqMkuRq6XivbJH/goK9Vl9rVLkmP1fXq6dXzj1Crkam
          mZzS9y41KdSrh1rjyrW64WH/g8IauRpdGIaGqx9rUgr06qPWyHKtavGwUK3kanaadJeP+erHmpSV
          e/VSa2y5VtOrVK3kajZMBssH/Xmy6+Nrr9irn1qjyzX5XP79q1it5Gpm3F2t6nVNt1bq1VOt8eVa
          /niTyJjwDLka7ZvGmrYq2BrcaIVefdUaYa7JX+XOvwrWSq4pTGNNnUqfoVvk3Ku3WmPMNfn2tcTf
          jdfNXh4jVzPjWNO6n5vXHede/dUaZa4lPk4nsE54EbmaHSW95UP/2tPN645jrx5rjTTX5HM5W/v/
          KjbINEOuZsZ1Td5uXnecevVZa6y5Jt9+K+Gb/0f6uyDXFKYCOn5mXmdy9+q11mhzLeEE+6vshbBC
          rilMz9BtnCfH/j5Bzl791hpxrsm3L0WmdP4UP7Um5JrKuFCi5WPZ8J1cvXquNeZck+Sv1R+B/U34
          rnWGXFMYb179LBu+k6NX37XGnevKwf7xl/QHnyHXNMYMBtU/87rI2qv3WmPPVQXrfEkcSqzkms54
          89r2N5WjZfY6rXHsu9b4c53ew/7jMuj09z9BXAbPkGsa48zrVpLsef0U6b1OP8ruMDEsvqq01jrk
          OvXzb/mK/fMP+dHgReSa5iBJNpcz6CVHfj9Geq/X6vfX9VxrTXKd+vmL7cUcv38Jq9WEXDNMTGeu
          ruer4YxeO4PUkqurtT65Kp//+e1X453s71+/fA7oGvgOuaY6TtaNIfi9Gs7odTPpbfqutV65znz+
          /M+XL19+1ab/x7+fgzup3iHXVGemZ147ie+r4Yxeb7a811rHXCNCrqn2jaOuvseGlfT7V++1kqso
          ck1nfIjO+9iw4tJrtbWSqyhyTXdouhre6PldKXH3UXL2WnGt5CqKXNPtpVwNXwh8lry9Vl0ruYoi
          1wwnpqvhzSQZCXyWfL1WXiu5iiLXDEfGq+GBz6fo7uXptfpayVUUuWYwXw3feNxTYpG9Vw+1kqso
          cs0yNl0NS0y9arZefdRKrqLINYt5bFhmsGnH1quXWslVFLlm2U+Sm+UuLmUGm3aye/VTK7mKItdM
          p6Z1w1KDTTtZvXqqlVxFkWumM+NTdDcSK5tm0nr1VSu5iiLXbJPk2jDY1PO2nf8Sc6/eaiVXUeSa
          bde0p8TGdTIRmctRTL36q5VcRZFrNvPU65XUXI6y3KvHWslVFLlajJNzw+lVbC5HUb0ubqra8lgr
          uYoiV4tD037DauGwv9dvmD5T7+71s522z1rJVRS52kxMG5hunEueXncOvyfJ9eyC+HKQJGOPN9Lk
          KolcbYZJzzBzcil6et05uJieYNv9y9b69HfodZSaXCWRq82ecWWT8Ol1Z3/39jd44XeFFblKIler
          Y9PLraRPr9M/I0fjaavHZ56/LLlKIlerUZJshXd6lUKuksjV7sQ4lyN+epVBrpLI1e4wSS45vc6R
          qyRyzeHCOJczPb0K7IkojVwlkWsO5qUSG13BlcNiyFUSueawb14qcdXE0yu5SiLXPIbm0+u13HOv
          YshVErnmkXJ67fSSU+mP5hu5SiLXXFJOrzdiuzaJIVdJ5JpLyul1Y9C4yRxylUSu+aScXps3mUOu
          ksg1n7TTazf53qzRJnKVRK45DY17Im5cNW20iVwlkWtO+xfGlcNqtMn3QzGiyFUSueaVsnJ4Y71Z
          a5vIVRK55nZh3NFfbdvUpMthcpVErrmNjHuY6o0IG3Q5TK6SyDW/sXHXpoZdDpOrJHLN7yBJWo2/
          HCZXSeTq4Ni8VqJRl8PkKolcHexPzJM56nK4KYslyFUSubo4Mu+ytrHZS8bSn80TcpVErk5OkoFx
          tOmmMWuHyVUSuToZJab3veq1ww15lI5cJZGrm13z0uGNzqAhsznkKolc3exPzGub1GxOI25fyVUS
          uTo6S5l8bcrtK7lKIldXp0nPOPmqbl8bMPtKrpLI1VXq5GtnPfl+IP3pKkeuksjV2Vna5fBmz+dr
          zIWQqyRydXeaMjq8sdWA4SZylUSu7lJHh9Vw07H0p6sYuUoi1xWcpS2W2GjX/i2S5CqJXFdxnLIR
          jFrsX/NeyVUSua5i/yLlSfX6Dw+TqyRyXclBkjKbo4aHJ3XulVwlketqjpLkJr3XGk/nkKskcl3R
          OPX2dSup8/QruUoi1xWl375u9OvcK7lKItdVpd++1rpXcpVEris7Sp19rXOv5CqJXFd3nLJP+Gy5
          RE17JVdJ5Lq6/ZOkt9m0XslVErkWsDdJHW6qba/kKolcixglyXrDeiVXSeRayGFifqm6clPLXslV
          ErkWc5w+PKzGhy9qtx6RXCWRa0Gn6cPDqtfarR8mV0nkWtD+SVavveR7zbZbI1dJ5FqUWo2YNp2j
          1vvX7PlXcpVEroUdTLJ6HSTJrvQnLNP27CIfIsi1uIPvGb121pPktEYDxNNcr6UP2uYi1xJkTb9u
          dNQEbH3e/rrd6Uofsw1GrmU4zOpVnY0mtXk93f9dlz5km4xcS5Hda7+XJEfSH7EcZ/8x/U6lD9rm
          ItdyZPeqBpxqcQO7myS9rZb0Qdtc5FqS7F4753VY4bR3Mv0mNzfIVQy5liW7V32MR35BfDZJknZn
          g1zlkGtpLL1e9uK+IN4/nl4I92//8kAEuZbH0qu6II53hPjgQl8Ik6soci3RtNf09RLz43w3zhPs
          MLl/9IhcxZBrmWy9bq4nyUWEJ9iDk+k3drlBrtLItVSH37N77agFfNGdYNWptXt/mU+uYsi1XAeT
          jOfp9IjTILYT7OhCTbY+uqaHCHItmer1JqtXfYKNZ4hYDQjr6RtyDQC5lk2tJWhn9bpxOb2DnUQy
          B3s0/eszePQyIHIVQ66lU/tLnHcyg231kuQkgivi0fRbSVqPvxdyFUOuFTi+m6JMc6WeQjsN/LG6
          PXUdfH61/MdG+qBtLnKtwnBx3iN9yCkZBnwLuz/8Pr0O3jJ8dHIVQ66VOPxuGXCaXxFPhtKfNM1w
          etPaa5k/uPRB21zkWg21Zq+dfQM7GyO+CHLntcPpx0/9/OQqhlwrsj+23sBOb2HbSYjBzmK9SvvU
          5CqGXCuze/sES+Yt7HkSWrA61vOMW29yFUOu1bl9PjRPsKEMOu0PbbGSqyByrdB894VcwU6GAUzr
          7O1O/8JYYiVXQeRaKXVBbBshntpU97DJsfDCiZGaZ824ZyVXceRaLXVB3LVeEG9sXF33pr+Nk0Ox
          a+L9Q7WCqXdtjVW/uRYyyLVie+NHz7Ok6dyohROTY5EN2A6O1VXw4CbPH5Zz6WO2wci1ckdJvhPs
          xsaW3iD/4sjzXezekRpeSrp5/qhs3EyvAghWCrlWT+/FkKuFjauWOsUmY38XxfuHp+orDlo5roKn
          N9nn+mZc+qhtLHL14dF2DJku2+ouNjn1Uey81V473x8TPcik1vxLH7WNRa5e6BNsjiFirdOfvTXq
          5KjS+9i9I91q0u3n/UMyPfNP/p/6v6SP2sYiV0/U8y3n9jnYR8Ve7J5Vc5I92z3RXyB3q7Nn/o73
          eR2zJHL1RQ0RJ9d541DFzq6Kk5PhqNxkR8Ox/od7+Vvd6Kjnh9QeU+QqiVz9UXOw9lXEDy4/r+dv
          ezvZPStluHj/NtVkcJ3zflXrD24f9iNXSeTq0f7u9Ce+fumQyfS0ttUezH5Vk/GwSLP7o6Pj2QVw
          Mmj3c40D3/3VUH80jmeneHKVRK5e6SvirlMrOtnru6nO8fFw5DgCtT86HI4v5v9711TnS5rHt1+T
          XCWRq2dnWQ9+Z1Zz075/D/LFtNrDkWWN8d5oNByOx3f/o8F569L5C88eyb3/SuQqiVy9O9LbqqwQ
          7NRmv3U+ePALHE8NH9md/mcXi/+feuftFUrdMD0/T66SyNW//WGBYHW0l63W+Xkvx693fdppf6VQ
          9ddRsT7aEZlcJZGrBL01fpFgZzqXl1utVuv6/LHpf9i6vMw9y2s2fwz30SQSuUoiVxl75QRbnb7e
          5OJoacaXXCWRq5S9nA+DS5g9zGfcQopcJZGrnD39kGnXbR7Wg029nGps3u+NXCWRqyQ96JSsO610
          qpq+Ck7fiIZcJZGrrH29TWiuPVd8mJ1YLzK2eSNXSeQq7kwvZDiXP8Ve3eh1GOPMXY/JVRK5BmC2
          X2ivXXDmpZD5I3sXu5ZlyeQqiVzDcKhPsTm3YKmq1eT4zPpByVUSuYZib1evG1y/8V3sbav5tpsh
          V0nkGpCRntmZFuvvqnhzdr+anBzlfASeXCWRa1jOZsW6PTy+6mn19lFal23cyFUSuQZnXmzSrfQk
          e7tRxeTYbctFcpVEriEaze5jV3iWPN9Z9fZp95Oh816L5CqJXAO1d3Q6O8n2uq0Slylu3j3kvuIm
          i+QqiVwDdnC7D1qy3r4p3Ozlzd0WMie7h6vu+kSuksg1cAdHp3fbLJ2v9qh553JhD4rJuNg+qOQq
          iVwjsHc2HE/ufmXq6fPLPNleXepdJ+7+h9NSVz6p3iFXSeQai/3R8Hgh2kR1e95W+0ZsXS5Q20u0
          2ufnD98Sp/ZPLOe9duQqiVzjojYhPX64b1qWE71jYpkvASBXSeQaqdHobKi2JR0v7Eyqqf/kdPpf
          ndn2NV0NuUoiVzghV0nkCifkKolc4YRcJZErnJCrJHKFE3KVRK5wQq6SyBVOyFUSucIJuUoiVzgh
          V0nkCifkKolc4YRcJZErnJCrJHKFE3KVRK5wQq6SyBVOyFUSucIJuUoiVzghV0nkCifkKolc4YRc
          JZErnJCrJHKFE3KVpHJ9Ln0IIB7kKump+ulLHwKIx6vp8fJM+qhtrBa5wsWL6fGyJn3UNlZX5fpG
          +hhANN5Pj5ct6aO2sZ6oXF9LHwOIxUd1vLSkj9rmUj/+D9IHAWLxRh0vXemDtrk2mclBfnpgeFD8
          sMNq+ow1IT810tSRPmYbrK1y/Un6KEAkNhhpEjXg5hW5/aSOlh+kj9kmezb9BbyUPgwQh0/cugrT
          65p+lD4OEIX3rGkStq5y/SR9HCAG+lr4qfQR22zqavj9R+kjARFQC4Y31qUP2GZ7ysIm5PJWHSmb
          0sdrw+mxYQabYKUHmtrSx2vTbXF6RQ4f1UBTpyd9uDbdE06vyOEDy/uDsMbpFVb6znWDk6s4fXpl
          cBiZXnFyDcQaKxFhoR+d4841BHqpBJtKIN3Hl5xcg6HnXl9yOYw0ehKH9Ydh6HVYiogMr/X11xPp
          4xQzeos1Rodh9uN7VgsH5Sm3r0gzu3HdZJwpGL1NPZvDk3RY8vG5HhVmbX9ABh16hcmsVhYLh0Uv
          lqBXPDKvlTmcwLTpFUvmtfalj048Nu+V8SbceUutwZr1uvFO+hhBKN68p9ZwzXt9xfomKNsb1Bqy
          ea/v2SgcOz8+p9bArXfmJ9i30gcLZH2cn1rZBjxkg835CXabK+Ime/1ydhx0WCgctqcbBNt0t7Fu
          rLHyMHRPns1/VxufmIRtoLfb7+e//w4XwjFodW6DffmBYhvl7bvnt7/7jT6vw4nDoH/3O9t4/2r7
          DZfFTfDm3aeX97/3Ne5a47EYrPLixYtt1NanFy/eP/h9E2tkBq1nG2imPk/LRajb7xT/1SMyW22G
          g2P1pLUmffjAn82nXVqN3Hq31V9b49K4xjpra1utH7hfBQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
          AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
          AAAAAAAAAAAAAAAAAAAAAAAAAAAAQFz+P0shq0RGUHkPAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE4
          LTA5LTI3VDAyOjM0OjMxLTA3OjAwp58oYwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOC0wOS0yN1Qw
          MjozNDozMS0wNzowMNbCkN8AAAAASUVORK5CYII='
            />
          </svg>
        );
      case 'arrow_right':
        return (
          <svg fill={color} height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M12,2 C6.48617769,2 2,6.48617769 2,12 C2,17.5138223 6.48617769,22 12,22 C17.5138223,22
            22,17.5138223 22,12 C22,6.48617769 17.5138223,2 12,2 Z M12,3.53846154 C16.6814908,3.53846154
            20.4615385,7.31851 20.4615385,12 C20.4615385,16.6814908 16.6814908,20.4615385 12,20.4615385
            C7.31851,20.4615385 3.53846154,16.6814908 3.53846154,12 C3.53846154,7.31851
            7.31851,3.53846154 12,3.53846154 Z M10.6298077,6.83173077 L9.52403846,7.9375 L13.5865385,12
            L9.52403846,16.0625 L10.6298077,17.1682692 L15.2451923,12.5528846 L15.7740385,12 L15.2451923,
            11.4471154 L10.6298077,6.83173077 Z'
              id='Shape'
              fill='#FFFFFF'
              fillRule='nonzero'
              mask='url(#mask-2)'
            />
          </svg>
        );
      case 'phone':
        return (
          <svg xmlns='http://www.w3.org/2000/svg' link='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'>
            <defs>
              <path id='a' d='M0 0h24v24H0z' />
            </defs>
            <g fill='none' fillRule='evenodd'>
              <mask id='b' fill='#fff'>
                <use href='#a' />
              </mask>
              <path
                fill='#FFF'
                fillRule='nonzero'
                d='M6.363 2c-.401 0-.797.144-1.127.408l-.048.024-.024.024L2.765 4.93l.024.024a2.41 2.41 0 0 0-.647 2.593c.003.006-.003.018 0 .024.65 1.864 2.314 5.463 5.565 8.717 3.262 3.266 6.905 4.866 8.707 5.572h.024c.932.312 1.943.09 2.663-.529l2.422-2.425c.636-.637.636-1.741 0-2.378l-3.118-3.121-.024-.048c-.636-.637-1.763-.637-2.399 0l-1.535 1.536a12.416 12.416 0 0 1-3.142-2.16c-1.257-1.201-1.898-2.582-2.135-3.123l1.535-1.536c.645-.646.657-1.72-.024-2.354l.024-.024-.072-.072-3.07-3.17-.024-.024-.048-.024A1.807 1.807 0 0 0 6.363 2zm0 1.537c.057 0 .114.027.168.072l3.07 3.146.073.072c-.006-.006.045.075-.048.168l-1.92 1.921-.36.336.169.48s.881 2.363 2.734 4.13l.168.145c1.784 1.63 3.886 2.521 3.886 2.521l.48.217 2.279-2.282c.132-.132.108-.132.24 0l3.142 3.146c.132.132.132.084 0 .216l-2.35 2.354c-.355.303-.73.366-1.176.216-1.74-.685-5.119-2.17-8.132-5.187-3.037-3.041-4.623-6.49-5.205-8.165-.117-.312-.033-.775.24-1.009l.048-.048L6.195 3.61a.269.269 0 0 1 .168-.072z'
                mask='url(#b)'
              />
            </g>
          </svg>
        );
      case 'edit-pen':
        return (
          <svg xmlns='http://www.w3.org/2000/svg' link='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'>
            <defs>
              <path id='a' d='M0 0h24v24H0z' />
            </defs>
            <g fill='none' fillRule='evenodd'>
              <mask id='b' fill={color}>
                <use href='#a' />
              </mask>
              <path
                fill={color}
                fillRule='nonzero'
                d='M18.607 2a3.37 3.37 0 0 0-2.391 1.003L3.208 16.01l-.051.257-.9 4.525L2 22l1.208-.257 4.525-.9.257-.051L20.997 7.784a3.352 3.352 0 0 0 0-4.781A3.37 3.37 0 0 0 18.607 2zm0 1.568c.414 0 .832.19 1.234.591.8.8.8 1.668 0 2.468l-.592.566-2.442-2.442.566-.592c.401-.401.82-.59 1.234-.59zm-2.957 2.34l2.443 2.442-9.949 9.948a5.605 5.605 0 0 0-2.442-2.442l9.948-9.949zM4.648 17.192a3.894 3.894 0 0 1 2.16 2.16l-2.7.539.54-2.7z'
                mask='url(#b)'
              />
            </g>
          </svg>
        );
      case 'search':
        return (
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
            <path
              fill={color}
              fillRule='nonzero'
              d='M 9 2 C 5.1458514 2 2 5.1458514 2 9 C 2 12.854149 5.1458514 16 9 16 C 10.747998 16 12.345009 15.348024 13.574219 14.28125 L 14 14.707031 L 14 16 L 20 22 L 22 20 L 16 14 L 14.707031 14 L 14.28125 13.574219 C 15.348024 12.345009 16 10.747998 16 9 C 16 5.1458514 12.854149 2 9 2 z M 9 4 C 11.773268 4 14 6.2267316 14 9 C 14 11.773268 11.773268 14 9 14 C 6.2267316 14 4 11.773268 4 9 C 4 6.2267316 6.2267316 4 9 4 z'
            />
          </svg>
        );
      default:
        return null;
    }
  }
}

Svg.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Svg;
