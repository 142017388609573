import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import ConcessionService from '../../../services/ConcessionsServices';
import ReportSelector from '../atoms/ReportSelector';

const ConcessionsAndReportsSelector = ({ concessionIdChange, reportIdChange }) => {
  const [concessionIds, setConcessionIds] = useState([]);
  const [concessionNames, setConcessionNames] = useState([]);
  const [concessionSelected, setConcessionSelected] = useState('');
  const userRole = JSON.parse(localStorage.getItem('user')).role;

  useEffect(() => {
    concessionIdChange(concessionIds[concessionNames.indexOf(concessionSelected)]);
  }, [concessionSelected]);

  useEffect(() => {
    setConcessionSelected(concessionNames[0]);
  }, [concessionNames]);

  useEffect(() => {
    ConcessionService.getAllConcessions().then((results) => {
      setConcessionIds(results.map((concess) => concess._id));
      setConcessionNames(results.map((concess) => concess.name));
    });
  }, []);

  return (
    <React.Fragment>
      {userRole !== 'reseller' && (
        <div data-cy='siteSelection' className='formInput withSearch'>
          <label>Assigner un site *</label>
          <Select
            value={{ value: concessionSelected, label: concessionSelected }}
            options={concessionNames.map((concession) => ({ value: concession, label: concession }))}
            onChange={(e) => setConcessionSelected(e.value)}
            name='choose concession'
            className='cypress__select-assign-site'
          />
        </div>
      )}
      <ReportSelector reportIdChange={reportIdChange} concessionSelected={concessionSelected} />
    </React.Fragment>
  );
};

ConcessionsAndReportsSelector.propTypes = {
  concessionNames: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  application: PropTypes.object,
  concessionSelected: PropTypes.object,
  concessionIdChange: PropTypes.func,
  reportIdChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  application: state.application,
});

export default connect(mapStateToProps)(ConcessionsAndReportsSelector);
