import { Checkbox, List } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../../constants';

const CheckboxGroup = Checkbox.Group;

const ListMissions = ({
  missions, handleMissionClick, missionsSelected, checkAll,
}) => {
  const [missionSelected, setMissionSelected] = useState([]);

  const onCheckMissionSelected = (missionChecked) => {
    let allMissionSelected = [];
    if (missionSelected.length === 0) {
      allMissionSelected.push(missionChecked);
    } else {
      allMissionSelected = _.find(missionSelected, missionChecked)
        ? missionSelected.filter((mission) => mission.readableId !== missionChecked.readableId)
        : [...missionSelected, missionChecked];
    }
    setMissionSelected(allMissionSelected);
    missionsSelected(allMissionSelected);
  };

  const truncateDescription = (text, count) => {
    if (!text) {
      return null;
    }
    return (`${text?.slice(0, count)}${text?.length > count ? '...' : ''}`);
  };

  useEffect(() => {
    setMissionSelected([]);
  }, [missions]);

  useEffect(() => {
    if (checkAll === null) {
      setMissionSelected([]);
      missionsSelected([]);
    } else {
      setMissionSelected(missions);
      missionsSelected(missions);
    }
  }, [checkAll]);

  return (
    <List
      size='small'
      style={{ overflowY: 'auto' }}
      itemLayout='horizontal'
      dataSource={missions}
      renderItem={(mission) => (
        <CheckboxGroup value={missionSelected} onChange={(e) => onCheckMissionSelected(mission, e)}>
          <List.Item className='inspectionTileItem card'>
            <Checkbox value={mission}>
              <List.Item.Meta
                avatar={
                  <div>
                    <h4 className='inspectionTileId'>
                      Nº {mission.readableId}
                    </h4>
                    <h2 className={`mission inspectionTileStatus ${mission.status}`}>{constants.statusLabels[mission?.status]}</h2>
                  </div>
                }
                title={
                  <div>
                    <h4>
                      { mission?.entity?.name || '' } - { mission?.concession?.name || '' }
                    </h4>
                    <div className='rightMenuCard'>
                      <div className='inspectionTileContact'>
                        <span style={{ display: 'block' }}>
                          <i className='fas fa-user' /> {mission?.appointment?.contactName || ''}
                        </span>
                        <span>
                          <i className='fas fa-phone' /> <a href={`tel:${mission?.appointment?.contactPhone || ''}`}> {mission?.appointment?.contactPhone || ''}</a>
                        </span>
                      </div>
                      <div className='icon-action-inspection'>
                        <a onClick={() => handleMissionClick(mission)}>
                          <i className='fas fa-eye' /> Voir la fiche
                        </a>
                        {mission?.appointment?.lat && mission?.appointment?.lng && (
                          <a
                            rel='noreferrer'
                            target='_blank'
                            href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${mission?.appointment?.lat},${mission?.appointment?.lng}`}>
                            <i className='fas fa-street-view' /> StreetView
                          </a>
                        )}
                      </div>
                    </div>
                    <p className='addressCard'>{mission?.appointment?.address}</p>
                  </div>
                }
                description={
                <div>
                  <p> { mission?.user?.firstname || '' } - { mission?.user?.lastname || '' } </p>
                  <p> Du {moment(mission?.start).locale('fr').format(constants.formatDateAppointmentAgGrid) || ''} au { moment(mission?.end).locale('fr').format(constants.formatDateAppointmentAgGrid) || ''}</p>
                  <p>{ truncateDescription(mission?.description, 150) || '' }</p>
                  <p ><strong>Compétence(s) requise(s): </strong>{mission.requiredSkills.map((object) => object.label).join(', ')}</p>
                </div>
                }
              />
            </Checkbox>
          </List.Item>
        </CheckboxGroup>
      )}
    />
  );
};

ListMissions.propTypes = {
  missions: PropTypes.array,
  handleMissionClick: PropTypes.func,
  missionsSelected: PropTypes.func,
  checkAll: PropTypes.bool,
};

export default ListMissions;
