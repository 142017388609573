import moment from 'moment';
import constants from '../../../constants';

const localeMomentDateFormatter = 'fr';

const generateDatesComparator = () => (a, b) => {
  const dateA = new Date(a).setHours(0, 0, 0, 0);
  const dateB = new Date(b).setHours(0, 0, 0, 0);

  if (dateA === dateB) {
    return 0;
  }

  return dateA > dateB ? -1 : 1;
};

const dateFormatterExcel = (params) =>
  params.value ? moment(new Date(params.value), constants.formatDateTimeAppointmentDB).locale(localeMomentDateFormatter).format(constants.formatDateAppointment) : '';

const dateTimeFormatterExcel = (params) =>
  params.value ? moment(new Date(params.value), constants.formatDateTimeAppointmentDB).locale(localeMomentDateFormatter).format(constants.formatDateTimeAppointment) : '';

const dateFormatter = (params) => (params.value ? moment(params.value).locale(localeMomentDateFormatter).format(constants.formatDateAppointmentAgGrid) : '');

const dateFormatterWithTime = (params) => (params.value ? moment(params.value).locale(localeMomentDateFormatter).format(constants.formatDateTimeAppointmentAgGrid) : '');

const exportExcelDateAndDateTimeFormatter = (params) => {
  if (params?.column?.userProvidedColDef?.type === 'dateColumn' && params.value) {
    return dateFormatterExcel(params);
  }
  if (params?.column?.userProvidedColDef?.type === 'dateTimeColumn' && params.value) {
    return dateTimeFormatterExcel(params);
  }
  return params.value;
};

export { dateFormatter, dateFormatterWithTime, exportExcelDateAndDateTimeFormatter, generateDatesComparator };
