import { PlusSquareOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import EdiText from 'react-editext';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import { getBilling, getBillings, updateBilling } from '../../../actions/BillingsActions';
import constants from '../../../constants';
import convertToFloat from '../../../helpers/convertToFloat';
import ModalBody from '../../shared/molecules/ModalBody';

Modal.setAppElement('body');

const ModalViewBilling = ({ dispatch, isOpen, billing, billingSelected, isLoading, isLoadingUpdate, closeModal, error }) => {
  const { addToast } = useToasts();
  const [billingData, setBillingData] = useState([]);
  const [billingToUpdateData, setBillingToUpdateData] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectComment, setSelectComment] = useState(null);
  const [errorState, setErrorState] = useState(null);
  const selectRef = useRef([]);
  const statusBilling = _.get(billingSelected, 'status');
  const editableInvoice = [constants.statusBilling.estimate.name, constants.statusBilling.computed.name].includes(statusBilling);

  const updateBillingModal = async () => {
    if (billingToUpdateData.length === 0) return;

    const adjustmentIncomplete = billingToUpdateData.some(
      (billingReference) => !billingReference.comment || billingReference.adjustment === null || billingReference.adjustment === undefined,
    );

    if (adjustmentIncomplete) {
      setErrorState('Veuillez saisir un adjustement et un commentaire pour chaque inspection modifiée');
      return;
    }

    const result = await updateBilling(dispatch, { adjustments: billingToUpdateData }, billingSelected._id);

    if (result.type === 'UPDATE_BILLING_SUCCESS') {
      addToast('Mise à jour effectuée avec succès', { appearance: 'success', autoDismiss: true, autoDismissTimeout: 2000 });
      closeModal();
      await getBillings(dispatch);
    }
  };

  const upsert = (item) => {
    const array = [...billingToUpdateData];
    const i = array.findIndex((_item) => _item.billingReferenceId === item.billingReferenceId);
    if (i >= 0) {
      array[i] = { ...array[i], ...item };
      return setBillingToUpdateData(array);
    }
    array.push(item);
    return setBillingToUpdateData(array);
  };

  const customSelectComment = (arrayOfComments) => {
    if (!arrayOfComments) return '';
    const array = [];
    arrayOfComments.map((item) => array.push(item.value));
    return array.join('\n');
  };

  const handleInputAdjustment = (value, id, index) => {
    setErrorState('');
    const { billingReferences } = billingData[index];
    billingReferences.forEach((billingReference) => {
      if (billingReference._id === id) {
        const updatedBillingReference = billingToUpdateData?.find(({ billingReferenceId }) => billingReferenceId === billingReference._id);
        const currentBilling = {
          billingReferenceId: id,
          adjustment: value,
          /**
           * As a modification requires an adjustment and a comment.
           * So, if a new comment has been entered, we select it otherwise we select the initial value of the billingReference
           */
          comment: updatedBillingReference?.comment || billingReference.comment,
        };
        upsert(currentBilling);
      }
    });
  };

  const addSelectedComments = (billingReference, index) => {
    setErrorState('');
    const array = [...billingToUpdateData];
    let currentBilling;
    let newComments;
    if (!selectComment || selectComment.comment === '') return;
    const billingReferenceToUpdate = array.find((_item) => _item.billingReferenceId === billingReference._id);
    const customComments = customSelectComment(selectRef.current[index].getValue());
    if (billingReferenceToUpdate) {
      newComments = billingReferenceToUpdate.comment ? `${billingReferenceToUpdate.comment}\n${customComments}` : customComments;
      currentBilling = { billingReferenceId: billingReference._id, comment: newComments };
      setSelectComment(null);
    } else {
      newComments = billingReference.comment ? `${billingReference.comment}\n${customComments}` : customComments;
      currentBilling = { billingReferenceId: billingReference._id, comment: newComments };
      setSelectComment(null);
    }
    const oldComments = comments;
    oldComments[index] = newComments;
    setComments(oldComments);
    /**
     * As a modification requires an adjustment and a comment.
     * So, if a new adjustment has been entered, we select it otherwise we select the initial value of the billingReference
     */
    currentBilling.adjustment = billingReferenceToUpdate?.adjustment || billingReference.adjustment;
    upsert(currentBilling);
    if (selectRef && selectRef.current) {
      billingData.map((bill, i) => selectRef.current[i].clearValue());
    }
  };

  const handleSaveComment = (newComments, billingReference) => {
    setErrorState('');
    const updatedBillingReference = billingToUpdateData?.find(({ billingReferenceId }) => billingReferenceId === billingReference._id);
    const currentBilling = {
      billingReferenceId: billingReference._id,
      /**
       * As a modification requires an adjustment and a comment.
       * So, if a new adjustment has been entered, we select it otherwise we select the initial value of the billingReference
       */
      adjustment: updatedBillingReference?.adjustment || billingReference.adjustment,
      comment: newComments,
    };
    upsert(currentBilling);
  };

  const handleSelectComment = (newComments, index) => {
    const array = [];
    newComments.map((item) => array.push(item.value));
    setSelectComment({ index, comment: array.join('\n') });
  };

  const renderAdjustment = (billingReferences, index) => {
    if (!editableInvoice && !billingReferences) return null;

    let billingReferenceId;
    const adjustment = billingReferences.reduce((adjustmentTotal, { _id, adjustment: ad, inspection: { billingInformation } }) => {
      billingReferenceId = billingInformation && _id;
      return adjustmentTotal + (ad || 0);
    }, 0);

    return (
      <div className='pt-15' key={index}>
        <label>Penalité / Prime exceptionnelle :</label>
        <InputNumber
          className='flRight'
          name='Penalité / Prime exceptionnelle'
          placeholder='Penalité / Prime exceptionnelle'
          step={1}
          disabled={!editableInvoice}
          defaultValue={adjustment ? convertToFloat(adjustment) : 0}
          formatter={(value) => `${value}€`}
          parser={(value) => value.replace('€', '')}
          onChange={(value) => handleInputAdjustment(value, billingReferenceId || billingReferences[0]._id, index)}
        />
      </div>
    );
  };

  const renderComment = (commentToShow) => {
    if (commentToShow && commentToShow.split('\n').length > 1) {
      return commentToShow.split('\n').map((comment, i) => <div key={i}> - {comment}</div>);
    }
    return commentToShow;
  };

  useEffect(() => {
    if (billing.length > 0) {
      setBillingData(billing);
      const commentsFromDB = [];
      // eslint-disable-next-line array-callback-return
      Object.values(billing).map((groupedBilling) => {
        commentsFromDB.push(groupedBilling.billingReferences[0].comment);
        setComments(commentsFromDB);
      });
    }
  }, [billing]);

  useEffect(() => {
    (async () => getBilling(dispatch, billingSelected._id))();
  }, []);

  return (
    <ModalBody
      closeModal={closeModal}
      title={`Détails de la facture - ${billingSelected.specialistHeader.companyName}`}
      isOpen={isOpen}
      actionSave={updateBillingModal}
      isLoading={isLoading}
      updateAction={editableInvoice}
      error={errorState || error}
      loadingButton={isLoadingUpdate}
    >
      <div className='modalBilling'>
        <div className='mainTitle title'>
          <span className='modalFontBig'> Facture </span>
          <span style={{ textTransform: 'capitalize' }}>
            ({`${moment(_.get(billingSelected, 'invoiceFrom')).format('DD/MM/YYYY')} - ${moment(_.get(billingSelected, 'invoiceTo')).format('DD/MM/YYYY')}`}
          </span>
          )
        </div>
        {Object.values(billing).map((groupedBilling, index) => (
          <div key={index} className='card expanded'>
            <div className='mainContent'>
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className='bloc-left' xs={6}>
                      <div className='inspectionTitle'>
                        {groupedBilling.billingReferences.length > 1 ? (
                          <span>
                            Inspections groupées <span className='nbrInspectionColor'> (X{groupedBilling.billingReferences.length})</span>
                          </span>
                        ) : (
                          <span>Inspection unitaire</span>
                        )}
                      </div>
                      <div className='bolder pt-15'>
                        <span>{groupedBilling.address}</span> - <span>({moment(groupedBilling.date).format(constants.formatDateAppointment)})</span>
                      </div>
                    </Col>
                    <Col className='bloc-right' xs={6}>
                      <div>
                        <label> {groupedBilling.billingReferences.length > 1 ? <span>Rémunération inspections</span> : <span>Rémunération de l'inspection</span>} :</label>
                        <span className='flRight'> {convertToFloat(groupedBilling.inspectionsTotal)}€</span>
                      </div>
                      <div className='pt-15'>
                        <label>Prime de déplacement :</label>
                        <span className='flRight'>{convertToFloat(groupedBilling.distanceCompensationTotal)}€</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className='subContent'>
              <div className='text-content'>
                <Row>
                  <Col xs={12}>
                    <Row>
                      <Col className='bloc-left pr-4' xs={6}>
                        <div className='blocModel'>
                          {groupedBilling.billingReferences.map((reference, i) => (
                            <div key={i} style={{ paddingTop: '15px' }}>
                              <div className='idInspection'>
                                <span>ID : </span>
                                <span> {reference.inspection.readableId} </span>
                                <span className='flRight'> {convertToFloat(reference.inspectionPrice)}€ </span>
                              </div>
                              <div>
                                <span>Marque / modèle : </span>{' '}
                                <span className='flRight'>
                                  {' '}
                                  {reference.inspection.vehicle.brand} / {reference.inspection.vehicle.model}{' '}
                                </span>
                              </div>
                              <div>
                                <span>Immat : </span> <span className='flRight'> {reference.inspection.vehicle?.immat?.displayFormat} </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Col className='bloc-right' xs={6}>
                        <div>
                          <label> TVA :</label>
                          <span className='flRight'>{convertToFloat(groupedBilling.vatTotal)}€</span>
                        </div>
                        {renderAdjustment(groupedBilling.billingReferences, index)}
                        <div className='pt-15'>
                          <hr />
                          <span className='bolder'>Total :</span>
                          <span data-cy='billTotal' className='flRight'>
                            {convertToFloat(groupedBilling.totalWithAdjustments)}€
                          </span>
                          <hr />
                        </div>
                        <div className='pt-15'>
                          <span>Statut :</span>
                          <span className='flRight'>{constants.statusBilling[statusBilling].label}</span>
                        </div>
                        <div className='pt-15'>
                          <span>Barême :</span>
                          <span className='flRight'>
                            {`${groupedBilling.billingReferences[0].billingConfiguration.creator.firstname} ${
                              groupedBilling.billingReferences[0].billingConfiguration.creator.lastname
                            } - ${moment(groupedBilling.billingReferences[0].billingConfiguration.updatedAt).format(constants.formatDateAppointment)}`}
                          </span>
                        </div>
                        <div className='pt-15'>
                          <div>Commentaire : </div>
                          <div>
                            <div>
                              {editableInvoice && (
                                <div>
                                  <Select
                                    isMulti
                                    name='commentTypeBillingReference'
                                    onChange={(e) => handleSelectComment(e, index)}
                                    options={constants.commentTypeBillingReference}
                                    className='basic-multi-select selectComment'
                                    classNamePrefix='Ajouter un commentaire'
                                    resetOnSelect={true}
                                    key={index}
                                    ref={(element) => selectRef.current.push(element)}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                  />
                                  <PlusSquareOutlined className='addSelectComment' onClick={() => addSelectedComments(groupedBilling.billingReferences[0], index)} />
                                </div>
                              )}
                              <EdiText
                                type='textarea'
                                inputProps={{
                                  className: 'textarea',
                                  placeholder: 'Veuillez saisir votre commentaire',
                                  style: {
                                    outline: 'none',
                                    minWidth: '85%',
                                  },
                                  rows: 5,
                                }}
                                editButtonClassName={`styles-module_Editext__button__sxYQX  styles-module_Editext__edit_button__310_J editButton-${!editableInvoice}`}
                                value={comments[index]}
                                renderValue={renderComment}
                                onSave={(e) => handleSaveComment(e, groupedBilling.billingReferences[0])}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ModalBody>
  );
};

ModalViewBilling.propTypes = {
  application: PropTypes.object,
  billing: PropTypes.array,
  billingSelected: PropTypes.object,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingUpdate: PropTypes.bool,
  closeModal: PropTypes.func,
  refresh: PropTypes.func,
  dispatch: PropTypes.func,
  updateBilling: PropTypes.func,
  getBilling: PropTypes.func,
  error: PropTypes.any,
};
const mapStateToProps = (state) => ({
  application: state.application,
  billing: state.billings.billing,
  isLoading: state.billings.isLoading,
  isLoadingUpdate: state.billings.isLoadingUpdate,
  error: state.billings.error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updateBilling,
  getBilling,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalViewBilling);
