import _ from 'lodash';
import * as actions from '../actions/specialistsActions';
import { transformDataSpecialist } from '../helpers/specialistsFilter';

const initialState = {
  specialists: [],
  specialistPropositions: [],
  specialist: {},
  specialistSkills: [],
  updatedAt: 0,
  isLoading: false,
  isLoadingGetProposition: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_SPECIALISTS:
    case actions.REQUEST_SPECIALIST:
    case actions.REQUEST_UPDATE_SPECIALIST:
    case actions.REQUEST_SPECIALIST_SKILLS:
    case actions.REQUEST_SEND_NOTIFICATIONS:
    case actions.REQUEST_SEND_SMS:
    case actions.REQUEST_ADD_PROFESSIONAL_EXPERIENCE:
    case actions.REQUEST_UPDATE_PROFESSIONAL_EXPERIENCES:
    case actions.REQUEST_DELETE_PROFESSIONAL_EXPERIENCE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.REQUEST_SPECIALIST_PROPOSITIONS: {
      return {
        ...state,
        isLoadingGetProposition: true,
      };
    }
    case actions.GET_SPECIALISTS_SUCCESS: {
      const specialistsNotModified = state.specialists && _.differenceBy(state.specialists, action.specialists, (i) => i._id);
      const allSpecialists = [...specialistsNotModified, ...action.specialists];
      const updatedAtFromBd = Math.round(new Date(_.maxBy(allSpecialists, 'updatedAt').updatedAt) / 1000);
      const updatedAt = _.min([Math.round(Date.now() / 1000), updatedAtFromBd + 1]);
      return {
        ...state,
        isLoading: false,
        error: null,
        specialists: [...allSpecialists],
        updatedAt,
      };
    }
    case actions.GET_SPECIALIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        specialist: transformDataSpecialist(action.specialist),
      };
    }
    case actions.CLEAR_SPECIALIST: {
      return {
        ...state,
        isLoading: false,
        error: null,
        specialist: {},
      };
    }
    case actions.UPDATE_SPECIALIST_SUCCESS: {
      const specialistsNotModified = _.differenceBy(state.specialists, action.specialist, (i) => i._id);
      const allSpecialists = [...specialistsNotModified, action.specialist];
      const updatedAtFromBd = Math.round(new Date(_.maxBy(allSpecialists, 'updatedAt').updatedAt) / 1000);
      const updatedAt = _.min([Math.round(Date.now() / 1000), updatedAtFromBd + 1]);
      return {
        ...state,
        isLoading: false,
        error: null,
        specialist: transformDataSpecialist(action.specialist),
        updatedAt,
      };
    }
    case actions.GET_SPECIALIST_PROPOSITIONS_SUCCESS: {
      return {
        ...state,
        isLoadingGetProposition: false,
        error: null,
        specialistPropositions: [...action.specialistPropositions],
      };
    }
    case actions.CLEAR_SPECIALIST_PROPOSITIONS: {
      return {
        ...state,
        specialistPropositions: [],
        specialist: {},
        isLoading: false,
        isLoadingGetProposition: false,
        error: null,
      };
    }
    case actions.GET_SPECIALIST_SKILLS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        specialistSkills: [...action.specialistSkills],
      };
    }
    case actions.SEND_NOTIFICATIONS_SUCCESS:
    case actions.SEND_SMS_SUCCESS:
    case actions.ADD_PROFESSIONAL_EXPERIENCE_SUCCESS:
    case actions.UPDATE_PROFESSIONAL_EXPERIENCES_SUCCESS:
    case actions.DELETE_PROFESSIONAL_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case actions.GET_SPECIALISTS_ERROR:
    case actions.GET_SPECIALIST_ERROR:
    case actions.UPDATE_SPECIALIST_ERROR:
    case actions.GET_SPECIALIST_SKILLS_ERROR:
    case actions.GET_SPECIALIST_PROPOSITIONS_ERROR:
    case actions.SEND_NOTIFICATIONS_ERROR:
    case actions.SEND_SMS_ERROR:
    case actions.ADD_PROFESSIONAL_EXPERIENCE_ERROR:
    case actions.UPDATE_PROFESSIONAL_EXPERIENCES_ERROR:
    case actions.DELETE_PROFESSIONAL_EXPERIENCE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isLoadingGetProposition: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
