import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import ConcessionsActions from '../../../../actions/Concessions';
import ConcessionsServices from '../../../../services/ConcessionsServices';
import Text from '../../../shared/atoms/Text';
import locale from '../../locale';
import ConcessionSelect from '../../shared/atoms/ConcessionSelect';

Modal.setAppElement('body');

class ModalCreateUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  closeModal = () => {
    const { dispatch, application } = this.props;

    dispatch(ConcessionsActions.getActionToDispatch({ type: 'HIDE_MODAL' }));
    ConcessionsActions.reloadTable({ index: 'users' })(dispatch, application);
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  onChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  onChangeUser = (name, user) => {
    this.setState({ [name]: user });
  };

  onClearInput = (name) => {
    this.setState({ [name]: null });
  };

  renderValidButtonText = () => {
    const { isLoading } = this.props.concessions;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse' /> Sauvegarde en cours.
        </span>
      );
    }
    return 'Sauvegarder';
  };

  submit = async (e) => {
    e.preventDefault();
    const {
      role: userRole,
      concession: { entityId },
    } = this.props.application.user;
    const {
      concessionSelected,
      silentNotify,
      concessionsSelected,
      email,
      firstname,
      lastname,
      phone,
      canCreateMsaInspection,
      password,
      notifyConcession,
    } = this.state;

    const createdResponse = await ConcessionsServices.createUser({
      role: userRole === 'superChief' ? 'chief' : 'reseller',
      concessionId: concessionSelected && concessionSelected._id,
      concessionIds: concessionsSelected && _.map(concessionsSelected, (s) => s.value),
      entityId,
      silentNotify,
      email,
      firstname,
      lastname,
      phone,
      canCreateMsaInspection,
      password,
      notifyConcession,
    }).catch((err) => this.setState({ error: err ? err.error : 'Une erreur est survenue' }));

    if (createdResponse && createdResponse.status === 200) {
      this.closeModal();
    }
  };

  renderConcession = () => {
    const { user } = this.props.application;
    const { concessionSelected, concessionsSelected } = this.state;

    if (user.role === 'chief') {
      return (
        <React.Fragment>
          <div className='formInput withSearch'>
            {!concessionSelected ? (
              <div className='formInput withSearch'>
                <label>Assigner un site</label>
                <ConcessionSelect entityId={user.concession.entityId} onSelect={(c) => this.onChangeUser('concessionSelected', c)} selectedSites={concessionsSelected}/>
              </div>
            ) : (
              <React.Fragment>
                <input type='text' disabled defaultValue={`${concessionSelected.name}`} />
                <i className='fa fa-times-circle' onClick={(c) => this.onClearInput('concessionSelected', c)} />
                <Text fontStyle='normal' size={10} onClick={(c) => this.onClearInput('concessionSelected', c)}>
                  Supprimer le champs
                </Text>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      );
    }

    if (user.role === 'superChief') {
      return (
        <React.Fragment>
          <div className='formInput withSearch'>
            <label>Assigner des sites</label>
            <ConcessionSelect isMulti entityId={user.concession.entityId} onSelectMultiple={(c) => this.onChangeUser('concessionsSelected', c)} />
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  onChangeCheckbox = (e) => {
    const { target } = e;
    const { name, checked } = target;

    this.setState({ [name]: checked });
  };

  renderTitle = (role) => {
    switch (role) {
      case 'superChief':
        return "Création d'un compte de responsable";
      case 'chief':
        return "Création d'un compte utilisateur";
      default:
        throw new Error('Unsuported account role');
    }
  };

  render() {
    const { concessions } = this.props;
    const { user } = this.props.application;
    const { role } = user;

    return (
      <Modal shouldCloseOnOverlayClick={false} isOpen={true} onRequestClose={this.closeModal} overlayClassName='modal-overlay' className='modal modalConcession'>
        <div className='modalContent '>
          <div className='mainTitle'>{this.renderTitle(role)}</div>
          <form className='mt10px' onSubmit={this.submit}>
            <div className='formInput'>
              <label>Nom*</label>
              <input data-cy='lastName' type='text' name='lastname' placeholder='Nom' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Prénom(s)*</label>
              <input data-cy='firstName' type='text' name='firstname' placeholder='Prénom(s)' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Téléphone</label>
              <input data-cy='phoneNumber' type='text' name='phone' placeholder='Ex : 06125637623' onChange={this.onChange} />
            </div>
            <div className='formInput'>
              <label>Email*</label>
              <input data-cy='email' type='email' pattern='^[^@ ]+@[^@ ]+\.[^@ ]+$' name='email' placeholder='Ex: john@doe.com' onChange={this.onChange} />
            </div>
            {user && user.role === 'superChief' && (
              <React.Fragment>
                <div className='formInput'>
                  <label>Nouveau mot de passe</label>
                  <input data-cy='password' type='password' name='password' placeholder='**********' onChange={this.onChange} />
                </div>
              </React.Fragment>
            )}
            {user && user.role === 'chief' && (
              <React.Fragment>
                <div className='formInput'>
                  <label>Nouveau mot de passe</label>
                  <input data-cy='password' type='password' name='password' placeholder='**********' onChange={this.onChange} />
                </div>
                <div className='formInput'>
                  <label>{locale.ACCESS_NEW_INSPECTION_MSA}</label>
                  <input data-cy='canCreateMsaInspection' type='checkbox' name='canCreateMsaInspection' onChange={this.onChangeCheckbox} />
                </div>
                <div className='formInput'>
                  <label>{locale.GET_NOTIFIED_NEW_INSPECTION}</label>
                  <input data-cy='notifyConcession' type='checkbox' name='notifyConcession' onChange={this.onChangeCheckbox} />
                </div>
                <div className='formInput'>
                  <label>{locale.NOTIFY_USER_MAIL}</label>
                  <input data-cy='silentNotify' type='checkbox' name='silentNotify' onChange={this.onChangeCheckbox} />
                </div>
              </React.Fragment>
            )}
            {this.renderConcession()}
            {this.renderError()}
            <div className='center formButton'>
              <button type='button' onClick={this.closeModal} className='negativ'>
                Annuler
              </button>
              <button type='submit' className='positiv' disabled={concessions.isLoading}>
                {this.renderValidButtonText()}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

ModalCreateUser.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  concessions: PropTypes.object,
};

export default ModalCreateUser;
