import ConcessionsServices from '../services/ConcessionsServices';
import EntitiesServices from '../services/EntitiesServices';

// Action Types
const SET_REFRESH_AT_FALSE = 'SET_REFRESH_CONCESSION_TABLE_AT_FALSE';

// Action Creators
const getActionToDispatch = (params) => {
  switch (params.type) {
    case 'CHANGE_PAGE': {
      return {
        type: params.type,
        page: params.page,
      };
    }
    case 'UPDATE_INPUT_ROWS': {
      return {
        type: params.type,
        inputRows: params.inputRows,
        inputLength: params.inputLength,
      };
    }
    case 'UPDATE_INPUT_ROWS_ENTITIES': {
      return {
        type: params.type,
        entitiesOptions: params.entitiesOptions,
      };
    }
    case 'UPDATE_INPUT_ROWS_CONCESSIONS': {
      return {
        type: params.type,
        concessionsOptions: params.concessionsOptions,
      };
    }
    case 'UPDATE_INPUT_ROWS_USERS': {
      return {
        type: params.type,
        usersOptions: params.usersOptions,
      };
    }
    case 'UPDATE_TABLE_ROWS': {
      return {
        type: params.type,
        rows: params.rows,
        length: params.length,
        index: params.index,
      };
    }
    case 'UPDATE_TABLE_OPTIONS': {
      return {
        type: params.type,
        options: params.options,
      };
    }
    case 'LOADING_TABLE': {
      return {
        type: params.type,
        isLoading: true,
        isError: false,
      };
    }
    case 'LOADING_DONE_TABLE': {
      return {
        type: params.type,
        isLoading: false,
        isError: false,
      };
    }
    case 'LOADING_ERROR_TABLE': {
      return {
        type: params.type,
        isLoading: false,
        isError: true,
      };
    }
    case 'UPDATE_ROLE': {
      return {
        type: params.type,
        role: params.role,
      };
    }
    case 'UPDATE_FACETS': {
      return {
        type: params.type,
        facets: params.facets,
      };
    }
    case 'UPDATE_FACETS_CONCESSION': {
      return {
        type: params.type,
        facetsConcession: params.facetsConcession,
      };
    }
    case 'UPDATE_SEARCH': {
      return {
        type: params.type,
        search: params.search,
      };
    }
    case 'UPDATE_RANGE_DATE': {
      return {
        type: params.type,
        ranges: params.ranges,
      };
    }
    case 'SHOW_MODAL': {
      return {
        type: params.type,
        modalName: params.modalName,
        modalData: params.modalData,
      };
    }
    case 'HIDE_MODAL': {
      return {
        type: params.type,
      };
    }
    case SET_REFRESH_AT_FALSE: {
      return {
        type: params.type,
      };
    }
    case 'UPDATE_STATUS': {
      return {
        type: params.type,
        status: params.status,
      };
    }
    case 'SERVER_KILLED_SESSION': {
      return {
        type: 'SERVER_KILLED_SESSION',
        payload: {},
      };
    }
    default:
      return {
        type: 'UNKNOWN',
      };
  }
};

const getAction = (index) => {
  let action;
  switch (index) {
    case 'entities':
      action = EntitiesServices.getAllEntities;
      break;
    default:
      action = ConcessionsServices.getAllConcessions;
      break;
  }
  return action;
};

const getRows = (data) => async (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(getActionToDispatch({ type: 'LOADING_TABLE' }));

    const action = getAction(data.index);
    action({
      role: data.role,
      roles: data.roles,
      search: data.search,
      page: data.page,
      limit: data.limit,
      type: data.type,
      status: data.status,
    })
      .then((response) => {
        if (response.status === 401) {
          dispatch(getActionToDispatch({ type: 'SERVER_KILLED_SESSION' }));
        }
        dispatch(getActionToDispatch({ type: 'LOADING_DONE_TABLE' }));
        dispatch(
          getActionToDispatch({
            type: 'UPDATE_TABLE_ROWS',
            rows: response.rows,
            length: response.length,
            index: data.index,
          }),
        );
        return resolve({ rows: response.rows, length: response.length, index: data.index });
      })
      .catch((err) => {
        // console.log(err);
        dispatch(getActionToDispatch({ type: 'LOADING_ERROR_TABLE', error: err.reason || err.message }));
        return reject(err);
      });
  });

const reloadTable = (data) => (dispatch) => {
  getRows({
    search: '',
    page: 0,
    type: data.type || 'concession',
    index: data.index,
  })(dispatch);
};

const Concessions = {
  getActionToDispatch,
  getRows,
  reloadTable,
  SET_REFRESH_AT_FALSE,
};

export default Concessions;
