import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavBar from '../../../shared/templates/NavBar';
import NavigationActions from '../../../../actions/NavigationActions';
import AdminSpecialistsContainer from '../templates/AdminSpecialistsContainer';

const AdminSpecialistsPage = ({
  dispatch,
}) => {
  const { pathname } = window.location;
  const menu = pathname.split('/')[2];

  useEffect(() => {
    dispatch(NavigationActions.setNavigation({ navigation: pathname.split('/')[3] }));
  }, []);

  return (
    <>
      <NavBar />
      <div className="appContainer map">
        <AdminSpecialistsContainer adminInterface menu={menu} />
      </div>
    </>
  );
};

AdminSpecialistsPage.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
};

const mapStateToProps = (state) => ({
  application: state.application,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSpecialistsPage);
