import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

const Public = ({ isLogged, component, ...rest }) => <Route {...rest} render={(props) => React.createElement(component, { ...props, isLogged })} />;

Public.propTypes = {
  isLogged: PropTypes.bool,
  isAdmin: PropTypes.bool,
  component: PropTypes.object,
};

export default Public;
