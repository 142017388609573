import { Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import MissionsServices from '../../../services/MissionsServices';
import RenderError from '../../shared/atoms/RenderError';
import SpecialistSelectors from '../../shared/atoms/SpecialistSelectors';
import { statusOptionsSelect } from '../atoms/filterOptions';
import { orangeLine } from '../../../helpers/styles';

Modal.setAppElement('body');

const ModalAssignSpecialistOrChangeStatusMissions = (props) => {
  const [missionIds, setMissionIds] = useState([]);
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [assignedSpecialist, setAssignedSpecialist] = useState();
  const [cancelationComment, setCancelationComment] = useState('');

  const { modalName } = props.allMissions;

  useEffect(() => {
    props.allMissions.modalData.map((mission) => setMissionIds((prevArray) => [...prevArray, mission._id]));
  }, []);

  async function assignSpecialistOrChangeStatus() {
    if (modalName === 'changeStatus' || modalName === 'cancelMission') {
      const statusReponse = await MissionsServices.changeStatus({
        status: modalName === 'changeStatus' ? status : 'canceled',
        missionIds,
        cancelationComment: modalName === 'cancelMission' ? cancelationComment : '',
      });

      if (statusReponse && statusReponse.status < 400) {
        return props.closeModal(true);
      }
      return setError(statusReponse?.body?.error?.replace('Error:', '') || 'Une erreur est survenue');
    }
    let specialistAssignedResponse;
    if (assignedSpecialist?._id) {
      specialistAssignedResponse = await MissionsServices.assignSpecialist({
        specialist: assignedSpecialist?._id,
        missionIds,
      });
    } else {
      specialistAssignedResponse = await MissionsServices.unassignSpecialist({
        missionIds,
      });
    }

    if (specialistAssignedResponse && specialistAssignedResponse.status < 400) {
      return props.closeModal(true);
    }
    return setError(specialistAssignedResponse?.body?.error?.replace('Error:', '') || 'Une erreur est survenue');
  }

  const selectedMissions = (
    <div className='mission-inputs-container'>
      <ul className='mission-ids-list'>
        {props.allMissions.modalData.map((selectedMission, i) => (
          <li key={i}>
            Id Mission : {selectedMission.readableId}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <Modal onRequestClose={props.closeModal} shouldCloseOnOverlayClick={false} isOpen={true} contentLabel='Modal status mission' overlayClassName='modal-overlay' className='modal'>
      <div className='mission-modal '>
        {modalName === 'changeStatus' && (
          <>
            <div className='mission-title'>Changement de statut de mission(s)</div>
            <div className='mission-subtitle'>Vous êtes sur le point de changer le statut de la (ou les) mission(s) suivante(s) :</div>
            {selectedMissions}
            {orangeLine()}
            <div className='mission-inputs-container'>
              <div className='mission-input-subcontainer mission-status'>
                <Select
                  name='missionStatus'
                  options={statusOptionsSelect(props.allMissions.modalData[0].status)}
                  onChange={(e) => setStatus(e)}
                  placeholder='Veuillez selectionner le statut'
                />
              </div>
            </div>
          </>
        )}

        {modalName === 'cancelMission' && (
          <>
            <div className='mission-title'>Annulation de mission(s)</div>
            <div className='mission-subtitle'>Vous êtes sur le point d'annuler la ou les mission(s) suivante(s) :</div>
            {selectedMissions}
            <div className='mission-inputs-container'>
              <div className='mission-input-subcontainer mission-text-area-subcontainer'>
                <label className='mission-subtitle'>Motif(s) d'annulation(s) :</label>
                <textarea
                  className='mission-input mission-text-area mission-cancel'
                  placeholder='Exemple : aucun profil dans cette zone géographique...'
                  value={cancelationComment}
                  onChange={(e) => setCancelationComment(e.target.value)}
                ></textarea>
              </div>
            </div>
          </>
        )}

        {modalName === 'assignSpecialist' && (
          <>
            <div className='mission-title'>Assigner un staffeur</div>
            <div className='mission-subtitle'>Mission(s) sélectionnée(s) :</div>

            {selectedMissions}
            {orangeLine()}
            <div className='mission-inputs-container'>
              <div className='mission-input-subcontainer mission-status'>
                <SpecialistSelectors
                  type='text'
                  className='mission-input mission-input-large'
                  placeholder='Rechercher un spécialiste'
                  selectOptions={(specialist) => setAssignedSpecialist(specialist)}
                  label='Spécialiste'
                />
              </div>
            </div>
          </>
        )}

        {modalName === 'unassignSpecialist' && (
          <>
            <div className='mission-title'>Remise en attente de mission(s)</div>
            <div className='mission-subtitle'>Vous êtes sur le point de remettre en attente la ou les mission(s) suivante(s) :</div>
            {selectedMissions}
          </>
        )}

        <div>
          {orangeLine()}
          <p className='content'>Souhaitez-vous confirmer ?</p>
          <div className='center formButton mt20px'>
            <button type='button' onClick={() => props.closeModal()} className='negativ'>
              Non
            </button>
            <button type='button' onClick={() => assignSpecialistOrChangeStatus()} className='positiv'>
              Oui, je confirme
            </button>
          </div>
        </div>
      </div>

      {error && <RenderError error={error} />}
    </Modal>
  );
};

ModalAssignSpecialistOrChangeStatusMissions.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  allMissions: PropTypes.object,
  updatedAt: PropTypes.number,
  rowsData: PropTypes.array,
  closeModal: PropTypes.func,
};

export default ModalAssignSpecialistOrChangeStatusMissions;
