import { Select } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Modal from 'react-modal';
import c from '../../../../constants';
import ReportServices from '../../../../services/ReportsService';

const { Option } = Select;

Modal.setAppElement('body');

const repairCostTypes = {
  flatRate: 'flatRate',
  GT: 'GT',
  labourTime: 'labourTime',
};

const gtEstimateOperations = {
  replace: 'SU',
  repair: 'RE',
  disassembleAssemble: 'DM',
  paint: 'PA',
  anticorrosionTreatment: 'TA',
  undersidePainting: 'PB',
  check: 'VE',
  adjust: 'AJ',
  align: 'AL',
  dismantleRemove: 'DA',
  clean: 'LI',
  purge: 'PU',
  emptyAndFill: 'VL',
  topUpOilRefill: 'RL',
  perform: 'RZ',
  polish: 'PL',
  program: 'PR',
};

const technicityTypes = {
  mechanics: 'mechanics',
  body: 'body',
  trim: 'trim',
  electricity: 'electricity',
  paint: 'paint',
};

const technicityLevels = {
  T1: 'T1',
  T2: 'T2',
  T3: 'T3',
};

const labourTimeInfosInitializer = {
  [technicityTypes.electricity]: undefined,
  [technicityTypes.body]: undefined,
  [technicityTypes.paint]: undefined,
  [technicityTypes.trim]: undefined,
  [technicityTypes.mechanics]: undefined,
};

class ModalCreateDefectTypologyDefect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: '',
      defectId: '',
      typologyId: '',
      repairCostType: repairCostTypes.flatRate,
      gtOperationCode: gtEstimateOperations.replace,
      labourTimeInfos: labourTimeInfosInitializer,
    };
  }

  componentDidMount() {
    if (this.props.isEdit) {
      const { modalData } = this.props;
      this.setState({
        label: _.get(modalData, 'label'),
        defectId: _.get(modalData, 'defectId'),
        typologyId: _.get(modalData, 'typologyId'),
        repairCostType: _.get(modalData, 'repairCostInfos.repairCostType', repairCostTypes.flatRate),
        gtOperationCode: _.get(modalData, 'repairCostInfos.gtInfos.gtOperationCode', gtEstimateOperations.replace),
        labourTimeInfos: _.get(modalData, 'repairCostInfos.labourTimeInfos', labourTimeInfosInitializer),
      });
    }
  }

  onChange = (e, isCheckbox) => {
    const { target } = e;
    const { name, value, checked } = target;

    if (isCheckbox) {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
  };

  onSelectChange = (value, name) => {
    this.setState({ [name]: value });
  };

  onLabourTimeChange = (value, name) => {
    const newLabourTimeInfos = this.state.labourTimeInfos;
    newLabourTimeInfos[name] = value === null ? undefined : value; // undefined will unset the value

    this.setState({ labourTimeInfos: newLabourTimeInfos });
  };

  submit = async (e) => {
    e.preventDefault();

    const { label, defectId, typologyId, repairCostType, gtOperationCode, labourTimeInfos } = this.state;

    const repairCostInfos = { repairCostType };

    if (repairCostType === repairCostTypes.GT) {
      repairCostInfos.gtInfos = { gtOperationCode };
    } else if (repairCostType === repairCostTypes.labourTime) {
      repairCostInfos.labourTimeInfos = labourTimeInfos;
    }

    const data = {
      label,
      repairCostInfos,
    };
    this.setState({ isLoading: true });
    if (this.props.isEdit === true) {
      try {
        await ReportServices.put(c.URI.v3.defectTypologies.concat(`/${typologyId}/defect/${defectId}`), data);
        this.props.onSubmit('defect', this.props.modalData, data);
      } catch (err) {
        this.setState({ error: err ? err.error : 'Une erreur est survenue' });
      }
    }

    this.setState({ isLoading: false });
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{this.state.error}</p>
        </div>
      );
    }
    return null;
  };

  renderValidButtonText = () => {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <span>
          <i className='fa fa-spinner fa-pulse'></i> Sauvegarde en cours.
        </span>
      );
    }
    return (
      <button type='button' onClick={this.submit} className='positiv' disabled={isLoading}>
        Sauvegarder
      </button>
    );
  };

  render() {
    const userCanEdit = this.props.isEdit;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        onRequestClose={this.closeModal}
        contentLabel='Modifier un défaut'
        overlayClassName='modal-overlay'
        className='modal modalReport'
        style={!userCanEdit ? { content: { cursor: 'not-allowed' } } : {}}
      >
        <Col className='modalContent' style={!userCanEdit ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
          <div className='mainTitle'>
            {' '}
            <span className='modalFontBig'>Modifier un défaut</span>
          </div>
          <form className='mt10px modalFont'>
            <Row xs={12}>
              <Col xs={12}>
                <div className='warning'>
                  <p>
                    <i className='fas fa-exclamation-triangle' />
                    &nbsp;Cette modification impactera toutes les occurences de ce défaut.{' '}
                  </p>
                </div>
              </Col>
            </Row>
            <Row xs={12} style={{ marginBottom: 5 }}>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Label</label>
                  <input
                    type='text'
                    disabled
                    name='label'
                    value={this.state.label}
                    placeholder='Label'
                    onChange={(e) => this.onChange(e, false)}
                    style={{ minHeight: 38, borderRadius: 8 }}
                  />
                </div>
              </Col>
              <Col xs={6}>
                <div className='formInput'>
                  <label>Type de repairCost </label>
                  <Select
                    name='repairCostType'
                    defaultValue={this.state.repairCostType}
                    dropdownStyle={{ zIndex: 1500 }}
                    onChange={(e) => this.onSelectChange(e, 'repairCostType')}
                  >
                    <Option value={repairCostTypes.flatRate}>Forfait (Par défaut)</Option>
                    <Option value={repairCostTypes.GT}>GT Estimate</Option>
                    <Option value={repairCostTypes.labourTime}>Temps Main d'oeuvre</Option>
                  </Select>
                </div>
              </Col>
            </Row>

            {this.state.repairCostType === repairCostTypes.GT ? (
              <Row xs={12} style={{ marginBottom: 5 }}>
                <Col xs={12}>
                  <div className='formInput'>
                    <label>Opération GT Estimate à effectuer</label>
                    <Select
                      name='gtOperationCode'
                      defaultValue={this.state.gtOperationCode}
                      dropdownStyle={{ zIndex: 1500 }}
                      onChange={(e) => this.onSelectChange(e, 'gtOperationCode')}
                    >
                      <Option value={gtEstimateOperations.replace}>Remplacement</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            ) : null}

            {this.state.repairCostType === repairCostTypes.labourTime ? (
              <Row xs={12} style={{ marginBottom: 5 }}>
                <Col xs={12} style={{ marginBottom: 15 }}>
                  <h3>Main d'oeuvre sur ce défaut</h3>
                </Col>
                <Col xs={6}>
                  <div className='formInput'>
                    <label>Carosserie</label>
                    <Select
                      name={technicityTypes.body}
                      defaultValue={this.state.labourTimeInfos[technicityTypes.body]}
                      style={{ width: 100 }}
                      dropdownStyle={{ zIndex: 1500 }}
                      onChange={(e) => this.onLabourTimeChange(e, technicityTypes.body)}
                    >
                      <Option value={null} />
                      <Option value={technicityLevels.T1}>T1</Option>
                      <Option value={technicityLevels.T2}>T2</Option>
                      <Option value={technicityLevels.T3}>T3</Option>
                    </Select>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className='formInput'>
                    <label>Peinture</label>
                    <Select
                      name={technicityTypes.paint}
                      defaultValue={this.state.labourTimeInfos[technicityTypes.paint]}
                      style={{ width: 100 }}
                      dropdownStyle={{ zIndex: 1500 }}
                      onChange={(e) => this.onLabourTimeChange(e, technicityTypes.paint)}
                    >
                      <Option value={null} />
                      <Option value={technicityLevels.T1}>T1</Option>
                      <Option value={technicityLevels.T2}>T2</Option>
                      <Option value={technicityLevels.T3}>T3</Option>
                    </Select>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className='formInput'>
                    <label>Mécanique</label>
                    <Select
                      name={technicityTypes.mechanics}
                      defaultValue={this.state.labourTimeInfos[technicityTypes.mechanics]}
                      style={{ width: 100 }}
                      dropdownStyle={{ zIndex: 1500 }}
                      onChange={(e) => this.onLabourTimeChange(e, technicityTypes.mechanics)}
                    >
                      <Option value={null} />
                      <Option value={technicityLevels.T1}>T1</Option>
                      <Option value={technicityLevels.T2}>T2</Option>
                      <Option value={technicityLevels.T3}>T3</Option>
                    </Select>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className='formInput'>
                    <label>Électricité</label>
                    <Select
                      name={technicityTypes.electricity}
                      defaultValue={this.state.labourTimeInfos[technicityTypes.electricity]}
                      style={{ width: 100 }}
                      dropdownStyle={{ zIndex: 1500 }}
                      onChange={(e) => this.onLabourTimeChange(e, technicityTypes.electricity)}
                    >
                      <Option value={null} />
                      <Option value={technicityLevels.T1}>T1</Option>
                      <Option value={technicityLevels.T2}>T2</Option>
                      <Option value={technicityLevels.T3}>T3</Option>
                    </Select>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className='formInput'>
                    <label>Garnissage intérieur</label>
                    <Select
                      name={technicityTypes.trim}
                      defaultValue={this.state.labourTimeInfos[technicityTypes.trim]}
                      style={{ width: 100 }}
                      dropdownStyle={{ zIndex: 1500 }}
                      onChange={(e) => this.onLabourTimeChange(e, technicityTypes.trim)}
                    >
                      <Option value={null} />
                      <Option value={technicityLevels.T1}>T1</Option>
                      <Option value={technicityLevels.T2}>T2</Option>
                      <Option value={technicityLevels.T3}>T3</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            ) : null}

            {this.renderError()}
          </form>
        </Col>
        <div className='center formButton'>
          <button type='button' onClick={this.props.onSubmit} className='negativ'>
            {userCanEdit ? 'Annuler' : 'Fermer'}
          </button>
          {userCanEdit && this.renderValidButtonText()}
        </div>
      </Modal>
    );
  }
}

ModalCreateDefectTypologyDefect.propTypes = {
  onSubmit: PropTypes.func,
  modalData: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default ModalCreateDefectTypologyDefect;
