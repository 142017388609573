import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ConcessionsServices from '../../../services/ConcessionsServices';

const UsersSelect = ({
  onSelect,
  entityId,
  isClearable = true,
  selection,
  concessionId,
  styles,
  disabledInputConcessionView,
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async function initializeOrSetUsers() {
      const allUsers = await ConcessionsServices.getAllAccounts({ entityId });
      const filteredUsers = allUsers.filter((user) =>
        (user.role === 'reseller' && user?.concession?.concessionId === concessionId)
      || (user.role === 'chief' && user?.concession?.concessionIds?.includes(concessionId))
       || (user.role === 'superChief' && user.concession.entityId === entityId));

      setUsers((filteredUsers) || []);
    }());
    return () => {
      setUsers([]);
    };
  }, [concessionId]);

  const handleSelect = (e) => onSelect(e?.user);

  const options = users.map((user) => ({ value: user._id, label: `${user.firstname} ${user.lastname}`, user }));
  return (
    <div data-cy='selectConcession' className='search-input max'>
      <Select
      styles={styles}
      className='multi-select'
      isClearable={isClearable}
      onChange={(e) => handleSelect(e)}
      options={options}
      isSearchable
      placeholder='Rechercher un utilisateur'
      value={selection ? { value: selection, label: `${selection?.firstname} ${selection?.lastname}` } : ''}
      isDisabled={disabledInputConcessionView}
      />
    </div>
  );
};

UsersSelect.propTypes = {
  onSelect: PropTypes.func,
  entityId: PropTypes.string,
  concessionId: PropTypes.string,
  isClearable: PropTypes.bool,
  selection: PropTypes.any,
  styles: PropTypes.object,
  disabledInputConcessionView: PropTypes.bool,
};

export default UsersSelect;
