import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ActionButton = (props) => {
  const [loading, setLoading] = useState(false);
  let className = '';
  const style = {};

  switch (props.shape) {
    case 'circle':
      className = 'gridButton circle';
      break;
    case 'reactangle':
    default:
      className = 'gridButton';
  }

  if (props.disabled) {
    className += ' disabled';
  } else {
    switch (props.color) {
      case 'red':
        className += ' red';
        break;
      case 'reactangle':
      default:
    }
  }

  if (props.gutterRight) {
    style.marginRight = '10px';
  } else if (props.gutterLeft) {
    style.marginLeft = '10px';
  }

  const onClickButton = async () => {
    setLoading(true);
    await props.onClick();
    setLoading(false);
  };

  return (
    <button style={style} type='button' title={props.title || ''} disabled={loading || props.disabled} className={className} onClick={onClickButton}>
      {loading && <i className='fa fa-spinner fa-pulse' />}
      {!loading && props.text}
    </button>
  );
};

ActionButton.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  shape: PropTypes.string,
  text: PropTypes.node,
  onClick: PropTypes.func,
  gutterRight: PropTypes.bool,
  gutterLeft: PropTypes.bool,
};

export default ActionButton;
