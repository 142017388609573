import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

function unloadScript() {
  const googleScripts = document.getElementsByTagName('script');
  for (let i = 0; i < googleScripts.length; i += 1) {
    const src = document.getElementsByTagName('script')[i].getAttribute('src');
    if (src?.includes('AutocompletionService')) {
      googleScripts[i].parentNode.removeChild(googleScripts[i]);
    }
  }
}

let autoComplete;
let fullAddressName;

async function handlePlaceSelect(setAddress, setAddressDetails) {
  const addressCoordinates = autoComplete.getPlace().geometry.location.toJSON();
  setAddressDetails(addressCoordinates);

  const addressObject = autoComplete.getPlace();

  if (addressObject.formatted_address.includes(addressObject.name)) {
    fullAddressName = addressObject.formatted_address;
  } else {
    fullAddressName = `${addressObject.name} ${addressObject.formatted_address}`;
  }
  setAddress(fullAddressName);
}

function handleScriptLoad(setAddress, setAddressDetails, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { componentRestrictions: { country: ['fr', 'es', 'nl', 'de', 'be', 'pt'] } },
  );

  autoComplete.setFields(['name', 'address_components', 'formatted_address', 'place_id', 'geometry']);
  autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(setAddress, setAddressDetails));
}

function GooglePlacesAutoComplete(props) {
  const [query, setQuery] = useState(props.value);
  const [address, setAddress] = useState(props.value);
  const [addressCoordinates, setAddressCoordinates] = useState('');
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    handleScriptLoad(setAddress, setAddressCoordinates, autoCompleteRef);
    return () => { unloadScript(); };
  }, []);

  useEffect(() => {
    setQuery(props.value);
  }, [props.value]);

  useEffect(() => {
    props.getAddressDetails(address, addressCoordinates);
    setQuery(address);
  }, [address]);

  return (
    <div className={props?.headerClassName || 'googlePlacesAutoComplete formInput'}>
    <label className={props?.labelClassName}>{props.label || null}</label>
      <input
        data-cy='autoCompleteInput'
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder={props.placeholder || ''}
        value={query || ''}
        type='text'
        autoComplete='custom-address'
        className={props?.className}
      />
    </div>
  );
}

GooglePlacesAutoComplete.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  getFullAdress: PropTypes.func,
  getAddressDetails: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default GooglePlacesAutoComplete;
