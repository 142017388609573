/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import c from '../../../../constants';
import { getSignedUrl } from '../../../../helpers/manageUpload';

export default function InfosHabilitationsViewer({ habilitationsId }) {
  const [habilitations, setHabilitations] = useState([]);

  const open = async (url) => {
    if (!url) {
      return;
    }
    const signedUrl = await getSignedUrl(c.URI.signedUrlGet, url);
    const res = await signedUrl.json();
    window.open(res.url, '_blank');
  };

  const habilitationsList = (habilitationName, habilitationsData) => (
    <div key={habilitationName} style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '.7rem',
    }}>
      <div style={{ color: '#054885', textTransform: 'Capitalize' }}>{habilitationName} : </div>
      {habilitationsData.map((habilitationData) => (
        <div key={habilitationData._id}>
          {habilitationsData.length >= 1 && <div style={{ display: 'inline-block', color: '#054885' }}>{ habilitationData.habilitationId.name }</div> }
          <div style={{ marginLeft: '.6rem', display: 'inline-block' }}>
            <label style={{ color: 'rgb(181 181 181)' }}>
              ({moment(habilitationData.obtainingDate).format('MM/YYYY')}
            </label> - <label style={{ color: 'rgb(181 181 181)' }}>{moment(habilitationData.renewalDate).format('MM/YYYY')})</label>
            <span
              style={{ marginLeft: '.35rem', cursor: 'pointer', color: '#054885' }}
              onClick={() => open(`${habilitationData.docUrl}`)}
            >
              <i className="fas fa-file-pdf"></i>
            </span>
          </div>
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    const groupedHabilitations = _.groupBy(habilitationsId, (habilitation) => habilitation.habilitationId.habilitationCategoryId.name);
    setHabilitations(groupedHabilitations);
  }, [habilitationsId]);

  return (
    <>
      { habilitations && Object.keys(habilitations).map((habilitation) => habilitationsList(habilitation, habilitations[habilitation]))}
    </>
  );
}

InfosHabilitationsViewer.propTypes = {
  habilitationsId: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    obtainingDate: PropTypes.string.isRequired,
    renewalDate: PropTypes.string.isRequired,
    docUrl: PropTypes.string.isRequired,
  })).isRequired,
};

